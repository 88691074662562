var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-new-rule-cards-component" },
    _vm._l(_vm.filteredCards, function (card, index) {
      return _c(
        "create-new-rule-card",
        _vm._b(
          {
            key: "create-new-rule-card--" + index,
            ref: "cards",
            refInFor: true,
            attrs: { disabled: _vm.disabled },
            nativeOn: {
              click: function ($event) {
                return (() => _vm.onClickCard()).apply(null, arguments)
              },
            },
          },
          "create-new-rule-card",
          card,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }