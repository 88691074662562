import BaseEntity from '@/entities/BaseEntity';

export default class Audience extends BaseEntity
{
  id = null;
  type = '';
  name = '';
  has_geotargeting = false;
  has_gender_targeting = false;
  has_age_targeting = false;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

}
