<template>
  <div
    :class="wrapperClasses"
    @mouseover="() => $emit('mouseover')"
    @mouseleave="() => $emit('mouseleave')"
    @click="() => $emit('click')"
  >
    <div class="sidebar-content">
      <div class="top">
        <div class="logo">
          <div class="logo-inline-without-logo" />
          <i class="icon main-logo primary" />
        </div>

        <transition-expand>
          <company-selector v-if="hasHandledCompanies && !closed" />
        </transition-expand>

        <div class="divider under-logo-divider" />

        <div class="menu">
          <access-handled-router-link to="/dashboard" :title="tr('Dashboard')">
            <i class="icon dashboard" />
            <span>{{ tr('Dashboard') }}</span>
          </access-handled-router-link>

          <!-- <access-handled-router-link to="/reports">
            <i class="i icon reports" />
            <span>Reports</span>
          </access-handled-router-link> -->

          <access-handled-router-link
            v-if="$app.isAd"
            to="/automation"
            :title="tr('Automations')"
          >
            <i class="i icon rocket" />
            <span>{{ tr('Automations') }}</span>
          </access-handled-router-link>

          <access-handled-router-link
            v-if="$app.isAd"
            to="/post-copy-analysis"
            :title="tr('Infinite∞Ai')"
          >
            <i class="i icon robot" />
            <span>{{ tr('Infinite∞Ai') }}</span>
            <div class="flag">Beta</div>
          </access-handled-router-link>
          <access-handled-router-link
            v-else-if="$app.isBuzz"
            to="/moderation"
            :title="tr('Moderation')"
          >
            <i class="i icon shield-ok" />
            <span>{{ tr('Moderation') }}</span>
          </access-handled-router-link>

          <access-handled-router-link
            v-if="$app.isAd"
            to="/report"
            :title="tr('Report')"
          >
            <i class="i icon bar-chart" />
            <span>{{ tr('Report') }}</span>
          </access-handled-router-link>

          <div class="divider" />

          <access-handled-router-link to="/connected-platforms" :title="tr('Connected platforms')">
            <i class="i icon plug" />
            <span>{{ tr('Connected platforms') }}</span>
          </access-handled-router-link>

          <access-handled-router-link
            to="/error-logs"
            :title="tr('Error log')"
          >
            <i class="i icon bug" />
            <span>{{ tr('Error log') }}</span>
          </access-handled-router-link>

          <access-handled-router-link to="/settings" :title="tr('Settings')">
            <i class="i icon gear" />
            <span>{{ tr('Settings') }}</span>
          </access-handled-router-link>

          <access-handled-router-link to="/account" :title="tr('Account')">
            <i class="i icon profile" />
            <span>{{ tr('Account') }}</span>
          </access-handled-router-link>

          <div class="divider" />

          <a @click="() => logout()" :title="tr('Log out')">
            <i class="i icon logout" />
            <span>{{ tr('Log out') }}</span>
          </a>
        </div>
      </div>

      <div class="bottom">
        <language-selector />
      </div>
    </div>

    <div class="toggle-button" :class="{'remove-animation': removeAnimation}" @click="() => toggle()">
      <i class="icon arrow primary" />
    </div>
  </div>
</template>

<script>
import CompanySelector from '@/components/CompanySelector.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default {
  name: 'Sidebar',
  props: {
    floating: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    CompanySelector,
    LanguageSelector,
    TransitionExpand,
  },
  data: () => ({
    closed: false,
    rendered: false,
    removeAnimation: false,
  }),
  computed: {
    wrapperClasses() {
      return {
        'sidebar-component': true,
        'closed': this.closed,
        'rendered': this.rendered,
      };
    },
    hasHandledCompanies() {
      if (!this.$root.loggedInUser) {
        return false;
      }

      var variable = this.$root.loggedInUser.company.managed_companies;
      return Array.isArray(variable) && variable.length > 0;
    },
  },
  watch: {
    floating: {
      immediate: true,
      handler() {
        this.closed = this.floating;
      }
    },
  },
  mounted() {
    setTimeout(() => this.rendered = true, 250);
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
    toggle() {
      this.removeAnimation = true;
      this.closed = !this.closed;
    },
    close() {
      this.closed = true;
    }
  },
};
</script>
