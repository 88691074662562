var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "onboarding-component onboarding--buzz",
      style: _vm.wrapperStyle,
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("i", {
            staticClass: "icon close",
            style: { "background-color": _vm.iconBackgroundColor },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          }),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 0,
                      expression: "step === 0",
                    },
                  ],
                  staticClass: "step step--0 step--welcome",
                },
                [
                  _c("top-image-content", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/onboarding/step-welcome.png"),
                      },
                    }),
                  ]),
                  _c("div", {
                    staticClass: "title large",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.tr(
                          "Welcome to the <span class='primary'>Infinite∞Buzz</span> automatic comment moderator system!"
                        )
                      ),
                    },
                  }),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "We are glad that you are with us in creating a more positive social media world."
                          )
                        )
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "In the following, we will help you use the system in two simple steps."
                          )
                        )
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "Don't worry, we will guide you step by step so that you can turn on your automatic comment moderation as easily as possible."
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Let start it!")))]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 1 && !_vm.skipFacebookProfile,
                      expression: "step === 1 && !skipFacebookProfile",
                    },
                  ],
                  staticClass: "step step--1 step--profile",
                },
                [
                  _c("top-image-content", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/onboarding/step-profile.png"),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Connect Facebook Profile"))),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "Okay, first, let's connect your Facebook profile. This is how we will be able to access your assets."
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.tr(
                              "Don't worry, data protection is also of utmost importance to us here."
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              'Click the "Connect Profile" button below and follow the instructions.'
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "With this step, we are already on our way to more clean social media experience!"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        class: _vm.connectProfileButtonClasses,
                        attrs: { disabled: _vm.isConnecting },
                        on: { click: () => _vm.connect() },
                      },
                      [
                        _vm.isConnecting
                          ? _c("i", { staticClass: "icon loader white" })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.tr("Connect Profile")) + " "),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 2,
                      expression: "step === 2",
                    },
                  ],
                  staticClass: "step step--2 step--source-profile",
                },
                [
                  _c("div", { staticClass: "title small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.tr(
                          "Congratulations, you are very close to moderating unsolicited comments!"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "In the next step, use the checkboxes to connect the <b>Facebook Pages</b> where you want to have comments on posts moderated."
                          )
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "scroll-wrapper" },
                    [
                      _c("connected-platforms-meta-facebook-items-view", {
                        attrs: {
                          "platform-from-prop": "facebook",
                          minimal: "",
                        },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "secondary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Back")))]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Next")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 3,
                      expression: "step === 3",
                    },
                  ],
                  staticClass: "step step--3 step--source-profile",
                },
                [
                  _c("div", { staticClass: "title small" }, [
                    _vm._v(
                      _vm._s(
                        _vm.tr(
                          "Congratulations, you are very close to moderating unsolicited comments!"
                        )
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "In the next step, use the checkboxes to connect the <b>Instagram Accounts</b> where you want to have comments on posts moderated."
                          )
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "scroll-wrapper" },
                    [
                      _c("connected-platforms-meta-facebook-items-view", {
                        attrs: {
                          "platform-from-prop": "instagram",
                          minimal: "",
                        },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "secondary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Back")))]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Finish")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 4,
                      expression: "step === 4",
                    },
                  ],
                  staticClass: "step step--4 step--done",
                },
                [
                  _c("top-image-content", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/onboarding/step-done.png"),
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Fantastic!"))),
                  ]),
                  _c("div", { staticClass: "textual" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.tr(
                            "From now on, with the help of <span class='primary'>Infinite∞Buzz</span>, you can easily protect your social media profiles from harmful comments."
                          )
                        ),
                      },
                    }),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "Remember, if you get stuck at any time, we are available to help you."
                          )
                        )
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "Now start your new cleaner, safer, and more positive social media experience!"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "bottom-button" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button primary",
                          attrs: { to: "/moderation" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.tr("Managing moderation")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 1 && _vm.step <= 3,
                    expression: "step >= 1 && step <= 3",
                  },
                ],
                staticClass: "dots",
              },
              [
                _c("div", { class: { dot: true, active: _vm.step >= 1 } }),
                _c("div", { class: { dot: true, active: _vm.step >= 2 } }),
                _c("div", { class: { dot: true, active: _vm.step >= 3 } }),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }