<template>
  <div :class="['read-more-text-component', isActiveClass]">
    <div v-html="text" />
    <span @click="readMore()">{{ readMoreText }}</span>
  </div>
</template>

<script>

export default {
  name: 'ReadMoreText',
  props: {
    text: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    isActiveClass() {
      return this.isActive ? 'active' : false;
    },
    readMoreText() {
      return this.isActive ? this.tr('Close') : this.tr('Read more');
    },
  },
  methods: {
    readMore() {
      return !this.isActive ? this.isActive = true : this.isActive = false;
    },
  },
};
</script>

