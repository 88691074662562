<template>
  <div class="language-selector-component">
    <dropdown-menu v-model="isOpen" :interactive="true" transition="slide-bottom-to-up">
      <div class="main">
        <i class="icon globe" />
        <label>
          {{ currentLanguageLabel }}
        </label>
        <i class="icon arrow-right dark-gray" />
      </div>
      <div slot="dropdown">
        <div
          v-for="(label, langcode) of availableLanguages"
          :key="'language-selector-option-' + langcode"
          @click="() => select(langcode)"
        >
          {{ label }}
        </div>
      </div>
    </dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';

export default {
  name: 'LanguageSelector',
  components: {
    DropdownMenu,
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    currentLanguage() {
      return this.$store.getters['app/language'];
    },
    availableLanguages() {
      return this.$store.getters['app/availableLanguages'];
    },
    currentLanguageLabel() {
      return this.availableLanguages[this.currentLanguage];
    },
  },
  methods: {
    select(langcode) {
      this.$store.commit('app/language', langcode);
      this.isOpen = false;
    },
  },
};
</script>
