<template>
  <div class="settings-organization-view">
    <form-block :title="tr('Organization details')">
      <div>
        <label class="input-label">{{ tr('Organization name') }}</label>
        <input type="text" v-model="model.name" />
      </div>

      <div>
        <label class="input-label">{{ tr('Time zone') }}</label>
        <v-select
          v-model="model.timezone"
          :reduce="(obj) => obj.name"
          :options="timezones"
        />
      </div>

      <div>
        <label class="input-label">{{ tr('Required two-factor authentication') }}</label>
        <label>
          <input
            v-model="model.required_2fa"
            type="checkbox"
            :disabled="!$root.loggedInUser.google_2fa_enabled"
            />
          {{ tr('Require 2FA from users in the organization') }}
        </label>
      </div>

      <info-text v-if="!model.required_2fa && !$root.loggedInUser.google_2fa_enabled">
        {{ tr('To enable this function, you must first enable two-factor authentication in your own user account on the Account page.') }}
      </info-text>
    </form-block>

    <form-block
      :title="tr('Delete organization')"
      :description="deleteDescription"
    >
      <button
        class="button red"
        @click="() => openDeleteConfirmDialog()"
      >
        {{ tr('Delete') }}
      </button>
    </form-block>
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock';
import InfoText from '@/components/InfoText.vue';

import Company from '@/entities/Company';
import CompanyService from '@/services/Company.js';

export default {
  name: 'SettingsCompanyView',
  components: {
    FormBlock,
    InfoText,
  },
  data:() => ({
    model: null,
    isLoading: false,
  }),
  computed: {
    loggedInUserCompany() {
      return this.$root.currentCompany;
    },
    timezones() {
      return this.$store.getters['app/timezones'];
    },
    deleteDescription() {
      if (this.$app.isAd) {
        return this.tr('By pressing the button, the entire organization and all data related to it will be deleted from the Infinite∞Ad, including automations, users, connected profiles and assets.');
      }
      if (this.$app.isBuzz) {
        return this.tr('By pressing the button, the entire organization and all data related to it will be deleted from the Infinit∞Buzz, including users, connected profiles and assets.');
      }

      return '';
    },
  },
  created() {
    this.model = new Company(this.loggedInUserCompany);
  },
  methods: {
    save() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      CompanyService.update(this.$root.currentCompany.id, this.model)
        .then(resp => {
          this.$store.commit('auth/updateCompany', resp);
          this.$toast.success(this.tr('Successful save'));
        })
        .catch((err) => this.error(err))
        .finally(() => this.isLoading = false);
    },
    openDeleteConfirmDialog() {
      this.$confirmation.open({
        title: this.tr('Are you sure you want to delete the organization?'),
        content: this.tr('The deletion is final and cannot be revoked.'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        confirmClass: 'red',
        confirm: () => this.delete(),
      });
    },
    delete() {
      return CompanyService.delete(this.$root.currentCompany.id)
      .then(() => {
          this.$store.commit('auth/logout');
          this.$router.replace('/auth/login');
          this.$toast.success(this.tr('The organization was successfully deleted.'));
        })
    },
  },
};
</script>
