import Api from '@/services/Api';

import PaymentHistory from '@/entities/PaymentHistory.js';

export default {
  get(id) {
    return Api().get('/payment-history/' + id)
      .then((resp) => {
        return new PaymentHistory(resp)
      });
  },

  getList({ page, limit }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/payment-histories?' + query)
      .then((resp) => {
        return resp.map(i => new PaymentHistory(i));
      });
  },

  generatePaymentIntent(id) {
    return Api().post('/payment-history/' + id + '/generate-payment-intent');
  },

  downloadInvoice(id, filename) {
    return Api().get('/payment-history/' + id + '/download-invoice', { responseType: 'blob' })
      .then(response => {
        const href = URL.createObjectURL(response);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  },
};
