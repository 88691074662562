<template>
  <div class="report-ad-table-component">
    <div v-if="isListEmpty && !isLoading" class="empty-text">
      <template v-if="campaignId !== null">
        {{ tr('There are currently no active ads in this campaign.') }}
      </template>
      <template v-else>
        {{ tr('There are currently no active ads in this ad account.') }}
      </template>
    </div>

    <div v-else class="horizontal-scrollable-table-wrapper">
      <sorted-table
        :values="list"
        :onSort="sortTable"
        :sort="orderBy"
        :dir="order"
        ascIcon="<i class='icon uncolored sort-active' style='transform: rotate(180deg);'/>"
        descIcon="<i class='icon uncolored sort-active' />"
      >
        <thead>
          <tr>
            <th></th>
            <th scope="col" style="width: 100%">
              <sort-link name="name">
                {{ tr('Name') }}
                <i v-if="orderBy !== 'name'" class="icon uncolored sort-inactive" />
              </sort-link>
            </th>
            <th scope="col" class="number">
              <sort-link name="metric.reach">
                {{ tr('Reach') }}
                <i v-if="orderBy !== 'metric.reach'" class="icon uncolored sort-inactive" />
              </sort-link>
            </th>
            <th scope="col" class="number">
              <sort-link :name="'metric.actions.' + action">
                {{ tr('Actions') }}
                <i v-if="orderBy !== 'metric.actions.' + action" class="icon uncolored sort-inactive" />
              </sort-link>
            </th>
            <th scope="col" class="number">
              <sort-link :name="'metric.cost_per_action_type.' + action">
                {{ tr('CPA') }}
                <i v-if="orderBy !== 'metric.cost_per_action_type.' + action" class="icon uncolored sort-inactive" />
              </sort-link>
            </th>
            <th scope="col" class="number">
              <sort-link name="metric.spend">
                {{ tr('Spend') }}
                <i v-if="orderBy !== 'metric.spend'" class="icon uncolored sort-inactive" />
              </sort-link>
            </th>
            <th scope="col" class="number">
              <sort-link name="created_at">
                {{ tr('Date created') }}
                <i v-if="orderBy !== 'created_at'" class="icon uncolored sort-inactive" />
              </sort-link>
            </th>
          </tr>
        </thead>
        <template #body="sort">
          <tbody>
            <template v-for="ad in sort.values">
              <tr :key="'report-ad-table--ad-' + ad.external_id">
                <td>
                  <div :class="{ 'status': true, 'green': ad.effective_status === 1, 'red': ad.effective_status !== 1 }" />
                </td>
                <td>
                  <div class="ad-name" @click="() => toggle(ad.external_id)">
                    {{ ad.name }}
                  </div>
                </td>
                <td class="number">
                  {{ ad.metric ? formatNumber(ad.metric.reach) : '-' }}
                </td>
                <td class="number">
                  {{ ad.metric && ad.metric.actions ? formatNumber(ad.metric.actions[action]) : '-' }}
                </td>
                <td class="number">
                  {{ formatNumber(calculateCpa(ad), currency, 3) }}
                </td>
                <td class="number">
                  {{ ad.metric ? formatNumber(ad.metric.spend, currency) : '-' }}
                </td>
                <td class="number">
                  {{ formatToYMD(ad.created_at) }}<br/>{{ formatTime(ad.created_at) }}
                </td>

                <td />
              </tr>

              <tr :key="'report-ad-table--ad-' + ad.external_id + '--details'" class="details-row">
                <td />
                <td colspan="6">
                  <transition-expand>
                    <div v-show="openedAd === ad.external_id">
                      <report-ad-details
                        v-if="renderedAdDetails.includes(ad.external_id)"
                        :token-part1="tokenPart1"
                        :token-part2="tokenPart2"
                        :ad-account-id="adAccountId"
                        :ad="ad"
                      />
                    </div>
                  </transition-expand>
                </td>
                <td />
              </tr>
            </template>

            <template v-if="hasMore">
              <tr v-for="i in (isListEmpty ? [ 1, 2, 3 ] : [ 1, 2 ])" :key="'post-copy-analysis-loader-skeleton--' + i" ref="loadMore">
                <td>
                  <skeleton width="10px" height="10px" />
                </td>
                <td>
                  <div class="ad-name">
                    <skeleton />
                    <skeleton width="80%" />
                  </div>
                </td>
                <td class="number">
                  <skeleton width="40px" />
                </td>
                <td class="number">
                  <skeleton width="40px" />
                </td>
                <td class="number">
                  <skeleton width="40px" />
                </td>
                <td class="number">
                  <skeleton width="40px" />
                </td>
                <td class="number">
                  <skeleton width="70px" />
                  <skeleton width="60px" />
                </td>

                <td />
              </tr>
            </template>
          </tbody>
        </template>
      </sorted-table>
    </div>
  </div>
</template>

<script>
export const ITEM_PER_PAGE = 20;

import ReportAdDetails from '@/components/ReportAdDetails.vue';
import Skeleton from '@/components/Skeleton.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

import ReportService from '@/services/Report';

import debounce from '@/utils/debounce.js';
import { formatNumber } from '@/utils/number.js';
import { formatToYMD, formatTime } from '@/utils/date';
import { ucfirst } from '@/utils/string';

export default {
  name: 'ReportAdsTable',
  props: {
    tokenPart1: {
      required: false,
      default: () => null,
    },
    tokenPart2: {
      required: false,
      default: () => null,
    },
    adAccountId: {
      type: String,
      required: true,
    },
    isActiveFilter: {
      type: Boolean,
      required: false,
    },
    campaignId: {
      required: false,
      default: () => null,
    },
    action: {
      type: String || null,
      required: false,
      default: () => null,
    },
    period: {
      type: String || null,
      required: false,
      default: () => null,
    },
    currency: {
      type: String,
      required: false,
      default: () => '$',
    },
  },
  components: {
    ReportAdDetails,
    Skeleton,
    TransitionExpand,
  },
  data() {
    return {
      isLoading: false,
      hasMore: true,
      page: 0,
      list: [],
      debouncedLoadData: debounce(() => { this.reset(); this.loadNewData(); }, 100),
      openedAd: null,
      renderedAdDetails: [],
      removerTimers: {},
      order: 'desc',
      orderBy: 'created_at',
    };
  },
  computed: {
    isListEmpty() {
      return this.list.length == 0;
    },
  },
  watch: {
    adAccountId() {
      this.debouncedLoadData();
    },
    campaignId() {
      this.debouncedLoadData();
    },
    period() {
      this.debouncedLoadData();
    },
    isActiveFilter() {
      this.debouncedLoadData();
    },
  },
  created() {
    this.loadNewData();
  },
  methods: {
    formatTime,
    formatToYMD,
    formatNumber,
    ucfirst,
    reset() {
      this.isLoading = false;
      this.hasMore = true;
      this.page = 0;
      this.list = [];
    },
    sortTable(field, order) {
      this.orderBy = field;
      this.order = order;

      this.reset();
      this.loadNewData();
    },
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }
      if (!this.adAccountId || !this.period) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        tokenPart1: this.tokenPart1,
        tokenPart2: this.tokenPart2,
        adAccountId: this.adAccountId,
        campaignId: this.campaignId,
        period: this.period,
        isActive: this.isActiveFilter,
        page: this.page,
        limit: ITEM_PER_PAGE,
        orderBy: this.orderBy,
        order: this.order,
      };

      ReportService.getAds(params)
        .then(resp => {
          this.hasMore = resp.length === ITEM_PER_PAGE;
          this.list.push(...resp);
        })
        .catch((err) => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0]);
      }
    },
    calculateCpa(ad) {
      if (
        !ad
        || !ad.metric
        || !ad.metric.spend
        || !ad.metric.actions
        || !ad.metric.actions[this.action]
      ) {
        return 0;
      }

      return ad.metric.spend / ad.metric.actions[this.action];
    },
    toggle(adId) {
      if (adId in this.removerTimers) {
        clearTimeout(this.removerTimers[adId]);
        delete this.removerTimers[adId];
      }

      const previous = this.openedAd;

      if (adId !== this.openedAd) {
        this.renderedAdDetails.push(adId);
        setTimeout(() => this.openedAd = adId, 50);
      }
      else {
        this.openedAd = null;
      }

      if (previous) {
        this.removerTimers[previous] = setTimeout(() => {
          this.renderedAdDetails = this.renderedAdDetails.filter(item => item !== previous);
          delete this.removerTimers[previous];
        }, 1000);
      }
    },
  }
};
</script>
