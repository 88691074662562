var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "condition-row" }, [
    _c(
      "div",
      { staticClass: "bordered" },
      [
        _vm._m(0),
        _c("v-select", {
          attrs: {
            options: _vm.fieldList,
            reduce: (obj) => obj.machine_name,
            searchable: true,
            clearable: false,
            placeholder: _vm.tr("Select filter options"),
            disabled: _vm.disabled,
            "calculate-position": _vm.pimpedSelectPositionCalculator,
          },
          on: {
            "option:selecting": _vm.onSelectField,
            "option:selected": () =>
              _vm.$nextTick(() => (_vm.changingManually = false)),
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (field) {
                return [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.left",
                          value: field.tooltip,
                          expression: "field.tooltip",
                          modifiers: { left: true },
                        },
                      ],
                      class: { "disabled-option": field.disabled },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr(field.label)) + " ")]
                  ),
                ]
              },
            },
            {
              key: "selected-option",
              fn: function (field) {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.right",
                          value: field.tooltip,
                          expression: "field.tooltip",
                          modifiers: { right: true },
                        },
                      ],
                      class: { "disabled-option": field.disabled },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.tr(field.label)) + " "),
                      field.disabled
                        ? _c("i", { staticClass: "icon warning red" })
                        : _vm._e(),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.value.field,
            callback: function ($$v) {
              _vm.$set(_vm.value, "field", $$v)
            },
            expression: "value.field",
          },
        }),
        _vm.showSubfields
          ? [
              _vm.loadingSubfields
                ? _c("v-select", {
                    attrs: { placeholder: _vm.tr("Loading..."), disabled: "" },
                  })
                : _c("v-select", {
                    attrs: {
                      options: _vm.subfields,
                      reduce: (obj) => obj.machine_name,
                      searchable: true,
                      clearable: false,
                      placeholder: _vm.tr("Select an action type"),
                      disabled: _vm.disabled,
                      loading: _vm.loadingSubfields,
                      "calculate-position": _vm.pimpedSelectPositionCalculator,
                    },
                    model: {
                      value: _vm.value.subfield,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "subfield", $$v)
                      },
                      expression: "value.subfield",
                    },
                  }),
            ]
          : _vm._e(),
        _vm.showDatePeriods
          ? _c("v-select", {
              attrs: {
                options: _vm.datePeriods,
                reduce: (obj) => obj.machine_name,
                searchable: false,
                clearable: false,
                disabled: _vm.disabled,
              },
              model: {
                value: _vm.value.date_period,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "date_period", $$v)
                },
                expression: "value.date_period",
              },
            })
          : _vm._e(),
        _c("v-select", {
          staticClass: "comparison",
          attrs: {
            options: _vm.operatorOptions,
            reduce: (obj) => obj.machine_name,
            searchable: false,
            clearable: false,
            disabled: _vm.disabled || _vm.disabledComparisonSelect,
            placeholder: _vm.tr("Select conditional rule"),
          },
          model: {
            value: _vm.value.comparison,
            callback: function ($$v) {
              _vm.$set(_vm.value, "comparison", $$v)
            },
            expression: "value.comparison",
          },
        }),
        _c(
          "div",
          { staticClass: "input" },
          [
            _vm.isDynamicOperator
              ? _c(
                  "div",
                  { staticClass: "dynamic-value" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.value,
                          expression: "value.value",
                        },
                      ],
                      attrs: {
                        type: "number",
                        step: "1",
                        min: "1",
                        max: "100",
                        disabled: _vm.disabled || _vm.disabledValueInput,
                      },
                      domProps: { value: _vm.value.value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.value, "value", $event.target.value)
                        },
                      },
                    }),
                    _c("v-select", {
                      staticClass: "value-setting-select",
                      attrs: {
                        options: _vm.dynamicOperatorValueOptions,
                        reduce: (obj) => obj.machine_name,
                        searchable: false,
                        clearable: false,
                        disabled: _vm.disabled || _vm.disabledValueInput,
                      },
                      model: {
                        value: _vm.value.settings.dynamic_operator_direction,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.value.settings,
                            "dynamic_operator_direction",
                            $$v
                          )
                        },
                        expression: "value.settings.dynamic_operator_direction",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "area-select",
                      attrs: {
                        options: _vm.dynamicOperatorAreas,
                        reduce: (obj) => obj.machine_name,
                        searchable: false,
                        clearable: false,
                        disabled: _vm.disabled || _vm.disabledValueInput,
                      },
                      model: {
                        value: _vm.value.settings.dynamic_operator_area,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.value.settings,
                            "dynamic_operator_area",
                            $$v
                          )
                        },
                        expression: "value.settings.dynamic_operator_area",
                      },
                    }),
                  ],
                  1
                )
              : this.isDataTypeString
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value.value,
                      expression: "value.value",
                    },
                  ],
                  attrs: {
                    type: "text",
                    placeholder: _vm.tr("Set filter text"),
                    disabled: _vm.disabled || _vm.disabledValueInput,
                  },
                  domProps: { value: _vm.value.value },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.value, "value", $event.target.value)
                    },
                  },
                })
              : this.isDataTypeInt
              ? _c("number-input", {
                  attrs: {
                    step: 1,
                    min: 0,
                    placeholder: _vm.tr("Set number on filter (days etc.)"),
                    disabled: _vm.disabled || _vm.disabledValueInput,
                  },
                  model: {
                    value: _vm.value.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "value", $$v)
                    },
                    expression: "value.value",
                  },
                })
              : _vm.isDataTypeFloat
              ? _c("number-input", {
                  attrs: {
                    step: 0.001,
                    min: 0,
                    placeholder: _vm.tr("Set currency on filter"),
                    disabled: _vm.disabled || _vm.disabledValueInput,
                  },
                  model: {
                    value: _vm.value.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "value", $$v)
                    },
                    expression: "value.value",
                  },
                })
              : _vm.isDataTypeMoney
              ? _c("money-input", {
                  attrs: {
                    disabled: _vm.disabled || _vm.disabledValueInput,
                    currency: _vm.entity.adAccountCurrency
                      ? _vm.entity.adAccountCurrency.code
                      : "USD",
                  },
                  model: {
                    value: _vm.value.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "value", $$v)
                    },
                    expression: "value.value",
                  },
                })
              : _c("input", {
                  attrs: {
                    type: "text",
                    disabled: "",
                    placeholder: _vm.tr("No value option for current filter"),
                  },
                }),
          ],
          1
        ),
      ],
      2
    ),
    _vm.removable && !_vm.disabled
      ? _c("i", {
          staticClass: "i icon delete",
          on: {
            click: function ($event) {
              return _vm.$emit("remove")
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-container" }, [
      _c("i", { staticClass: "i icon dots" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }