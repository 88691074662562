var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-ad-table-component" }, [
    _vm.isListEmpty && !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "empty-text" },
          [
            _vm.campaignId !== null
              ? [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "There are currently no active ads in this campaign."
                        )
                      ) +
                      " "
                  ),
                ]
              : [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "There are currently no active ads in this ad account."
                        )
                      ) +
                      " "
                  ),
                ],
          ],
          2
        )
      : _c(
          "div",
          { staticClass: "horizontal-scrollable-table-wrapper" },
          [
            _c(
              "sorted-table",
              {
                attrs: {
                  values: _vm.list,
                  onSort: _vm.sortTable,
                  sort: _vm.orderBy,
                  dir: _vm.order,
                  ascIcon:
                    "<i class='icon uncolored sort-active' style='transform: rotate(180deg);'/>",
                  descIcon: "<i class='icon uncolored sort-active' />",
                },
                scopedSlots: _vm._u([
                  {
                    key: "body",
                    fn: function (sort) {
                      return [
                        _c(
                          "tbody",
                          [
                            _vm._l(sort.values, function (ad) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key:
                                      "report-ad-table--ad-" + ad.external_id,
                                  },
                                  [
                                    _c("td", [
                                      _c("div", {
                                        class: {
                                          status: true,
                                          green: ad.effective_status === 1,
                                          red: ad.effective_status !== 1,
                                        },
                                      }),
                                    ]),
                                    _c("td", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ad-name",
                                          on: {
                                            click: () =>
                                              _vm.toggle(ad.external_id),
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(ad.name) + " ")]
                                      ),
                                    ]),
                                    _c("td", { staticClass: "number" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            ad.metric
                                              ? _vm.formatNumber(
                                                  ad.metric.reach
                                                )
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "number" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            ad.metric && ad.metric.actions
                                              ? _vm.formatNumber(
                                                  ad.metric.actions[_vm.action]
                                                )
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "number" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              _vm.calculateCpa(ad),
                                              _vm.currency,
                                              3
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "number" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            ad.metric
                                              ? _vm.formatNumber(
                                                  ad.metric.spend,
                                                  _vm.currency
                                                )
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("td", { staticClass: "number" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatToYMD(ad.created_at))
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(_vm.formatTime(ad.created_at)) +
                                          " "
                                      ),
                                    ]),
                                    _c("td"),
                                  ]
                                ),
                                _c(
                                  "tr",
                                  {
                                    key:
                                      "report-ad-table--ad-" +
                                      ad.external_id +
                                      "--details",
                                    staticClass: "details-row",
                                  },
                                  [
                                    _c("td"),
                                    _c(
                                      "td",
                                      { attrs: { colspan: "6" } },
                                      [
                                        _c("transition-expand", [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.openedAd ===
                                                    ad.external_id,
                                                  expression:
                                                    "openedAd === ad.external_id",
                                                },
                                              ],
                                            },
                                            [
                                              _vm.renderedAdDetails.includes(
                                                ad.external_id
                                              )
                                                ? _c("report-ad-details", {
                                                    attrs: {
                                                      "token-part1":
                                                        _vm.tokenPart1,
                                                      "token-part2":
                                                        _vm.tokenPart2,
                                                      "ad-account-id":
                                                        _vm.adAccountId,
                                                      ad: ad,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("td"),
                                  ]
                                ),
                              ]
                            }),
                            _vm.hasMore
                              ? _vm._l(
                                  _vm.isListEmpty ? [1, 2, 3] : [1, 2],
                                  function (i) {
                                    return _c(
                                      "tr",
                                      {
                                        key:
                                          "post-copy-analysis-loader-skeleton--" +
                                          i,
                                        ref: "loadMore",
                                        refInFor: true,
                                      },
                                      [
                                        _c(
                                          "td",
                                          [
                                            _c("skeleton", {
                                              attrs: {
                                                width: "10px",
                                                height: "10px",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          _c(
                                            "div",
                                            { staticClass: "ad-name" },
                                            [
                                              _c("skeleton"),
                                              _c("skeleton", {
                                                attrs: { width: "80%" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "number" },
                                          [
                                            _c("skeleton", {
                                              attrs: { width: "40px" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "number" },
                                          [
                                            _c("skeleton", {
                                              attrs: { width: "40px" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "number" },
                                          [
                                            _c("skeleton", {
                                              attrs: { width: "40px" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "number" },
                                          [
                                            _c("skeleton", {
                                              attrs: { width: "40px" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "number" },
                                          [
                                            _c("skeleton", {
                                              attrs: { width: "70px" },
                                            }),
                                            _c("skeleton", {
                                              attrs: { width: "60px" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("td"),
                                      ]
                                    )
                                  }
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _c(
                      "th",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { scope: "col" },
                      },
                      [
                        _c("sort-link", { attrs: { name: "name" } }, [
                          _vm._v(" " + _vm._s(_vm.tr("Name")) + " "),
                          _vm.orderBy !== "name"
                            ? _c("i", {
                                staticClass: "icon uncolored sort-inactive",
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      { staticClass: "number", attrs: { scope: "col" } },
                      [
                        _c("sort-link", { attrs: { name: "metric.reach" } }, [
                          _vm._v(" " + _vm._s(_vm.tr("Reach")) + " "),
                          _vm.orderBy !== "metric.reach"
                            ? _c("i", {
                                staticClass: "icon uncolored sort-inactive",
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      { staticClass: "number", attrs: { scope: "col" } },
                      [
                        _c(
                          "sort-link",
                          { attrs: { name: "metric.actions." + _vm.action } },
                          [
                            _vm._v(" " + _vm._s(_vm.tr("Actions")) + " "),
                            _vm.orderBy !== "metric.actions." + _vm.action
                              ? _c("i", {
                                  staticClass: "icon uncolored sort-inactive",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      { staticClass: "number", attrs: { scope: "col" } },
                      [
                        _c(
                          "sort-link",
                          {
                            attrs: {
                              name: "metric.cost_per_action_type." + _vm.action,
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.tr("CPA")) + " "),
                            _vm.orderBy !==
                            "metric.cost_per_action_type." + _vm.action
                              ? _c("i", {
                                  staticClass: "icon uncolored sort-inactive",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      { staticClass: "number", attrs: { scope: "col" } },
                      [
                        _c("sort-link", { attrs: { name: "metric.spend" } }, [
                          _vm._v(" " + _vm._s(_vm.tr("Spend")) + " "),
                          _vm.orderBy !== "metric.spend"
                            ? _c("i", {
                                staticClass: "icon uncolored sort-inactive",
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      { staticClass: "number", attrs: { scope: "col" } },
                      [
                        _c("sort-link", { attrs: { name: "created_at" } }, [
                          _vm._v(" " + _vm._s(_vm.tr("Date created")) + " "),
                          _vm.orderBy !== "created_at"
                            ? _c("i", {
                                staticClass: "icon uncolored sort-inactive",
                              })
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }