<template>
  <div class="facebook-post-preview-component post-preview">
    <div class="top">
      <div class="profile">
        <lazy-image
          v-if="post && post.source_profile.picture"
          :src="post.source_profile.picture"
          class="image"
        />
        <div class="profile-data">
          <div class="name">
            {{ post.source_profile.name }}
          </div>
          <a
            :href="post.url"
            target="_blank"
            class="date"
          >
            {{ formattedDate(post.created_at) }} {{ formatTime(post.created_at) }}
          </a>
        </div>
      </div>
      <div v-if="post.text" class="main-text" v-html="post.text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
    </div>
    <div v-if="post.attachments" :class="post.media_type" class="attachment">
      <lazy-image
        v-if="post.attachments[0].media.image && (post.media_type === 'photo' || post.media_type === 'link')"
        :src="post.attachments[0].media.image.src"
      />
      <video
        v-if="post.attachments[0].media.source"
        :src="post.attachments[0].media.source"
        autoplay
        loop
        muted
      ></video>
      <div
        v-if="post.media_type === 'link'"
        class="link-details"
      >
        <div
          v-if="extractedDomain"
          class="domain"
        >
          {{ extractedDomain }}
        </div>
        <div
          v-if="post.attachments[0].title"
          class="title"
        >
          {{ post.attachments[0].title }}
        </div>
        <div
          v-if="post.attachments[0].description"
          class="description"
        >
          {{ post.attachments[0].description }}
        </div>
      </div>
    </div>
    <div class="social">
      <div v-if="post.metric.reactions>0" class="reactions">
        <img v-if="post.metric.reactions_like>0" src="~@/assets/img/icons/fb-like.png" />
        <img v-if="post.metric.reactions_love>0" src="~@/assets/img/icons/fb-love.png" />
        <img v-if="post.metric.reactions_haha>0" src="~@/assets/img/icons/fb-haha.png" />
        <img v-if="post.metric.reactions_wow>0" src="~@/assets/img/icons/fb-wow.png" />
        <img v-if="post.metric.reactions_sad>0" src="~@/assets/img/icons/fb-sad.png" />
        <img v-if="post.metric.reactions_angry>0" src="~@/assets/img/icons/fb-angry.png" />
        <span>
          {{ post.metric.reactions }}
        </span>
      </div>
      <div v-if="commentsStr" class="comments">
        {{ commentsStr }}
      </div>
      <div v-if="shareStr" class="shares">
        {{ shareStr }}
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue';
import { formatTime, formatToStringMD } from '@/utils/date.js';

export default {
  components: {
    LazyImage,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      extractedDomain: '',
    };
  },
  computed: {
    commentsStr() {
      if (this.post.comment_count === 1) {
        return this.post.comment_count + ' ' + this.tr('comment');
      }
      else if (this.post.comment_count > 1) {
        return this.post.comment_count + ' ' + this.tr('comments');
      }

      return null;
    },
    shareStr() {
      if (this.post.metric.shares === 1) {
        return this.post.metric.shares + ' ' + this.tr('share');
      }
      else if (this.post.metric.shares > 1) {
        return this.post.metric.shares + ' ' + this.tr('shares');
      }

      return null;
    },
  },
  mounted() {
    this.extractedDomain = this.getDomainFromURL(this.post.attachments[0].target.url);
  },
  methods: {
    formatTime,
    formattedDate(date) {
      return formatToStringMD(date, this.tr);
    },
    getDomainFromURL(fullUrl) {
      try {
        const urlParams = new URL(fullUrl);
        const queryString = urlParams.searchParams.get('u');
        if (queryString) {
          const decodedUrl = decodeURIComponent(queryString);
          const hostname = new URL(decodedUrl).hostname.replace(/^www\./, '');
          return hostname;
        }
      }
      catch (error) {
        this.error(this.tr('Url parsing error'), error);
      }

      return '';
    },
  },
};
</script>

