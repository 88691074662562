<template>
  <div class="rule-list-view">
    <sticky-header
      :title="tr('Automations')"
      :info="tr('Unlock the Full Power of Your Social Media with Advanced Automations. 🚀 Seamlessly manage and optimize your Facebook and Instagram ads with automations for creation, scaling and stopping. 💪')"
      bordered
    />

    <system-level-notifications space-top />

    <empty-screen
      v-if="!hasConnectedProfileInCompany"
      icon="play"
      :title="tr('Connect your accounts to start automating')"
      :flag-text="tr('Connect profiles')"
      :description="tr('To begin using our AI-powered automation features, you\'ll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising.')"
      @click="() => openOnboardingModal()"
    />

    <empty-screen
      v-if="showNewRuleCards && isListWithoutFiltersEmpty && !listIsLoading"
      icon="rocket"
      :title="tr('Add your first advertising automation and start growing')"
      :flag-text="tr('Start creating automations')"
      :description="tr('Create AI-powered ad automation rules that will manage your post\'s promotion. Set up your first automation rule, define your goals and budget, and let our AI optimize your advertising campaigns automatically.')"
    />
    <transition-expand>
      <create-new-rule-cards v-if="showNewRuleCards" />
    </transition-expand>
    <button
      v-if="showNewRuleCards"
      class="primary create-new-automation-button"
      @click="openCreateNewRuleModal"
    >
      + {{ tr('Create new automation') }}
    </button>

    <div v-if="showFilters" class="filters">
      <rule-action-filter :disabled="listIsLoading" />

      <input
        v-model="searchTerm"
        type="text"
        class="term-filter icon--search"
        :placeholder="tr('Search') + '...'"
      />
      <v-select
        v-model="adAccountId"
        :options="adAccountOptions"
        :reduce="(item) => item.id"
        label="name"
        class="ad-account-select"
      />
      <v-select
        v-model="statusFilter"
        :options="statusOptions"
        :reduce="(item) => item.value"
        :searchable="false"
        class="status-select"
      />

      <dropdown-menu v-model="filtersIsOpen" transition="slide-top-to-bottom" interactive>
        <button class="filter-button white">
          <i class="icon adjustments light-black" />
          {{ tr('Filters') }}
        </button>

        <div slot="dropdown">
          <div class="filter-input-block">
            <label>{{ tr('Status') }}</label>
            <v-select
              v-model="statusFilter"
              :options="statusOptions"
              :reduce="(item) => item.value"
              :searchable="false"
              class="status-select"
            />
          </div>

          <div class="filter-input-block">
            <label>{{ tr('Ad Account') }}</label>
            <v-select
              v-model="adAccountId"
              :options="adAccountOptions"
              :reduce="(item) => item.id"
              label="name"
              class="ad-account-select"
            />
          </div>
        </div>
      </dropdown-menu>
    </div>

    <div v-if="!listIsLoading && isListEmpty">
      <empty-screen
        v-if="!isEmptyFilters"
        icon="sad"
        :title="tr('No automations match your current filters')"
        :flag-text="tr('Adjust filters')"
        :description="tr('We couldn\'t find any automations that match your selected filters. Try adjusting your filter settings to see more results in your automation list.')"
      />
    </div>

    <sorted-table
      v-else
      :data="list"
      :initial-sort="{ key: orderBy, direction: order }"
      @sort="sortTable"
    >
      <template #header>
        <table-head col="status" width="50px" />
        <table-head col="automation-type" width="35px" />
        <table-head col="name" sortable>{{ tr('Name') }}</table-head>
        <table-head col="last-run" width="130px">{{ tr('Last Run') }}</table-head>
        <table-head col="created_at" width="150px" sortable>{{ tr('Date created') }}</table-head>
        <table-head col="triggered" width="90px" class="center">{{ tr('Task Done') }}</table-head>
        <table-head col="actions" width="30px" class="center"></table-head>
      </template>

      <template #default="{ sort }">
        <table-row
          v-for="row in sort"
          :key="row.id"
          :header-color="'rule-header--' + row.action"
          :class="['card--' + row.action, rowClasses(row)]"
        >
          <table-data col="status" class="status-container">
            <switches
              v-model="row.active"
              :disabled="$root.loggedInUser.isRestrictedViewer || $root.isSupportMode || deletedCampaignFlagIsVisible(row)"
              :emit-on-mount="false"
              @input="() => onChangeRuleStatus(row)"
            />
          </table-data>
          <table-data col="automation-type" class="automation-type-container">
            <div class="automation-type-flag">
              <div class="automation-type-flag--icon">
                <i :class="'icon white ' + automationTypeIcon(row.action)" />
              </div>
              <span class="automation-type-flag--label">
                {{ automationTypeLabel(row.action) }}
              </span>
              <i
                v-if="row.group_id !== null"
                class="icon magic-stars "
              />
            </div>
          </table-data>

          <table-data col="name" class="name-container">
            <label>{{ tr('Name') }}</label>
            <access-handled-router-link :to="'/automation/' + row.id + '/overview'">
              {{ row.name }}
            </access-handled-router-link>
            <div v-if="row.ad_account && facebookAdAccountName(row.ad_account.id)" class="flag">
              {{ facebookAdAccountName(row.ad_account.id) }}
            </div>
            <div v-if="disconnectedAdAccountFlagIsVisible(row)" class="flag red">
              {{ tr('Automation Stopped') }} - {{ tr('Disconnected Ad Account') }}
            </div>
            <div v-else-if="deletedCampaignFlagIsVisible(row)" class="flag red">
              {{ tr('Automation Stopped') }} - {{ tr('Campaign Deleted on Ads Manager') }}
            </div>
            <div v-else-if="inactiveCampaignFlagIsVisible(row)" class="flag orange">
              {{ tr('Inactive Campaign') }}
            </div>
          </table-data>

          <table-data col="last-run" class="last-run-content">
            <div class="divider" />
            <label>{{ tr('Last Run') }}</label>

            <div>
              <rule-next-run-tooltip v-if="row.active" :entity="row" class="icon-wrapper">
                <i class="icon clock-filled light-black" />
              </rule-next-run-tooltip>
              <div v-else class="no-clock"></div>
              <div>
                <template v-if="row.last_run">
                  <span v-tooltip="{ content: formatToYMD(row.last_run) + '. ' + formatTime(row.last_run), classes: [ '' ] }">
                    {{ timeAgo(row.last_run, tr) }}
                  </span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
          </table-data>

          <table-data col="created_at" class="date-created-content">
            <div class="divider" />
            <label>{{ tr('Date created') }}</label>

            <div>
              <template v-if="row.created_at">
                <span v-tooltip="{ content: formatToYMD(row.created_at) + '. ' + formatTime(row.created_at), classes: [ '' ] }">
                  {{ timeAgo(row.created_at, tr) }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </div>
          </table-data>

          <table-data col="triggered" class="center triggered">
            <div class="divider" />
            <label>{{ tr('Task done') }}</label>

            {{ row.triggered }}
          </table-data>
          <table-data col="actions" class="center actions-container">
            <navigation-arrow :link="'/automation/' + row.id + '/overview'" :color="row.action" />
          </table-data>
        </table-row>

        <template v-if="listIsLoading || hasMore">
          <table-row
            v-for="action in (isListEmpty ? [ 'start', 'scaling_up', 'stop' ] : [ 'start', 'scaling_up' ])"
            :key="'automations-loader-skeleton--' + action"
            ref="loadMore"
            header-color="white"
          >
            <table-data col="status" class="status-container">
              <switches value="1" disabled style="opacity: 0.5" />
            </table-data>
            <table-data col="automation-type" class="automation-type-container">
              <skeleton
                class="automation-type-flag"
                height="20px"
                min-width="20px"
                max-width="20px"
              />
            </table-data>

            <table-data col="name" class="name-container name-container--skeleton">
              <skeleton
                class="name-skeleton"
                height="17px"
                min-width="200px"
                max-width="400px"
              />
              <skeleton
                class="flag"
                width="70px"
                height="16px"
                radius="5px"
              />
            </table-data>
            <table-data col="last-run" class="last-run-content">
              <div class="divider" />
              <label>{{ tr('Last Run') }}</label>

              <skeleton width="60%" max-width="100px" height="18px" />
            </table-data>
            <table-data col="created_at" class="date-created-content">
              <div class="divider" />
              <label>{{ tr('Date created') }}</label>

              <skeleton width="60%" max-width="100px" height="18px" />
            </table-data>
            <table-data col="triggered" class="center triggered">
              <div class="divider" />
              <label>{{ tr('Task done') }}</label>

              <skeleton width="26px" height="18px" />
            </table-data>
          </table-row>
        </template>
      </template>
    </sorted-table>

    <template v-if="!$root.loggedInUser.isRestrictedViewer && !$root.isSupportMode">
      <transition name="fade">
        <floating-create-new-rule-modal ref="FloatingCreateNewRuleModal" />
      </transition>
    </template>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';
import Switches from 'vue-switches';

import CreateNewRuleCards from '@/components/CreateNewRuleCards.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import FloatingCreateNewRuleModal from '@/components/FloatingCreateNewRuleModal.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import RuleActionFilter from '@/components/RuleActionFilter.vue';
import RuleNextRunTooltip from '@/components/RuleNextRunTooltip.vue';
import Skeleton from '@/components/Skeleton.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import { CAMPAIGN_STATUS } from '@/consts.js';
import DashboardMixin from '@/mixins/Dashboard.js';
import OnboardingAutoOpenMixin from '@/mixins/OnboardingAutoOpen.js';
import RuleSwitchMixin from '@/mixins/RuleSwitch.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import debounce from '@/utils/debounce.js';

export default {
  name: 'RuleListView',
  components: {
    CreateNewRuleCards,
    DropdownMenu,
    EmptyScreen,
    FloatingCreateNewRuleModal,
    NavigationArrow,
    RuleActionFilter,
    RuleNextRunTooltip,
    Skeleton,
    StickyHeader,
    Switches,
    SystemLevelNotifications,
    TransitionExpand,
  },
  mixins: [ DashboardMixin, RuleSwitchMixin, OnboardingAutoOpenMixin ],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.temporaryDisableWatchers();

      if (!from || !from.name || vm.isListEmpty) {
        vm.loadNewData();
      }
      else if (from.path.split('/')[1] !== to.path.split('/')[1]) {
        vm.$store.dispatch('rules/resetView');
        vm.loadNewData();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.temporaryDisableWatchers();

    next();

    this.$store.dispatch('rules/resetView');
    this.loadData();
  },
  data() {
    return {
      disableWatchers: false,
      debouncedLoadData: debounce(() => this.loadData(), 500),
      filtersIsOpen: false,
    };
  },
  computed: {
    dashboardIsEmpty() {
      return this.$store.getters['dashboard/isEmpty'];
    },
    isListEmpty() {
      return this.list.length == 0;
    },
    isListWithoutFiltersEmpty() {
      return !this.$store.getters['rules/hasAnyRules'];
    },
    showNewRuleCards() {
      return !this.$root.loggedInUser.isRestrictedViewer && !this.$root.isSupportMode && this.hasConnectedProfileInCompany;
    },
    showFilters() {
      if (!this.hasConnectedProfileInCompany) {
        return false;
      }

      if (this.listIsLoading) {
        return true;
      }

      if (this.isListWithoutFiltersEmpty) {
        return false;
      }

      return !this.isListEmpty || !this.isEmptyFilters;
    },
    adAccountList() {
      if (!this.$root.currentCompany) {
        return [];
      }

      return this.$root.currentCompany.ad_accounts;
    },
    isEmptyFilters() {
      return this.searchTerm.trim().length === 0
        && this.adAccountId === null
        && this.statusFilter === null
        && this.$store.getters['rules/actionFilterIsEmpty'];
    },
    hasConnectedProfileInCompany() {
      if (!this.$root.currentCompany) {
        return true;
      }

      const profileItems = this.$root.currentCompany.connected_profiles.filter(profile => profile.platform === 'facebook');
      return profileItems.length !== 0;
    },
    adAccountOptions() {
      const options = [ { id: null, name: 'All ad accounts' } ];
      options.push(...this.adAccountList);
      return options;
    },
    statusOptions() {
      return [
        {
          value: null,
          label: 'All statuses',
        },
        {
          value: 1,
          label: 'Active',
        },
        {
          value: 0,
          label: 'Inactive',
        },
      ];
    },
    listIsLoading() {
      return this.$store.getters['rules/isLoading'];
    },
    hasMore() {
      return this.$store.getters['rules/hasMore'];
    },
    list() {
      return this.$store.getters['rules/list'];
    },
    searchTerm: {
      get() {
        return this.$store.getters['rules/searchTerm'];
      },
      set(value) {
        this.$store.commit('rules/setSearchTerm', value);
      },
    },
    actionFilter() {
      return this.$store.getters['rules/actionFilter'];
    },
    adAccountId: {
      get() {
        return this.$store.getters['rules/adAccountId'];
      },
      set(value) {
        this.$store.commit('rules/setAdAccountId', value);
      },
    },
    statusFilter: {
      get() {
        return this.$store.getters['rules/statusFilter'];
      },
      set(value) {
        this.$store.commit('rules/setStatusFilter', value);
      },
    },
    order: {
      get() {
        return this.$store.getters['rules/order'];
      },
      set(value) {
        this.$store.commit('rules/setOrder', value);
      },
    },
    orderBy: {
      get() {
        return this.$store.getters['rules/orderBy'];
      },
      set(value) {
        this.$store.commit('rules/setOrderBy', value);
      },
    },
  },
  watch: {
    searchTerm(val) {
      if (this.disableWatchers) {
        return;
      }

      if (!val || val.trim().length === 0) {
        this.loadData();
      }
      else {
        this.debouncedLoadData();
      }
    },
    actionFilter() {
      if (this.disableWatchers) {
        return;
      }

      this.loadData();
    },
    adAccountId() {
      if (this.disableWatchers) {
        return;
      }

      this.loadData();
    },
    statusFilter() {
      if (this.disableWatchers) {
        return;
      }

      this.loadData();
    },
  },
  created() {
    this.$store.dispatch('dashboard/fetch', this.$root.currentCompany.id).catch(err => this.error(err));
  },
  methods: {
    formatToYMD,
    formatTime,
    timeAgo,
    automationTypeIcon(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return 'blister';

        case 'start':
          return 'rocket';

        case 'scaling_up':
          return 'scaling-up';
      }

      return null;
    },
    automationTypeLabel(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return this.tr('automation_type_stop');

        case 'start':
          return this.tr('automation_type_start');

        case 'scaling_up':
          return this.tr('automation_type_scaling_up');
      }

      return null;
    },
    temporaryDisableWatchers() {
      this.disableWatchers = true;
      setTimeout(() => this.disableWatchers = false, 100);
    },
    facebookAdAccount(id) {
      return this.$root.currentCompany.ad_accounts.find(item => item.id === id);
    },
    facebookAdAccountName(id) {
      const adAccount = this.facebookAdAccount(id);
      return adAccount ? adAccount.name : null;
    },
    loadData() {
      this.$store.dispatch('rules/resetView', true);
      this.loadNewData();
    },
    loadNewData() {
      if (this.listIsLoading || !this.hasConnectedProfileInCompany) {
        return;
      }

      const loadMore = this.$store.dispatch('rules/loadMoreData');
      if (loadMore) {
        loadMore
          .catch(err => this.error(err))
          .finally(() => {
            if (this.hasMore) {
              this.$nextTick(() => this.initInfiniteScroll());
            }
          });
      }
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0].$el);
      }
    },
    sortTable({ key, direction }) {
      this.$store.commit('rules/setOrderBy', key);
      this.$store.commit('rules/setOrder', direction);
      this.loadData();
    },
    deletedCampaignFlagIsVisible(rule) {
      return rule.campaign_status === CAMPAIGN_STATUS.DELETED;
    },
    disconnectedAdAccountFlagIsVisible(rule) {
      const adAccount = this.facebookAdAccount(rule.ad_account.id);
      return !adAccount;
    },
    inactiveCampaignFlagIsVisible(rule) {
      return rule.campaign_status === CAMPAIGN_STATUS.INACTIVE;
    },
    activityManagementTooltipContent(rule) {
      let content = this.tr('Pending Ads');
      if (rule.pending_items_count_in_activity_management) {
        content += '<br><small>(';
        if (rule.pending_items_count_in_activity_management === 1) {
          content += this.tr(':num pending item', { ':num': rule.pending_items_count_in_activity_management });
        }
        else {
          content += this.tr(':num pending items', { ':num': rule.pending_items_count_in_activity_management });
        }
        content += ')</small>';
      }

      return content;
    },
    openCreateNewRuleModal() {
      this.$refs.FloatingCreateNewRuleModal.toggle();
    },
    rowClasses(rule) {
      return {
        'card--ai': rule.group_id !== null,
      };
    },
  },
};
</script>
