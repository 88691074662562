<template>
  <div class="post-copy-analysis-view">
    <div v-if="isLoading" ref="loadMore" class="load-more">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
    <template v-else>
      <info-text v-if="!post.source_profile.has_active_start_rule" class="ai-evaluation-not-allowed">
        {{ tr('The post AI evaluation will not occur until the profile that published it has active Start Automation.') }}
      </info-text>

      <ai-score
        v-else-if="post.ai_evaluation_possible && !post.ai_meta_error"
        :value="post.ai_value"
        label="Post AI Score" />

      <div class="post">
        <div class="top">
          <div class="image">
            <img v-if="post.source_profile.picture" :src="post.source_profile.picture" />
          </div>
          <div class="top-details">
            <div class="source-name">{{ post.source_profile.name }}</div>
            <div class="posted" v-tooltip="formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)">
              {{ timeAgo(post.created_at, tr) }}
            </div>
          </div>
        </div>

        <div v-if="post.text" class="text" v-html="post.text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />

        <a
          v-if="post.url"
          :href="post.url"
          class="post-link"
          target="_blank"
        >
          {{ tr('Go to Post') }}
        </a>
      </div>

      <h3>{{ tr('Post metrics') }}</h3>

      <div class="metrics-wrapper">
        <new-sorted-table :data="[ post.metric ]" disable-scroll>
          <template #header>
            <table-head
              v-for="(field, key) in metricFields"
              :key="'metric-head-' + key"
              :col="field.machine_name"
              minWidth="130px"
            >
              {{ tr(field.label) }}
            </table-head>
          </template>
          <template #default="{ sort }">
            <table-row>
              <table-data
                v-for="(field, key) in metricFields"
                :key="'metric-value-' + key"
                :col="field.machine_name"
              >
                {{ formatNumber(sort[0][field.machine_name], field.machine_name === 'ctr' ? '%' : '') }}
              </table-data>
            </table-row>
          </template>
        </new-sorted-table>
      </div>

      <div class="last-update">
        {{ tr('Data last updated at :time', { ':time': timeAgo(post.source_profile.fetched_at, tr) }) }}
      </div>

      <template v-if="post.source_profile.has_active_start_rule">
        <h3 class="title-with-icon">
          {{ tr('Actuality of the content') }}
          <div
            v-if="(!post.ai_evaluation_possible || post.ai_content_actuality_checked) && !contectActualityFormOpen && !$root.loggedInUser.isRestrictedViewer"
            v-tooltip="tr('Change')"
            class="icon-wrapper"
            @click="() => contectActualityFormOpen = true"
          >
            <i class="icon edit light-black" />
          </div>
        </h3>

        <template v-if="contectActualityFormOpen">
          <div class="inline-input-button">
            <date-input v-model="newContentActuality" time clearable allow-empty />
            <button
              class="primary"
              :disabled="isLoadingContantActualitySave"
              @click="() => updateContentActuality()"
            >
              <i v-if="isLoadingContantActualitySave" class="icon loader white" />
              {{ tr('Save') }}
            </button>
          </div>
        </template>
        <info-text v-else-if="!post.ai_evaluation_possible" class="ai-evaluation-not-possible">
          {{ tr('The text of the post is too short. AI evaluation is not possible.') }}
        </info-text>
        <template v-else>
          <div v-if="!post.ai_content_actuality_checked" class="ai-actuality-checkign">
            <i class="icon loader" /> {{ tr('Checking content actuality...') }}
          </div>
          <template v-else-if="post.ai_content_actuality">
            {{ tr('According to Infinite∞Ai, the content loses its relevance at the following date') }}:<br/>
            {{ formatToYMD(post.ai_content_actuality) }}
            {{ formatTime(post.ai_content_actuality) }}
          </template>
          <template v-else>
            {{ tr('The Infinite∞Ai did not find any element in the content that would tie its actuality to time.') }}
          </template>
          <br/>
        </template>

        <template v-if="post.ai_meta && post.ai_meta.length !== 0">
          <h3>{{ tr('AI Evaluation') }}</h3>
          <copy-evaluation :details="post.ai_meta" />
        </template>

        <div class="buttons">
          <button
            v-if="post.ai_meta && post.ai_meta.length !== 0 && (!post.improved_copy || post.improved_copy.inProgress)"
            class="primary"
            :disabled="improvedCopyInProgress"
            @click="() => improveCopy()"
          >
            <i v-if="improvedCopyInProgress" class="icon loader white" />
            {{ tr('Get improved copy') }}
          </button>

          <button
            v-if="post.platform === 'facebook'"
            class="red"
            @click="() => openDeleteConfirmModal()"
          >
            {{ tr('Delete post') }}
          </button>
        </div>

        <improved-copy-component
          v-if="post.improved_copy && !post.improved_copy.inProgress"
          :entity="post.improved_copy"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import { formatNumber } from '@/utils/number.js';

import AiScore from '@/components/AiScore.vue';
import CopyEvaluation from '@/components/CopyEvaluation.vue';
import DateInput from '@/components/DateInput.vue';
import ImprovedCopyComponent from '@/components/ImprovedCopy.vue';
import InfoText from '@/components/InfoText.vue';

import ImprovedCopy from '@/entities/ImprovedCopy.js';

import MagicToolService from '@/services/MagicTool.js';
import PostService from '@/services/Post.js';

import Post from '@/entities/Post.js';

export default {
  name: 'PostCopyAnalysisView',
  components: {
    AiScore,
    CopyEvaluation,
    DateInput,
    ImprovedCopyComponent,
    InfoText,
  },
  data() {
    return {
      isLoading: true,
      postLoadedFromStore: false,
      post: null,
      fetchTimer: null,

      newContentActuality: null,
      contectActualityFormOpen: false,
      isLoadingContantActualitySave: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    metricFields() {
      return this.$store.getters['app/postMetrics'];
    },
    improvedCopyInProgress() {
      return this.post && this.post.improved_copy && this.post.improved_copy.inProgress;
    },
  },
  created() {
    this.loadData()
  },
  beforeDestroy() {
    this.destroyFetchTimer();
  },
  methods: {
    formatNumber,
    formatTime,
    formatToYMD,
    timeAgo,
    loadData(force) {
      if (!force) {
        this.post = this.$store.getters['postCopyAnalysis/list'].find(post => post.id === this.id);
        this.postLoadedFromStore = !!this.post;
        if (this.postLoadedFromStore) {
          this.isLoading = false;
          this.onUpdated();
          return;
        }
      }

      return PostService.get(this.id)
        .then(resp => this.post = resp)
        .catch((err) => this.error(err))
        .finally(() => {
          this.isLoading = false;
          this.onUpdated();
        });
    },
    onUpdated() {
      this.$nextTick(() => {
        if (this.improvedCopyInProgress) {
          this.initFetchTimer();
        }
      });

      this.newContentActuality = this.post.ai_content_actuality;
    },
    initFetchTimer() {
      if (this.fetchTimer !== null) {
        return;
      }

      this.fetchTimer = setTimeout(() => {
        this.fetchTimer = null;
        this.loadData(true);
      }, 10000);
    },
    destroyFetchTimer() {
      if (this.fetchTimer !== null) {
        clearTimeout(this.fetchTimer);
        this.fetchTimer = null;
      }
    },
    onClickToBack(e, navigate) {
      if (this.postLoadedFromStore) {
        e.preventDefault();
        e.stopPropagation();
        this.$router.go(-1)
      }
      else {
        navigate(e);
      }
    },
    updateContentActuality() {
      if (this.isLoadingContantActualitySave) {
        return;
      }

      this.isLoadingContantActualitySave = true;

      const clonedEntity = new Post(this.post);
      clonedEntity.ai_content_actuality = this.newContentActuality;

      PostService.update(clonedEntity)
        .then(() => {
          this.post.ai_content_actuality = this.newContentActuality;
          this.contectActualityFormOpen = false;

          this.$toast.success(this.tr('The changes have been successfully saved.'));
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoadingContantActualitySave = false);
    },
    improveCopy() {
      this.post.improved_copy = new ImprovedCopy({ status: 1 });

      MagicToolService.improve({ postId: this.post.id })
        .then(resp => {
          this.post.improved_copy = resp;
          this.$toast.success(this.tr('We are already working on it! The evaluation will be completed within 1-2 minutes.'));
          this.initFetchTimer();
        })
        .catch(err => {
          this.post.improved_copy = null;
          this.error(err);
        });
    },
    openDeleteConfirmModal() {
      this.$confirmation.open({
        className: 'delete-confirm',
        image: require('@/assets/img/delete-confirm.png'),
        imageClass: 'delete-confirm-image',
        title: this.tr('Are you sure you want to delete this post?'),
        content: this.tr('The post will also be deleted from Facebook and the process is irreversible.'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        confirm: () => this.delete(),
      });
    },
    delete() {
      return PostService.delete(this.id)
        .then(() => {
          this.$toast.clear();
          this.$toast.success(this.tr('The post was successfully deleted.'));

          this.$store.dispatch('postCopyAnalysis/resetView', true);
          this.$router.replace('/post-copy-analysis/posts');
        })
        .catch(err => this.error(err));
    },
  },
};
</script>
