<template>
  <div :class="'formblock ' + extraClass">
    <div v-if="title || switchable || info" class="formtitle">
      <div class="main">
        <input v-if="switchable" type="checkbox" v-model="checkboxValue" :disabled="disabled">
        <span v-if="title" v-html="title" />
        <i v-if="info" class="icon gray tooltip" v-tooltip.right="info" />
      </div>

      <slot name="top-right" />
    </div>
    <div v-if="description" class="description" v-html="description" />
    <div class="slot" v-show="!switchable || checkboxValue">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormBlock',
  props: {
    title: {
      type: String,
      required: false,
    },
    info: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    switchable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    description: {
      type: String,
      required: false,
    },
    extraClass: {
      type: String,
      required: false,
      default: () => '',
    }
  },
  data() {
    return {
      checkboxValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.checkboxValue = val;
    },
    checkboxValue(val) {
      this.$emit('input', val);
    },
  },
};
</script>
