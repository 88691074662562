var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block adset-name-block-component",
      attrs: {
        title: _vm.tr("Ad set name"),
        description: _vm.tr(
          "This will be the name of the currently created adset. If you are not sure what should be the name of your adset, you can also add different macros that will automatically be filled according to your current ad series settings."
        ),
      },
    },
    [
      _c("div", [
        _c("label", { staticClass: "input-label" }, [
          _vm._v(_vm._s(_vm.tr("Name prefix"))),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.entity.settings.name_prefix,
              expression: "entity.settings.name_prefix",
            },
          ],
          attrs: { type: "text", placeholder: "[Infinite.ad]" },
          domProps: { value: _vm.entity.settings.name_prefix },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.entity.settings, "name_prefix", $event.target.value)
            },
          },
        }),
      ]),
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Ad set name"))),
          ]),
          _c("templateable-input", {
            attrs: {
              templateParts: _vm.adsetNameMacros,
              placeholder: _vm.tr("Set the ad set name"),
              disabled: _vm.disabled,
            },
            model: {
              value: _vm.entity.settings.adset_name,
              callback: function ($$v) {
                _vm.$set(_vm.entity.settings, "adset_name", $$v)
              },
              expression: "entity.settings.adset_name",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "preview" }, [
        _c("span", [
          _c("label", [_vm._v(_vm._s(_vm.tr("Preview")) + ":")]),
          _vm._v(" " + _vm._s(_vm.preview) + " "),
        ]),
        _vm.previewIsLoading
          ? _c("i", { staticClass: "icon loader" })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }