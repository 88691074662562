<template>
  <div class="rule-activity-management-view">
    <template v-if="rule && !isLoading && isListEmpty">
      <notification-card
        v-if="rule.activity_management"
        empty-screen
      >
          {{ tr('There are no activities in the rule activity management yet.') }}
      </notification-card>
      <notification-card
        v-else
        :image="require('@/assets/img/sad_new.png')"
        empty-screen
      >
          {{ tr('This automation does not have the "Activity Management" feature enabled.') }}
      </notification-card>
    </template>

    <div v-else class="activities">
      <card v-for="item in items" :key="item.id" class="activity">
        <span class="datetime" v-tooltip="formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)">
          {{ timeAgo(item.created_at, tr) }}
        </span>

        <div v-html="buildAction(item)" />
        <div v-html="buildPostOrAd(item)" class="post-or-ad" />

        <div class="buttons">
          <template v-if="actionButtonsVisible(item)">
            <button
              class="primary small"
              :disabled="loadingButton !== null || $root.isSupportMode"
              @click="() => accept(item)"
            >
              <i v-if="loadingButton === item.id + '--accept'" class="icon loader white" />
              <i v-else class="icon like white" />
              {{ tr('Accept') }}
            </button>
            <button
              class="red small"
              :disabled="loadingButton !== null || $root.isSupportMode"
              @click="() => reject(item)"
            >
              <i v-if="loadingButton === item.id + '--reject'" class="icon loader white" />
              <i v-else class="icon dislike white" />
              {{ tr('Reject') }}
            </button>
          </template>

          <a
            v-if="rule.action === 'start'"
            :href="'/post-copy-analysis/posts/' + item.post.id"
            target="_blank"
            class="button secondary small go-to-post-button"
          >
            {{ tr('Go to Post') }}
          </a>
        </div>

        <div v-if="item.isHandled" class="status-wrapper">
          <div v-html="buildItemStatus(item)" class="status" />
          <span
            v-if="item.handled_at"
            v-tooltip="formatToYMD(item.handled_at) + ', ' + formatTime(item.handled_at)"
            class="datetime"
          >
            {{ timeAgo(item.handled_at, tr) }}
          </span>
        </div>
      </card>

      <template v-if="!rule || hasMore">
        <card
          v-for="i in (isListEmpty ? [ 1, 2, 3 ] : [ 1, 2 ])"
          :key="'rules-loader-skeleton--' + i"
          ref="loadMore"
          class="activity"
        >
          <div class="skeleton-container">
            <skeleton height="10px" width="80px" />
            <skeleton width="60%" height="13px" />
            <skeleton width="80%" height="13px" />

            <div class="buttons">
              <skeleton width="100px" height="30px" radius="5px" />
              <skeleton width="100px" height="30px" radius="5px" />
            </div>
          </div>
        </card>
      </template>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import Skeleton from '@/components/Skeleton.vue';
import TopImageContent from '@/components/TopImageContent.vue';

import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';
import RuleActivityManagementService from '@/services/RuleActivityManagement.js';

import { formatToYMD, formatTime, timeAgo } from '@/utils/date.js';
import { ucfirst } from '@/utils/string';

const ITEM_PER_PAGE = 20;

export default {
  name: 'RuleActivityManagementView',
  components: {
    Card,
    NotificationCard,
    Skeleton,
    TopImageContent,
  },
  data:() => ({
    isLoading: false,
    hasMore: true,
    page: 0,
    items: [],
    loadingButton: null,

    itemIdInQuery: null,
    doInQuery: null,
  }),
  computed: {
    ruleId() {
      return this.$route.params.id;
    },
    isListEmpty() {
      return this.items.length === 0;
    },
    rule() {
      return this.$parent.rule;
    },
  },
  watch: {
    rule: {
      immediate: true,
      handler(val) {
        if (val && this.rule.activity_management) {
          this.loadNewData();
        }
      },
    },
    isLoading(val) {
      if (!val && this.itemIdInQuery && this.doInQuery) {
        let item = this.items.find(i => i.id === this.itemIdInQuery);
        if (!item) {
          item = new RuleActivityManagementQueueItem({ id: this.itemIdInQuery });
        }

        this.instruct(item, this.doInQuery);
      }
    },
  },
  created() {
    this.itemIdInQuery = this.$route.query['item-id'];
    this.doInQuery = this.$route.query['do'];
    if (this.itemIdInQuery) {
      this.$router.replace(this.$route.path);
    }
  },
  methods: {
    formatToYMD,
    formatTime,
    timeAgo,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        ruleId: this.ruleId,
        page: this.page,
        limit: ITEM_PER_PAGE,
      };

      RuleActivityManagementService.getList(params)
        .then(resp => {
          resp.forEach(item => this.items.push(item));
          this.hasMore = resp.length === ITEM_PER_PAGE;
        })
        .catch((err) => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore[0]);
      }
    },
    buildAction(item) {
      if (!this.rule) {
        return null;
      }

      switch (this.rule.action) {
        case 'start':
          return this.tr(
            '<strong>Create advertisement</strong> from the following ":name" :platform post',
            {
              ':platform': ucfirst(item.post.platform),
              ':name': item.post.source_profile.name,
            }
          ) + ':';

        case 'scaling_up':
          return this.tr('<strong>Scale</strong> up the following advertisement') + ':';

        case 'stop':
        case 'smart_stop':
          return this.tr('<strong>Stop</strong> the following advertisement') + ':';

        case 'delete':
          return this.tr('<strong>Delete</strong> the following advertisement') + ':';
      }

      return null;
    },
    buildPostOrAd(item) {
      if (!this.rule) {
        return null;
      }

      switch (this.rule.action) {
        case 'start':
          return item.post.shortText;

        case 'scaling_up':
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return 'ID: ' + item.ad.external_id + ' | ' + item.ad.name;
      }

      return null;
    },
    buildItemStatus(item) {
      if (!item.isHandled) {
        return this.tr('Waiting for instruction...');
      }

      if (item.handled_by === null) {
        switch (item.instruction) {
          case 'accept':
            return '<strong>' + this.tr('Accepted') + '</strong> ' + this.tr('automatically');

          case 'reject':
          return '<strong>' + this.tr('Rejected') + '</strong> ' + this.tr('automatically');
        }
      }

      switch (item.instruction) {
        case 'accept':
          return '<strong>' + this.tr('Accepted') + '</strong> ' + this.tr('by :name', { ':name': item.handled_by.fullname });

        case 'reject':
          return '<strong>' + this.tr('Rejected') + '</strong> ' + this.tr('by :name', { ':name': item.handled_by.fullname });
      }

      return null;
    },
    actionButtonsVisible(item) {
      return !item.isHandled && !this.$root.loggedInUser.isRestrictedViewer;
    },
    accept(item) {
      this.instruct(item, 'accept');
    },
    reject(item) {
      this.instruct(item, 'reject');
    },
    instruct(item, instruction) {
      if (this.loadingButton !== null) {
        return;
      }
      if (instruction !== 'accept' && instruction !== 'reject') {
        return;
      }
      this.loadingButton = item.id + '--' + instruction;

      RuleActivityManagementService.instruct(this.rule.id, item.id, instruction)
        .then(resp => {
          item.updateWith(resp);

          this.$toast.clear();
          if (instruction === 'accept') {
            this.$toast.success(this.tr('Activity accepted successfully. The activity will run in a few minutes.'));
          }
          else if (instruction === 'reject') {
            this.$toast.success(this.tr('Activity rejected successfully.'));
          }
        })
        .catch(err => this.error(err))
        .finally(() => this.loadingButton = null);
    },
  }
};
</script>
