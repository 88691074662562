var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "google-2fa-form-component" },
    [
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.title,
            description: _vm.tr(
              "Please, enter you 6-digit authentication code from the Google Authenticator app."
            ),
          },
        },
        [
          _c("auth-code-input", {
            ref: "authCodeInput",
            attrs: { disabled: _vm.isLoading },
            on: { enter: () => _vm.submit() },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
          _vm.trustOption
            ? _c("label", { staticClass: "trust-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.trust,
                      expression: "trust",
                    },
                  ],
                  attrs: { type: "checkbox", value: "1" },
                  domProps: {
                    checked: Array.isArray(_vm.trust)
                      ? _vm._i(_vm.trust, "1") > -1
                      : _vm.trust,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.trust,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "1",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.trust = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.trust = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.trust = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  " " + _vm._s(_vm.tr("Trust this device for 30 days")) + " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "bottom-button" }, [
        _c(
          "button",
          { staticClass: "secondary", on: { click: () => _vm.cancel() } },
          [_vm._v(" " + _vm._s(_vm.tr(_vm.cancelButtonLabel)) + " ")]
        ),
        _c(
          "button",
          {
            class: _vm.confirmButtonClass,
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.submit() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.tr("Confirm")) + " "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }