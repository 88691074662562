import ConfirmationModal from './components/Modal';
import Api from './js/api.js';

const Plugin = (Vue, options = {}) => {
  let methods = Api(Vue, options);
  Vue.$confirmation = methods;
  Vue.prototype.$confirmation = methods;
};

ConfirmationModal.install = Plugin

export default ConfirmationModal;
export {ConfirmationModal}
