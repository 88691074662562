var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block conversion-odax-block-component",
      attrs: { title: _vm.title !== null ? _vm.title : _vm.tr("Conversion") },
    },
    [
      _vm.entity.disconnectedAdAccount
        ? _c("info-text", { attrs: { type: "error" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr("The selected ad account has been disconnected.")
                ) +
                " "
            ),
          ])
        : [
            !_vm.minimal
              ? [
                  _vm.destiantionTypeSelectVisible
                    ? _c(
                        "div",
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(_vm._s(_vm.tr("Conversion location"))),
                          ]),
                          _c("v-select", {
                            attrs: {
                              options: _vm.destinationTypes,
                              selectable: (item) => !item.disabled,
                              reduce: (item) => item.machine_name,
                              searchable: false,
                              clearable: false,
                              disabled:
                                _vm.entity.isRoasGoalCampaignStrategy ||
                                !_vm.entity.isNew,
                              label: "label",
                            },
                            model: {
                              value:
                                _vm.entity.settings.adset_conversion_location,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.entity.settings,
                                  "adset_conversion_location",
                                  $$v
                                )
                              },
                              expression:
                                "entity.settings.adset_conversion_location",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("label", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.tr("Performance goal"))),
                      ]),
                      _c("v-select", {
                        attrs: {
                          options: _vm.optimizationGoals,
                          reduce: (obj) => obj.machine_name,
                          searchable: false,
                          clearable: false,
                          disabled: !_vm.entity.isNew,
                          label: "label",
                        },
                        model: {
                          value: _vm.entity.settings.adset_optimization_goal,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_optimization_goal",
                              $$v
                            )
                          },
                          expression: "entity.settings.adset_optimization_goal",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm.fbOdaxConversionAppIsVisible
              ? [
                  _c(
                    "div",
                    [
                      _c("label", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.tr("Application"))),
                      ]),
                      _c("v-select", {
                        attrs: {
                          options: _vm.entity.appList,
                          reduce: (obj) => obj.id,
                          placeholder: _vm.tr("Select an app"),
                          disabled: _vm.disabled,
                          label: "name",
                        },
                        model: {
                          value: _vm.entity.settings.adset_conversion_app_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_conversion_app_id",
                              $$v
                            )
                          },
                          expression: "entity.settings.adset_conversion_app_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("label", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.tr("Application type"))),
                      ]),
                      _c("v-select", {
                        attrs: {
                          options: _vm.appTypeList,
                          reduce: (obj) => obj.value,
                          placeholder: _vm.tr("Select app type"),
                          disabled:
                            !_vm.entity.settings.adset_conversion_app_id ||
                            _vm.disabled,
                        },
                        model: {
                          value:
                            _vm.entity.settings.adset_conversion_app_store_url,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_conversion_app_store_url",
                              $$v
                            )
                          },
                          expression:
                            "entity.settings.adset_conversion_app_store_url",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm.fbOdaxConversionPixelIsVisible
              ? _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.tr("Pixel"))),
                    ]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.entity.pixelListForSelect,
                        reduce: (option) => option.value,
                        placeholder: _vm.tr("Select a pixel"),
                        disabled: _vm.disabled,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "option",
                            fn: function (option) {
                              return [
                                _c(
                                  "div",
                                  [
                                    "status" in option
                                      ? [
                                          option.status ||
                                          _vm.$root.currentCompany.is_demo
                                            ? _c("i", {
                                                staticClass: "icon point green",
                                              })
                                            : _c("i", {
                                                staticClass: "icon point red",
                                              }),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(option.label) + " "),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                          {
                            key: "selected-option",
                            fn: function (option) {
                              return [
                                _c(
                                  "div",
                                  [
                                    "status" in option
                                      ? [
                                          option.status ||
                                          _vm.$root.currentCompany.is_demo
                                            ? _c("i", {
                                                staticClass: "icon point green",
                                              })
                                            : _c("i", {
                                                staticClass: "icon point red",
                                              }),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(option.label) + " "),
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2221845197
                      ),
                      model: {
                        value: _vm.entity.settings.adset_conversion_pixel_id,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.entity.settings,
                            "adset_conversion_pixel_id",
                            $$v
                          )
                        },
                        expression: "entity.settings.adset_conversion_pixel_id",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.fbOdaxConversionEventIsVisible
              ? [
                  _c(
                    "div",
                    [
                      _c(
                        "label",
                        { staticClass: "input-label" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.tr("Conversion event")) + " "
                          ),
                          !_vm.entity.disconnectedAdAccount
                            ? _c("refresh-button", {
                                attrs: {
                                  label: _vm.tr("Update events"),
                                  "success-message": _vm.tr(
                                    "Conversion events updated successfully."
                                  ),
                                  promise: () => _vm.refreshSelectedAdAccount(),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-select", {
                        attrs: {
                          options: _vm.conversionEvents,
                          reduce: (obj) => obj.machine_name,
                          placeholder: _vm.tr("Select a conversion"),
                          disabled:
                            _vm.entity.isRoasGoalCampaignStrategy ||
                            _vm.disabled,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      "status" in option
                                        ? [
                                            option.status ||
                                            _vm.$root.currentCompany.is_demo
                                              ? _c("i", {
                                                  staticClass:
                                                    "icon point green",
                                                })
                                              : _c("i", {
                                                  staticClass: "icon point red",
                                                }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(_vm.tr(option.label)) + " "
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      "status" in option
                                        ? [
                                            option.status ||
                                            _vm.$root.currentCompany.is_demo
                                              ? _c("i", {
                                                  staticClass:
                                                    "icon point green",
                                                })
                                              : _c("i", {
                                                  staticClass: "icon point red",
                                                }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(
                                        " " + _vm._s(_vm.tr(option.label)) + " "
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2567971885
                        ),
                        model: {
                          value: _vm.entity.settings.adset_conversion_event,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_conversion_event",
                              $$v
                            )
                          },
                          expression: "entity.settings.adset_conversion_event",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.minimal
                    ? _c(
                        "a",
                        {
                          staticClass: "facebook-link",
                          attrs: {
                            href: "https://business.facebook.com/settings/custom-conversions",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr("Create new custom conversion")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
            !_vm.minimal
              ? [
                  _vm.entity.isRoasGoalAvailable
                    ? _c(
                        "div",
                        [
                          _c(
                            "label",
                            { staticClass: "input-label" },
                            [
                              _vm._v(" " + _vm._s(_vm.tr("ROAS goal")) + " "),
                              !_vm.entity.isRoasGoalCampaignStrategy
                                ? [
                                    _vm._v(
                                      " (" + _vm._s(_vm.tr("optional")) + ") "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "description" }, [
                            _vm._v(
                              _vm._s(
                                _vm.tr(
                                  "Set a goal if you’re aiming for a certain return on ad spend. To set your ROAS goal, use a number between 0.001 and 1,000."
                                )
                              )
                            ),
                          ]),
                          _c("number-input", {
                            attrs: { step: 0.001, max: 1000 },
                            model: {
                              value: _vm.entity.settings.adset_roas_goal,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.entity.settings,
                                  "adset_roas_goal",
                                  $$v
                                )
                              },
                              expression: "entity.settings.adset_roas_goal",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.entity.bidAmountIsAvailable
                    ? _c(
                        "div",
                        [
                          _c(
                            "label",
                            { staticClass: "input-label" },
                            [
                              _vm._v(" " + _vm._s(_vm.bidAmountLabel) + " "),
                              !_vm.entity.bidAmountIsRequired
                                ? [
                                    _vm._v(
                                      " (" + _vm._s(_vm.tr("optional")) + ") "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          !_vm.entity.isBidCapStrategy
                            ? _c("div", { staticClass: "description" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.tr(
                                      "A type of advertising metric that is used to measure the effectiveness and efficiency of an advertising campaign. It represents the average cost that you pay for a specific result, such as a click, an impression, a lead, or a sale."
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _c("money-input", {
                            attrs: {
                              currency: _vm.entity.adAccountCurrency
                                ? _vm.entity.adAccountCurrency.code
                                : "EUR",
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.entity.settings.adset_bid_amount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.entity.settings,
                                  "adset_bid_amount",
                                  $$v
                                )
                              },
                              expression: "entity.settings.adset_bid_amount",
                            },
                          }),
                          !_vm.entity.bidAmountIsRequired
                            ? _c("info-text", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tr(
                                        "Meta will aim to spend your entire budget and get the most link clicks using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal."
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }