var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block adset-budget-block-component",
      attrs: { title: _vm.tr("Budget") },
    },
    [
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Ad set budget"))),
          ]),
          _vm.isAvailable
            ? _c(
                "div",
                { staticClass: "inline-input-select" },
                [
                  _c("money-input", {
                    attrs: {
                      disabled:
                        _vm.disabled || !!_vm.entity.output_campaign.budget,
                      currency: _vm.entity.adAccountCurrency
                        ? _vm.entity.adAccountCurrency.code
                        : "EUR",
                      min: _vm.entity.adAccountMinDailyBugdet,
                    },
                    model: {
                      value: _vm.entity.settings.adset_budget,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity.settings, "adset_budget", $$v)
                      },
                      expression: "entity.settings.adset_budget",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      options: _vm.budgetPeriods,
                      reduce: (obj) => obj.machine_name,
                      disabled:
                        true ||
                        !_vm.entity.isNew ||
                        _vm.entity.output_campaign.budget ||
                        _vm.disabled,
                      searchable: false,
                      clearable: false,
                    },
                    model: {
                      value: _vm.entity.settings.adset_budget_period,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.entity.settings,
                          "adset_budget_period",
                          $$v
                        )
                      },
                      expression: "entity.settings.adset_budget_period",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("info-text", [_vm._v(" " + _vm._s(_vm.adsetBudgetInfo) + " ")]),
        ],
        1
      ),
      _vm.isAvailable
        ? _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.tr("Maximum number of posts promoted at once")) +
                  " " +
                  _vm._s("(" + _vm.tr("optional") + ")") +
                  " "
              ),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.entity.settings.max_active_ads,
                  expression: "entity.settings.max_active_ads",
                },
              ],
              staticClass: "max-number",
              attrs: {
                type: "number",
                min: "1",
                step: "1",
                disabled: _vm.disabled,
              },
              domProps: { value: _vm.entity.settings.max_active_ads },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.entity.settings,
                    "max_active_ads",
                    $event.target.value
                  )
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }