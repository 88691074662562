import OnboardingAd from '@/components/OnboardingAd.vue';
import OnboardingBuzz from '@/components/OnboardingBuzz.vue';

export default {
  computed: {
    isLoading() {
      return this.$store.getters['dashboard/isLoading'];
    },
    dashboardData: {
      get() {
        return this.$store.getters['dashboard/dashboardData'];
      },
      set(value) {
        this.$store.commit('dashboard/setDashboardData', value);
      },
    },
    onboardingNeccessary() {
      if (!this.$root.isLoggedIn || !this.$root.isInOriginalCompany) {
        return false;
      }

      return this.$store.getters['dashboard/onboardingNeccessary'];
    },
  },
  methods: {
    openOnboardingModal() {
      let onboardingComponent = null;
      if (this.$app.isAd) {
        onboardingComponent = OnboardingAd;
      }
      else if (this.$app.isBuzz) {
        onboardingComponent = OnboardingBuzz;
      }
      else {
        return;
      }

      this.$fullscreenModal.open(
        this,
        onboardingComponent,
        {},
        { closable: false },
      );
    },
  },
};
