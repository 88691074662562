var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      !_vm.minimal
        ? _c("system-level-notifications", { attrs: { "space-top": "" } })
        : _vm._e(),
      !_vm.minimal && _vm.list.length !== 0
        ? _c("div", { staticClass: "page-textual" }, [
            _vm._v(" " + _vm._s(_vm.text) + " "),
          ])
        : _vm._e(),
      _vm.list.length > 10 && !_vm.minimal
        ? _c("div", { staticClass: "actions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              staticClass: "text",
              attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.list.length === 0
        ? [
            !_vm.hasConnectedProfile
              ? _c(
                  "notification-card",
                  {
                    attrs: {
                      image: require("@/assets/img/onboarding/step-profile.png"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm.platform === "facebook"
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "If you want to use an Facebook Page, first you should connect you Meta Profile here"
                                )
                              ) +
                              " "
                          ),
                        ]
                      : _vm.platform === "instagram"
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "If you want to use an Instagram Account, first you should connect you Meta Profile here"
                                )
                              ) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                    _vm.connectButtonVisible
                      ? _c(
                          "button",
                          {
                            staticClass: "button primary",
                            on: { click: () => _vm.$emit("connect") },
                          },
                          [
                            _vm._v(
                              " + " +
                                _vm._s(_vm.tr("Connect Your Profile")) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm.platform === "facebook"
              ? _c("notification-card", { attrs: { "empty-screen": "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.tr("There are no available Facebook pages")) +
                      " "
                  ),
                ])
              : _vm.platform === "instagram"
              ? _c("notification-card", { attrs: { "empty-screen": "" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr("There are no available Instagram accounts")
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm.filteredList.length === 0
        ? _c(
            "notification-card",
            { staticClass: "no-result", attrs: { "empty-screen": "" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "There are no results matching your search criteria."
                    )
                  ) +
                  " "
              ),
            ]
          )
        : _c("new-sorted-table", {
            attrs: {
              data: _vm.filteredList,
              "initial-sort": { key: "name", direction: "asc" },
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "name",
                          "min-width": "200px",
                          sortable: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tr("Name")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "status-cell",
                        attrs: { col: "status", width: "160px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Status")))]
                    ),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          {
                            staticClass: "external-id-cell",
                            attrs: { col: "id", width: "180px", sortable: "" },
                          },
                          [_vm._v(_vm._s(_vm.idLabel))]
                        )
                      : _vm._e(),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          { attrs: { col: "connected_by", width: "180px" } },
                          [_vm._v(_vm._s(_vm.tr("Connected by")))]
                        )
                      : _vm._e(),
                    !_vm.minimal && _vm.$app.isAd
                      ? _c("table-head", {
                          attrs: { col: "actions", width: "120px" },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (row) {
                    return _c(
                      "table-row",
                      { key: row.id },
                      [
                        _c("table-data", { attrs: { col: "name" } }, [
                          _c("label", [
                            _vm.isLoading && _vm.loadingId === row.id
                              ? _c("i", { staticClass: "icon loader" })
                              : _c("input", {
                                  attrs: {
                                    type: "checkbox",
                                    disabled: _vm.isDisabled(row),
                                  },
                                  domProps: {
                                    value: row.id,
                                    checked: _vm.isConnected(row),
                                  },
                                  on: {
                                    change: () => _vm.checkboxChanged(row),
                                  },
                                }),
                            _c("div", { staticClass: "name-wrapper" }, [
                              _vm.minimal
                                ? _c("i", { class: "icon " + _vm.platform })
                                : _vm._e(),
                              _c("div", { staticClass: "name" }, [
                                _c("span", [_vm._v(_vm._s(row.name))]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c(
                          "table-data",
                          {
                            staticClass: "status-cell",
                            attrs: { col: "status" },
                          },
                          [
                            _c("span", { class: _vm.statusClasses(row) }, [
                              _vm._v(
                                _vm._s(
                                  _vm.isConnected(row)
                                    ? _vm.tr("Active")
                                    : _vm.tr("Not connected")
                                )
                              ),
                            ]),
                          ]
                        ),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              {
                                staticClass: "external-id-cell",
                                attrs: { col: "id" },
                              },
                              [_vm._v(" " + _vm._s(row.id) + " ")]
                            )
                          : _vm._e(),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              { attrs: { col: "connected_by" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.owner ? row.owner.fullname : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.minimal && _vm.$app.isAd
                          ? _c("table-data", { attrs: { col: "actions" } }, [
                              _vm.isConnected(row)
                                ? _c("div", { staticClass: "action-group" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "gray small",
                                        on: {
                                          click: () =>
                                            _vm.openSourceProfileSettings(row),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.tr("Fine tuning")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
      _c("disconnect-confirm-modal", { ref: "disconnectConfirmModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }