export default {
  computed: {
    isFbOdax() {
      return this.entity.settings.is_fb_odax_version;
    },
    fbOdaxMap() {
      return this.$store.getters['app/facebookOdaxMap'];
    },
    fbOdaxSelectedObjective() {
      if (!this.isFbOdax) {
        return null;
      }

      const objective = this.entity.output_campaign.objective;
      return this.fbOdaxMap.find(item => item.machine_name === objective);
    },
    fbOdaxDestinationTypes() {
      if (!this.isFbOdax) {
        return [];
      }

      const list = JSON.parse(JSON.stringify(this.fbOdaxSelectedObjective.destination_types));
      list.forEach(item => {
        if (item.machine_name === 'instagram_profile') {
          item.disabled = this.entity.hasSelectedFacebookPlatform || !this.entity.hasSelectedInstagramPlatform;
        }
      });

      return list;
    },
    fbOdaxSelectedDestinationType() {
      if (!this.isFbOdax || this.entity.settings.adset_conversion_location === null) {
        return null;
      }

      return this.fbOdaxDestinationTypes.find(item => item.machine_name === this.entity.settings.adset_conversion_location);
    },
    fbOdaxOptimizationGoals() {
      if (!this.isFbOdax || !this.fbOdaxSelectedDestinationType) {
        return [];
      }

      return this.conditionalFilteredList(this.fbOdaxSelectedDestinationType.optimization_goals);
    },
    fbOdaxSelectedOptimizationGoal() {
      return this.fbOdaxOptimizationGoals.find(item => item.machine_name === this.entity.settings.adset_optimization_goal);
    },
    fbOdaxPromotedObjects() {
      if (!this.isFbOdax || !this.fbOdaxSelectedOptimizationGoal) {
        return [];
      }

      return this.fbOdaxSelectedOptimizationGoal.promoted_objects;
    },
    fbOdaxConversionAppIsVisible() {
      return this.fbOdaxPromotedObjects.includes('adset_conversion_app_id');
    },
    fbOdaxConversionPixelIsVisible() {
      return this.fbOdaxPromotedObjects.includes('adset_conversion_pixel_id');
    },
    fbOdaxConversionEventIsVisible() {
      return this.fbOdaxPromotedObjects.includes('adset_conversion_event');
    },
  }
}
