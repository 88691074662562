<template>
  <div class="geolocation-target-selector-component">
    <div v-if="hasSelectedValue" class="selected-values">
      <template v-for="targetType in [ 'geo_locations', 'excluded_geo_locations' ]">
        <div
          v-for="(row, key) in value[targetType].all"
          :key="'geolocation-target-selector--row--' + key + '--' + row.key"
          class="row"
        >
          <i v-if="!row.meta.name" class="icon option-icon loader primary small" />
          <i v-else :class="'option-icon marker-' + (targetType === 'geo_locations' ? 'include' : 'exclude')" />

          <div class="name">
            <template v-if="row.meta.name">
              {{ getName(row.meta) }}
            </template>
            <template v-else>
              {{ tr('Loading...') }}
            </template>
          </div>

          <distance-radius-selector
            v-if="'radius' in row"
            v-model="row.radius"
            :min="row.radiusMin"
            :max="row.radiusMax"
            @input="() => delayedRedrawFullMap()"
          />

          <div class="actions">
            <i class="icon close" @click="() => value[targetType].remove(row.type, row.key)" />
          </div>
        </div>
      </template>
    </div>

    <div class="autosuggest-input-wrapper">
      <v-select
        v-model="includeOrExclude"
        :options="includeOrExcludeOptions"
        :clearable="false"
        :searchable="false"
        :append-to-body="false"
        :reduce="(option) => option.value"
      >
        <template #selected-option="option">
          <div class="include-exclude-option">
            <i :class="'option-icon ' + option.icon" />
            {{ option.label }}
          </div>
        </template>

        <template #option="option">
          <div class="include-exclude-option">
            <i :class="'option-icon ' + option.icon" />
            {{ option.label }}
          </div>
        </template>
      </v-select>

      <vue-autosuggest
        v-model="autosuggestInputValue"
        :input-props="{ id: 'autosuggest', placeholder: tr('Search locations') }"
        :suggestions="[{ data: suggestions }]"
        @input="updateSuggestions"
        @selected="onSelectSuggestion"
      >
        <template slot-scope="{ suggestion }">
          <div @mousedown="() => onMouseDownSuggestion(suggestion)">
            {{ getName(suggestion.item) }}
          </div>
        </template>
      </vue-autosuggest>

      <i v-if="isLoading" class="icon loader primary" />
    </div>

    <div v-show="hasSelectedValue" ref="map" class="map" />
  </div>
</template>

<script>
import DistanceRadiusSelector from '@/components/DistanceRadiusSelector.vue';
import { VueAutosuggest } from 'vue-autosuggest';

import debounce from '@/utils/debounce.js';

import FacebookTargetingDescriptor from '@/descriptors/FacebookTargeting.js';
import ToolService from '@/services/Tool.js';

export default {
  name: 'GeolocationTargetSelector',
  components: {
    DistanceRadiusSelector,
    VueAutosuggest,
  },
  props: {
    value: {
      type: FacebookTargetingDescriptor,
      required: true,
    },
  },
  data() {
    return {
      isMounted: false,
      includeOrExclude: 'include',
      autosuggestInputValue: null,
      isLoading: false,
      suggestions: [],
      updateSuggestions: debounce((newValue) => {
        if (newValue.trim().length === 0 || !this.isMounted) {
          this.suggestions = [];
          return;
        }

        this.isLoading = true;

        ToolService.facebookAdGeolocationSearch(newValue)
          .then(resp => this.suggestions = resp)
          .finally(() => this.isLoading = false);
      }),
      lastMouseDownSuggestion: null,
      delayedRedrawFullMap: debounce(() => {
        this.redrawFullMap();
      }),

      map: null,
      mapCircles: {},
      mapMarkers: {},
    };
  },
  computed: {
    includeOrExcludeOptions() {
      return [
        {
          value: 'include',
          label: this.tr('Include location'),
          icon: 'marker-include',
        },
        {
          value: 'exclude',
          label: this.tr('Exclude location'),
          icon: 'marker-exclude',
        },
      ]
    },
    selectedValues() {
      return [ ...this.value.geo_locations.all, ...this.value.excluded_geo_locations.all ];
    },
    hasSelectedValue() {
      return this.selectedValues.length !== 0;
    },
    selectedValuesClone() {
      return JSON.parse(JSON.stringify(this.selectedValues));
    },
  },
  watch: {
    selectedValuesClone: {
      deep: true,
      handler(val, oldVal) {
        this.initMap().then(() => {
          const newKeys = val.map(item => item.key);
          const oldKeys = oldVal.map(item => item.key);

          if (newKeys.length === oldKeys.length) {
            return;
          }

          const removedKeys = oldKeys.filter(x => !newKeys.includes(x));
          removedKeys.forEach(key => {
            if (key in this.mapCircles) {
              this.mapCircles[key].setMap(null);
              delete this.mapCircles[key];
            }

            if (key in this.mapMarkers) {
              this.mapMarkers[key].setMap(null);
              delete this.mapMarkers[key];
            }
          });

          this.loadAllMetadata();
          this.drawCountriesToMap();
          this.fitMap();
        });
      },
    }
  },
  created() {
    if (!('google' in window)) {
      const mapsScript = document.createElement('script');
      mapsScript.async = true;
      mapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB_oVlad22d70FK3nqBRAznShA_NFH-oKU&loading=async';
      document.head.appendChild(mapsScript);
    }
  },
  mounted() {
    if (this.hasSelectedValue) {
      this.reinit();
    }

    this.isMounted = true;
  },
  beforeDestroy() {
    this.isMounted = false;
  },
  methods: {
    reinit() {
      this.initMap()
        .then(() => this.loadAllMetadata())
        .then(() => this.redrawFullMap());
    },
    loadAllMetadata() {
      return new Promise(resolve => {
        if (!this.hasSelectedValue || !this.isMounted) {
          return resolve();
        }

        let processed = 0;
        this.selectedValues.forEach(item => {
          ToolService.facebookAdGeolocationMetadata(item.type, item.key)
            .then(resp => item.meta = resp)
            .finally(() => {
              ++processed;
              if (processed === this.selectedValues.length) {
                resolve();
                this.$forceUpdate();
              }
            })
        });
      });
    },
    onMouseDownSuggestion(suggestion) {
      this.lastMouseDownSuggestion = suggestion;
    },
    onSelectSuggestion(suggestion) {
      if (suggestion === null) {
        suggestion = this.lastMouseDownSuggestion;
      }

      this.lastMouseDownSuggestion = null;

      if (suggestion && suggestion.item) {
        const key = this.includeOrExclude === 'include' ? 'geo_locations' : 'excluded_geo_locations';

        const addedItem = this.value[key].add(suggestion.item.type, suggestion.item.key);
        if (addedItem) {
          addedItem.meta.name = suggestion.item.name;

          ToolService.facebookAdGeolocationMetadata(addedItem.type, addedItem.key)
            .then(resp => {
              addedItem.meta = resp;

              this.drawToMap(this.includeOrExclude, addedItem);
              this.fitMap();

              this.validate();
            });
        }
      }

      this.autosuggestInputValue = null;
      this.suggestions = [];
    },
    initMap() {
      return new Promise(resolve => {
        if (this.map !== null) {
          return resolve();
        }

        const waitForGoogle = () => {
          if (
            !('google' in window)
            || !('maps' in window.google)
            || !('MapTypeId' in window.google.maps)
          ) {
            setTimeout(() => waitForGoogle(), 25);
            return;
          }

          if (this.selectedValues.length !== 0) {
            this.map = new window.google.maps.Map(this.$refs.map, {
              styles: require('@/assets/googleMapsStyle.json'),
              mapTypeId: window.google.maps.MapTypeId.ROADMAP,
              disableDefaultUI: true,
              draggable: false,
              zoom: 1,
              center: { lat: 0, lng: 0 },
            });

            // https://geojson-maps.ash.ms
            this.map.data.addGeoJson(require('@/assets/geo.json'));
            this.drawCountriesToMap();
          }

          resolve();
        };
        waitForGoogle();
      });
    },
    drawToMap(targetType, item) {
      if ('radius' in item) {
        this.drawCircleToMap(targetType, item);
      }
      else if (item.type === 'country') {
        this.drawCountriesToMap();
      }
      else {
        this.drawMarkerToMap(targetType, item);
      }
    },
    drawCircleToMap(targetType, item) {
      const color = targetType === 'include' ? '#2400ff' : '#F04D4D';

      const center = new window.google.maps.LatLng(item.meta.latitude, item.meta.longitude);
      this.mapCircles[item.key] = new window.google.maps.Circle({
        strokeColor: color,
        strokeOpacity: 0.4,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.15,
        map: this.map,
        center,
        radius: item.radius * 1000 / 2,
      });

      this.drawMarkerToMap(targetType, item);
    },
    drawMarkerToMap(targetType, item) {
      let iconUrl = null;
      if (targetType === 'include') {
        iconUrl = require('@/assets/img/icons/marker-include.svg');
      }
      else {
        iconUrl = require('@/assets/img/icons/marker-exclude.svg');
      }

      const center = new window.google.maps.LatLng(item.meta.latitude, item.meta.longitude);
      this.mapMarkers[item.key] = new window.google.maps.Marker({
        map: this.map,
        position: center,
        icon: {
          url: iconUrl,
          scaledSize: new window.google.maps.Size(32, 32),
        }
      });
    },
    drawCountriesToMap() {
      if (!this.map) {
        return;
      }

      const includedCountries = this.value.geo_locations.countries.map(item => item.key);
      const excludedCountries = this.value.excluded_geo_locations.countries.map(item => item.key);

      this.map.data.setStyle((feature) => {
        let color = 'transparent';
        if (includedCountries.includes(feature.getProperty('wb_a2'))) {
          color = 'blue';
        }
        if (excludedCountries.includes(feature.getProperty('wb_a2'))) {
          color = 'red';
        }

        return /** @type {google.maps.Data.StyleOptions} */({
          fillColor: color,
          strokeColor: color,
          strokeWeight: 2
        });
      });
    },
    clearMap() {
      Object.keys(this.mapCircles).forEach(key => {
        this.mapCircles[key].setMap(null);
        delete this.mapCircles[key];
      });

      Object.keys(this.mapMarkers).forEach(key => {
        this.mapMarkers[key].setMap(null);
        delete this.mapMarkers[key];
      });

    },
    redrawFullMap() {
      if (!this.isMounted) {
        return;
      }

      this.clearMap();

      this.drawCountriesToMap();
      this.value.geo_locations.all.forEach(item => this.drawToMap('include', item));
      this.value.excluded_geo_locations.all.forEach(item => this.drawToMap('exclude', item));

      this.fitMap();
    },
    fitMap() {
      const bounds = new window.google.maps.LatLngBounds();
      Object.values(this.mapCircles).forEach(circle => {
        const circleBounds = circle.getBounds();

        bounds.extend(circleBounds.getNorthEast());
        bounds.extend(circleBounds.getSouthWest());
      });
      Object.values(this.mapMarkers).forEach(marker =>  bounds.extend(marker.position));

      const includedCountries = this.value.geo_locations.countries.map(item => item.key);
      const excludedCountries = this.value.excluded_geo_locations.countries.map(item => item.key);

      this.map.data.forEach((feature) => {
        const countryCode = feature.getProperty('wb_a2');
        if (
          includedCountries.includes(countryCode)
          || excludedCountries.includes(countryCode)
        ) {
          feature.getGeometry().forEachLatLng(coords => bounds.extend(coords));
        }
      });

      this.map.fitBounds(bounds, 0);
    },
    getName(locationMeta) {
      if (!locationMeta) {
        return '';
      }

      let result = locationMeta.name;
      if ('address_string' in locationMeta) {
        result += ', ' + locationMeta.address_string;
      }
      else if ('primary_city' in locationMeta) {
        result += ', ' + locationMeta.primary_city;
      }
      else if ('region' in locationMeta && locationMeta.region !== locationMeta.name) {
        result += ', ' + locationMeta.region;
      }

      return result;
    },
    validate() {
      if (this.value.geo_locations.all.length === 0) {
        this.error(this.tr('The target location must be defined.'));
        return false;
      }
      if (this.value.hasInvalidGeoExclude) {
        this.error(this.tr('There is an excluded location that is not part of a larger, selected targeted area.'));
        return false;
      }
      if (this.value.hasGeoOverlaps) {
        this.error(this.tr('There is overlap among the selected target locations. It is necessary to eliminate the overlap to proceed.'));
        return false;
      }

      return true;
    }
  }
};
</script>
