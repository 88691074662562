var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "post-copy-analysis-view" },
    [
      _vm.isLoading
        ? _c("div", { ref: "loadMore", staticClass: "load-more" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : [
            !_vm.post.source_profile.has_active_start_rule
              ? _c("info-text", { staticClass: "ai-evaluation-not-allowed" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "The post AI evaluation will not occur until the profile that published it has active Start Automation."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm.post.ai_evaluation_possible && !_vm.post.ai_meta_error
              ? _c("ai-score", {
                  attrs: { value: _vm.post.ai_value, label: "Post AI Score" },
                })
              : _vm._e(),
            _c("div", { staticClass: "post" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "image" }, [
                  _vm.post.source_profile.picture
                    ? _c("img", {
                        attrs: { src: _vm.post.source_profile.picture },
                      })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "top-details" }, [
                  _c("div", { staticClass: "source-name" }, [
                    _vm._v(_vm._s(_vm.post.source_profile.name)),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value:
                            _vm.formatToYMD(_vm.post.created_at) +
                            ", " +
                            _vm.formatTime(_vm.post.created_at),
                          expression:
                            "formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)",
                        },
                      ],
                      staticClass: "posted",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.timeAgo(_vm.post.created_at, _vm.tr)) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm.post.text
                ? _c("div", {
                    staticClass: "text",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.post.text.replace(/(?:\r\n|\r|\n)/g, "<br>")
                      ),
                    },
                  })
                : _vm._e(),
              _vm.post.url
                ? _c(
                    "a",
                    {
                      staticClass: "post-link",
                      attrs: { href: _vm.post.url, target: "_blank" },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr("Go to Post")) + " ")]
                  )
                : _vm._e(),
            ]),
            _c("h3", [_vm._v(_vm._s(_vm.tr("Post metrics")))]),
            _c(
              "div",
              { staticClass: "metrics-wrapper" },
              [
                _c("new-sorted-table", {
                  attrs: { data: [_vm.post.metric], "disable-scroll": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return _vm._l(_vm.metricFields, function (field, key) {
                          return _c(
                            "table-head",
                            {
                              key: "metric-head-" + key,
                              attrs: {
                                col: field.machine_name,
                                minWidth: "130px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.tr(field.label)) + " ")]
                          )
                        })
                      },
                      proxy: true,
                    },
                    {
                      key: "default",
                      fn: function ({ sort }) {
                        return [
                          _c(
                            "table-row",
                            _vm._l(_vm.metricFields, function (field, key) {
                              return _c(
                                "table-data",
                                {
                                  key: "metric-value-" + key,
                                  attrs: { col: field.machine_name },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          sort[0][field.machine_name],
                                          field.machine_name === "ctr"
                                            ? "%"
                                            : ""
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("div", { staticClass: "last-update" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr("Data last updated at :time", {
                      ":time": _vm.timeAgo(
                        _vm.post.source_profile.fetched_at,
                        _vm.tr
                      ),
                    })
                  ) +
                  " "
              ),
            ]),
            _vm.post.source_profile.has_active_start_rule
              ? [
                  _c("h3", { staticClass: "title-with-icon" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tr("Actuality of the content")) + " "
                    ),
                    (!_vm.post.ai_evaluation_possible ||
                      _vm.post.ai_content_actuality_checked) &&
                    !_vm.contectActualityFormOpen &&
                    !_vm.$root.loggedInUser.isRestrictedViewer
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.tr("Change"),
                                expression: "tr('Change')",
                              },
                            ],
                            staticClass: "icon-wrapper",
                            on: {
                              click: () =>
                                (_vm.contectActualityFormOpen = true),
                            },
                          },
                          [_c("i", { staticClass: "icon edit light-black" })]
                        )
                      : _vm._e(),
                  ]),
                  _vm.contectActualityFormOpen
                    ? [
                        _c(
                          "div",
                          { staticClass: "inline-input-button" },
                          [
                            _c("date-input", {
                              attrs: {
                                time: "",
                                clearable: "",
                                "allow-empty": "",
                              },
                              model: {
                                value: _vm.newContentActuality,
                                callback: function ($$v) {
                                  _vm.newContentActuality = $$v
                                },
                                expression: "newContentActuality",
                              },
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "primary",
                                attrs: {
                                  disabled: _vm.isLoadingContantActualitySave,
                                },
                                on: {
                                  click: () => _vm.updateContentActuality(),
                                },
                              },
                              [
                                _vm.isLoadingContantActualitySave
                                  ? _c("i", {
                                      staticClass: "icon loader white",
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.tr("Save")) + " "),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : !_vm.post.ai_evaluation_possible
                    ? _c(
                        "info-text",
                        { staticClass: "ai-evaluation-not-possible" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "The text of the post is too short. AI evaluation is not possible."
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : [
                        !_vm.post.ai_content_actuality_checked
                          ? _c(
                              "div",
                              { staticClass: "ai-actuality-checkign" },
                              [
                                _c("i", { staticClass: "icon loader" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tr("Checking content actuality...")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm.post.ai_content_actuality
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tr(
                                      "According to Infinite∞Ai, the content loses its relevance at the following date"
                                    )
                                  ) +
                                  ":"
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formatToYMD(
                                      _vm.post.ai_content_actuality
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.formatTime(
                                      _vm.post.ai_content_actuality
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tr(
                                      "The Infinite∞Ai did not find any element in the content that would tie its actuality to time."
                                    )
                                  ) +
                                  " "
                              ),
                            ],
                        _c("br"),
                      ],
                  _vm.post.ai_meta && _vm.post.ai_meta.length !== 0
                    ? [
                        _c("h3", [_vm._v(_vm._s(_vm.tr("AI Evaluation")))]),
                        _c("copy-evaluation", {
                          attrs: { details: _vm.post.ai_meta },
                        }),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "buttons" }, [
                    _vm.post.ai_meta &&
                    _vm.post.ai_meta.length !== 0 &&
                    (!_vm.post.improved_copy ||
                      _vm.post.improved_copy.inProgress)
                      ? _c(
                          "button",
                          {
                            staticClass: "primary",
                            attrs: { disabled: _vm.improvedCopyInProgress },
                            on: { click: () => _vm.improveCopy() },
                          },
                          [
                            _vm.improvedCopyInProgress
                              ? _c("i", { staticClass: "icon loader white" })
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.tr("Get improved copy")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.post.platform === "facebook"
                      ? _c(
                          "button",
                          {
                            staticClass: "red",
                            on: { click: () => _vm.openDeleteConfirmModal() },
                          },
                          [_vm._v(" " + _vm._s(_vm.tr("Delete post")) + " ")]
                        )
                      : _vm._e(),
                  ]),
                  _vm.post.improved_copy && !_vm.post.improved_copy.inProgress
                    ? _c("improved-copy-component", {
                        attrs: { entity: _vm.post.improved_copy },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }