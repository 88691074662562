import NonCriticalError from '@/errors/NonCriticalError.js';

class ValidationError extends NonCriticalError {
  static appInstance;

  constructor(errors) {
    const message = ValidationError.buildMessage(errors);
    super(message);
    this.errors = errors;
  }

  get errors() {
    return this.errorList;
  }

  set errors(errors) {
    this.errorList = errors;
  }

  get firstError() {
    return this.getError(Object.keys(this.errors)[0]);
  }

  hasError(field) {
    return typeof this.errors[field] !== 'undefined';
  }

  getError(field) {
    return this.errors[field];
  }

  static buildMessage(errors) {
    let field = Object.keys(errors)[0];
    const value = errors[field];

    if (value.indexOf('validation.') !== 0) {
      return value;
    }

    let translateParams = {};
    if ('params' in errors) {
      for (let paramKey in errors.params) {
        translateParams['{' + paramKey + '}'] = errors.params[paramKey];
      }
    }

    field = field.replace(/\.([0-9{1,2}])/, '');
    return ValidationError.appInstance.tr(value, translateParams)
      .replace('{field}', ValidationError.appInstance.tr('field.' + field));
  }
}

export default ValidationError;
