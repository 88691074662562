var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    _vm._b(
      {
        staticClass: "table-row",
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$slots, function (slotContent, name) {
              return {
                key: name,
                fn: function () {
                  return [_vm._t(name)]
                },
                proxy: true,
              }
            }),
          ],
          null,
          true
        ),
      },
      "card",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }