export default (func, timeout = 300) => {
  let timer = null;

  let delayerFunction = (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args); }, timeout)

    return {
      cancel() {
        clearTimeout(timer)
      }
    }
  };

  delayerFunction.cancel = function () {
    if (timer !== null) {
      clearTimeout(timer);
      timer = null;
    }
  };

  return delayerFunction;
}
