import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';
import api from '@/services/Api';

export default {
  getList({ ruleId, status, handledBy, page, limit }) {
    const data = {};
    if (typeof status !== 'undefined') {
      data['status'] = status;
    }
    if (handledBy) {
      data['handled_by'] = handledBy;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rule/' + ruleId + '/ai-management/list?' + query)
      .then(resp => resp.map(i => new PostAiProhibitedContent(i)));
  },

  overrideResult(ruleId, entity) {
    return api().put('/rule/' + ruleId + '/ai-management/' + entity.id)
      .then(resp => new PostAiProhibitedContent(resp));
  },
};
