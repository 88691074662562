var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapperClasses,
      on: {
        mouseover: () => _vm.$emit("mouseover"),
        mouseleave: () => _vm.$emit("mouseleave"),
        click: () => _vm.$emit("click"),
      },
    },
    [
      _c("div", { staticClass: "sidebar-content" }, [
        _c(
          "div",
          { staticClass: "top" },
          [
            _vm._m(0),
            _c(
              "transition-expand",
              [
                _vm.hasHandledCompanies && !_vm.closed
                  ? _c("company-selector")
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "divider under-logo-divider" }),
            _c(
              "div",
              { staticClass: "menu" },
              [
                _c(
                  "access-handled-router-link",
                  { attrs: { to: "/dashboard", title: _vm.tr("Dashboard") } },
                  [
                    _c("i", { staticClass: "icon dashboard" }),
                    _c("span", [_vm._v(_vm._s(_vm.tr("Dashboard")))]),
                  ]
                ),
                _vm.$app.isAd
                  ? _c(
                      "access-handled-router-link",
                      {
                        attrs: {
                          to: "/automation",
                          title: _vm.tr("Automations"),
                        },
                      },
                      [
                        _c("i", { staticClass: "i icon rocket" }),
                        _c("span", [_vm._v(_vm._s(_vm.tr("Automations")))]),
                      ]
                    )
                  : _vm._e(),
                _vm.$app.isAd
                  ? _c(
                      "access-handled-router-link",
                      {
                        attrs: {
                          to: "/post-copy-analysis",
                          title: _vm.tr("Infinite∞Ai"),
                        },
                      },
                      [
                        _c("i", { staticClass: "i icon robot" }),
                        _c("span", [_vm._v(_vm._s(_vm.tr("Infinite∞Ai")))]),
                        _c("div", { staticClass: "flag" }, [_vm._v("Beta")]),
                      ]
                    )
                  : _vm.$app.isBuzz
                  ? _c(
                      "access-handled-router-link",
                      {
                        attrs: {
                          to: "/moderation",
                          title: _vm.tr("Moderation"),
                        },
                      },
                      [
                        _c("i", { staticClass: "i icon shield-ok" }),
                        _c("span", [_vm._v(_vm._s(_vm.tr("Moderation")))]),
                      ]
                    )
                  : _vm._e(),
                _vm.$app.isAd
                  ? _c(
                      "access-handled-router-link",
                      { attrs: { to: "/report", title: _vm.tr("Report") } },
                      [
                        _c("i", { staticClass: "i icon bar-chart" }),
                        _c("span", [_vm._v(_vm._s(_vm.tr("Report")))]),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "divider" }),
                _c(
                  "access-handled-router-link",
                  {
                    attrs: {
                      to: "/connected-platforms",
                      title: _vm.tr("Connected platforms"),
                    },
                  },
                  [
                    _c("i", { staticClass: "i icon plug" }),
                    _c("span", [_vm._v(_vm._s(_vm.tr("Connected platforms")))]),
                  ]
                ),
                _c(
                  "access-handled-router-link",
                  { attrs: { to: "/error-logs", title: _vm.tr("Error log") } },
                  [
                    _c("i", { staticClass: "i icon bug" }),
                    _c("span", [_vm._v(_vm._s(_vm.tr("Error log")))]),
                  ]
                ),
                _c(
                  "access-handled-router-link",
                  { attrs: { to: "/settings", title: _vm.tr("Settings") } },
                  [
                    _c("i", { staticClass: "i icon gear" }),
                    _c("span", [_vm._v(_vm._s(_vm.tr("Settings")))]),
                  ]
                ),
                _c(
                  "access-handled-router-link",
                  { attrs: { to: "/account", title: _vm.tr("Account") } },
                  [
                    _c("i", { staticClass: "i icon profile" }),
                    _c("span", [_vm._v(_vm._s(_vm.tr("Account")))]),
                  ]
                ),
                _c("div", { staticClass: "divider" }),
                _c(
                  "a",
                  {
                    attrs: { title: _vm.tr("Log out") },
                    on: { click: () => _vm.logout() },
                  },
                  [
                    _c("i", { staticClass: "i icon logout" }),
                    _c("span", [_vm._v(_vm._s(_vm.tr("Log out")))]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "bottom" }, [_c("language-selector")], 1),
      ]),
      _c(
        "div",
        {
          staticClass: "toggle-button",
          class: { "remove-animation": _vm.removeAnimation },
          on: { click: () => _vm.toggle() },
        },
        [_c("i", { staticClass: "icon arrow primary" })]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("div", { staticClass: "logo-inline-without-logo" }),
      _c("i", { staticClass: "icon main-logo primary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }