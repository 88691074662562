var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "refresh-button-component": true, loading: _vm.isLoading },
      on: { click: () => _vm.onClick() },
    },
    [
      !_vm.labelAsTooltip
        ? [
            _c("i", { class: "icon refresh " + _vm.colorClass }),
            _vm._v(" " + _vm._s(_vm.label) + " "),
          ]
        : [
            _c("i", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.label,
                  expression: "label",
                },
              ],
              class: "icon refresh " + _vm.colorClass,
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }