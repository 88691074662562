import AutoCtaFunctionMixin from '@/mixins/RuleForm/Functions/AutoCta.js';
import MetaOdaxMixin from '@/mixins/RuleForm/Functions/MetaOdax.js';

import FormBlock from '@/components/FormBlock.vue';

export default {
  mixins: [ MetaOdaxMixin, AutoCtaFunctionMixin ],
  components: {
    FormBlock,
  },
  computed: {
    entity() {
      return this.$store.getters['ruleForm/entity'];
    },
  },
  methods: {
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      return true;
    },
    validateBlocks() {
      for (let child of this.$children) {
        if (typeof child.validate !== 'function') {
          continue;
        }

        let isValid = child.validate();
        if (!isValid) {
          return false;
        }
      }

      return true;
    },
  },
}
