var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass:
        "rule-form-block campaign-special-ad-categories-block-component",
      attrs: {
        title: _vm.tr("Special ad categories"),
        description: _vm.description,
        disabled: _vm.disabled,
        switchable: "",
      },
      model: {
        value: _vm.isEnabled,
        callback: function ($$v) {
          _vm.isEnabled = $$v
        },
        expression: "isEnabled",
      },
    },
    [
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Categories"))),
          ]),
          _c("v-select", {
            attrs: {
              options: _vm.facebookSpecialAdCategories,
              reduce: (obj) => obj.machine_name,
              disabled: _vm.disabled,
              searchable: false,
              multiple: "",
            },
            model: {
              value: _vm.entity.output_campaign.special_ad_categories,
              callback: function ($$v) {
                _vm.$set(
                  _vm.entity.output_campaign,
                  "special_ad_categories",
                  $$v
                )
              },
              expression: "entity.output_campaign.special_ad_categories",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Countries"))),
          ]),
          _c("v-select", {
            attrs: {
              options: _vm.facebookSpecialAdCountries,
              reduce: (obj) => obj.iso_a2,
              disabled: _vm.disabled,
              label: "name",
              multiple: "",
            },
            model: {
              value: _vm.entity.output_campaign.special_ad_category_country,
              callback: function ($$v) {
                _vm.$set(
                  _vm.entity.output_campaign,
                  "special_ad_category_country",
                  $$v
                )
              },
              expression: "entity.output_campaign.special_ad_category_country",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }