import BaseEntity from '@/entities/BaseEntity';

import User from '@/entities/User';

export default class ConnectedProfile extends BaseEntity
{
  id = null;
  external_id = null;
  platform = null;
  name = '';
  picture = null;
  missing_scope = false;
  token_expire_at = null;
  owner = null;
  details = {};
  deleted_at = null;

  _entityFields = {
    owner: User,
  };

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Disconnected] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }

  updateWith(newValues) {
    if (!super.updateWith(newValues)) {
      return;
    }

    for (const [key, value] of Object.entries(newValues)) {
      if (!(key in this)) {
        continue;
      }

      if (
        key === 'token_expire_at'
        && !!value
      ) {
        this[key] = new Date(value);
      }
    }
  }

  get machine_name() {
    return this.platform + '_' + this.id;
  }
}
