<template>
  <div class="change-subscription-form-component">
    <form-block
      class="change-subscription-form"
      :title="tr('Payment')"
      :description="blockDescription"
    >
      <div v-if="availablePackages.length === 0" class="inline-loader">
        <i class="icon loader" />
        {{ tr('Loading...') }}
      </div>
      <template v-else>
        <div>
          <label class="input-label">{{ tr('Billing period') }}</label>
          <div class="checkbox-list horizontal">
            <label>
              <input
                v-model="billingPeriod"
                type="radio"
                name="billing_period"
                value="monthly"
                :disabled="billingPeriodSelectorDisabled"
              >
              {{ tr('Monthly') }}
            </label>

            <label>
              <input
                v-model="billingPeriod"
                type="radio"
                name="billing_period"
                value="annual"
                :disabled="billingPeriodSelectorDisabled"
              >
              {{ tr('Annual') }}
            </label>
          </div>
        </div>

        <div>
          <label class="input-label">{{ tr('Subscription package') }}</label>
          <v-select
            v-model="selectedPackage"
            :options="selectablePackages"
            :placeholder="tr('Choose a subscription plan')"
            :clearable="false"
            :searchable="false"
          >
            <template #option="option">
              {{ option.name }} - {{ formatPrice(option.price, '€', option.period) }}
            </template>
            <template #selected-option="option">
              {{ option.name }} - {{ formatPrice(option.price, '€', option.period) }}
            </template>
          </v-select>
        </div>

        <div>
          <label class="input-label">{{ tr('Connectable assets') }}</label>
          <template v-if="$app.isBuzz">
            {{ selectedPackage ? formatPcs(selectedPackage.max_assets) : '-' }}
          </template>
          <template v-else>
            <input
              v-model="requiredAssetCount"
              type="number"
              step="1"
              :min="selectedPackage ? selectedPackage.max_assets : null"
              :disabled="requiredAssetCountDisabled"
              @change="() => correctingRequiredAssetsCount()"
            >
            <small v-if="selectedPackage">
              {{
                tr(
                  'Of which :number included in the subscription package.',
                  { ':number': formatPcs(selectedPackage.max_assets) }
                )
              }}
              <template v-if="selectedPackage.extra_asset_price">
                {{
                  tr(
                    'Each additional asset costs :price.',
                    { ':price': formatPrice(selectedPackage.extra_asset_price, '€', selectedPackage.period) }
                  )
                }}
              </template>
            </small>
          </template>
        </div>

        <info-text v-if="requiredAssetCount !== null && company.usedAssetsCount > requiredAssetCount" type="warning">
          {{ tr('Your company currently has :num connected assets.', { ':num': company.usedAssetsCount }) }}
          {{ $app.isAd ? tr('If you reduce the number of connectable assets below this number, it will cause the automations to stop.') : '' }}
          {{ $app.isBuzz ? tr('If you reduce the number of connectable assets below this number, it will cause the moderation to stop.') : '' }}
        </info-text>

        <div v-if="!$app.isBuzz">
          <label class="input-label">{{ tr('Maximum users') }}</label>
          {{ selectedPackage ? formatPcs(selectedPackage.max_users) : '-' }}
        </div>

        <div v-if="featureListIsVisible">
          <label class="input-label">{{ tr('Extra services') }}</label>
          <div class="checkbox-list vertical">
            <label v-if="featureManagedAccountIsAvailable">
              <input v-model="requiredFeatureManagedAccount" type="checkbox" :disabled="managedAccountCheckboxDisabled" />
              {{ tr('Managed Account Service') }}
              <small>+{{ formatPrice(selectedPackage.feature_managed_account_price, '€', selectedPackage.period) }}</small>
              <i
                v-tooltip="{ content: tr('Get up to 1 hour of expert assistance per month (in two 30-minute sessions) to optimize your Infinite∞Ad campaigns and maximize your advertising success.'), classes: [ 'textonly' ] }"
                class="icon info light-black"
              />
            </label>
          </div>
        </div>

        <div class="extra-services-info-block">
          <info-text v-if="showManagedAccountContactMessage">
            {{ tr('Our partner will soon contact you regarding the Managed Account service you requested.') }}
          </info-text>

          <info-text v-if="managedAccountCheckboxDisabled">
            {{ tr('The "Managed Account Service" can be canceled no earlier than :date', { ':date': managedAccountCancellableDate }) }}
          </info-text>
          <info-text v-else-if="showManagedAccountCancelInfoBefore">
            {{ tr('When requesting the "Managed Account Service", the first fee will be determined proportionally according to your billing cycle, but you will not have the opportunity to cancel the service before the next billing. If you request it now, the first opportunity to cancel the service will be on :date', { ':date': managedAccountCancellableDate }) }}
          </info-text>
        </div>

        <div class="coupon-code-block">
          <label class="input-label">{{ tr('Coupon code') }}</label>
          <div class="input-wrapper">
            <input
              v-model="couponCode"
              type="text"
              :disabled="hadPreviousSubscription"
              :class="{ 'error': !couponValidation.isValid && couponValidation.message }"
            />
            <i v-if="couponValidation.isLoading" class="icon loader primary" />
          </div>

          <info-text v-if="hadPreviousSubscription && !couponCode">
            {{ tr('You can only use a coupon code on your first subscription') }}
          </info-text>

          <info-text v-else-if="couponValidation.isValid" type="info">
            {{ tr('This coupon provides :discount% discount on the base subscription price', { ':discount': couponValidation.discount }) }}
          </info-text>
          <info-text v-else-if="couponValidation.error" type="error">
            {{ couponValidation.error }}
          </info-text>
        </div>

        <div class="bottom-bar">
          <div class="cost-wrapper">
            <label>{{ tr('Cost') }}</label>
            <i v-if="costIsLoading" class="icon loader primary" />
            <span v-else class="price">
              <template v-if="selectedPackage && firstCostIsLoaded">
                <template v-if="vatRate">
                  {{ formatPrice(totalCost * (1 + vatRate), '€', selectedPackage.period) }}
                  <small v-if="totalCost > 0">
                    ({{ tr('net') + ' ' + formatPrice(totalCost, '€') }} + {{ vatRate * 100 }}% {{ tr('VAT') }})
                  </small>
                </template>
                <template v-else>
                  {{ formatPrice(totalCost, '€', selectedPackage.period) }}
                </template>
              </template>
              <template v-else>
                -
              </template>
            </span>
          </div>

          <div v-if="payableNow" class="broken-period-cost">
            <b>{{ tr('Payable now') }}:</b>
            <template v-if="vatRate">
              {{ formatPrice(payableNow * (1 + vatRate), '€') }}
              <small>
                ({{ tr('net') + ' ' + formatPrice(payableNow, '€') }} + {{ vatRate * 100 }}% {{ tr('VAT') }})
              </small>
            </template>
            <template v-else>
              {{ formatPrice(payableNow, '€') }}
            </template>
          </div>
        </div>
      </template>
    </form-block>
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import InfoText from '@/components/InfoText.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import PaymentHistory from '@/entities/PaymentHistory';
import PaymentHistoryService from '@/services/PaymentHistory';
import SubscriptionService from '@/services/Subscription';
import SubscriptionCouponService from '@/services/SubscriptionCoupon';
import { formatToStringMD, modifyDate } from '@/utils/date.js';
import debounce from '@/utils/debounce.js';
import { formatPrice } from '@/utils/number.js';
import { clearCouponCode, getCouponCode } from '@/utils/storage.js';
import { formatPcs } from '@/utils/string.js';

export default {
  name: 'ChangeSubscriptionForm',
  components: {
    FormBlock,
    InfoText,
  },
  props: {
    billingCountry: {
      type: String,
      required: false,
      default: () => null,
    },
    vatNumber: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      billingPeriod: 'monthly',
      selectedPackage: null,
      requiredAssetCount: null,
      requiredFeatureManagedAccount: false,
      availablePackages: [],

      costUpdater: debounce(() => this.updateCost(), 800),
      debouncedCorrectingRequiredAssetsCount: debounce(() => this.correctingRequiredAssetsCount(), 1000),
      costIsLoading: false,
      firstCostIsLoaded: false,
      totalCost: null,
      payableNow: null,

      originalValues: {},
      hadPreviousSubscription: false,
      couponCode: '',
      couponValidation: {
        isLoading: false,
        isValid: false,
        discount: null,
        error: null,
      },
      validateCouponDebounced: debounce(() => this.validateCoupon(), 800),
    };
  },
  computed: {
    company() {
      return this.$root.currentCompany;
    },
    computedBillingCountry() {
      return this.billingCountry ?? this.company.billing_country;
    },
    computedVatNumber() {
      return this.vatNumber ?? this.company.billing_vat_number;
    },
    vatRate() {
      if (!this.computedBillingCountry) {
        return 0;
      }

      if (this.computedBillingCountry === 'HU' || !this.computedVatNumber) {
        return 0.27;
      }

      return 0;
    },
    blockDescription() {
      if (!this.company.paid_subscription?.package) {
        return null;
      }

      return this.tr('In case of switching to a smaller subscriber package or canceling services, you can use the service ' +
          'you have already paid for until the end of the current billing period.') +
        '<br>' +
        this.tr('In the event of switching to a higher subscription package or requesting new services, we will calculate ' +
          'a fee based on your current billing period and you can start using it immediately.');
    },
    selectablePackages() {
      return this.availablePackages.filter(item => item.period === this.billingPeriod);
    },
    billingPeriodSelectorDisabled() {
      const current = this.company.current_subscription?.package;
      const paid = this.company.paid_subscription?.package;

      if (paid) {
        return true;
      }

      return !current || current.machine_name.indexOf('free_trial') === -1;
    },
    requiredAssetCountDisabled() {
      return !this.selectedPackage || this.selectedPackage.max_assets === null;
    },
    requiredExtraAssets() {
      if (this.$app.isBuzz) {
        return 0;
      }

      if (!this.selectedPackage) {
        return 0;
      }

      let result = this.requiredAssetCount - this.selectedPackage.max_assets;
      if (result < 0) {
        result = 0;
      }

      return result;
    },
    featureManagedAccountIsAvailable() {
      return this.selectedPackage && this.selectedPackage.feature_managed_account_price !== null;
    },
    featureListIsVisible() {
      if (this.$app.isBuzz || !this.selectedPackage) {
        return false;
      }

      return this.featureManagedAccountIsAvailable;
    },
    managedAccountCheckboxDisabled() {
      return this.company.current_subscription?.feature_managed_account && this.company.feature_managed_account_cancellable_at > new Date();
    },
    showManagedAccountCancelInfoBefore() {
      if (!this.company.current_subscription?.package || this.company.current_subscription?.package.is_free_trial) {
        return false;
      }

      return this.requiredFeatureManagedAccount && !this.company.current_subscription?.feature_managed_account;
    },
    managedAccountCancellableDate() {
      if (this.company.feature_managed_account_cancellable_at) {
        return formatToStringMD(this.company.feature_managed_account_cancellable_at, this.tr);
      }

      const date = modifyDate(this.company.next_payment_date, '+1 day');

      return formatToStringMD(date, this.tr);
    },
    showManagedAccountContactMessage() {
      return !this.company.has_manager_company
        && (this.company.paid_subscription?.feature_managed_account || this.company.current_subscription?.feature_managed_account);
    },
    buttonLabel() {
      if (this.company.current_subscription?.package !== null && !this.company.current_subscription?.package.is_free_trial) {
        return this.tr('Change subscription');
      }

      return this.tr('Choose subscription package');
    },
  },
  watch: {
    billingPeriod() {
      if (this.availablePackages.length === 0) {
        return;
      }

      this.selectedPackage = null;
      this.requiredAssetCount = null;
    },
    selectedPackage(val, oldVal) {
      if (this.availablePackages.length === 0) {
        return;
      }

      if (val && (oldVal || !this.requiredAssetCount)) {
        if (this.selectedPackage.max_assets < this.company.usedAssetsCount) {
          this.requiredAssetCount = this.company.usedAssetsCount;
        }
        else {
          this.requiredAssetCount = this.selectedPackage.max_assets;
        }
      }

      this.costUpdater();
    },
    requiredAssetCount(val) {
      if (this.availablePackages.length === 0) {
        return;
      }
      if (!this.selectedPackage) {
        return;
      }

      if (val < this.selectedPackage.max_assets) {
        this.debouncedCorrectingRequiredAssetsCount();
      }
      else {
        this.debouncedCorrectingRequiredAssetsCount.cancel();
        this.costUpdater();
      }
    },
    requiredFeatureManagedAccount() {
      if (this.availablePackages.length === 0) {
        return;
      }
      if (!this.selectedPackage) {
        return;
      }

      this.costUpdater();
    },
    requiredAssetCountDisabled(val) {
      if (val) {
        this.requiredAssetCount = null;
      }
    },
    featureManagedAccountIsAvailable(val) {
      if (!val) {
        this.requiredFeatureManagedAccount = false;
      }
    },
    couponCode() {
      if (this.hadPreviousSubscription) {
        return;
      }
      this.costUpdater();
      this.validateCouponDebounced();
    },
  },
  created() {
    if (this.company.paid_subscription?.package) {
      if (this.company.paid_subscription.package.max_assets !== null) {
        this.requiredAssetCount = '' + (this.company.paid_subscription.package.max_assets + this.company.paid_subscription?.extra_assets);
      }
      if (this.company.paid_subscription?.feature_managed_account) {
        this.requiredFeatureManagedAccount = true;
      }
    }

    SubscriptionService.getPackages()
      .then(resp => {
        let searchablePackageId;
        if (this.company.paid_subscription?.package) {
          searchablePackageId = this.company.paid_subscription.package.id;
        }

        if (searchablePackageId) {
          this.selectedPackage = resp.find(item => item.id === searchablePackageId);
          if (this.selectedPackage) {
            this.billingPeriod = this.selectedPackage.period;
          }
        }

        this.originalValues.billingPeriod = this.billingPeriod ?? null;
        this.originalValues.selectedPackage = this.selectedPackage ?? null;
        this.originalValues.requiredAssetCount = this.requiredAssetCount !== null ? ('' + this.requiredAssetCount) : null;
        this.originalValues.requiredFeatureManagedAccount = this.requiredFeatureManagedAccount;

        this.updateCost();

        this.$nextTick(() => this.availablePackages = resp);

        return resp;
      })
      .catch(() => this.error('Failed to load subscription packages'));

    this.hadPreviousSubscription = this.company.paid_subscription !== null || (this.company.current_subscription !== null && !this.company.current_subscription.package?.is_free_trial);
    if (!this.hadPreviousSubscription) {
      SubscriptionService.hadPreviousSubscription()
        .then(resp => {
          this.hadPreviousSubscription = resp;

          const storedCouponCode = getCouponCode();
          if (storedCouponCode) {
            this.couponCode = storedCouponCode;
            this.validateCoupon();
          }

          return resp;
        })
        .catch(() => {
          this.error('Failed to check previous subscription');
          this.hadPreviousSubscription = true;
        });
    }

    if (this.company.paid_subscription?.coupon?.code) {
      const coupon = this.company.paid_subscription.coupon;

      this.couponValidation = {
        isValid: true,
        discount: coupon.discount,
        isLoading: false,
      };
      this.couponCode = this.company.paid_subscription.coupon.code;
    }
  },
  methods: {
    formatPrice,
    formatPcs,
    updateCost() {
      if (!this.selectedPackage) {
        return;
      }

      this.costIsLoading = true;

      SubscriptionService.calculateCost({
        packageId: this.selectedPackage.id,
        extraAssets: this.requiredExtraAssets,
        featureManagedAccount: this.requiredFeatureManagedAccount,
        couponCode: this.couponCode,
      })
        .then(resp => {
          this.totalCost = resp.total_cost;
          this.payableNow = resp.payable_now;
          this.firstCostIsLoaded = true;

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.costIsLoading = false);
    },
    async validateCoupon() {
      if (!this.couponCode) {
        this.couponValidation = {
          isLoading: false,
          isValid: false,
          discount: null,
          error: null,
        };
        return;
      }

      this.couponValidation.isLoading = true;

      const response = await SubscriptionCouponService.validateCoupon(this.couponCode);
      if (response.error) {
        this.couponValidation = {
          isLoading: false,
          isValid: false,
          discount: null,
          error: response.error,
        };
        return;
      }
      this.couponValidation = {
        isLoading: false,
        isValid: true,
        discount: response.discount,
        error: null,
      };
    },
    async save() {
      if (this.isLoading) {
        return;
      }

      if (!this.selectedPackage) {
        return;
      }

      this.isLoading = true;

      try {
        const resp = await SubscriptionService.change({
          packageId: this.selectedPackage.id,
          extraAssets: this.requiredExtraAssets,
          featureManagedAccount: this.requiredFeatureManagedAccount,
          couponCode: this.couponCode,
        });

        if (resp.action_required === 'open_payment') {
          this.infoToast(this.tr('Automatic payment failed.') + ' ' +
            resp.message + ' ' +
            this.tr('The use of a payment form is required.'));

          const paymentHistory = new PaymentHistory(resp.payment_history);
          this.$modal.show(
            PaymentModal,
            {
              paymentHistory,
              onSuccess: () => setTimeout(() => window.location.reload(), 500),
            },
            { class: '' },
          );
          this.isLoading = false;
          return;
        }

        if (resp.payment_history) {
          const paymentHistory = resp.payment_history;
          await this.checkPaymentHistoryStatus(paymentHistory.id);
        }

        this.$root.fetchUserData();

        this.$toast.clear();
        this.$toast.success(this.tr('Your subscription has been successfully modified.'));
        this.$modal.hide('change-subscription-modal');

        clearCouponCode();
      }
      catch (err) {
        this.error(err);
      }

      this.isLoading = false;
    },
    checkPaymentHistoryStatus(id, originalResolver, originalRejecter) {
      return new Promise((resolve, reject) => {
        if (!originalResolver) {
          originalResolver = resolve;
        }
        if (!originalRejecter) {
          originalRejecter = reject;
        }

        PaymentHistoryService.get(id)
          .then(entity => {
            if (entity.status === 'success') {
              const currentActivePackage = this.$root.currentCompany.current_subscription.package;
              if (
                !currentActivePackage
                || currentActivePackage.machine_name.indexOf('free_trial') !== -1
              ) {
                if (window.fbq) {
                  window.fbq('trackCustom', 'SubscriptionStarted');
                }
                if (window.ttq) {
                  window.ttq.track('SubscriptionStarted');
                  window.ttq.track('Subscribe');
                }
                if (window.gtag) {
                  if (this.$app.isBuzz) {
                    window.gtag('event', 'conversion', { 'send_to': 'AW-11432616777/KS0dCOfJ4_wYEMnGv8sq' });
                  }
                }
              }

              originalResolver();
              return entity;
            }
            if (entity.status === 'failed') {
              originalRejecter(new Error(entity.fail_reason));
              return entity;
            }

            setTimeout(() => this.checkPaymentHistoryStatus(id, originalResolver, originalRejecter), 2000);
            return entity;
          })
          .catch(err => reject(err));
      });
    },
    correctingRequiredAssetsCount() {
      if (this.requiredAssetCount < this.selectedPackage.max_assets) {
        this.requiredAssetCount = this.selectedPackage.max_assets;
      }
    },
    isChanged() {
      if (
        'billingPeriod' in this.originalValues
        && JSON.stringify(this.billingPeriod) != JSON.stringify(this.originalValues.billingPeriod)
      ) {
        return true;
      }
      if (
        'selectedPackage' in this.originalValues
        && JSON.stringify(this.selectedPackage) != JSON.stringify(this.originalValues.selectedPackage)
      ) {
        return true;
      }
      if (
        'requiredAssetCount' in this.originalValues
        && JSON.stringify(this.requiredAssetCount) != JSON.stringify(this.originalValues.requiredAssetCount)
      ) {
        return true;
      }
      if (
        'requiredFeatureManagedAccount' in this.originalValues
        && JSON.stringify(this.requiredFeatureManagedAccount) != JSON.stringify(this.originalValues.requiredFeatureManagedAccount)
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>
