var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multiple-audience-select" },
    [
      _vm._l(_vm.internalValue, function (audienceId, key) {
        return _c(
          "div",
          { key: "audience-row-" + key, staticClass: "row-wrapper" },
          [
            _c(
              "div",
              { staticClass: "select-search-input-wrapper" },
              [
                _c("v-select", {
                  ref: "select",
                  refInFor: true,
                  attrs: {
                    options: _vm.audiences,
                    reduce: (obj) => obj.id,
                    clearable: false,
                    searchable: false,
                    placeholder: _vm.tr("Select target audience"),
                    dropdownShouldOpen: () => false,
                    disabled: _vm.disabled,
                    loading: _vm.isLoading,
                    label: "name",
                  },
                  on: {
                    open: () => (_vm.openedDropdownIndex = key),
                    "dropdown-should-open": () =>
                      _vm.openedDropdownIndex === key,
                  },
                  model: {
                    value: _vm.internalValue[key],
                    callback: function ($$v) {
                      _vm.$set(_vm.internalValue, key, $$v)
                    },
                    expression: "internalValue[key]",
                  },
                }),
                _vm.openedDropdownIndex === key
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.term,
                          expression: "term",
                        },
                      ],
                      ref: "searchInput",
                      refInFor: true,
                      staticClass: "search-input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.term },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return (() => (_vm.openedDropdownIndex = null)).apply(
                            null,
                            arguments
                          )
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.term = $event.target.value
                        },
                      },
                    })
                  : _vm._e(),
                _vm.openedDropdownIndex === key
                  ? _c("i", {
                      staticClass:
                        "icon arrow light-black icon-on-search-input",
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.showDeleteButton
              ? _c("i", {
                  staticClass: "icon delete dark-gray",
                  on: { click: () => _vm.removeRow(key) },
                })
              : _vm._e(),
            !_vm.disabled
              ? _c(
                  "div",
                  {
                    class: {
                      menu: true,
                      hidden: _vm.openedDropdownIndex !== key,
                    },
                  },
                  [
                    _vm.externalAudiences.length === 0 &&
                    _vm.internalAudiences.length === 0
                      ? _c("div", { staticClass: "empty-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "There are no target audiences available."
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.externalAudiences.length !== 0
                      ? _c(
                          "dropdown-menu",
                          {
                            attrs: {
                              interactive: false,
                              "close-on-click-outside": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "dropdown",
                                  fn: function () {
                                    return _vm._l(
                                      _vm.externalAudiences.filter((item) =>
                                        item.name
                                          .toLowerCase()
                                          .includes(
                                            _vm.term.toLocaleLowerCase()
                                          )
                                      ),
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "option",
                                            class: {
                                              selected:
                                                _vm.internalValue.includes(
                                                  item.id
                                                ),
                                            },
                                            on: {
                                              click: () =>
                                                _vm.selectValue(key, item.id),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.isExternalOpen,
                              callback: function ($$v) {
                                _vm.isExternalOpen = $$v
                              },
                              expression: "isExternalOpen",
                            },
                          },
                          [
                            _c("div", { staticClass: "group-label" }, [
                              _c("i", {
                                class: {
                                  "icon arrow dark-gray": true,
                                  opened: _vm.isExternalOpen,
                                },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.tr(_vm.externalLabel)) + " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.internalAudiences.length !== 0
                      ? _c(
                          "dropdown-menu",
                          {
                            attrs: {
                              interactive: false,
                              "close-on-click-outside": false,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "dropdown",
                                  fn: function () {
                                    return _vm._l(
                                      _vm.internalAudiences.filter((item) =>
                                        item.name
                                          .toLowerCase()
                                          .includes(
                                            _vm.term.toLocaleLowerCase()
                                          )
                                      ),
                                      function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item.id,
                                            staticClass: "option",
                                            class: {
                                              selected:
                                                _vm.internalValue.includes(
                                                  item.id
                                                ),
                                            },
                                            on: {
                                              click: () =>
                                                _vm.selectValue(key, item.id),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.isInternalOpen,
                              callback: function ($$v) {
                                _vm.isInternalOpen = $$v
                              },
                              expression: "isInternalOpen",
                            },
                          },
                          [
                            _c("div", { staticClass: "group-label" }, [
                              _c("i", {
                                class: {
                                  "icon arrow dark-gray": true,
                                  opened: _vm.isInternalOpen,
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.tr("Saved on Infinite∞Ad")) +
                                  " "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
      _vm.showAddButton
        ? _c(
            "button",
            {
              staticClass: "button primary",
              attrs: {
                disabled: _vm.disabled || _vm.internalValue.includes(""),
              },
              on: { click: () => _vm.addNewRow() },
            },
            [_vm._v(" + " + _vm._s(_vm.tr("Add audience")) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }