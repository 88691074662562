import BaseEntity from '@/entities/BaseEntity';
import Company from '@/entities/Company';
import ConnectedProfile from '@/entities/ConnectedProfile';

export default class User extends BaseEntity
{
  id = null;
  firstname = '';
  lastname = '';
  email = '';
  company = null;
  level = null;
  language = 'en';
  connected_profiles = [];
  visible_ad_account_ids = [];
  visible_source_profile_ids = [];
  google_2fa_enabled = false;
  created_at = new Date();

  _entityFields = {
    company: Company,
  };
  _entityArrayFields = {
    connected_profiles: ConnectedProfile,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get fullname() {
    return (this.firstname + ' ' + this.lastname).trim();
  }

  get facebookProfiles() {
    return this.connected_profiles.filter(i => i.platform === 'facebook');
  }

  get facebookAdAccounts() {
    let result = [];

    this.facebookProfiles.forEach(profile => {
      if (!profile.details || !profile.details.ad_accounts) {
        return;
      }

      result = result.concat(profile.details.ad_accounts);
    });

    return result;
  }

  get isRestricted() {
    return this.level.indexOf('restricted') !== -1;
  }

  get isRestrictedViewer() {
    return this.level === 'restricted_viewer';
  }
}
