<template>
  <div class="condition-row">
    <div class="bordered">
      <div class="icon-container">
        <i class="i icon dots" />
      </div>
      <v-select
        v-model="value.field"
        :options="fieldList"
        :reduce="(obj) => obj.machine_name"
        :searchable="true"
        :clearable="false"
        :placeholder="tr('Select filter options')"
        :disabled="disabled"
        :calculate-position="pimpedSelectPositionCalculator"
        @option:selecting="onSelectField"
        @option:selected="() => $nextTick(() => changingManually = false)"
      >
        <template #option="field">
          <!-- Don't ask about the position of the v-tooltip! It just works. -->
          <span
            :class="{ 'disabled-option': field.disabled }"
            v-tooltip.left="field.tooltip"
          >
            {{ tr(field.label) }}
          </span>
        </template>

        <template #selected-option="field">
          <div
            :class="{ 'disabled-option': field.disabled }"
            v-tooltip.right="field.tooltip"
          >
            {{ tr(field.label) }}
            <i v-if="field.disabled" class="icon warning red" />
          </div>
        </template>
      </v-select>

      <template v-if="showSubfields">
        <v-select
          v-if="loadingSubfields"
          :placeholder="tr('Loading...')"
          disabled
        />
        <v-select
          v-else
          v-model="value.subfield"
          :options="subfields"
          :reduce="(obj) => obj.machine_name"
          :searchable="true"
          :clearable="false"
          :placeholder="tr('Select an action type')"
          :disabled="disabled"
          :loading="loadingSubfields"
          :calculate-position="pimpedSelectPositionCalculator"
        />
      </template>

      <v-select
        v-if="showDatePeriods"
        v-model="value.date_period"
        :options="datePeriods"
        :reduce="(obj) => obj.machine_name"
        :searchable="false"
        :clearable="false"
        :disabled="disabled"
      />

      <v-select
        v-model="value.comparison"
        :options="operatorOptions"
        :reduce="(obj) => obj.machine_name"
        :searchable="false"
        :clearable="false"
        :disabled="disabled || disabledComparisonSelect"
        :placeholder="tr('Select conditional rule')"
        class="comparison"
      />

      <div class="input">
        <div v-if="isDynamicOperator" class="dynamic-value">
          <input
            v-model="value.value"
            type="number"
            step="1"
            min="1"
            max="100"
            :disabled="disabled || disabledValueInput"
          />
          <v-select
            v-model="value.settings.dynamic_operator_direction"
            class="value-setting-select"
            :options="dynamicOperatorValueOptions"
            :reduce="(obj) => obj.machine_name"
            :searchable="false"
            :clearable="false"
            :disabled="disabled || disabledValueInput"
          />
          <v-select
            v-model="value.settings.dynamic_operator_area"
            class="area-select"
            :options="dynamicOperatorAreas"
            :reduce="(obj) => obj.machine_name"
            :searchable="false"
            :clearable="false"
            :disabled="disabled || disabledValueInput"
          />
        </div>
        <input
          v-else-if="this.isDataTypeString"
          v-model="value.value"
          type="text"
          :placeholder="tr('Set filter text')"
          :disabled="disabled || disabledValueInput"
        />
        <number-input
          v-else-if="this.isDataTypeInt"
          v-model="value.value"
          :step="1"
          :min="0"
          :placeholder="tr('Set number on filter (days etc.)')"
          :disabled="disabled || disabledValueInput"
        />
        <number-input
          v-else-if="isDataTypeFloat"
          v-model="value.value"
          :step="0.001"
          :min="0"
          :placeholder="tr('Set currency on filter')"
          :disabled="disabled || disabledValueInput"
        />
        <money-input
          v-else-if="isDataTypeMoney"
          v-model="value.value"
          :disabled="disabled || disabledValueInput"
          :currency="adAccount.currencyDetails ? adAccount.currencyDetails.code : 'USD'"
        />
        <input
          v-else
          type="text"
          disabled
          :placeholder="tr('No value option for current filter')"
        />
      </div>
    </div>

    <i v-if="removable && !disabled" class="i icon delete" @click="$emit('remove')" />
  </div>
</template>

<script>
import selectPositionCalculator from '@/utils/selectPositionCalculator.js';

import MoneyInput from '@/components/MoneyInput.vue';
import NumberInput from '@/components/NumberInput.vue';

import AdAccount from '@/entities/AdAccount';

import { ucfirst } from '@/utils/string';

const FIELD_DROPDOWN_CUSTOM_CLASS = 'condition-row--field-select-dropdown';

export default {
  name: 'ConnectionRow',
  components: {
    MoneyInput,
    NumberInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: false,
    },
    platforms: {
      type: Array,
      required: false,
      default: () => ['facebook', 'instagram'],
    },
    removable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    disablePostMetrics: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    adAccount: {
      type: AdAccount,
      required: false,
    },
    loadingSubfields: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    subfields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    changingManually: false,
  }),
  watch: {
    dataType() {
      if (!this.changingManually) {
        return;
      }

      this.value.value = '';
      this.value.comparison = '';
    },
    selectedField: {
      immediate: true,
      handler(field) {
        if (!field) {
          return;
        }

        if (field.dynamic_subfields) {
          this.$emit('loadSubfields');
        }
      },
    },
    showDatePeriods: {
      immediate: true,
      handler(val) {
        if (val) {
          if (!this.value.date_period) {
            this.value.date_period = 'last_30d';
          }
        }
        else {
          this.value.date_period = null;
        }
      }
    },
    isDynamicOperator(val, oldVal) {
      if (val && !oldVal) {
        this.value.settings.dynamic_operator_direction = this.dynamicOperatorValueOptions[0].machine_name;
        this.value.settings.dynamic_operator_area = this.dynamicOperatorAreas[0].machine_name;
      }
      else if (!val && oldVal) {
        this.value.settings.dynamic_operator_direction = null;
        this.value.settings.dynamic_operator_area = null;
      }
    },
    dynamicOperatorAreas(val, oldVal) {
      if (val && oldVal) {
        this.value.settings.dynamic_operator_area = this.dynamicOperatorAreas[0].machine_name;
      }
    },
    'value.value'(val) {
      if (this.isDynamicOperator) {
        if (val < 1) {
          this.value.value = 1;
        }
        else if (val > 100) {
          this.value.value = 100;
        }
      }
    },
  },
  computed: {
    fieldList() {
      let items = JSON.parse(JSON.stringify(this.$store.getters['app/fields']));

      items.forEach(field => {
        const allowedByPlatform = this.platforms.every((elem) => field.platforms.includes(elem));
        const allowedByAction = field.actions.includes(this.action);
        const disabledMetric = field.machine_name.startsWith('post.metric.') && this.disablePostMetrics;

        field.disabled = !allowedByPlatform || !allowedByAction || disabledMetric;

        if (field.disabled) {
          let disabledReason = null;

          if (!allowedByAction) {
            disabledReason = this.tr('This value is not available for the :action automation', { ':action': this.tr(this.action) });
          }
          else if (!allowedByPlatform) {
            const platform = this.platforms.filter(i => !field.platforms.includes(i))[0];
            disabledReason = this.tr('This value is not available for the :platform platform', { ':platform': ucfirst(platform) });
          }
          else if (disabledMetric) {
            disabledReason = this.tr('The information is not available for the posts of the selected data source');
          }

          if (disabledReason !== null) {
            field.tooltip = { content: disabledReason, container: '.' + FIELD_DROPDOWN_CUSTOM_CLASS };
          }
        }
      });

      items.sort((a, b) => {
        if (!a.disabled && b.disabled) {
          return -1;
        }
        else if (a.disabled && !b.disabled) {
          return 1;
        }
        else {
          return 0;
        }
      });

      return items;
    },
    selectedField() {
      return this.fieldList.find(
        (i) => i.machine_name === this.value.field
      );
    },
    datePeriods() {
      return this.selectedField.date_periods;
    },
    operatorOptions() {
      if (!this.selectedField) {
        return [];
      }

      let items = this.$store.getters['app/fieldComparisons'];
      let comparisons = items.filter((operator) =>
        operator.data_types.includes(this.selectedField.data_type)
        || (
          (this.isDataTypeInt || this.isDataTypeFloat || this.isDataTypeMoney)
          && operator.dynamic_num
        )
      );

      return comparisons;
    },
    selectedOperator() {
      if (!this.value.comparison) {
        return null;
      }

      return this.$store.getters['app/fieldComparisons'].find(
        (i) => i.machine_name === this.value.comparison
      );
    },
    dynamicOperatorValueOptions() {
      return this.selectedOperator ? this.selectedOperator.dynamic_value_options : [];
    },
    dynamicOperatorAreas() {
      if (!this.value.field || !this.selectedOperator || !this.selectedOperator.areas) {
        return null;
      }

      const fieldType = this.value.field.split('.')[0];

      if (!(fieldType in this.selectedOperator.areas)) {
        return null;
      }

      return this.selectedOperator.areas[fieldType];
    },
    dataType() {
      return this.selectedField ? this.selectedField.data_type : null;
    },
    isDataTypeString() {
      return this.dataType === 'string';
    },
    isDataTypeInt() {
      return this.dataType === 'int';
    },
    isDataTypeFloat() {
      return this.dataType === 'float';
    },
    isDataTypeMoney() {
      return this.dataType === 'money';
    },
    isDynamicOperator() {
      return this.selectedOperator && this.selectedOperator.dynamic_num;
    },
    showSubfields() {
      return this.selectedField && this.selectedField.dynamic_subfields;
    },
    showDatePeriods() {
      return this.selectedField
        && this.selectedField.date_periods
        && this.selectedField.date_periods.length !== 0;
    },
    disabledComparisonSelect() {
      return this.operatorOptions.length === 0 || (this.showSubfields && this.value.subfield === null);
    },
    disabledValueInput() {
      return this.disabledComparisonSelect || !this.value.comparison;
    },
  },
  methods: {
    pimpedSelectPositionCalculator(dropdownList, component, props) {
      selectPositionCalculator(dropdownList, component, props, FIELD_DROPDOWN_CUSTOM_CLASS);
    },
    onKeyUpNumberInput(event) {
      if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
        return;
      }

      let key = event.key;
      if (key.length !== 1) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (key === ',') {
        key = '.';
      }

      event.target.focus();
      document.execCommand('insertText', false, key);

      let v = JSON.parse(JSON.stringify(this.value));

      v.value = event.target.value;
      this.$emit('input', v);
    },
    onSelectField(field) {
      this.changingManually = true;
      if (field.disabled) {
        const oldVal = this.value.field;
        this.$nextTick(() => this.value.field = oldVal);
      }
    },
    validate() {
      if (!this.value.field) {
        this.error(this.tr('All fields must be filled in the filter conditions.'));
        return false;
      }

      const field = this.fieldList.find((i) => i.machine_name === this.value.field);
      if (field.disabled) {
        this.error(this.tr('A value has been selected in the filter conditions that is not available.'));
        return false;
      }

      return true;
    },
  },
};
</script>
