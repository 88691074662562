var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["read-more-text-component", _vm.isActiveClass] }, [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.text) } }),
    _c(
      "span",
      {
        on: {
          click: function ($event) {
            return _vm.readMore()
          },
        },
      },
      [_vm._v(_vm._s(_vm.readMoreText))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }