var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "magic-tool-item-details-component" },
    [
      _c("form-step-tab", { attrs: { items: _vm.tabs } }),
      _vm.isLoading
        ? _c("div", { staticClass: "inline-loader" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm.entity !== null
        ? _c("div", { staticClass: "scroll-wrapper" }, [
            _vm.isOriginal
              ? _c(
                  "div",
                  { staticClass: "original" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Original text")) + " "),
                    ]),
                    _c("facebook-ad-preview", {
                      attrs: {
                        text: _vm.entity.text,
                        "cta-title": _vm.entity.title,
                        "cta-description": _vm.entity.description,
                        "cta-button": _vm.previewCtaButton,
                        "img-url": _vm.entity.image
                          ? _vm.entity.image.url
                          : null,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "ai-values" },
                      [
                        _c("ai-score", {
                          attrs: {
                            value: _vm.entity.value,
                            label: _vm.tr("AI Score") + ":",
                          },
                        }),
                        _vm.entity.content_actuality_check_status !== null
                          ? _c(
                              "div",
                              { staticClass: "ai-inline-value" },
                              [
                                _vm.entity.contentActualityCheckInProgress ||
                                _vm.entity.expiry_date
                                  ? [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.tr("Content expired at")) +
                                            ":"
                                        ),
                                      ]),
                                      _vm.entity.contentActualityCheckInProgress
                                        ? _c("i", {
                                            staticClass: "icon loader",
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatToYMD(
                                                    _vm.entity.expiry_date
                                                  ) +
                                                    " / " +
                                                    _vm.formatTime(
                                                      _vm.entity.expiry_date
                                                    )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  : _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tr(
                                              "The content has no defined expiration date."
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "ai-inline-value" },
                          [
                            _c("b", [
                              _vm._v(_vm._s(_vm.tr("Meta policy check")) + ":"),
                            ]),
                            _vm.entity.policyCheckInProgress
                              ? _c("i", { staticClass: "icon loader" })
                              : _c("post-content-policy-check-status", {
                                  attrs: {
                                    value:
                                      _vm.entity.policy_check_is_prohibited,
                                  },
                                }),
                          ],
                          1
                        ),
                        _vm.entity.policy_check_is_prohibited &&
                        _vm.entity.policy_check_reason
                          ? _c("div", { staticClass: "ai-non-inline-value" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.tr("Reason for denial") + ":")
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.entity.policy_check_reason) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.entity.details_translation
                      ? _c("copy-evaluation", {
                          attrs: { details: _vm.entity.details_translation },
                        })
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "button red full-width",
                        on: { click: () => _vm.openDeleteConfirmationModal() },
                      },
                      [_vm._v(" " + _vm._s(_vm.tr("Delete evaluation")) + " ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "vertical-divider" }),
            _vm.isImproved
              ? _c(
                  "div",
                  { staticClass: "improved" },
                  [
                    !_vm.entity.improved_copy ||
                    _vm.entity.improved_copy.inProgress
                      ? _c("div", { staticClass: "request-improved" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "Click on 'Generate improved copy' to get your ad's improved version."
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "primary",
                              attrs: { disabled: _vm.entity.improved_copy },
                              on: { click: () => _vm.improveCopy() },
                            },
                            [
                              _vm.entity.improved_copy
                                ? _c("i", { staticClass: "icon loader white" })
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.tr("Generate improved copy")) +
                                  " "
                              ),
                            ]
                          ),
                          _vm.entity.improved_copy &&
                          _vm.entity.improved_copy.inProgress
                            ? _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tr(
                                        "We are already working on it! The evaluation will be completed within 1-2 minutes."
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.entity.improved_copy && _vm.entity.improved_copy.isDone
                      ? [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(_vm.tr("Improved text")) + " "),
                          ]),
                          _c("facebook-ad-preview", {
                            attrs: {
                              text: _vm.entity.improved_copy.improved_text,
                              "cta-title":
                                _vm.entity.improved_copy.improved_title,
                              "cta-description":
                                _vm.entity.improved_copy.improved_description,
                              "img-url": _vm.entity.image
                                ? _vm.entity.image.url
                                : null,
                              "cta-button": _vm.previewCtaButton,
                            },
                          }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.tr("Improved Content Scoring:"))),
                          ]),
                          _c("ai-score", {
                            attrs: { value: _vm.entity.improved_copy.value },
                          }),
                          _vm.entity.improved_copy.details_translation
                            ? _c("copy-evaluation", {
                                attrs: {
                                  details:
                                    _vm.entity.improved_copy
                                      .details_translation,
                                },
                              })
                            : _vm._e(),
                          _c("improved-content", {
                            attrs: {
                              title: _vm.entity.improved_copy.improved_title,
                              description:
                                _vm.entity.improved_copy.improved_description,
                              "post-text":
                                _vm.entity.improved_copy.improved_text,
                            },
                          }),
                        ]
                      : _vm._e(),
                    _vm.entity.improved_copy &&
                    !_vm.entity.improved_copy.isDone &&
                    !_vm.entity.improved_copy.inProgress
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "An error occurred while creating the corrected version."
                                )
                              ) +
                              " "
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }