var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapperClasses,
      on: {
        mouseenter: () => _vm.onHover(),
        mouseleave: () => _vm.debouncedOnLeave(),
      },
    },
    [
      _vm._m(0),
      _vm.popupInDom
        ? _c("div", { class: _vm.popupClasses }, [_vm._t("default")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-wrapper" }, [
      _c("i", { staticClass: "icon info primary" }),
      _c("i", { staticClass: "icon info-filled primary" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }