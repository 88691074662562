var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-form-view signup-form-view form" },
    [
      _c("div", { staticClass: "input-wrapper input-wrapper--first-name" }, [
        _c("div", { staticClass: "input-label" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.tr("First name"))),
            _c("sup", [_vm._v("*")]),
          ]),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.firstname,
              expression: "firstname",
            },
          ],
          attrs: {
            type: "text",
            tabindex: _vm.firstnameTabindex,
            disabled: _vm.isInvite,
          },
          domProps: { value: _vm.firstname },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.firstname = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "input-wrapper input-wrapper--last-name" }, [
        _c("div", { staticClass: "input-label" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.tr("Last name"))),
            _c("sup", [_vm._v("*")]),
          ]),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.lastname,
              expression: "lastname",
            },
          ],
          attrs: {
            type: "text",
            tabindex: _vm.lastnameTabindex,
            disabled: _vm.isInvite,
          },
          domProps: { value: _vm.lastname },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.lastname = $event.target.value
            },
          },
        }),
      ]),
      !_vm.isInvite
        ? [
            _c("div", { staticClass: "input-label" }, [
              _c("span", [_vm._v(_vm._s(_vm.tr("Organization name")))]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.companyName,
                  expression: "companyName",
                },
              ],
              attrs: { type: "text", tabindex: "3" },
              domProps: { value: _vm.companyName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.companyName = $event.target.value
                },
              },
            }),
          ]
        : _vm._e(),
      _c("div", { staticClass: "input-label" }, [
        _c("span", [_vm._v(_vm._s(_vm.tr("Email"))), _c("sup", [_vm._v("*")])]),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email",
          },
        ],
        attrs: { type: "email", tabindex: "4", disabled: _vm.isInvite },
        domProps: { value: _vm.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.email = $event.target.value
          },
        },
      }),
      _c("div", { staticClass: "input-label" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.tr("Password"))),
          _c("sup", [_vm._v("*")]),
        ]),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.password,
            expression: "password",
          },
        ],
        attrs: {
          type: "password",
          placeholder: _vm.tr("Enter at least 8 characters..."),
          tabindex: "5",
        },
        domProps: { value: _vm.password },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.password = $event.target.value
          },
        },
      }),
      _c("div", { staticClass: "input-label" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.tr("Password again"))),
          _c("sup", [_vm._v("*")]),
        ]),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.passwordAgain,
            expression: "passwordAgain",
          },
        ],
        attrs: {
          type: "password",
          placeholder: _vm.tr("Repeat your password"),
          tabindex: "6",
        },
        domProps: { value: _vm.passwordAgain },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.passwordAgain = $event.target.value
          },
        },
      }),
      _c("label", { staticClass: "checkbox-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.gtcCheckbox,
              expression: "gtcCheckbox",
            },
          ],
          attrs: { type: "checkbox", tabindex: "7" },
          domProps: {
            checked: Array.isArray(_vm.gtcCheckbox)
              ? _vm._i(_vm.gtcCheckbox, null) > -1
              : _vm.gtcCheckbox,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.gtcCheckbox,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.gtcCheckbox = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.gtcCheckbox = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.gtcCheckbox = $$c
              }
            },
          },
        }),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.gtcCheckboxLabel) } }),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
      ]),
      _c("label", { staticClass: "checkbox-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.privacyCheckbox,
              expression: "privacyCheckbox",
            },
          ],
          attrs: { type: "checkbox", tabindex: "8" },
          domProps: {
            checked: Array.isArray(_vm.privacyCheckbox)
              ? _vm._i(_vm.privacyCheckbox, null) > -1
              : _vm.privacyCheckbox,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.privacyCheckbox,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.privacyCheckbox = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.privacyCheckbox = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.privacyCheckbox = $$c
              }
            },
          },
        }),
        _c("span", {
          domProps: { innerHTML: _vm._s(_vm.privacyCheckboxLabel) },
        }),
        _c("span", { staticClass: "required" }, [_vm._v("*")]),
      ]),
      _c("label", { staticClass: "checkbox-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newsletterCheckbox,
              expression: "newsletterCheckbox",
            },
          ],
          attrs: { type: "checkbox", tabindex: "9" },
          domProps: {
            checked: Array.isArray(_vm.newsletterCheckbox)
              ? _vm._i(_vm.newsletterCheckbox, null) > -1
              : _vm.newsletterCheckbox,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.newsletterCheckbox,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.newsletterCheckbox = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.newsletterCheckbox = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.newsletterCheckbox = $$c
              }
            },
          },
        }),
        _vm._v(
          " " +
            _vm._s(
              _vm.tr(
                "I would like to be notified by email about news and the methods to make ads even more effective"
              )
            ) +
            " "
        ),
      ]),
      _c(
        "button",
        {
          staticClass: "primary",
          attrs: { tabindex: "10" },
          on: { click: _vm.submit },
        },
        [
          _vm.isLoading
            ? _c("i", {
                staticClass: "icon loader white",
                attrs: {
                  loading: _vm.isLoading,
                  color: "#ffffff",
                  size: "6px",
                },
              })
            : [_vm._v(" " + _vm._s(_vm.tr("Signup")) + " ")],
        ],
        2
      ),
      _c("router-link", { staticClass: "link", attrs: { to: "/auth/login" } }, [
        _c("u", [
          _vm._v(_vm._s(_vm.tr("If you already have an account, log in here"))),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "language-selector-wrapper" },
        [_c("language-selector")],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }