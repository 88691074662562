var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      _vm.isSkeleton
        ? [
            _c("skeleton", {
              attrs: { width: "62px", height: "62px", radius: "10px" },
            }),
            _c(
              "div",
              { staticClass: "text" },
              [
                _c("skeleton", { attrs: { width: "100%" } }),
                _c("skeleton", { attrs: { width: "98%" } }),
              ],
              1
            ),
          ]
        : [
            _c("lazy-image", { attrs: { src: _vm.imageUrl } }),
            _c("div", { staticClass: "text" }, [
              _vm._v(" " + _vm._s(_vm.computedText) + " "),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }