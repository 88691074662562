var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-view" },
    [
      _c("sticky-header", {
        attrs: { title: _vm.tr("Account"), bordered: "" },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "primary",
                    attrs: { loading: _vm.isSaving },
                    on: { click: () => _vm.save() },
                  },
                  [
                    _vm.isSaving
                      ? _c("i", { staticClass: "icon loader white" })
                      : [_vm._v(" " + _vm._s(_vm.tr("Save")) + " ")],
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "form-block",
        {
          staticClass: "account-view--basic-informations",
          attrs: { title: _vm.tr("Basic informations") },
        },
        [
          _c(
            "div",
            { staticClass: "input-wrapper input-wrapper--first-name" },
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("First name"))),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.firstname,
                    expression: "firstname",
                  },
                ],
                class: _vm.inputClasses("firstname"),
                attrs: { type: "text" },
                domProps: { value: _vm.firstname },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.firstname = $event.target.value
                  },
                },
              }),
            ]
          ),
          _c("div", { staticClass: "input-wrapper input-wrapper--last-name" }, [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Last name"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastname,
                  expression: "lastname",
                },
              ],
              class: _vm.inputClasses("lastname"),
              attrs: { type: "text" },
              domProps: { value: _vm.lastname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.lastname = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        { attrs: { title: _vm.tr("Login details") } },
        [
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("E-mail"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              class: _vm.inputClasses("email"),
              attrs: { type: "email" },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.email = $event.target.value
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Password"))),
            ]),
            _c(
              "button",
              {
                staticClass: "primary",
                on: { click: () => _vm.$refs["changePasswordModal"].open() },
              },
              [_vm._v(" " + _vm._s(_vm.tr("Change password")) + " ")]
            ),
          ]),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Two-factor authentication"))),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$root.loggedInUser.google_2fa_enabled,
                    expression: "$root.loggedInUser.google_2fa_enabled",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  readonly: "",
                  disabled: _vm.disabled2faCheckbox,
                },
                domProps: {
                  checked: Array.isArray(
                    _vm.$root.loggedInUser.google_2fa_enabled
                  )
                    ? _vm._i(_vm.$root.loggedInUser.google_2fa_enabled, null) >
                      -1
                    : _vm.$root.loggedInUser.google_2fa_enabled,
                },
                on: {
                  click: ($e) => _vm.toggle2fa($e),
                  change: function ($event) {
                    var $$a = _vm.$root.loggedInUser.google_2fa_enabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.$root.loggedInUser,
                            "google_2fa_enabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.$root.loggedInUser,
                            "google_2fa_enabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.$root.loggedInUser,
                        "google_2fa_enabled",
                        $$c
                      )
                    }
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$root.loggedInUser.google_2fa_enabled
                      ? _vm.tr("Google 2FA enabled")
                      : _vm.tr("Enable Google 2FA")
                  ) +
                  " "
              ),
            ]),
          ]),
          _vm.disabled2faCheckbox
            ? _c("info-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "For users in the organization, the use of two-factor authentication is teasing."
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("change-password-modal", { ref: "changePasswordModal" }),
      !_vm.$root.loggedInUser.google_2fa_enabled
        ? _c(
            "modal",
            {
              attrs: {
                name: "enable-google-2fa-modal",
                overlayTransition: "fade-modal-overlay",
              },
            },
            [
              _c("enable-google-2fa", {
                on: {
                  success: () => _vm.$modal.hide("enable-google-2fa-modal"),
                  cancel: () => _vm.$modal.hide("enable-google-2fa-modal"),
                },
              }),
            ],
            1
          )
        : !_vm.disabled2faCheckbox
        ? _c(
            "modal",
            {
              attrs: {
                name: "disable-google-2fa-modal",
                overlayTransition: "fade-modal-overlay",
              },
            },
            [
              _c("disable-google-2fa", {
                on: {
                  cancel: () => _vm.$modal.hide("disable-google-2fa-modal"),
                  success: () => _vm.$modal.hide("disable-google-2fa-modal"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }