<template>
  <div :class="wrapperClasses">
    <div class="inputs">
      <number-input
        v-model="dayInput"
        :min="1"
        :max="31"
        :step="1"
        :placeholder="tr('DD')"
        :disabled="disabled"
        @focus="(e) => onFocusInput(e)"
        @blur="() => onBlurInput()"
        @keydown="(e) => onKeyDown(e, 2)"
        @keyup="(e) => onKeyUp(e, 2)"
      />
      <span>/</span>
      <number-input
        v-model="monthInput"
        :min="1"
        :max="12"
        :step="1"
        :placeholder="tr('MM')"
        :disabled="disabled"
        @focus="(e) => onFocusInput(e)"
        @blur="() => onBlurInput()"
        @keydown="(e) => onKeyDown(e, 2)"
        @keyup="(e) => onKeyUp(e, 2)"
      />
      <span>/</span>
      <number-input
        v-model="yearInput"
        :min="2000"
        :max="2100"
        :step="1"
        class="year"
        :placeholder="tr('YYYY')"
        :disabled="disabled"
        @focus="(e) => onFocusInput(e)"
        @blur="() => onBlurInput()"
        @keydown="(e) => onKeyDown(e, 4)"
        @keyup="(e) => onKeyUp(e, 4)"
      />

      <template v-if="time">
        <number-input
          v-model="hourInput"
          :min="0"
          :max="23"
          :step="1"
          class="hour"
          :placeholder="tr('hh')"
          :disabled="disabled"
          @focus="(e) => onFocusInput(e)"
          @blur="() => onBlurInput()"
          @keydown="(e) => onKeyDown(e, 2)"
          @keyup="(e) => onKeyUp(e, 2)"
        />
        <span>:</span>
        <number-input
          v-model="minuteInput"
          :min="0"
          :max="59"
          :step="1"
          class="minute"
          :placeholder="tr('mm')"
          :disabled="disabled"
          @focus="(e) => onFocusInput(e)"
          @blur="() => onBlurInput()"
          @keydown="(e) => onKeyDown(e, 2)"
          @keyup="(e) => onKeyUp(e, 2)"
        />
        <span>:</span>
        <number-input
          v-model="secondInput"
          :min="0"
          :max="59"
          :step="1"
          class="second"
          :placeholder="tr('ss')"
          :disabled="disabled"
          @focus="(e) => onFocusInput(e)"
          @blur="() => onBlurInput()"
          @keydown="(e) => onKeyDown(e, 2)"
        />
      </template>
    </div>

    <div
      v-if="clearable"
      v-tooltip="tr('Clear')"
      class="icon-wrapper"
      @click="() => clear()"
    >
      <i class="icon close light-black" />
    </div>
  </div>
</template>

<script>
import NumberInput from '@/components/NumberInput.vue';

import debounce from '@/utils/debounce.js';

export default {
  name: 'DateInput',
  props: {
    value: {
      type: Date,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    time: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    NumberInput,
  },
  created() {
    this.loadInitValues();
  },
  data() {
    return {
      dayInput: null,
      monthInput: null,
      yearInput: null,
      hourInput: null,
      minuteInput: null,
      secondInput: null,
      focusedWrapper: false,
      debouncedFocusedWrapperUpdater: debounce((val) => this.focusedWrapper = val, 25),

      lastKeyDownTarget: null,
    };
  },
  watch: {
    focusedWrapper(newValue) {
      this.$toast.clear();

      if (!newValue) {
        this.strpadDayAndMonthValues();
        this.$nextTick(() => {
          if (this.errorState) {
            this.$toast.error(this.tr('Invalid date selected!'));
          }
          else {
            this.dateEmit();
          }
        });
      }
    },
    value() {
      this.loadInitValues();
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'date-input-component': true,
        'disabled': this.disabled,
        'editing': this.focusedWrapper,
        'error': this.errorState,
      }
    },
    monthInInt() {
      return parseInt(this.monthInput);
    },
    dayInInt() {
      return parseInt(this.dayInput);
    },
    hourInInt() {
      return parseInt(this.hourInput);
    },
    minuteInInt() {
      return parseInt(this.minuteInput);
    },
    secondInInt() {
      return parseInt(this.secondInput);
    },
    currentDateObject() {
      return new Date(this.yearInput, this.monthInInt - 1, this.dayInInt, this.hourInInt, this.minuteInInt, this.secondInInt, 0);
    },
    errorState() {
      if (this.focusedWrapper) {
        return false;
      }

      if (this.allowEmpty && this.isEmpty) {
        return false;
      }

      return !this.isValidDate;
    },
    isEmpty() {
      if (this.time) {
        if (
          (this.dayInput !== null && this.dayInput.toString().length !== 0)
          || (this.monthInput !== null && this.monthInput.toString().length !== 0)
          || (this.yearInput !== null && this.yearInput.toString().length !== 0)
        ) {
          return false;
        }
      }

      return (this.dayInput === null || this.dayInput.toString().length === 0)
        && (this.monthInput === null || this.monthInput.toString().length === 0)
        && (this.yearInput === null || this.yearInput.toString().length === 0);
    },
    isFilledInput() {
      if (this.time) {
        if (
          this.hourInput === null
          || this.hourInput.toString().length === 0
          || this.minuteInput === null
          || this.minuteInput.toString().length === 0
          || this.secondInput === null
          || this.secondInput.toString().length === 0
        ) {
          return false;
        }
      }

      return this.dayInput !== null
        && this.dayInput.toString().length !== 0
        && this.monthInput !== null
        && this.monthInput.toString().length !== 0
        && this.yearInput !== null
        && this.yearInput.toString().length !== 0;
    },
    isValidDate() {
      if (!this.isFilledInput) {
        return;
      }

      const testDate = this.currentDateObject;

      if (this.time) {
        if (
          testDate.getHours() !== this.hourInInt
          || testDate.getMinutes() !== this.minuteInInt
          || testDate.getSeconds() !== this.secondInInt
        ) {
          return false;
        }
      }

      return testDate.getDate() === this.dayInInt
        && testDate.getMonth() === (this.monthInInt - 1);
    },
  },
  methods: {
    loadInitValues() {
      if (this.value !== null) {
        this.dayInput = this.value.getDate();
        this.monthInput = this.value.getMonth() + 1;
        this.yearInput = this.value.getFullYear();

        this.hourInput = this.value.getHours();
        this.minuteInput = this.value.getMinutes();
        this.secondInput = this.value.getSeconds();
      }

      this.strpadDayAndMonthValues();
    },
    strpadDayAndMonthValues() {
      let twoCharInputs = [ 'dayInput', 'monthInput', 'hourInput', 'minuteInput', 'secondInput' ];
      twoCharInputs.forEach(inputVar => {
        if (
          this[inputVar] !== null
          && this[inputVar] !== ''
          && this[inputVar].toString().length < 2
        ) {
          this[inputVar] = '0' + this[inputVar];
        }
      });
    },
    onFocusInput(e) {
      this.debouncedFocusedWrapperUpdater(true);
      e.target.select();
      this.$emit('focus');
    },
    onBlurInput() {
      this.debouncedFocusedWrapperUpdater(false);
    },
    onKeyDown(e, lenghLimit = 2) {
      this.lastKeyDownTarget = e.target;

      // Backspace or Delete button.
      if (e.keyCode === 8 || e.keyCode === 46) {
        return;
      }

      if (isFinite(e.key) && e.target.value.toString().length === lenghLimit) {
        e.target.value = '';
      }
    },
    onKeyUp(e, lenghLimit = 2) {
      if (this.lastKeyDownTarget !== e.target) {
        return;
      }

      // Tab button.
      if (e.keyCode === 9) {
        return;
      }

      // Step to next input.
      if (e.target.value.toString().length === lenghLimit) {
        const inputs = this.$el.querySelectorAll('input');
        let currentIndex = null;
        for (let key in inputs) {
          if (inputs[key] === e.target) {
            currentIndex = parseInt(key);
            break;
          }
        }

        if (currentIndex !== null && (currentIndex + 1) !== inputs.length) {
          inputs[currentIndex + 1].focus();
        }
      }
    },
    dateEmit() {
      if (this.allowEmpty && this.isEmpty) {
        this.$emit('input', null);
        return;
      }

      if (this.isValidDate) {
        this.$emit('input', this.currentDateObject);
      }
    },
    clear() {
      this.dayInput = null;
      this.monthInput = null;
      this.yearInput = null;

      this.hourInput = null;
      this.minuteInput = null;
      this.secondInput = null;

      this.$emit('input', null)
    },
  },
};
</script>
