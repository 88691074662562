import Api from '@/services/Api';

import Ad from '@/entities/Ad.js';
import Campaign from '@/entities/Campaign.js';

export default {
  getPeriods() {
    return Api().get('/report/periods');
  },

  getActions({ tokenPart1, tokenPart2, adAccountId }) {
    const data = {};
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }

    const query = new URLSearchParams(data).toString();

    let path = '/report/actions';
    if (tokenPart1 && tokenPart2) {
      path += '/' + tokenPart1 + '/' + tokenPart2;
    }

    return Api().get(path + '?' + query);
  },

  getCampaigns({ tokenPart1, tokenPart2, adAccountId }) {
    const data = {};
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }

    const query = new URLSearchParams(data).toString();

    let path = '/report/campaigns';
    if (tokenPart1 && tokenPart2) {
      path += '/' + tokenPart1 + '/' + tokenPart2;
    }

    return Api().get(path + '?' + query)
      .then(resp => {
        return resp.map(item => new Campaign(item));
      });
  },

  getTotals({ tokenPart1, tokenPart2, adAccountId, campaignId, period, action }) {
    const data = {};
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }
    if (campaignId) {
      data['campaign_id'] = campaignId;
    }
    if (period) {
      data['period'] = period;
    }
    if (action) {
      data['action'] = action;
    }

    const query = new URLSearchParams(data).toString();

    let path = '/report/totals';
    if (tokenPart1 && tokenPart2) {
      path += '/' + tokenPart1 + '/' + tokenPart2;
    }

    return Api().get(path + '?' + query);
  },

  getAds({ tokenPart1, tokenPart2, adAccountId, campaignId, period, isActive, page, limit, orderBy, order }) {
    const data = {};
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }
    if (campaignId) {
      data['campaign_id'] = campaignId;
    }
    if (period) {
      data['period'] = period;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }
    if (orderBy) {
      data['order_by'] = orderBy;
    }
    if (order) {
      data['order'] = order;
    }

    if (isActive === true || isActive === 1) {
      data['is_active'] = 1;
    }
    else if (isActive === false || isActive === 0) {
      data['is_active'] = 0;
    }

    const query = new URLSearchParams(data).toString();

    let path = '/report/ads';
    if (tokenPart1 && tokenPart2) {
      path += '/' + tokenPart1 + '/' + tokenPart2;
    }

    return Api().get(path + '?' + query)
      .then((resp) => {
        return resp.map(i => new Ad(i));
      });
  },

  getAdPreview({ tokenPart1, tokenPart2, adAccountId, adId, adFormat }) {
    const data = {};
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }
    if (adId) {
      data['ad_id'] = adId;
    }
    if (adFormat) {
      data['ad_format'] = adFormat;
    }

    const query = new URLSearchParams(data).toString();

    let path = '/report/ad-preview';
    if (tokenPart1 && tokenPart2) {
      path += '/' + tokenPart1 + '/' + tokenPart2;
    }

    return Api().get(path + '?' + query);
  },

  getByToken(tokenPart1, tokenPart2) {
    return Api().get('/report/' + tokenPart1 + '/' + tokenPart2);
  },

  share({ adAccountId, campaignId, period, action }) {
    const data = {};
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }
    if (campaignId) {
      data['campaign_id'] = campaignId;
    }
    if (period) {
      data['period'] = period;
    }
    if (action) {
      data['action'] = action;
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/report/share?' + query);
  }
};
