var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "magic-tool-view view" },
    [
      _c("sticky-header", {
        attrs: { title: _vm.title, info: _vm.info, bordered: "" },
      }),
      _c("div", { staticClass: "formblock" }, [
        _c("div", { staticClass: "formtitle" }, [
          _c("div", { staticClass: "main" }, [
            _c("span", [_vm._v(_vm._s(_vm.tr("Analyze your ad copy")))]),
          ]),
        ]),
        _c("div", { staticClass: "description" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.tr(
                  "Insert your ad text and wait for the system to evaluate it. The system evaluates your ad based on several aspects (creativity, attention-grabbing, etc.), and gives a short assessment for each aspect. In addition, you can also create a new version of your ad text generated by the system."
                )
              ) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "form-with-preview" },
        [
          _c("magic-tool-form", {
            ref: "form",
            on: {
              "image-uploaded": (image) => _vm.onImageUploaded(image),
              "entity-created": (entity) => _vm.onEntityCreated(entity),
            },
          }),
          _c("facebook-ad-preview", {
            attrs: {
              text: _vm.$refs.form?.text || null,
              "cta-title": _vm.$refs.form?.title || null,
              "cta-description": _vm.$refs.form?.description || null,
              "cta-button": _vm.previewCtaButton,
              "img-url": _vm.imageUrl,
            },
          }),
        ],
        1
      ),
      _c("div", { ref: "scrollHereAfterSubmit", staticClass: "divider" }),
      !_vm.isLoading && _vm.list.length === 0
        ? _c("empty-screen", {
            attrs: {
              icon: "magic",
              title: _vm.tr("Let our AI analyze and improve your ad copy"),
              "flag-text": _vm.tr("Start analyzing"),
              description: _vm.tr(
                "Enter your advertising text above, and our AI will evaluate it based on creativity, engagement potential, and other key metrics. Get instant feedback to optimize your ad performance before going live."
              ),
            },
          })
        : [
            _c("h3", [_vm._v(_vm._s(_vm.tr("Completed evaluations")))]),
            _c("sorted-table", {
              staticClass: "row-hover-shadow",
              attrs: { data: _vm.list },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "table-head",
                        { attrs: { col: "post", "min-width": "100px" } },
                        [_vm._v(_vm._s(_vm.tr("Content")))]
                      ),
                      _c(
                        "table-head",
                        {
                          staticClass: "centered-text",
                          attrs: { col: "ai-score", width: "100px" },
                        },
                        [_vm._v(_vm._s(_vm.tr("AI Score")))]
                      ),
                      _c(
                        "table-head",
                        { attrs: { col: "generated", width: "110px" } },
                        [_vm._v(_vm._s(_vm.tr("Generated")))]
                      ),
                      _c(
                        "table-head",
                        { attrs: { col: "expiry-date", width: "110px" } },
                        [_vm._v(_vm._s(_vm.tr("Expiry date")))]
                      ),
                      _c(
                        "table-head",
                        {
                          staticClass: "centered-text",
                          attrs: { col: "policy-check", width: "100px" },
                        },
                        [_vm._v(_vm._s(_vm.tr("Policy check")))]
                      ),
                      _c("table-head", {
                        staticClass: "centered-text",
                        attrs: { col: "navigation-arrow", width: "30px" },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function ({ sort }) {
                    return [
                      _vm._l(sort, function (item) {
                        return _c(
                          "table-row",
                          { key: item.id },
                          [
                            _c(
                              "table-data",
                              {
                                staticClass: "post-content",
                                attrs: { col: "post" },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/magic-tool/" + item.id + "/original",
                                    },
                                  },
                                  [
                                    _c("inline-social-content", {
                                      attrs: { entity: item },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "table-data",
                              {
                                staticClass: "col-center ai-score-cell",
                                attrs: { col: "ai-score" },
                              },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.tr("AI Score"))),
                                ]),
                                _c("ai-score", {
                                  attrs: { value: item.value },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "table-data",
                              {
                                staticClass: "generated-cell formatted-date",
                                attrs: { col: "generated" },
                              },
                              [
                                _c("label", [
                                  _vm._v(_vm._s(_vm.tr("Generated"))),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.formatToYMD(item.created_at))
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "formatted-date--time" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formatTime(item.created_at))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "table-data",
                              {
                                staticClass: "expiry-date-cell formatted-date",
                                attrs: { col: "expiry-date" },
                              },
                              [
                                item.contentActualityCheckInProgress
                                  ? _c("skeleton", {
                                      attrs: { width: "80px", height: "16px" },
                                    })
                                  : item.expiry_date
                                  ? _c("div", [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.tr("Expiry date"))),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatToYMD(item.expiry_date)
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "formatted-date--time" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatTime(item.expiry_date)
                                            )
                                          ),
                                        ]
                                      ),
                                    ])
                                  : [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.tr("Expiry date"))),
                                      ]),
                                      _vm._v(" - "),
                                    ],
                              ],
                              2
                            ),
                            _c(
                              "table-data",
                              {
                                staticClass: "policy-check-cell",
                                attrs: { col: "policy-check" },
                              },
                              [
                                !item.policyCheckInProgress
                                  ? _c("label", [
                                      _vm._v(_vm._s(_vm.tr("Policy check"))),
                                    ])
                                  : _vm._e(),
                                item.policyCheckInProgress
                                  ? _c("skeleton", {
                                      attrs: { width: "50px", height: "16px" },
                                    })
                                  : _c("post-content-policy-check-status", {
                                      attrs: {
                                        value: item.policy_check_is_prohibited,
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "table-data",
                              {
                                staticClass: "navigation-arrow-cell",
                                attrs: { col: "navigation-arrow" },
                              },
                              [
                                _c("navigation-arrow", {
                                  attrs: {
                                    link:
                                      "/magic-tool/" + item.id + "/original",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.isLoading || _vm.hasMore
                        ? _vm._l([1, 2], function (i) {
                            return _c(
                              "table-row",
                              {
                                key: "magic-tool-loader-skeleton-" + i,
                                ref: "loadMore",
                                refInFor: true,
                                staticClass: "skeleton-row",
                              },
                              [
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "post-content",
                                    attrs: { col: "post" },
                                  },
                                  [_c("inline-social-content")],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "ai-score-cell col-center",
                                    attrs: { col: "ai-score" },
                                  },
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "32px", height: "28px" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "generated-cell",
                                    attrs: { col: "requested" },
                                  },
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "100px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "expiry-date-cell",
                                    attrs: { col: "expiry-date" },
                                  },
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "80px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "policy-check-cell",
                                    attrs: { col: "policy-check" },
                                  },
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "50px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                                _c("table-data", {
                                  staticClass: "navigation-arrow-cell",
                                  attrs: { col: "navigation-arrow" },
                                }),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }