var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block ad-placement-block-component",
      attrs: { title: _vm.tr("Placement") },
    },
    [
      _vm.disabledText
        ? _c("info-text", [_vm._v(" " + _vm._s(_vm.disabledText) + " ")])
        : _c(
            "div",
            { staticClass: "checkbox-list vertical placement-selector" },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isAutoPlacement,
                      expression: "isAutoPlacement",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "placement",
                    disabled: _vm.entity.isAppPromo || _vm.disabled,
                  },
                  domProps: {
                    value: true,
                    checked: _vm._q(_vm.isAutoPlacement, true),
                  },
                  on: {
                    change: function ($event) {
                      _vm.isAutoPlacement = true
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.tr("Automatic")) + " "),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.tr(
                      "Maximize your budget and help show your ads to more people. Facebook's delivery system will allocate your ad set's budget across multiple placements based on where they're likely to perform best."
                    )
                  )
                ),
              ]),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isAutoPlacement,
                      expression: "isAutoPlacement",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    name: "placement",
                    disabled: _vm.disabled,
                  },
                  domProps: {
                    value: false,
                    checked: _vm._q(_vm.isAutoPlacement, false),
                  },
                  on: {
                    change: function ($event) {
                      _vm.isAutoPlacement = false
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.tr("Manual")) + " "),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.tr(
                      "Manually choose the places to show your ad. The more placements you select, the more opportunities you'll have to reach your target audience and achieve your business goals."
                    )
                  )
                ),
              ]),
            ]
          ),
      !_vm.isAutoPlacement
        ? _c("div", { staticClass: "manual-placement-selectors" }, [
            _c(
              "div",
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(" " + _vm._s(_vm.tr("Devices")) + " "),
                ]),
                _c("v-select", {
                  attrs: {
                    options: _vm.devices,
                    searchable: false,
                    clearable: false,
                    disabled: _vm.entity.isAppPromo || _vm.disabled,
                  },
                  model: {
                    value: _vm.adsetTargetingDevices,
                    callback: function ($$v) {
                      _vm.adsetTargetingDevices = $$v
                    },
                    expression: "adsetTargetingDevices",
                  },
                }),
              ],
              1
            ),
            _vm.hasManualMobileTargeting
              ? _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.tr("Specific mobile devices")) + " "
                      ),
                    ]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.mobileDevices,
                        searchable: false,
                        clearable: false,
                        disabled: _vm.entity.isAppPromo || _vm.disabled,
                      },
                      model: {
                        value: _vm.adsetTargetingUserOs,
                        callback: function ($$v) {
                          _vm.adsetTargetingUserOs = $$v
                        },
                        expression: "adsetTargetingUserOs",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      !_vm.isAutoPlacement
        ? _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(" " + _vm._s(_vm.tr("Platforms")) + " "),
              ]),
              _c("placement-selector", {
                ref: "placementSelector",
                attrs: { entity: _vm.entity, disabled: _vm.disabled },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }