<template>
  <form-block v-if="paymentHistory" :title="tr('Online bank card payment')">
    <i
      class="icon close"
      @click="() => $emit('close')"
    />

    <payment-history-details :entity="paymentHistory" />

    <i v-if="!clientSecret || !stripeCardInputReady" class="icon loader primary center-loader" />

    <template v-if="clientSecret">
      <stripe-card-input
        ref="stripeForm"
        :client-secret="clientSecret"
        @ready="() => stripeCardInputReady = true"
        @success="() => { onSuccess(); $emit('close') }"
        @error="(err) => error(err)"
      />
      <button
        class="primary"
        :disabled="isPaying"
        @click="() => $refs.stripeForm.save()"
      >
        <i v-if="isPaying" class="icon loader white" />
        {{ tr('Pay') }}
        - {{ formatPrice(paymentHistory.gross) }}
      </button>
    </template>
  </form-block>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import PaymentHistoryDetails from '@/components/PaymentHistoryDetails.vue';
import StripeCardInput from '@/components/StripeCardInput.vue';

import PaymentHistory from '@/entities/PaymentHistory';
import PaymentHistoryService from '@/services/PaymentHistory';

import { formatPrice } from '@/utils/string';

export default {
  name: 'PaymentModal',
  props: {
    paymentHistory: {
      type: PaymentHistory,
      required: true,
    },
    onSuccess: {
      type: Function,
      required: false,
      default: () => () => {}
    }
  },
  components: {
    FormBlock,
    PaymentHistoryDetails,
    StripeCardInput,
  },
  data: () => ({
    formLoaded: false,
    clientSecret: null,
    stripeCardInputReady: false,
  }),
  computed: {
    isPaying() {
      return this.formLoaded && this.$refs.stripeForm && this.$refs.stripeForm.isLoading;
    },
  },
  watch: {
    isPaying(val) {
      if (!val) {
        if (this.paymentHistoryChangeStatusTimer !== null) {
          clearTimeout(this.paymentHistoryChangeStatusTimer);
        }
      }
    },
  },
  created() {
    PaymentHistoryService.generatePaymentIntent(this.paymentHistory.id)
      .then(resp => {
        this.clientSecret = resp.client_secret;
        this.$nextTick(() => this.formLoaded = true);
      })
      .catch(err => {
        this.error(err);
        this.$modal.hide('payment-modal');
      });
  },
  methods: {
    formatPrice,
  },
};
</script>
