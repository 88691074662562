<template>
  <div class="rule-form-step rule-form-step--start rule-form-step--start--step-3">
    <adset-name-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <conversion-odax-block
      v-if="isFbOdax"
      :entity="entity"
      :disabled="$parent.readonlyMode"
    />
    <conversion-block
      v-else
      :entity="entity"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <target-audience-block
      ref="targetAudienceBlock"
      :entity="entity"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <demographic-target-block
      :entity="entity"
      :disabled="$parent.readonlyMode"
      :target-audience-block="isMounted ? $refs.targetAudienceBlock : null"
      :target-audience-block-is-loading="isMounted ? $refs.targetAudienceBlock.isLoading : true"
    />

    <geotarget-block
      :entity="entity"
      :disabled="$parent.readonlyMode"
      :target-audience-block="isMounted ? $refs.targetAudienceBlock : null"
      :target-audience-block-is-loading="isMounted ? $refs.targetAudienceBlock.isLoading : true"
    />

    <div class="divider" />

    <adset-budget-block
      v-if="isFbOdax"
      :entity="entity"
      :disabled="$parent.readonlyMode"
    />
    <adset-budget-non-odax-block
      v-else
      :entity="entity"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <attribution-window-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <dsa-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <ad-placement-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>

<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import AdPlacementBlock from '@/components/RuleForm/Blocks/AdPlacementBlock.vue';
import AdsetBudgetBlock from '@/components/RuleForm/Blocks/AdsetBudgetBlock.vue';
import AdsetBudgetNonOdaxBlock from '@/components/RuleForm/Blocks/AdsetBudgetNonOdaxBlock.vue';
import AdsetNameBlock from '@/components/RuleForm/Blocks/AdsetNameBlock.vue';
import AttributionWindowBlock from '@/components/RuleForm/Blocks/AttributionWindowBlock.vue';
import ConversionBlock from '@/components/RuleForm/Blocks/ConversionBlock.vue';
import ConversionOdaxBlock from '@/components/RuleForm/Blocks/ConversionOdaxBlock.vue';
import DemographicTargetBlock from '@/components/RuleForm/Blocks/DemographicTargetBlock.vue';
import DsaBlock from '@/components/RuleForm/Blocks/DsaBlock.vue';
import GeotargetBlock from '@/components/RuleForm/Blocks/GeotargetBlock.vue';
import TargetAudienceBlock from '@/components/RuleForm/Blocks/TargetAudienceBlock.vue';

export default {
  name: 'StartRuleFormStep3',
  mixins: [ RuleFormStepMixin ],
  components: {
    AdPlacementBlock,
    AdsetBudgetBlock,
    AdsetBudgetNonOdaxBlock,
    AdsetNameBlock,
    AttributionWindowBlock,
    ConversionBlock,
    ConversionOdaxBlock,
    DemographicTargetBlock,
    DsaBlock,
    GeotargetBlock,
    TargetAudienceBlock,
  },
  data: () => ({
    isMounted: false,
  }),
  mounted() {
    this.isMounted = true;
  },
  methods:{
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      if (this.isFbOdax) {
        for (let promotedObjectField of this.fbOdaxPromotedObjects) {
          if (!this.entity.settings[promotedObjectField]) {
            this.error(this.tr(':field is required!', { ':field': this.tr('field.settings.' + promotedObjectField) }));
            return false;
          }
        }
      }

      return true;
    },
  },
};
</script>
