var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block url-params-block-component",
      attrs: {
        title: _vm.tr("URL parameters"),
        description: _vm.tr(
          "A query string that follows the main URL in a web address. This parameter helps in analyzing and tracking advertisements as it allows us to determine exactly which ad has brought the visitor to the website. You can easily set this parameter with the help of the macros."
        ),
      },
    },
    [
      _c("templateable-input", {
        attrs: {
          templateParts: _vm.urlTemplateableParts,
          disabled: _vm.disabled,
        },
        model: {
          value: _vm.entity.settings.url_params,
          callback: function ($$v) {
            _vm.$set(_vm.entity.settings, "url_params", $$v)
          },
          expression: "entity.settings.url_params",
        },
      }),
      _vm.entity.settings.url_params
        ? _c("div", { staticClass: "url-params-preview" }, [
            _c("span", [
              _c("label", [_vm._v(_vm._s(_vm.tr("Preview")) + ":")]),
              _vm._v(" " + _vm._s(_vm.urlParamsPreview) + " "),
            ]),
            _vm.urlParamsIsLoading
              ? _c("i", { staticClass: "icon loader" })
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }