export default class ChartLine
{
  stroke = 'black';
  strokeWidth = 1;
  strokeDasharray = 6;
  x1 = 0;
  x2 = 0;
  y1 = 0;
  y2 = 0;

  #svg = null;
  #element = null;

  constructor(chart) {
    this.#svg = chart.svg;
  }

  get element() {
    return this.#element;
  }

  draw() {
    this.#element = this.#svg.append('line')
      .attr('stroke', this.stroke)
      .attr('stroke-width', this.strokeWidth)
      .attr('stroke-dasharray', this.strokeDasharray);

    ['x1', 'x2', 'y1', 'y2'].forEach(attr => {
      if (this[attr]) {
        this.#element.attr(attr, this[attr]);
      }
    });

    return this.#element;
  }
}
