<template>
  <form-block
    class="rule-form-block operating-area-block-component"
    :title="tr('Operating area')"
  >
    <div>
      <label class="input-label">{{ tr('Ads created by the following automations')}}</label>
      <div class="description">{{ tr('Here, you can select the start automation that was previously set for this ad management automation to work in.')}}</div>

      <div v-if="isLoading" class="inline-loader">
        <i class="icon loader" />
        {{ tr('Loading...') }}
      </div>
      <multi-select
        v-else
        v-model="entity.input_campaigns"
        :options="ruleCampaignsByAdAccount"
        :disabled="disabled || !entity.hasSelectedAdAccount"
        :placeholder="tr('Select an automation...')"
        :deleted-plus-label="tr('Automation Stopped') + ' - ' + tr('Campaign Deleted on Ads Manager')"
        :disabled-click-message="disabledOptionMessage"
      />
    </div>

    <template v-if="!isLoading">
      <info-text v-if="entity.hasSelectedAdAccount && ruleCampaignsByAdAccount.length === 0">
        {{ tr('There are no start automations available in the selected ad account(s).') }}
      </info-text>
      <info-text v-else-if="disabledOptionMessage">
        {{ disabledOptionMessage }}
      </info-text>
    </template>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

import InfoText from '@/components/InfoText.vue';
import MultiSelect from '@/components/MultiSelect.vue';

import RuleService from '@/services/Rule.js';

export default {
  name: 'OperationAreaBlock',
  mixins: [ RuleFormBlockMixin ],
  components: {
    InfoText,
    MultiSelect,
  },
  props: {
    disabledOptionMessage: {
      type: String,
      required: false,
      default: '',
    }
  },
  data: () => ({
    isLoading: true,
    ruleCampaignPairs: [],
  }),
  computed: {
    ruleCampaignsByAdAccount() {
      if (this.isLoading || !this.entity.hasSelectedAdAccount) {
        return [];
      }

      let pairs = this.ruleCampaignPairs.filter(i => i.value.ad_account_id === this.entity.ad_account.id);
      pairs = JSON.parse(JSON.stringify(pairs));

      if (this.entity.action === 'scaling_up' && this.entity.firstInputCampaign) {
        if (this.entity.output_campaign.objective) {
          const currentSpecialCategories = JSON.parse(JSON.stringify(this.entity.firstInputCampaign.special_ad_categories)).sort();
          const currentSpecialCategoryCountry = JSON.parse(JSON.stringify(this.entity.firstInputCampaign.special_ad_category_country)).sort();

          pairs.forEach(item => {
            item.disabled = item.value.objective !== this.entity.output_campaign.objective;
            if (item.disabled) {
              return;
            }

            const itemSpecialCategories = JSON.parse(JSON.stringify(item.value.special_ad_categories)).sort();
            const itemSpecialCategoryCountry = JSON.parse(JSON.stringify(item.value.special_ad_category_country)).sort();

            item.disabled = JSON.stringify(currentSpecialCategories) !== JSON.stringify(itemSpecialCategories);
            item.disabled = item.disabled || JSON.stringify(itemSpecialCategoryCountry) !== JSON.stringify(currentSpecialCategoryCountry);
          });
        }
        else if (this.entity.firstInputCampaign.id) {
          pairs.forEach(item => {
            item.disabled = item.disabled || item.value.objective !== this.entity.firstInputCampaign.objective;
          });
        }
      }

      const campaignIdsInList = pairs.map(i => i.value.id);
      this.entity.input_campaigns.forEach(campaign => {
        if (!campaignIdsInList.includes(campaign.id)) {
          campaign.deleted_at = new Date();

          pairs.push({
            label: campaign.name,
            value: campaign,
            deletedPlusLabel: this.tr('Deleted Start Automation')
          });
        }
      });

      return pairs;
    },
  },
  watch: {
    'entity.ad_account.id'() {
      this.entity.input_campaigns = [];
    },
    'entity.input_campaigns': {
      immediate: true,
      handler(val) {
        this.reloadInputPlatformInfo();
      },
    },
    ruleCampaignPairs() {
      this.reloadInputPlatformInfo();
    },
  },
  created() {
    RuleService.getRuleCampaignPairs()
      .then(resp => {
        this.ruleCampaignPairs = resp.map(item => {
          return {
            status: item.active,
            label: item.name,
            value: item.campaign,
            source_platforms: item.source_platforms,
          };
        })
      })
      .finally(() => this.isLoading = false);
  },
  methods: {
    validate() {
      if (this.isLoading) {
        this.$toast.clear();
        this.$toast.info(this.tr('Please wait until the Operation Area data are loaded.'));
        return false;
      }

      if (this.entity.input_campaigns.length === 0) {
        this.error(this.tr('At least one working area must be selected!'));
        return false;
      }

      const selectedCampaignIds = this.entity.input_campaigns.map(campaign => campaign.id);
      const availableCampaignIds = this.ruleCampaignPairs.map(item => item.value.id);
      for (const campaignId of selectedCampaignIds) {
        if (!availableCampaignIds.includes(campaignId)) {
          this.error(this.tr('One of the selected Start Automations in the operational areas is no longer available.'));
          return false;
        }
      }

      return true;
    },
    reloadInputPlatformInfo() {
      this.entity.resetInputPlatforms();

      if (this.entity.input_campaigns.length === 0) {
        return;
      }

      const selectedCampaignIds = this.entity.input_campaigns.map(campaign => campaign.id);
      this.ruleCampaignPairs.forEach(item => {
        if (selectedCampaignIds.includes(item.value.id)) {
          item.source_platforms.forEach(platform => this.entity.addInputPlatform(platform));
        }
      });
    },
  },
};
</script>
