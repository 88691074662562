var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "meta-ap-creative-features-component" }, [
    _c("label", { staticClass: "input-label" }, [
      _vm._v(_vm._s(_vm.tr("Advantage+ Creative Features"))),
    ]),
    _c(
      "div",
      { staticClass: "checkbox-list vertical" },
      _vm._l(_vm.apCreativeFeatures, function (item, key) {
        return _c(
          "label",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.right",
                value: _vm.tr(item.info),
                expression: "tr(item.info)",
                modifiers: { right: true },
              },
            ],
            key: "ap-creative-feature--" + key,
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value[item.machine_name],
                  expression: "value[item.machine_name]",
                },
              ],
              attrs: { type: "checkbox", disabled: _vm.disabled },
              domProps: {
                checked: Array.isArray(_vm.value[item.machine_name])
                  ? _vm._i(_vm.value[item.machine_name], null) > -1
                  : _vm.value[item.machine_name],
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.value[item.machine_name],
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.value,
                          item.machine_name,
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.value,
                          item.machine_name,
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.value, item.machine_name, $$c)
                  }
                },
              },
            }),
            _vm._v(" " + _vm._s(_vm.tr(item.label)) + " "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }