var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integrations-meta-profiles-view" },
    [
      _vm.isProfilesEmpty
        ? _c("empty-screen", {
            attrs: {
              icon: _vm.emptyScreenIcon,
              title: _vm.tr("Connect your accounts to start automating"),
              "flag-text": _vm.tr("Connect profiles"),
              description: _vm.tr(
                "To begin using our AI-powered automation features, you'll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising."
              ),
            },
            on: { click: _vm.handleClick },
          })
        : _c(
            "div",
            { staticClass: "profiles" },
            _vm._l(_vm.profiles, function (profile) {
              return _c("connected-profile-item", {
                key: profile.id,
                attrs: { entity: profile },
                on: {
                  remove: () => _vm.removeProfile(profile),
                  reconnect: function ($event) {
                    return _vm.$emit("reconnect", profile)
                  },
                },
              })
            }),
            1
          ),
      _c("disconnect-confirm-modal", { ref: "disconnectConfirmModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }