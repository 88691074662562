<template>
  <div class="auth-form-view forgotten-password-view">
    <div class="form">
      <div class="input-label">
        <span>{{ tr('E-mail address') }}</span>
      </div>
      <input
        v-model="email"
        type="email"
        :placeholder="tr('you@gmail.com')"
        @keydown.enter="() => submit()"
      />

      <button class="primary" @click="() => submit()">
        <i v-if="isLoading" class="icon loader white" />
        <template v-else> {{ tr('Send') }} </template>
      </button>

      <router-link to="/auth/login" class="link">
        <u>{{ tr('Back to login') }}</u>
      </router-link>
    </div>
    <div class="language-selector-wrapper">
      <language-selector />
    </div>
  </div>
</template>

<script>

import LanguageSelector from '@/components/LanguageSelector';
import { UnauthorizedError } from '@/errors';
import AuthService from '@/services/Auth.js';
import { isValidEmail } from '@/utils/validators';

export default {
  name: 'ForgottenPassword',
  components: {
    LanguageSelector,
  },
  data() {
    return {
      isLoading: false,
      email: '',
    };
  },
  methods: {
    resetForm() {
      this.email = '';
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      AuthService.forgottenPassword(this.email)
        .then(resp => {
          this.resetForm();
          this.$toast.success(this.tr('The link to reset the password has been sent to the specified email address.'));

          return resp;
        })
        .catch(err => {
          if (err instanceof UnauthorizedError) {
            this.$toast.error(this.tr('Invalid e-mail given!'));
          }
          else {
            this.error(err);
          }
        })
        .finally(() => this.isLoading = false);
    },
    validate() {
      this.$toast.clear();

      if (!this.email) {
        this.$toast.error(this.tr('E-mail cannot be empty!'));
        return false;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.tr('E-mail provided has an incorrect format!'));
        return false;
      }

      return true;
    },
  },
};
</script>
