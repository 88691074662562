<template>
  <div class="settings-billing-view">

    <p class="top-text">
      {{ tr('Changes to these preferences will apply to future invoices only. If you need a past invoice reissued, please contact hello@infinite.ad.') }}
    </p>

    <form-block
      :title="tr('Billing name') + '<sup>*</sup>'"
    >
        <input type="text" v-model="model.billing_company" />
    </form-block>

    <form-block
      :title="tr('Business tax ID')"
      :description="tr('If you are a business tax registrant, please enter your business tax ID here.')"
    >
      <input type="text" v-model="model.billing_vat_number" />
    </form-block>

    <form-block
      :title="tr('Primary business address') + '<sup>*</sup>'"
      :description="primaryBusinessAddressDescription"
    >
      <div>
        <label class="input-label">{{ tr('Country') }}<sup>*</sup></label>
        <v-select
          v-model="model.billing_country"
          :options="countriesList"
          :reduce="(item) => item.iso_a2"
          :get-option-label="item => tr(item.name)"
          :clearable="false"
        />
      </div>

      <div>
        <label class="input-label">{{ tr('City') }}<sup>*</sup></label>
        <input type="text" v-model="model.billing_city" />
      </div>
      <div>
        <label class="input-label">{{ tr('Postcode / ZIP') }}<sup>*</sup></label>
        <input type="text" v-model="model.billing_zip" />
      </div>

      <div>
        <label class="input-label">{{ tr('Street address') }}<sup>*</sup></label>
        <input type="text" v-model="model.billing_address" />
      </div>
    </form-block>

    <form-block
      :title="tr('Billing email') + '<sup>*</sup>'"
      :description="tr('Invoices and other billing notifications will be sent here.')"
    >
      <input type="text" v-model="model.billing_email" />
    </form-block>

    <div class="divider" />

    <change-subscription-form
      ref="subscriptionForm"
      :billing-country="model.billing_country"
      :vat-number="model.billing_vat_number"
    />

    <div>
      <label class="input-label">{{ tr('Payment method') }}</label>
      <i v-if="currentPaymentMethodIsLoading" class="icon loader primary" />

      <template v-else>
        <div v-if="currentPaymentMethodHasError">
          {{ tr('An unexpected error occurred while retrieving data.') }}
        </div>
        <div v-else-if="currentPaymentMethod" class="payment-method-item" >
          <div class="payment-method-details">
            <div class="number">
              <img
                v-if="currentCardIconAvailable"
                class="card-icon"
                :src="require(`@/assets/img/cards/${currentPaymentMethod.brand}.png`)"
              >

              •••• {{ currentPaymentMethod.last4 }}
            </div>

            <div class="expires">
              {{ tr('Expires :at', { ':at': currentCardExpireDate }) }}
            </div>
          </div>
          <button
            class="red small"
            @click="() => openDeleteConfirmModal()"
          >
            {{ tr('Remove') }}
          </button>
        </div>
        <div v-else>
          {{ tr('There is no registered payment method.') }}
        </div>
      </template>
    </div>

    <template v-if="!currentPaymentMethodIsLoading && !currentPaymentMethodHasError">
      <div v-if="showStripeForm" class="add-payment-method-form">
        <label class="input-label">{{ tr('Add payment method') }}</label>
        <stripe-card-input
          ref="stripeForm"
          @success="() => onNewPaymentMethodSaved()"
          @error="(err) => onNewPaymentMethodError(err)"
        />

        <button
          v-if="currentPaymentMethod !== null"
          class="primary add-credit-card-button"
          :disabled="isLoading"
          @click="() => save()"
        >
          <i v-if="isLoading" class="icon loader white" />
          {{ tr('Save payment method') }}
        </button>
      </div>

      <button
        v-else
        class="primary add-new-payment-method-button"
        @click="() => showStripeForm = true"
      >
        {{ currentPaymentMethod ? tr('Replace payment method') : tr('Add payment method') }}
      </button>
    </template>

    <cancel-subscription-modal-button />
  </div>
</template>

<script>
import CancelSubscriptionModalButton from '@/components/CancelSubscriptionModalButton.vue';
import ChangeSubscriptionForm from '@/components/ChangeSubscriptionForm.vue';
import FormBlock from '@/components/FormBlock.vue';
import StripeCardInput from '@/components/StripeCardInput.vue';

import Company from '@/entities/Company';
import CompanyPaymentService from '@/services/CompanyPayment';
import CompanyService from '@/services/Company';

import { isValidEmail } from '@/utils/validators';

export default {
  name: 'SettingsBillingAndPaymentView',
  components: {
    FormBlock,
    CancelSubscriptionModalButton,
    ChangeSubscriptionForm,
    StripeCardInput,
  },
  data: () => ({
    model: null,
    isSavingCompany: false,
    isSavingSubscription: false,
    isSavingPaymentMethod: false,

    currentPaymentMethodIsLoading: false,
    currentPaymentMethodHasError: false,
    currentPaymentMethod: null,

    saveSubscriptionLater: false,
    showStripeForm: false,
    stripeError: null,
  }),
  created() {
    this.model = new Company(this.$root.currentCompany);
    this.loadCurrentCard();

    if (!this.model.billing_email) {
      this.model.billing_email = this.$root.loggedInUser.email;
    }
  },
  computed: {
    isLoading() {
      return this.isSavingCompany || this.isSavingSubscription || this.isSavingPaymentMethod;
    },
    companyEntity() {
      return this.$root.currentCompany;
    },
    countriesList() {
      return this.$store.getters['app/countries'];
    },
    publishableKey() {
      return process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    },
    currentCardIconAvailable() {
      const availableImages = [
        'amex',
        'diners',
        'discover',
        'jcb',
        'mastercard',
        'visa',
      ];

      return this.currentPaymentMethod && availableImages.includes(this.currentPaymentMethod.brand);
    },
    currentCardExpireDate() {
      if (!this.currentPaymentMethod) {
        return null;
      }

      return this.currentPaymentMethod.exp_month.toString().padStart(2, '0') + ' / ' + this.currentPaymentMethod.exp_year;
    },
    primaryBusinessAddressDescription() {
      if (this.$app.isAd) {
        return this.tr('This is the physical address of the company purchasing Infinite∞Ad service and is used to calculate any applicable sales taxes.');
      }
      if (this.$app.isBuzz) {
        return this.tr('This is the physical address of the company purchasing Infinite∞Buzz service and is used to calculate any applicable sales taxes.');
      }

      return '';
    }
  },
  watch: {
    currentPaymentMethodIsLoading(val) {
      if (!val && !this.currentPaymentMethod) {
        this.showStripeForm = true;
      }
    },
    isLoading(val) {
      if (!val && this.saveSubscriptionLater) {
        this.saveSubscriptionLater = false;

        if (this.saveCompanyWasSuccessful) {
          this.saveSubscription();
        }
      }
    }
  },
  methods: {
    async save() {
      if (this.isLoading) {
        return;
      }

      this.$toast.clear();
      this.saveSubscriptionLater = false;

      if (!this.validate()) {
        return;
      }

      if (this.showStripeForm && this.$refs.stripeForm) {
        if (!this.$refs.stripeForm.isCompleted) {
          this.$toast.error(this.tr('You have not specified a payment method.'));
          return false;
        }

        this.saveSubscriptionLater = true;
        this.isSavingPaymentMethod = true;
        const stripeSaveSuccess = await this.$refs.stripeForm.save();
        if (!stripeSaveSuccess) {
          return;
        }
      }
      else if (!this.currentPaymentMethod) {
        this.$toast.error(this.tr('You have not specified a payment method.'));
        return false;
      }

      this.isSavingCompany = true;
      this.saveCompanyWasSuccessful = false;

      return CompanyService.update(this.$root.currentCompany.id, this.model)
        .then(resp => {
          this.$store.commit('auth/updateCompany', resp);
        })
        .then(async () => {
          this.saveCompanyWasSuccessful = true;

          if (this.$refs.subscriptionForm.isChanged()) {
            if (!this.saveSubscriptionLater) {
              return this.saveSubscription();
            }
          }
          else {
            this.$toast.success(this.tr('Successful save'));
          }
        })
        .catch((err) => {
          this.saveSubscriptionLater = false;
          this.error(err);
        })
        .finally(() => (this.isSavingCompany = false));
    },
    saveSubscription() {
      this.isSavingSubscription = true;

      return this.$refs.subscriptionForm.save()
        .catch((err) => this.error(err))
        .finally(() => (this.isSavingSubscription = false));
    },
    validate() {
      if (!this.model.billing_company) {
        this.$toast.error(this.tr('Billing name cannot be empty!'));
        return false;
      }
      if (!this.model.billing_country) {
        this.$toast.error(this.tr('Country cannot be empty!'));
        return false;
      }
      if (!this.model.billing_city) {
        this.$toast.error(this.tr('City cannot be empty!'));
        return false;
      }
      if (!this.model.billing_zip) {
        this.$toast.error(this.tr('Postcode cannot be empty!'));
        return false;
      }
      if (!this.model.billing_address) {
        this.$toast.error(this.tr('Address cannot be empty!'));
        return false;
      }
      if (!isValidEmail(this.model.billing_email)) {
        this.$toast.error(this.tr('E-mail provided has an incorrect format!'));
        return false;
      }

      /* if (!this.$refs.subscriptionForm.selectedPackage) {
        this.$toast.error(this.tr('You have not selected a subscription package.'));
        return false;
      } */

      return true;
    },

    loadCurrentCard() {
      if (this.currentPaymentMethodIsLoading) {
        return;
      }

      this.currentPaymentMethodIsLoading = true;
      this.currentPaymentMethod = null;

      return CompanyPaymentService.getStripeCurrentCard(this.$root.currentCompany.id)
        .then(resp => this.currentPaymentMethod = resp)
        .catch(() => this.currentPaymentMethodHasError = true)
        .finally(() => this.currentPaymentMethodIsLoading = false);
    },
    async onNewPaymentMethodSaved() {
      await this.loadCurrentCard();

      this.showStripeForm = false;
      this.isSavingPaymentMethod = false;
    },
    onNewPaymentMethodError(err) {
      this.saveSubscriptionLater = false;
      this.isSavingPaymentMethod = false;

      this.error(err.message ? err.message : err);
    },
    openDeleteConfirmModal() {
      this.$confirmation.open({
        title: this.tr('Are you sure you want to remove this payment method?'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        confirm: () => this.removePaymentMethod(),
      });
    },
    removePaymentMethod() {
      return CompanyPaymentService.deleteStripeCurrentCard(this.$root.currentCompany.id)
        .then(() => this.currentPaymentMethod = null)
        .catch(err => this.error(err));
    },
  },
};
</script>
