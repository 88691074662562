<template>
  <div class="settings-view">
    <sticky-header :title="tr('Settings')">
      <form-step-tab :items="formStepTabs" />

      <template #buttons>
        <button
          v-if="hasSaveButton"
          class="primary"
          @click="() => save()"
          :loading="isSaving"
        >
          <i class="icon loader white" v-if="isSaving" />
          <template v-else>
            {{ tr('Save') }}
          </template>
        </button>
        <button
          v-if="$router.currentRoute.path === '/settings/team'"
          class="primary open-invite-modal-button"
          @click="() => $modal.show('invite-member')"
          :disabled="!canInviteNewMember"
        >
          {{ tr('Invite a teammember') }}
        </button>
      </template>
    </sticky-header>

    <router-view ref="childView" @canInviteNewMember="setCanInviteNewMember($event)"/>
    <invite-member-modal />
  </div>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue';
import FormStepTab from '@/components/FormStepTab.vue';
import InviteMemberModal from '@/components/InviteMemberModal.vue';

export default {
  name: 'SettingsView',
  components: {
    StickyHeader,
    FormStepTab,
    InviteMemberModal,
  },
  data: () => ({
    hasSaveButton: false,
    canInviteNewMember: false,
  }),
  computed: {
    isSaving() {
      return this.$refs['childView'] && this.$refs['childView'].isLoading;
    },
    formStepTabs() {
      return [
        {
          label: this.tr('Billing and payment'),
          link: '/settings/billing-and-payment',
        },
        {
          label: this.tr('Payment history'),
          link: '/settings/payment-history',
        },
        {
          label: this.tr('Team'),
          link: '/settings/team',
        },
        {
          label: this.tr('Organization'),
          link: '/settings/organization',
        },
      ];
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.hasSaveButton = this.$refs['childView'] && typeof this.$refs['childView'].save === 'function';
        });
      },
    },
  },
  methods: {
    save() {
      this.$refs['childView'].save();
    },
    setCanInviteNewMember(value) {
      this.canInviteNewMember = value;
    },
  },
};
</script>
