<template>
  <form-block
    class="rule-form-block ad-account-block-component"
    :title="tr('Ad account')"
    :description="description"
  >
    <info-text v-if="entity.disconnectedAdAccount" type="error">
      {{ tr('The selected ad account has been disconnected.') }}
    </info-text>
    <template v-else>
      <v-select
        v-model="entity.ad_account.id"
        :options="adAccounts"
        :reduce="(obj) => obj.id"
        :placeholder="tr('Search ad accounts')"
        :disabled="disabled"
        :clearable="false"
        label="name"
      />
      <router-link to="/connected-platforms/meta/ad-accounts" class="facebook-link">{{ tr('Can\'t find your ad account? Connect a new one') }}</router-link>
    </template>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

import MultiSelect from '@/components/MultiSelect.vue';

export default {
  name: 'AdAccountBlock',
  mixins: [ RuleFormBlockMixin ],
  components: {
    MultiSelect,
  },
  props: {
    description: {
      type: String | null,
      required: false,
    },
  },
  computed: {
    adAccounts() {
      return this.$root.currentCompany.ad_accounts;
    },
  },
  mounted() {
    if (this.adAccounts.length === 1 && !this.entity.ad_account.id) {
      this.entity.ad_account.id = this.adAccounts[0].id;
    }
  },
  methods: {
    validate() {
      if (!this.entity.hasSelectedAdAccount) {
        this.error(this.tr('The {field} field cannot be empty!', { '{field}': this.tr('Ad account') }));
        return false;
      }

      const availableAdAccountIds = this.$root.currentCompany.ad_accounts.map(adAccount => adAccount.id);
      if (!availableAdAccountIds.includes(this.entity.ad_account.id)) {
        this.error(this.tr('The selected ad account is no longer available.'));
        return false;
      }

      return true;
    },
  },
};
</script>
