var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-form-view forgotten-password-view" }, [
    _c(
      "div",
      { staticClass: "form" },
      [
        _c("div", { staticClass: "input-label" }, [
          _c("span", [_vm._v(_vm._s(_vm.tr("E-mail address")))]),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email",
            },
          ],
          attrs: { type: "email", placeholder: _vm.tr("you@gmail.com") },
          domProps: { value: _vm.email },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return (() => _vm.submit()).apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.email = $event.target.value
            },
          },
        }),
        _c(
          "button",
          { staticClass: "primary", on: { click: () => _vm.submit() } },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : [_vm._v(" " + _vm._s(_vm.tr("Send")) + " ")],
          ],
          2
        ),
        _c(
          "router-link",
          { staticClass: "link", attrs: { to: "/auth/login" } },
          [_c("u", [_vm._v(_vm._s(_vm.tr("Back to login")))])]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "language-selector-wrapper" },
      [_c("language-selector")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }