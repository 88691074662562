import BaseEntity from '@/entities/BaseEntity';

export default class PostAiMeta extends BaseEntity
{
  title = '';
  value = null;
  details = '';

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
