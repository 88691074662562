<template>
  <div :class="{ 'refresh-button-component': true, 'loading': isLoading }" @click="() => onClick()">
    <template v-if="!labelAsTooltip">
      <i :class="'icon refresh ' + colorClass" />
      {{ label }}
    </template>
    <template v-else>
      <i :class="'icon refresh ' + colorClass" v-tooltip="label" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'RefreshButton',
  props: {
    label: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      required: true,
    },
    promise: {
      type: Function,
      required: true,
    },
    colorClass: {
      type: String,
      required: false,
      default: () => 'primary',
    },
    labelAsTooltip: {
      type: Boolean,
      required: false,
      default: () =>  false,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {
    onClick() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const promise = this.promise();
      if (!promise) {
        return;
      }

      promise.then(resp => {
        this.$toast.clear();
        this.$toast.success(this.successMessage);
        return resp;
      })
      .catch((err) => this.error(err))
      .finally(() => this.isLoading = false);
    },
  },
};
</script>
