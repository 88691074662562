var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "dashboard-ad-view container" }, [
      _c(
        "div",
        { staticClass: "left" },
        [
          _vm.showSteps && _vm.showReopenOnboardingButton
            ? _c(
                "button",
                {
                  staticClass: "reopen-onboarding-button primary",
                  on: { click: _vm.reopenOnboarding },
                },
                [
                  _c("i", { staticClass: "icon magic white" }),
                  _vm._v(" " + _vm._s(_vm.tr("Open Onboarding Wizard")) + " "),
                ]
              )
            : _vm._e(),
          _vm.showSteps
            ? _c("dashboard-steps", {
                attrs: {
                  "profile-connected": _vm.dashboardData.profile_connected,
                  "ad-account-connected":
                    _vm.dashboardData.ad_account_connected,
                  "source-profile-connected":
                    _vm.dashboardData.source_profile_connected,
                  "post-boosting-created":
                    _vm.dashboardData.post_boosting_rule_created,
                  "rule-created": _vm.dashboardData.rule_created,
                },
              })
            : _c("latest-activities", {
                attrs: {
                  activities: _vm.dashboardData.rule_activity_log,
                  loading: _vm.isLoading,
                },
              }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c(
            "div",
            { staticClass: "active-boosts" },
            [
              _c("active-boosts-card", {
                attrs: {
                  loading: _vm.isLoading,
                  icon: "blister",
                  number: _vm.dashboardData.total_active_rule_count ?? 0,
                  "number-text":
                    _vm.dashboardData.total_active_rule_count === 1
                      ? _vm.tr("active automation")
                      : _vm.tr("active automations"),
                  "last-activity": _vm.dashboardData.last_activity,
                  link: "/automation",
                  "link-text": _vm.tr("Go to my automations"),
                },
              }),
            ],
            1
          ),
          _vm.$root.language === "hu" ? _c("blog-box") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }