<template>
  <div class="auth-form-view signup-form-view form">
    <div class="input-wrapper input-wrapper--first-name">
      <div class="input-label">
        <span>{{ tr('First name') }}<sup>*</sup></span>
      </div>
      <input v-model="firstname" type="text" :tabindex="firstnameTabindex" :disabled="isInvite" />
    </div>

    <div class="input-wrapper input-wrapper--last-name">
      <div class="input-label">
        <span>{{ tr('Last name') }}<sup>*</sup></span>
      </div>
      <input v-model="lastname" type="text" :tabindex="lastnameTabindex" :disabled="isInvite" />
    </div>

    <template v-if="!isInvite">
      <div class="input-label">
        <span>{{ tr('Organization name') }}</span>
      </div>
      <input v-model="companyName" type="text" tabindex="3" />
    </template>

    <div class="input-label">
      <span>{{ tr('Email') }}<sup>*</sup></span>
    </div>
    <input v-model="email" type="email" tabindex="4" :disabled="isInvite" />

    <div class="input-label">
      <span>{{ tr('Password') }}<sup>*</sup></span>
    </div>
    <input
      v-model="password"
      type="password"
      :placeholder="tr('Enter at least 8 characters...')"
      tabindex="5"
    />

    <div class="input-label">
      <span>{{ tr('Password again') }}<sup>*</sup></span>
    </div>
    <input
      v-model="passwordAgain"
      type="password"
      :placeholder="tr('Repeat your password')"
      tabindex="6"
    />

    <label class="checkbox-label">
      <input v-model="gtcCheckbox" type="checkbox" tabindex="7" />
      <span v-html="gtcCheckboxLabel" />
      <span class="required">*</span>
    </label>

    <label class="checkbox-label">
      <input v-model="privacyCheckbox" type="checkbox" tabindex="8" />
      <span v-html="privacyCheckboxLabel" />
      <span class="required">*</span>
    </label>

    <label class="checkbox-label">
      <input v-model="newsletterCheckbox" type="checkbox" tabindex="9" />
      {{ tr('I would like to be notified by email about news and the methods to make ads even more effective') }}
    </label>

    <button class="primary" @click="submit" tabindex="10">
      <i class="icon loader white"
        v-if="isLoading"
        :loading="isLoading"
        color="#ffffff"
        size="6px"
      />
      <template v-else> {{ tr('Signup') }} </template>
    </button>

    <router-link to="/auth/login" class="link">
      <u>{{ tr('If you already have an account, log in here') }}</u>
    </router-link>
  </div>
</template>

<script>
import AuthService from '@/services/Auth.js';

import { isValidEmail } from '@/utils/validators';

export default {
  name: 'SignupFormView',
  data: () => ({
    isLoading: false,
    firstname: '',
    lastname: '',
    companyName: '',
    timezone: 'UTC',
    email: '',
    password: '',
    passwordAgain: '',
    gtcCheckbox: false,
    privacyCheckbox: false,
    newsletterCheckbox: false,
  }),
  computed: {
    timezones() {
      return this.$store.getters['app/timezones'];
    },
    token() {
      return this.$route.params.token;
    },
    isInvite() {
      return !!this.token;
    },
    gtcCheckboxLabel() {
      return this.tr('I accept the <a href="https://8ad.io/altalanos-szerzodesi-feltetelek/" target="_blank">Terms and Conditions</a>');
    },
    privacyCheckboxLabel() {
      return this.tr('I accept the <a href="https://8ad.io/adatkezelesi-szabalyzat/" target="_blank">Privacy Policy</a>');
    },
    firstnameTabindex() {
      return this.$root.language === 'hu' ? 2 :1;
    },
    lastnameTabindex() {
      return this.firstnameTabindex === 2 ? 1 : 2;
    },
  },
  watch: {
    timezones: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setSystemTimezone();
        }
      },
    },
  },
  created() {
    this.loadRecaptchaScript();

    if (this.isInvite) {
      AuthService.invitationValidate(this.token)
        .then((invitation) => {
          this.email = invitation.email;
          this.firstname = invitation.firstname;
          this.lastname = invitation.lastname;
        })
        .catch((err) => {
          this.error(err);
          this.$router.replace('/auth/login');
        })
        .finally(() => {
          this.$store.commit('app/isLoading', false);
        });
    }
    else {
      this.$store.commit('app/isLoading', false);
    }
  },
  methods: {
    resetForm() {
      this.firstname = '';
      this.lastname = '';
      this.companyName = '';
      this.timezone = 'UTC';
      this.email = '';
      this.password = '';
      this.passwordAgain = '';
      this.gtcCheckbox = false;
      this.privacyCheckbox = false;
      this.newsletterCheckbox = false;

      this.setSystemTimezone();
    },
    setSystemTimezone() {
      try {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        for (let timezone of this.timezones) {
          if (timezone.name === userTimezone) {
            this.timezone = userTimezone;
            break;
          }
        }
      }
      catch (_) {
        // ignore.
      }
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      // Load and execute reCAPTCHA
      this.isLoading = true;
      grecaptcha.ready(() => {
        grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'signup' })
          .then((token) => {
            this.submitForm(token);
          });
      });
    },

    submitForm(recaptchaToken) {
      this.isLoading = true;
      this.$store
        .dispatch('auth/signup', {
          hash: this.token,
          email: this.email,
          password: this.password,
          firstname: this.firstname,
          lastname: this.lastname,
          companyName: this.companyName,
          newsletter: this.newsletterCheckbox,
          timezone: this.timezone,
          recaptchaToken: recaptchaToken,
        })
        .then(() => {
          if (window.fbq) {
            window.fbq('trackCustom', 'TrialStarted');
          }
          if (window.ttq) {
            window.ttq.track('TrialStarted');
            window.ttq.track('CompleteRegistration');
          }
          if (window.gtag) {
            if (this.$app.isBuzz) {
              window.gtag('event', 'conversion', {'send_to': 'AW-11432616777/2kfoCM2C4vwYEMnGv8sq'});
            }
          }

          setTimeout(() => {
            this.$router.replace({ path: '/' });
            this.resetForm();
          }, 250);
        })
        .catch((err) => this.error(err))
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      this.$toast.clear();

      if (!this.isInvite) {
        if (!isValidEmail(this.email)) {
          this.$toast.error(this.tr('E-mail provided has an incorrect format!'));
          return false;
        }
        if (this.firstname.trim().length === 0 || this.lastname.trim().length === 0) {
          this.$toast.error(this.tr('Name field cannot be empty!'));
          return false;
        }
      }

      if (this.password.length < 8) {
        this.$toast.error(this.tr('The password must be at least 8 characters long.'));
        return false;
      }
      if (this.password !== this.passwordAgain) {
        this.$toast.error(this.tr('Passwords given do not match!'));
        return false;
      }

      if (!this.gtcCheckbox) {
        let message = 'To use the app, you must accept the Terms and Conditions.';
        if (this.$app.isAd) {
          message = this.tr('To use Infinite∞Ad, you must accept the Terms and Conditions.');
        }
        else if (this.$app.isBuzz) {
          message = this.tr('To use Infinite∞Buzz, you must accept the Terms and Conditions.');
        }
        this.$toast.error(message);
        return false;
      }
      if (!this.privacyCheckbox) {
        let message = 'To use the app, you must accept the Privacy Policy.';
        if (this.$app.isAd) {
          message = this.tr('To use Infinite∞Ad, you must accept the Privacy Policy.');
        }
        else if (this.$app.isBuzz) {
          message = this.tr('To use Infinite∞Buzz, you must accept the Privacy Policy.');
        }
        this.$toast.error(message);
        return false;
      }

      return true;
    },
    getCapthcaToken() {
      return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(process.env.VUE_APP_RECAPTCHA_SITE_KEY, { action: 'signup' })
            .then((token) => resolve(token))
            .catch((err) => reject(err));
        });
      });
    },
    loadRecaptchaScript() {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`;
      document.head.appendChild(script);
    },
    removeRecaptchaScripts() {
      // Remove script tags
      const scripts = document.querySelectorAll('script[src*="recaptcha"]');
      scripts.forEach(script => script.remove());
      
      // Remove any reCAPTCHA iframes
      const iframes = document.querySelectorAll('iframe[src*="recaptcha"]');
      iframes.forEach(iframe => iframe.remove());
      
      // Remove any remaining reCAPTCHA related elements
      const recaptchaElements = document.querySelectorAll('.grecaptcha-badge, .g-recaptcha');
      recaptchaElements.forEach(element => element.remove());
      
      // Clear any global reCAPTCHA variables
      if (window.grecaptcha) {
        try {
          delete window.grecaptcha;
        } catch(e) {
          window.grecaptcha = undefined;
        }
      }
    },
  },

  beforeDestroy() {
    this.removeRecaptchaScripts();
  },
};
</script>
