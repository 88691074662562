var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-ai-risk-review-view" },
    [
      _vm.rule && !_vm.isLoading && _vm.isListEmpty
        ? [
            _vm.rule && _vm.rule.settings.ai_review
              ? _c(
                  "notification-card",
                  {
                    attrs: {
                      image: require("@/assets/img/thumbsup.png"),
                      title: _vm.tr("Super!"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "The Infinite∞Ai has not yet found any content problematic for advertising."
                          )
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "The Infinite∞Ai will continue to monitor the content and will notify you."
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _c(
                  "notification-card",
                  {
                    attrs: {
                      image: require("@/assets/img/sad_new.png"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            'This automation does not have "Checking for compliance with Meta Advertising Standards" enabled.'
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
          ]
        : _c(
            "div",
            { staticClass: "items" },
            [
              _vm._l(_vm.items, function (item, key) {
                return _c(
                  "card",
                  {
                    key: "ai-risk-review-item--" + key + "--" + item.post.id,
                    class: { "is-not-actual": !item.is_current },
                  },
                  [
                    _c("div", { staticClass: "datetime" }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value:
                                _vm.formatToYMD(item.created_at) +
                                ", " +
                                _vm.formatTime(item.created_at),
                              expression:
                                "formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.timeAgo(item.created_at, _vm.tr)) +
                              " "
                          ),
                        ]
                      ),
                      !item.is_current
                        ? _c("span", { staticClass: "is-not-actual" }, [
                            _vm._v(
                              " - " +
                                _vm._s(
                                  _vm.tr(
                                    "The result is no longer relevant because the automation or the entry has been modified since the review."
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", {
                      staticClass: "ai-action",
                      domProps: { innerHTML: _vm._s(_vm.buildAction(item)) },
                    }),
                    _c("div", {
                      staticClass: "post",
                      domProps: { innerHTML: _vm._s(item.post.shortText) },
                    }),
                    _c("div", { staticClass: "reason-title" }, [
                      _vm._v(_vm._s(_vm.tr("Reasoning of Infinite∞Ai")) + ":"),
                    ]),
                    item.reason
                      ? _c("div", {
                          staticClass: "reason quote",
                          domProps: { innerHTML: _vm._s(item.reason) },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "buttons" }, [
                      _vm.rule.action === "start"
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "button secondary small go-to-post-button",
                              attrs: {
                                href:
                                  "/post-copy-analysis/posts/" + item.post.id,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.tr("Go to Post")) + " ")]
                          )
                        : _vm._e(),
                      item.is_current && !item.isHandled
                        ? _c(
                            "button",
                            {
                              staticClass: "primary small",
                              attrs: {
                                disabled:
                                  _vm.loadingId || _vm.$root.isSupportMode,
                              },
                              on: { click: () => _vm.openOverruleModal(item) },
                            },
                            [
                              _vm.loadingId == item.id
                                ? _c("i", { staticClass: "icon loader white" })
                                : _c("i", { staticClass: "icon gavel white" }),
                              _vm._v(" " + _vm._s(_vm.tr("Override AI")) + " "),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    item.isHandled
                      ? _c("div", { staticClass: "status-wrapper" }, [
                          _c(
                            "div",
                            { staticClass: "status" },
                            [
                              _vm._v(" " + _vm._s(_vm.tr("Overruled")) + " "),
                              item.handled_by
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.tr("by :name", {
                                            ":name": item.handled_by.fullname,
                                          })
                                        ) +
                                        " "
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          item.handled_at
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.formatToYMD(item.handled_at) +
                                        ", " +
                                        _vm.formatTime(item.handled_at),
                                      expression:
                                        "formatToYMD(item.handled_at) + ', ' + formatTime(item.handled_at)",
                                    },
                                  ],
                                  staticClass: "datetime",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.timeAgo(item.handled_at, _vm.tr)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              _vm.hasMore
                ? _vm._l(_vm.isListEmpty ? [1, 2, 3] : [1, 2], function (i) {
                    return _c(
                      "card",
                      {
                        key: "rule-ai-risk-review-loader-skeleton--" + i,
                        ref: "loadMore",
                        refInFor: true,
                      },
                      [
                        _c("skeleton", {
                          staticClass: "datetime",
                          attrs: { width: "120px", height: "12px" },
                        }),
                        _c("skeleton", {
                          staticClass: "ai-action",
                          attrs: { width: "70%", "max-width": "600px" },
                        }),
                        _c("skeleton", {
                          staticClass: "post",
                          attrs: {
                            width: "70%",
                            "max-width": "600px",
                            height: "12px",
                          },
                        }),
                        _c("skeleton", {
                          staticClass: "reason-title",
                          attrs: { width: "140px" },
                        }),
                        _c("skeleton", {
                          staticClass: "reason",
                          attrs: { width: "80%" },
                        }),
                        _c(
                          "div",
                          { staticClass: "buttons" },
                          [
                            _c("skeleton", {
                              attrs: {
                                width: "120px",
                                height: "30px",
                                radius: "10px",
                              },
                            }),
                            _c("skeleton", {
                              attrs: {
                                width: "120px",
                                height: "30px",
                                radius: "10px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }