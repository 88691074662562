import Comment from '@/entities/Comment.js';
import Company from '@/entities/Company.js';
import ErrorLog from '@/entities/ErrorLog.js';
import RuleActivityLog from '@/entities/RuleActivityLog.js';
import User from '@/entities/User.js';
import api from '@/services/Api';

export default {
  getDashboardAd(companyId) {
    return api().get('/company/' + companyId + '/dashboard')
      .then(resp => {
        resp.rule_activity_log = resp.rule_activity_log.map(i => new RuleActivityLog(i));
        if (resp.last_post_boosting_activity) {
          resp.last_post_boosting_activity = new Date(resp.last_post_boosting_activity);
        }
        if (resp.last_rule_activity) {
          resp.last_rule_activity = new Date(resp.last_rule_activity);
        }
        if (resp.last_activity) {
          resp.last_activity = new Date(resp.last_activity);
        }

        return resp;
      });
  },

  getDashboardBuzz(companyId, { sourceProfileId, dateFrom, dateTo }) {
    const data = {};
    if (sourceProfileId) {
      data['source_profile_id'] = sourceProfileId;
    }
    if (dateFrom) {
      data['date_from'] = dateFrom.toISOString().split('T')[0];
    }
    if (dateTo) {
      data['date_to'] = dateTo.toISOString().split('T')[0];
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/company/' + companyId + '/dashboard?' + query)
      .then(resp => {
        resp.reviewed_comments.map(i => i.date = new Date(i.date));
        resp.moderated_comments.map(i => i.date = new Date(i.date));
        resp.quality_scores.map(i => i.date = new Date(i.date));
        resp.latest_activities = resp.latest_activities.map(i => new Comment(i));

        return resp;
      });
  },

  get(id) {
    return api().get('/company/' + id)
      .then(resp => new Company(resp));
  },

  update(id, company) {
    const data = {
      'name': company.name,
      'billing_first_name': company.billing_first_name,
      'billing_last_name': company.billing_last_name,
      'billing_company': company.billing_company,
      'billing_email': company.billing_email,
      'billing_vat_number': company.billing_vat_number,
      'billing_country': company.billing_country,
      'billing_zip': company.billing_zip,
      'billing_city': company.billing_city,
      'billing_address': company.billing_address,
      'timezone': company.timezone,
      'required_2fa': company.required_2fa,
    };

    return api().put('/company/' + id, data)
      .then(resp => new Company(resp));
  },

  delete(id) {
    return api().delete('/company/' + id);
  },

  reset(id) {
    return api().delete('/company/' + id + '/reset');
  },

  getErrorLogs({ id, page, limit, ruleId }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }
    if (ruleId) {
      data['rule_id'] = ruleId;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/company/' + id + '/error-logs?' + query)
      .then(resp => resp.map(i => new ErrorLog(i)));
  },

  getMembers(id) {
    return api().get('/company/' + id + '/members')
      .then(resp => resp.map(i => new User(i)));
  },
};
