var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "formsteptab-component" }, [
    _c(
      "div",
      { staticClass: "steps-container" },
      _vm._l(_vm.items, function (item, i) {
        return _c(
          _vm.component,
          {
            key: "tab" + i,
            tag: "component",
            class: _vm.tabClasses(i),
            attrs: { to: _vm.toAttribute(item) },
            on: { click: () => _vm.onClick(i) },
          },
          [_vm._v(" " + _vm._s(_vm.label(item)) + " ")]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }