var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block demographic-target-block-component",
      attrs: {
        title: _vm.title !== null ? _vm.title : _vm.tr("Demographic targeting"),
      },
    },
    [
      _vm.targetAudienceBlockIsLoading
        ? _c("div", { staticClass: "inline-loader" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm.entity.output_campaign.hasSpecialAdCategories
        ? _c("info-text", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr(
                    "Ads in special advertising categories cannot target demographic data."
                  )
                ) +
                " "
            ),
          ])
        : [
            !_vm.hasGenderInSelectedAudiences
              ? _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.tr("Gender"))),
                    ]),
                    _c("gender-selector", {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.entity.settings.adset_targeting.genders,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.entity.settings.adset_targeting,
                            "genders",
                            $$v
                          )
                        },
                        expression: "entity.settings.adset_targeting.genders",
                      },
                    }),
                  ],
                  1
                )
              : _c("info-text", [
                  _vm._v(
                    _vm._s(
                      _vm.tr(
                        "You have chosen a target audience where gender targeting is specified."
                      )
                    )
                  ),
                ]),
            !_vm.hasAgeInSelectedAudiences
              ? _c("div", [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.tr("Age"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "age-block" },
                    [
                      _c("age-selector", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.entity.settings.adset_targeting.age_min,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings.adset_targeting,
                              "age_min",
                              $$v
                            )
                          },
                          expression: "entity.settings.adset_targeting.age_min",
                        },
                      }),
                      _vm._v(" - "),
                      _c("age-selector", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.entity.settings.adset_targeting.age_max,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings.adset_targeting,
                              "age_max",
                              $$v
                            )
                          },
                          expression: "entity.settings.adset_targeting.age_max",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _c("info-text", [
                  _vm._v(
                    _vm._s(
                      _vm.tr(
                        "You have chosen a target audience where age targeting is specified."
                      )
                    )
                  ),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }