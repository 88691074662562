import RuleService from '@/services/Rule.js';

const ITEM_PER_PAGE = 20;

const state = {
  isLoading: false,
  hasMore: true,
  page: 0,
  actionFilter: null,
  adAccountId: null,
  statusFilter: null,
  searchTerm: '',
  orderBy: 'created_at',
  order: 'desc',
  list: [],
  hasAnyRules: false,
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  hasMore(state) {
    return state.hasMore;
  },
  page(state) {
    return state.page;
  },
  actionFilter(state) {
    return state.actionFilter;
  },
  adAccountId(state) {
    return state.adAccountId;
  },
  statusFilter(state) {
    return state.statusFilter;
  },
  searchTerm(state) {
    return state.searchTerm;
  },
  orderBy(state) {
    return state.orderBy;
  },
  order(state) {
    return state.order;
  },
  list(state) {
    return state.list;
  },
  hasAnyRules(state) {
    return state.hasAnyRules;
  },
};

const actions = {
  resetView(store, keepTerm) {
    store.commit('setIsLoading', false);
    store.commit('setHasMore', true);
    store.commit('setPage', 0);
    store.commit('setList', []);

    if (!keepTerm) {
      store.commit('setActionFilter', null);
      store.commit('setAdAccountId', null);
      store.commit('setStatusFilter', null);
      store.commit('setSearchTerm', '');
    }
  },
  loadMoreData(store) {
    if (
      store.getters['isLoading']
      || !store.getters['hasMore']
    ) {
      return [];
    }

    store.commit('setIsLoading', true);
    store.commit('increasePage');

    const params = {
      term: store.getters['searchTerm'],
      action: store.getters['actionFilter'],
      adAccountId: store.getters['adAccountId'],
      campaignStatus: store.getters['statusFilter'],
      orderBy: store.getters['orderBy'],
      order: store.getters['order'],
      page: store.getters['page'],
      limit: ITEM_PER_PAGE
    };

    if (store.getters['statusFilter'] !== null) {
      params['status'] = store.getters['statusFilter'] ? 1 : 0;
    }

    return RuleService.getList(params)
      .then(resp => {
        let list = store.getters['list'];
        resp.forEach(item => list.push(item));
        store.commit('setList', list);

        store.commit('setHasMore', resp.length === ITEM_PER_PAGE);

        return resp;
      })
      .catch((err) => {
        store.commit('setHasMore', false);
        throw err;
      })
      .finally(() => {
        store.commit('setIsLoading', false);
      });
  }
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setHasMore(state, value) {
    state.hasMore = value;
  },
  setPage(state, value) {
    state.page = value;
  },
  increasePage(state) {
    state.page += 1;
  },
  setActionFilter(state, value) {
    state.actionFilter = value;
  },
  setAdAccountId(state, value) {
    state.adAccountId = value;
  },
  setStatusFilter(state, value) {
    state.statusFilter = value;
  },
  setSearchTerm(state, value) {
    state.searchTerm = value;
  },
  setOrderBy(state, value) {
    state.orderBy = value;
  },
  setOrder(state, value) {
    state.order = value;
  },
  setVisibleFields(state, value) {
    state.visibleFields = value;
  },
  setList(state, value) {
    state.list = value;
    if (value.length > 0) {
      state.hasAnyRules = true;
    }
  },
  ruleRemoved(state, id) {
    state.list = state.list.filter(item => item.id !== id);
  },
  updateInList(state, entity) {
    const entityInList = state.list.find(item => item.id === entity.id);
    if (entityInList) {
      entityInList.updateWith(entity);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
