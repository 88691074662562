import ChartTooltip from '@/chart/chartTooltip';
import * as d3 from 'd3';

export default class ChartDataDetails
{
  data = null;
  xKey = null;
  yKey = null;
  color = 'black';
  #tooltip = null;

  circleOnHoverElement = null;
  verticalLineOnHoverElement = null;

  constructor(data, xKey, yKey)
  {
    this.data = data;
    this.xKey = xKey;
    this.yKey = yKey;
  }

  set tooltip(value) {
    if (!(value instanceof ChartTooltip)) {
      throw new Error('Invalid tooltip details.');
    }

    this.#tooltip = value;
  }

  get tooltip() {
    return this.#tooltip;
  }

  updateDynamicDetails(event, xScale, yScale) {
    const i = this.#tooltip.bisect(this.data, xScale.invert(d3.pointer(event)[0]));
    const xPos = xScale(this.data[i][this.xKey]);
    const yPos = yScale(this.data[i][this.yKey]);

    if (this.#tooltip) {
      this.#tooltip.draw(
        xScale(this.data[i][this.xKey]),
        yScale(this.data[i][this.yKey]),
        this.data[i][this.xKey].toISOString().split('T')[0],
        this.data[i][this.yKey]
      );
    }

    if (this.circleOnHoverElement) {
      this.circleOnHoverElement.style('display', null);
      this.circleOnHoverElement.attr('transform', `translate(${xPos}, ${yPos})`);
    }

    if (this.verticalLineOnHoverElement) {
      this.verticalLineOnHoverElement.style('display', null);
      this.verticalLineOnHoverElement.attr('transform', `translate(${xPos}, 0)`);
    }
  }

  hideOnHoverDetails() {
    if (this.circleOnHoverElement) {
      this.circleOnHoverElement.style('display', 'none');
    }

    if (this.verticalLineOnHoverElement) {
      this.verticalLineOnHoverElement.style('display', 'none');
    }

    if (this.#tooltip) {
      this.#tooltip.hide();
    }
  }
}
