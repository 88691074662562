var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("modal", { attrs: { name: _vm.name }, on: { closed: _vm.onClosed } }, [
        _c("div", { staticClass: "top" }, [
          _vm.icon ? _c("i", { class: "icon " + _vm.icon }) : _vm._e(),
          _vm.image
            ? _c("div", [
                _c("img", {
                  class: "image " + _vm.imageClass,
                  attrs: { src: _vm.image },
                }),
              ])
            : _vm._e(),
          _vm.title
            ? _c("div", {
                staticClass: "title",
                domProps: { innerHTML: _vm._s(_vm.title) },
              })
            : _vm._e(),
          _vm.content
            ? _c("div", {
                staticClass: "content",
                domProps: { innerHTML: _vm._s(_vm.content) },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "button",
            {
              class: "cancel-button " + _vm.cancelClass,
              on: { click: _vm.actionCancel },
            },
            [_vm._v(" " + _vm._s(_vm.labelCancel) + " ")]
          ),
          _c(
            "button",
            {
              class: "confirm-button " + _vm.confirmClass,
              on: { click: _vm.actionConfirm },
            },
            [
              _vm.progress
                ? _c("i", { staticClass: "icon loader white" })
                : [_vm._v(" " + _vm._s(_vm.labelConfirm) + " ")],
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }