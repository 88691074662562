var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: { content: _vm.tooltipContent, classes: [""] },
          expression: "{ content: tooltipContent, classes: [ '' ] }",
        },
      ],
      staticClass: "rule-next-run-tooltip-component",
      on: {
        mouseover: function ($event) {
          _vm.isActive = true
        },
        mouseleave: function ($event) {
          _vm.isActive = false
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }