var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      !_vm.minimal ? _c("system-level-notifications") : _vm._e(),
      !_vm.minimal
        ? _c(
            "info-text",
            { staticClass: "meta-tech", attrs: { icon: "meta" } },
            [_vm._v(" " + _vm._s(_vm.techProviderText) + " ")]
          )
        : _vm._e(),
      !_vm.minimal && _vm.list.length !== 0
        ? _c("div", { staticClass: "page-header" }, [
            _vm.headerTitle
              ? _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.headerTitle)),
                ])
              : _vm._e(),
            _vm.headerText
              ? _c("div", { staticClass: "description" }, [
                  _vm._v(" " + _vm._s(_vm.headerText) + " "),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.list.length > 10
        ? _c("div", { staticClass: "actions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              staticClass: "text icon--search",
              attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.list.length === 0
        ? [
            !_vm.hasConnectedProfile
              ? _c("empty-screen", {
                  attrs: {
                    icon: _vm.emptyScreenIcon,
                    title: _vm.tr("Connect your accounts to start automating"),
                    "flag-text": _vm.tr("Connect profiles"),
                    description: _vm.tr(
                      "To begin using our AI-powered automation features, you'll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising."
                    ),
                    mobile: _vm.minimal,
                  },
                  on: { click: _vm.handleClick },
                })
              : _vm.platform === "facebook"
              ? _c("empty-screen", {
                  attrs: {
                    icon: "facebook",
                    title: _vm.tr(
                      "Facebook page needed for Facebook ad automation"
                    ),
                    "flag-text": _vm.tr("Watch tutorial"),
                    description: _vm.tr(
                      "While your Instagram account is connected, we couldn't find any Facebook pages linked to your Meta profile. If you want to automate ads from Facebook posts, you'll need to add a Facebook page. Watch our tutorial video to learn how to add a Facebook page to your Meta Business profile in just a few minutes."
                    ),
                    mobile: _vm.minimal,
                    path: _vm.tr("https://www.youtube.com/watch?v=OPuP9F7DQEY"),
                  },
                })
              : _vm.platform === "instagram"
              ? _c("empty-screen", {
                  attrs: {
                    icon: "instagram",
                    title: _vm.tr(
                      "Instagram account needed for Instagram content automation"
                    ),
                    description: _vm.tr(
                      "While your Facebook page is connected, we couldn't find any Instagram accounts linked to your Meta profile. If you want to automate ads from Instagram posts, you'll need to add an Instagram account. Watch our tutorial video to learn how to add an Instagram account to your Meta Business profile in just a few minutes."
                    ),
                    "flag-text": _vm.tr("Watch tutorial"),
                    mobile: _vm.minimal,
                  },
                })
              : _vm._e(),
          ]
        : _vm.filteredList.length === 0
        ? _c("empty-screen", {
            attrs: {
              icon: "search",
              title: _vm.tr("No results match your current search criteria"),
              "flag-text": _vm.tr("Modify search"),
              description: _vm.tr(
                "Currently there are no items that match your criteria. The items you're looking for might appear here later, or try different parameters to see currently available items."
              ),
              mobile: _vm.minimal,
            },
          })
        : _c("sorted-table", {
            staticClass: "row-hover-shadow",
            attrs: {
              data: _vm.filteredList,
              "initial-sort": { key: "name", direction: "asc" },
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      {
                        staticClass: "name-cell",
                        attrs: { col: "name", sortable: "" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Name")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "status-cell",
                        attrs: { col: "status", width: "80px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Status")))]
                    ),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          {
                            staticClass: "external-id-cell",
                            attrs: { col: "id", width: "180px", sortable: "" },
                          },
                          [_vm._v(" " + _vm._s(_vm.idLabel) + " ")]
                        )
                      : _vm._e(),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          { attrs: { col: "connected_by", width: "180px" } },
                          [_vm._v(_vm._s(_vm.tr("Connected by")))]
                        )
                      : _vm._e(),
                    !_vm.minimal && _vm.$app.isAd
                      ? _c("table-head", {
                          attrs: {
                            col: "actions",
                            width: _vm.$root.supportHasL2Access
                              ? "140px"
                              : "30px",
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (row) {
                    return _c(
                      "table-row",
                      {
                        key: row.id,
                        class: { active: _vm.selectedItems.includes(row.id) },
                      },
                      [
                        _c(
                          "table-data",
                          { staticClass: "name-cell", attrs: { col: "name" } },
                          [
                            _c(
                              "label",
                              [
                                _vm.isLoading && _vm.loadingId === row.id
                                  ? _c("i", { staticClass: "icon loader" })
                                  : _c("input", {
                                      attrs: {
                                        type: "checkbox",
                                        disabled: _vm.isDisabled(row),
                                      },
                                      domProps: {
                                        value: row.id,
                                        checked: _vm.isConnected(row),
                                      },
                                      on: {
                                        change: () => _vm.checkboxChanged(row),
                                      },
                                    }),
                                _c("social-page", {
                                  attrs: { "source-profile": row },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "table-data",
                          {
                            staticClass: "status-cell",
                            attrs: { col: "status" },
                          },
                          [
                            _vm.isConnected(row)
                              ? _c("span", { class: _vm.statusClasses(row) }, [
                                  _vm._v(_vm._s(_vm.tr("Active"))),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              {
                                staticClass: "external-id-cell",
                                attrs: { col: "id" },
                              },
                              [_vm._v(" " + _vm._s(row.id) + " ")]
                            )
                          : _vm._e(),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              { attrs: { col: "connected_by" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.owner ? row.owner.fullname : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.minimal && _vm.$app.isAd
                          ? _c("table-data", { attrs: { col: "actions" } }, [
                              _vm.isConnected(row)
                                ? _c(
                                    "div",
                                    { staticClass: "action-group" },
                                    [
                                      _vm.$root.supportHasL2Access
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "bordered gray small",
                                              on: {
                                                click: () =>
                                                  _vm.openCommunicatorDebug(
                                                    row
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.tr("Debug")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("navigation-arrow", {
                                        on: {
                                          click: () =>
                                            _vm.openSourceProfileSettings(row),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
      _c("disconnect-confirm-modal", { ref: "disconnectConfirmModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }