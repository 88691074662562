<template>
  <div :class="wrapperClasses">
    <template v-if="isSkeleton">
      <div class="image-wrapper">
        <skeleton
          class="image"
          width="35px"
          height="35px"
          radius="100%"
        />
      </div>
      <div class="texts">
        <skeleton width="100px" />
      </div>
    </template>

    <template v-else>
      <div class="image-wrapper">
        <lazy-image class="image" :src="sourceProfile.picture" />
        <div class="platform-icon">
          <i :class="'icon ' + sourceProfile.platform + '-color uncolored'" />
        </div>
      </div>

      <div class="texts">
        <div v-if="date" v-tooltip="dateString" class="date">
          {{ dateAgoString }}
        </div>
        <div class="name">{{ sourceProfile.name }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue';
import Skeleton from '@/components/Skeleton.vue';
import SourceProfile from '@/entities/SourceProfile.js';
import { formatTime, formatToStringYMD, timeAgo } from '@/utils/date';

export default {
  name: 'SocialPage',
  components: {
    LazyImage,
    Skeleton,
  },
  props: {
    sourceProfile: {
      type: [ SourceProfile, null ],
      required: false,
      default: () => null,
    },
    date: {
      type: [ Date, null ],
      required: false,
      default: () => null,
    },
    dateTooltipPrefix: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'social-page-component': true,
        'is-skeleton': this.isSkeleton,
      };
    },
    isSkeleton() {
      return this.sourceProfile === null;
    },
    dateString() {
      if (this.date === null) {
        return null;
      }

      const dateString = formatToStringYMD(this.date, this.tr) + ' ' + formatTime(this.date);

      if (this.dateTooltipPrefix) {
        return this.dateTooltipPrefix + ' ' + dateString;
      }

      return dateString;
    },
    dateAgoString() {
      if (this.date === null) {
        return null;
      }

      return timeAgo(this.date, this.tr);
    },
  },
};
</script>
