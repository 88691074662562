import AuthService from '@/services/Auth';

/* global FB */

export default {
  computed: {
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    connectProfileButtonClasses() {
      return {
        button: true,
        primary: true,
        'connect-new-profile': true,
        loading: this.isConnecting,
      };
    },
  },
  created() {
    this.$store.dispatch('app/loadFbSdk');
  },
  methods: {
    onFacebookProfileConnected() {
    },
    reconnect(profile) {
      // TODO: alert modal.

      return AuthService.revokeProfilePermissions(profile.id)
        .then(() => {
          return this.connect(false, profile)
            .catch(err => {
              this.$store.dispatch('auth/removeProfile', profile.id);
              this.error(err); // TODO: improved error message: leálltak a szabályaid.
            });
        })
        .catch(err => this.error(err));
    },
    connect(navigateToProfilesPage, profile, skipSuccessMessage) {
      this.$store.commit('app/isConnecting', true);

      return this._login(profile, skipSuccessMessage)
        .then(() => {
          if (navigateToProfilesPage && this.$route.path !== '/connected-platforms/meta/profiles') {
            this.$router.push('/connected-platforms/meta/profiles');
          }
        });
    },
    _getCurrentUserToken() {
      return new Promise(resolve => {
        FB.login(response => {
          if (response.authResponse && response.authResponse.accessToken) {
            resolve(response.authResponse.accessToken);
          }

          resolve(null);
        });
      });
    },
    _revokePermissions(token) {
      return new Promise(resolve => {
        FB.api('me/permissions?access_token=' + token, 'delete', (r1) => {
          console.log(r1);

          resolve();
        });
      });
    },
    _login(profile, skipSuccessMessage) {
      let scopes = ''
      if (
        typeof this.$store.getters['app/facebook'] !== 'undefined'
        && typeof this.$store.getters['app/facebook'].scopes !== 'undefined'
        && Array.isArray(this.$store.getters['app/facebook'].scopes)
      ) {
        scopes = this.$store.getters['app/facebook'].scopes.join(',')
      }

      return new Promise((resolve, reject) => {
        FB.login(
          (response) => {
            if (!response.authResponse) {
              this.$store.commit('app/isConnecting', false);
              this.$toast.error(
                this.tr('User cancelled login or did not provide full authorization for the app.')
              );
              return reject();
            }

            if (profile && profile.external_id !== response.authResponse.userID) {
              console.log(response.authResponse);
              this.$store.commit('app/isConnecting', false);
              this.$toast.error(
                this.tr('You are not currently logged in to Facebook with this user, so reconnecting is not possible.')
              );
              return reject();
            }

            const accessToken = response.authResponse.accessToken;
            this.$store
              .dispatch('auth/connectProfile', {
                platform: 'facebook',
                accessToken,
              })
              .then(() => {
                this.onFacebookProfileConnected();

                resolve();

                if (!skipSuccessMessage) {
                  if (profile) {
                    this.$toast.success(
                      this.tr('Profile reconnected successfully.')
                    );
                  }
                  else {
                    this.$toast.success(
                      this.tr('Profile connected successfully.')
                    );
                  }
                }

                if (window.fbq) {
                  window.fbq('trackCustom', 'ProfileConnected');
                }
                if (window.ttq) {
                  window.ttq.track('ProfileConnected');
                }
                if (window.gtag) {
                  if (this.$app.isBuzz) {
                    window.gtag('event', 'conversion', {'send_to': 'AW-11432616777/zPXvCNCC4vwYEMnGv8sq'});
                  }
                }
              })
              .catch((err) => this.error(err, 'An error occurred while connecting the profile'))
              .finally(() => this.$store.commit('app/isConnecting', false));
          },
          {
            scope: scopes
          }
        );
      });
    }
  }
};
