<template>
  <div class="rule-overview-view">
    <div class="left">
      <sticky-container :active="stickyIsActive">
        <div class="action-buttons">
          <router-link
            :class="'button primary ' + (isLoading ? 'disabled' : '')"
            :to="isLoading ? '' : ('/automation/' + rule.id + '/edit')"
          >
            <i class="icon edit white" />
            {{ tr('Edit') }}
          </router-link>
          <router-link
            :class="'button primary ' + (isLoading ? 'disabled' : '')"
            :to="isLoading ? '' : ('/automation/' + rule.id + '/duplicate')"
          >
            <i class="icon copy white" />
            {{ tr('Duplicate') }}
          </router-link>
        </div>

        <div class="rule-details">
          <card :header-color="rule && rule.active ? 'primary' : 'inactive'">
            <template #header>
              <switches v-if="!rule" />
              <switches
                v-else
                v-model="rule.active"
                :disabled="statusSwitchIsDisabled"
                :emit-on-mount="false"
                @input="() => onChangeRuleStatus(rule)"
              />
              {{ tr('Automation') }}:

              <template v-if="isLoading">
                ...
              </template>
              <template v-else>
                {{ rule.active ? tr('Active') : tr('Inactive') }}
              </template>
            </template>

            <template #default>
              <template v-if="!isLoading">
                <div
                  v-for="profile in sourceProfiles"
                  :key="'source-profile--' + profile.id"
                >
                  <social-page :source-profile="profile" />
                  <div
                    v-if="rule.action === 'start' || rule.action === 'scale'"
                    class="ai-settings"
                  >
                    <span>
                      {{ tr('Ai Policy Check') }}
                      <i :class="{ 'icon shield': true, 'primary': rule.settings.ai_review, 'gray': !rule.settings.ai_review }" />
                      <i v-if="rule.settings.ai_review" class="icon icon--check checkmark-circle-blue uncolored" />
                    </span>
                    <span>
                      {{ tr('Ai Content Actuality') }}
                      <i :class="{ 'icon calendar': true, 'primary': rule.settings.dont_create_expired_ads, 'gray': !rule.settings.dont_create_expired_ads }" />
                      <i v-if="rule.settings.dont_create_expired_ads" class="icon icon--check checkmark-circle-blue uncolored" />
                    </span>
                  </div>
                </div>

                <div v-if="rule.action !== 'stop'">
                  <label>{{ tr('Budget') }}:</label>
                  {{ budget }}
                </div>

                <div>
                  <label>{{ tr('Ad Account') }}:</label>
                  <span class="flag purple">
                    {{ rule.ad_account ? rule.ad_account.name : '---' }}
                  </span>
                </div>

                <div v-if="rule.action !== 'stop'">
                  <label>{{ tr('Number of active ads') }}:</label>
                  {{ rule.active_ads }}
                </div>
              </template>

              <div v-else class="skeletons">
                <skeleton width="40%" height="14px" />
                <skeleton width="60%" height="14px" />
              </div>
            </template>
          </card>

          <card :header-color="!isLoading ? 'primary' : 'inactive'">
            <template #header>
              {{ tr('Activity') }}
            </template>
            <template #default>
              <template v-if="!isLoading">
                <div>
                  <label>{{ tr('Created') }}:</label>
                  {{ formatToStringYMD(rule.created_at, tr) }}
                </div>

                <div>
                  <label>{{ tr('Last modified') }}:</label>
                  <span
                    v-if="rule.updated_at"
                    v-tooltip="{ content: formatToYMD(rule.updated_at) + '. ' + formatTime(rule.updated_at), classes: [ '' ] }"
                  >
                    {{ timeAgo(rule.updated_at, tr) }}
                  </span>
                  <template v-else> - </template>
                </div>

                <div>
                  <label>{{ tr('Last run') }}:</label>
                  <span
                    v-if="rule.last_run"
                    v-tooltip="{ content: formatToYMD(rule.last_run) + '. ' + formatTime(rule.last_run), classes: [ '' ] }"
                  >
                    {{ timeAgo(rule.last_run, tr) }}
                  </span>
                  <template v-else> - </template>
                </div>

                <!-- <div v-if="rule.active">
                  <label>{{ tr('Next run in') }}:</label>
                  {{ nextRunCountdown }}
                </div> -->

                <div>
                  <label>{{ tr('Last activity') }}:</label>
                  <span
                    v-if="rule.last_activity"
                    v-tooltip="{ content: formatToYMD(rule.last_activity) + '. ' + formatTime(rule.last_activity), classes: [ '' ] }"
                  >
                    {{ timeAgo(rule.last_activity, tr) }}
                  </span>
                  <template v-else> - </template>
                </div>

                <div>
                  <label>{{ tr('Tasks done') }}:</label>
                  {{ rule.triggered > 0 ? rule.triggered : '-' }}
                </div>
              </template>

              <div v-else class="skeletons">
                <skeleton width="40%" height="14px" />
                <skeleton width="60%" height="14px" />
                <skeleton width="30%" height="14px" />
              </div>
            </template>
          </card>

          <card :header-color="!isLoading ? 'primary' : 'inactive'" class="connected-rules">
            <template #header>
              {{ tr('Connected automations') }}
            </template>

            <template v-if="!isLoading">
              <div v-if="rule.connected_rules.length === 0" class="empty-text">
                {{ tr('No connected automations') }}
              </div>

              <link-with-icon
                v-for="connectedRule in rule.connected_rules"
                :key="'connected-rule--' + connectedRule.id"
                :link="'/automation/' + connectedRule.id + '/overview'"
              >
                {{ connectedRule.name }}
              </link-with-icon>
            </template>

            <div v-else class="skeletons">
              <skeleton width="40%" height="14px" />
              <skeleton width="60%" height="14px" />
              <skeleton width="30%" height="14px" />
            </div>
          </card>
        </div>

        <div v-if="!isLoading" class="action-buttons action-buttons--bottom">
          <button
            class="button red"
            @click="openDeleteConfirmationModal()"
          >
            <i class="icon delete white" />
            {{ tr('Delete') }}
          </button>

          <button
            v-if="$root.currentCompany.enabled_manual_run && $root.loggedInUser.level === 'admin'"
            class="button primary"
            :disabled="isRunning"
            @click="runRule()"
          >
            <i :class="runIconClasses" />
            {{ tr('Run') }}
          </button>
        </div>
      </sticky-container>
    </div>

    <div class="right">
      <sticky-container :active="stickyIsActive">
        <activity-log
          ref="activityLog"
          :rule-id="rule ? rule.id : null"
        />
      </sticky-container>
    </div>
  </div>
</template>

<script>
import Switches from 'vue-switches';

import ActivityLog from '@/components/ActivityLog.vue';
import Card from '@/components/Card.vue';
import DeleteRuleConfirmModalContent from '@/components/DeleteRuleConfirmModalContent.vue';
import LinkWithIcon from '@/components/LinkWithIcon.vue';
import Skeleton from '@/components/Skeleton.vue';
import SocialPage from '@/components/SocialPage.vue';
import StickyContainer from '@/components/StickyContainer.vue';
import Rule from '@/entities/Rule.js';
import RuleSwitchMixin from '@/mixins/RuleSwitch.js';
import RuleService from '@/services/Rule.js';
import { formatTime, formatToStringYMD, formatToYMD, timeAgo } from '@/utils/date';
import { formatNumber } from '@/utils/number';

export default {
  name: 'RuleOverview',
  components: {
    StickyContainer,
    ActivityLog,
    Card,
    LinkWithIcon,
    Switches,
    Skeleton,
    SocialPage,
  },
  mixins: [ RuleSwitchMixin ],
  data() {
    return {
      windowWidth: window.innerWidth,
      nextRunCountdown: '',
      countdownTimer: null,
      isRunning: false,
    };
  },
  computed: {
    rule() {
      return this.$parent.rule;
    },
    stickyIsActive() {
      return this.windowWidth >= 992;
    },
    isLoading() {
      return !(this.rule instanceof Rule);
    },
    statusSwitchIsDisabled() {
      return this.$root.loggedInUser.isRestrictedViewer
        || this.$root.isSupportMode
        || this.isLoading
        || this.deletedCampaignFlagIsVisible;
    },
    runIconClasses() {
      return {
        'icon': true,
        'white': true,
        'play': !this.isRunning,
        'loader': this.isRunning,
      };
    },
    deletedCampaignFlagIsVisible() {
      return this.rule.campaign_status === 'deleted';
    },
    budget() {
      let budget = null;
      let period = null;
      let isAdsetBudget = false;

      if (this.rule.output_campaign && this.rule.output_campaign.budget) {
        budget = formatNumber(this.rule.output_campaign.budget, null, 2);
        period = this.rule.output_campaign.budget_period;
      }
      else if (this.rule.settings.adset_budget) {
        budget = formatNumber(this.rule.settings.adset_budget, null, 2);
        period = this.rule.settings.adset_budget_period;
        isAdsetBudget = true;
      }
      else {
        return '???';
      }

      const currency = this.rule.adAccountCurrency;
      if (!currency) {
        return '???';
      }

      let resultString = '';

      if (this.$root.language === 'hu') {
        resultString = `${budget} ${currency.symbol} ${this.tr(period)}`;
      }
      else {
        resultString = `${currency.symbol}${budget} ${this.tr(period)}`;
      }

      if (isAdsetBudget) {
        resultString += ` / ${this.tr('Ad set')}`;
      }

      return resultString;
    },
    sourceProfiles() {
      if (!this.rule.source_profiles) {
        return [];
      }
      return this.rule.source_profiles;
    },
  },
  watch: {
    'rule.active': {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          if (val !== oldVal) {
            this.initCountdownTimer();
          }
        }
        else {
          this.destroyCountdownTimer();
        }
      },
    },
    isRunning() {
      this.updateNextRunCountdown();
    },
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    this.destroyCountdownTimer();
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    formatToStringYMD,
    formatToYMD,
    formatTime,
    timeAgo,
    initCountdownTimer() {
      /* this.destroyCountdownTimer();
      this.updateNextRunCountdown();
      this.countdownTimer = setInterval(this.updateNextRunCountdown, 1000); */
    },
    destroyCountdownTimer() {
      if (this.countdownTimer !== null) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
        this.nextRunCountdown = '-';
      }
    },
    updateNextRunCountdown() {
      if (!this.rule) {
        this.nextRunCountdown = '-';
        return;
      }

      const { nextRunInSeconds } = this.rule;
      if (nextRunInSeconds === null) {
        this.nextRunCountdown = '-';
      }
      else if (this.isRunning || nextRunInSeconds === 0) {
        this.nextRunCountdown = this.tr('just now');
      }
      else {
        this.nextRunCountdown = this.secToHHMMSS(nextRunInSeconds);
      }
    },
    secToHHMMSS(secNum) {
      let hours = Math.floor(secNum / 3600);
      let minutes = Math.floor((secNum - hours * 3600) / 60);
      let seconds = Math.floor(secNum - hours * 3600 - minutes * 60);
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      let result = minutes + ':' + seconds;
      if (hours !== '00') {
        result = hours + ':' + result;
      }
      return result;
    },
    openDeleteConfirmationModal() {
      this.$modal.show(
        DeleteRuleConfirmModalContent,
        { rule: this.rule },
        { class: 'confirmation-modal' },
      );
    },
    runRule() {
      if (this.isRunning) {
        return;
      }

      this.isRunning = true;

      RuleService.run(this.rule.id)
        .then(resp => {
          this.rule.updateWith(resp);
          this.$store.commit('rules/updateInList', this.rule);

          this.$toast.clear();
          this.$toast.success(this.tr('Automation executed successfully.'));

          if (this.$refs.activityLog) {
            this.$refs.activityLog.refresh();
          }

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isRunning = false);
    },
  },
};
</script>
