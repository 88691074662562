import Api from '@/services/Api';

import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';

export default {
  getList({ ruleId, status, handled_by, page, limit }) {
    const data = {};
    if (typeof status !== 'undefined') {
      data['status'] = status;
    }
    if (handled_by) {
      data['handled_by'] = handled_by;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/rule/' + ruleId + '/ai-management/list?' + query)
      .then((resp) => {
        return resp.map(i => new PostAiProhibitedContent(i));
      });
  },

  overruleResult(ruleId, entity) {
    return Api().put('/rule/' + ruleId + '/ai-management/' + entity.id + '/' + entity.post.id)
      .then((resp) => new PostAiProhibitedContent(resp));
  },
};
