var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    {
      tag: "component",
      class: _vm.wrapperClasses,
      attrs: { to: _vm.path },
      on: { click: ($event) => _vm.$emit("click", $event) },
    },
    [
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("div", { staticClass: "first-line" }, [
            _c("i", {
              class: "action-icon icon " + _vm.icon + " " + _vm.color,
            }),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c("navigation-arrow", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.disabled,
                    expression: "!disabled",
                  },
                ],
                attrs: { link: _vm.path, color: _vm.navigationArrowColor },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }