<template>
  <div class="rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-4">
    <form-block
      :title="tr('Manage original ad')"
      :description="tr('You can set whether the scaled-up original ad should continue running or stop automatically.')"
    >
      <div class="checkbox-list vertical">
        <label>
          <input
            v-model="entity.settings.stop_original"
            type="radio"
            name="keep-or-stop"
            :value="false"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Keep original ad running') }}
        </label>

        <label>
          <input
            v-model="entity.settings.stop_original"
            type="radio"
            name="keep-or-stop"
            :value="true"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Stop original ad automatically') }}
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <form-block :title="tr('Ad creation method')">
      <div class="checkbox-list vertical">
        <label>
          <input
            v-model="entity.settings.ad_creation_method"
            type="radio"
            name="ad-creation-method"
            value="copy"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Copy original ad') }}
        </label>

        <label>
          <input
            v-model="entity.settings.ad_creation_method"
            type="radio"
            name="ad-creation-method"
            value="creative"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Create new ad creative(s)') }}
        </label>
      </div>

      <template v-if="entity.settings.ad_creation_method === 'creative'">
        <div>
          <label class="input-label">{{ tr('AI Improvements') }}</label>
          <div class="description">
            Könnyedén rábízhatod az Infinite∞Ai-ra, hogy automatikusan optimalizálja a hirdetéseid egyes elemeit, így hatékonyabbá téve a kampányaidat.
            Az Ai a célközönséged jellemzői és a hirdetésed kontextusa alapján készíti el és állítja be a különböző részeket, hogy a lehető legjobban szólítsa meg a közönségedet.
          </div>
          <div class="checkbox-list vertical">
            <label
              v-for="(item, key) in aiImprovementFields"
              :key="'scaling-up-settins--ai--' + key"
              v-tooltip.right="tr(item.description)"
            >
              <input
                type="checkbox"
                v-model="aiImprovements"
                :value="item.machine_name"
                :disabled="$parent.readonlyMode"
              />

              {{ item.label }}
            </label>
          </div>
        </div>

        <div>
          <label class="input-label">
            {{ tr('Number of Ad Variations') }}
          </label>
          <div class="description">
            {{ tr('You can specify how many different variations should be created from the ad that will be scaled up.') }}
          </div>
          <number-input
            v-model="entity.settings.ad_variants"
            :step="1"
            :min="1"
            :max="5"
            :disabled="$parent.readonlyMode"
            class="ad-variations-number"
          />
        </div>

        <div v-if="entity.settings.ad_variants > 1">
          <label>
            <input
              type="checkbox"
              v-model="entity.settings.ai_ad_with_original_content"
              :disabled="$parent.readonlyMode"
            />

            {{ tr('One of the created variations should be made without Infinite∞Ai') }}
          </label>

          <info-text v-if="!adVariantsValueIsValid">
            {{ tr('If you want to create multiple ad variations, you need to specify at least one ad element that the Infinite∞Ai can determine.') }}
          </info-text>
        </div>

        <button class="button primary ap-creative-settings" @click="() => openApCreativeSettings()">
          <i class="icon development white" />
          {{ tr('Meta Advantage+ Creative Settings') }}
        </button>
      </template>
    </form-block>

    <div class="divider" />

    <cta-block
      v-if="entity.settings.ad_creation_method === 'creative'"
      :entity="entity"
      :disabled="$parent.readonlyMode || ctaIsDisabled"
      :disabled-text="ctaDisabledText"
      switchable
    />

    <div class="divider" />

    <url-params-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>
<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import CtaBlock from '@/components/RuleForm/Blocks/CtaBlock.vue';
import UrlParamsBlock from '@/components/RuleForm/Blocks/UrlParamsBlock.vue';
import MetaApCreativeFeaturesModal from '@/components/RuleForm/MetaApCreativeFeaturesModal.vue';

import InfoText from '@/components/InfoText.vue';
import NumberInput from '@/components/NumberInput.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default {
  name: 'ScalingUpRuleFormStep4',
  mixins: [ RuleFormStepMixin ],
  components: {
    CtaBlock,
    UrlParamsBlock,
    InfoText,
    NumberInput,
    TransitionExpand,
  },
  data: () => ({
    aiImprovements: [],
  }),
  computed: {
    aiImprovementFields() {
      return [
        {
          label: this.tr('Primary text'),
          description: this.tr('Infinite∞Ai writes the main content to ensure your message reaches its target effectively and with impact.'),
          machine_name: 'ai_primary_text',
        },
        {
          label: this.tr('CTA Title and Description'),
          description: this.tr('Hagyd, hogy az Infinite∞Ai javasoljon egy ütős címet és leírást a hirdetésedhez, hogy még jobban megragadhassa a figyelmet.'),
          machine_name: 'ai_cta_content',
        },
        {
          label: this.tr('CTA Button type'),
          description: this.tr('Let AI choose the best button type (such as \"Learn More\" or \"Buy Now\") to support the effectiveness of your ad.'),
          machine_name: 'ai_cta_button',
        },
      ];
    },
    adVariantsValueIsValid() {
      return !(this.entity.settings.ad_variants > 1 && this.aiImprovements.length === 0);
    },
    ctaList() {
      let list = this.$store.getters['app/facebookCallToActions'];
      if (this.entity.isAppPromo) {
        list = list.filter(i => i.machine_name !== 'NO_BUTTON');
      }

      return list;
    },
    ctaIsDisabled() {
      return this.entity.settings.ai_cta_button
        && (this.entity.settings.ad_variants === 1 || !this.entity.settings.ai_ad_with_original_content);
    },
    ctaDisabledText() {
      if (!this.ctaIsDisabled) {
        return null;
      }

      return this.tr('You have entrusted the CTA setup to Infinite∞Ai, so you don\'t have the option to modify it.');
    },
  },
  watch: {
    'entity.settings.ad_creation_method'(val) {
      if (val === 'creative') {
        if (!this.entity.settings.cta_button) {
          this.entity.settings.cta_button = this.ctaList[0].machine_name;
        }

        Object.keys(this.entity.settings.ap_creative_features).map(key => this.entity.settings.ap_creative_features[key] = true);
      }
      else {
        this.entity.settings.custom_cta_settings = false;
        this.entity.settings.cta_button = null;
        this.entity.settings.cta_url = null;
        this.entity.settings.smart_url = true;

        Object.keys(this.entity.settings.ap_creative_features).map(key => this.entity.settings.ap_creative_features[key] = false);
      }
    },
    aiImprovements(newVal, oldVal) {
      let difference = newVal
        .filter(x => !oldVal.includes(x))
        .concat(oldVal.filter(x => !newVal.includes(x)));

      difference.forEach(field => this.entity.settings[field] = !this.entity.settings[field]);
    },
    ctaIsDisabled(val) {
      if (val) {
        this.entity.settings.custom_cta_settings = false;
      }
    },
  },
  created() {
    this.aiImprovementFields.forEach(item => {
      if (this.entity.settings[item.machine_name]) {
        this.aiImprovements.push(item.machine_name);
      }
    });
  },
  methods: {
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      if (!this.adVariantsValueIsValid) {
        this.error(this.tr('If you want to create multiple ad variations, you need to specify at least one ad element that the Infinite∞Ai can determine.'));
        return false;
      }

      return true;
    },
    openApCreativeSettings() {
      this.$modal.show(
        MetaApCreativeFeaturesModal,
        {
          value: this.entity.settings.ap_creative_features,
          disabled: this.$parent.readonlyMode,
        },
        { class: '' },
      );
    },
  },
};
</script>
