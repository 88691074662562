var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block tracking-block",
      attrs: {
        title: _vm.tr("Tracking"),
        description: _vm.tr(
          "You can track datasets of events that include conversions your ad may have driven. By default, the system tracks the dataset associated with the conversion selected for your ad account."
        ),
        disabled: _vm.disabled,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "checkbox-list",
          class: { disabled: _vm.appTrackingDisabled },
        },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.appEventsChecked,
                  expression: "appEventsChecked",
                },
              ],
              attrs: { type: "checkbox", disabled: _vm.appTrackingDisabled },
              domProps: {
                checked: Array.isArray(_vm.appEventsChecked)
                  ? _vm._i(_vm.appEventsChecked, null) > -1
                  : _vm.appEventsChecked,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.appEventsChecked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.appEventsChecked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.appEventsChecked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.appEventsChecked = $$c
                  }
                },
              },
            }),
            _vm._v(" " + _vm._s(_vm.tr("App events")) + " "),
          ]),
          _vm.appEventsChecked
            ? _c("v-select", {
                attrs: {
                  options: _vm.advertisableApplications,
                  reduce: (obj) => obj.id,
                  placeholder: _vm.tr("Select an app"),
                  label: "name",
                  disabled: _vm.appTrackingDisabled,
                  clearable: false,
                },
                model: {
                  value:
                    _vm.entity.settings.tracking_advertisable_application_id,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.entity.settings,
                      "tracking_advertisable_application_id",
                      $$v
                    )
                  },
                  expression:
                    "entity.settings.tracking_advertisable_application_id",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "checkbox-list", class: { disabled: !_vm.hasPixels } },
        [
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.websiteEventsChecked,
                  expression: "websiteEventsChecked",
                },
              ],
              attrs: { type: "checkbox", disabled: !_vm.hasPixels },
              domProps: {
                checked: Array.isArray(_vm.websiteEventsChecked)
                  ? _vm._i(_vm.websiteEventsChecked, null) > -1
                  : _vm.websiteEventsChecked,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.websiteEventsChecked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.websiteEventsChecked = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.websiteEventsChecked = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.websiteEventsChecked = $$c
                  }
                },
              },
            }),
            _vm._v(" " + _vm._s(_vm.tr("Website events")) + " "),
          ]),
          _vm.websiteEventsChecked
            ? _c("v-select", {
                attrs: {
                  options: _vm.pixels,
                  reduce: (obj) => obj.id,
                  placeholder: _vm.tr("Select a pixel"),
                  label: "name",
                  disabled: _vm.disabled || !_vm.hasPixels,
                  clearable: false,
                },
                model: {
                  value: _vm.entity.settings.tracking_pixel_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity.settings, "tracking_pixel_id", $$v)
                  },
                  expression: "entity.settings.tracking_pixel_id",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }