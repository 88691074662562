export default class ChartTooltip
{
  #valueLabelSingular = null;
  #valueLabelPlural = null;
  bisect = null;
  element = null;
  color = 'black';
  position = 'top';

  #svg = null;
  #chartWidth = null;
  #chartHeight = null;

  #lastXValue = null;
  #lastYValue = null;

  constructor(chart, valueLabelSingular = null, valueLabelPlural = null)
  {
    this.#svg = chart.svg;
    this.#chartWidth = chart.width;
    this.#chartHeight = chart.height;
    this.#valueLabelSingular = valueLabelSingular;
    this.#valueLabelPlural = valueLabelPlural;
  }

  draw(x, y, xValue, yValue) {
    if (xValue === this.#lastXValue && yValue === this.#lastYValue) {
      return;
    }

    this.#lastXValue = xValue;
    this.#lastYValue = yValue;

    if (this.element === null) {
      this.element = this.#svg.append('g')
    }
    else {
      this.element.selectAll('*').remove();
    }

    const stroke = 5;
    const rect = this.element.append('rect')
      .attr('fill', this.color)
      .attr('stroke', this.color)
      .attr('stroke-width', 5)
      .attr('stroke-linejoin','round')
      .attr('width', 100)
      .attr('height', 42)
      .attr('rx', 5);

    const text = this.element.append('text')
      .attr('fill', 'white')
      .style('cursor', 'default')
      .call(text => {
        text
          .selectAll('tspan')
          .data(() => [ xValue, this._getLabeledValue(yValue) ])
          .join('tspan')
            .attr('x', '6px')
            .attr('y', (_, i) => `${1.3 + i * 1.25}em`)
            .attr('font-weight', (_, i) => i ? null : 'bold')
            .style('font-size', '8px')
          .text(d => d);
      });

    this._autoSize(text, rect);

    const { width: w, height: h } = rect.node().getBBox();

    const distance = 10;
    let xPos = x - w / 2;
    let yPos = y - h;
    switch (this.position) {
      case 'top':
        yPos = y - h - distance;
        break;

      case 'bottom':
        yPos = y + distance;
        break;
    }

    let rightSide = x + (w / 2 + stroke);
    let letfSide = x -(w / 2 + stroke);
    let topSide = y - (h + stroke * 2);
    let bottomSide = y + (h + stroke * 2);

    if (rightSide > this.#chartWidth) {
      xPos -= rightSide - this.#chartWidth;
    }
    else if (letfSide < 0) {
      xPos = stroke;
    }

    if (bottomSide > this.#chartHeight) {
      yPos -= bottomSide - this.#chartHeight + 3;
    }
    else if (topSide < 0) {
      yPos = 3;
    }

    this.element
      .attr('transform', 'translate(' + xPos + ', ' + yPos + ')')
      .style('display', null);
  }

  hide() {
    if (this.element !== null) {
      this.element.style('display', 'none');

      this.#lastXValue = null;
      this.#lastYValue = null;
    }
  }

  _getLabeledValue(value) {
    let parsedValue = parseFloat(value);
    if (parsedValue != Math.round(parsedValue)) {
      parsedValue = parsedValue.toFixed(2);
    }

    if (this.#valueLabelSingular === null) {
      return parsedValue;
    }

    if (this.#valueLabelPlural === null) {
      return parsedValue + ' ' + this.#valueLabelSingular;
    }

    return parsedValue + ' ' + (parsedValue == 1 ? this.#valueLabelSingular : this.#valueLabelPlural);
  }

  _autoSize(by, what) {
    const {x, y, width: w, height: h} = by.node().getBBox();
    what.attr('width', w + 2 * x);
    what.attr('height', h + 2 * y);
  }
}
