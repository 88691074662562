var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "facebook-ad-preview-component" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "profile" }, [
        _vm._m(0),
        _c("div", { staticClass: "name" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
      ]),
      _vm.text
        ? _c("div", {
            staticClass: "main-text",
            domProps: {
              innerHTML: _vm._s(_vm.text.replace(/(?:\r\n|\r|\n)/g, "<br>")),
            },
          })
        : _vm._e(),
    ]),
    _vm.imgUrl
      ? _c("div", { staticClass: "image" }, [
          _c("img", { attrs: { src: _vm.imgUrl, alt: "Ad Image" } }),
        ])
      : _vm._e(),
    _vm.ctaTitle || _vm.ctaDescription
      ? _c("div", { staticClass: "cta-section" }, [
          _c("div", { staticClass: "texts" }, [
            _vm.ctaTitle
              ? _c("div", { staticClass: "cta-title" }, [
                  _vm._v(_vm._s(_vm.ctaTitle)),
                ])
              : _vm._e(),
            _vm.ctaDescription
              ? _c("div", { staticClass: "cta-description" }, [
                  _vm._v(_vm._s(_vm.ctaDescription)),
                ])
              : _vm._e(),
          ]),
          _vm.ctaButton
            ? _c("div", { staticClass: "cta-button" }, [
                _vm._v(_vm._s(_vm.ctaButton)),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "image" }, [
      _c("i", { staticClass: "icon infinite white" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }