var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "templateable-input" },
    [
      _c("input", {
        ref: "input",
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event.target.value)
          },
        },
      }),
      _c(
        "dropdown-menu",
        {
          model: {
            value: _vm.dropdownIsOpen,
            callback: function ($$v) {
              _vm.dropdownIsOpen = $$v
            },
            expression: "dropdownIsOpen",
          },
        },
        [
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: _vm.disabled },
            },
            [_vm._v("+ " + _vm._s(_vm.tr("Macros")))]
          ),
          _c(
            "div",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.templateParts, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  on: {
                    click: function ($event) {
                      return _vm.appendToInput(item)
                    },
                  },
                },
                [
                  item.title
                    ? _c("span", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.tr(item.title))),
                      ])
                    : _c("span", [_vm._v(_vm._s(_vm.tr(item.label)))]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }