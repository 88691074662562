<template>
  <form-block
    v-model="isEnabled"
    :title="tr('Campaign spending limit')"
    :description="tr('Set an overall spending limit for your ad campaign. This means your ad sets in the campaign will stop once you\'ve reached your spending limit.')"
    :disabled="!entity.isNew"
    class="campaign-spending"
    switchable
  >
    <money-input
      v-model="entity.output_campaign.spend_cap"
      :disabled="!entity.isNew"
      :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : ''"
    />
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

import MoneyInput from '@/components/MoneyInput.vue';

export default {
  name: 'CampaignSpendingLimitBlock',
  mixins: [ RuleFormBlockMixin ],
  components: {
    MoneyInput,
  },
  data: () => ({
    isEnabled: null,
  }),
  watch: {
    isEnabled(val, oldVal) {
      if (oldVal === null) {
        return;
      }

      this.entity.output_campaign.spend_cap = null;
    },
  },
  created() {
    this.isEnabled = !!this.entity.output_campaign.spend_cap;
  },
  methods: {
    validate() {
      if (this.isEnabled && this.entity.output_campaign.spend_cap <= 0) {
        this.error(this.tr('Campaign spending limit must be higher than zero!'));
        return false;
      }

      return true;
    },
  },
};
</script>
