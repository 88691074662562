var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "latest-activities" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.tr("Your latest Activities"))),
    ]),
    _vm.loading
      ? _c(
          "div",
          { staticClass: "activity-wrapper" },
          _vm._l([0, 1, 2], function (i) {
            return _c("div", { key: i, staticClass: "activities" }, [
              _c(
                "div",
                { staticClass: "date-time" },
                [
                  _c("skeleton", {
                    attrs: { width: "32px", height: "30px", light: "" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "metadata" },
                [
                  _c("skeleton", {
                    attrs: { width: "200px", height: "15px", light: "" },
                  }),
                  _c("skeleton", { attrs: { height: "15px", light: "" } }),
                  _c("skeleton", {
                    attrs: { width: "160px", height: "15px", light: "" },
                  }),
                ],
                1
              ),
            ])
          }),
          0
        )
      : _vm.activities.length != 0
      ? _c(
          "div",
          { staticClass: "activity-wrapper" },
          _vm._l(_vm.activities, function (activity) {
            return _c("div", { key: activity.id, staticClass: "activities" }, [
              _c("div", { staticClass: "date-time" }, [
                _c("div", { staticClass: "icon-wrapper" }, [
                  _c("i", {
                    class: {
                      icon: true,
                      primary: true,
                      play: activity.action === "start",
                      pause:
                        activity.action === "stop" ||
                        activity.action === "smart_stop" ||
                        activity.action === "delete",
                      "scaling-up": activity.action === "scaling_up",
                    },
                  }),
                ]),
                _c("div", { staticClass: "date" }, [
                  _vm._v(_vm._s(_vm.formatToMD(activity.created_at))),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.formatTime(activity.created_at))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "metadata" },
                [
                  _c("div", { staticClass: "action" }, [
                    _vm._v(
                      " " + _vm._s(_vm.activityLabel(activity.action)) + " "
                    ),
                  ]),
                  activity.ad !== null
                    ? [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(activity.ad.name)),
                        ]),
                        _c("div", { staticClass: "ad-platform" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr("Ad platform")) +
                              ": " +
                              _vm._s(activity.ad.platform) +
                              " "
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  activity.text
                    ? _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(activity.text)),
                      ])
                    : _vm._e(),
                  activity.post !== null
                    ? [
                        _c("div", { staticClass: "source" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr("Post source")) +
                              ": " +
                              _vm._s(activity.post.source_profile.platform) +
                              " "
                          ),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "activity-button",
                            attrs: {
                              href: `https://www.facebook.com/${activity.post.external_id}`,
                              target: "_blank",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.tr("Go to Post")) + " ")]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "activity-button",
                      attrs: { to: `/automation/${activity.rule.id}` },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr("Go to the Automation")) + " ")]
                  ),
                ],
                2
              ),
            ])
          }),
          0
        )
      : _c("div", { staticClass: "no-activity" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.tr("No automation has performed any activities yet.")
              ) +
              " "
          ),
        ]),
    _c(
      "div",
      { staticClass: "buttons" },
      [
        _c("router-link", { attrs: { to: "/automation" } }, [
          _c("i", { staticClass: "icon blister" }),
          _vm._v(" " + _vm._s(_vm.tr("Go to all Automations")) + " "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }