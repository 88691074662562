<template>
  <form-block
    class="rule-form-block campaign-name-block-component"
    :title="tr('Campaign name')"
    :description="tr('The campaign name is automatically generated from the name of the automation. However, you have the option to set a custom name prefix for the campaign, allowing for easier identification and management of your campaigns.')"
  >
    <div>
      <label class="input-label">{{ tr('Name prefix') }}</label>
      <input
        v-model="entity.settings.campaign_name_prefix"
        type="text"
        placeholder="[Infinite.ad]"
        :disabled="disabled"
      />

      <div class="preview">
        <span>
          <label>{{ tr('Campaign name preview') }}:</label> {{ entity.campaignName }}
        </span>
      </div>
    </div>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'CampaignNameBlock',
  mixins: [ RuleFormBlockMixin ],
};
</script>
