/* eslint-disable filenames/match-regex */

import CompanyService from '@/services/Company';

const now = new Date();
const nowMinus30Day = new Date();
nowMinus30Day.setDate(now.getDate() - 30);

const state = {
  isLoading: false,
  dashboardData: {},
  sourceProfileId: null,
  dateRange: {
    start: nowMinus30Day,
    end: now,
  },
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },

  dashboardData(state) {
    return state.dashboardData;
  },

  skipFacebookProfile(state, getters) {
    return !getters.isEmpty && state.dashboardData.profile_connected;
  },

  isEmpty(state) {
    return state.dashboardData === null || Object.keys(state.dashboardData).length === 0;
  },

  sourceProfileId(state) {
    return state.sourceProfileId;
  },

  dateRange(state) {
    return state.dateRange;
  },

  dateRangeStart(state) {
    return state.dateRange.start;
  },

  dateRangeEnd(state) {
    return state.dateRange.end;
  },

  onboardingNeccessary(state, getters) {
    if (state.isLoading && getters.isEmpty) {
      return false;
    }

    return !state.dashboardData.profile_connected;
  },

  allTypesOfAssetsConnected(state, getters) {
    if (getters.onboardingNeccessary) {
      return false;
    }

    return state.dashboardData.ad_account_connected
      && state.dashboardData.source_profile_connected;
  },
};

const actions = {
  reset() {
    state.isLoading = false;
    state.dashboardData = {};
    state.sourceProfileId = null;
    state.dateRange.start = nowMinus30Day;
    state.dateRange.end = now;
  },

  fetch(store, companyId) {
    if (store.getters.isLoading) {
      return Promise.resolve();
    }

    store.commit('isLoading', true);
    let promise = null;
    switch (process.env.VUE_APP_MODE) {
      case 'ad':
        promise = CompanyService.getDashboardAd(companyId);
        break;

      case 'buzz':
        promise = CompanyService.getDashboardBuzz(companyId, {
          sourceProfileId: store.getters.sourceProfileId,
          dateFrom: store.getters.dateRangeStart,
          dateTo: store.getters.dateRangeEnd,
        });
        break;

      default:
        return Promise.resolve();
    }

    return promise
      .then(resp => store.commit('setDashboardData', resp))
      .finally(() => store.commit('isLoading', false));
  },
};

const mutations = {
  isLoading(state, value) {
    state.isLoading = value;
  },

  setDashboardData(state, value) {
    state.dashboardData = value;
  },

  setSourceProfileId(state, value) {
    state.sourceProfileId = value;
  },

  setDateRange(state, value) {
    state.dateRange.start = value.start;
    state.dateRange.end = value.end;
  },

  setDateRangeStart(state, value) {
    state.dateRange.start = value;
  },

  setDateRangeEnd(state, value) {
    state.dateRange.end = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
