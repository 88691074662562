var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-code-input" },
    [
      _vm._l(_vm.code, function (n, index) {
        return _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.code[index],
              expression: "code[index]",
            },
          ],
          key: index,
          ref: "input",
          refInFor: true,
          attrs: {
            id: "input_" + index,
            type: "number",
            pattern: "\\d*",
            maxlength: "1",
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.code[index] },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.code, index, $event.target.value)
              },
              _vm.handleInput,
            ],
            keypress: _vm.isNumber,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleEnter.apply(null, arguments)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              )
                return null
              return _vm.handleDelete.apply(null, arguments)
            },
            paste: _vm.onPaste,
          },
        })
      }),
      _c("div", { staticClass: "separator" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }