import ConnectedProfile from '@/entities/ConnectedProfile';
import api from '@/services/Api';

export default {
  connectProfile(platform, accessToken) {
    const data = {
      platform,
      access_token: accessToken,
    };

    return api()
      .post('/connected-profile/', data)
      .then(resp => new ConnectedProfile(resp));
  },

  removeProfile(id) {
    return api().delete('/connected-profile/' + id);
  },

  revokeProfilePermissions(id) {
    return api().delete('/connected-profile/' + id + '/permissions');
  },
};
