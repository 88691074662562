var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "wrapper", class: _vm.wrapperClasses }, [
    _c(
      "div",
      { ref: "checkboxes", staticClass: "checkboxes" },
      _vm._l(_vm.actionOptions, function (action, index) {
        return _c("checkbox-input", {
          key: "rule-action-filter--" + index,
          attrs: {
            value: _vm.actionFilter.includes(action.machine_name),
            icon: action.icon,
            label: action.label,
            color: action.color,
            disabled: _vm.disabled,
          },
          on: {
            input: function ($event) {
              return _vm.toggleAction(action.machine_name, $event)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }