var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block dsa-block-component",
      attrs: { title: _vm.tr("Beneficiary and payer") },
    },
    [
      _c("info-text", [
        _vm._v(
          " " +
            _vm._s(
              _vm.tr(
                "Starting July 10, 2023, you'll need to indicate the beneficiary and payer for any new or edited ad set with an audience in the European Union."
              )
            ) +
            " "
        ),
      ]),
      _c("div", [
        _c("label", { staticClass: "input-label" }, [
          _vm._v(" " + _vm._s(_vm.tr("Beneficiary")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "autosuggest-wrapper" },
          [
            _c("vue-autosuggest", {
              attrs: {
                "input-props": { disabled: _vm.disabled },
                suggestions: [{ data: _vm.beneficiarySuggestions }],
              },
              on: {
                input: _vm.updateBeneficiarySuggestions,
                selected: _vm.onSelectSuggestedBeneficiary,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ suggestion }) {
                    return [_vm._v(_vm._s(suggestion.item))]
                  },
                },
              ]),
              model: {
                value: _vm.entity.dsa_beneficiary,
                callback: function ($$v) {
                  _vm.$set(_vm.entity, "dsa_beneficiary", $$v)
                },
                expression: "entity.dsa_beneficiary",
              },
            }),
            _vm.beneficiarySuggestionsIsLoading
              ? _c("i", {
                  staticClass: "icon loader primary autosuggest-loader",
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.differentPayer,
              expression: "differentPayer",
            },
          ],
          attrs: { type: "checkbox", disabled: _vm.disabled },
          domProps: {
            checked: Array.isArray(_vm.differentPayer)
              ? _vm._i(_vm.differentPayer, null) > -1
              : _vm.differentPayer,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.differentPayer,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.differentPayer = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.differentPayer = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.differentPayer = $$c
              }
            },
          },
        }),
        _vm._v(
          " " +
            _vm._s(_vm.tr("A different person or organization is paying")) +
            " "
        ),
      ]),
      _c("transition-expand", [
        _vm.differentPayer
          ? _c("div", [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(" " + _vm._s(_vm.tr("Payer")) + " "),
              ]),
              _c(
                "div",
                { staticClass: "autosuggest-wrapper" },
                [
                  _c("vue-autosuggest", {
                    attrs: {
                      "input-props": { disabled: _vm.disabled },
                      suggestions: [{ data: _vm.payerSuggestions }],
                    },
                    on: {
                      input: _vm.updatePayerSuggestions,
                      selected: _vm.onSelectSuggestedPayer,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ suggestion }) {
                            return [_vm._v(_vm._s(suggestion.item))]
                          },
                        },
                      ],
                      null,
                      false,
                      2549262794
                    ),
                    model: {
                      value: _vm.entity.dsa_payer,
                      callback: function ($$v) {
                        _vm.$set(_vm.entity, "dsa_payer", $$v)
                      },
                      expression: "entity.dsa_payer",
                    },
                  }),
                  _vm.payerSuggestionsIsLoading
                    ? _c("i", {
                        staticClass: "icon loader primary autosuggest-loader",
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }