var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "money-input-component",
      attrs: { "symbol-length": _vm.symbol.length },
    },
    [
      _c("number-input", {
        attrs: { disabled: _vm.disabled, min: _vm.min, step: _vm.step },
        model: {
          value: _vm.insideValue,
          callback: function ($$v) {
            _vm.insideValue = $$v
          },
          expression: "insideValue",
        },
      }),
      _c("div", { staticClass: "prefix" }, [_vm._v(_vm._s(_vm.symbol))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }