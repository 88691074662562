<template>
  <div class="open-in-browser-component">
    <i class="logo-inline" />

    <span class="text">
      <template v-if="$root.language === 'hu'">
        Az {{ appName }} használatához kérjük,<br />

        nyisd be az
        <span class="address" @click="() => copyLinkToClipboard()">{{ domain }}</span>
        címet<br />

        <strong>közvetlenül egy böngészőből</strong>.
      </template>
      <template v-else>
        To use {{ appName }},<br />

        please open the
        <span class="address" @click="() => copyLinkToClipboard()">{{ domain }}</span>
        address<br />

        <strong>directly in a browser</strong>.
      </template>
    </span>

    <button v-if="!isIos" class="primary" @click="() => $emit('open')">
      {{ tr('Open in browser') }}
    </button>
    <button v-else class="primary" @click="() => copyLinkToClipboard()">
      {{ tr('Copy link to clipboard') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'OpenInBrowser',
  props: {
    isIos: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    appName() {
      return process.env.VUE_APP_HTML_TITLE;
    },
    domain() {
      return window.location.hostname;
    },
  },
  methods: {
    copyLinkToClipboard() {
      navigator.clipboard.writeText(window.location.href);
      this.infoToast(this.tr('Link successfully copied to clipboard.'));
    },
  },
};
</script>
