var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      _vm.isSkeleton
        ? [
            _c(
              "div",
              { staticClass: "image-wrapper" },
              [
                _c("skeleton", {
                  staticClass: "image",
                  attrs: { width: "35px", height: "35px", radius: "100%" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "texts" },
              [_c("skeleton", { attrs: { width: "100px" } })],
              1
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "image-wrapper" },
              [
                _c("lazy-image", {
                  staticClass: "image",
                  attrs: { src: _vm.sourceProfile.picture },
                }),
                _c("div", { staticClass: "platform-icon" }, [
                  _c("i", {
                    class:
                      "icon " + _vm.sourceProfile.platform + "-color uncolored",
                  }),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "texts" }, [
              _vm.date
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.dateString,
                          expression: "dateString",
                        },
                      ],
                      staticClass: "date",
                    },
                    [_vm._v(" " + _vm._s(_vm.dateAgoString) + " ")]
                  )
                : _vm._e(),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.sourceProfile.name)),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }