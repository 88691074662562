var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "card",
    { class: _vm.wrapperClasses },
    [
      _vm.image || _vm.showImageWrapper
        ? _c("top-image-content", [
            _vm.image ? _c("img", { attrs: { src: _vm.image } }) : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "textual" },
        [
          _vm.title ? _c("h2", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }