var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block post-type-block-component",
      attrs: { title: _vm.tr("Post type"), description: _vm.description },
    },
    [
      _vm.showFacebookBlock
        ? [
            _c("post-type-selector", {
              ref: "facebookSelector",
              attrs: {
                platform: "facebook",
                entity: _vm.entity,
                disabled: _vm.disabled,
              },
            }),
            _vm.entity.facebook_post_media_types.includes("event")
              ? _c("info-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "Events can't be advertised directly, but if you share the event on your Facebook Page, you'll be able to boost that post."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.showInstagramBlock
        ? [
            _c("post-type-selector", {
              ref: "instagramSelector",
              attrs: {
                platform: "instagram",
                entity: _vm.entity,
                disabled: _vm.disabled,
              },
            }),
          ]
        : _vm._e(),
      !_vm.showFacebookBlock && !_vm.showInstagramBlock
        ? _c("div", { staticClass: "blocker-text" }, [
            _vm._v(
              " " + _vm._s(_vm.tr("Please, select a source first.")) + " "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }