var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "post-copy-analysis-list-view list-view" },
    [
      _c("h3", [_vm._v(_vm._s(_vm.tr("Analyzed copies")))]),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          _vm._s(
            _vm.tr(
              "Here you can see a list of your published contents and their performance. To set the specific data you want to see about your contents, press the 'Edit view' button and select them from the list."
            )
          )
        ),
      ]),
      !_vm.isListEmpty || !_vm.isEmptyFilters || _vm.isLoading
        ? _c(
            "div",
            { staticClass: "actions" },
            [
              _c("v-select", {
                staticClass: "source-select",
                attrs: {
                  options: _vm.sourceProfileList,
                  reduce: (option) => option.id,
                  placeholder: _vm.tr("Datasource"),
                  label: "name",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          _c("span", [
                            option.platform
                              ? _c("i", { class: "icon " + option.platform })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(option.name) + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "selected-option",
                      fn: function (option) {
                        return [
                          _c("span", [
                            option.platform
                              ? _c("i", { class: "icon " + option.platform })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(option.name) + " "),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2769159693
                ),
                model: {
                  value: _vm.sourceProfileId,
                  callback: function ($$v) {
                    _vm.sourceProfileId = $$v
                  },
                  expression: "sourceProfileId",
                },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                staticClass: "text",
                attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading && _vm.isListEmpty
        ? [
            !_vm.isEmptyFilters
              ? _c(
                  "notification-card",
                  {
                    staticClass: "no-result",
                    attrs: {
                      image: require("@/assets/img/sad_new.png"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "There are no results matching your search criteria."
                          )
                        ) +
                        " "
                    ),
                  ]
                )
              : _c(
                  "notification-card",
                  {
                    attrs: {
                      image: require("@/assets/img/new-rule-card/ai.png"),
                      "empty-screen": "",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "Analyze your Facbook and Instagram posts with AI."
                          )
                        ) +
                        " "
                    ),
                    _c(
                      "access-handled-router-link",
                      {
                        staticClass: "button primary",
                        attrs: {
                          to: "/connected-platforms/meta/facebook-pages",
                        },
                      },
                      [_vm._v(" + " + _vm._s(_vm.tr("Connect platform")) + " ")]
                    ),
                  ],
                  1
                ),
          ]
        : _vm._e(),
      _c("new-sorted-table", {
        style: {
          display: !_vm.isLoading && _vm.isListEmpty ? "none" : "block",
        },
        attrs: {
          data: _vm.list,
          "initial-sort": { key: _vm.orderBy, direction: _vm.order },
        },
        on: { sort: _vm.sortTable },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "table-head",
                  { attrs: { col: "source", minWidth: "150px" } },
                  [_vm._v(_vm._s(_vm.tr("Source")))]
                ),
                _vm._l(_vm.visibleFields, function (field, key) {
                  return _c(
                    "table-head",
                    {
                      key: "field-th-" + key,
                      attrs: {
                        col: field,
                        sortable: _vm.availableFields.find(
                          (i) => i.machine_name === field
                        ).sortable,
                        minWidth: field === "text" ? "400px" : "100px",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              _vm.availableFields.find(
                                (i) => i.machine_name === field
                              ).label
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                _c("table-head", {
                  attrs: { col: "actions", minWidth: "150px" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ sort }) {
              return [
                _vm._l(sort, function (row) {
                  return _c(
                    "table-row",
                    { key: row.id },
                    [
                      _c(
                        "table-data",
                        { staticClass: "source", attrs: { col: "source" } },
                        [
                          _c("div", { staticClass: "platform" }, [
                            _vm._v(_vm._s(row.source_profile.platform)),
                          ]),
                          _vm._v(" " + _vm._s(row.source_profile.name) + " "),
                        ]
                      ),
                      _vm._l(_vm.visibleFields, function (field, key) {
                        return _c(
                          "table-data",
                          {
                            key: "field-td-" + key + "-" + row.id,
                            class: field,
                            attrs: { col: field },
                          },
                          [
                            field === "text"
                              ? [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/post-copy-analysis/posts/" + row.id,
                                      },
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.tr("Post ID")) +
                                            ": " +
                                            _vm._s(row.external_id) +
                                            " "
                                        ),
                                      ]),
                                      _c("br"),
                                      _c("div", { staticClass: "post-text" }, [
                                        _vm._v(
                                          " " + _vm._s(row.shortText) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : field === "ai_value"
                              ? [
                                  row.ai_value
                                    ? [
                                        _vm._v(
                                          " " + _vm._s(row.ai_value) + " / 10 "
                                        ),
                                      ]
                                    : row.ai_meta_in_progress
                                    ? [_c("i", { staticClass: "icon loader" })]
                                    : [_vm._v(" - ")],
                                ]
                              : field === "created_at"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            _vm.formatToYMD(row.created_at) +
                                            ", " +
                                            _vm.formatTime(row.created_at),
                                          expression:
                                            "formatToYMD(row.created_at) + ', ' + formatTime(row.created_at)",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.timeAgo(row.created_at, _vm.tr)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : field === "ai_content_actuality"
                              ? [
                                  row.contentActualityCheckInProgress
                                    ? _c("skeleton")
                                    : row.ai_content_actuality
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatToYMD(
                                                row.ai_content_actuality
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatTime(
                                                row.ai_content_actuality
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    : [_vm._v(" - ")],
                                ]
                              : [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          row.metric[
                                            field.replace("metric.", "")
                                          ],
                                          field === "ctr" ? "%" : ""
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      _c("table-data", { attrs: { col: "actions" } }, [
                        _c(
                          "div",
                          { staticClass: "action-group" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "button gray small",
                                attrs: {
                                  to: "/post-copy-analysis/posts/" + row.id,
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.tr("Details")) + " ")]
                            ),
                            _vm.$root.supportHasL2Access
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "gray small",
                                    on: {
                                      click: () =>
                                        _vm.openCommunicatorDebug(row),
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.tr("Debug")) + " ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                }),
                _vm.isLoading || _vm.hasMore || true
                  ? _vm._l(_vm.isListEmpty ? [1, 2, 3] : [1, 2], function (i) {
                      return _c(
                        "table-row",
                        {
                          key: "post-copy-analysis-loader-skeleton--" + i,
                          ref: "loadMore",
                          refInFor: true,
                        },
                        [
                          _c(
                            "table-data",
                            { staticClass: "source", attrs: { col: "source" } },
                            [
                              _c("skeleton", {
                                attrs: { width: "45px", height: "10px" },
                              }),
                              _c("skeleton", { attrs: { width: "100px" } }),
                            ],
                            1
                          ),
                          _vm._l(_vm.visibleFields, function (field, key) {
                            return _c(
                              "table-data",
                              {
                                key: "field-td-" + key + "-loadmore-" + i,
                                class: field,
                                attrs: { col: field },
                              },
                              [
                                field === "text"
                                  ? [
                                      _c("skeleton", {
                                        attrs: {
                                          "max-width": "120px",
                                          height: "10px",
                                        },
                                      }),
                                      _c("skeleton", {
                                        attrs: { "max-width": "400px" },
                                      }),
                                      _c("skeleton", {
                                        attrs: { width: "80%" },
                                      }),
                                    ]
                                  : field === "ai_value"
                                  ? _c("skeleton", { attrs: { width: "40px" } })
                                  : field === "created_at"
                                  ? _c("skeleton", { attrs: { width: "80px" } })
                                  : field === "ai_content_actuality"
                                  ? _c("skeleton", { attrs: { width: "80px" } })
                                  : _c("skeleton", {
                                      attrs: { width: "40px" },
                                    }),
                              ],
                              2
                            )
                          }),
                          _c("table-data", { attrs: { col: "actions" } }, [
                            _c(
                              "div",
                              { staticClass: "action-group" },
                              [
                                _c("skeleton", {
                                  attrs: {
                                    width: "80px",
                                    height: "26px",
                                    radius: "10px",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      )
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "modal",
        {
          staticClass: "visible-field-editor-modal",
          attrs: { name: "visible-field-editor" },
        },
        [
          _c("div", { staticClass: "modal-title" }, [
            _vm._v(" " + _vm._s(_vm.tr("Visible fields")) + " "),
          ]),
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              { staticClass: "checkbox-list vertical" },
              _vm._l(_vm.availableFields, function (item, key) {
                return _c("label", { key: "field-" + key }, [
                  _c("input", {
                    attrs: {
                      type: "checkbox",
                      name: "visible-fields",
                      disabled: key === 0,
                    },
                    domProps: {
                      value: item.machine_name,
                      checked: _vm.tmpVisibleFields.includes(item.machine_name),
                    },
                    on: {
                      click: () => _vm.toggleVisibleField(item.machine_name),
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.tr(item.label)) + " "),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "modal-action-group" }, [
            _c(
              "button",
              {
                staticClass: "primary",
                on: { click: () => _vm.applyChangedFields() },
              },
              [_vm._v(" " + _vm._s(_vm.tr("Apply")) + " ")]
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }