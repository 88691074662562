<template>
  <div v-if="isVisible" :class="wrapperClasses" @click="() => onClickBackground()">
    <div class="fullscreen-modal--wrapper">
      <div
        v-if="closable"
        class="fullscreen-modal--close-button"
        @click="() => close()"
      >
        <i class="icon close primary" />
      </div>
      <div class="fullscreen-modal--container" @click.stop>
        <div class="fullscreen-modal--content">
          <component
            :is="component"
            v-bind="properties"
            @close="() => close()"
            @close-all="() => closeAll()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullscreenModal',
  props: {
    component: {
      type: Object,
      required: true,
    },
    properties: {
      type: Object,
      required: false,
      default: () => new Object(),
    },
    classes: {
      type: [ String, Array ],
      required: false,
      default: () => [],
    },
    center: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    closable: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    closeWithOutclick: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data: () => ({
    isVisible: false,
    isOpening: false,
    isClosing: false,
  }),
  computed: {
    wrapperClasses() {
      const classes = {
        'fullscreen-modal': true,
        'is-opening': this.isOpening,
        'is-closing': this.isClosing,
        'position-center': this.center,
      };
      if (typeof this.classes === 'string' && this.classes.length !== 0) {
        classes[this.classes] = true;
      }
      else if (Array.isArray(this.classes) && this.classes.length !== 0) {
        this.classes.forEach(className => classes[className] = true);
      }

      return classes;
    },
  },
  methods: {
    open() {
      this.isOpening = true;
      this.isVisible = true;
      setTimeout(() => this.isOpening = false, 10);

      document.body.classList.add('disable-scroll');
    },
    close() {
      this.isClosing = true;

      setTimeout(() => {
        this.isVisible = false;
        this.isClosing = false;
        this.$emit('closed');
      }, 300);

      this.$fullscreenModal.closed(this);
      if (this.$fullscreenModal.instances.length === 0) {
        document.body.classList.remove('disable-scroll');
      }
    },
    closeAll() {
      this.$fullscreenModal.closeAll();
    },
    onClickBackground() {
      if (this.closable && this.closeWithOutclick) {
        this.close();
      }
    },
  },
};
</script>
