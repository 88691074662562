var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "connected-platforms-view" },
    [
      _c(
        "sticky-header",
        { attrs: { title: _vm.tr("Connected platforms") } },
        [_c("form-step-tab", { attrs: { items: _vm.formStepTabs } })],
        1
      ),
      _c(
        "div",
        { staticClass: "platforms" },
        [
          _c(
            "router-link",
            {
              staticClass: "button primary",
              attrs: { to: "/connected-platforms/meta" },
            },
            [
              _c("i", { staticClass: "icon meta white" }),
              _vm._v("Meta Business "),
            ]
          ),
          _vm.$app.isAd
            ? _c(
                "div",
                { staticClass: "button disabled", attrs: { disabled: "" } },
                [
                  _c("i", { staticClass: "icon tiktok white" }),
                  _vm._v("TikTok Ads "),
                  _c("div", { staticClass: "coming-soon" }, [
                    _vm._v(_vm._s(_vm.tr("Coming Soon"))),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }