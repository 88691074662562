var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-payment-history-view" },
    [
      _c("sorted-table", {
        staticClass: "payment-history-table row-hover-shadow",
        attrs: { data: _vm.list },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "table-head",
                  { attrs: { col: "date", minWidth: "100px" } },
                  [_vm._v(_vm._s(_vm.tr("Date")))]
                ),
                _c("table-head", { attrs: { col: "net", minWidth: "80px" } }, [
                  _vm._v(_vm._s(_vm.tr("Net"))),
                ]),
                _c(
                  "table-head",
                  { attrs: { col: "gross", minWidth: "80px" } },
                  [_vm._v(_vm._s(_vm.tr("Gross")))]
                ),
                _c(
                  "table-head",
                  { attrs: { col: "status", minWidth: "120px" } },
                  [_vm._v(_vm._s(_vm.tr("Status")))]
                ),
                _c(
                  "table-head",
                  { attrs: { col: "invoice_number", minWidth: "120px" } },
                  [_vm._v(_vm._s(_vm.tr("Invoice number")))]
                ),
                _c("table-head", { attrs: { col: "actions", width: "24px" } }),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ sort }) {
              return _vm._l(sort, function (paymentHistory) {
                return _c(
                  "table-row",
                  { key: paymentHistory.id },
                  [
                    _c("table-data", { attrs: { col: "date" } }, [
                      _vm._v(
                        _vm._s(_vm.formatToYMD(paymentHistory.created_at))
                      ),
                    ]),
                    _c("table-data", { attrs: { col: "net" } }, [
                      _vm._v(_vm._s(_vm.formatPrice(paymentHistory.net, "€"))),
                    ]),
                    _c("table-data", { attrs: { col: "gross" } }, [
                      _vm._v(
                        _vm._s(_vm.formatPrice(paymentHistory.gross, "€"))
                      ),
                    ]),
                    _c("table-data", { attrs: { col: "status" } }, [
                      _c(
                        "div",
                        { class: "status-flag " + paymentHistory.status },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.tr(paymentHistory.status)) + " "
                          ),
                        ]
                      ),
                    ]),
                    _c("table-data", { attrs: { col: "invoice_number" } }, [
                      _vm._v(
                        _vm._s(
                          paymentHistory.invoice_number
                            ? paymentHistory.invoice_number
                            : "-"
                        )
                      ),
                    ]),
                    _c(
                      "table-data",
                      { attrs: { col: "actions" } },
                      [
                        _c("navigation-arrow", {
                          on: { click: () => _vm.openDetails(paymentHistory) },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
      _vm.list.length === 0 && !_vm.isLoading
        ? _c("div", { staticClass: "empty-list" }, [
            _vm._v(
              " " + _vm._s(_vm.tr("There are no items to display.")) + " "
            ),
          ])
        : _vm._e(),
      _vm.hasMore
        ? _c("div", { ref: "loadMore", staticClass: "load-more" }, [
            _c("i", { staticClass: "icon loader primary" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm._e(),
      _c(
        "modal",
        {
          staticClass: "payment-history-details-modal",
          attrs: { name: "payment-history-details-modal" },
          on: { closed: () => (_vm.openedPaymentHistory = null) },
        },
        [
          _c("i", {
            staticClass: "icon close",
            on: {
              click: () => _vm.$modal.hide("payment-history-details-modal"),
            },
          }),
          _vm.openedPaymentHistory
            ? _c(
                "form-block",
                { attrs: { title: _vm.tr("Payment details") } },
                [
                  _c("div", { staticClass: "informations" }, [
                    _c("div", [
                      _c("label", [_vm._v(_vm._s(_vm.tr("Date")) + ":")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatToYMD(_vm.openedPaymentHistory.created_at)
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v(_vm._s(_vm.tr("Status")) + ":")]),
                      _c(
                        "div",
                        {
                          class:
                            "status-flag " + _vm.openedPaymentHistory.status,
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr(_vm.openedPaymentHistory.status)) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _vm.openedPaymentHistory.fail_reason
                      ? _c("div", [
                          _c("label", [
                            _vm._v(_vm._s(_vm.tr("Fail reason")) + ":"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.openedPaymentHistory.fail_reason) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("payment-history-details", {
                    attrs: { entity: _vm.openedPaymentHistory },
                  }),
                  _c("div", { staticClass: "buttons" }, [
                    _vm.openedPaymentHistory.invoice_number
                      ? _c(
                          "button",
                          {
                            staticClass: "primary",
                            attrs: { disabled: _vm.invoiceDownloading },
                            on: {
                              click: () =>
                                _vm.downloadInvoice(_vm.openedPaymentHistory),
                            },
                          },
                          [
                            _vm.invoiceDownloading ===
                            _vm.openedPaymentHistory.id
                              ? _c("i", { staticClass: "icon loader white" })
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.tr("Download invoice")) + " "
                            ),
                          ]
                        )
                      : _vm.openedPaymentHistory.status === "failed"
                      ? _c(
                          "button",
                          {
                            staticClass: "primary",
                            on: {
                              click: () => _vm.payNow(_vm.openedPaymentHistory),
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.tr("Pay now")) + " ")]
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }