var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "active-boosts-card-component" },
    [
      _vm.loading
        ? [
            _c(
              "div",
              [
                _c("skeleton", {
                  staticClass: "title",
                  attrs: { width: "100px", height: "15px" },
                }),
                _c("skeleton", {
                  staticClass: "time",
                  attrs: { width: "80px", height: "12px" },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("skeleton", {
                  staticClass: "number",
                  attrs: { width: "60px", height: "45px" },
                }),
                _c("skeleton", {
                  staticClass: "number-text",
                  attrs: { width: "100px", height: "12px" },
                }),
              ],
              1
            ),
            _c("skeleton", { attrs: { width: "130px", height: "15px" } }),
          ]
        : [
            _c("div", { staticClass: "last-activity" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.loading || _vm.lastActivity
                        ? _vm.tr("Last activity")
                        : _vm.tr("No activity")
                    ) +
                    " "
                ),
                _c("i", { class: "icon " + _vm.icon }),
              ]),
              _vm.lastActivity
                ? _c("div", { staticClass: "time" }, [
                    _vm._v(
                      " " + _vm._s(_vm.timeAgo(_vm.lastActivity, _vm.tr)) + " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "counter" }, [
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.number)),
              ]),
              _vm._v(" " + _vm._s(_vm.numberText) + " "),
            ]),
            _c("router-link", { attrs: { to: _vm.link } }, [
              _vm._v(_vm._s(_vm.linkText) + " →"),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }