var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-view" },
    [
      _c(
        "sticky-header",
        { attrs: { title: _vm.tr("Dashboard"), bordered: "" } },
        [[_c("div", { staticClass: "links" })]],
        2
      ),
      _c("system-level-notifications", { attrs: { "space-top": "" } }),
      _vm.$app.isAd
        ? _c("dashboard-ad-view")
        : _vm.$app.isBuzz
        ? _c("dashboard-buzz-view")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }