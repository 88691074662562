<template>
  <div
    v-if="details"
    class="copy-evaluation-component"
  >
    <div
      v-for="(row, key) in details"
      :key="'ai-meta-' + key"
      class="meta"
    >
      <div class="title">{{ tr(row.title) }}: {{ row.value }} / 10</div>
      <div v-if="row.details" class="details">
        {{ detailsToShow(row) }}
      </div>
    </div>
  </div>
  <div
    v-else
    class="copy-evaluation-component empty"
  >
    <skeleton width="120px" height="15px" radius="10px" />
    <skeleton width="90%" height="15px" radius="10px" />
    <skeleton width="60%" height="15px" radius="10px" />
    <skeleton width="90%" height="15px" radius="10px" />
    <div class="divider"></div>
    <skeleton width="120px" height="15px" radius="10px" />
    <skeleton width="90%" height="15px" radius="10px" />
    <skeleton width="60%" height="15px" radius="10px" />
    <skeleton width="90%" height="15px" radius="10px" />
    <div class="divider"></div>
    <skeleton width="120px" height="15px" radius="10px" />
    <skeleton width="90%" height="15px" radius="10px" />
    <skeleton width="60%" height="15px" radius="10px" />
    <skeleton width="90%" height="15px" radius="10px" />
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue';

export default {
  name: 'CopyEvaluation',
  components: {
    Skeleton,
  },
  props: {
    details: {
      type: [ Array, null ],
      required: false,
      default: () => null,
    },
  },
  methods: {
    detailsToShow(row) {
      return row.details_translation || row.details;
    },
  },
};
</script>
