var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cancel-subscription-modal-button-component" },
    [
      _vm.isCancelled
        ? _c(
            "info-text",
            { staticClass: "cancelled-text", attrs: { type: "error" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.tr("The subscription has been cancelled.")) +
                  " "
              ),
            ]
          )
        : _vm.hasPaidPackage
        ? [
            _c(
              "button",
              {
                staticClass: "red cancel-subscription-button",
                on: {
                  click: () => _vm.$modal.show("cancel-subscription-modal"),
                },
              },
              [_vm._v(" " + _vm._s(_vm.tr("Cancel subscription")) + " ")]
            ),
            _c(
              "modal",
              {
                staticClass: "cancel-subscription-modal",
                attrs: {
                  name: "cancel-subscription-modal",
                  overlayTransition: "fade-modal-overlay",
                },
              },
              [
                _c("i", {
                  staticClass: "icon close",
                  on: {
                    click: () => _vm.$modal.hide("cancel-subscription-modal"),
                  },
                }),
                _c(
                  "form-block",
                  {
                    staticClass: "change-subscription-form",
                    attrs: {
                      title: _vm.tr("Cancel subscription"),
                      description: _vm.tr(
                        "If you cancel your subscription, you can still use the service during the billing cycle you have already paid for. If you change your mind, you can subscribe to our package that suits you best at any time after cancellation."
                      ),
                    },
                  },
                  [
                    _c("div", { staticClass: "buttons" }, [
                      _c(
                        "button",
                        {
                          attrs: { disabled: _vm.isLoading },
                          on: { click: () => _vm.closeModal() },
                        },
                        [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "red",
                          attrs: { disabled: _vm.isLoading },
                          on: { click: () => _vm.confirm() },
                        },
                        [
                          _vm.isLoading
                            ? _c("i", { staticClass: "icon loader white" })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(_vm.tr("Confirm")) + " "),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }