<template>
  <div class="distance-radius-selector-component">
    <div class="main" @click="() => toggle()">
      + {{ value }} km
    </div>
    <div v-if="isOpen" ref="sliderWrapper" class="slider-wrapper">
      <div class="min-label">{{ min }}</div>
      <input
        v-model="innerValue"
        type="range"
        :min="min"
        :max="max"
      />
      <div class="min-label">{{ max }}</div>

      <div class="number-input-wrapper">
        <number-input
          v-model="innerValue"
          :step="1"
          :min="min"
          :max="max"
        />
        <span>km</span>
      </div>
    </div>
  </div>
</template>

<script>
import NumberInput from '@/components/NumberInput.vue';

export default {
  name: 'DistanceRadiusSelector',
  components: {
    NumberInput,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    innerValue: 0,
    isOpen: false,
    isMounted: false,
  }),
  created() {
    this.innerValue = this.value;
  },
  mounted() {
    setTimeout(() => this.isMounted = true, 25);
  },
  watch: {
    innerValue(val) {
      if (!this.isMounted) {
        return;
      }

      this.$emit('input', parseInt(val));
    },
    isOpen(val) {
      setTimeout(() => {
        if (val) {
          document.addEventListener('click', this.clickOutHandler);
        }
        else {
          document.removeEventListener('click', this.clickOutHandler);
        }
      }, 100);
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    clickOutHandler(e) {
      if (!this.$refs.sliderWrapper) {
        document.removeEventListener('click', this.clickOutHandler);
        return;
      }

      if (
        e.target !== this.$refs.sliderWrapper
        && !this.$refs.sliderWrapper.contains(e.target)
      ) {
        this.isOpen = false;
      }
    },
  },
};
</script>
