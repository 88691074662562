var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.facebookAttributionWindows.length !== 0
    ? _c(
        "form-block",
        {
          staticClass: "rule-form-block attribution-window-block-component",
          attrs: { title: _vm.tr("Attribution window") },
        },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isCustom,
                    expression: "isCustom",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "attribution",
                  disabled: _vm.disabled,
                },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.isCustom, false),
                },
                on: {
                  change: function ($event) {
                    _vm.isCustom = false
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Default")) + " "),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isCustom,
                    expression: "isCustom",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "attribution",
                  disabled: _vm.disabled,
                },
                domProps: { value: true, checked: _vm._q(_vm.isCustom, true) },
                on: {
                  change: function ($event) {
                    _vm.isCustom = true
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Custom")) + " "),
            ]),
            _vm.isCustom
              ? _c("div", { staticClass: "checkbox-list vertical" }, [
                  _c("div", [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.tr(
                            "The number of days between when a person viewed or clicked your ad and subsequently took an action."
                          )
                        ) + " "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.facebook.com/business/help/2198119873776795?id=768381033531365",
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.tr("Learn more")))]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "checkbox-list vertical" },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.facebookAttributionWindows,
                          reduce: (obj) => obj.machine_name,
                          disabled: _vm.disabled,
                          label: "label",
                        },
                        model: {
                          value: _vm.entity.settings.adset_attribution_window,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_attribution_window",
                              $$v
                            )
                          },
                          expression:
                            "entity.settings.adset_attribution_window",
                        },
                      }),
                      _c("div", [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.tr(
                                "By default Ads Manager uses ad set attribution window, also known as unified attribution window. You may see discrepancies in conversion data if this setting is disabled."
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }