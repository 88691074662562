import { RULE_REVIEW_CODE } from '@/consts.js';
import Ad from '@/entities/Ad';
import BaseEntity from '@/entities/BaseEntity';
import Post from '@/entities/Post';
import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';
import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';

export default class RuleActivityReviewItem extends BaseEntity {
  code = null;
  entity_type = null;
  entity = null;
  related_entity = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);

    switch (this.entity_type) {
      case 'ad':
        this.entity = new Ad(rawData.entity);
        break;

      case 'post':
        this.entity = new Post(rawData.entity);
        break;

      default:
        throw new Error('Unknown entity type: ' + this.entity_type);
    }
  }

  updateWith(newValues) {
    if (!super.updateWith(newValues)) {
      return;
    }

    if (this.isProhibitedContent && newValues.related_entity) {
      this.related_entity = new PostAiProhibitedContent(newValues.related_entity);
    }
    else if (this.isWaitingForApproval && newValues.related_entity) {
      this.related_entity = new RuleActivityManagementQueueItem(newValues.related_entity);
    }
  }

  get post() {
    if (this.entity instanceof Post) {
      return this.entity;
    }

    return this.entity.post;
  }

  get isWaitingForApproval() {
    return this.code === RULE_REVIEW_CODE.IN_ACTIVITY_MANAGEMENT_QUEUE;
  }

  get isProhibitedContent() {
    return this.code === RULE_REVIEW_CODE.PROHIBITED_CONTENT;
  }
}
