var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "meta-ap-creative-features-modal" },
    [
      _c("meta-ap-creative-features", {
        attrs: { disabled: _vm.disabled },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c("div", { staticClass: "actions" }, [
        _c(
          "button",
          {
            staticClass: "button primary",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.tr("Apply and Close")) + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }