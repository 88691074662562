<template>
  <div class="auth-form-view login-form-view">
    <form class="form" @submit="$event => onFormSubmit($event)">
      <div class="input-label">
        <span>{{ tr('E-mail') }}</span>
      </div>
      <input
        v-model="email"
        type="email"
        :placeholder="tr('you@gmail.com')"
      />
      <div class="input-label">
        <span>{{ tr('Password') }}</span>
      </div>
      <input
        v-model="password"
        type="password"
        :placeholder="tr('Enter at least 8 characters...')"
      />

      <button type="submit" class="primary">
        <i
          v-if="isLoading"
          class="icon loader white"
          :loading="isLoading"
          color="#ffffff"
          size="6px"
        />
        <template v-else> {{ tr('Login') }} </template>
      </button>
    </form>

    <router-link to="/auth/forgotten-password" class="link">
      <u>{{ tr('Forgot password?') }}</u>
    </router-link>
    <router-link to="/auth/signup" class="link">
      <u>{{ tr('Don\'t have an account? Try it now for 7 days for free!') }}</u>
    </router-link>

    <modal
      name="google-2fa-modal"
      class="twofa-modal"
    >
      <google-2fa-code-form
        v-model="code"
        :title="tr('Two-factor authentication')"
        :cancel-button-label="tr('Cancel')"
        :is-loading="isLoading"
        trust-option
        @submit="({ trust }) => onFormSubmit(null, trust)"
        @cancel="() => $modal.hide('google-2fa-modal')"
      />
    </modal>
    <div class="language-selector-wrapper">
      <language-selector />
    </div>
  </div>
</template>

<script>
import getBrowserFingerprint from 'get-browser-fingerprint';

import Google2faCodeForm from '@/components/Google2faCodeForm';
import LanguageSelector from '@/components/LanguageSelector';
import { Login2faNeccessaryError, UnauthorizedError } from '@/errors';
import { isValidEmail } from '@/utils/validators';

export default {
  name: 'LoginFormView',
  components: {
    Google2faCodeForm,
    LanguageSelector,
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      code: '',
    };
  },
  methods: {
    resetForm() {
      this.email = '';
      this.password = '';
      this.code = '';
    },
    onFormSubmit($event, trust) {
      if ($event) {
        $event.preventDefault();
      }

      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      this.$store
        .dispatch('auth/login', {
          email: this.email,
          password: this.password,
          browser: getBrowserFingerprint({ enableScreen: false }),
          code: this.code,
          trust: trust ?? false,
        })
        .then(resp => {
          if (window.PasswordCredential) {
            const passwordCredential = new window.PasswordCredential({ id: this.email, password: this.password });
            navigator.credentials.store(passwordCredential);
          }

          this.$store.commit('app/language', this.$root.loggedInUser.language);

          this.$router.replace({ path: '/' });
          this.$nextTick(() => this.resetForm());

          return resp;
        })
        .catch(err => {
          if (err instanceof Login2faNeccessaryError) {
            this.$modal.show('google-2fa-modal');
          }
          else if (err instanceof UnauthorizedError) {
            this.$toast.error(this.tr('Invalid login information.'));
          }
          else {
            this.error(err);
          }
        })
        .finally(() => this.isLoading = false);
    },
    validate() {
      this.$toast.clear();

      if (!this.email) {
        this.$toast.error(this.tr('E-mail field cannot be empty!'));
        return false;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.tr('E-mail provided has an incorrect format!'));
        return false;
      }
      if (!this.password) {
        this.$toast.error(this.tr('Password field cannot be empty!'));
        return false;
      }
      if (this.password.length < 8) {
        this.$toast.error(this.tr('The password must be at least 8 characters long.'));
        return false;
      }

      return true;
    },
  },
};
</script>
