<template>
  <div class="error-logs-component">
    <div v-if="computedIsLoading && isListEmpty" class="loader">
      <i class="icon loader black" />
    </div>
    <notification-card
      v-else-if="isListEmpty"
      :image="require('@/assets/img/thumbsup.png')"
      :title="tr('Super!')"
      empty-screen
    >
      {{ tr('No error messages have been generated yet.') }}
    </notification-card>

    <new-sorted-table
      v-if="!isListEmpty"
      :data="list"
    >
      <template #header>
        <table-head col="created_at" width="120px">{{ tr('Date created') }}</table-head>
        <table-head col="type" width="130px">{{ tr('Type') }}</table-head>
        <table-head col="error_details" minWidth="300px">{{ tr('Error details') }}</table-head>
        <table-head col="data_source" width="220px">{{ tr('Datasource') }}</table-head>
        <table-head col="rule" width="250px">{{ tr('Rule') }}</table-head>
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.id">
          <table-data col="created_at">{{ formatToYMD(row.created_at) }}<br/>{{ formatTime(row.created_at) }}</table-data>
          <table-data col="type">{{ getTypeLabel(row.type) }}</table-data>
          <table-data col="error_details">
            <p v-html="row.text.replace(/([^>])\n/g, '$1<br />\n')" />
          </table-data>
          <table-data col="data_source" class="source-profile-col" v-html="sourceProfileColContent(row.source_profile)" />
          <table-data col="rule">
            <component :is="row.rule ? 'router-link' : 'span'" :to="ruleUrl(row.rule)">
              {{ row.rule ? row.rule.name : '-' }}
            </component>
          </table-data>
        </table-row>
      </template>
    </new-sorted-table>

    <div v-if="!isListEmpty && hasMore" ref="loadMore" class="load-more">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
  </div>
</template>

<script>
import NotificationCard from '@/components/NotificationCard.vue';
import { formatToYMD, formatTime } from '@/utils/date';

import RuleEntity from '@/entities/Rule.js';
import CompanyService from '@/services/Company.js';

const ITEM_PER_PAGE = 20;

export default {
  name: 'ErrorLogs',
  props: {
    rule: {
      type: RuleEntity | null,
      required: false,
      default: () => null,
    },
    waitForRule: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    NotificationCard,
  },
  data() {
    return {
      isLoading: false,
      hasMore: true,
      page: 0,
      list: [],
    };
  },
  computed: {
    isListEmpty() {
      return this.list.length == 0;
    },
    computedIsLoading() {
      return this.isLoading || (this.waitForRule && !this.rule);
    }
  },
  watch: {
    rule: {
      immediate: true,
      handler() {
        if (this.waitForRule && !this.rule) {
          return;
        }

        this.page = 0;
        this.hasMore = true;
        this.list = [];
        this.loadNewData();
      },
    }
  },
  methods: {
    formatToYMD,
    formatTime,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        id: this.$root.currentCompany.id,
        page: this.page,
        limit: ITEM_PER_PAGE,
        ruleId: this.rule ? this.rule.id : null,
      };

      CompanyService.getErrorLogs(params)
        .then(resp => {
          resp.forEach(item => this.list.push(item));
          this.hasMore = resp.length === ITEM_PER_PAGE;
        })
        .catch((err) => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    getTypeLabel(type) {
      switch (type) {
        case 'infinite_notice':
          return this.tr('Notice');

        case 'facebook_error':
          return this.tr('Facebook API Error');

        default:
          return type;
      }
    },
    ruleUrl(rule) {
      if (!rule) {
        return null;
      }

      switch (rule.type) {
        case 'post_boosting':
          return '/post-boosting/' + rule.id;

        default:
          return '/rule/' + rule.id;
      }
    },
    sourceProfileColContent(sourceProfile) {
      if (!sourceProfile) {
        return '-';
      }

      let link = '';
      switch (sourceProfile.platform) {
        case 'facebook':
          link = 'https://www.facebook.com/' + sourceProfile.external_id;
          break;

        case 'instagram':
          link = 'https://www.instagram.com/' + sourceProfile.name;
          break;
      }

      return ''
        + '<a href="' + link + '" target="_blank">' + sourceProfile.name + '</a><br/>'
        + '<small>(' + sourceProfile.platform + ')</small>';
    }
  },
};
</script>
