<template>
  <div class="settings-team-view">
    <div v-if="isLoading" class="loader">
      <i class="icon loader black" />
    </div>

    <template v-else>
      <div
        v-for="user in users"
        :key="user.id"
        class="user"
      >
        <div class="top">
          <div class="main-details">
            <span class="name">
              {{ user.fullname }}
            </span>
            <span class="email">
              {{ user.email }}
            </span>
          </div>

          <div class="actions">
            <v-select
              v-model="tmpUserLevels[user.id]"
              :options="userLevelList"
              :reduce="(val) => val.value"
              :searchable="false"
              :clearable="false"
              :appendToBody="false"
              :disabled="userBeingUpdated !== null || $root.loggedInUser.id === user.id"
              @option:selected="() => onChangeUserLevel(user)"
            />

            <button
              v-tooltip.left="{ content: isUserCompanyOwner(user) ? tr('You cannot delete the company owner') : '' }"
              class="icon"
              :disabled="userBeingUpdated !== null || $root.loggedInUser.id === user.id || isUserCompanyOwner(user)"
              @click="() => openUserDeleteConfirmModal(user)"
            >
              <i class="icon delete" />
            </button>

            <i v-if="userBeingUpdated === user.id" class="icon loader" />
            <i
              v-else-if="tmpUserLevels[user.id] === 'restricted_viewer' || tmpUserLevels[user.id] === 'restricted_editor'"
              class="icon edit"
              @click="() => $refs.editUserRestrictionsModal.open(user, tmpUserLevels[user.id])"
            />
            <i v-else class="icon transparent" />
          </div>
        </div>
      </div>
      <div
        v-for="invite in invites"
        :key="invite.id"
        class="user"
      >
        <div class="top">
          <div class="main-details">
            <span class="name">
              {{ (invite.firstname + ' ' + invite.lastname).trim() }}
              <span class="pending"> - {{ tr('Pending invitation') }}</span>
            </span>
            <span class="email">
              {{ invite.email }}
            </span>
          </div>

          <div class="actions">
            <v-select
              v-model="invite.level"
              :options="userLevelList"
              :reduce="(val) => val.value"
              disabled
            />

            <button
              class="icon"
              :disabled="resendInvitationId !== null || revokeInvitationId !== null"
              @click="() => revokeInvitation(invite)"
            >
              <i v-if="revokeInvitationId === invite.id" class="icon loader" />
              <i v-else class="icon delete" />
            </button>
          </div>
        </div>

        <div class="buttons">
          <button
            class="small primary"
            :disabled="resendInvitationId !== null || revokeInvitationId !== null"
            @click="() => resendInvitation(invite)"
          >
            <i v-if="resendInvitationId === invite.id" class="icon loader white" />
            <template v-else> {{ tr('Resend') }} </template>
          </button>
        </div>
      </div>

      <info-text v-if="!canInviteNewMember">
        {{ tr('You cannot invite more members. Switch to a larger subscription plan, or delete another user\'s access first.') }}
      </info-text>
    </template>

    <edit-user-restrictions-modal ref="editUserRestrictionsModal" />
  </div>
</template>

<script>
import EditUserRestrictionsModal from '@/components/EditUserRestrictionsModal.vue';
import InfoText from '@/components/InfoText.vue';
import CompanyService from '@/services/Company.js';
import UserService from '@/services/User.js';

export default {
  name: 'SettingsTeamView',
  components: {
    InfoText,
    EditUserRestrictionsModal,
  },
  data:() => ({
    isLoading: true,
    users: [],
    tmpUserLevels: {},
    userBeingUpdated: null,
    resendInvitationId: null,
    revokeInvitationId: null,
  }),
  computed: {
    loggedInUserCompany() {
      return this.$root.currentCompany;
    },
    invites() {
      return this.$store.getters['invites/list'];
    },
    inputClasses() {
      return this.error ? 'error' : '';
    },
    userLevelList() {
      return [
        { label: 'Admin', value: 'admin' },
        { label: 'Normal', value: 'normal' },
        { label: 'Restricted Editor', value: 'restricted_editor' },
        { label: 'Restricted Viewer', value: 'restricted_viewer' },
      ];
    },
    canInviteNewMember() {
      return this.loggedInUserCompany.max_users === null || this.loggedInUserCompany.max_users > (this.users.length + this.invites.length);
    },
  },
  watch: {
    users: {
      immediate: true,
      handler() {
        const newLevels = {};
        this.users.forEach(user => newLevels[user.id] = user.level);
        this.tmpUserLevels = newLevels;
      },
    },
    canInviteNewMember(newValue) {
      this.$emit('canInviteNewMember', newValue);
    },
  },
  created() {
    this.updateContent();
    this.$emit('canInviteNewMember', this.canInviteNewMember);
  },
  methods: {
    updateContent() {
      this.isLoading = true;

      const promises = [
        CompanyService.getMembers(this.loggedInUserCompany.id).then(users => this.users = users),
        this.$store.dispatch('invites/fetch'),
      ];

      Promise.all(promises)
        .then(() => this.isLoading = false)
        .catch(err => this.error(err));
    },
    resendInvitation(invite) {
      if (this.resendInvitationId !== null) {
        return;
      }
      this.resendInvitationId = invite.id;

      this.$store
        .dispatch('invites/resendInvitation', invite)
        .then(resp => {
          this.$toast.success(this.tr('Invitation has been sent successfully.'));

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.resendInvitationId = null);
    },
    revokeInvitation(invite) {
      if (this.revokeInvitationId !== null) {
        return;
      }
      this.revokeInvitationId = invite.id;

      this.$store
        .dispatch('invites/deleteInvitation', invite)
        .then(resp => {
          this.$toast.success(this.tr('Invitation has been revoked successfully.'));

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.revokeInvitationId = null);
    },
    onChangeUserLevel(user) {
      const newLevel = this.tmpUserLevels[user.id];

      if (newLevel === 'restricted_viewer' || newLevel === 'restricted_editor') {
        const backupMethod = () => {
          this.tmpUserLevels[user.id] = user.level;
        };

        this.$nextTick(() => this.$refs.editUserRestrictionsModal.open(user, newLevel, backupMethod));
      }
      else {
        this.changeUserLevel(user, newLevel);
      }
    },
    changeUserLevel(user, newLevel) {
      this.userBeingUpdated = user.id;

      UserService.changeLevel(user.id, { level: newLevel })
        .then(resp => {
          user.level = newLevel;

          this.$toast.success(this.tr('The user\'s access level has been successfully changed.'));

          return resp;
        })
        .catch(err => {
          this.error(err);
          this.tmpUserLevels[user.id] = user.level;
        })
        .finally(() => this.userBeingUpdated = null);
    },
    openUserDeleteConfirmModal(user) {
      this.$confirmation.open({
        title: this.tr('Delete user'),
        content: this.tr('Do you really want to delete :name\'s user account?', { ':name': user.fullname }),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        confirmClass: 'red',
        confirm: () => this.removeUser(user),
      });
    },
    removeUser(user) {
      return UserService.delete(user.id)
        .then(resp => {
          this.updateContent();

          this.$toast.clear();
          this.$toast.success(this.tr('User was successfully deleted.'));

          return resp;
        })
        .catch(err => this.error(err));
    },
    isUserCompanyOwner(user) {
      return user.id === this.loggedInUserCompany.owner.id;
    },
  },
};
</script>
