import Api from '@/services/Api';

export default {
  getConfig() {
    return Api().get('/app/config');
  },

  getDynamicSubfields(field, adAccountId)
  {
    const data = {
      field,
      ad_account_id: adAccountId,
    };

    const query = new URLSearchParams(data).toString();

    return Api().get('/app/dynamic-subfields?' + query);
  }
};
