var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delete-rule-confirm-modal-content-component" },
    [
      _c("div", { staticClass: "top" }, [
        _c("img", {
          staticClass: "image delete-confirm-image",
          attrs: { src: require("@/assets/img/delete-confirm.png") },
        }),
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.tr("Are you sure you want to delete this automation?")
              ) +
              " "
          ),
        ]),
      ]),
      _vm.showCampaignDeletionOptions
        ? _c(
            "div",
            { staticClass: "campaign-options" },
            [
              !_vm.showDeletedCampaignMessage
                ? _c("label", { staticClass: "checkbox-wrapper" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.deleteCampaign,
                          expression: "deleteCampaign",
                        },
                      ],
                      staticClass: "delete-campaign-checkbox",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.deleteCampaign)
                          ? _vm._i(_vm.deleteCampaign, null) > -1
                          : _vm.deleteCampaign,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.deleteCampaign,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.deleteCampaign = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.deleteCampaign = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.deleteCampaign = $$c
                          }
                        },
                      },
                    }),
                    _c("span", { staticClass: "checkbox-label" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.tr("Also delete associated campaign")) +
                          " "
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    { staticClass: "info-text-wrapper" },
                    [
                      _c("info-text", { staticClass: "info" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "The associated campaign has already been deleted."
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
              _c("transition-expand", [
                _vm.deleteCampaign
                  ? _c(
                      "div",
                      { staticClass: "info-text-wrapper" },
                      [
                        _c("info-text", { staticClass: "warning" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "Warning: This action will permanently delete the campaign and all associated data. This action cannot be undone."
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "cancel-button clean",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.close() },
          },
          [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "confirm-button red",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.actionConfirm() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : [_vm._v(" " + _vm._s(_vm.tr("Confirm")) + " ")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }