<template>
  <div class="disable-google-2fa-component">
    <google-2fa-code-form
      v-model="code"
      :title="tr('Disable Google 2FA')"
      :is-loading="isSaving"
      confirm-button-class="red"
      @submit="() => confirm()"
      @cancel="() => cancel()"
    />
  </div>
</template>

<script>
import Google2faCodeForm from '@/components/Google2faCodeForm';

import AuthService from '@/services/Auth';

export default {
  name: 'DisableGoogle2fa',
  components: {
    Google2faCodeForm,
  },
  data: () => ({
    isSaving: false,
    code: '',
  }),
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      if (this.code.length !== 6) {
        this.error('You must enter the 6-digit code from the Google Authenticator app.');
        return;
      }

      if (this.isSaving) {
        return;
      }
      this.isSaving = true;

      AuthService.disableGoogle2fa(this.code)
        .then(() => {
          this.$root.loggedInUser.google_2fa_enabled = false;
          this.$toast.success(this.tr('Two-factor authentication has been successfully disabled.'));
          this.$emit('success');
        })
        .catch(err => this.error(err))
        .finally(() => this.isSaving = false);
    }
  }
};
</script>
