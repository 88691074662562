var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instagram-post-preview-component post-preview" },
    [
      _c("div", { staticClass: "top" }, [
        _c(
          "div",
          { staticClass: "profile" },
          [
            _vm.post && _vm.post.source_profile.picture
              ? _c("lazy-image", {
                  staticClass: "image",
                  attrs: { src: _vm.post.source_profile.picture },
                })
              : _vm._e(),
            _c("div", { staticClass: "profile-data" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(_vm.post.source_profile.name) + " "),
              ]),
              _c(
                "a",
                {
                  staticClass: "date",
                  attrs: { href: _vm.post.url, target: "_blank" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.formattedDate(_vm.post.created_at)) + " "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm.post.attachments
        ? _c(
            "div",
            { staticClass: "attachment", class: _vm.post.media_type },
            [
              _vm.isPhoto || _vm.isAlbum
                ? _c("lazy-image", { attrs: { src: _vm.imageSrc } })
                : _vm._e(),
              _vm.isVideo
                ? _c("video", {
                    attrs: {
                      src: _vm.videoSrc,
                      autoplay: "",
                      loop: "",
                      muted: "",
                    },
                    domProps: { muted: true },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "social" }, [
        _vm._m(0),
        _vm.likesStr
          ? _c("span", [_vm._v(" " + _vm._s(_vm.likesStr) + " ")])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "text-section" }, [
        _c("span", { staticClass: "profile-name" }, [
          _vm._v(" " + _vm._s(_vm.post.source_profile.name) + " "),
        ]),
        _vm.post.text
          ? _c("span", {
              staticClass: "main-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.post.text.replace(/(?:\r\n|\r|\n)/g, "<br>")
                ),
              },
            })
          : _vm._e(),
      ]),
      _vm.commentsStr
        ? _c("div", { staticClass: "comments" }, [
            _vm._v(" " + _vm._s(_vm.commentsStr) + " "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "reactions" }, [
      _c("i", { staticClass: "icon ig-like light-black" }),
      _c("i", { staticClass: "icon ig-comment light-black" }),
      _c("i", { staticClass: "icon ig-send light-black" }),
      _c("i", { staticClass: "icon ig-bookmark light-black" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }