import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';

export default [
  {
    label: 'General',
    view: Step1,
  },
  {
    label: 'Campaign',
    view: Step2,
  },
  {
    label: 'Ad set',
    view: Step3,
  },
  {
    label: 'Ad',
    view: Step4,
  },
];
