import BaseEntity from '@/entities/BaseEntity';

export default class Audience extends BaseEntity
{
  id = null;
  type = '';
  name = '';
  has_geotargeting = false;
  has_gender_targeting = false;
  has_age_targeting = false;
  deleted_at = null;

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Deleted] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }

}
