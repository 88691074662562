var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "table", class: _vm.wrapperClasses }, [
    _c("div", { staticClass: "table-header" }, [_vm._t("header")], 2),
    _c(
      "div",
      { staticClass: "table-body" },
      [_vm._t("default", null, { sort: _vm.sortedData })],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }