var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "protected-posts-view list-view" },
    [
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("v-select", {
            staticClass: "source-select",
            attrs: {
              options: _vm.sourceProfileList,
              reduce: (option) => option.id,
              placeholder: _vm.tr("Datasource"),
              label: "name",
            },
            scopedSlots: _vm._u([
              {
                key: "option",
                fn: function (option) {
                  return [
                    _c("span", [
                      option.platform
                        ? _c("i", { class: "icon " + option.platform })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(option.name) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "selected-option",
                fn: function (option) {
                  return [
                    _c("span", [
                      option.platform
                        ? _c("i", { class: "icon " + option.platform })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(option.name) + " "),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.sourceProfileId,
              callback: function ($$v) {
                _vm.sourceProfileId = $$v
              },
              expression: "sourceProfileId",
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            staticClass: "text icon--search",
            attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
            domProps: { value: _vm.searchTerm },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "checkbox-list horizontal" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.onlyWithComments,
                    expression: "onlyWithComments",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.onlyWithComments)
                    ? _vm._i(_vm.onlyWithComments, null) > -1
                    : _vm.onlyWithComments,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.onlyWithComments,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.onlyWithComments = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.onlyWithComments = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.onlyWithComments = $$c
                    }
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Content with comments")) + " "),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.onlyWithModeratedComments,
                    expression: "onlyWithModeratedComments",
                  },
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.onlyWithModeratedComments)
                    ? _vm._i(_vm.onlyWithModeratedComments, null) > -1
                    : _vm.onlyWithModeratedComments,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.onlyWithModeratedComments,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.onlyWithModeratedComments = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.onlyWithModeratedComments = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.onlyWithModeratedComments = $$c
                    }
                  },
                },
              }),
              _vm._v(
                " " + _vm._s(_vm.tr("Content with moderated comments")) + " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.isListEmpty && !_vm.hasMore && !_vm.isEmptyFilters
        ? _c("empty-screen", {
            attrs: {
              icon: "search",
              title: _vm.tr("No results match your current search criteria"),
              "flag-text": _vm.tr("Modify search"),
              description: _vm.tr(
                "Currently there are no items that match your criteria. The items you're looking for might appear here later, or try different parameters to see currently available items."
              ),
            },
          })
        : _vm.isListEmpty && !_vm.hasMore && _vm.isEmptyFilters
        ? _c("empty-screen", {
            attrs: {
              icon: "search",
              title: _vm.tr(
                "There are no posts under this account at this time."
              ),
              "flag-text": _vm.tr("Modify search"),
              description: _vm.tr(
                "Currently there are no items that match your criteria. The items you're looking for might appear here later, or try different parameters to see currently available items."
              ),
            },
          })
        : !_vm.isListEmpty
        ? _c("sorted-table", {
            attrs: { data: _vm.list },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("table-head", {
                      staticClass: "status",
                      attrs: { col: "status", width: "42px" },
                    }),
                    _c("table-head", {
                      attrs: { col: "name", minWidth: "200px" },
                    }),
                    _c(
                      "table-head",
                      { attrs: { col: "created_at", width: "150px" } },
                      [_vm._v(_vm._s(_vm.tr("Posted")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "number-column",
                        attrs: { col: "comment_count", width: "100px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Comments")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "number-column",
                        attrs: {
                          col: "reviewed_comment_count",
                          width: "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tr("Reviewed")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "number-column",
                        attrs: {
                          col: "moderated_comment_count",
                          width: "100px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tr("Moderated")))]
                    ),
                    _c("table-head", {
                      attrs: { col: "actions", width: "94px" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (post) {
                    return _c(
                      "table-row",
                      { key: post.id },
                      [
                        _c(
                          "table-data",
                          { staticClass: "status", attrs: { col: "status" } },
                          [
                            _c("switches", {
                              attrs: {
                                disabled:
                                  _vm.$root.loggedInUser.isRestrictedViewer,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return (() =>
                                    _vm.onChangeModerationStatus(post)).apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                              model: {
                                value: post.comment_moderation,
                                callback: function ($$v) {
                                  _vm.$set(post, "comment_moderation", $$v)
                                },
                                expression: "post.comment_moderation",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "table-data",
                          {
                            staticClass: "name-column",
                            attrs: { col: "name" },
                          },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: `/moderation/post/${post.id}` } },
                              [
                                _c("div", { staticClass: "name-with-icon" }, [
                                  _c("i", {
                                    class:
                                      "icon " + post.source_profile.platform,
                                  }),
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(post.source_profile.name)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "post-text" }, [
                                  _vm._v(" " + _vm._s(post.shortText) + " "),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("table-data", { attrs: { col: "created_at" } }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value:
                                    _vm.formatToYMD(post.created_at) +
                                    ", " +
                                    _vm.formatTime(post.created_at),
                                  expression:
                                    "formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.timeAgo(post.created_at, _vm.tr)) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "table-data",
                          {
                            staticClass: "number-column",
                            attrs: { col: "comment_count" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatNumber(post.comment_count)) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "table-data",
                          {
                            staticClass: "number-column",
                            attrs: { col: "reviewed_comment_count" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatNumber(post.reviewed_comment_count)
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "table-data",
                          {
                            staticClass: "number-column",
                            attrs: { col: "moderated_comment_count" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatNumber(post.moderated_comment_count)
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("table-data", { attrs: { col: "actions" } }, [
                          _c(
                            "div",
                            { staticClass: "action-group" },
                            [
                              !_vm.$root.loggedInUser.isRestrictedViewer
                                ? [
                                    _c("refresh-button", {
                                      attrs: {
                                        label: _vm.tr("Update comments"),
                                        "success-message": _vm.tr(
                                          "Comments of the selected post updated successfully."
                                        ),
                                        promise: () => _vm.updateComments(post),
                                        "color-class": "light-black",
                                        "label-as-tooltip": "",
                                      },
                                    }),
                                    _vm.$root.currentCompany
                                      .enabled_manual_run &&
                                    _vm.$root.loggedInUser.level === "admin"
                                      ? _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content:
                                                    _vm.tr("Moderate Now"),
                                                  classes: [""],
                                                },
                                                expression:
                                                  "{ content: tr('Moderate Now'), classes: [ '' ] }",
                                              },
                                            ],
                                            staticClass: "icon-wrapper",
                                            on: {
                                              click: () =>
                                                _vm.moderatePost(post),
                                            },
                                          },
                                          [
                                            _c("i", {
                                              class: _vm.runIconClasses(post),
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("navigation-arrow", {
                                      attrs: {
                                        link: `/moderation/post/${post.id}`,
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          })
        : _vm._e(),
      _vm.hasMore
        ? _c("div", { ref: "loadMore", staticClass: "load-more" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }