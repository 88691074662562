import Api from '@/services/Api';

import Audience from '@/entities/Audience';

export default {
  getList({ id, type }) {
    const data = {
      ad_account_id: id,
    };
    if (type) {
      data['type'] = type;
    }

    const query = new URLSearchParams(data).toString();

    return Api()
      .get('/audiences?' + query)
      .then((resp) => {
        return resp.map(i => new Audience(i));
      });
  },

  refresh(id) {
    const data = { ad_account_id: id };

    return Api()
      .post('/audiences/refresh', data)
      .then((resp) => {
        return resp.map(i => new Audience(i));
      });
  },
};
