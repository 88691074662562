<template>
  <div class="activity-log-component" :hidable-classes="['event']">
    <div v-if="!isListEmpty || hasMore" class="events">
      <div v-for="(event, index) in logs" :key="index" class="event">
        <div class="time">
          <div v-if="showDate(index)" class="date">
            {{ formattedDate(event.created_at) }}
          </div>

          <div class="vertical-line" />

          <div class="content-container">
            <div :class="actionIconContainerColor(event)">
              <i :class="actionIconClasses(event)" />
            </div>
            {{ formatTime(event.created_at) }}
          </div>

          <div class="vertical-line bottom" />
        </div>

        <card
          class="event-card"
          :class="{ 'with-date': cardWithDate(index) }"
          :header-color="headerColor(event)"
        >
          <template #header>
            <div v-if="event.action === 'error'" class="event-title">
              {{ tr('Could not create an ad from this content.') }}
            </div>
            <div v-else class="action-message">
              <div class="icon-container">
                <i :class="'icon checkmark ' + headerColor(event)" />
              </div>

              <div class="event-title">{{ loggedActionText(event) }}</div>

              <div v-if="getAdFromEvent(event).variation" class="event-variation">
                - v{{ getAdFromEvent(event).variation }}
              </div>
            </div>
          </template>

          <template v-if="event.created_ad?.created_with_ai" #header-decorator>
            <i class="icon magic-stars white" />
            <template v-if="!$root.isMobile">
              {{ tr('Created with Ai') }}
            </template>
          </template>

          <template #default>
            <inline-social-content :entity="event.post" :text="getText(event)" />

            <div
              v-if="(event.created_ad && event.created_ad.url) || (event.post && event.post.url)"
              class="links"
            >
              <link-with-icon
                v-if="event.created_ad && event.created_ad.url"
                :link="event.created_ad.url"
              >
                {{ tr('Go to created Ad') }}
              </link-with-icon>

              <link-with-icon
                v-if="event.post && event.post.url"
                :link="event.post.url"
              >
                {{ tr('Go to Original Post') }}
              </link-with-icon>
            </div>
          </template>
        </card>
      </div>

      <template v-if="hasMore">
        <div
          v-for="i in [ 1, 2 ]"
          :key="'activity-log-loader-skeleton--' + i"
          ref="loadMore"
          class="event"
        >
          <div class="time">
            <div v-if="i === 1" class="date">
              <skeleton width="100%" height="20px" />
            </div>

            <div class="vertical-line" />

            <div class="content-container">
              <div class="icon-container secondary">
                <skeleton width="100%" height="100%" />
              </div>
              <skeleton width="50px" height="14px" />
            </div>

            <div class="vertical-line bottom" />
          </div>

          <card
            :class="{ 'event-card': true, 'with-date': i === 1 }"
            header-color="white"
          >
            <template #header>
              <skeleton width="60%" max-width="160px" height="12px" />
            </template>

            <template #default>
              <inline-social-content />
            </template>
          </card>
        </div>
      </template>
    </div>

    <empty-screen
      v-else
      icon="rocket"
      :title="tr('Your automation history will appear here')"
      :flag-text="tr('Monitoring active')"
      :description="tr('When your automation starts working, you\'ll see a chronological list of all activities here, including started and stopped ads, policy checks, and other automated actions.')"
    />
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import LinkWithIcon from '@/components/LinkWithIcon.vue';
import Skeleton from '@/components/Skeleton.vue';
import RuleActivityLog from '@/entities/RuleActivityLog.js';
import RuleService from '@/services/Rule.js';
import { formatTime, formatToStringMD } from '@/utils/date.js';
import { ucfirst } from '@/utils/string';

const ITEM_PER_PAGE = 10;

export default {
  name: 'ActivityLog',
  components: {
    Card,
    EmptyScreen,
    InlineSocialContent,
    LinkWithIcon,
    Skeleton,
  },
  props: {
    ruleId: {
      type: [ String, null ],
      default: null,
    },
    limit: {
      type: [ Number, null ],
      default: null,
    },
  },
  data: () => ({
    isLoading: false,
    hasMore: true,
    page: 0,
    logs: [],
  }),
  computed: {
    computedIsLoading() {
      return this.isLoading || this.ruleId === null;
    },
    isListEmpty() {
      return this.logs.length === 0;
    },
  },
  watch: {
    ruleId: {
      immediate: true,
      handler(val) {
        this.reset();

        if (val) {
          this.loadNewData();
        }
      },
    },
  },
  methods: {
    ucfirst,
    formatTime,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        id: this.ruleId,
        page: this.page,
        limit: ITEM_PER_PAGE,
      };

      RuleService.getLogs(params)
        .then(resp => {
          resp.forEach(item => this.logs.push(new RuleActivityLog(item)));
          this.hasMore = resp.length === ITEM_PER_PAGE;

          return resp;
        })
        .catch(err => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    reset() {
      this.logs = [];
      this.page = 0;
      this.hasMore = true;
    },
    refresh() {
      this.reset();
      this.loadNewData();
    },
    getAdFromEvent(event) {
      if (event.created_ad) {
        return event.created_ad;
      }

      return event.ad;
    },
    getText(event) {
      const ad = this.getAdFromEvent(event);
      if (ad.text) {
        return ad.text;
      }
      if (ad) {
        return ad.name;
      }
      if (event.post) {
        return event.post.text;
      }
      return null;
    },
    headerColor(event) {
      switch (event.action) {
        case 'start':
        case 'stop':
        case 'smart_stop':
        case 'scaling_up':
          return 'primary';

        case 'delete':
        case 'error':
          return 'red';
      }

      return null;
    },
    actionIconContainerColor(event) {
      const entityAction = event.action;
      let containerColor = '';

      switch (entityAction) {
        case 'start':
        case 'stop':
        case 'smart_stop':
        case 'scaling_up':
          containerColor = 'primary';
          break;

        case 'delete':
        case 'error':
          containerColor = 'red';
          break;
      }

      return 'icon-container ' + containerColor;
    },
    actionIconClasses(event) {
      const entityAction = event.action;
      let iconType = '';

      switch (entityAction) {
        case 'start':
          iconType = 'play';
          break;

        case 'stop':
        case 'smart_stop':
        case 'delete':
          iconType = 'pause';
          break;

        case 'scaling_up':
          iconType = 'scaling-up';
          break;

        // TODO what is this case for?
        case 'error':
          iconType = 'warning';
          break;
      }

      return 'icon white ' + iconType;
    },
    loggedActionText(event) {
      const entityAction = event.action;
      let actionText = '';

      switch (entityAction) {
        case 'start':
        case 'scaling_up':
          if (event.created_ad?.created_with_ai && this.$root.isMobile) {
            actionText = this.tr('New ad created with AI');
          }
          else {
            actionText = this.tr('New ad was created');
          }
          break;

        case 'stop':
        case 'smart_stop':
          actionText = this.tr('Your ad was stopped');
          break;

        case 'delete':
          actionText = this.tr('An ad was deleted');
          break;
      }

      return actionText;
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0]);
      }
    },
    formattedDate(date) {
      return formatToStringMD(date, this.tr);
    },
    showDate(index) {
      if (index === 0) {
        return true;
      }
      if (index > 0) {
        const currentDate = this.formattedDate(this.logs[index].created_at);
        const previousDate = this.formattedDate(this.logs[index - 1].created_at);
        return currentDate !== previousDate;
      }
      return true;
    },
    cardWithDate(index) {
      return this.showDate(index);
    },
  },
};
</script>
