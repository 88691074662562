var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "onboarding-component onboarding--ad",
      style: _vm.wrapperStyle,
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 0,
                      expression: "step === 0",
                    },
                  ],
                  staticClass: "step step--0 step--welcome",
                },
                [
                  _c("i", { staticClass: "icon primary trumpet" }),
                  _c("div", {
                    staticClass: "title",
                    domProps: {
                      innerHTML: _vm._s(_vm.tr("Get started with Infinite∞Ad")),
                    },
                  }),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "onboarding-main-content" }, [
                    _c("div", { staticClass: "textual" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.tr(
                              "Welcome to smarter advertising! As an official Meta Tech Provider, we ensure your ads are managed securely and safely. Let's set up your first automated campaigns in three simple steps - we'll guide you through everything while maintaining the highest security standards."
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Let start it!")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 1 && !_vm.skipFacebookProfile,
                      expression: "step === 1 && !skipFacebookProfile",
                    },
                  ],
                  staticClass: "step step--1 step--profile",
                },
                [
                  _c("i", { staticClass: "icon primary meta-v2" }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Connect Meta Profile"))),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { staticClass: "onboarding-main-content scroll-wrapper" },
                    [
                      _c("div", { staticClass: "textual" }, [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "To begin using our AI-powered automation features, you'll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising."
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        class: _vm.connectProfileButtonClasses,
                        attrs: { disabled: _vm.isConnecting },
                        on: { click: () => _vm.connect() },
                      },
                      [
                        _vm.isConnecting
                          ? _c("i", { staticClass: "icon loader white" })
                          : _vm._e(),
                        _vm._v(
                          " " +
                            _vm._s(_vm.tr("Connect your Meta profile")) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 2,
                      expression: "step === 2",
                    },
                  ],
                  staticClass: "step step--2 step--ad-account",
                },
                [
                  _c("i", { staticClass: "icon primary ad-lift" }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Connect Ad account"))),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { staticClass: "onboarding-main-content scroll-wrapper" },
                    [
                      _c("div", { staticClass: "textual" }, [
                        _c("p", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "In the next step, connect the ad accounts where you want to run your ads."
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("integrations-meta-ad-accounts-view", {
                        attrs: { minimal: "" },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bordered gray",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [
                        _c("i", { staticClass: "icon arrow-left" }),
                        _vm._v(" " + _vm._s(_vm.tr("Back")) + " "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Next")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 3,
                      expression: "step === 3",
                    },
                  ],
                  staticClass: "step step--3 step--source-profile",
                },
                [
                  _c("i", { staticClass: "icon primary facebook" }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Add Facebook Pages"))),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { staticClass: "onboarding-main-content scroll-wrapper" },
                    [
                      _c("div", { staticClass: "textual" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.tr(
                                "In the next step, connect the Facebook pages you want to use for advertising."
                              )
                            ),
                          },
                        }),
                      ]),
                      _c("integrations-meta-facebook-items-view", {
                        attrs: {
                          "platform-from-prop": "facebook",
                          minimal: "",
                        },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bordered gray",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [
                        _c("i", { staticClass: "icon arrow-left" }),
                        _vm._v(" " + _vm._s(_vm.tr("Back")) + " "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Next")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 4,
                      expression: "step === 4",
                    },
                  ],
                  staticClass: "step step--4 step--source-profile",
                },
                [
                  _c("i", { staticClass: "icon primary instagram" }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Add Instagram profiles"))),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { staticClass: "onboarding-main-content scroll-wrapper" },
                    [
                      _c("div", { staticClass: "textual" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.tr(
                                "In the next step, connect the Instagram profiles you want to use for advertising."
                              )
                            ),
                          },
                        }),
                      ]),
                      _c("integrations-meta-facebook-items-view", {
                        attrs: {
                          "platform-from-prop": "instagram",
                          minimal: "",
                        },
                        on: {
                          loading: (value) =>
                            _vm.disableNavigationButtons(value),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "bottom-button space-between" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bordered gray",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.back() },
                      },
                      [
                        _c("i", { staticClass: "icon arrow-left" }),
                        _vm._v(" " + _vm._s(_vm.tr("Back")) + " "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.next() },
                      },
                      [_vm._v(_vm._s(_vm.tr("Finish")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "slide-right-to-left", mode: "out-in" } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.step === 5,
                      expression: "step === 5",
                    },
                  ],
                  staticClass: "step step--5 step--done",
                },
                [
                  _c("i", { staticClass: "icon primary trumpet" }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.tr("Congratulations, you're all set!"))),
                  ]),
                  _c("div", { staticClass: "divider" }),
                  _c(
                    "div",
                    { staticClass: "onboarding-main-content scroll-wrapper" },
                    [
                      _c("div", { staticClass: "textual" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.tr(
                                "You've successfully connected all your accounts. Now it's time to create your first automation and let our AI optimize your advertising campaigns."
                              )
                            ),
                          },
                        }),
                      ]),
                      _c("create-new-rule-cards", {
                        attrs: { showCards: [0] },
                        on: { click: () => _vm.$emit("close") },
                      }),
                      _c(
                        "router-link",
                        {
                          staticClass: "primary-link",
                          attrs: { to: "/automation/new/start" },
                          nativeOn: {
                            click: function ($event) {
                              return (() => _vm.$emit("close")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "Create Ad Creation automation in manual mode"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "divider" }),
                  _c("div", { staticClass: "bottom-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "primary",
                        attrs: { disabled: _vm.disabledButtons },
                        on: { click: () => _vm.$emit("close") },
                      },
                      [_vm._v(_vm._s(_vm.tr("Close")))]
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.step >= 0 && _vm.step <= 4,
                    expression: "step >= 0 && step <= 4",
                  },
                ],
                staticClass: "dots",
              },
              [
                _c("div", { class: { dot: true, active: _vm.step === 0 } }),
                !_vm.skipFacebookProfile
                  ? _c("div", { class: { dot: true, active: _vm.step === 1 } })
                  : _vm._e(),
                _c("div", { class: { dot: true, active: _vm.step === 2 } }),
                _c("div", { class: { dot: true, active: _vm.step === 3 } }),
                _c("div", { class: { dot: true, active: _vm.step === 4 } }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c("img", {
          staticClass: "meta-badge",
          attrs: { src: require("@/assets/img/onboarding/meta-badge.png") },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }