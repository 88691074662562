import BaseEntity from '@/entities/BaseEntity';
import Rule from '@/entities/Rule';
import User from '@/entities/User';

export default class RuleChangeLog extends BaseEntity
{
  id = null;
  user = null;
  rule = null;
  changes = [];
  created_at = new Date();

  _entityFields = {
    rule: Rule,
    user: User,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
