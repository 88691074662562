<template>
  <div :class="['facebook-ad-preview-component', imageLayoutClass]">
    <div class="top">
      <div class="profile">
        <div v-if="name" class="image">
          <i class="icon infinite white" />
        </div>
        <skeleton
          v-else
          class="image profile-image-skeleton"
          width="32px"
          height="32px"
        />
        <div v-if="name" class="name">
          {{ name }}
        </div>
        <skeleton
          v-else
          class="name"
          width="100px"
          height="18px"
        />
      </div>
      <div
        v-if="text"
        class="main-text"
      >
        <read-more-text :text="text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
      </div>
      <skeleton v-else-if="showAllSkeleton" width="80%" height="18px" />
    </div>

    <div :class="[ 'image', checkImageClass ]">
      <img
        v-if="imgUrl"
        :src="imgUrl"
        alt="Ad Image"
      />
      <skeleton
        v-else
        class="ad-image-skeleton"
        width="100%"
        min-height="300px"
      />
    </div>

    <div v-if="showCtaSection" class="cta-section">
      <div class="texts">
        <div v-if="ctaTitle" class="cta-title">{{ ctaTitle }}</div>
        <skeleton
          v-else-if="showAllSkeleton"
          class="cta-title"
          width="150px"
          height="18px"
        />
        <div v-if="ctaDescription" class="cta-description">{{ ctaDescription }}</div>
        <skeleton
          v-else-if="showAllSkeleton"
          class="cta-description"
          width="120px"
          height="18px"
        />
      </div>
      <div v-if="ctaButton" class="cta-button">{{ ctaButton }}</div>
      <skeleton
        v-else-if="showAllSkeleton"
        class="cta-button"
        width="70px"
        height="36px"
      />
    </div>
  </div>
</template>

<script>
import ReadMoreText from '@/components/ReadMoreText.vue';
import Skeleton from '@/components/Skeleton.vue';

export default {
  name: 'FacebookAdPreviewComponent',
  components: {
    ReadMoreText,
    Skeleton,
  },
  props: {
    text: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    imgUrl: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ctaTitle: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ctaDescription: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    ctaButton: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      imageWidth: null,
      imageHeight: null,
      aspectRatio: null,
    };
  },
  computed: {
    name() {
      return process.env.VUE_APP_HTML_TITLE;
    },
    checkImageClass() {
      return this.imgUrl ? 'image--loaded' : 'image--waiting';
    },
    imageLayoutClass() {
      if (!this.aspectRatio) {
        return 'image-ratio--9-16';
      }

      if (this.aspectRatio >= 1.6) {
        return 'image-ratio--16-9';
      }
      else if (this.aspectRatio < 1.6 && this.aspectRatio > 1.1) {
        return 'image-ratio--4-3';
      }
      else if (this.aspectRatio <= 1.1 && this.aspectRatio > 0.9) {
        return 'image-ratio--1-1';
      }
      else if (this.aspectRatio >= 0.6) {
        return 'image-ratio--3-4';
      }
      else if (this.aspectRatio < 0.6) {
        return 'image-ratio--9-16';
      }

      return 'image-ratio--9-16';
    },
    showAllSkeleton() {
      return !this.text && !this.imgUrl && !this.ctaTitle && !this.ctaDescription && !this.ctaButton;
    },
    showCtaSection() {
      return this.ctaTitle || this.ctaDescription || this.ctaButton || this.showAllSkeleton;
    },
  },
  watch: {
    'imgUrl': {
      immediate: false,
      handler() {
        this.getImageSize();
      },
    },
  },
  mounted() {
    this.getImageSize();
  },
  methods: {
    getImageSize() {
      if (!this.imgUrl) {
        this.imageWidth = null;
        this.imageHeight = null;
        this.aspectRatio = null;
        return;
      }

      const img = new Image();
      img.src = this.imgUrl;
      img.onload = () => {
        this.imageWidth = img.width;
        this.imageHeight = img.height;
        this.aspectRatio = (img.width / img.height).toFixed(2);
      };
    },
  },
};
</script>

