<template>
  <div class="rule-preview">
    <div
      class="size-keeper"
      :style="'min-height: ' + sizeKeeperHeight + 'px'"
    />

    <div v-if="isLoading" class="loading-state">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
    <empty-screen
      v-else-if="items.length === 0"
      icon="adjustments"
      :title="tr('No posts match your current filters')"
      :flag-text="tr('Adjust filters')"
      :description="tr('We couldn\'t find any posts that match your selected filters. Try adjusting your filter settings to see more results in your post list.')"
    />
    <div v-else ref="content" class="content">
      <div class="item-count-text">
        {{ itemsCountText }}
      </div>

      <sorted-table :data="items">
        <template #header>
          <table-head col="source" width="150px">{{ tr('Source') }}</table-head>
          <table-head col="post" min-width="300px">{{ tr('Post') }}</table-head>
          <table-head col="post_type" width="140px">{{ tr('Post type') }}</table-head>
          <table-head col="media_type" width="140px">{{ tr('Media type') }}</table-head>
          <table-head col="posted" width="140px">{{ tr('Posted') }}</table-head>
        </template>

        <template #default="{ sort }">
          <table-row v-for="post in sort" :key="post.id">
            <table-data col="source">
              <label>
                {{ tr('Source') }}
              </label>
              <div class="platform">{{ ucfirst(post.source_profile.platform) }}</div>
              {{ post.source_profile.name }}
            </table-data>
            <table-data col="post">
              <label>
                {{ tr('Post') }}
              </label>
              <small>
                {{ tr('Post ID') }}:
                <component
                  :is="post.url ? 'a' : 'span'"
                  :href="post.url"
                  :target="post.url ? '_blank' : ''"
                >
                  {{ post.external_id }}
                </component>
              </small><br />
              <div class="post-text">
                {{ post.shortText }}
              </div>
            </table-data>
            <table-data col="post_type">
              <label>
                {{ tr('Post type') }}
              </label>
              {{ post.type }}
            </table-data>
            <table-data col="media_type">
              <label>
                {{ tr('Media type') }}
              </label>
              {{ post.media_type }}
            </table-data>
            <table-data col="posted">
              <label>
                {{ tr('Posted') }}
              </label>
              <span v-tooltip="formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)">
                {{ timeAgo(post.created_at, tr) }}
              </span>
            </table-data>
          </table-row>
        </template>
      </sorted-table>
    </div>

    <div v-if="!isLoading && sourceProfileDetails.length !== 0" class="source-profile-informations-wrapper">
      <h3>{{ tr('Datasource informations') }}</h3>
      <div class="source-profile-informations">
        <div
          v-for="(sourceProfile, key) in sourceProfileDetails"
          :key="'source-profile-details--' + key"
          class="source-profile-informations--item"
        >
          <div class="source-profile-title">
            {{ sourceProfile.name }} - {{ ucfirst(sourceProfile.platform) }}

            <refresh-button
              :label="tr('Update posts')"
              :success-message="tr('Posts of :name updated successfully.', { ':name': sourceProfile.name })"
              :promise="() => refreshSourceProfile(sourceProfile.id)"
            />
          </div>

          <template v-if="sourceProfile.fetched_at">
            {{ tr('Data last updated at :time', { ':time': timeAgo(sourceProfile.fetched_at, tr) }) }}
          </template>
          <template v-else>
            {{ tr('The datasource has not been fetched yet.') }}
          </template>
          <br />

          {{ tr('Number of posts examined') }}:
          {{ tr('last :num pcs', { ':num': sourceProfile.post_limit }) }}
          <br />

          {{ tr('Latest known post date') }}:
          <template v-if="sourceProfile.latest_post_date">
            {{ formatToYMD(sourceProfile.latest_post_date) }}
            {{ formatTime(sourceProfile.latest_post_date) }}
          </template>
          <template v-else>
            -
          </template>
          <br />

          {{ tr('Oldest examined post date') }}:
          <template v-if="sourceProfile.oldest_post_date">
            {{ formatToYMD(sourceProfile.oldest_post_date) }}
            {{ formatTime(sourceProfile.oldest_post_date) }}
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyScreen from '@/components/EmptyScreen.vue';
import RefreshButton from '@/components/RefreshButton.vue';
import { RULE_REVIEW_CODE } from '@/consts.js';
import RuleService from '@/services/Rule.js';
import SourceProfileService from '@/services/SourceProfile.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import debounce from '@/utils/debounce.js';
import { ucfirst } from '@/utils/string';

export default {
  name: 'RulePreview',
  components: {
    EmptyScreen,
    RefreshButton,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
      sourceProfileDetailsIsLoading: false,
      sourceProfileDetails: [],
      debouncedGeneratePreview: debounce(() => this.generateNewPreview(), 1000),
      sizeKeeperHeight: 0,
    };
  },
  computed: {
    itemsCountText() {
      if (this.items.length < 2) {
        return this.tr(':num item', { ':num': this.items.length });
      }

      return this.tr(':num items', { ':num': this.items.length });
    },
  },
  watch: {
    'entity.source_profiles': {
      deep: true,
      handler() {
        this.debouncedGeneratePreview();
        this.getSourceProfileDetails();
      },
    },
    'entity.facebook_post_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.facebook_post_media_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.instagram_post_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.instagram_post_media_types'() {
      this.debouncedGeneratePreview();
    },
    'entity.output_campaign.objective'() {
      this.debouncedGeneratePreview();
    },
    'entity.conditions': {
      deep: true,
      handler() {
        this.debouncedGeneratePreview();
      },
    },
    'entity.settings.dont_create_expired_ads'() {
      this.debouncedGeneratePreview();
    },
  },
  mounted() {
    this.generateNewPreview();
    this.getSourceProfileDetails();
  },
  methods:{
    formatToYMD,
    formatTime,
    timeAgo,
    ucfirst,
    getSourceProfileDetails() {
      if (
        this.entity.source_profiles.length === 0
        || !this.entity.source_profiles[0].id
      ) {
        this.sourceProfileDetails = [];
        return;
      }

      this.sourceProfileDetailsIsLoading = true;

      SourceProfileService.get(this.entity.source_profiles[0].id)
        .then(resp => this.sourceProfileDetails = [ resp ])
        .catch(err => {
          this.error(err);
          this.sourceProfileDetails = [];
        })
        .finally(() => {
          this.sourceProfileDetailsIsLoading = false;
        });
    },
    generateNewPreview() {
      if (this.isLoading) {
        return;
      }

      const sourceProfileIds = this.entity.source_profiles.map(item => item.id).filter(item => !!item);

      if (
        sourceProfileIds.length === 0
        || this.entity.output_campaign.objective === null
        || !this.entity.isValidConditions
      ) {
        return;
      }

      if (this.$refs.content) {
        this.sizeKeeperHeight = this.$refs.content.offsetHeight;
      }

      this.isLoading = true;

      RuleService.generateActivityReview(this.entity)
        .then(resp => {
          this.items = resp
            .filter(item => item.code === RULE_REVIEW_CODE.WAITING_FOR_PROCESS)
            .map(item => item.entity);

          return resp;
        })
        .catch(err => {
          this.error(err);
          this.items = [];
        })
        .finally(() => {
          this.isLoading = false;

          this.$nextTick(() => {
            this.sizeKeeperHeight = this.$refs.content ? this.$refs.content.offsetHeight : 0;
          });
        });
    },
    refreshSourceProfile(id) {
      this.infoToast(this.tr('We will start updating the posts. Be patient, this may take up to a minute.'));

      return SourceProfileService.refresh(id)
        .then(resp => {
          this.generateNewPreview();
          return resp;
        });
    },
  },
};
</script>
