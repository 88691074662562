import axios from 'axios';

export default {
  getLatests() {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_WORDPRESS_URL,
      withCredentials: false,
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json',
      },
    });

    return instance.get('/wp-json/wp/v2/posts?per_page=3&_fields[]=id&_fields[]=link&_fields[]=date_gmt&_fields[]=title');
  },
};
