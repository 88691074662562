<template>
  <div class="magic-tool-view view">
    <sticky-header
      :title="title"
      :info="info"
      bordered
    />

    <div class="formblock">
      <div class="formtitle">
        <div class="main">
          <span>{{ tr('Analyze your ad copy') }}</span>
        </div>
      </div>
      <div class="description">
        {{ tr('Insert your ad text and wait for the system to evaluate it. The system evaluates your ad based on several aspects (creativity, attention-grabbing, etc.), and gives a short assessment for each aspect. In addition, you can also create a new version of your ad text generated by the system.') }}
      </div>
    </div>

    <div class="form-with-preview">
      <magic-tool-form
        ref="form"
        @image-uploaded="(image) => onImageUploaded(image)"
        @entity-created="(entity) => onEntityCreated(entity)"
      />
      <facebook-ad-preview
        :text="$refs.form?.text || null"
        :cta-title="$refs.form?.title || null"
        :cta-description="$refs.form?.description || null"
        :cta-button="previewCtaButton"
        :img-url="imageUrl"
      />
    </div>
    <div ref="scrollHereAfterSubmit" class="divider" />
    <empty-screen
      v-if="!isLoading && list.length === 0"
      icon="magic"
      :title="tr('Let our AI analyze and improve your ad copy')"
      :flag-text="tr('Start analyzing')"
      :description="tr('Enter your advertising text above, and our AI will evaluate it based on creativity, engagement potential, and other key metrics. Get instant feedback to optimize your ad performance before going live.')"
    />
    <template v-else>
      <h3>{{ tr('Completed evaluations') }}</h3>
      <sorted-table :data="list" class="row-hover-shadow">
        <template #header>
          <table-head col="post" min-width="100px">{{ tr('Content') }}</table-head>
          <table-head col="ai-score" width="100px" class="centered-text">{{ tr('AI Score') }}</table-head>
          <table-head col="generated" width="110px">{{ tr('Generated') }}</table-head>
          <table-head col="expiry-date" width="110px">{{ tr('Expiry date') }}</table-head>
          <table-head col="policy-check" width="100px" class="centered-text">{{ tr('Policy check') }}</table-head>
          <table-head col="navigation-arrow" width="30px" class="centered-text"></table-head>
        </template>
        <template #default="{ sort }">
          <table-row
            v-for="item in sort"
            :key="item.id"
          >
            <table-data col="post" class="post-content">
              <router-link :to="'/magic-tool/' + item.id + '/original'">
                <inline-social-content :entity="item" />
              </router-link>
            </table-data>
            <table-data col="ai-score" class="col-center ai-score-cell">
              <label>{{ tr('AI Score') }}</label>
              <ai-score :value="item.value" />
            </table-data>
            <table-data col="generated" class="generated-cell formatted-date">
              <label>{{ tr('Generated') }}</label>
              <div>{{ formatToYMD(item.created_at)}}</div>
              <div class="formatted-date--time">{{ formatTime(item.created_at) }}</div>
            </table-data>
            <table-data col="expiry-date" class="expiry-date-cell formatted-date">
              <skeleton v-if="item.contentActualityCheckInProgress" width="80px" height="16px" />
              <div v-else-if="item.expiry_date">
                <label>{{ tr('Expiry date') }}</label>
                <div>{{ formatToYMD(item.expiry_date)}}</div>
                <div class="formatted-date--time">{{ formatTime(item.expiry_date) }}</div>
              </div>
              <template v-else>
                <label>{{ tr('Expiry date') }}</label>
                -
              </template>
            </table-data>
            <table-data col="policy-check" class="policy-check-cell">
              <label v-if="!item.policyCheckInProgress">{{ tr('Policy check') }}</label>
              <skeleton v-if="item.policyCheckInProgress" width="50px" height="16px" />
              <post-content-policy-check-status v-else :value="item.policy_check_is_prohibited" />
            </table-data>
            <table-data col="navigation-arrow" class="navigation-arrow-cell">
              <navigation-arrow :link="'/magic-tool/' + item.id + '/original'" />
            </table-data>
          </table-row>

          <template v-if="isLoading || hasMore">
            <table-row
              v-for="i in [ 1, 2 ]"
              :key="'magic-tool-loader-skeleton-' + i"
              ref="loadMore"
              class="skeleton-row"
            >
              <table-data col="post" class="post-content">
                <inline-social-content />
              </table-data>
              <table-data col="ai-score" class="ai-score-cell col-center">
                <skeleton width="32px" height="28px" />
              </table-data>
              <table-data col="requested" class="generated-cell">
                <skeleton width="100px" height="16px" />
              </table-data>
              <table-data col="expiry-date" class="expiry-date-cell">
                <skeleton width="80px" height="16px" />
              </table-data>
              <table-data col="policy-check" class="policy-check-cell">
                <skeleton width="50px" height="16px" />
              </table-data>
              <table-data col="navigation-arrow" class="navigation-arrow-cell" />
            </table-row>
          </template>
        </template>
      </sorted-table>
    </template>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import FacebookAdPreview from '@/components/FacebookAdPreview.vue';
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import MagicToolForm from '@/components/MagicToolForm.vue';
import MagicToolItemDetails from '@/components/ModalContents/MagicToolItemDetails.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import PostContentPolicyCheckStatus from '@/components/PostContentPolicyCheckStatus.vue';
import Skeleton from '@/components/Skeleton.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import MagicToolService from '@/services/MagicTool.js';
import { formatTime, formatToYMD } from '@/utils/date';

const ITEM_PER_PAGE = 20;

export default {
  name: 'MagicToolView',
  components: {
    AiScore,
    EmptyScreen,
    FacebookAdPreview,
    InlineSocialContent,
    MagicToolForm,
    NavigationArrow,
    PostContentPolicyCheckStatus,
    Skeleton,
    StickyHeader,
  },
  data: () => ({
    isMounted: false,
    isLoading: false,
    hasMore: true,
    page: 0,
    list: [],
    scrollPagerObserver: null,
    fetchTimers: {},
    imageUrl: null,
  }),
  computed: {
    title() {
      return this.tr('Magic Tool');
    },
    info() {
      return this.tr('The <b class="primary">Infinite∞Ai</b> evaluates your posts based on 5 criteria and provides suggestions for the text to make them as sought after as possible. 🧠🤖');
    },
    showPreview() {
      if (!this.isMounted) {
        return null;
      }

      return this.$refs.form.text || this.$refs.form.title || this.$refs.form.description;
    },
    previewCtaButton() {
      if (!this.isMounted) {
        return null;
      }

      switch (this.$refs.form.goal) {
        case 'conversion':
          return this.tr('Shop now');

        case 'traffic':
          return this.tr('Learn more');

        case 'app_promotion':
          return this.tr('Use App');
      }

      return null;
    },
    deletedAdCopyMagicItem() {
      return this.$store.getters['magicTool/deletedAdCopyMagicItem'];
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.openPostModal();
        }
      },
    },
    deletedAdCopyMagicItem: {
      immediate: true,
      handler(adCopyMagicItem) {
        if (adCopyMagicItem) {
          this.onEntityDeleted(adCopyMagicItem);
          this.$store.commit('magicTool/setDeletedAdCopyMagicItem', false);
        }
      },
    },
    '$fullscreenModal.isVisible'(newVal) {
      if (!newVal) {
        this.$router.replace('/magic-tool');
      }
    },
  },
  created() {
    this.loadNewData();
  },
  mounted() {
    this.isMounted = true;
  },
  beforeDestroy() {
    this.destroyAllFetchTimers();
  },
  methods: {
    formatTime,
    formatToYMD,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;

      ++this.page;

      MagicToolService.getList({ page: this.page, limit: ITEM_PER_PAGE })
        .then(resp => {
          resp.forEach(entity => {
            this.list.push(entity);
            if (entity.hasInProgressParts) {
              this.initFetchTimer(entity.id);
            }
          });

          this.hasMore = resp.length === ITEM_PER_PAGE;

          this.reinitScrollPager();

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    reinitScrollPager() {
      this.disconnectScrollPager();

      if (!this.hasMore) {
        return;
      }

      this.scrollPagerObserver = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.disconnectScrollPager();
          this.loadNewData();
        }
      });

      if (this.$refs.loadMore[0]) {
        this.scrollPagerObserver.observe(this.$refs.loadMore[0].$el);
      }
    },
    disconnectScrollPager() {
      if (this.scrollPagerObserver !== null) {
        this.scrollPagerObserver.disconnect();
        this.scrollPagerObserver = null;
      }
    },
    onEntityCreated(entity) {
      if (this.list.length === 10) {
        this.list.pop();
      }
      this.list.unshift(entity);

      this.$nextTick(() => {
        this.$refs.scrollHereAfterSubmit.scrollIntoView({ behavior: 'smooth' });
      });

      this.initFetchTimer(entity.id);
    },
    onEntityDeleted(entity) {
      this.list = this.list.filter(i => i.id !== entity.id);
    },
    initFetchTimer(id) {
      if (id in this.fetchTimers) {
        return;
      }

      this.fetchTimers[id] = setInterval(() => {
        MagicToolService.get(id)
          .then(entity => {
            const index = this.list.findIndex(i => i.id === id);
            if (index !== -1) {
              this.list.splice(index, 1, entity);
            }

            if (!entity.hasInProgressParts) {
              clearInterval(this.fetchTimers[id]);
              delete this.fetchTimers[id];
            }

            return entity;
          })
          .catch(err => {
            clearInterval(this.fetchTimers[id]);
            delete this.fetchTimers[id];
            this.error(err);
          });
      }, 10000);
    },
    destroyAllFetchTimers() {
      Object.keys(this.fetchTimers).forEach(id => {
        clearInterval(this.fetchTimers[id]);
        delete this.fetchTimers[id];
      });
    },
    onImageUploaded(image) {
      if(image) {
        this.imageUrl = URL.createObjectURL(image);
      }
      else {
        this.imageUrl = null;
      }
    },
    openPostModal() {
      const modal = this.$fullscreenModal.open(
        this,
        MagicToolItemDetails,
        {},
        {
          classes: 'magic-tool-item-details-modal magic-tool-item-details-modal--active',
        },
      );

      if (modal) {
        modal.$on('closed', () => {
          if (this.$route.path !== '/magic-tool') {
            this.$router.push('/magic-tool');
          }
        });
      }
    },
  },
};
</script>
