<template>
  <div class="auth-form-view forgotten-password-view">
    <div class="form">
      <div class="input-label">
        <span>{{ tr('E-mail address') }}</span>
      </div>
      <input
        v-model="email"
        type="email"
        :placeholder="tr('you@gmail.com')"
        @keydown.enter="() => submit()"
      />

      <button class="primary" @click="() => submit()">
        <i v-if="isLoading" class="icon loader white" />
        <template v-else> {{ tr('Send') }} </template>
      </button>
    </div>
  </div>
</template>

<script>
import { UnauthorizedError } from '@/errors';
import { isValidEmail } from '@/utils/validators';

import AuthService from '@/services/Auth.js';

export default {
  name: 'ForgottenPassword',
  data() {
    return {
      isLoading: false,
      email: '',
    };
  },
  methods: {
    resetForm() {
      this.email = '';
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      AuthService.forgottenPassword(this.email)
        .then(() => {
          this.resetForm();
          this.$toast.success(this.tr('The link to reset the password has been sent to the specified email address.'));
        })
        .catch((err) => {
          if (err instanceof UnauthorizedError) {
            this.$toast.error(this.tr('Invalid e-mail given!'));
          }
          else {
            this.error(err);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      this.$toast.clear();

      if (!this.email) {
        this.$toast.error(this.tr('E-mail cannot be empty!'));
        return false;
      }
      if (!isValidEmail(this.email)) {
        this.$toast.error(this.tr('E-mail provided has an incorrect format!'));
        return false;
      }

      return true;
    },
  },
};
</script>
