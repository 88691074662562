<template>
  <div class="rule-report-view view">
    <div v-if="!$parent.rule" class="loader">
      <i class="icon loader black" />
    </div>
    <report-view
      v-else
      :ad-account-id="$parent.rule.ad_account.id"
      :campaign-id="$parent.rule.output_campaign.id"
      :action="action"
      :period="period"
    />
  </div>
</template>

<script>
import ReportView from '@/views/ReportView.vue';

export default {
  name: 'RuleReportView',
  components: {
    ReportView,
  },
  computed: {
    action() {
      let action = null;
      switch (this.$parent.rule.output_campaign.objective) {
        case 'TRAFFIC':
        case 'OUTCOME_TRAFFIC':
          action = 'landing_page_view';
          break;

        case 'POST_ENGAGEMENT':
        case 'OUTCOME_ENGAGEMENT':
          action = 'post_engagement';
          break;

        case 'CONVERSIONS':
        case 'OUTCOME_SALES':
          if (this.$parent.rule.conversion_event === 'PURCHASE') {
            action = 'omni_purchase';
          }
          else if (this.$parent.rule.conversion_event) {
            action = this.$parent.rule.conversion_event.toLowerCase() + '_total';
          }
          break;

        default:
          if (this.$parent.rule.conversion_event) {
            action = this.$parent.rule.conversion_event.toLowerCase() + '_total';
          }
      }

      return action;
    },
    period() {
      const yesterdayMidnight = new Date();
      yesterdayMidnight.setDate(yesterdayMidnight.getDate() - 1);
      yesterdayMidnight.setHours(0, 0, 0, 0);
      if (this.$parent.rule.created_at >= yesterdayMidnight) {
        return 'today_and_yesterday';
      }

      return 'last_30d';
    },
  },
};
</script>
