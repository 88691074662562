var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "disable-google-2fa-component" },
    [
      _c("google-2fa-code-form", {
        attrs: {
          title: _vm.tr("Disable Google 2FA"),
          "is-loading": _vm.isSaving,
          "confirm-button-class": "red",
        },
        on: { submit: () => _vm.confirm(), cancel: () => _vm.cancel() },
        model: {
          value: _vm.code,
          callback: function ($$v) {
            _vm.code = $$v
          },
          expression: "code",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }