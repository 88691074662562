<template>
  <div
    :class="wrapperClasses"
    @click="($event) => floatingSidebarAutoCloseHandler($event)"
  >
    <open-in-browser
      v-if="showOpenAppInBrowserScreen"
      :is-ios="isIos"
      @open="() => openInBrowser()"
    />
    <template v-else>
      <div v-if="$root.isSupportMode" class="support-mode-bar">
        <template v-if="!$root.loggedInUser">
          {{ tr('Support mode') }}
        </template>
        <template v-else>
          {{ tr('Company') }}:

          <template v-if="$root.currentCompany.name">
            {{ $root.currentCompany.name }}
          </template>
          <i v-else>{{ tr('unnamed company') }}</i>
        </template>
      </div>

      <sidebar
        v-if="isSidebarVisible"
        ref="sidebar"
        :floating="floatingSidebar"
        @click.native="($event) => floatingSidebarAutoCloseHandler($event)"
      />
      <div class="router-frame">
        <div v-if="isLoading" class="loader">
          <i />
        </div>
        <router-view v-else />
      </div>
    </template>
  </div>
</template>

<script>
import OpenInBrowser from '@/components/OpenInBrowser.vue';
import Sidebar from '@/components/Sidebar.vue';
import UserService from '@/services/User';

export default {
  name: 'App',
  components: {
    Sidebar,
    OpenInBrowser,
  },
  computed: {
    userAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    isInAppBrowser() {
      return this.userAgent.indexOf('FBAN') != -1 || this.userAgent.indexOf('FBAV') !== -1;
    },
    isAndroid() {
      return this.userAgent.indexOf('Android') !== -1;
    },
    isIos() {
      return this.userAgent.indexOf('iPhone') !== -1 || this.userAgent.indexOf('iPad') !== -1;
    },
    showOpenAppInBrowserScreen() {
      return this.isInAppBrowser;
    },
    language() {
      return this.$store.getters['app/language'];
    },
    isLoading() {
      if (this.$root.isSupportMode && this.$store.getters['support/isFetching']) {
        return true;
      }

      return this.$store.getters['app/isLoading'] || this.$root.isUserFetching;
    },
    wrapperClasses() {
      const classes = {
        'main-content': true,
        'floating-sidebar': this.floatingSidebar,
        'sidebar-open': this.isSidebarVisible && !this.sidebarClosed,
        'sidebar-closed': this.isSidebarVisible && this.sidebarClosed,
        'without-sidebar': !this.isSidebarVisible,
        'support-mode': this.$root.isSupportMode,
      };

      classes['lang-' + this.language] = true;

      return classes;
    },
    isSidebarVisible() {
      return this.$root.isLoggedIn
        && !this.$route.path.startsWith('/auth')
        && this.$route.path !== '/enable-2fa';
    },
    sidebarClosed() {
      return this.$store.getters['app/sidebarClosed'];
    },
    floatingSidebar() {
      return this.$root.screenWidth <= 1180;
    },
    isSharedReportView() {
      return this.$route.name === 'SharedReport';
    },
  },
  watch: {
    '$root.loggedInUser'() {
      this.rebootIntercom();
    },
    language() {
      this.rebootIntercom();
      if (this.$root.isLoggedIn) {
        UserService.changeLanguage(this.$root.loggedInUser.id, this.language);
      }
    },
    '$route.path'() {
      this.$nextTick(() => {
        // Pageview.
        if (window.gtag) {
          if (this.$app.isBuzz) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-11432616777/DaGLCNOC4vwYEMnGv8sq' });
          }
          else if (this.$app.isAd) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-11565729405/Yo9JCMuK6YwaEP2M_Ior' });
          }
        }
      });
    },
  },
  created() {
    if (this.showOpenAppInBrowserScreen) {
      this.openInBrowser();
      return;
    }

    this.$store.dispatch('app/fetch');

    if (this.$root.isSupportMode && this.$store.getters['support/hasToken']) {
      this.$root.fetchSupportUserData();
    }

    if (this.$store.getters['auth/hasToken']) {
      this.$root.fetchUserData().catch(err => this.error(err));
    }
    else {
      this.$store.commit('app/isLoading', false);
    }

    this.rebootIntercom();

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      document.body.classList.add('is-safari');

      this.initSafariTableBackgroundFixer();
    }

    // eslint-disable-next-line no-console
    console.log('\n\n   88888888888888888888888888\n 888888888888888888888888888888\n88888888888888888888888888888888\n88888888888888888888888888888888\n8888888         8888888888888888\n888888    888    888888888888888\n888888     888           8888888\n88888888          8888    888888\n888888888888888     888   888888\n88888888888888888        8888888\n88888888888888888888888888888888\n 888888888888888888888888888888\n   88888888888888888888888888\n\n\n');
  },
  mounted() {
    this.$nextTick(() => {
      if (window.gtag) {
        if (this.$app.isBuzz) {
          window.gtag('event', 'conversion', { 'send_to': 'AW-11432616777/DaGLCNOC4vwYEMnGv8sq' });
        }
      }
    });
  },
  methods: {
    openInBrowser() {
      if (this.isAndroid) {
        window.location = 'intent:' + window.location + '#Intent;end';
      }
    },
    rebootIntercom() {
      if (!window.Intercom) {
        return;
      }

      // eslint-disable-next-line new-cap
      window.Intercom('shutdown');

      let intercomParams = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'gq5d2yir',
        language_override: this.language,
      };

      if (this.$root.loggedInUser) {
        intercomParams = Object.assign(intercomParams, {
          email: this.$root.loggedInUser.email,
          name: this.$root.loggedInUser.fullname,
          user_id: this.$root.loggedInUser.id,
          created_at: Math.floor((new Date(this.$root.loggedInUser.created_at)).getTime() / 1000),
          company: {
            id: this.$root.loggedInUser.company.id,
            name: this.$root.loggedInUser.company.name ?? this.$root.loggedInUser.company.billing_company,
            plan: this.$root.loggedInUser.company.current_subscription ? this.$root.loggedInUser.company.current_subscription.package?.name : '-',
            created_at: Math.floor((new Date(this.$root.loggedInUser.company.created_at)).getTime() / 1000),
          },
        });

        if (this.$app.isAd) {
          intercomParams['Support Login'] = 'https://support.infinite.ad/?user_id=' + this.$root.loggedInUser.id;
        }
      }

      // eslint-disable-next-line new-cap
      window.Intercom('boot', intercomParams);
    },
    floatingSidebarAutoCloseHandler(e) {
      if (this.floatingSidebar && this.$refs['sidebar']) {
        const clickedToLanguageSelector = !!e.target.closest('.language-selector-component');
        const clickedToCompanySelector = !!e.target.closest('.company-selector-component');
        const clickedToToggleButton = !!e.target.closest('.toggle-button');

        if (!clickedToLanguageSelector && !clickedToCompanySelector && !clickedToToggleButton) {
          this.$refs['sidebar'].close();
        }
      }
    },
    initSafariTableBackgroundFixer() {
      const fixSingleRow = row => {
        const bg = row.querySelector('td.background');
        if (!bg) {
          return;
        }

        bg.style.top = 'auto';
        bg.style.bottom = 'unset';
        bg.style.height = row.offsetHeight + 'px';
      };

      const fixAllRows = parent => {
        if (!parent) {
          parent = document;
        }

        const tableRows = parent.querySelectorAll('.table tbody tr');
        tableRows.forEach(row => fixSingleRow(row));
      };

      setInterval(() => fixAllRows(), 250);

      document.addEventListener('DOMNodeInserted', e => {
        if (!e.target.tagName) {
          return;
        }

        if (e.target.tagName === 'TR') {
          fixSingleRow(e.target);
        }
        else {
          fixAllRows(e.target);
        }
      });
    },
  },
};
</script>
