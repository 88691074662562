<template>
  <div class="rule-form-step rule-form-step--ai-mode rule-form-step--ai-mode--step-1">
    <form-block
      :title="tr('Your goal')"
      :description="tr('Depending on what you want to achieve with your ad, different settings will be the most optimal for running ads. By defining the goal, Infinite∞Ad will make these settings automatically.')"
      class="goal-block"
    >
      <div class="checkbox-list horizontal">
        <label class="radio large">
          <input
            v-model="goal"
            type="radio"
            name="goal"
            value="traffic"
          />
          <i class="icon primary traffic"></i>
          <span class="radio--title">
            {{ tr('I want to drive traffic to my website') }}
          </span>
          {{ tr('You have a website and want to attract visitors using ads') }}
        </label>
        <label class="radio large">
          <input
            v-model="goal"
            type="radio"
            name="goal"
            value="conversion"
          />
          <i class="icon primary conversion"></i>
          <span class="radio--title">
            {{ tr('I want to increase my sales') }}
          </span>
          {{ tr('You have a webshop or sell products/services online') }}
        </label>
        <label class="radio large">
          <input
            v-model="goal"
            type="radio"
            name="goal"
            value="app_promotion"
          />
          <i class="icon primary promotion"></i>
          <span class="radio--title">
            {{ tr('I want to promote my app') }}
          </span>
          {{ tr('You have an app and want it to achieve better results') }}
        </label>
        <label class="radio large">
          <input
            v-model="goal"
            type="radio"
            name="goal"
            value="engagement"
          />
          <i class="icon primary engagement"></i>
          <span class="radio--title">
            {{ tr('I want more reach and engagement on my posts') }}
          </span>
          {{ tr('You want your Facebook or Instagram posts to get more reactions and reach') }}
        </label>
        <label class="radio large">
          <input
            v-model="goal"
            type="radio"
            name="goal"
            value="page_like"
          />
          <i class="icon primary pagelike"></i>
          <span class="radio--title">
            {{ tr('I want to increase my number of Facebook followers') }}
          </span>
          {{ tr('You have a Facebook page and want to gain more followers with your content') }}
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <ad-account-block
      :entity="entity"
      :description="tr('You can choose the ad account in which you want to launch your advertisement. (This is useful if you manage multiple accounts.)')"
    />

    <conversion-odax-block
      v-if="conversionEventSettingsAvailable"
      :entity="entity"
      :disabled="$parent.readonlyMode"
      minimal
    />

    <div class="divider" />

    <source-block :entity="entity" :disabled="$parent.readonlyMode" single />

    <form-block v-if="ctaUrlIsAvailable">
      <div>
        <label class="input-label">
          {{ ctaUrlLabel }}
        </label>
        <input
          v-model="entity.settings.cta_url"
          type="text"
          :placeholder="tr('Enter the URL you want to promote')"
          @blur="() => onWebsiteInputBlur()"
        />
        <small>
          {{ tr('If the post contains at least one link, we will use it as the Button URL to create the ad. In this case, the URL provided here will only be used if the post does not contain any links.') }}
        </small>
      </div>
    </form-block>

    <div class="divider" />

    <activity-management-block
      :entity="entity"
      :disabled="$parent.readonlyMode"
      :disable-block-in-ai-mode="true"
    />

    <div class="divider" />

    <form-block
      v-if="goal !== 'engagement'"
      class="ai-assist-block"
      :title="tr('AI Assist') + '<div class=\'beta-flag\'>Beta</div>'"
    >
      <div class="checkbox-list vertical">
        <label>
          <input
            v-model="aiAssist"
            type="checkbox"
            :value="AI_ASSIST_OPTIONS.CREATE_CREATIVES"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Create Ai creatives') }}

          <info-popup>
            <div v-html="tr('When Infinite∞Ad creates ad variations, it generates new ads instead of boosting your original post. This means engagement will appear on the ads, not on your original post.')" />
          </info-popup>
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <campaign-budget-block
      ref="campaignBudgetBlock"
      :entity="entity"
      :title="tr('Daily budget')"
      :description="tr('You can determine the maximum daily budget that you allocate for advertising.')"
      :switchable="false"
      minimal
    />

    <form-block
      v-if="scalingBudgetIsAvailable"
      :title="tr('Daily budget for high-performing ads') + ' (' + tr('optional') + ')'"
      :description="tr('You can determine the maximum daily budget that you would allocate to those ads that have already proven to perform well.')"
    >
      <div>
        <money-input
          v-model="scalingBudget"
          :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : 'EUR'"
        />

        <info-text v-if="invalidScalingBudget" type="error">
          {{ invalidScalingBudgetInfoText }}
        </info-text>
        <info-text v-else-if="entity.adAccountCurrency">
          {{ tr('Minimum daily budget') }}:
          {{ entity.adAccountCurrency.symbol }} {{ formatNumber(entity.adAccountMinDailyBugdet) }}
        </info-text>

        <info-text v-if="availableScaledAdsFromBudget > 0">
          <template v-if="availableScaledAdsFromBudget !== 1">
            {{ tr('With this budget, you will be able to run :num ads simultaneously.', { ':num': availableScaledAdsFromBudget }) }}
          </template>
          <template v-else>
            {{ tr('With this budget, you will be able to run 1 ad simultaneously.') }}
          </template>
        </info-text>
      </div>
    </form-block>

    <div class="divider" />

    <dsa-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <div>
      <demographic-target-block
        :title="tr('Target audience')"
        :entity="entity"
        :disabled="$parent.readonlyMode"
      />

      <label class="input-label geotarget-label">{{ tr('Geotargeting') }}</label>
      <geotarget-block
        :entity="entity"
        :disabled="$parent.readonlyMode"
        title=""
      />
    </div>

    <div class="divider" />

    <campaign-special-ad-categories-block :entity="entity" :disabled="!entity.isNew" />
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import InfoPopup from '@/components/InfoPopup.vue';
import InfoText from '@/components/InfoText.vue';
import MoneyInput from '@/components/MoneyInput.vue';
import ActivityManagementBlock from '@/components/RuleForm/Blocks/ActivityManagementBlock.vue';
import AdAccountBlock from '@/components/RuleForm/Blocks/AdAccountBlock.vue';
import CampaignBudgetBlock from '@/components/RuleForm/Blocks/CampaignBudgetBlock.vue';
import CampaignSpecialAdCategoriesBlock from '@/components/RuleForm/Blocks/CampaignSpecialAdCategoriesBlock.vue';
import ConversionOdaxBlock from '@/components/RuleForm/Blocks/ConversionOdaxBlock.vue';
import DemographicTargetBlock from '@/components/RuleForm/Blocks/DemographicTargetBlock.vue';
import DsaBlock from '@/components/RuleForm/Blocks/DsaBlock.vue';
import GeotargetBlock from '@/components/RuleForm/Blocks/GeotargetBlock.vue';
import SourceBlock from '@/components/RuleForm/Blocks/SourceBlock.vue';
import { AI_ASSIST_OPTIONS } from '@/consts';
import FacebookTargeting from '@/descriptors/FacebookTargeting';
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';
import { formatNumber, formatPrice } from '@/utils/number.js';
import { isValidURL } from '@/utils/validators';

export default {
  name: 'AiModeRuleFormStep1',
  components: {
    ActivityManagementBlock,
    AdAccountBlock,
    CampaignBudgetBlock,
    CampaignSpecialAdCategoriesBlock,
    ConversionOdaxBlock,
    DemographicTargetBlock,
    DsaBlock,
    GeotargetBlock,
    SourceBlock,
    FormBlock,
    InfoText,
    MoneyInput,
    InfoPopup,
  },
  mixins: [ RuleFormStepMixin ],
  data: () => ({
    AI_ASSIST_OPTIONS,
  }),
  computed: {
    goal: {
      get() {
        return this.$store.getters['ruleForm/goal'];
      },
      set(value) {
        this.$store.commit('ruleForm/setGoal', value);
      },
    },
    aiAssist: {
      get() {
        return this.$store.getters['ruleForm/aiAssist'];
      },
      set(value) {
        this.$store.commit('ruleForm/setAiAssist', value);
      },
    },
    budget: {
      get() {
        return this.$store.getters['ruleForm/budget'];
      },
      set(value) {
        this.$store.commit('ruleForm/setBudget', value);
      },
    },
    scalingBudget: {
      get() {
        return this.$store.getters['ruleForm/scalingBudget'];
      },
      set(value) {
        this.$store.commit('ruleForm/setScalingBudget', value);
      },
    },
    conversionEventSettingsAvailable() {
      return this.goal === 'conversion' || this.goal === 'app_promotion';
    },
    scalingBudgetIsAvailable() {
      return this.goal === 'conversion' || this.goal === 'traffic';
    },
    invalidScalingBudget() {
      return this.scalingBudget && this.scalingBudget < this.entity.adAccountMinDailyBugdet;
    },
    invalidScalingBudgetInfoText() {
      return this.tr('Budget must be more than <span class="budget">:value</span>!', { ':value' : formatPrice(this.entity.adAccountMinDailyBugdet, this.entity.adAccountCurrency.symbol) });
    },
    availableScaledAdsFromBudget() {
      if (this.scalingBudget >= this.entity.adAccountMinDailyBugdet) {
        return Math.floor(this.scalingBudget / this.entity.adAccountMinDailyBugdet);
      }

      return null;
    },
    ctaUrlIsAvailable() {
      return this.ctaUrlLabel !== null && this.entity.ad_account.id !== null;
    },
    ctaUrlLabel() {
      switch (this.goal) {
        case 'conversion':
          return this.tr('Product or service sales link');

        case 'traffic':
          return this.tr('Website link');

        default:
          return null;
      }
    },
  },
  watch: {
    goal(val) {
      this.entity.settings.cta_url = null;

      switch (val) {
        case 'conversion':
          this.entity.output_campaign.objective = 'OUTCOME_SALES';
          break;

        case 'traffic':
          this.entity.output_campaign.objective = 'OUTCOME_TRAFFIC';
          break;

        case 'app_promotion':
          this.entity.output_campaign.objective = 'OUTCOME_APP_PROMOTION';
          break;

        case 'engagement':
        case 'page_like':
          this.entity.output_campaign.objective = 'OUTCOME_ENGAGEMENT';
          break;
      }
    },
    conversionEventSettingsAvailable(val) {
      if (!val) {
        this.entity.settings.adset_conversion_app_id = null;
        this.entity.settings.adset_conversion_app_store_url = null;
        this.entity.settings.adset_conversion_pixel_id = null;
        this.entity.settings.adset_conversion_event = null;
      }
    },
    scalingBudgetIsAvailable(val) {
      if (!val) {
        this.scalingBudget = null;
      }
    },
    'entity.source_profiles': {
      deep: true,
      handler(val) {
        if (!val || !val[0] || !val[0].id) {
          return;
        }

        this.entity.settings.adset_targeting = new FacebookTargeting(val[0].targeting);

        if (this.ctaUrlIsAvailable) {
          this.entity.settings.cta_url = val[0].website;
        }
      },
    },
    ctaUrlIsAvailable(val) {
      if (!val) {
        this.entity.settings.cta_url = null;
      }
      else if (
        this.entity.source_profiles.length > 0
        && this.entity.source_profiles[0].website
      ) {
        this.entity.settings.cta_url = this.entity.source_profiles[0].website;
      }
    },
  },
  created() {
    this.entity.dsa_beneficiary = this.$root.currentCompany.billing_company;
    if (!this.entity.dsa_beneficiary) {
      this.entity.dsa_beneficiary = this.$root.currentCompany.name;
    }
  },
  methods: {
    formatNumber,
    validate() {
      if (!this.goal) {
        this.error(this.tr('Defining the goal is mandatory.'));
        return false;
      }

      if (!this.validateBlocks()) {
        return false;
      }

      if (this.ctaUrlIsAvailable && !isValidURL(this.entity.settings.cta_url)) {
        this.error(this.tr('Invalid call to action URL.'));
        return false;
      }

      if (this.invalidScalingBudget) {
        this.error(this.invalidScalingBudgetInfoText);
        return false;
      }

      return true;
    },
    onWebsiteInputBlur() {
      if (
        this.entity.settings.cta_url
        && isValidURL(this.entity.settings.cta_url)
        && this.entity.settings.cta_url.toLowerCase().indexOf('http') !== 0
      ) {
        this.entity.settings.cta_url = 'https://' + this.entity.settings.cta_url;
      }
    },
  },
};
</script>
