import BaseEntity from '@/entities/BaseEntity';

import { AI_STATUS } from '@/consts';

export default class AdCopyMagicItem extends BaseEntity
{
  improved_text = '';
  value = null;
  status = null;
  details = null;
  created_at = new Date();

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get inProgress() {
    return this.status === AI_STATUS.WAITING || this.status === AI_STATUS.IN_PROGRESS;
  }

  get isDone() {
    return this.status === AI_STATUS.DONE;
  }
}
