var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: "condition-group-wrapper--level-" + _vm.level }, [
    _vm.value && _vm.value.items
      ? _c(
          "div",
          { class: _vm.wrapperClasses },
          [
            _c("condition-connector", {
              attrs: {
                "item-count": _vm.value.items.length,
                "allow-add-new-group": _vm.level < 4,
                disabled: _vm.disabled,
              },
              on: {
                "open-dropdown": () => _vm.onOpenDropdown(),
                "close-dropdown": () => _vm.onCloseDropdown(),
                "add-item": _vm.addItem,
                "add-group": _vm.addGroup,
              },
              model: {
                value: _vm.value.logic,
                callback: function ($$v) {
                  _vm.$set(_vm.value, "logic", $$v)
                },
                expression: "value.logic",
              },
            }),
            _c(
              "div",
              { staticClass: "items" },
              [
                _vm._l(_vm.value.items, function (item, key) {
                  return [
                    !!item.logic
                      ? _c("condition-group", {
                          key: "level-" + _vm.level + "-item-" + key + "-first",
                          attrs: {
                            level: _vm.level + 1,
                            disabled: _vm.disabled,
                            action: _vm.action,
                            platforms: _vm.platforms,
                            "ad-account": _vm.adAccount,
                          },
                          on: {
                            "open-dropdown": () => _vm.onOpenDropdown(),
                            "close-dropdown": () => _vm.onCloseDropdown(),
                          },
                          model: {
                            value: _vm.value.items[key],
                            callback: function ($$v) {
                              _vm.$set(_vm.value.items, key, $$v)
                            },
                            expression: "value.items[key]",
                          },
                        })
                      : _c("condition-row", {
                          key:
                            "level-" + _vm.level + "-item-" + key + "-second",
                          attrs: {
                            action: _vm.action,
                            platforms: _vm.platforms,
                            "ad-account": _vm.adAccount,
                            removable:
                              _vm.level !== 1 || _vm.value.items.length !== 1,
                            disabled: _vm.disabled,
                            subfields:
                              item.field in _vm.subfields
                                ? _vm.subfields[item.field]
                                : [],
                            loadingSubfields:
                              _vm.loadingSubfields === item.field ||
                              !(item.field in _vm.subfields),
                          },
                          on: {
                            loadSubfields: () => _vm.loadSubfields(item.field),
                            remove: () => _vm.removeItem(key),
                          },
                          model: {
                            value: _vm.value.items[key],
                            callback: function ($$v) {
                              _vm.$set(_vm.value.items, key, $$v)
                            },
                            expression: "value.items[key]",
                          },
                        }),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }