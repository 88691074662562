import AdMetric from '@/entities/AdMetric';
import Adset from '@/entities/Adset';
import BaseEntity from '@/entities/BaseEntity';
import Campaign from '@/entities/Campaign';
import Post from '@/entities/Post';

export default class Ad extends BaseEntity {
  _entityFields = {
    campaign: Campaign,
    adset: Adset,
    post: Post,
    metric: AdMetric,
  };

  _entityArrayFields = {
    metrics: AdMetric,
  };

  id = '';
  platform = '';
  external_id = '';
  name = '';
  campaign = null;
  adset = null;
  post = null;
  status = null;
  effective_status = null;
  metric = null;
  metrics = [];
  created_at = null;
  deleted_at = null;
  text = '';
  variation = null;
  created_with_ai = false;
  url = '';

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Deleted] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }
}
