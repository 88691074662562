<template>
  <div class="rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-4">
    <form-block
      :title="tr('Manage original ad')"
      :description="tr('You can set whether the scaled-up original ad should continue running or stop automatically.')"
    >
      <div class="checkbox-list vertical">
        <label>
          <input
            v-model="entity.settings.stop_original"
            type="radio"
            name="keep-or-stop"
            :value="false"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Keep original ad running') }}
        </label>

        <label>
          <input
            v-model="entity.settings.stop_original"
            type="radio"
            name="keep-or-stop"
            :value="true"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Stop original ad automatically') }}
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <ad-creation-method-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <cta-block
      v-if="entity.settings.ad_creation_method === 'creative'"
      :entity="entity"
      :disabled="$parent.readonlyMode"
      switchable
    />

    <div class="divider" />

    <url-params-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>
<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import AdCreationMethodBlock from '@/components/RuleForm/Blocks/AdCreationMethodBlock.vue';
import CtaBlock from '@/components/RuleForm/Blocks/CtaBlock.vue';
import UrlParamsBlock from '@/components/RuleForm/Blocks/UrlParamsBlock.vue';

import InfoText from '@/components/InfoText.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

export default {
  name: 'ScalingUpRuleFormStep4',
  mixins: [ RuleFormStepMixin ],
  components: {
    AdCreationMethodBlock,
    CtaBlock,
    UrlParamsBlock,
    InfoText,
    TransitionExpand,
  },
  methods: {
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      return true;
    },
  },
};
</script>
