import BaseEntity from '@/entities/BaseEntity';

import FacebookTargeting from '@/descriptors/FacebookTargeting';
import User from '@/entities/User';

export default class SourceProfile extends BaseEntity
{
  id = null;
  owner = null;
  platform = null;
  external_id = null;
  picture = null;
  name = '';
  category = '';
  website = '';
  usable_with_this = null;
  post_limit = 100;
  fetched_at = null;
  oldest_post_date = null;
  latest_post_date = null;
  targeting = null;
  comment_moderation = false;
  comment_moderation_sensitivity = null;
  has_active_post_boost = false;
  has_active_start_rule = false;

  _entityFields = {
    targeting: FacebookTargeting,
    owner: User,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  updateWith(newValues) {
    if (!super.updateWith(newValues)) {
      return;
    }

    for (const [key, value] of Object.entries(newValues)) {
      if (!(key in this)) {
        continue;
      }

      if (
        (
          key === 'fetched_at'
          || key === 'oldest_post_date'
          || key === 'latest_post_date'
        )
        && !!value
      ) {
        this[key] = new Date(value);
      }
    }
  }
}
