var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-waiting-list-view view" },
    [
      _c("form-block", {
        attrs: {
          description: _vm.tr(
            "Here you can see the contents on the waiting list that require attention due to rules or advertising policy issues. Click on individual items for detailed information. You can approve or reject activities, or override AI decisions."
          ),
        },
      }),
      _vm.rule && !_vm.isLoading && _vm.isListEmpty
        ? [
            _c("empty-screen", {
              attrs: {
                icon: "clock-sleepy",
                title: _vm.tr("No ads waiting for manual review"),
                "flag-text": _vm.tr("Full control over your ads"),
                description: _vm.tr(
                  "When you enable manual review in your automation settings, all new ads will appear here before going live. This safety feature gives you complete control over which automated campaigns can start running on your account, letting you review and approve each campaign before spending begins."
                ),
              },
            }),
          ]
        : _c("sorted-table", {
            attrs: { data: _vm.items, "disable-scroll": "" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      { attrs: { col: "source", width: "220px" } },
                      [_vm._v(" " + _vm._s(_vm.tr("Source")) + " ")]
                    ),
                    _c(
                      "table-head",
                      { attrs: { col: "content", "min-width": "200px" } },
                      [_vm._v(" " + _vm._s(_vm.contentLabel) + " ")]
                    ),
                    _c(
                      "table-head",
                      { attrs: { col: "status", width: "250px" } },
                      [_vm._v(" " + _vm._s(_vm.tr("Status")) + " ")]
                    ),
                    _c("table-head", {
                      attrs: { col: "navigation", width: "40px" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return [
                    _vm._l(sort, function (row) {
                      return _c(
                        "table-row",
                        { key: "waiting-list--" + row.id },
                        [
                          _c(
                            "table-data",
                            { attrs: { col: "source" } },
                            [
                              _c("social-page", {
                                attrs: {
                                  "source-profile": row.post.source_profile,
                                  date: row.created_at,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "content-col",
                              attrs: { col: "content" },
                            },
                            [
                              _c("inline-social-content", {
                                attrs: { entity: row.ad ?? row.post },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "status-col",
                              attrs: { col: "status" },
                            },
                            [
                              _c("rule-waiting-list-status", {
                                attrs: {
                                  rule: _vm.rule,
                                  entity: row,
                                  disabled:
                                    _vm.loadingButton !== null ||
                                    _vm.$root.isSupportMode,
                                },
                                on: {
                                  "loading-button": (value) =>
                                    (_vm.loadingButton = value),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            { attrs: { col: "navigation" } },
                            [
                              _c("navigation-arrow", {
                                on: { click: () => _vm.openItem(row) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm.hasMore || _vm.isLoading
                      ? _vm._l([1, 2, 3], function (i) {
                          return _c(
                            "table-row",
                            {
                              key: "skeleton--" + i,
                              ref: "loadMore",
                              refInFor: true,
                            },
                            [
                              _c(
                                "table-data",
                                { attrs: { col: "source" } },
                                [_c("social-page")],
                                1
                              ),
                              _c(
                                "table-data",
                                {
                                  staticClass: "content-col",
                                  attrs: { col: "content" },
                                },
                                [_c("inline-social-content")],
                                1
                              ),
                              _c(
                                "table-data",
                                {
                                  staticClass: "status-col",
                                  attrs: { col: "status" },
                                },
                                [
                                  _c("skeleton", { attrs: { width: "100%" } }),
                                  _c("skeleton", {
                                    attrs: { width: "80%", height: "12px" },
                                  }),
                                ],
                                1
                              ),
                              _c("table-data", {
                                attrs: { col: "navigation" },
                              }),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }