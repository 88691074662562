<template>
  <div class="error-logs-view">
    <sticky-header :title="tr('Error log')" bordered />
    <error-logs />
  </div>
</template>

<script>
import ErrorLogs from '@/components/ErrorLogs.vue';
import StickyHeader from '@/components/StickyHeader.vue';

export default {
  name: 'ErrorLogsView',
  components: {
    ErrorLogs,
    StickyHeader,
  },
};
</script>
