<template>
  <div
    :class="wrapperClasses"
    :data-items="itemCount"
    @click="() => changeValue()"
  >
    <i class="placeholder" />

    <div v-if="itemCount > 1" class="value">{{ tr(value) }}</div>

    <dropdown-menu v-if="!disabled" v-model="dropdownIsOpen">
      <div
        class="plus-button"
        @click="onClickPlus"
        @mouseover="() => (plusButtonHovered = true)"
        @mouseleave="() => (plusButtonHovered = false)"
      >
        <i class="i icon plus" />
      </div>

      <div slot="dropdown">
        <div @click="addNewItem">{{ tr('Add new condition row') }}</div>
        <div @click="addNewGroup">{{ tr('Add new group') }}</div>
      </div>
    </dropdown-menu>
    <i v-else class="placeholder" />
  </div>
</template>
<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';

export default {
  name: 'ConditionConnector',
  components: {
    DropdownMenu,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: () => 'OR',
    },
    itemCount: {
      type: Number,
      required: false,
      default: () => 1,
    },
    allowAddNewGroup: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    plusButtonHovered: false,
    dropdownIsOpen: false,
  }),
  computed: {
    wrapperClasses() {
      return {
        'condition-connector': true,
        and: this.value === 'AND',
        or: this.value === 'OR',
        'prevent-hover-color-change':
          this.plusButtonHovered || this.dropdownIsOpen,
      };
    },
  },
  watch: {
    dropdownIsOpen(val) {
      if (val) {
        this.$emit('open-dropdown');
      }
      else {
        this.$emit('close-dropdown');
      }
    },
  },
  methods: {
    changeValue() {
      if (!this.disabled) {
        this.$emit('input', this.value === 'OR' ? 'AND' : 'OR');
      }
    },
    onClickPlus(e) {
      e.stopPropagation();

      if (!this.allowAddNewGroup) {
        this.addNewItem();
      } else {
        this.dropdownIsOpen = true;
      }
    },
    addNewItem() {
      this.$emit('add-item');
      this.dropdownIsOpen = false;
    },
    addNewGroup() {
      this.$emit('add-group');
      this.dropdownIsOpen = false;
    },
  },
};
</script>
