<template>
  <div class="report-view">
    <template v-if="!adAccountId">
      <sticky-header
        v-if="$root.isLoggedIn"
        :title="tr('Report')"
        bordered
      >
        <template v-if="!isSharedReportView" v-slot:buttons>
          <button
            class="primary"
            :disabled="isLoadingShare"
            @click="() => share()"
          >
            <i v-if="isLoadingShare" class="icon loader white" />
            {{ tr('Share') }}
          </button>
        </template>
      </sticky-header>
      <div v-else class="logged-out-header">
        <a href="https://infinite.ad" target="_blank">
          <img src="~@/assets/img/ad-logo-inline.svg" />
        </a>
      </div>
    </template>

    <div v-if="isSharedReportView && isFetchingToken" class="loader">
      <i class="icon loader black" />
    </div>
    <empty-screen
      v-else-if ="isSharedReportView && isInvalidReportToken"
      icon="stat"
      :title="tr('This report link is no longer available or might be incorrect')"
      :flag-text="tr('Invalid report link')"
      :description="tr('The report you\'re trying to access might have expired or been removed. Please check if you\'ve entered the URL correctly, or contact the person who shared it with you to get an updated link.')"
    />
    <empty-screen
      v-else-if="!isSharedReportView && !hasConnectedAdAccount"
      icon="stat"
      :title="tr('Connect your ad account to see analytics')"
      :flag-text="tr('Real-time insights await')"
      :description="tr('Track your campaign performance, monitor spending, and analyze key metrics all in one place. To start seeing your data, connect your Meta ad account and let our AI help you optimize your advertising results.')"
      path="/integrations/meta/ad-accounts"
    />
    <template v-else>
      <div class="block">
        <div class="inline-blocks">
          <div v-if="adAccountId === null" class="filter-block">
            <div class="block-title">{{ tr('Ad account') }}</div>
            <v-select
              v-model="selectedAdAccountId"
              :options="adAccountList"
              :reduce="(item) => item.id"
              :placeholder="tr('Ad account')"
              :clearable="false"
              :disabled="isSharedReportView || disabledFilters"
              :loading="isLoadingCampaigns"
              label="name"
              class="ad-account-select"
            />
          </div>

          <div class="filter-block">
            <div class="block-title">{{ tr('Ad status') }}</div>
            <v-select
              v-model="isActiveFilter"
              :options="adStatusList"
              :disabled="disabledFilters"
              :reduce="(option) => option.value"
              :clearable="false"
              :searchable="false"
              class="ad-status-select"
            >
              <template #option="option">
                <div>
                  <i v-if="'icon' in option" :class="'icon point ' + option.icon" />
                  {{ tr(option.label) }}
                </div>
              </template>
              <template #selected-option="option">
                <div>
                  <i v-if="'icon' in option" :class="'icon point ' + option.icon" />
                  {{ tr(option.label) }}
                </div>
              </template>
            </v-select>
          </div>

          <div class="filter-block">
            <div class="block-title">{{ tr('Period') }}</div>
            <v-select v-if="periods.length === 0" disabled />
            <v-select
              v-else
              v-model="selectedPeriod"
              :options="periods"
              :reduce="(item) => item.machine_name"
              :clearable="false"
              :searchable="false"
              :disabled="!selectedAdAccountId || !selectedAction || disabledFilters"
              :loading="isLoadingPeriods"
            />
          </div>
        </div>

        <div class="inline-blocks">
          <div v-if="campaignId === null" class="filter-block">
            <div class="block-title">{{ tr('Campaign') }}</div>
            <v-select
              v-model="selectedCampaignId"
              :options="campaigns"
              :reduce="(item) => item.id"
              label="name"
              :clearable="false"
              :disabled="!selectedAdAccountId || isSharedReportView || disabledFilters"
            >
              <template #option="option">
                <span>
                  <i v-if="'status' in option" :class="{ 'icon point': true, 'green': option.status == 1, 'red': option.status != 1 }" />
                  {{ option.name }}
                </span>
              </template>
              <template #selected-option="option">
                <span>
                  <i v-if="'status' in option" :class="{ 'icon point': true, 'green': option.status == 1, 'red': option.status != 1 }" />
                  {{ option.name }}
                </span>
              </template>
            </v-select>
          </div>

          <div class="filter-block">
            <div class="block-title">{{ tr('Report aspect') }}</div>
            <v-select
              v-model="selectedAction"
              :options="actions"
              :reduce="(item) => item.machine_name"
              :clearable="false"
              :disabled="!selectedAdAccountId || !selectedAction || disabledFilters"
              :loading="isLoadingActions"
            />
          </div>
        </div>
      </div>

      <div class="inline-blocks">
        <div class="block">
          <div class="block-header">
            <div class="block-title">
              {{ tr('Spend') }}
            </div>

            <i class="icon dollar black" />
          </div>

          <skeleton
            v-if="isLoadingActions || (isLoadingTotals && !totals)"
            class="large-number"
            height="32px"
            width="100px"
          />
          <div v-else class="large-number">
            {{ totals && totals.spend ? formatCompactNumber(totals.spend) : '-' }}
            <small v-if="totals && totals.spend">{{ adAccountCurrency }}</small>
          </div>
        </div>

        <div class="block">
          <div class="block-header">
            <div class="block-title">
              {{ tr('Impressions') }}
            </div>

            <i class="icon eye black" />
          </div>

          <skeleton
            v-if="isLoadingActions || (isLoadingTotals && !totals)"
            class="large-number"
            height="32px"
            width="100px"
          />
          <div v-else class="large-number">
            {{ totals && totals.impressions ? formatCompactNumber(totals.impressions) : '-' }}
          </div>
        </div>

        <div class="block">
          <div class="block-header">
            <div class="block-title">
              {{ tr('Actions') }}
            </div>

            <i class="icon click black" />
          </div>

          <skeleton
            v-if="isLoadingActions || isLoadingTotals"
            class="large-number"
            height="32px"
            width="100px"
          />
          <div v-else class="large-number">
            {{ totals && totals.actions ? formatCompactNumber(totals.actions) : '-' }}
          </div>
          {{ selectedActionLabel }}
        </div>

        <div class="block">
          <div class="block-header">
            <div class="block-title">
              {{ tr('Average CPA') }}
            </div>

            <i class="icon chart-line black" />
          </div>

          <skeleton
            v-if="isLoadingActions || isLoadingTotals"
            class="large-number"
            height="32px"
            width="100px"
          />
          <div v-else class="large-number">
            {{ totals && totals.avg_cpa ? formatCompactNumber(totals.avg_cpa) : '-' }}
            <small v-if="totals && totals.avg_cpa">{{ adAccountCurrency }}</small>
          </div>
          <template v-if="selectedActionLabel">{{ tr('Cost') }} / {{ selectedActionLabel }}</template>
        </div>
      </div>

      <report-ads-table
        ref="reportAdsTable"
        :ad-account-id="selectedAdAccountId"
        :campaign-id="selectedCampaignId"
        :action="selectedAction"
        :period="selectedPeriod"
        :currency="adAccountCurrency"
        :is-active-filter="isActiveFilter"
        :token-part1="tokenPart1"
        :token-part2="tokenPart2"
      />
    </template>

    <!--
    TODO: - top performing creative blokk alatta számokkal (reach, impression, cpa, actions)
    -->

    <modal name="report-share-modal" overlayTransition="fade-modal-overlay">
      <i
        class="icon close"
        @click="() => $modal.hide('report-share-modal')"
      />

      <form-block
        :title="tr('Share Report')"
        :description="tr('With the link below, anyone can access the reports of the currently selected advertising account.')"
      >
        <input
          type="text"
          readony
          :value="shareUrl"
          @click="($e) => $e.target.select()"
        />

        <div class="buttons">
          <button class="primary" @click="() => copyShareUrl()">{{ tr('Copy link') }}</button>
        </div>
      </form-block>
    </modal>
  </div>
</template>

<script>
import EmptyScreen from '@/components/EmptyScreen.vue';
import FormBlock from '@/components/FormBlock.vue';
import ReportAdsTable from '@/components/ReportAdsTable.vue';
import Skeleton from '@/components/Skeleton.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import { NonCriticalError } from '@/errors';
import ReportService from '@/services/Report';
import { formatCompactNumber } from '@/utils/number.js';

export default {
  name: 'ReportView',
  components: {
    EmptyScreen,
    FormBlock,
    ReportAdsTable,
    Skeleton,
    StickyHeader,
  },
  props: {
    adAccountId: {
      type: [ String, null ],
      default: null,
    },
    campaignId: {
      type: [ String, null ],
      default: null,
    },
    action: {
      type: [ String, null ],
      default: null,
    },
    period: {
      type: [ String, null ],
      default: null,
    },
  },
  data() {
    return {
      hasError: false,
      isLoadingPeriods: false,
      isLoadingActions: false,
      isLoadingCampaigns: false,
      isLoadingTotals: false,
      isLoadingShare: false,
      isFetchingToken: false,
      isInvalidReportToken: false,

      sharedAdAccount: null,

      isActiveFilter: null,
      selectedAdAccountId: this.adAccountId,

      periods: [],
      selectedPeriod: this.period,

      actions: [],
      selectedAction: this.action,

      campaigns: [ this.buildAllCampaignsOption() ],
      selectedCampaignId: this.campaignId,
      ignoreCampaignWatcher: false,

      totals: null,

      shareUrl: null,
    };
  },
  computed: {
    hasConnectedAdAccount() {
      return this.$root.loggedInUser && this.$root.loggedInUser.company.ad_accounts.length !== 0;
    },
    isSharedReportView() {
      return this.$route.name === 'SharedReport';
    },
    tokenPart1() {
      return this.$route.params.tokenPart1;
    },
    tokenPart2() {
      return this.$route.params.tokenPart2;
    },
    disabledFilters() {
      return this.isLoadingActions
        || this.isLoadingTotals
        || this.isLoadingCampaigns
        || (this.$refs.reportAdsTable && this.$refs.reportAdsTable.isLoading);
    },
    adStatusList() {
      return [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Active ads',
          value: true,
          icon: 'green',
        },
        {
          label: 'Inactive ads',
          value: false,
          icon: 'red',
        },
      ];
    },
    adAccountList() {
      const result = [];
      if (this.$root.isLoggedIn) {
        result.push(...this.$root.currentCompany.ad_accounts);
      }

      if (this.sharedAdAccount !== null) {
        if (!result.map(i => i.id).includes(this.sharedAdAccount.id)) {
          result.push(this.sharedAdAccount);
        }
      }

      return result;
    },
    selectedAdAccount() {
      return this.adAccountList.find(i => i.id === this.selectedAdAccountId);
    },
    adAccountCurrency() {
      const fallback = '$';
      if (!this.selectedAdAccount) {
        return fallback;
      }

      const currency = this.$store.getters['app/currencies'].find(i => i.code === this.selectedAdAccount.currency);

      return currency ? currency.symbol : fallback;
    },
    completedFilters() {
      return this.selectedAdAccountId && this.selectedAction && this.selectedPeriod;
    },
    selectedActionLabel() {
      if (!this.selectedAction) {
        return null;
      }

      const actionItem = this.actions.find(action => action.machine_name === this.selectedAction);

      return actionItem ? actionItem.label : null;
    },
  },
  watch: {
    selectedAdAccountId(val) {
      if (!val) {
        return;
      }

      this.resetTotals();
      if (!this.isSharedReportView) {
        this.resetActions();
        this.resetCampaigns();
      }

      this.getActions()
        .then(resp => {
          if (!this.isSharedReportView) {
            this.getCampaigns();
          }

          return resp;
        })
        .catch(err => this.error(err));
    },
    selectedCampaignId() {
      if (!this.completedFilters || this.ignoreCampaignWatcher) {
        return;
      }

      this.resetTotals();
      this.getTotals();
    },
    selectedAction() {
      if (!this.completedFilters) {
        return;
      }

      this.getTotals();
    },
    selectedPeriod() {
      if (!this.completedFilters) {
        return;
      }

      this.resetTotals();
      this.getTotals();
    },
  },
  created() {
    if (this.isSharedReportView) {
      this.fetchReportByToken();
    }

    this.getPeriods();
    if (this.adAccountId !== null) {
      this.getActions();
    }
    if (this.campaignId !== null) {
      this.getTotals();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const selectedAdAccountId = urlParams.get('ad_account');
    const campaignId = urlParams.get('campaign');
    const action = urlParams.get('action');
    const period = urlParams.get('period');

    if (campaignId || selectedAdAccountId) {
      this.$router.replace(window.location.pathname);
    }

    if (selectedAdAccountId) {
      this.selectedAdAccountId = selectedAdAccountId;
    }

    this.$nextTick(() => {
      if (campaignId) {
        this.selectedAction = action;
        this.selectedCampaignId = campaignId;
      }
      if (period) {
        this.selectedPeriod = period;
      }
    });
  },
  methods: {
    buildAllCampaignsOption() {
      return {
        id: null,
        name: 'All campaigns',
      };
    },
    formatCompactNumber,
    resetTotals() {
      this.totals = null;

      this.isLoadingTotals = false;
    },
    resetActions() {
      this.actions = [];
      this.selectedAction = null;

      this.isLoadingActions = false;
    },
    resetCampaigns() {
      this.campaigns = [];
      this.selectedCampaignId = null;

      this.isLoadingCampaigns = false;
    },
    resetView() {
      this.hasError = false;
    },
    getPeriods() {
      if (this.isLoadingPeriods) {
        return Promise.reject(new NonCriticalError(this.tr('Please wait until the previous operation is completed.')));
      }

      this.isLoadingPeriods = true;

      return ReportService.getPeriods()
        .then(resp => {
          this.periods = resp.periods;
          if (this.selectedPeriod === null) {
            this.selectedPeriod = resp.default_period;
          }

          return resp;
        })
        .catch(err => this.onError(err))
        .finally(() => this.isLoadingPeriods = false);
    },
    getActions() {
      if (this.isLoadingActions) {
        return Promise.reject(new NonCriticalError(this.tr('Please wait until the previous operation is completed.')));
      }

      this.isLoadingActions = true;

      return ReportService.getActions({
        tokenPart1: this.tokenPart1,
        tokenPart2: this.tokenPart2,
        adAccountId: this.selectedAdAccountId,
      })
        .then(resp => {
          this.actions = resp.actions;
          if (resp.default_action === null) {
            this.selectedAction = null;
            return resp;
          }

          const availableActionValues = this.actions.map(i => i.machine_name);
          if (
            this.selectedAction !== null
            && !availableActionValues.includes(this.selectedAction)
            && this.selectedAction.startsWith('omni_')
          ) {
            this.selectedAction = this.selectedAction.replace('omni_', '');
          }

          if (
            !this.isSharedReportView
            && (this.selectedAction === null || !this.actions.map(i => i.machine_name).includes(this.selectedAction))
          ) {
            this.selectedAction = resp.default_action;
          }

          return resp;
        })
        .catch(err => this.onError(err))
        .finally(() => this.isLoadingActions = false);
    },
    getCampaigns() {
      if (this.isLoadingCampaigns) {
        return Promise.reject(new NonCriticalError(this.tr('Please wait until the previous operation is completed.')));
      }

      this.isLoadingCampaigns = true;

      return ReportService.getCampaigns({
        tokenPart1: this.tokenPart1,
        tokenPart2: this.tokenPart2,
        adAccountId: this.selectedAdAccountId,
      })
        .then(resp => {
          resp.unshift(this.buildAllCampaignsOption());
          this.campaigns = resp;

          // Reset selected campaign.
          const campaignIds = this.campaigns.map(campaign => campaign.id);
          if (this.selectedCampaignId && !campaignIds.includes(this.selectedCampaignId)) {
            this.selectedCampaignId = null;
          }

          return resp;
        })
        .catch(err => this.onError(err))
        .finally(() => this.isLoadingCampaigns = false);
    },
    getTotals() {
      if (this.isLoadingTotals) {
        return Promise.reject(new NonCriticalError(this.tr('Please wait until the previous operation is completed.')));
      }

      this.isLoadingTotals = true;

      return ReportService.getTotals({
        tokenPart1: this.tokenPart1,
        tokenPart2: this.tokenPart2,
        adAccountId: this.selectedAdAccountId,
        campaignId: this.selectedCampaignId,
        period: this.selectedPeriod,
        action: this.selectedAction,
      })
        .then(resp => this.totals = resp)
        .catch(err => this.onError(err))
        .finally(() => this.isLoadingTotals = false);
    },
    fetchReportByToken() {
      if (this.isFetchingToken) {
        return Promise.reject(new NonCriticalError(this.tr('Please wait until the previous operation is completed.')));
      }

      this.isFetchingToken = true;

      return ReportService.getByToken(this.tokenPart1, this.tokenPart2)
        .then(resp => {
          this.sharedAdAccount = resp.ad_account;

          if (resp.campaign) {
            this.campaigns = [ resp.campaign ];
            this.selectedCampaignId = resp.campaign.id;
          }

          this.selectedAdAccountId = this.sharedAdAccount.id;
          this.selectedPeriod = resp.period;
          this.selectedAction = resp.action;

          return resp;
        })
        .catch(err => {
          this.onError(err);
          this.isInvalidReportToken = true;
        })
        .finally(() => this.isFetchingToken = false);
    },
    onError(err) {
      this.hasError = true;
      this.error(err);
    },
    share() {
      if (this.isLoadingShare) {
        return;
      }
      if (!this.completedFilters) {
        this.error(this.tr('Choose an advertising account!'));
        return;
      }

      this.isLoadingShare = true;

      ReportService.share({
        adAccountId: this.selectedAdAccountId,
        campaignId: this.selectedCampaignId,
        period: this.selectedPeriod,
        action: this.selectedAction,
      })
        .then(resp => {
          this.shareUrl = resp.url;
          this.$modal.show('report-share-modal');

          return resp;
        })
        .catch(err => this.onError(err))
        .finally(() => this.isLoadingShare = false);
    },
    copyShareUrl() {
      navigator.clipboard.writeText(this.shareUrl);
      this.$toast.clear();
      this.$toast.success(this.tr('Link successfully copied to clipboard.'));
    },
  },
};
</script>
