var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-view" },
    [
      !_vm.adAccountId
        ? [
            _vm.$root.isLoggedIn
              ? _c("sticky-header", {
                  attrs: { title: _vm.tr("Report"), bordered: "" },
                  scopedSlots: _vm._u(
                    [
                      !_vm.isSharedReportView
                        ? {
                            key: "buttons",
                            fn: function () {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass: "primary",
                                    attrs: { disabled: _vm.isLoadingShare },
                                    on: { click: () => _vm.share() },
                                  },
                                  [
                                    _vm.isLoadingShare
                                      ? _c("i", {
                                          staticClass: "icon loader white",
                                        })
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(_vm.tr("Share")) + " "),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                })
              : _c("div", { staticClass: "logged-out-header" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "https://infinite.ad", target: "_blank" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/ad-logo-inline.svg"),
                        },
                      }),
                    ]
                  ),
                ]),
          ]
        : _vm._e(),
      _vm.isSharedReportView && _vm.isFetchingToken
        ? _c("div", { staticClass: "loader" }, [
            _c("i", { staticClass: "icon loader black" }),
          ])
        : _vm.isSharedReportView && _vm.isInvalidReportToken
        ? _c("empty-screen", {
            attrs: {
              icon: "stat",
              title: _vm.tr(
                "This report link is no longer available or might be incorrect"
              ),
              "flag-text": _vm.tr("Invalid report link"),
              description: _vm.tr(
                "The report you're trying to access might have expired or been removed. Please check if you've entered the URL correctly, or contact the person who shared it with you to get an updated link."
              ),
            },
          })
        : !_vm.isSharedReportView && !_vm.hasConnectedAdAccount
        ? _c("empty-screen", {
            attrs: {
              icon: "stat",
              title: _vm.tr("Connect your ad account to see analytics"),
              "flag-text": _vm.tr("Real-time insights await"),
              description: _vm.tr(
                "Track your campaign performance, monitor spending, and analyze key metrics all in one place. To start seeing your data, connect your Meta ad account and let our AI help you optimize your advertising results."
              ),
              path: "/integrations/meta/ad-accounts",
            },
          })
        : [
            _c("div", { staticClass: "block" }, [
              _c("div", { staticClass: "inline-blocks" }, [
                _vm.adAccountId === null
                  ? _c(
                      "div",
                      { staticClass: "filter-block" },
                      [
                        _c("div", { staticClass: "block-title" }, [
                          _vm._v(_vm._s(_vm.tr("Ad account"))),
                        ]),
                        _c("v-select", {
                          staticClass: "ad-account-select",
                          attrs: {
                            options: _vm.adAccountList,
                            reduce: (item) => item.id,
                            placeholder: _vm.tr("Ad account"),
                            clearable: false,
                            disabled:
                              _vm.isSharedReportView || _vm.disabledFilters,
                            loading: _vm.isLoadingCampaigns,
                            label: "name",
                          },
                          model: {
                            value: _vm.selectedAdAccountId,
                            callback: function ($$v) {
                              _vm.selectedAdAccountId = $$v
                            },
                            expression: "selectedAdAccountId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "filter-block" },
                  [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(_vm._s(_vm.tr("Ad status"))),
                    ]),
                    _c("v-select", {
                      staticClass: "ad-status-select",
                      attrs: {
                        options: _vm.adStatusList,
                        disabled: _vm.disabledFilters,
                        reduce: (option) => option.value,
                        clearable: false,
                        searchable: false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c("div", [
                                "icon" in option
                                  ? _c("i", {
                                      class: "icon point " + option.icon,
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " + _vm._s(_vm.tr(option.label)) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("div", [
                                "icon" in option
                                  ? _c("i", {
                                      class: "icon point " + option.icon,
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " + _vm._s(_vm.tr(option.label)) + " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.isActiveFilter,
                        callback: function ($$v) {
                          _vm.isActiveFilter = $$v
                        },
                        expression: "isActiveFilter",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-block" },
                  [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(_vm._s(_vm.tr("Period"))),
                    ]),
                    _vm.periods.length === 0
                      ? _c("v-select", { attrs: { disabled: "" } })
                      : _c("v-select", {
                          attrs: {
                            options: _vm.periods,
                            reduce: (item) => item.machine_name,
                            clearable: false,
                            searchable: false,
                            disabled:
                              !_vm.selectedAdAccountId ||
                              !_vm.selectedAction ||
                              _vm.disabledFilters,
                            loading: _vm.isLoadingPeriods,
                          },
                          model: {
                            value: _vm.selectedPeriod,
                            callback: function ($$v) {
                              _vm.selectedPeriod = $$v
                            },
                            expression: "selectedPeriod",
                          },
                        }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "inline-blocks" }, [
                _vm.campaignId === null
                  ? _c(
                      "div",
                      { staticClass: "filter-block" },
                      [
                        _c("div", { staticClass: "block-title" }, [
                          _vm._v(_vm._s(_vm.tr("Campaign"))),
                        ]),
                        _c("v-select", {
                          attrs: {
                            options: _vm.campaigns,
                            reduce: (item) => item.id,
                            label: "name",
                            clearable: false,
                            disabled:
                              !_vm.selectedAdAccountId ||
                              _vm.isSharedReportView ||
                              _vm.disabledFilters,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "option",
                                fn: function (option) {
                                  return [
                                    _c("span", [
                                      "status" in option
                                        ? _c("i", {
                                            class: {
                                              "icon point": true,
                                              green: option.status == 1,
                                              red: option.status != 1,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.name) + " "),
                                    ]),
                                  ]
                                },
                              },
                              {
                                key: "selected-option",
                                fn: function (option) {
                                  return [
                                    _c("span", [
                                      "status" in option
                                        ? _c("i", {
                                            class: {
                                              "icon point": true,
                                              green: option.status == 1,
                                              red: option.status != 1,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.name) + " "),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            27836685
                          ),
                          model: {
                            value: _vm.selectedCampaignId,
                            callback: function ($$v) {
                              _vm.selectedCampaignId = $$v
                            },
                            expression: "selectedCampaignId",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "filter-block" },
                  [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(_vm._s(_vm.tr("Report aspect"))),
                    ]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.actions,
                        reduce: (item) => item.machine_name,
                        clearable: false,
                        disabled:
                          !_vm.selectedAdAccountId ||
                          !_vm.selectedAction ||
                          _vm.disabledFilters,
                        loading: _vm.isLoadingActions,
                      },
                      model: {
                        value: _vm.selectedAction,
                        callback: function ($$v) {
                          _vm.selectedAction = $$v
                        },
                        expression: "selectedAction",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "inline-blocks" }, [
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("div", { staticClass: "block-header" }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Spend")) + " "),
                    ]),
                    _c("i", { staticClass: "icon dollar black" }),
                  ]),
                  _vm.isLoadingActions || (_vm.isLoadingTotals && !_vm.totals)
                    ? _c("skeleton", {
                        staticClass: "large-number",
                        attrs: { height: "32px", width: "100px" },
                      })
                    : _c("div", { staticClass: "large-number" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.totals && _vm.totals.spend
                                ? _vm.formatCompactNumber(_vm.totals.spend)
                                : "-"
                            ) +
                            " "
                        ),
                        _vm.totals && _vm.totals.spend
                          ? _c("small", [_vm._v(_vm._s(_vm.adAccountCurrency))])
                          : _vm._e(),
                      ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("div", { staticClass: "block-header" }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Impressions")) + " "),
                    ]),
                    _c("i", { staticClass: "icon eye black" }),
                  ]),
                  _vm.isLoadingActions || (_vm.isLoadingTotals && !_vm.totals)
                    ? _c("skeleton", {
                        staticClass: "large-number",
                        attrs: { height: "32px", width: "100px" },
                      })
                    : _c("div", { staticClass: "large-number" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.totals && _vm.totals.impressions
                                ? _vm.formatCompactNumber(
                                    _vm.totals.impressions
                                  )
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("div", { staticClass: "block-header" }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Actions")) + " "),
                    ]),
                    _c("i", { staticClass: "icon click black" }),
                  ]),
                  _vm.isLoadingActions || _vm.isLoadingTotals
                    ? _c("skeleton", {
                        staticClass: "large-number",
                        attrs: { height: "32px", width: "100px" },
                      })
                    : _c("div", { staticClass: "large-number" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.totals && _vm.totals.actions
                                ? _vm.formatCompactNumber(_vm.totals.actions)
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                  _vm._v(" " + _vm._s(_vm.selectedActionLabel) + " "),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "block" },
                [
                  _c("div", { staticClass: "block-header" }, [
                    _c("div", { staticClass: "block-title" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Average CPA")) + " "),
                    ]),
                    _c("i", { staticClass: "icon chart-line black" }),
                  ]),
                  _vm.isLoadingActions || _vm.isLoadingTotals
                    ? _c("skeleton", {
                        staticClass: "large-number",
                        attrs: { height: "32px", width: "100px" },
                      })
                    : _c("div", { staticClass: "large-number" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.totals && _vm.totals.avg_cpa
                                ? _vm.formatCompactNumber(_vm.totals.avg_cpa)
                                : "-"
                            ) +
                            " "
                        ),
                        _vm.totals && _vm.totals.avg_cpa
                          ? _c("small", [_vm._v(_vm._s(_vm.adAccountCurrency))])
                          : _vm._e(),
                      ]),
                  _vm.selectedActionLabel
                    ? [
                        _vm._v(
                          _vm._s(_vm.tr("Cost")) +
                            " / " +
                            _vm._s(_vm.selectedActionLabel)
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("report-ads-table", {
              ref: "reportAdsTable",
              attrs: {
                "ad-account-id": _vm.selectedAdAccountId,
                "campaign-id": _vm.selectedCampaignId,
                action: _vm.selectedAction,
                period: _vm.selectedPeriod,
                currency: _vm.adAccountCurrency,
                "is-active-filter": _vm.isActiveFilter,
                "token-part1": _vm.tokenPart1,
                "token-part2": _vm.tokenPart2,
              },
            }),
          ],
      _c(
        "modal",
        {
          attrs: {
            name: "report-share-modal",
            overlayTransition: "fade-modal-overlay",
          },
        },
        [
          _c("i", {
            staticClass: "icon close",
            on: { click: () => _vm.$modal.hide("report-share-modal") },
          }),
          _c(
            "form-block",
            {
              attrs: {
                title: _vm.tr("Share Report"),
                description: _vm.tr(
                  "With the link below, anyone can access the reports of the currently selected advertising account."
                ),
              },
            },
            [
              _c("input", {
                attrs: { type: "text", readony: "" },
                domProps: { value: _vm.shareUrl },
                on: { click: ($e) => $e.target.select() },
              }),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "primary",
                    on: { click: () => _vm.copyShareUrl() },
                  },
                  [_vm._v(_vm._s(_vm.tr("Copy link")))]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }