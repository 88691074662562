var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "post-modal-content" },
    [
      !_vm.isLoading
        ? [
            _c(
              "div",
              { staticClass: "post" },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [
                    _c("social-page", {
                      attrs: {
                        "source-profile": _vm.post.source_profile,
                        date: _vm.post.created_at,
                      },
                    }),
                  ],
                  1
                ),
                _c("form-step-tab", { attrs: { items: _vm.tabs } }),
              ],
              1
            ),
            _c("div", { staticClass: "scroll-wrapper" }, [
              _vm.isOriginal
                ? _c(
                    "div",
                    { staticClass: "original" },
                    [
                      _c(
                        "div",
                        { staticClass: "post" },
                        [
                          _c("inline-social-content", {
                            attrs: { entity: _vm.post },
                          }),
                          _vm.post.url
                            ? _c(
                                "link-with-icon",
                                { attrs: { link: _vm.post.url } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.tr("Go to Post")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("h3", [_vm._v(_vm._s(_vm.tr("Post metrics")))]),
                      _c(
                        "div",
                        { staticClass: "metric-select-wrapper" },
                        [
                          _c("v-select", {
                            staticClass: "metric-select",
                            attrs: {
                              options: _vm.metricFields,
                              multiple: "",
                              label: "label",
                              "close-on-select": false,
                            },
                            on: {
                              open: function ($event) {
                                _vm.isMetricDropdownOpen = true
                              },
                              close: function ($event) {
                                _vm.isMetricDropdownOpen = false
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "search",
                                  fn: function ({ attributes, events }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "metric-select--placeholder-input",
                                              attrs: {
                                                placeholder:
                                                  _vm.tr("Customize metrics"),
                                              },
                                            },
                                            "input",
                                            attributes,
                                            false
                                          ),
                                          events
                                        )
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "option",
                                  fn: function (field) {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "metric-select--option ignore-checkbox-style",
                                          on: {
                                            click: () =>
                                              _vm.toggleVisibleField(field),
                                          },
                                        },
                                        [
                                          _c(
                                            "input",
                                            _vm._b(
                                              {
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked:
                                                    _vm.isSelected(field),
                                                },
                                              },
                                              "input",
                                              field,
                                              false
                                            )
                                          ),
                                          _vm._v(
                                            " " + _vm._s(field.label) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2371328250
                            ),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "metrics-wrapper" },
                        [
                          _c("sorted-table", {
                            attrs: {
                              data: [_vm.post.metric],
                              "disable-scroll": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return _vm._l(
                                      _vm.metricVisibleFields,
                                      function (field) {
                                        return _c(
                                          "table-head",
                                          {
                                            key:
                                              "metric-head-" +
                                              field.machine_name,
                                            attrs: { col: field.machine_name },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.tr(field.label)) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "default",
                                  fn: function ({ sort }) {
                                    return [
                                      _c(
                                        "table-row",
                                        _vm._l(
                                          _vm.metricVisibleFields,
                                          function (field) {
                                            return _c(
                                              "table-data",
                                              {
                                                key:
                                                  "metric-value-" +
                                                  field.machine_name,
                                                attrs: {
                                                  col: field.machine_name,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formatNumber(
                                                        sort[0][
                                                          field.machine_name
                                                        ],
                                                        field.machine_name ===
                                                          "ctr"
                                                          ? "%"
                                                          : ""
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              969652663
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "last-update" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr("Data last updated at :time", {
                                ":time": _vm.timeAgo(
                                  _vm.post.source_profile.fetched_at,
                                  _vm.tr
                                ),
                              })
                            ) +
                            " "
                        ),
                      ]),
                      !_vm.post.source_profile.has_active_start_rule
                        ? _c(
                            "info-text",
                            { staticClass: "ai-evaluation-not-allowed" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tr(
                                      "The post AI evaluation will not occur until the profile that published it has active Start Automation."
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : [
                            _c("h3", { staticClass: "title-with-icon" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.tr("Actuality of the content")) +
                                  " "
                              ),
                            ]),
                            _vm.contectActualityFormOpen
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "templateable-input" },
                                    [
                                      _c("date-time-input", {
                                        model: {
                                          value: _vm.newContentActuality,
                                          callback: function ($$v) {
                                            _vm.newContentActuality = $$v
                                          },
                                          expression: "newContentActuality",
                                        },
                                      }),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "primary",
                                          attrs: {
                                            disabled:
                                              _vm.isLoadingContantActualitySave,
                                          },
                                          on: {
                                            click: () =>
                                              _vm.updateContentActuality(),
                                          },
                                        },
                                        [
                                          _vm.isLoadingContantActualitySave
                                            ? _c("i", {
                                                staticClass:
                                                  "icon loader white",
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            " " + _vm._s(_vm.tr("Save")) + " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "primary-link",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clearDateAndTime()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tr("Clear date and time")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : !_vm.post.ai_evaluation_possible
                              ? _c(
                                  "info-text",
                                  { staticClass: "ai-evaluation-not-possible" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.tr(
                                            "The text of the post is too short. AI evaluation is not possible."
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : [
                                  !_vm.post.ai_content_actuality_checked
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "ai-actuality-checking",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon loader",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tr(
                                                  "Checking content actuality..."
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm.post.ai_content_actuality
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.aiContentAtualityText) +
                                            ":"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatToYMD(
                                                _vm.post.ai_content_actuality
                                              )
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.formatTime(
                                                _vm.post.ai_content_actuality
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.contentActualityNotSetText
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                            (!_vm.post.ai_evaluation_possible ||
                              _vm.post.ai_content_actuality_checked) &&
                            !_vm.contectActualityFormOpen &&
                            !_vm.$root.loggedInUser.isRestrictedViewer
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "primary-link edit-content-actuality-link",
                                    on: { click: () => _vm.editDateAndTime() },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Edit date and time")) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.post.ai_meta && _vm.post.ai_meta.length !== 0
                              ? _c("h3", { staticClass: "title-with-icon" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.tr("Content scoring")) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.post.ai_evaluation_possible &&
                            !_vm.post.ai_meta_error
                              ? _c("ai-score", {
                                  attrs: { value: _vm.post.ai_value },
                                })
                              : _vm._e(),
                            _vm.post.ai_meta && _vm.post.ai_meta.length !== 0
                              ? [
                                  _c("copy-evaluation", {
                                    attrs: { details: _vm.post.ai_meta },
                                  }),
                                ]
                              : _vm._e(),
                            _vm.post.platform === "facebook"
                              ? _c("div", { staticClass: "buttons" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "red",
                                      on: {
                                        click: () =>
                                          _vm.openDeleteConfirmModal(),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.tr("Delete post")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isImproved
                ? _c(
                    "div",
                    { staticClass: "improved" },
                    [
                      _vm.post.ai_meta &&
                      _vm.post.ai_meta.length !== 0 &&
                      (!_vm.post.improved_copy ||
                        _vm.post.improved_copy.inProgress)
                        ? _c("div", { staticClass: "buttons" }, [
                            _c(
                              "button",
                              {
                                staticClass: "primary",
                                attrs: { disabled: _vm.improvedCopyInProgress },
                                on: { click: () => _vm.improveCopy() },
                              },
                              [
                                _vm.improvedCopyInProgress
                                  ? _c("i", {
                                      staticClass: "icon loader white",
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.tr("Generate improved copy")) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : !_vm.post.ai_meta || _vm.post.ai_meta.length === 0
                        ? _c(
                            "info-text",
                            { staticClass: "imporovement-not-allowed" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tr(
                                      "Improved copy cannot be generated until AI evaluation is complete. Please check the issues on the Original tab first."
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.post.improved_copy &&
                      !_vm.post.improved_copy.inProgress
                        ? _c("improved-copy-component", {
                            attrs: { entity: _vm.post.improved_copy },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        : [
            _c(
              "div",
              { staticClass: "post" },
              [
                _c(
                  "div",
                  { staticClass: "top" },
                  [_c("social-page", { attrs: { "source-profile": null } })],
                  1
                ),
                _c("form-step-tab", { attrs: { items: _vm.tabs } }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "scroll-wrapper" },
              [
                _c(
                  "div",
                  { staticClass: "post" },
                  [
                    _c("inline-social-content", { attrs: { entity: null } }),
                    _c("link-with-icon", [
                      _vm._v(" " + _vm._s(_vm.tr("Go to Post")) + " "),
                    ]),
                  ],
                  1
                ),
                _c("h3", [_vm._v(_vm._s(_vm.tr("Post metrics")))]),
                _c(
                  "div",
                  { staticClass: "metric-select-wrapper" },
                  [
                    _c("skeleton", {
                      attrs: { width: "100%", height: "40px", radius: "10px" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "metrics-wrapper" },
                  [
                    _c(
                      "sorted-table",
                      {
                        attrs: { "disable-scroll": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "table-head",
                                  { attrs: { width: "100px" } },
                                  [
                                    _c("skeleton", {
                                      attrs: {
                                        width: "50px",
                                        height: "20px",
                                        radius: "10px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-head",
                                  { attrs: { width: "100px" } },
                                  [
                                    _c("skeleton", {
                                      attrs: {
                                        width: "50px",
                                        height: "20px",
                                        radius: "10px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-head",
                                  { attrs: { width: "100px" } },
                                  [
                                    _c("skeleton", {
                                      attrs: {
                                        width: "50px",
                                        height: "20px",
                                        radius: "10px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-head",
                                  { attrs: { width: "100px" } },
                                  [
                                    _c("skeleton", {
                                      attrs: {
                                        width: "50px",
                                        height: "20px",
                                        radius: "10px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        [
                          _c(
                            "table-row",
                            [
                              _c(
                                "table-data",
                                [
                                  _c("skeleton", {
                                    attrs: {
                                      width: "50px",
                                      height: "20px",
                                      radius: "10px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "table-data",
                                [
                                  _c("skeleton", {
                                    attrs: {
                                      width: "50px",
                                      height: "20px",
                                      radius: "10px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "table-data",
                                [
                                  _c("skeleton", {
                                    attrs: {
                                      width: "50px",
                                      height: "20px",
                                      radius: "10px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "table-data",
                                [
                                  _c("skeleton", {
                                    attrs: {
                                      width: "50px",
                                      height: "20px",
                                      radius: "10px",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "last-update" },
                  [
                    _c("skeleton", {
                      attrs: { width: "250", height: "20px", radius: "10px" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c("skeleton", {
                      attrs: { width: "100%", height: "40px", radius: "10px" },
                    }),
                  ],
                  1
                ),
                [
                  _c("h3", { staticClass: "title-with-icon" }, [
                    _vm._v(
                      " " + _vm._s(_vm.tr("Actuality of the content")) + " "
                    ),
                  ]),
                  [
                    _c("div", { staticClass: "ai-actuality-checking" }, [
                      _c("i", { staticClass: "icon loader" }),
                      _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
                    ]),
                    _c("br"),
                  ],
                  _c("h3", [_vm._v(_vm._s(_vm.tr("Content scoring")))]),
                  _c("skeleton", {
                    attrs: { width: "50px", height: "20px", radius: "10px" },
                  }),
                  [_c("copy-evaluation", { attrs: { details: null } })],
                ],
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }