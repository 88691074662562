<template>
  <div :class="wrapperClasses">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'PostContentPolicyCheckStatus',
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'post-content-policy-check-status-component': true,
        passed: this.value === false,
        denied: this.value === true,
        unknown: this.value === null,
      };
    },
    text() {
      if (this.value === false) {
        return this.tr('Passed');
      }
      if (this.value === true) {
        return this.tr('Denied');
      }

      return this.tr('Unchecked');
    },
  },
};
</script>
