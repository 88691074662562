var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-list-view" },
    [
      _c("sticky-header", {
        attrs: {
          title: _vm.tr("Automations"),
          info: _vm.tr(
            "Unlock the Full Power of Your Social Media with Advanced Automations. 🚀 Seamlessly manage and optimize your Facebook and Instagram ads with automations for creation, scaling and stopping. 💪"
          ),
          bordered: "",
        },
      }),
      _c("system-level-notifications", { attrs: { "space-top": "" } }),
      _c(
        "transition-expand",
        [
          _vm.showNewRuleCards
            ? _c("create-new-rule-cards", { ref: "createNewRuleCards" })
            : _vm._e(),
        ],
        1
      ),
      !_vm.isListEmpty || !_vm.isEmptyFilters || _vm.isLoading
        ? _c(
            "div",
            { staticClass: "filters" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                staticClass: "term-filter icon--search new-input-theming",
                attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
              _c("v-select", {
                staticClass: "action-select new-input-theming",
                attrs: {
                  options: _vm.actionOptions,
                  reduce: (item) => item.machine_name,
                  searchable: false,
                },
                model: {
                  value: _vm.actionFilter,
                  callback: function ($$v) {
                    _vm.actionFilter = $$v
                  },
                  expression: "actionFilter",
                },
              }),
              _c("v-select", {
                staticClass: "ad-account-select new-input-theming",
                attrs: {
                  options: _vm.adAccountOptions,
                  reduce: (item) => item.id,
                  label: "name",
                },
                model: {
                  value: _vm.adAccountId,
                  callback: function ($$v) {
                    _vm.adAccountId = $$v
                  },
                  expression: "adAccountId",
                },
              }),
              _c("v-select", {
                staticClass: "status-select new-input-theming",
                attrs: {
                  options: _vm.statusOptions,
                  reduce: (item) => item.value,
                  searchable: false,
                },
                model: {
                  value: _vm.statusFilter,
                  callback: function ($$v) {
                    _vm.statusFilter = $$v
                  },
                  expression: "statusFilter",
                },
              }),
              _c(
                "dropdown-menu",
                {
                  attrs: { transition: "slide-top-to-bottom", interactive: "" },
                  model: {
                    value: _vm.filtersIsOpen,
                    callback: function ($$v) {
                      _vm.filtersIsOpen = $$v
                    },
                    expression: "filtersIsOpen",
                  },
                },
                [
                  _c("button", { staticClass: "filter-button white" }, [
                    _c("i", { staticClass: "icon adjustments light-black" }),
                    _vm._v(" " + _vm._s(_vm.tr("Filters")) + " "),
                  ]),
                  _c("div", { attrs: { slot: "dropdown" }, slot: "dropdown" }, [
                    _c(
                      "div",
                      { staticClass: "filter-input-block" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(_vm.tr("Automation type"))),
                        ]),
                        _c("v-select", {
                          staticClass: "action-select new-input-theming",
                          attrs: {
                            options: _vm.actionOptions,
                            reduce: (item) => item.machine_name,
                            searchable: false,
                          },
                          model: {
                            value: _vm.actionFilter,
                            callback: function ($$v) {
                              _vm.actionFilter = $$v
                            },
                            expression: "actionFilter",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "filter-input-block" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.tr("Status")))]),
                        _c("v-select", {
                          staticClass: "status-select new-input-theming",
                          attrs: {
                            options: _vm.statusOptions,
                            reduce: (item) => item.value,
                            searchable: false,
                          },
                          model: {
                            value: _vm.statusFilter,
                            callback: function ($$v) {
                              _vm.statusFilter = $$v
                            },
                            expression: "statusFilter",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "filter-input-block" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.tr("Ad Account")))]),
                        _c("v-select", {
                          staticClass: "ad-account-select new-input-theming",
                          attrs: {
                            options: _vm.adAccountOptions,
                            reduce: (item) => item.id,
                            label: "name",
                          },
                          model: {
                            value: _vm.adAccountId,
                            callback: function ($$v) {
                              _vm.adAccountId = $$v
                            },
                            expression: "adAccountId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading && _vm.isListEmpty
        ? _c(
            "div",
            [
              !_vm.isEmptyFilters
                ? _c(
                    "notification-card",
                    {
                      staticClass: "no-result",
                      attrs: {
                        image: require("@/assets/img/sad_new.png"),
                        "empty-screen": "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "There are no results matching your search criteria."
                            )
                          ) +
                          " "
                      ),
                    ]
                  )
                : _c(
                    "notification-card",
                    {
                      attrs: {
                        image: require("@/assets/img/new-rule-card/ai.png"),
                        "empty-screen": "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "Manage your advertising with automation on Facebook or Instagram."
                            )
                          ) +
                          " "
                      ),
                      !_vm.$root.loggedInUser.isRestrictedViewer
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button primary",
                              attrs: { to: "/automation/new/ai-mode" },
                            },
                            [
                              _vm._v(
                                " + " +
                                  _vm._s(
                                    _vm.tr("Create your first Automation")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          )
        : _c("new-sorted-table", {
            attrs: {
              data: _vm.list,
              "initial-sort": { key: _vm.orderBy, direction: _vm.order },
            },
            on: { sort: _vm.sortTable },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("table-head", {
                      attrs: { col: "status", width: "50px" },
                    }),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "name",
                          "min-width": "250px",
                          sortable: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tr("Name")))]
                    ),
                    _c(
                      "table-head",
                      { attrs: { col: "last-run", width: "150px" } },
                      [_vm._v(_vm._s(_vm.tr("Last Run")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "center",
                        attrs: { col: "triggered", width: "82px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Task Done")))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return [
                    _vm._l(sort, function (row) {
                      return _c(
                        "table-row",
                        {
                          key: row.id,
                          attrs: {
                            "header-color": "rule-header--" + row.action,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      class:
                                        "icon white " +
                                        _vm.automationTypeIcon(row.action),
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.automationTypeLabel(row.action)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              row.group_id !== null
                                ? {
                                    key: "header-decorator",
                                    fn: function () {
                                      return [
                                        _c("i", {
                                          staticClass: "icon magic-stars white",
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.tr("Created in Ai mode")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "table-data",
                            { attrs: { col: "status" } },
                            [
                              _c("switches", {
                                attrs: {
                                  disabled:
                                    _vm.$root.loggedInUser.isRestrictedViewer ||
                                    _vm.$root.isSupportMode ||
                                    _vm.deletedCampaignFlagIsVisible(row),
                                  "emit-on-mount": false,
                                },
                                on: {
                                  input: () => _vm.onChangeRuleStatus(row),
                                },
                                model: {
                                  value: row.active,
                                  callback: function ($$v) {
                                    _vm.$set(row, "active", $$v)
                                  },
                                  expression: "row.active",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "name-container",
                              attrs: { col: "name" },
                            },
                            [
                              _c(
                                "access-handled-router-link",
                                {
                                  attrs: {
                                    to: "/automation/" + row.id + "/overview",
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.name) + " ")]
                              ),
                              row.ad_account &&
                              _vm.facebookAdAccountName(row.ad_account.id)
                                ? _c("div", { staticClass: "flag" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.facebookAdAccountName(
                                          row.ad_account.id
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.disconnectedAdAccountFlagIsVisible(row)
                                ? _c("div", { staticClass: "flag red" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Automation Stopped")) +
                                        " - " +
                                        _vm._s(
                                          _vm.tr("Disconnected Ad Account")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.deletedCampaignFlagIsVisible(row)
                                ? _c("div", { staticClass: "flag red" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Automation Stopped")) +
                                        " - " +
                                        _vm._s(
                                          _vm.tr(
                                            "Campaign Deleted on Ads Manager"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.inactiveCampaignFlagIsVisible(row)
                                ? _c("div", { staticClass: "flag orange" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Inactive Campaign")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "last-run-content",
                              attrs: { col: "last-run" },
                            },
                            [
                              row.active
                                ? _c(
                                    "rule-next-run-tooltip",
                                    {
                                      staticClass: "icon-wrapper",
                                      attrs: { entity: row },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "icon clock-filled light-black",
                                      }),
                                    ]
                                  )
                                : _c("div", { staticClass: "no-clock" }),
                              _c(
                                "div",
                                [
                                  row.last_run
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content:
                                                    _vm.formatToYMD(
                                                      row.last_run
                                                    ) +
                                                    ". " +
                                                    _vm.formatTime(
                                                      row.last_run
                                                    ),
                                                  classes: [""],
                                                },
                                                expression:
                                                  "{ content: formatToYMD(row.last_run) + '. ' + formatTime(row.last_run), classes: [ '' ] }",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.timeAgo(
                                                    row.last_run,
                                                    _vm.tr
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [_vm._v(" - ")],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "center triggered",
                              attrs: { col: "triggered" },
                            },
                            [_vm._v(" " + _vm._s(row.triggered) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    _vm.isLoading || _vm.hasMore
                      ? _vm._l(
                          _vm.isListEmpty
                            ? ["start", "scaling_up", "stop"]
                            : ["start", "scaling_up"],
                          function (action) {
                            return _c(
                              "table-row",
                              {
                                key: "automations-loader-skeleton--" + action,
                                ref: "loadMore",
                                refInFor: true,
                                attrs: {
                                  "header-color": "rule-header--" + action,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function () {
                                        return [
                                          _c("i", {
                                            staticClass: "icon white loader",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.tr("Loading...")) +
                                              " "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "table-data",
                                  { attrs: { col: "status" } },
                                  [
                                    _c("switches", {
                                      staticStyle: { opacity: "0.5" },
                                      attrs: { value: "1", disabled: "" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "name-container",
                                    attrs: { col: "name" },
                                  },
                                  [
                                    _c("skeleton", {
                                      staticClass: "name-skeleton",
                                      attrs: {
                                        height: "15px",
                                        "min-width": "250px",
                                        "max-width": "400px",
                                      },
                                    }),
                                    _c("skeleton", {
                                      staticClass: "flag",
                                      attrs: {
                                        width: "70px",
                                        height: "13px",
                                        radius: "5px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  { attrs: { col: "last-run" } },
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "60%", height: "18px" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "center",
                                    attrs: { col: "triggered" },
                                  },
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "26px", height: "18px" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
      !_vm.$root.loggedInUser.isRestrictedViewer && !_vm.$root.isSupportMode
        ? [
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _c("floating-create-new-rule-button", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.floatingAddButtonIsVisible,
                      expression: "floatingAddButtonIsVisible",
                    },
                  ],
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }