<template>
  <div class="rule-switch-modal-content-component">
    <div class="modal-title">
      {{ tr('Would you like to turn off the campaign too?') }}
    </div>

    <div class="modal-content">
      <p>{{ tr('The campaign is currently active, in which the desired automation is being worked to be turned off.') }}</p>
      <p>{{ tr('You can choose to disable only the automation: in this case, no new advertisements will be created, but the existing ones will continue to run.') }}</p>
      <p>{{ tr('You can also disable the associated campaign along with the automation: in this case, the existing ads will also be paused.') }}</p>
    </div>

    <div class="modal-action-group">
      <button
        class="bordered gray"
        :disabled="isLoading"
        @click="() => switchOnlyRule()"
      >
        <i v-if="loadingRule" class="icon loader" />
        <template v-else>
          {{ tr('Only switching the Automation') }}
        </template>
      </button>
      <button
        class="primary"
        :disabled="isLoading"
        @click="() => switchRuleWithCampaign()"
      >
        <i v-if="loadingRuleWithCampaign" class="icon loader white" />
        <template v-else>
          {{ tr('Turn off Automation and Campaign') }}
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import Rule from '@/entities/Rule';
import RuleService from '@/services/Rule.js';

export default {
  name: 'RuleCampaignStatusChangeModalContent',
  props: {
    rule: {
      type: Rule,
      required: true,
    },
  },
  data: () => ({
    loadingRule: false,
    loadingRuleWithCampaign: false,
  }),
  computed: {
    id() {
      return this.rule.id;
    },
    newStatus() {
      return !this.rule.active;
    },
    isLoading() {
      return this.loadingRule || this.loadingRuleWithCampaign;
    },
  },
  methods: {
    switchOnlyRule() {
      if (this.isLoading) {
        return;
      }
      this.loadingRule = true;

      const promise = RuleService.changeStatus(this.id, this.newStatus);
      this.handlePromise(promise);
    },
    switchRuleWithCampaign() {
      if (this.isLoading) {
        return;
      }
      this.loadingRuleWithCampaign = true;

      const promise = RuleService.changeStatus(this.id, this.newStatus, true);
      this.handlePromise(promise);
    },
    onSuccess(newValues) {
      this.rule.updateWith(newValues);
      this.$modal.hide('rule-switch-off-modal');
    },
    handlePromise(promise) {
      promise.then(rule => this.onSuccess(rule))
        .catch(err => {
          this.error(err);
          this.rule.active = !this.newStatus;
        })
        .finally(() => {
          this.loadingRule = false;
          this.loadingRuleWithCampaign = false;
        });
    },
  },
};
</script>
