<template>
  <form-block
    class="rule-form-block target-audience-block-component"
    :title="tr('Target audience')"
  >
    <template #top-right>
      <refresh-button
        v-if="!entity.disconnectedAdAccount"
        :label="tr('Update audiences')"
        :success-message="tr('Saved and custom target audiences updated successfully.')"
        :promise="() => refreshAudiencesInSelectedAdAccount()"
      />
    </template>

    <info-text v-if="entity.disconnectedAdAccount" type="error">
      {{ tr('The selected ad account has been disconnected.') }}
    </info-text>
    <template v-else>
      <div class="description" v-html="description" />

      <div>
        <label class="input-label">
          <input
            v-model="entity.settings.adset_targeting_advantage_plus_audience"
            :disabled="entity.output_campaign.hasSpecialAdCategories"
            type="checkbox"
          />
          {{ tr('Advantage+ Audience') }}
        </label>

        <info-text v-if="entity.output_campaign.hasSpecialAdCategories">
          {{ tr('Advantage+ Audience disabled because Special Ad Categories were set in the campaign settings.') }}
        </info-text>
      </div>

      <div>
        <label class="input-label">
          {{ tr('Use saved target audiences') }}
        </label>
        <multiple-audience-select
          v-model="entity.settings.adset_targeting_audiences"
          :external-audiences="savedAudiences"
          :disabled="disabled"
          :is-loading="isLoading"
        />
      </div>

      <div>
        <label class="input-label">
          {{ tr('Use custom audiences') }}
        </label>
        <multi-select
          v-model="entity.settings.adset_included_custom_audiences"
          :options="customAudiences"
          :loading="isLoading"
          :placeholder="tr('Search existing custom audiences')"
          :disabled="disabled"
          color="#94DA88"
          label-key="name"
          value-key="id"
          :disabled-option-tooltip="tr('The audience is empty')"
        />
      </div>

      <div>
        <label class="input-label">
          {{ tr('Exclude') }}
        </label>
        <multi-select
          v-model="entity.settings.adset_excluded_custom_audiences"
          :options="customAudiences"
          :loading="isLoading"
          :placeholder="tr('Search existing custom audiences')"
          :disabled="disabled"
          color="#FF7272"
          label-key="name"
          value-key="id"
          :disabled-option-tooltip="tr('The audience is empty')"
        />
      </div>
    </template>
  </form-block>
</template>

<script>
import InfoText from '@/components/InfoText.vue';
import MultiSelect from '@/components/MultiSelect.vue';
import MultipleAudienceSelect from '@/components/MultipleAudienceSelect.vue';
import RefreshButton from '@/components/RefreshButton.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'TargetAudienceBlock',
  components: {
    InfoText,
    MultipleAudienceSelect,
    MultiSelect,
    RefreshButton,
  },
  mixins: [ RuleFormBlockMixin ],
  computed: {
    facebookAudienceManagerLink() {
      if (!this.entity.selectedAdAccount) {
        return null;
      }

      const id = this.entity.selectedAdAccount.external_id.split('_').pop();
      return 'https://adsmanager.facebook.com/adsmanager/audiences?act=' + id;
    },
    description() {
      if (!this.facebookAudienceManagerLink) {
        return null;
      }

      const here = '<a href="' + this.facebookAudienceManagerLink + '" target="_blank" class="facebook-link bigger">' + this.tr('here') + '</a>';

      return this.tr(
        'You can create your target audience(s) in Facebook Business Manager. ' +
        'If you have already created them before, you can select them from the dropdown list to use. ' +
        'If you haven`t created any target audience yet, you can do so by clicking :here',
        { ':here': here },
      );
    },
    isLoading() {
      return this.$store.getters['ruleForm/loadingAudiences'];
    },
    audiences() {
      return this.$store.getters['ruleForm/audiences'];
    },
    customAudiences() {
      return this.audiences.filter(i => i.type === 'custom');
    },
    savedAudiences() {
      return this.audiences.filter(i => i.type === 'saved');
    },
    selectedCustomAudiencesAreValid() {
      const included = this.entity.settings.adset_included_custom_audiences;
      const excluded = this.entity.settings.adset_excluded_custom_audiences;

      for (const item of included) {
        if (excluded.includes(item)) {
          return false;
        }
      }

      return true;
    },
    hasGeotargeting() {
      const selectedAudiences = this.savedAudiences.filter(i => this.entity.settings.adset_targeting_audiences.includes(i.id));
      for (const audience of selectedAudiences) {
        if (audience.has_geotargeting) {
          return true;
        }
      }

      return false;
    },
    hasGenderTargeting() {
      const selectedAudiences = this.savedAudiences.filter(i => this.entity.settings.adset_targeting_audiences.includes(i.id));
      for (const audience of selectedAudiences) {
        if (audience.has_gender_targeting) {
          return true;
        }
      }

      return false;
    },
    hasAgeTargeting() {
      const selectedAudiences = this.savedAudiences.filter(i => this.entity.settings.adset_targeting_audiences.includes(i.id));
      for (const audience of selectedAudiences) {
        if (audience.has_age_targeting) {
          return true;
        }
      }

      return false;
    },
  },
  watch: {
    hasGeotargeting() {
      this.entity.settings.adset_targeting.geo_locations.clear();
      this.entity.settings.adset_targeting.excluded_geo_locations.clear();
    },
    hasAgeTargeting: {
      immediate: true,
      handler(val) {
        if (val) {
          this.entity.settings.adset_targeting.age_min = null;
          this.entity.settings.adset_targeting.age_max = null;
        }
        else if (
          this.entity.settings.adset_targeting.age_min === null
          || this.entity.settings.adset_targeting.age_max === null
        ) {
          this.entity.settings.adset_targeting.age_min = 18;
          this.entity.settings.adset_targeting.age_max = 65;
        }
      },
    },
    hasGenderTargeting: {
      immediate: true,
      handler(val) {
        if (val) {
          this.entity.settings.adset_targeting.genders = null;
        }
        else if (this.entity.settings.adset_targeting.genders === null) {
          this.entity.settings.adset_targeting.genders = 'all';
        }
      },
    },
    selectedCustomAudiencesAreValid(val) {
      if (!val) {
        this.error('A custom audience cannot be used as included and excluded at the same time.');
      }
    },
  },
  methods: {
    validate() {
      if (!this.selectedCustomAudiencesAreValid) {
        this.error('A custom audience cannot be used as included and excluded at the same time.');
        return false;
      }

      return true;
    },
    refreshAudiencesInSelectedAdAccount() {
      if (!this.entity.ad_account.id) {
        return Promise.resolve();
      }

      return this.$store.dispatch('ruleForm/fetchAudiences', this.entity.ad_account.id);
    },
  },
};
</script>
