var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class:
        "dashboard-buzz-view container " + (_vm.isLoading ? "loading" : ""),
    },
    [
      _c("div", { staticClass: "card filters" }, [
        _c(
          "div",
          { staticClass: "filter" },
          [
            _c("label", [_vm._v(_vm._s(_vm.tr("Datasource")))]),
            _c("v-select", {
              staticClass: "source-select",
              attrs: {
                options: _vm.sourceProfileList,
                reduce: (option) => option.id,
                clearable: false,
                label: "name",
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function (option) {
                    return [
                      _c("span", [
                        option.platform
                          ? _c("i", { class: "icon " + option.platform })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(option.name) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "selected-option",
                  fn: function (option) {
                    return [
                      _c("span", [
                        option.platform
                          ? _c("i", { class: "icon " + option.platform })
                          : _vm._e(),
                        _vm._v(" " + _vm._s(option.name) + " "),
                      ]),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.sourceProfileId,
                callback: function ($$v) {
                  _vm.sourceProfileId = $$v
                },
                expression: "sourceProfileId",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter" },
          [
            _c("label", [_vm._v(_vm._s(_vm.tr("Date range")))]),
            _c(
              "div",
              { staticClass: "date-from-to" },
              [
                _c("date-input", {
                  ref: "filterDateFrom",
                  on: { focus: () => (_vm.showDatepicker = true) },
                  model: {
                    value: _vm.dateRange.start,
                    callback: function ($$v) {
                      _vm.$set(_vm.dateRange, "start", $$v)
                    },
                    expression: "dateRange.start",
                  },
                }),
                _vm._v(" – "),
                _c("date-input", {
                  ref: "filterDateTo",
                  on: { focus: () => (_vm.showDatepicker = true) },
                  model: {
                    value: _vm.dateRange.end,
                    callback: function ($$v) {
                      _vm.$set(_vm.dateRange, "end", $$v)
                    },
                    expression: "dateRange.end",
                  },
                }),
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "datepicker-show-hide" } },
              [
                _c("v-date-picker", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDatepicker,
                      expression: "showDatepicker",
                    },
                  ],
                  ref: "filterDatepicker",
                  attrs: {
                    mode: "date",
                    "title-position": "left",
                    locale: _vm.language,
                    masks: { weekdays: "WW" },
                    "is-range": "",
                  },
                  model: {
                    value: _vm.dateRange,
                    callback: function ($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.dashboardData
        ? [
            _c("div", { staticClass: "charts left" }, [
              _c("div", { staticClass: "card card--horizontal chart-card" }, [
                _c("div", { staticClass: "card--horizontal-left" }, [
                  _c("div", { staticClass: "card-top card-title" }, [
                    _vm._v(_vm._s(_vm.tr("Comment statistics"))),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "card-subitem-title" }, [
                      _vm._v(_vm._s(_vm.tr("Reviewed comments"))),
                    ]),
                    _c("div", { staticClass: "card-subitem-value" }, [
                      _vm._v(_vm._s(_vm.reviewedCommentsSum)),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "card-subitem-title" }, [
                      _vm._v(_vm._s(_vm.tr("Moderated comments"))),
                    ]),
                    _c("div", { staticClass: "card-subitem-value" }, [
                      _vm._v(_vm._s(_vm.moderatedCommentsSum)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card--horizontal-right" }, [
                  _c("div", { staticClass: "card-top legend" }, [
                    _c("div", { staticClass: "legend--item" }, [
                      _c("div", { staticClass: "line primary" }),
                      _vm._v(" " + _vm._s(_vm.tr("Moderated comments")) + " "),
                    ]),
                    _c("div", { staticClass: "legend--item" }, [
                      _c("div", { staticClass: "line" }),
                      _vm._v(" " + _vm._s(_vm.tr("Reviewed comments")) + " "),
                    ]),
                  ]),
                  _c("div", { ref: "statChartFrame" }, [
                    _c("svg", { ref: "statChart" }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "card card--horizontal chart-card" }, [
                _c("div", { staticClass: "card--horizontal-left" }, [
                  _c("div", { staticClass: "card-top card-title" }, [
                    _vm._v(" " + _vm._s(_vm.tr("Quality score")) + " "),
                    _c("div", { staticClass: "card-subitem-title" }, [
                      _vm._v("(" + _vm._s(_vm.tr("Higher is better")) + ")"),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "card-subitem-title" }, [
                      _vm._v(_vm._s(_vm.tr("Avg. Score"))),
                    ]),
                    _c("div", { staticClass: "card-subitem-value" }, [
                      _vm._v(_vm._s(_vm.totalQualityScore)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "card--horizontal-right" }, [
                  _c("div", { staticClass: "card-top legend" }, [
                    _c("div", { staticClass: "legend--item" }, [
                      _c("div", { staticClass: "line primary" }),
                      _vm._v(" " + _vm._s(_vm.tr("Quality score")) + " "),
                    ]),
                  ]),
                  _c("div", { ref: "qualityScoreChartFrame" }, [
                    _c("svg", { ref: "qualityScoreChart" }),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _vm.showReopenOnboardingButton
                ? _c(
                    "button",
                    {
                      staticClass: "reopen-onboarding-button primary",
                      on: { click: _vm.reopenOnboarding },
                    },
                    [
                      _c("i", { staticClass: "icon magic white" }),
                      _vm._v(
                        " " + _vm._s(_vm.tr("Open Onboarding Wizard")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "card latest-activities-card" }, [
                _c("div", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.tr("Your latest Activities"))),
                ]),
                _c(
                  "div",
                  { staticClass: "list-wrapper" },
                  _vm._l(
                    _vm.dashboardData.latest_activities,
                    function (comment) {
                      return _c(
                        "div",
                        {
                          key: "latest-activities--" + comment.id,
                          staticClass: "item",
                        },
                        [
                          _c("div", { staticClass: "icon-wrapper" }, [
                            _c("i", {
                              class:
                                "icon white " +
                                (comment.is_hidden_by_ai
                                  ? "shield-ok"
                                  : "heart-filled"),
                            }),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "title",
                              attrs: {
                                to: "/moderation/post/" + comment.post.id,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.buildActivityHeader(comment)) +
                                  " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "message" }, [
                            _vm._v(" " + _vm._s(comment.shortMessage) + " "),
                          ]),
                          _c("div", { staticClass: "details" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.tr("Classification")) + ":"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.tr(comment.aiResultText))),
                            _c("br"),
                            _c("label", [
                              _vm._v(_vm._s(_vm.tr("Moderated by Ai")) + ":"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  comment.is_hidden_by_ai
                                    ? _vm.tr("yes")
                                    : _vm.tr("no")
                                ) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
          ]
        : _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [
            _c("i", { staticClass: "icon loader" }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }