import pluralize from 'pluralize';

import BaseEntity from '@/entities/BaseEntity';
import FacebookTargetingGeolocationCountry from '@/descriptors/FacebookTargetingGeolocationCountry';
import FacebookTargetingGeolocationRegion from '@/descriptors/FacebookTargetingGeolocationRegion';
import FacebookTargetingGeolocationCity from '@/descriptors/FacebookTargetingGeolocationCity';
import FacebookTargetingGeolocationPlace from '@/descriptors/FacebookTargetingGeolocationPlace';

export default class FacebookTargetingGeolocations extends BaseEntity
{
  countries = [];
  regions = [];
  cities = [];
  places = [];

  _entityArrayFields = {
    countries: FacebookTargetingGeolocationCountry,
    regions: FacebookTargetingGeolocationRegion,
    cities: FacebookTargetingGeolocationCity,
    places: FacebookTargetingGeolocationPlace,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  add(type, key) {
    let className = null;
    switch (type) {
      case 'country':
        className = FacebookTargetingGeolocationCountry;
        break;

      case 'region':
        className = FacebookTargetingGeolocationRegion;
        break;

      case 'city':
        className = FacebookTargetingGeolocationCity;
        break;

      case 'place':
        className = FacebookTargetingGeolocationPlace;
        break;
    }

    if (className === null) {
      throw new Error('Unkown ad geolocation type: ' + type);
    }

    let propertyName = pluralize(type);
    const dataset = this[propertyName];
    if (dataset.map(item => item.key).includes(key)) {
      return;
    }

    const descriptorInstance = new className({ key });

    dataset.push(descriptorInstance);

    return descriptorInstance;
  }

  remove(type, key) {
    let propertyName = pluralize(type);
    this[propertyName] = this[propertyName].filter(item => item.key !== key);
  }

  clear() {
    Object.keys(this._entityArrayFields).forEach(property => this[property] = []);
  }

  get all() {
    let result = [
      ...this.countries,
      ...this.regions,
      ...this.cities,
      ...this.places,
    ];

    return result;
  }

  get hasOverlaps() {
    for (let item of this.all) {
      if ('primary_city_id' in item.meta && this._isSelected('cities', item.meta.primary_city_id)) {
        return true;
      }
      if ('region_id' in item.meta && this._isSelected('regions', item.meta.region_id)) {
        return true;
      }
      if ('country_code' in item.meta && this._isSelected('countries', item.meta.country_code)) {
        return true;
      }
    }

    return false;
  }

  _isSelected(dataset, key) {
    for (let item of this[dataset]) {
      if (item.key == key) {
        return true;
      }
    }

    return false;
  }

  parentSelected(item) {
    if (item.type === 'country') {
      return false;
    }

    if (item.type === 'place' || item.type === 'city' || item.type === 'region') {
      if (this.countries.map(item => item.key).includes(item.meta.country_code)) {
        return true;
      }
    }
    if (item.type === 'place' || item.type === 'city') {
      if (this.regions.map(item => item.key).includes(item.meta.region_id)) {
        return true;
      }
    }
    if (item.type === 'place') {
      if (this.cities.map(item => item.key).includes(item.meta.primary_city_id)) {
        return true;
      }
    }

    return false;
  }
}
