<template>
  <div :class="'dashboard-buzz-view container ' + (isLoading ? 'loading' : '')">
    <div class="card filters">
      <div class="filter">
        <label>{{ tr('Datasource') }}</label>
        <v-select
          v-model="sourceProfileId"
          :options="sourceProfileList"
          :reduce="(option) => option.id"
          :clearable="false"
          label="name"
          class="source-select"
        >
          <template #option="option">
            <span>
              <i v-if="option.platform" :class="'icon ' + option.platform" />
              {{ option.name }}
            </span>
          </template>
          <template #selected-option="option">
            <span>
              <i v-if="option.platform" :class="'icon ' + option.platform" />
              {{ option.name }}
            </span>
          </template>
        </v-select>
      </div>

      <div class="filter">
        <label>{{ tr('Date range') }}</label>
        <div class="date-from-to">
          <date-input
            ref="filterDateFrom"
            v-model="dateRange.start"
            @focus="() => showDatepicker = true" />
          –
          <date-input
            ref="filterDateTo"
            v-model="dateRange.end"
            @focus="() => showDatepicker = true" />
        </div>


        <transition name="datepicker-show-hide">
          <v-date-picker
            v-show="showDatepicker"
            v-model="dateRange"
            ref="filterDatepicker"
            mode="date"
            title-position="left"
            :locale="language"
            :masks="{ weekdays: 'WW' }"
            is-range
          />
        </transition>
      </div>
    </div>

    <template v-if="dashboardData">
      <div class="charts left">
        <div class="card card--horizontal chart-card">
          <div class="card--horizontal-left">
            <div class="card-top card-title">{{ tr('Comment statistics') }}</div>

            <div>
              <div class="card-subitem-title">{{ tr('Reviewed comments') }}</div>
              <div class="card-subitem-value">{{ reviewedCommentsSum }}</div>
            </div>

            <div>
              <div class="card-subitem-title">{{ tr('Moderated comments') }}</div>
              <div class="card-subitem-value">{{ moderatedCommentsSum }}</div>
            </div>
          </div>

          <div class="card--horizontal-right">
            <div class="card-top legend">
              <div class="legend--item">
                <div class="line primary" />
                {{ tr('Moderated comments') }}
              </div>

              <div class="legend--item">
                <div class="line" />
                {{ tr('Reviewed comments') }}
              </div>
            </div>

            <div ref="statChartFrame">
              <svg ref="statChart"></svg>
            </div>
          </div>
        </div>

        <div class="card card--horizontal chart-card">
          <div class="card--horizontal-left">
            <div class="card-top card-title">
              {{ tr('Quality score') }}
              <div class="card-subitem-title">({{ tr('Higher is better') }})</div>
            </div>

            <div>
              <div class="card-subitem-title">{{ tr('Avg. Score') }}</div>
              <div class="card-subitem-value">{{ totalQualityScore }}</div>
            </div>
          </div>

          <div class="card--horizontal-right">
            <div class="card-top legend">
              <div class="legend--item">
                <div class="line primary" />
                {{ tr('Quality score') }}
              </div>
            </div>

            <div ref="qualityScoreChartFrame">
              <svg ref="qualityScoreChart"></svg>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <button
          v-if="showReopenOnboardingButton"
          @click="reopenOnboarding"
          class="reopen-onboarding-button primary"
        >
          <i class="icon magic white" />
          {{ tr('Open Onboarding Wizard') }}
        </button>

        <div class="card latest-activities-card">
          <div class="card-title">{{ tr('Your latest Activities') }}</div>
          <div class="list-wrapper">
            <div v-for="comment in dashboardData.latest_activities" :key="'latest-activities--' + comment.id" class="item">
              <div class="icon-wrapper">
                <i :class="'icon white ' + (comment.is_hidden_by_ai ? 'shield-ok' : 'heart-filled')" />
              </div>

              <router-link :to="'/moderation/post/' + comment.post.id" class="title">
                {{ buildActivityHeader(comment) }}
              </router-link>

              <div class="message">
                {{ comment.shortMessage }}
              </div>

              <div class="details">
                <label>{{ tr('Classification') }}:</label> {{ tr(comment.aiResultText) }}<br>
                <label>{{ tr('Moderated by Ai') }}:</label> {{ comment.is_hidden_by_ai ? tr('yes') : tr('no') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else-if="isLoading" class="loader">
      <i class="icon loader" />
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/DateInput.vue';

import DashboardMixin from '@/mixins/Dashboard.js';
import CompanyService from '@/services/Company.js';

import ChartCircle from '@/chart/chartCircle';
import ChartLine from '@/chart/chartLine';
import ChartTooltip from '@/chart/chartTooltip';
import ChartDataDetails from '@/chart/chartDataDetails';
import LineChart from '@/chart/lineChart';

import OnboardingBuzz from '@/components/OnboardingBuzz.vue';

import { timeAgo } from '@/utils/date';

const now = new Date();
const nowMinus30Day = new Date();
nowMinus30Day.setDate(now.getDate() - 30);

export default {
  name: 'DashboardBuzzView',
  mixins: [ DashboardMixin ],
  components: {
    DateInput,
  },
  data: () => ({
    sourceProfileId: null,
    dateRange: {
      start: nowMinus30Day,
      end: now,
    },
    showDatepicker: false,
    isLoading: false,
    dashboardData: {},
  }),
  created() {
    this.loadData();

    window.addEventListener('resize', this.renderChart);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderChart);
  },
  computed: {
    language() {
      return this.$store.getters['app/language'];
    },
    showReopenOnboardingButton() {
      if (!this.dashboardData) {
        return false;
      }

      return !this.dashboardData.profile_connected ||
        !this.dashboardData.source_profile_connected;
    },
    reviewedCommentsSum() {
      const value = this.sumValue('reviewed_comments');
      if (value === 0) {
        return '-';
      }

      return new Intl.NumberFormat('en-US').format(value);
    },
    moderatedCommentsSum() {
      const value = this.sumValue('moderated_comments');
      if (value === 0) {
        return '-';
      }

      return new Intl.NumberFormat('en-US').format(value);
    },
    totalQualityScore() {
      if (
        !this.dashboardData
        || !this.dashboardData.total_quality_score
      ) {
        return '-';
      }

      return this.dashboardData.total_quality_score.toFixed(2);
    },
    sourceProfileList() {
      let list = this.$root.currentCompany.source_profiles;
      if (this.$root.loggedInUser.isRestricted) {
        list = list.filter(item => this.$root.loggedInUser.visible_source_profile_ids.includes(item.id));
      }

      const options = [{ id: null, name: 'All datasources' }];
      options.push(...list);

      return options;
    },
  },
  watch: {
    sourceProfileId() {
      this.loadData();
    },
    'dateRange.start'() {
      this.loadData();
    },
    'dateRange.end'() {
      this.loadData();
    },
    showDatepicker(val) {
      if (val) {
        window.addEventListener('click', this.datepickerAutoCloseHandler);
      }
      else {
        window.removeEventListener('click', this.datepickerAutoCloseHandler);
      }
    }
  },
  methods: {
    timeAgo,
    sumValue(key) {
      let sum = 0;
      this.dashboardData[key].forEach(item => sum += item.value);

      return sum;
    },
    loadData() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      CompanyService.getDashboardBuzz(this.$root.currentCompany.id, {
        sourceProfileId: this.sourceProfileId,
        dateFrom: this.dateRange.start,
        dateTo: this.dateRange.end,
      })
        .then((result) => {
          this.dashboardData = result;
        })
        .then(() => this.renderCharts())
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    renderCharts() {
      this.renderStatChart();
      this.renderQualityScoreChart();
    },
    renderStatChart() {
      if (this.dashboardData === null || !this.$refs.statChart) {
        return;
      }

      this.$refs.statChart.innerHTML = '';
      const chart = new LineChart(this.$refs.statChart, this.$refs.statChartFrame.offsetWidth, 200);

      const hoverVerticalLine = new ChartLine(chart);
      hoverVerticalLine.stroke = 'primary';
      hoverVerticalLine.y2 = 'calc(100% - 20px)';

      const dataModerated = new ChartDataDetails(this.dashboardData.moderated_comments, 'date', 'value');
      dataModerated.color = 'primary';
      dataModerated.tooltip = new ChartTooltip(chart, this.tr('Moderated comment'), this.tr('Moderated comments'));
      dataModerated.tooltip.position = 'bottom';
      dataModerated.verticalLineOnHoverElement = hoverVerticalLine.draw();

      const dataReviewed = new ChartDataDetails(this.dashboardData.reviewed_comments, 'date', 'value');
      dataReviewed.color = 'black';
      dataReviewed.tooltip = new ChartTooltip(chart, this.tr('Reviewed comment'), this.tr('Reviewed comments'));

      chart.addData(dataReviewed);
      chart.addData(dataModerated);
      chart.verticalLineOnHover = hoverVerticalLine;
      chart.render();

      const dataModeratedOnHoverCircle = new ChartCircle(chart);
      dataModeratedOnHoverCircle.stroke = dataModerated.color;

      const dataReviewedOnHoverCircle = new ChartCircle(chart);
      dataReviewedOnHoverCircle.stroke = dataReviewed.color;

      dataReviewed.circleOnHoverElement = dataReviewedOnHoverCircle.draw();
      dataModerated.circleOnHoverElement = dataModeratedOnHoverCircle.draw();

      dataModerated.hideOnHoverDetails();
      dataReviewed.hideOnHoverDetails();
    },
    renderQualityScoreChart() {
      if (this.dashboardData === null || !this.$refs.qualityScoreChart) {
        return;
      }

      this.$refs.qualityScoreChart.innerHTML = '';
      const chart = new LineChart(this.$refs.qualityScoreChart, this.$refs.qualityScoreChartFrame.offsetWidth, 200);

      const hoverVerticalLine = new ChartLine(chart);
      hoverVerticalLine.stroke = 'primary';
      hoverVerticalLine.y2 = 'calc(100% - 20px)';

      const avgLine = new ChartLine(chart);
      avgLine.stroke = 'primary';
      avgLine.x1 = '0';
      avgLine.x2 = '100%';
      avgLine.y1 = (100 - this.dashboardData.total_quality_score * 10) + '%';
      avgLine.y2 = avgLine.y1;
      avgLine.draw();

      const lineData = new ChartDataDetails(this.dashboardData.quality_scores, 'date', 'value');
      lineData.color = 'primary';
      lineData.tooltip = new ChartTooltip(chart, this.tr('Daily score'), this.tr('Daily score'));
      lineData.tooltip.position = 'bottom';
      lineData.verticalLineOnHoverElement = hoverVerticalLine.draw();

      chart.addData(lineData);
      chart.verticalLineOnHover = hoverVerticalLine;
      chart.render();

      const onHoverCircle = new ChartCircle(chart);
      onHoverCircle.stroke = lineData.color;

      lineData.circleOnHoverElement = onHoverCircle.draw();

      lineData.hideOnHoverDetails();
    },
    buildActivityHeader(comment) {
      const ago = timeAgo(comment.created_at, this.tr);
      let platform = '';
      switch (comment.source_profile.platform) {
        case 'facebook':
          platform = this.tr('Facebook Page');
          break;

        case 'instagram':
          platform = this.tr('Instagram Account');
          break;
      }

      const params = {
        ':ago': ago,
        ':name': comment.source_profile.name,
        ':platform': platform,
      };

      return this.tr(':ago on :name :platform', params)
    },
    datepickerAutoCloseHandler(event) {
      if (
        event.target !== this.$refs.filterDateFrom
        && event.target !== this.$refs.filterDateTo
        && event.target !== this.$refs.filterDatepicker
        && !this.$refs.filterDateFrom.$el.contains(event.target)
        && !this.$refs.filterDateTo.$el.contains(event.target)
        && !this.$refs.filterDatepicker.$el.contains(event.target)
      ) {
        this.showDatepicker = false;
      }
    },
    reopenOnboarding() {
      const props = {
        skipFacebookProfile: this.dashboardData.profile_connected,
      };

      this.$modal.show(
        OnboardingBuzz,
        props,
        {
          name: 'onboarding-modal',
          class: 'onboarding-modal',
          clickToClose: false,
        }
      );
    },
  }
};
</script>
