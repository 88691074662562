import Vue from 'vue';
import Vuex from 'vuex';

import app from '@/store/modules/app.js';
import auth from '@/store/modules/auth.js';
import dashboard from '@/store/modules/dashboard.js';
import invites from '@/store/modules/invites.js';
import magicTool from '@/store/modules/magicTool.js';
import posts from '@/store/modules/posts.js';
import protectedPosts from '@/store/modules/protectedPosts.js';
import ruleForm from '@/store/modules/ruleForm.js';
import rules from '@/store/modules/rules.js';
import support from '@/store/modules/support.js';

Vue.use(Vuex);

const modules = {
  app,
  auth,
  dashboard,
  invites,
  magicTool,
  posts,
  protectedPosts,
  ruleForm,
  rules,
};

if (process.env.VUE_APP_SUPPORT_BUILD == 1) {
  modules.support = support;
}

export const store = new Vuex.Store({
  modules,
  plugins: [],
});
