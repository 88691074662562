<template>
  <div class="account-view">
    <sticky-header :title="tr('Account')" bordered>
      <template #buttons>
        <button
          class="primary"
          @click="() => save()"
          :loading="isSaving"
        >
          <i class="icon loader white" v-if="isSaving" />
          <template v-else>
            {{ tr('Save') }}
          </template>
        </button>
      </template>
    </sticky-header>


    <form-block
      :title="tr('Basic informations')"
      class="account-view--basic-informations"
    >
      <div class="input-wrapper input-wrapper--first-name">
        <label class="input-label">{{ tr('First name') }}</label>
        <input
          type="text"
          v-model="firstname"
          :class="inputClasses('firstname')"
        />
      </div>

      <div class="input-wrapper input-wrapper--last-name">
        <label class="input-label">{{ tr('Last name') }}</label>
        <input
          type="text"
          v-model="lastname"
          :class="inputClasses('lastname')"
        />
      </div>
    </form-block>

    <div class="divider" />

    <form-block
      :title="tr('Login details')"
    >
      <div>
        <label class="input-label">{{ tr('E-mail') }}</label>
        <input
          type="email"
          v-model="email"
          :class="inputClasses('email')"
        />
      </div>

      <div>
        <label class="input-label">{{ tr('Password') }}</label>
        <button
          class="primary"
          @click="() => $refs['changePasswordModal'].open()"
        >
          {{ tr('Change password') }}
        </button>
      </div>

      <div>
        <label class="input-label">{{ tr('Two-factor authentication') }}</label>

        <label>
          <input
            v-model="$root.loggedInUser.google_2fa_enabled"
            type="checkbox"
            @click="($e) => toggle2fa($e)"
            readonly
            :disabled="disabled2faCheckbox"
          />
          {{ $root.loggedInUser.google_2fa_enabled ? tr('Google 2FA enabled') : tr('Enable Google 2FA') }}
        </label>
      </div>

      <info-text v-if="disabled2faCheckbox">
        {{ tr('For users in the organization, the use of two-factor authentication is teasing.') }}
      </info-text>
    </form-block>

    <change-password-modal ref="changePasswordModal" />

    <modal
      v-if="!$root.loggedInUser.google_2fa_enabled"
      name="enable-google-2fa-modal"
      overlayTransition="fade-modal-overlay"
    >
      <enable-google-2fa
        @success="() => $modal.hide('enable-google-2fa-modal')"
        @cancel="() => $modal.hide('enable-google-2fa-modal')"
      />
    </modal>
    <modal
      v-else-if="!disabled2faCheckbox"
      name="disable-google-2fa-modal"
      overlayTransition="fade-modal-overlay"
    >
      <disable-google-2fa
        @cancel="() => $modal.hide('disable-google-2fa-modal')"
        @success="() => $modal.hide('disable-google-2fa-modal')"
      />
    </modal>
  </div>
</template>
<script>
import ChangePasswordModal from '@/components/ChangePasswordModal.vue';
import DisableGoogle2fa from '@/components/DisableGoogle2fa.vue';
import EnableGoogle2fa from '@/components/EnableGoogle2fa.vue';
import FormBlock from '@/components/FormBlock.vue';
import InfoText from '@/components/InfoText.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import { isValidEmail } from '@/utils/validators';

export default {
  name: 'AccountView',
  components: {
    ChangePasswordModal,
    DisableGoogle2fa,
    EnableGoogle2fa,
    FormBlock,
    InfoText,
    StickyHeader,
  },
  data:() => ({
    isSaving: false,
    firstname: '',
    lastname: '',
    email: '',
    errorField: '',
    changePasswordIsLoading: false,

    showDisable2faContent: false,
  }),
  computed: {
    loggedinUser() {
      return this.$root.loggedInUser;
    },
    disabled2faCheckbox() {
      return this.$root.currentCompany.required_2fa && this.$root.loggedInUser.google_2fa_enabled;
    },
  },
  created() {
    this.email = this.loggedinUser.email ?? '';
    this.firstname = this.loggedinUser.firstname ?? '';
    this.lastname = this.loggedinUser.lastname ?? '';
  },
  methods: {
    save() {
      if (this.isSaving || !this.validate()) {
        return;
      }

      this.isSaving = true;

      this.$store.dispatch('auth/updateAccount', {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
      })
      .then(() => {
        this.$toast.success(this.tr('Successful save'));
      })
      .catch((err) => this.error(this.tr('An error occurred while saving the account details') + ':<br/>' + err.message))
      .finally(() => (this.isSaving = false))
    },
    validate() {
      this.$toast.clear();
      this.errorField = '';

      this.firstname = this.firstname.trim();
      this.lastname = this.lastname.trim();
      this.email = this.email.trim();

      if (!this.firstname) {
        this.errorField = 'firstname';
        this.$toast.error('Cannot leave name empty!');
        return false;
      }
      if (!this.lastname) {
        this.errorField = 'lastname';
        this.$toast.error('Cannot leave name empty!');
        return false;
      }

      if (!this.email) {
        this.errorField = 'email';
        this.$toast.error('Cannot leave e-mail empty!');
        return false;
      }

      if (!isValidEmail(this.email)) {
        this.errorField = 'email';
        this.$toast.error('Invalid e-mail given!');
        return false;
      }

      return true;
    },
    inputClasses(checkedClass) {
      return this.errorField == checkedClass ? 'error' : '';
    },
    toggle2fa(event) {
      event.preventDefault();
      if (this.disabled2faCheckbox) {
        return;
      }

      if (!this.$root.loggedInUser.google_2fa_enabled) {
        this.$modal.show('enable-google-2fa-modal');
      }
      else {
        this.$modal.show('disable-google-2fa-modal');
      }
    },
  }
};
</script>
