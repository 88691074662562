var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-4",
    },
    [
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Manage original ad"),
            description: _vm.tr(
              "You can set whether the scaled-up original ad should continue running or stop automatically."
            ),
          },
        },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.stop_original,
                    expression: "entity.settings.stop_original",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "keep-or-stop",
                  disabled: _vm.$parent.readonlyMode,
                },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.entity.settings.stop_original, false),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.entity.settings, "stop_original", false)
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Keep original ad running")) + " "),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.stop_original,
                    expression: "entity.settings.stop_original",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "keep-or-stop",
                  disabled: _vm.$parent.readonlyMode,
                },
                domProps: {
                  value: true,
                  checked: _vm._q(_vm.entity.settings.stop_original, true),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.entity.settings, "stop_original", true)
                  },
                },
              }),
              _vm._v(
                " " + _vm._s(_vm.tr("Stop original ad automatically")) + " "
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        { attrs: { title: _vm.tr("Ad creation method") } },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.ad_creation_method,
                    expression: "entity.settings.ad_creation_method",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "ad-creation-method",
                  value: "copy",
                  disabled: _vm.$parent.readonlyMode,
                },
                domProps: {
                  checked: _vm._q(
                    _vm.entity.settings.ad_creation_method,
                    "copy"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.entity.settings,
                      "ad_creation_method",
                      "copy"
                    )
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Copy original ad")) + " "),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.ad_creation_method,
                    expression: "entity.settings.ad_creation_method",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "ad-creation-method",
                  value: "creative",
                  disabled: _vm.$parent.readonlyMode,
                },
                domProps: {
                  checked: _vm._q(
                    _vm.entity.settings.ad_creation_method,
                    "creative"
                  ),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(
                      _vm.entity.settings,
                      "ad_creation_method",
                      "creative"
                    )
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Create new ad creative(s)")) + " "),
            ]),
          ]),
          _vm.entity.settings.ad_creation_method === "creative"
            ? [
                _c("div", [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.tr("AI Improvements"))),
                  ]),
                  _c("div", { staticClass: "description" }, [
                    _vm._v(
                      " Könnyedén rábízhatod az Infinite∞Ai-ra, hogy automatikusan optimalizálja a hirdetéseid egyes elemeit, így hatékonyabbá téve a kampányaidat. Az Ai a célközönséged jellemzői és a hirdetésed kontextusa alapján készíti el és állítja be a különböző részeket, hogy a lehető legjobban szólítsa meg a közönségedet. "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "checkbox-list vertical" },
                    _vm._l(_vm.aiImprovementFields, function (item, key) {
                      return _c(
                        "label",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.right",
                              value: _vm.tr(item.description),
                              expression: "tr(item.description)",
                              modifiers: { right: true },
                            },
                          ],
                          key: "scaling-up-settins--ai--" + key,
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.aiImprovements,
                                expression: "aiImprovements",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.$parent.readonlyMode,
                            },
                            domProps: {
                              value: item.machine_name,
                              checked: Array.isArray(_vm.aiImprovements)
                                ? _vm._i(
                                    _vm.aiImprovements,
                                    item.machine_name
                                  ) > -1
                                : _vm.aiImprovements,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.aiImprovements,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = item.machine_name,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.aiImprovements = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.aiImprovements = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.aiImprovements = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" " + _vm._s(item.label) + " "),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        " " + _vm._s(_vm.tr("Number of Ad Variations")) + " "
                      ),
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "You can specify how many different variations should be created from the ad that will be scaled up."
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("number-input", {
                      staticClass: "ad-variations-number",
                      attrs: {
                        step: 1,
                        min: 1,
                        max: 5,
                        disabled: _vm.$parent.readonlyMode,
                      },
                      model: {
                        value: _vm.entity.settings.ad_variants,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity.settings, "ad_variants", $$v)
                        },
                        expression: "entity.settings.ad_variants",
                      },
                    }),
                  ],
                  1
                ),
                _vm.entity.settings.ad_variants > 1
                  ? _c(
                      "div",
                      [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.entity.settings
                                    .ai_ad_with_original_content,
                                expression:
                                  "entity.settings.ai_ad_with_original_content",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.$parent.readonlyMode,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.entity.settings.ai_ad_with_original_content
                              )
                                ? _vm._i(
                                    _vm.entity.settings
                                      .ai_ad_with_original_content,
                                    null
                                  ) > -1
                                : _vm.entity.settings
                                    .ai_ad_with_original_content,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.entity.settings
                                      .ai_ad_with_original_content,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.entity.settings,
                                        "ai_ad_with_original_content",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.entity.settings,
                                        "ai_ad_with_original_content",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.entity.settings,
                                    "ai_ad_with_original_content",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "One of the created variations should be made without Infinite∞Ai"
                                )
                              ) +
                              " "
                          ),
                        ]),
                        !_vm.adVariantsValueIsValid
                          ? _c("info-text", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tr(
                                      "If you want to create multiple ad variations, you need to specify at least one ad element that the Infinite∞Ai can determine."
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "button primary ap-creative-settings",
                    on: { click: () => _vm.openApCreativeSettings() },
                  },
                  [
                    _c("i", { staticClass: "icon development white" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.tr("Meta Advantage+ Creative Settings")) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("div", { staticClass: "divider" }),
      _vm.entity.settings.ad_creation_method === "creative"
        ? _c("cta-block", {
            attrs: {
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode || _vm.ctaIsDisabled,
              "disabled-text": _vm.ctaDisabledText,
              switchable: "",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("url-params-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }