var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "protected-post-view list-view" }, [
    _c("div", { staticClass: "preview" }, [
      _c("div", { staticClass: "sticky-wrapper" }, [
        _c("h3", [_vm._v(" " + _vm._s(_vm.tr("Post preview")) + " ")]),
        _vm.embed
          ? _c("div", {
              staticClass: "embed",
              domProps: { innerHTML: _vm._s(_vm.embed) },
            })
          : _c("div", { staticClass: "embed-loader" }, [
              _c("i", { staticClass: "icon loader light-black" }),
            ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "listed-comments" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.tr("Comments")))]),
        _c("div", { staticClass: "actions" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            staticClass: "text",
            attrs: { type: "text", placeholder: _vm.tr("Comment search") },
            domProps: { value: _vm.searchTerm },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          }),
        ]),
        _vm.isListEmpty && !_vm.hasMore && _vm.searchTerm
          ? _c("div", { staticClass: "empty-text" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "There are no results matching your search criteria."
                    )
                  ) +
                  " "
              ),
            ])
          : _vm.isListEmpty && !_vm.hasMore && !_vm.searchTerm
          ? _c("div", { staticClass: "empty-text" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.tr("There are no comments under this post.")) +
                  " "
              ),
            ])
          : !_vm.isListEmpty
          ? _c("new-sorted-table", {
              attrs: { data: _vm.commentList },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("table-head", { attrs: { col: "author" } }),
                      _c(
                        "table-head",
                        {
                          staticClass: "posted-column",
                          attrs: { col: "posted", width: "120px" },
                        },
                        [_vm._v(_vm._s(_vm.tr("Posted")))]
                      ),
                      _c(
                        "table-head",
                        {
                          staticClass: "classification-column",
                          attrs: { col: "classification", width: "150px" },
                        },
                        [_vm._v(_vm._s(_vm.tr("Classification")))]
                      ),
                      _c(
                        "table-head",
                        {
                          staticClass: "moderated-by-ai-column",
                          attrs: { col: "moderated", width: "68px" },
                        },
                        [_vm._v(_vm._s(_vm.tr("Moterated")))]
                      ),
                      !_vm.$root.loggedInUser.isRestrictedViewer
                        ? _c("table-head", {
                            attrs: { col: "actions", width: "30px" },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function ({ sort }) {
                    return _vm._l(sort, function (comment) {
                      return _c(
                        "table-row",
                        { key: comment.id },
                        [
                          _c("table-data", { attrs: { col: "author" } }, [
                            _c("div", { staticClass: "author" }, [
                              _vm._v(_vm._s(comment.author.name)),
                            ]),
                            _c("div", { staticClass: "comment" }, [
                              _vm._v(_vm._s(comment.shortMessage)),
                            ]),
                          ]),
                          _c(
                            "table-data",
                            {
                              staticClass: "posted-column",
                              attrs: { col: "posted" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        _vm.formatToYMD(comment.created_at) +
                                        ", " +
                                        _vm.formatTime(comment.created_at),
                                      expression:
                                        "formatToYMD(comment.created_at) + ', ' + formatTime(comment.created_at)",
                                    },
                                  ],
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.timeAgo(comment.created_at, _vm.tr)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "classification-column",
                              attrs: { col: "classification" },
                            },
                            [
                              !_vm.commentIsResultIsVisible(comment)
                                ? [_vm._v(" - ")]
                                : _c("ai-result", {
                                    attrs: {
                                      status: comment.ai_status,
                                      result: comment.ai_result,
                                    },
                                  }),
                            ],
                            2
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "moderated-by-ai-column",
                              attrs: { col: "moderated" },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.moderatedBeautify(
                                      comment.is_hidden_by_ai
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          !_vm.$root.loggedInUser.isRestrictedViewer
                            ? _c("table-data", { attrs: { col: "actions" } }, [
                                _c("div", { staticClass: "action-group" }, [
                                  _vm.isLoadingComment === comment.id
                                    ? _c("i", {
                                        staticClass: "icon loader light-black",
                                      })
                                    : !comment.is_hidden
                                    ? _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.tr("Hide comment"),
                                            expression: "tr('Hide comment')",
                                          },
                                        ],
                                        class:
                                          "icon hide light-black " +
                                          (_vm.isLoadingComment !== null
                                            ? "disabled"
                                            : ""),
                                        on: {
                                          click: () => _vm.hideComment(comment),
                                        },
                                      })
                                    : _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.tr("Restore comment"),
                                            expression: "tr('Restore comment')",
                                          },
                                        ],
                                        class:
                                          "icon restore light-black " +
                                          (_vm.isLoadingComment !== null
                                            ? "disabled"
                                            : ""),
                                        on: {
                                          click: () =>
                                            _vm.restoreComment(comment),
                                        },
                                      }),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            })
          : _vm._e(),
        _vm.hasMore
          ? _c("div", { ref: "loadMore", staticClass: "load-more" }, [
              _c("i", { staticClass: "icon loader" }),
              _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }