import Api from '@/services/Api';

import AdCopyMagicItem from '@/entities/AdCopyMagicItem.js';
import ImprovedCopy from '@/entities/ImprovedCopy.js';

export default {
  getList({ page = 1, limit = 10 }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/magic-tool/list?' + query)
      .then(resp => resp.map(item => new AdCopyMagicItem(item)));
  },

  get(id) {
    return Api().get('/magic-tool/ad-copy-magic-item/' + id)
      .then(resp => new AdCopyMagicItem(resp));
  },

  analyze(text, goal, title, description) {
    return Api().post('/magic-tool/analyze', { text, goal, title, description })
      .then(resp => new AdCopyMagicItem(resp));
  },

  improve({ postId, adCopyMagicItemId }) {
    let data = {};
    if (postId) {
      data['post_id'] = postId;
    }
    else if (adCopyMagicItemId) {
      data['ad_copy_magic_item_id'] = adCopyMagicItemId;
    }

    return Api().post('/magic-tool/improve', data)
      .then(resp => new ImprovedCopy(resp));
  },

  delete(id) {
    return Api().delete('/magic-tool/ad-copy-magic-item/' + id)
  }
};
