<template>
  <div class="auth-form-view reset-password-view">
    <div class="input-label">
      <span>{{ tr('E-mail') }}</span>
    </div>
    <input
      :value="email"
      type="email"
      disabled
    />

    <div class="input-label">
      <span>{{ tr('New password') }}</span>
    </div>
    <input
      v-model="newPassword"
      type="password"
      :placeholder="tr('Enter at least 8 characters...')"
    />

    <div class="input-label">
      <span>{{ tr('New password again') }}</span>
    </div>
    <input
      v-model="newPasswordAgain"
      type="password"
      :placeholder="tr('Repeat your password')"
    />

    <button class="primary" @click="submit">
      <i class="icon loader white"
        v-if="isLoading"
        :loading="isLoading"
        color="#ffffff"
        size="6px"
      />
      <template v-else> {{ tr('Change') }} </template>
    </button>

    <modal
      name="google-2fa-modal"
      class="twofa-modal"
    >
      <google-2fa-code-form
        v-model="code"
        :title="tr('Two-factor authentication')"
        :cancel-button-label="tr('Cancel')"
        :is-loading="isLoading"
        @submit="({ trust }) => loginWith2fa(trust)"
        @cancel="() => $modal.hide('google-2fa-modal')"
        trust-option
      />
    </modal>
  </div>
</template>

<script>
import Google2faCodeForm from '@/components/Google2faCodeForm';
import AuthService from '@/services/Auth.js';
import { Login2faNeccessaryError, UnauthorizedError } from '@/errors';
import getBrowserFingerprint from 'get-browser-fingerprint';

export default {
  name: 'PasswordReset',
  components: {
    Google2faCodeForm,
  },
  data() {
    return {
      isLoading: false,
      newPassword: '',
      newPasswordAgain: '',
      code: '',
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    email() {
      if (!this.$route.query.email) {
        return null;
      }

      return this.$route.query.email.replaceAll(' ', '+');
    },
  },
  methods: {
    resetForm() {
      this.newPassword = '';
      this.newPasswordAgain = '';
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.isLoading = true;
      AuthService.resetPassword({
        token: this.token,
        email: this.email,
        password: this.newPassword,
      })
        .then(resp => {
          this.$toast.success(this.tr('Password is modified successfully.'));

          if ('2fa' in resp) {
            throw new Login2faNeccessaryError();
          }

          this.$store.commit('auth/setToken', resp.access_token);
          this.$store.commit('auth/setUser', resp.user);

          this.$router.replace({ path: '/dashboard' });

          if (window.PasswordCredential) {
            const passwordCredential = new window.PasswordCredential({ id: this.email, password: this.newPassword });
            navigator.credentials.store(passwordCredential);
          }

          this.resetForm();
        })
        .catch((err) => {
          if (err instanceof Login2faNeccessaryError) {
            this.$modal.show('google-2fa-modal');
          }
          else {
            this.error(err);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    validate() {
      this.$toast.clear();

      if (!this.newPassword) {
        this.$toast.error(this.tr('Password cannot be empty!'));
        return false;
      }
      if (this.newPassword.length < 8) {
        this.$toast.error(this.tr('The password must be at least 8 characters long.'));
        return false;
      }
      if (this.newPassword !== this.newPasswordAgain) {
        this.$toast.error(this.tr('Passwords given do not match!'));
        return false;
      }

      return true;
    },
    loginWith2fa(trust) {
      this.isLoading = true;
      this.$store
        .dispatch('auth/login', {
          email: this.email,
          password: this.newPassword,
          browser: getBrowserFingerprint({ enableScreen: false }),
          code: this.code,
          trust: trust ?? false,
        })
        .then(() => {
          this.$store.commit('app/language', this.$root.loggedInUser.language);

          this.$router.replace({ path: '/dashboard' });

          if (window.PasswordCredential) {
            const passwordCredential = new window.PasswordCredential({ id: this.email, password: this.newPassword });
            navigator.credentials.store(passwordCredential);
          }

          this.$nextTick(() => this.resetForm());
        })
        .catch((err) => {
          if (err instanceof UnauthorizedError) {
            this.$toast.error(this.tr('Invalid login information.'));
          }
          else {
            this.error(err);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
