<template>
  <label :class="{ 'three-state-checkbox-component': true, 'disabled': disabled }" @click="() => toggle()">
    <i :class="iconClasses" />
    <slot />
  </label>
</template>

<script>
export default {
  name: 'ThreeStateCheckbox',
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    sortedItems() {
      return JSON.parse(JSON.stringify(this.items)).sort();
    },
    sortedValue() {
      return JSON.parse(JSON.stringify(this.value)).sort();
    },
    isEmpty() {
      return this.value.length === 0;
    },
    isPartial() {
      return !this.isEmpty && !this.isFull;
    },
    isFull() {
      return JSON.stringify(this.sortedItems) === JSON.stringify(this.sortedValue);
    },
    iconClasses() {
      return {
        'disabled': this.disabled,
        'empty': this.isEmpty,
        'partial': this.isPartial,
        'full': this.isFull,
      };
    },
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return;
      }

      if (this.isEmpty || this.isPartial) {
        this.$emit('input', this.items);
        this.$emit('change')
      }
      else if (this.isFull) {
        this.$emit('input', []);
        this.$emit('change')
      }
    },
  },
};
</script>
