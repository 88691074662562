var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block campaign-budget-block-component",
      attrs: {
        title:
          _vm.title !== null ? _vm.title : _vm.tr("Advantage campaign budget"),
        description:
          _vm.description !== null
            ? _vm.description
            : _vm.tr(
                "If you enable this feature, it will automatically distribute your campaign`s budget across different ad sets to get more results depending on your delivery optimization choices and bid strategy. The purpose of this is to ensure the best overall result, so the budget may not be evenly distributed among the individual ad sets. Your posts, so to speak, will `compete` with each other. For example, if you have 2 active ad sets in your campaign, it`s possible that the majority of your budget will be allocated to one ad set if it achieves the best overall performance."
              ),
        disabled: _vm.disabled || !_vm.entity.isNew,
        switchable: _vm.switchable,
      },
      model: {
        value: _vm.isEnabled,
        callback: function ($$v) {
          _vm.isEnabled = $$v
        },
        expression: "isEnabled",
      },
    },
    [
      _c(
        "div",
        [
          !_vm.minimal
            ? _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Campaign budget"))),
              ])
            : _vm._e(),
          _c(
            "div",
            { class: { "inline-input-select": !_vm.minimal } },
            [
              _c("money-input", {
                attrs: {
                  disabled: _vm.disabled,
                  currency: _vm.entity.adAccountCurrency
                    ? _vm.entity.adAccountCurrency.code
                    : "EUR",
                  min: _vm.entity.adAccountMinDailyBugdet,
                },
                model: {
                  value: _vm.entity.output_campaign.budget,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity.output_campaign, "budget", $$v)
                  },
                  expression: "entity.output_campaign.budget",
                },
              }),
              !_vm.minimal
                ? _c("v-select", {
                    attrs: {
                      options: _vm.budgetPeriods,
                      reduce: (obj) => obj.machine_name,
                      disabled: _vm.disabled || true,
                      searchable: false,
                      clearable: false,
                    },
                    model: {
                      value: _vm.entity.output_campaign.budget_period,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.entity.output_campaign,
                          "budget_period",
                          $$v
                        )
                      },
                      expression: "entity.output_campaign.budget_period",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.originalCampaignBudget &&
          _vm.originalCampaignBudget > _vm.entity.output_campaign.budget
            ? _c("info-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "Reducing the campaign budget may result in the suspension of some already running ads."
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("info-text", [
            _vm._v(" " + _vm._s(_vm.campaignBudgetInfoText) + " "),
          ]),
          _vm.availableAdsFromBudget
            ? _c(
                "info-text",
                [
                  _vm.availableAdsFromBudget !== 1
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "You will be able to run :num ads from this budget.",
                                { ":num": _vm.availableAdsFromBudget }
                              )
                            ) +
                            " "
                        ),
                      ]
                    : [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "You will be able to run 1 ad from this budget."
                              )
                            ) +
                            " "
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.minimal
        ? _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Bid strategy"))),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.facebookCampaignBidStrategies,
                  reduce: (obj) => obj.machine_name,
                  searchable: false,
                  clearable: false,
                  disabled: _vm.disabled || !_vm.entity.isNew,
                },
                model: {
                  value: _vm.entity.output_campaign.bid_strategy,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity.output_campaign, "bid_strategy", $$v)
                  },
                  expression: "entity.output_campaign.bid_strategy",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }