<template>
  <div class="report-ad-table-component">
    <empty-screen
      v-if="!adAccountId"
      icon="stat"
      :title="tr('Select ad account to view performance data')"
      :flag-text="tr('View your insights')"
      :description="tr('Your accounts are connected and ready for analysis. Choose a specific ad account and automation rule to see detailed metrics and discover how your automated campaigns are performing.')"
    />
    <empty-screen
      v-else-if="isListEmpty && !isLoading"
      icon="stat"
      :title="tr('Your analytics dashboard is ready and waiting for data')"
      :flag-text="tr('Analytics pending')"
      :description="tr('Your analytics dashboard will show performance metrics once your automated ads start running. Check back here after your campaigns have gathered enough data to display meaningful insights.')"
    />

    <sorted-table
      v-else
      :data="list"
      :initial-sort="{ key: orderBy, direction: order }"
      class="row-hover-shadow"
      @sort="sortTable"
    >
      <template #header>
        <table-head col="status" width="20px" />
        <table-head
          col="name"
          sortable
          min-width="400px"
        />
        <table-head
          col="metric.reach"
          sortable
          width="70px"
        >
          {{ tr('Reach') }}
        </table-head>
        <table-head
          :col="'metric.actions.' + action"
          sortable
          width="70px"
        >
          {{ tr('Actions') }}
        </table-head>
        <table-head
          :col="'metric.cost_per_action_type.' + action"
          sortable
          width="70px"
        >
          {{ tr('CPA') }}
        </table-head>
        <table-head
          col="metric.spend"
          sortable
          width="70px"
        >
          {{ tr('Spend') }}
        </table-head>
        <table-head
          col="created_at"
          sortable
          width="150px"
        >
          {{ tr('Date created') }}
        </table-head>
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="'report-ad-table--ad-' + row.external_id">
          <table-data col="status">
            <div :class="{ 'status': true, 'green': row.effective_status === 1, 'red': row.effective_status !== 1 }" />
          </table-data>
          <table-data col="name">
            <div class="ad-name" @click="() => toggle(row.external_id)">
              {{ row.name }}
            </div>
          </table-data>
          <table-data col="metric.reach">
            {{ row.metric ? formatNumber(row.metric.reach) : '-' }}
          </table-data>
          <table-data :col="'metric.actions.' + action">
            {{ row.metric && row.metric.actions ? formatNumber(row.metric.actions[action]) : '-' }}
          </table-data>
          <table-data :col="'metric.cost_per_action_type.' + action">
            {{ formatNumber(calculateCpa(row), currency, 3) }}
          </table-data>
          <table-data col="'metric.spend">
            {{ row.metric ? formatNumber(row.metric.spend, currency) : '-' }}
          </table-data>
          <table-data col="created_at">
            {{ formatToYMD(row.created_at) }} {{ formatTime(row.created_at) }}
          </table-data>

          <transition-expand>
            <div v-show="openedAd === row.external_id" :key="'report-ad-table--ad-' + row.external_id + '--details'" class="details-row">
              <report-ad-details
                v-if="renderedAdDetails.includes(row.external_id)"
                :token-part1="tokenPart1"
                :token-part2="tokenPart2"
                :ad-account-id="adAccountId"
                :ad="row"
              />
            </div>
          </transition-expand>
        </table-row>
      </template>
    </sorted-table>
  </div>
</template>

<script>
export const ITEM_PER_PAGE = 20;

import EmptyScreen from '@/components/EmptyScreen.vue';
import ReportAdDetails from '@/components/ReportAdDetails.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';
import ReportService from '@/services/Report';
import { formatTime, formatToYMD } from '@/utils/date';
import debounce from '@/utils/debounce.js';
import { formatNumber } from '@/utils/number.js';
import { ucfirst } from '@/utils/string';

export default {
  name: 'ReportAdsTable',
  components: {
    EmptyScreen,
    ReportAdDetails,
    TransitionExpand,
  },
  props: {
    tokenPart1: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    tokenPart2: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    adAccountId: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    campaignId: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    action: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    period: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    isActiveFilter: {
      type: Boolean,
      required: false,
    },
    currency: {
      type: String,
      required: false,
      default: () => '$',
    },
  },
  data() {
    return {
      isLoading: false,
      hasMore: true,
      page: 0,
      list: [],
      row: [],
      debouncedLoadData: debounce(() => {
        this.reset(); this.loadNewData();
      }, 100),
      openedAd: null,
      renderedAdDetails: [],
      removerTimers: {},
      order: 'desc',
      orderBy: 'created_at',
    };
  },
  computed: {
    isListEmpty() {
      return this.list.length == 0;
    },
  },
  watch: {
    adAccountId() {
      this.isLoading = true;
      this.debouncedLoadData();
    },
    campaignId() {
      this.isLoading = true;
      this.debouncedLoadData();
    },
    period() {
      this.isLoading = true;
      this.debouncedLoadData();
    },
    isActiveFilter() {
      this.isLoading = true;
      this.debouncedLoadData();
    },
  },
  created() {
    this.loadNewData();
  },
  methods: {
    formatTime,
    formatToYMD,
    formatNumber,
    ucfirst,
    reset() {
      this.isLoading = false;
      this.hasMore = true;
      this.page = 0;
      this.list = [];
    },
    sortTable({ key, direction }) {
      this.orderBy = key;
      this.order = direction;

      this.reset();
      this.loadNewData();
    },
    loadNewData() {
      if (!this.hasMore) {
        return;
      }
      if (!this.adAccountId || !this.period) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        tokenPart1: this.tokenPart1,
        tokenPart2: this.tokenPart2,
        adAccountId: this.adAccountId,
        campaignId: this.campaignId,
        period: this.period,
        isActive: this.isActiveFilter,
        page: this.page,
        limit: ITEM_PER_PAGE,
        orderBy: this.orderBy,
        order: this.order,
      };
      ReportService.getAds(params)
        .then(resp => {
          this.hasMore = resp.length === ITEM_PER_PAGE;
          this.list.push(...resp);

          return resp;
        })
        .catch(err => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0]);
      }
    },
    calculateCpa(ad) {
      if (
        !ad
        || !ad.metric
        || !ad.metric.spend
        || !ad.metric.actions
        || !ad.metric.actions[this.action]
      ) {
        return 0;
      }

      return ad.metric.spend / ad.metric.actions[this.action];
    },
    toggle(adId) {
      if (adId in this.removerTimers) {
        clearTimeout(this.removerTimers[adId]);
        delete this.removerTimers[adId];
      }

      const previous = this.openedAd;

      if (adId !== this.openedAd) {
        this.renderedAdDetails.push(adId);
        setTimeout(() => this.openedAd = adId, 50);
      }
      else {
        this.openedAd = null;
      }

      if (previous) {
        this.removerTimers[previous] = setTimeout(() => {
          this.renderedAdDetails = this.renderedAdDetails.filter(item => item !== previous);
          delete this.removerTimers[previous];
        }, 1000);
      }
    },
  },
};
</script>
