var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-4",
    },
    [
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Manage original ad"),
            description: _vm.tr(
              "You can set whether the scaled-up original ad should continue running or stop automatically."
            ),
          },
        },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.stop_original,
                    expression: "entity.settings.stop_original",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "keep-or-stop",
                  disabled: _vm.$parent.readonlyMode,
                },
                domProps: {
                  value: false,
                  checked: _vm._q(_vm.entity.settings.stop_original, false),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.entity.settings, "stop_original", false)
                  },
                },
              }),
              _vm._v(" " + _vm._s(_vm.tr("Keep original ad running")) + " "),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.stop_original,
                    expression: "entity.settings.stop_original",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "keep-or-stop",
                  disabled: _vm.$parent.readonlyMode,
                },
                domProps: {
                  value: true,
                  checked: _vm._q(_vm.entity.settings.stop_original, true),
                },
                on: {
                  change: function ($event) {
                    return _vm.$set(_vm.entity.settings, "stop_original", true)
                  },
                },
              }),
              _vm._v(
                " " + _vm._s(_vm.tr("Stop original ad automatically")) + " "
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c("ad-creation-method-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _vm.ctaBlockIsVisible
        ? _c("cta-block", {
            attrs: {
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
              switchable: "",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _vm.urlParamsBlockIsVisible
        ? _c("url-params-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }