<template>
  <form-block
    class="rule-form-block adset-budget-block-component"
    :title="tr('Budget')"
  >
    <div>
      <label class="input-label">{{ tr('Ad set budget') }}</label>
      <div v-if="isAvailable" class="inline-input-select">
        <money-input
          v-model="entity.settings.adset_budget"
          :disabled="disabled || !!entity.output_campaign.budget"
          :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : 'EUR'"
          :min="entity.adAccountMinDailyBugdet"
        />
        <v-select
          v-model="entity.settings.adset_budget_period"
          :options="budgetPeriods"
          :reduce="(obj)=> obj.machine_name"
          :disabled="true || !entity.isNew || entity.output_campaign.budget || disabled"
          :searchable="false"
          :clearable="false"
        />
      </div>
      <info-text>
        {{ adsetBudgetInfo }}
      </info-text>
    </div>

    <div v-if="isAvailable">
      <label class="input-label">
        {{ tr('Maximum number of posts promoted at once') }} {{ '(' + tr('optional') + ')' }}
      </label>
      <input
        v-model="entity.settings.max_active_ads"
        type="number"
        min="1"
        step="1"
        class="max-number"
        :disabled="disabled"
      />
    </div>

    <slot />
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

import MoneyInput from '@/components/MoneyInput.vue';
import InfoText from '@/components/InfoText.vue';

import { formatNumber } from '@/utils/number.js';

export default {
  name: 'AdsetBudgetBlock',
  mixins: [ RuleFormBlockMixin ],
  components: {
    MoneyInput,
    InfoText,
  },
  computed: {
    isAvailable() {
      return !this.entity.output_campaign.budget;
    },
    budgetPeriods() {
      return this.$store.getters['app/budgetPeriods'];
    },
    adsetBudgetInfo() {
      if (!this.isAvailable) {
        return this.tr('You have set a campaign budget, so the budget at ad set level is not available.');
      }

      return this.tr('Minimum budget') + ': ' + this.entity.adAccountCurrency.symbol + formatNumber(this.entity.adAccountMinDailyBugdet);
    },
  },
  created() {
    if (!this.entity.output_campaign.budget && !this.entity.settings.adset_budget_period) {
      this.entity.settings.adset_budget_period = this.budgetPeriods[0].machine_name;
    }
  },
  methods: {
    validate() {
      if (this.isAvailable && !this.entity.settings.adset_budget) {
        this.error(this.tr('Ad set budget is required because you have not set a campain level budget!'));
        return false;
      }

      if (this.entity.settings.adset_budget && this.entity.settings.adset_budget < this.entity.adAccountMinDailyBugdet) {
        this.error(this.tr('Budget must be more than :value!', { ':value': this.entity.adAccountCurrency.symbol + this.entity.adAccountMinDailyBugdet }));
        return false;
      }

      if (
        this.entity.settings.max_active_ads !== null
        && this.entity.settings.max_active_ads !== ''
        && this.entity.settings.max_active_ads < 1
      ) {
        this.error(
          this.tr(
            'Maximum number of active ads at the same time cannot be less than one!'
          )
        );
        return false;
      }

      return true;
    },
  },
};
</script>
