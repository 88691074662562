<template>
  <div class="magic-tool-item-delete-modal-content">
    <template>
      <i class="icon mid delete primary"></i>
      <h3>{{ tr('Are you sure you want to delete this evaluation?') }}</h3>
      <div class="divider"></div>
      <div class="scroll-wrapper">
        <div class="textual">
          <p>{{ tr('Warning: This post will be permanently deleted from Facebook. Once deleted, this action cannot be undone and the content cannot be recovered. Please ensure you want to proceed with deleting this post as it will be completely removed from your Facebook page.') }}</p>
        </div>
        <button
          class="primary full-width"
          @click="() => deleteItem()"
        >
          {{ tr('Confirm') }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>

import AdCopyMagicItem from '@/entities/AdCopyMagicItem.js';
import MagicToolService from '@/services/MagicTool.js';

export default {
  name: 'MagicToolItemDeleteModalContent',
  props: {
    entity: {
      type: AdCopyMagicItem || Object,
      required: true,
    },
  },
  methods: {
    deleteItem() {
      return MagicToolService.delete(this.entity.id)
        .then(resp => {
          this.$store.commit('magicTool/setDeletedAdCopyMagicItem', this.entity);
          this.$toast.clear();
          this.$toast.success(this.tr('Item was successfully deleted.'));
          this.$router.replace('/magic-tool');
          this.$fullscreenModal.closeAll();
          return resp;
        }).catch(err => this.error(err));
    },
  },
};
</script>
