var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form-block",
        {
          staticClass: "rule-form-block cta-block-component",
          attrs: {
            title: _vm.title,
            description: _vm.description,
            switchable: _vm.switchable,
            disabled: _vm.disabled || _vm.disabledByAiSettings,
          },
          model: {
            value: _vm.entity.settings.custom_cta_settings,
            callback: function ($$v) {
              _vm.$set(_vm.entity.settings, "custom_cta_settings", $$v)
            },
            expression: "entity.settings.custom_cta_settings",
          },
        },
        [
          _vm.disabledText
            ? _c("info-text", [_vm._v(" " + _vm._s(_vm.disabledText) + " ")])
            : _vm.entity.isInstagramProfileVisitCampaign
            ? _c("info-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "You have chosen Instagram profile as the conversion location, so you cannot set a custom CTA."
                      )
                    ) +
                    " "
                ),
              ])
            : [
                _vm.campaignObjectiveLabel
                  ? _c("info-text", { staticClass: "first" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "If you do not set a CTA Button URL, no ad will be created from those posts that do not contain a URL, as this is required by the campaign's goal (:goal).",
                              { ":goal": _vm.campaignObjectiveLabel }
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(" " + _vm._s(_vm.tr("Button type")) + " "),
                    ]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.ctaList,
                        reduce: (obj) => obj.machine_name,
                        searchable: false,
                        clearable: false,
                        disabled: _vm.disabled || _vm.disabledByAiSettings,
                        label: "label",
                      },
                      model: {
                        value: _vm.entity.settings.cta_button,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity.settings, "cta_button", $$v)
                        },
                        expression: "entity.settings.cta_button",
                      },
                    }),
                  ],
                  1
                ),
                _vm.hasSelectedCtaButton
                  ? _c("div", [
                      _c("label", { staticClass: "input-label" }, [
                        _vm._v(" " + _vm._s(_vm.tr("Button URL")) + " "),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.entity.settings.cta_url,
                            expression: "entity.settings.cta_url",
                          },
                        ],
                        class: { error: _vm.urlError },
                        attrs: {
                          type: "text",
                          placeholder: _vm.tr(
                            "Enter the URL you want to promote"
                          ),
                          disabled:
                            _vm.disabled ||
                            _vm.disabledByAiSettings ||
                            _vm.entity.isAppPromo,
                        },
                        domProps: { value: _vm.entity.settings.cta_url },
                        on: {
                          blur: () => _vm.urlValidation(),
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.entity.settings,
                              "cta_url",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm.hasSelectedCtaButton && !_vm.entity.isAppPromo
                  ? _c("div", [
                      _c("div", { staticClass: "checkbox-list horizontal" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.entity.settings.smart_cta_url,
                                expression: "entity.settings.smart_cta_url",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              disabled:
                                _vm.disabled || _vm.disabledByAiSettings,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.entity.settings.smart_cta_url
                              )
                                ? _vm._i(
                                    _vm.entity.settings.smart_cta_url,
                                    null
                                  ) > -1
                                : _vm.entity.settings.smart_cta_url,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.entity.settings.smart_cta_url,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.entity.settings,
                                        "smart_cta_url",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.entity.settings,
                                        "smart_cta_url",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.entity.settings,
                                    "smart_cta_url",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" " + _vm._s(_vm.tr("Smart URL")) + " "),
                        ]),
                      ]),
                      _c("small", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tr(
                                "If the post contains at least one link, we will use it as the Button URL to create the ad. In this case, the URL provided here will only be used if the post does not contain any links."
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm.switchable &&
      !_vm.entity.settings.custom_cta_settings &&
      _vm.disabledText
        ? _c("info-text", [_vm._v(" " + _vm._s(_vm.disabledText) + " ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }