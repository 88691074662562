var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--ai-mode rule-form-step--ai-mode--step-1",
    },
    [
      _c(
        "form-block",
        {
          staticClass: "goal-block",
          attrs: {
            title: _vm.tr("Your goal"),
            description: _vm.tr(
              "Depending on what you want to achieve with your ad, different settings will be the most optimal for running ads. By defining the goal, Infinite∞Ad will make these settings automatically."
            ),
          },
        },
        [
          _c("div", { staticClass: "checkbox-list horizontal" }, [
            _c("label", { staticClass: "radio large" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "traffic" },
                domProps: { checked: _vm._q(_vm.goal, "traffic") },
                on: {
                  change: function ($event) {
                    _vm.goal = "traffic"
                  },
                },
              }),
              _c("i", { staticClass: "icon primary traffic" }),
              _c("span", { staticClass: "radio--title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.tr("I want to drive traffic to my website")) +
                    " "
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have a website and want to attract visitors using ads"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "radio large" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "conversion" },
                domProps: { checked: _vm._q(_vm.goal, "conversion") },
                on: {
                  change: function ($event) {
                    _vm.goal = "conversion"
                  },
                },
              }),
              _c("i", { staticClass: "icon primary conversion" }),
              _c("span", { staticClass: "radio--title" }, [
                _vm._v(
                  " " + _vm._s(_vm.tr("I want to increase my sales")) + " "
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have a webshop or sell products/services online"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "radio large" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "app_promotion" },
                domProps: { checked: _vm._q(_vm.goal, "app_promotion") },
                on: {
                  change: function ($event) {
                    _vm.goal = "app_promotion"
                  },
                },
              }),
              _c("i", { staticClass: "icon primary promotion" }),
              _c("span", { staticClass: "radio--title" }, [
                _vm._v(" " + _vm._s(_vm.tr("I want to promote my app")) + " "),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have an app and want it to achieve better results"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "radio large" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "engagement" },
                domProps: { checked: _vm._q(_vm.goal, "engagement") },
                on: {
                  change: function ($event) {
                    _vm.goal = "engagement"
                  },
                },
              }),
              _c("i", { staticClass: "icon primary engagement" }),
              _c("span", { staticClass: "radio--title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr("I want more reach and engagement on my posts")
                    ) +
                    " "
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You want your Facebook or Instagram posts to get more reactions and reach"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", { staticClass: "radio large" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "page_like" },
                domProps: { checked: _vm._q(_vm.goal, "page_like") },
                on: {
                  change: function ($event) {
                    _vm.goal = "page_like"
                  },
                },
              }),
              _c("i", { staticClass: "icon primary pagelike" }),
              _c("span", { staticClass: "radio--title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "I want to increase my number of Facebook followers"
                      )
                    ) +
                    " "
                ),
              ]),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have a Facebook page and want to gain more followers with your content"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c("ad-account-block", {
        attrs: {
          entity: _vm.entity,
          description: _vm.tr(
            "You can choose the ad account in which you want to launch your advertisement. (This is useful if you manage multiple accounts.)"
          ),
        },
      }),
      _vm.conversionEventSettingsAvailable
        ? _c("conversion-odax-block", {
            attrs: {
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
              minimal: "",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("source-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode,
          single: "",
        },
      }),
      _vm.ctaUrlIsAvailable
        ? _c("form-block", [
            _c("div", [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(" " + _vm._s(_vm.ctaUrlLabel) + " "),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.cta_url,
                    expression: "entity.settings.cta_url",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.tr("Enter the URL you want to promote"),
                },
                domProps: { value: _vm.entity.settings.cta_url },
                on: {
                  blur: () => _vm.onWebsiteInputBlur(),
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.entity.settings,
                      "cta_url",
                      $event.target.value
                    )
                  },
                },
              }),
              _c("small", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "If the post contains at least one link, we will use it as the Button URL to create the ad. In this case, the URL provided here will only be used if the post does not contain any links."
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("activity-management-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode,
          "disable-block-in-ai-mode": true,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _vm.goal !== "engagement"
        ? _c(
            "form-block",
            {
              staticClass: "ai-assist-block",
              attrs: {
                title:
                  _vm.tr("AI Assist") + "<div class='beta-flag'>Beta</div>",
              },
            },
            [
              _c("div", { staticClass: "checkbox-list vertical" }, [
                _c(
                  "label",
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.aiAssist,
                          expression: "aiAssist",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        disabled: _vm.$parent.readonlyMode,
                      },
                      domProps: {
                        value: _vm.AI_ASSIST_OPTIONS.CREATE_CREATIVES,
                        checked: Array.isArray(_vm.aiAssist)
                          ? _vm._i(
                              _vm.aiAssist,
                              _vm.AI_ASSIST_OPTIONS.CREATE_CREATIVES
                            ) > -1
                          : _vm.aiAssist,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.aiAssist,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = _vm.AI_ASSIST_OPTIONS.CREATE_CREATIVES,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.aiAssist = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.aiAssist = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.aiAssist = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" " + _vm._s(_vm.tr("Create Ai creatives")) + " "),
                    _c("info-popup", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tr(
                              "When Infinite∞Ad creates ad variations, it generates new ads instead of boosting your original post. This means engagement will appear on the ads, not on your original post."
                            )
                          ),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("campaign-budget-block", {
        ref: "campaignBudgetBlock",
        attrs: {
          entity: _vm.entity,
          title: _vm.tr("Daily budget"),
          description: _vm.tr(
            "You can determine the maximum daily budget that you allocate for advertising."
          ),
          switchable: false,
          minimal: "",
        },
      }),
      _vm.scalingBudgetIsAvailable
        ? _c(
            "form-block",
            {
              attrs: {
                title:
                  _vm.tr("Daily budget for high-performing ads") +
                  " (" +
                  _vm.tr("optional") +
                  ")",
                description: _vm.tr(
                  "You can determine the maximum daily budget that you would allocate to those ads that have already proven to perform well."
                ),
              },
            },
            [
              _c(
                "div",
                [
                  _c("money-input", {
                    attrs: {
                      currency: _vm.entity.adAccountCurrency
                        ? _vm.entity.adAccountCurrency.code
                        : "EUR",
                    },
                    model: {
                      value: _vm.scalingBudget,
                      callback: function ($$v) {
                        _vm.scalingBudget = $$v
                      },
                      expression: "scalingBudget",
                    },
                  }),
                  _vm.invalidScalingBudget
                    ? _c("info-text", { attrs: { type: "error" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.invalidScalingBudgetInfoText) + " "
                        ),
                      ])
                    : _vm.entity.adAccountCurrency
                    ? _c("info-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tr("Minimum daily budget")) +
                            ": " +
                            _vm._s(_vm.entity.adAccountCurrency.symbol) +
                            " " +
                            _vm._s(
                              _vm.formatNumber(
                                _vm.entity.adAccountMinDailyBugdet
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.availableScaledAdsFromBudget > 0
                    ? _c(
                        "info-text",
                        [
                          _vm.availableScaledAdsFromBudget !== 1
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tr(
                                        "With this budget, you will be able to run :num ads simultaneously.",
                                        {
                                          ":num":
                                            _vm.availableScaledAdsFromBudget,
                                        }
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            : [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tr(
                                        "With this budget, you will be able to run 1 ad simultaneously."
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("dsa-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "div",
        [
          _c("demographic-target-block", {
            attrs: {
              title: _vm.tr("Target audience"),
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
            },
          }),
          _c("label", { staticClass: "input-label geotarget-label" }, [
            _vm._v(_vm._s(_vm.tr("Geotargeting"))),
          ]),
          _c("geotarget-block", {
            attrs: {
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
              title: "",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "divider" }),
      _c("campaign-special-ad-categories-block", {
        attrs: { entity: _vm.entity, disabled: !_vm.entity.isNew },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }