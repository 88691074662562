<template>
  <div class="source-profile-settings-component">
    <form-block :title="title">
      <div>
        <label class="input-label">{{ tr('Website') }}</label>
        <input v-model="model.website" type="text" />
      </div>
    </form-block>

    <form-block
      :title="tr('Target audience')"
      :description="tr('If you cannot define an option exactly, leave it unchanged.')"
    >
      <div>
        <label class="input-label">{{ tr('Gender') }}</label>
        <gender-selector v-model="model.targeting.genders" />
      </div>

      <div>
        <label class="input-label">{{ tr('Age') }}</label>
        <div class="age-block">
          <age-selector
            v-model="model.targeting.age_min"
          />
          -
          <age-selector
            v-model="model.targeting.age_max"
          />
        </div>
      </div>

      <div>
        <label class="input-label">{{ tr('Geo locations') }}</label>

        <geolocation-target-selector
          ref="geolocationTargetSelector"
          v-model="model.targeting"
        />
      </div>

      <div>
        <label class="input-label">{{ tr('Language') }}</label>
        <v-select
          v-model="model.targeting.locales"
          :options="languages"
          :reduce="(obj) => obj.id"
          :placeholder="tr('Choose at least one language...')"
          label="name"
          multiple
        />
      </div>
    </form-block>

    <div class="modal-action-group">
      <button class="secondary" :disabled="isLoading" @click="() => $emit('close')">
        {{ tr('Cancel') }}
      </button>
      <button class="primary" :disabled="isLoading" @click="() => save()">
        <i v-if="isLoading" class="icon loader white" />
        {{ tr('Save') }}
      </button>
    </div>
  </div>
</template>

<script>
import AgeSelector from '@/components/AgeSelector.vue';
import FormBlock from '@/components/FormBlock.vue';
import GenderSelector from '@/components/GenderSelector.vue';
import GeolocationTargetSelector from '@/components/GeolocationTargetSelector.vue';

import FacebookTargetingDescriptor from '@/descriptors/FacebookTargeting.js';
import SourceProfile from '@/entities/SourceProfile';
import SourceProfileService from '@/services/SourceProfile';

import { isValidURL } from '@/utils/validators';

export default {
  name: 'SourceProfileSettings',
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  components: {
    AgeSelector,
    FormBlock,
    GenderSelector,
    GeolocationTargetSelector,
  },
  data() {
    return {
      isLoading: false,
      model: null,
    };
  },
  computed: {
    title() {
      let platform = '';
      switch (this.entity.platform) {
        case 'facebook':
          platform = this.tr('Facebook Page');
          break;

        case 'instagram':
          platform = this.tr('Instagram Account');
          break;
      }

      return this.tr('":name" :platform settings', {
        ':name': this.entity.name,
        ':platform': platform,
      });
    },
    languages() {
      let list = JSON.parse(JSON.stringify(this.$store.getters['app/facebookLanguages']));
      list.sort((a, b) => a.name.localeCompare(b.name));

      return list;
    },
  },
  created() {
    this.model = new SourceProfile(this.entity);
    this.model.owner = null;

    if (this.model.targeting === null) {
      this.model.targeting = new FacebookTargetingDescriptor();
    }
  },
  methods: {
    save() {
      if (this.$refs['geolocationTargetSelector'] && !this.$refs['geolocationTargetSelector'].validate()) {
        return false;
      }

      if (this.model.website && !isValidURL(this.model.website)) {
        this.$toast.error(this.tr('Invalid website URL.'));
        return false;
      }

      this.isLoading = true;

      SourceProfileService.update(this.model)
        .then((resp) => {
          this.entity.updateWith(resp);

          this.$toast.success(this.tr('Settings have been successfully saved.'));
          this.$emit('close');
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
  }
};
</script>
