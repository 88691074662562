class PaymentRequiredError extends Error {
  constructor(price) {
    super('Payment required');
    this.price = price;
  }

  getPrice() {
    return this.price;
  }
}

module.exports = PaymentRequiredError;
