var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-form-step rule-form-step--review" },
    [
      _c("form-block", {
        attrs: { title: _vm.tr("Overview"), description: _vm.description },
      }),
      (!_vm.isListEmpty || _vm.isLoading) && !_vm.isAiMode
        ? _c(
            "div",
            { staticClass: "filters" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                staticClass: "term-filter icon--search",
                attrs: {
                  type: "text",
                  placeholder: _vm.tr("Search") + "...",
                  disabled: _vm.isLoading,
                },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
              _c("v-select", {
                staticClass: "code-select",
                attrs: {
                  options: _vm.codeFilterOptions,
                  reduce: (item) => item.codes,
                  searchable: false,
                  disabled: _vm.isLoading,
                },
                model: {
                  value: _vm.codeFilter,
                  callback: function ($$v) {
                    _vm.codeFilter = $$v
                  },
                  expression: "codeFilter",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading && _vm.filteredList.length === 0
        ? [
            _vm.isAiMode
              ? _c("empty-screen", {
                  attrs: {
                    icon: "hourglass",
                    title: _vm.tr(
                      "Infinite∞Ai is still analyzing your contents"
                    ),
                    "flag-text": _vm.tr("Wait a moment"),
                    description: _vm.aiEmptyScreenDescription,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "under-description",
                        fn: function () {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "primary",
                                on: { click: () => _vm.loadActivityOverview() },
                              },
                              [_vm._v(" " + _vm._s(_vm.tr("Refresh")) + " ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    739565996
                  ),
                })
              : _c("empty-screen", {
                  attrs: {
                    icon: "search",
                    title: _vm.tr(
                      "No results match your current search criteria"
                    ),
                    "flag-text": _vm.tr("Modify search"),
                    description: _vm.tr(
                      "Currently there are no items that match your criteria. The items you're looking for might appear here later, or try different parameters to see currently available items."
                    ),
                  },
                }),
          ]
        : _c("sorted-table", {
            attrs: { data: _vm.renderedList },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      { attrs: { col: "source", width: "180px" } },
                      [_vm._v(_vm._s(_vm.tr("Source")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "content-cell",
                        attrs: { col: "content", "min-width": "400px" },
                      },
                      [_vm._v(_vm._s(_vm.contentLabel))]
                    ),
                    _c("table-head", {
                      attrs: { col: "actions", width: "40px" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return [
                    _vm.isLoading
                      ? _vm._l([1, 2, 3], function (i) {
                          return _c(
                            "table-row",
                            {
                              key: "rule-activitity-overview-skeleton-" + i,
                              attrs: { "header-color": "white" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("skeleton", {
                                          attrs: {
                                            height: "13px",
                                            width: "150px",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "table-data",
                                { attrs: { col: "source" } },
                                [_c("social-page")],
                                1
                              ),
                              _c(
                                "table-data",
                                {
                                  staticClass: "content-cell",
                                  attrs: { col: "content" },
                                },
                                [_c("inline-social-content")],
                                1
                              ),
                              _c("table-data", { attrs: { col: "actions" } }),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    _vm._l(sort, function (item) {
                      return _c(
                        "table-row",
                        {
                          key: item.entity.id,
                          attrs: {
                            "header-color": _vm.getHeaderColor(item.code),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return (() =>
                                _vm.openFullscreenModal(item)).apply(
                                null,
                                arguments
                              )
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _vm.getHeaderIcon(item.code) !== null
                                      ? _c("i", {
                                          class:
                                            "icon white " +
                                            _vm.getHeaderIcon(item.code),
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getHeaderLabel(item.code)) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "table-data",
                            { attrs: { col: "source" } },
                            [
                              _c("social-page", {
                                attrs: {
                                  "source-profile": item.post.source_profile,
                                  date: item.entity.created_at,
                                  "date-tooltip-prefix":
                                    _vm.getDateTooltipPrefix(item),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "content-cell",
                              attrs: { col: "content" },
                            },
                            [
                              _c("inline-social-content", {
                                attrs: { entity: item.entity },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "actions",
                              attrs: { col: "actions" },
                            },
                            [
                              _vm.isOpenable(item.code)
                                ? _c("navigation-arrow", {
                                    attrs: { color: "error" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ]
                },
              },
            ]),
          }),
      _c("div", { ref: "showMore" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }