import BaseEntity from '@/entities/BaseEntity';

export default class Audience extends BaseEntity {
  id = null;
  type = '';
  name = '';
  has_geotargeting = false;
  has_gender_targeting = false;
  has_age_targeting = false;
  status = true;
  disabled = false;
  deleted_at = null;

  constructor(rawData) {
    super();
    if (!rawData?.status) {
      this.disabled = true;
    }
    if (rawData?.deleted_at) {
      rawData.name = `[Deleted] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }
}
