<template>
  <div :class="wrapperClasses">
    <system-level-notifications v-if="!minimal" />

    <info-text v-if="!minimal" icon="meta" class="meta-tech">
      {{ techProviderText }}
    </info-text>

    <div v-if="!minimal && list.length !== 0" class="page-header">
      <div v-if="headerTitle" class="title">{{ headerTitle }}</div>
      <div v-if="headerText" class="description">
        {{ headerText }}
      </div>
    </div>

    <div v-if="list.length > 10" class="actions">
      <input
        v-model="searchTerm"
        type="text"
        class="text icon--search"
        :placeholder="tr('Search') + '...'"
      />
    </div>

    <template v-if="list.length === 0">
      <empty-screen
        v-if="!hasConnectedProfile"
        :icon="emptyScreenIcon"
        :title="tr('Connect your accounts to start automating')"
        :flag-text="tr('Connect profiles')"
        :description="tr('To begin using our AI-powered automation features, you\'ll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising.')"
        :mobile="minimal"
        @click="handleClick"
      />
      <empty-screen
        v-else-if="platform === 'facebook'"
        icon="facebook"
        :title="tr('Facebook page needed for Facebook ad automation')"
        :flag-text="tr('Watch tutorial')"
        :description="tr('While your Instagram account is connected, we couldn\'t find any Facebook pages linked to your Meta profile. If you want to automate ads from Facebook posts, you\'ll need to add a Facebook page. Watch our tutorial video to learn how to add a Facebook page to your Meta Business profile in just a few minutes.')"
        :mobile="minimal"
        :path="tr('https://www.youtube.com/watch?v=OPuP9F7DQEY')"
      />
      <empty-screen
        v-else-if="platform === 'instagram'"
        icon="instagram"
        :title="tr('Instagram account needed for Instagram content automation')"
        :description="tr('While your Facebook page is connected, we couldn\'t find any Instagram accounts linked to your Meta profile. If you want to automate ads from Instagram posts, you\'ll need to add an Instagram account. Watch our tutorial video to learn how to add an Instagram account to your Meta Business profile in just a few minutes.')"
        :flag-text="tr('Watch tutorial')"
        :mobile="minimal"
      />
    </template>
    <empty-screen
      v-else-if="filteredList.length === 0"
      icon="search"
      :title="tr('No results match your current search criteria')"
      :flag-text="tr('Modify search')"
      :description="tr('Currently there are no items that match your criteria. The items you\'re looking for might appear here later, or try different parameters to see currently available items.')"
      :mobile="minimal"
    />
    <sorted-table
      v-else
      :data="filteredList"
      :initial-sort="{ key: 'name', direction: 'asc' }"
      class="row-hover-shadow"
    >
      <template #header>
        <table-head col="name" class="name-cell" sortable>{{ tr('Name') }}</table-head>
        <table-head col="status" width="80px" class="status-cell">{{ tr('Status') }}</table-head>
        <table-head
          v-if="!minimal"
          col="id"
          width="180px"
          class="external-id-cell"
          sortable
        >
          {{ idLabel }}
        </table-head>
        <table-head v-if="!minimal" col="connected_by" width="180px">{{ tr('Connected by') }}</table-head>
        <table-head
          v-if="!minimal && $app.isAd"
          col="actions"
          :width="$root.supportHasL2Access ? '140px' : '30px'"
        />
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.id" :class="{ 'active': selectedItems.includes(row.id) }">
          <table-data col="name" class="name-cell">
            <label>
              <i
                v-if="isLoading && loadingId === row.id"
                class="icon loader"
              />
              <input
                v-else
                type="checkbox"
                :value="row.id"
                :checked="isConnected(row)"
                :disabled="isDisabled(row)"
                @change="() => checkboxChanged(row)"
              />
              <social-page :source-profile="row" />
            </label>
          </table-data>
          <table-data col="status" class="status-cell">
            <span v-if="isConnected(row)" :class="statusClasses(row)">{{ tr('Active') }}</span>
          </table-data>
          <table-data v-if="!minimal" col="id" class="external-id-cell">
            {{ row.id }}
          </table-data>
          <table-data v-if="!minimal" col="connected_by">
            {{ row.owner ? row.owner.fullname : '-' }}
          </table-data>
          <table-data v-if="!minimal && $app.isAd" col="actions">
            <div v-if="isConnected(row)" class="action-group">
              <button
                v-if="$root.supportHasL2Access"
                class="bordered gray small"
                @click="() => openCommunicatorDebug(row)"
              >
                {{ tr('Debug') }}
              </button>

              <navigation-arrow @click="() => openSourceProfileSettings(row)" />
            </div>
          </table-data>
        </table-row>
      </template>
    </sorted-table>

    <disconnect-confirm-modal ref="disconnectConfirmModal" />
  </div>
</template>

<script>
import CommunicatorDebug from '@/components/CommunicatorDebug.vue';
import DisconnectConfirmModal from '@/components/DisconnectConfirmModal.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import InfoText from '@/components/InfoText.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import SocialPage from '@/components/SocialPage.vue';
import SourceProfileModerationSettings from '@/components/SourceProfileModerationSettings.vue';
import SourceProfileSettings from '@/components/SourceProfileSettings.vue';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';
import SourceProfile from '@/entities/SourceProfile.js';
import User from '@/entities/User';
import { PaymentRequiredError } from '@/errors';
import SourceProfileService from '@/services/SourceProfile';
import ToolService from '@/services/Tool.js';
import { getUserCountry } from '@/utils/date';
import { formatPrice } from '@/utils/number.js';

export default {
  name: 'IntegrationsMetaFacebookItemsView',
  components: {
    DisconnectConfirmModal,
    EmptyScreen,
    NavigationArrow,
    SystemLevelNotifications,
    InfoText,
    SocialPage,
  },
  props: {
    platformFromProp: {
      type: String,
      required: false,
      default: null,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchTerm: '',
    loadingId: null,
    editing: null,
  }),
  computed: {
    wrapperClasses() {
      return {
        'integrations-meta-facebook-items-view': true,
        'minimal': this.minimal,
      };
    },
    techProviderText() {
      return this.tr('<strong>Infinite∞Ad is a Meta Tech Provider</strong> verified and approved by Meta to ensure the highest security standards.');
    },
    emptyScreenIcon() {
      return this.isConnecting ? 'loader' : 'plug';
    },
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
    hasConnectedProfile() {
      const profileItems = this.$root.loggedInUser.company.connected_profiles.filter(profile => profile.platform === 'facebook');

      return profileItems.length !== 0;
    },
    platform() {
      if (this.platformFromProp) {
        return this.platformFromProp;
      }

      switch (this.$route.params.itemType) {
        case 'instagram-accounts':
          return 'instagram';

        case 'facebook-pages':
        default:
          return 'facebook';
      }
    },
    profileDetailsKey() {
      switch (this.platform) {
        case 'instagram':
          return 'instagram_accounts';

        case 'facebook':
        default:
          return 'pages';
      }
    },
    idLabel() {
      switch (this.platform) {
        case 'instagram':
          return this.tr('Account ID');

        case 'facebook':
        default:
          return this.tr('Page ID');
      }
    },
    isLoading() {
      return this.loadingId !== null;
    },
    list() {
      const profileItems = this.$root.loggedInUser.connected_profiles.filter(profile => profile.platform === 'facebook');

      const listItems = [];
      const currentListItemIds = [];

      profileItems.forEach(profile => {
        if (profile.details[this.profileDetailsKey]) {
          profile.details[this.profileDetailsKey].forEach(item => {
            if (!currentListItemIds.includes(item.id)) {
              currentListItemIds.push(item.id);
              const profileData = {
                ...item,
                platform: this.platform,
                external_id: item.id,
              };
              listItems.push(new SourceProfile(profileData));
            }
          });

          listItems.map(item => {
            if (this.isConnected(item) && this.sourceProfileIsMine(item)) {
              item.owner = new User({
                id: this.$root.loggedInUser.id,
                firstname: this.$root.loggedInUser.firstname,
                lastname: this.$root.loggedInUser.lastname,
              });
            }
            else {
              const sourceProfile = this.getSourceProfile(item.id);
              if (sourceProfile) {
                item.owner = sourceProfile.owner;
              }
              else {
                item.owner = null;
              }
            }
          });
        }
      });
      const sourceProfiles = this.$root.currentCompany.source_profiles
        .filter(i => i.platform === this.platform)
        .map(i => {
          const profileData = JSON.parse(JSON.stringify(i));

          profileData.id = profileData.external_id;
          delete profileData.external_id;
          profileData.owner = new User(profileData.owner);

          return new SourceProfile(profileData);
        });

      sourceProfiles.forEach(i => {
        if (!currentListItemIds.includes(i.id)) {
          listItems.push(i);
        }
      });

      return listItems;
    },
    filteredList() {
      const term = this.searchTerm.trim().toLowerCase();
      if (term.length === 0) {
        return this.list;
      }

      return this.list.filter(i => i.name.toLowerCase().includes(term));
    },
    selectedItems() {
      return this.$root.currentCompany.source_profiles
        .filter(i => i.platform === this.platform)
        .filter(i => i.deleted_at === null)
        .map(i => i.external_id);
    },
    headerTitle() {
      switch (this.platform) {
        case 'instagram':
          return this.tr('Instagram Profile Connection');

        case 'facebook':
          return this.tr('Facebook Page Connection');
      }

      return '';
    },
    headerText() {
      if (this.$app.isAd) {
        switch (this.platform) {
          case 'instagram':
            return this.tr('Link your Instagram profile to maximize your social media impact through AI-powered advertising. This connection allows Infinite∞Ad to automatically promote your Instagram posts to the right audience at the right time. Our platform is officially verified by Meta, ensuring your Instagram content and data remain secure.');

          case 'facebook':
            return this.tr('Connect your Facebook Page to automate post promotion and reach more customers effortlessly. This secure connection enables Infinite∞Ad to transform your best-performing posts into high-converting ads automatically. As a Meta-verified application, we maintain strict compliance with all Facebook\'s data protection policies.');
        }
      }
      if (this.$app.isBuzz) {
        switch (this.platform) {
          case 'instagram':
            return this.tr('Connect your Instagram account to automate comment moderation and maintain a positive community effortlessly. This secure integration enables Infinite∞Buzz to filter inappropriate content and highlight valuable engagement automatically on your Instagram posts. Our platform is officially verified by Meta, ensuring your Instagram content and data remain secure.');

          case 'facebook':
            return this.tr('Connect your Facebook Page to automate comment moderation and maintain a positive community effortlessly. As a Meta-verified application, we maintain strict compliance with all Facebook\'s data protection policies.');
        }
      }

      return '';
    },
  },
  watch: {
    platform(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.searchTerm = '';
      }
    },
  },
  methods: {
    formatPrice,
    handleClick() {
      if (!this.isConnecting) {
        this.$emit('connect');
      }
    },
    statusClasses(item) {
      return {
        'status': true,
        'active': this.selectedItems.includes(item.id),
        'not-connected': !this.selectedItems.includes(item.id),
      };
    },
    checkboxChanged(page) {
      this.loadingId = page.id;
      const company = this.$root.currentCompany;

      if (!this.isConnected(page)) {
        this.$emit('loading', true);
        const connectProcessCallback = async chargeExtraAsset => {
          let sourceProfile = null;
          try {
            sourceProfile = await SourceProfileService.connect(this.platform, page.id, this.getUserCountryCode(), chargeExtraAsset);

            company.source_profiles.push(sourceProfile);
            this.openSourceProfileSettings(sourceProfile);
          }
          catch (err) {
            if (err instanceof PaymentRequiredError) {
              let priceMessage = '';
              if (err.getNetPrice() === err.getGrossPrice()) {
                priceMessage = this.tr(
                  'We will attempt to automatically deduct the prorated amount of :currentGrossPrice for this billing period from your registered credit card.',
                  { ':currentGrossPrice': this.formatPrice(err.getGrossPrice(), '€') },
                );
              }
              else {
                priceMessage = this.tr(
                  'We will attempt to automatically deduct the prorated amount of :currentGrossPrice (:currentNetPrice + VAT) for this billing period from your registered credit card.',
                  {
                    ':currentNetPrice': this.formatPrice(err.getNetPrice(), '€'),
                    ':currentGrossPrice': this.formatPrice(err.getGrossPrice(), '€'),
                  },
                );
              }

              this.$confirmation.open({
                title: this.tr('Connecting new extra asset'),
                content: this.tr(
                  'You are attempting to connect a new asset, but your current subscription allows a maximum of :maxAssets assets.',
                  { ':maxAssets': company.max_assets },
                ) + '<br/>' +

                this.tr(
                  'The cost to use an additional asset is :price.',
                  { ':price': this.formatPrice(company.current_subscription?.package?.extra_asset_price, '€', company.current_subscription?.package?.period) },
                ) + '<br/><br/>' +
                priceMessage,
                labelCancel: this.tr('Cancel'),
                labelConfirm: this.tr('Confirm'),
                cancel: () => {
                  this.loadingId = null;
                },
                confirm: () => connectProcessCallback(true)
                  .then(resp => {
                    if (company.max_assets !== null && company.max_assets === company.usedAssetsCount - 1) {
                      ++company.max_assets;
                      ++company.current_subscription.max_assets;
                      ++company.paid_subscription.max_assets;
                    }

                    return resp;
                  }),
              });
            }
            else {
              this.error(err);
            }
          }
          finally {
            this.loadingId = null;
            this.$emit('loading', false);
          }

          return sourceProfile;
        };

        connectProcessCallback(false);
      }
      else {
        const sourceProfile = this.getSourceProfile(page.id);
        if (!sourceProfile) {
          this.loadingId = null;
          this.error(this.tr('Source profile not found.'));
          return;
        }

        if (!this.$app.isAd) {
          this.disconnect(sourceProfile)
            .catch(err => this.error(err))
            .finally(() => this.loadingId = null);
          return;
        }

        ToolService.disconnectPrecheck({ sourceProfileId: sourceProfile.id })
          .then(async resp => {
            if (resp.defunc_rules.length !== 0) {
              this.$refs.disconnectConfirmModal.open(
                { sourceProfile },
                resp.defunc_rules,
                () => this.disconnect(sourceProfile),
              );
            }
            else {
              await this.disconnect(sourceProfile);
            }

            return resp;
          })
          .catch(err => this.error(err))
          .finally(() => this.loadingId = null);
      }
    },
    disconnect(sourceProfile) {
      this.$emit('loading', true);
      const company = this.$root.currentCompany;

      return SourceProfileService.disconnect(sourceProfile.id)
        .then(resp => {
          const index = company.source_profiles.findIndex(obj => obj.id === sourceProfile.id);

          if (index !== -1) {
            company.source_profiles.splice(index, 1);
          }

          this.$toast.success('Data source was successfully disconnected.');

          return resp;
        }).finally(() => {
          this.$emit('loading', false);
        });
    },
    isConnected(page) {
      return this.selectedItems.includes(page.id);
    },
    isDisabled(page) {
      if (this.isLoading) {
        return true;
      }

      if (this.isConnected(page) && !this.sourceProfileIsMine(page)) {
        return true;
      }

      return false;
    },
    sourceProfileIsMine(page) {
      const sourceProfile = this.getSourceProfile(page.id);
      if (!sourceProfile) {
        return false;
      }

      return sourceProfile.owner.id === this.$root.loggedInUser.id;
    },
    getSourceProfile(externalId) {
      return this.$root.currentCompany.source_profiles
        .find(i => i.platform === this.platform && i.external_id === externalId);
    },
    openSourceProfileSettings(sourceProfile) {
      if ('external_id' in sourceProfile) {
        this.editing = sourceProfile;
      }
      else {
        this.editing = this.getSourceProfile(sourceProfile.id);
      }

      if (!this.minimal) {
        const modalMaxHeight = this.$app.isBuzz ? 640 : 540;
        this.$modal.show(
          this.$app.isBuzz ? SourceProfileModerationSettings : SourceProfileSettings,
          { entity: this.editing },
          {  class: 'modal-without-padding modal-without-scroll modal-height--' + modalMaxHeight },
          { closed: () => this.editing = null },
        );
      }
    },
    openCommunicatorDebug(sourceProfile) {
      const sourceProfileEntity = this.getSourceProfile(sourceProfile.id);

      this.$modal.show(
        CommunicatorDebug,
        { entity: sourceProfileEntity },
        { class: 'modal-without-padding modal-without-scroll modal-height--640 modal-width--960' },
      );
    },
    getUserCountryCode() {
      const userCountry = getUserCountry();
      if (userCountry) {
        const userCountryItem = this.$store.getters['app/countries'].find(c => c.name === userCountry);
        if (userCountryItem) {
          return userCountryItem.iso_a2;
        }
      }

      return null;
    },
  },
};
</script>
