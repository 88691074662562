<template>
  <div v-if="isVisible" :class="classList">
    <info-text
      v-if="showTokenExpiredError"
      type="error"
      :link="isAdmin ? '/integrations/meta/profiles' : ''"
      large
    >
      {{ tr('The access token associated with one of your organization\'s connected :platform profiles named ":name" has expired.', {
        ':name': connectedProfileWithExpiredToken.name,
        ':platform': connectedProfileWithExpiredToken.platform,
      }) }}
      {{ $app.isAd ? tr('Consequently, until the profile is reconnected, the automations using the assets of this profile will not work.') : '' }}
      {{ $app.isBuzz ? tr('Consequently, until the profile is reconnected, the comment moderations using the assets of this profile will not work.') : '' }}
    </info-text>
    <info-text
      v-else-if="showTokenWillBeExpiredWarning"
      type="error"
      :link="isAdmin ? '/integrations/meta/profiles' : ''"
      large
    >
      {{ tr('The access token associated with one of your organization\'s connected :platform profiles named ":name" will be expired :expireInterval.', {
        ':name': connectedProfileWithExpiredToken.name,
        ':platform': connectedProfileWithExpiredToken.platform,
        ':expireInterval': connectedProfileTokenExpireInterval,
      }) }}
      {{ $app.isAd ? tr('Consequently, the profile needs to be reconnected, otherwise automations using the profile\'s assets will not work.') : '' }}
      {{ $app.isBuzz ? tr('Consequently, the profile needs to be reconnected, otherwise comment moderations using the profile\'s assets will not work.') : '' }}
    </info-text>
    <info-text
      v-else-if="showTokenHasMissingScopeWarning"
      type="error"
      :link="isAdmin ? '/integrations/meta/profiles' : ''"
      large
    >
      {{ tr('The access token associated with one of your organization\'s connected :platform profiles named ":name" does not have a required permission. Reconnecting the profile is required to resolve the issue.', {
        ':name': connectedProfileWithMissingScope.name,
        ':platform': connectedProfileWithMissingScope.platform,
      }) }}
    </info-text>

    <info-text
      v-if="hasNoBillingAddress"
      link="/settings/billing-and-payment"
      icon="cash"
      large
    >
      {{ tr('Don\'t forget to set your billing information on the Settings / Billing page.') }}
    </info-text>

    <info-text
      v-if="showLastPaymentIsIncomplete"
      type="warning"
      icon="cash"
      link="/settings/payment-history"
      large
    >
      {{ tr('You have a pending payment obligation. Check it on the Settings / Payment History page.') }}
    </info-text>

    <info-text v-if="showAssetNumberError && !showPaymentArrearsError && !hasNoActiveSubscription" type="error" large>
      {{
        tr(
          'Attention! Your company is entitled to use a maximum of :availableNum devices, but :connectedNum devices are connected.',
          {
            ':availableNum': $root.currentCompany.max_assets,
            ':connectedNum': $root.currentCompany.usedAssetsCount,
          }
        )
      }}

      {{ $app.isAd ? tr('Until you fix the problem, your automations won\'t run.') : '' }}
      {{ $app.isAd ? tr('A solution may be to switch to a larger subscription package, purchase extra asset option, or disconnect one or more assets.') : '' }}

      {{ $app.isBuzz ? tr('Until you fix the problem, automatic comment moderation will not run.') : '' }}
      {{ $app.isBuzz ? tr('A solution may be to switch to a larger subscription package, or disconnect one or more assets.') : '' }}
    </info-text>

    <info-text
      v-if="showPaymentArrearsError"
      type="error"
      icon="cash"
      :link="isAdmin ? '/settings/payment-history' : ''"
      large
    >
      {{ tr('Your company\'s payment balance shows arrears!') }}
      {{ $app.isAd ? tr('Because of this, your automations are currently not running.') : '' }}
      {{ $app.isBuzz ? tr('Because of this, your automatic comment moderation is not running.') : '' }}

      <template v-if="isAdmin">
        {{ tr('Complete the payment obligation on the Settings / Payment history page.', {
          ':date': formatToStringYMD(company.grace_period_end_date) + ' ' + formatTime(company.grace_period_end_date),
        }) }}
      </template>
      <template v-else>
        {{ tr('Ask the administrator to fulfill your company\'s payment obligations.', {
          ':date': formatToStringYMD(company.grace_period_end_date) + ' ' + formatTime(company.grace_period_end_date),
        }) }}
      </template>

      {{ $app.isAd ? tr('After the account is settled, your automations will run again.') : '' }}
      {{ $app.isBuzz ? tr('After the account is settled, comment moderation will run again.') : '' }}
    </info-text>
    <info-text
      v-else-if="showPaymentArrearsWarning"
      type="warning"
      :link="isAdmin ? '/settings/payment-history' : ''"
      large
    >
      {{ tr('Attention! We were unable to renew your subscription on the last deadline.') }}

      <template v-if="isAdmin">
        {{ tr('Complete the payment obligation by :date on the Settings / Payment history page.', {
          ':date': formatToStringYMD(company.grace_period_end_date) + ' ' + formatTime(company.grace_period_end_date),
        }) }}
      </template>
      <template v-else>
        {{ tr('Ask the administrator to fulfill your company\'s payment obligations by :date.', {
          ':date': formatToStringYMD(company.grace_period_end_date) + ' ' + formatTime(company.grace_period_end_date),
        }) }}
      </template>

      {{ $app.isAd ? tr('If this is not done, the automations will be suspended.') : '' }}
      {{ $app.isBuzz ? tr('If this is not done, the commend moderation will be suspended.') : '' }}
    </info-text>
    <info-text
      v-else-if="hasNoActiveSubscription"
      type="warning"
      link="/settings/billing-and-payment"
      large
    >
      {{ $app.isAd ? tr('You currently do not have an active subscription! To use Infinite∞Ad, select and subscribe to the package that suits you best.') : '' }}
      {{ $app.isBuzz ? tr('You currently do not have an active subscription! To use Infinite∞Buzz, select and subscribe to the package that suits you best.') : '' }}
    </info-text>
    <info-text
      v-if="showAdAccountStatusNotActive"
      type="error"
      large
    >
      {{ adAccountStatusMessage }}
    </info-text>
  </div>
</template>

<script>
import InfoText from '@/components/InfoText';
import { formatTime, formatToStringYMD } from '@/utils/date';

const AD_ACCOUNT_STATUS = {
  ACTIVE: 1,
  DISABLED: 2,
  UNSETTLED: 3,
  UNKNOWN: 4,
};

export default {
  name: 'SystemLevelNotifications',
  components: {
    InfoText,
  },
  props: {
    spaceTop: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    classList() {
      return {
        'system-level-notifications-component': true,
        'space-top': this.spaceTop,
      };
    },
    company() {
      return this.$root.currentCompany;
    },
    isAdmin() {
      return this.$root.loggedInUser.level === 'admin';
    },
    isVisible() {
      return this.hasNoBillingAddress
        || this.hasNoActiveSubscription
        || this.showAssetNumberError
        || this.showPaymentArrearsError
        || this.showPaymentArrearsWarning
        || this.showLastPaymentIsIncomplete
        || this.showTokenExpiredError
        || this.showTokenWillBeExpiredWarning
        || this.showTokenHasMissingScopeWarning
        || this.showAdAccountStatusNotActive;
    },
    hasNoBillingAddress() {
      return this.isAdmin && this.company.billing_country.length === 0;
    },
    hasNoActiveSubscription() {
      return !this.company.current_subscription?.package && this.isAdmin;
    },
    showAssetNumberError() {
      return this.company.max_assets !== null && this.company.max_assets < this.company.usedAssetsCount;
    },
    showPaymentArrearsError() {
      return this.company.grace_period_end_date !== null && this.company.grace_period_end_date < new Date();
    },
    showPaymentArrearsWarning() {
      return this.company.grace_period_end_date !== null;
    },
    showLastPaymentIsIncomplete() {
      return this.isAdmin && this.company.last_payment_is_incomplete;
    },
    connectedProfileWithExpiredToken() {
      const date = new Date();
      date.setDate(date.getDate() + 6);

      return this.$root.currentCompany.connected_profiles.find(profile => profile.token_expire_at < date);
    },
    connectedProfileWithMissingScope() {
      return this.$root.currentCompany.connected_profiles.find(profile => profile.missing_scope);
    },
    showTokenExpiredError() {
      return this.connectedProfileWithExpiredToken && this.connectedProfileWithExpiredToken.token_expire_at < new Date();
    },
    showTokenWillBeExpiredWarning() {
      return this.connectedProfileWithExpiredToken;
    },
    showTokenHasMissingScopeWarning() {
      return this.connectedProfileWithMissingScope;
    },
    connectedProfileTokenExpireInterval() {
      const diff = this.connectedProfileWithExpiredToken.token_expire_at.getTime() - (new Date()).getTime();
      const diffInDays = parseInt((diff / 1000) / 86400);

      if (diffInDays > 1) {
        return this.tr('in :numberOfDays days', { ':numberOfDays': diffInDays });
      }
      if (diffInDays === 1) {
        return this.tr('in 1 day');
      }

      return this.tr('today');
    },
    showAdAccountStatusNotActive() {
      return this.getProblematicAdAccounts().length > 0;
    },
    adAccountStatusMessage() {
      const { accounts, type } = this.getProblematicAdAccounts()[0] || {};
      if (!accounts?.length) {
        return '';
      }

      const messages = {
        [AD_ACCOUNT_STATUS.DISABLED]: {
          single: this.tr('Your ad account :name is disabled.', { ':name': accounts[0].name }),
          multiple: this.tr(':count ad accounts are disabled.', { ':count': accounts.length }),
        },
        [AD_ACCOUNT_STATUS.UNSETTLED]: {
          single: this.tr('Your ad account :name has unsettled payments.', { ':name': accounts[0].name }),
          multiple: this.tr(':count ad accounts have unsettled payments.', { ':count': accounts.length }),
        },
        [AD_ACCOUNT_STATUS.UNKNOWN]: {
          single: this.tr('Your ad account :name has been deactivated.', { ':name': accounts[0].name }),
          multiple: this.tr(':count ad accounts have been deactivated.', { ':count': accounts.length }),
        },
      };

      const messageSecondary = {
        single: this.tr('Automations associated with these ad account will not work until the issue is resolved.'),
        multiple: this.tr('Automations associated with these ad accounts will not work until the issue is resolved.'),
      };

      const statusMessage = messages[type][accounts.length === 1 ? 'single' : 'multiple'];
      const statusMessageSecondary = messageSecondary[accounts.length === 1 ? 'single' : 'multiple'];
      return `${statusMessage} ${statusMessageSecondary}`;
    },
  },
  methods: {
    formatToStringYMD,
    formatTime,
    getProblematicAdAccounts() {
      const statusGroups = [
        { type: AD_ACCOUNT_STATUS.DISABLED, accounts: [] },
        { type: AD_ACCOUNT_STATUS.UNSETTLED, accounts: [] },
        { type: AD_ACCOUNT_STATUS.UNKNOWN, accounts: [] },
      ];

      this.company.ad_accounts.forEach(account => {
        const group = statusGroups.find(group => group.type === account.account_status);
        if (group) {
          group.accounts.push(account);
        }
      });

      return statusGroups.filter(group => group.accounts.length > 0);
    },
  },
};
</script>
