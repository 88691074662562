var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _c("img", { attrs: { src: _vm.entity.picture } }),
    _c("div", { staticClass: "textual" }, [
      _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.entity.name))]),
      _vm.entity.token_expire_at || this.hasMissingPermission
        ? _c(
            "span",
            { staticClass: "warn-message" },
            [
              _vm.entity.token_expire_at
                ? [
                    _vm.hasMissingPermission && !_vm.isExpired
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr("Missing permission.")) +
                              " " +
                              _vm._s(_vm.tr("Reconnection is required!")) +
                              " "
                          ),
                        ]
                      : !_vm.isExpired
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr("Expires in :time", {
                                  ":time": _vm.expireIn,
                                })
                              ) +
                              " "
                          ),
                        ]
                      : [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tr("Reconnection is required!")) +
                              " "
                          ),
                        ],
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]),
    _vm.entity.owner.id === _vm.$root.loggedInUser.id &&
    !this.$root.isSupportMode
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tr("Reconnect"),
              expression: "tr('Reconnect')",
            },
          ],
          staticClass: "icon refresh",
          on: { click: () => _vm.reconnectProfile() },
        })
      : _vm._e(),
    _vm.disconnectButtonVisible
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tr("Disconnect"),
              expression: "tr('Disconnect')",
            },
          ],
          staticClass: "icon close",
          on: { click: () => _vm.removeProfile() },
        })
      : _vm._e(),
    _vm.$root.supportHasL2Access
      ? _c("i", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tr("Debug"),
              expression: "tr('Debug')",
            },
          ],
          staticClass: "icon debug-console",
          on: {
            click: function ($event) {
              return _vm.openCommunicatorDebug(_vm.entity)
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }