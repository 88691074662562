/* eslint-disable filenames/match-regex */

import PostService from '@/services/Post.js';

const ITEM_PER_PAGE = 20;

let visibleFields = JSON.parse(window.localStorage.getItem('visibleFields') || '[]');

if(!visibleFields[0]) {
  visibleFields = [
    'text',
    'ai_value',
    'ai_content_actuality',
  ];
}
window.localStorage.setItem('visibleFields', JSON.stringify(visibleFields));

let metricVisibleFields = JSON.parse(window.localStorage.getItem('metricVisibleFields') || '[]');
if(!metricVisibleFields[0]) {
  metricVisibleFields = [
    { 'machine_name':'reach','label':'Reach','sortable':true },
    { 'machine_name':'impressions','label':'Impression','sortable':true },
    { 'machine_name':'clicks','label':'Clicks','sortable':true },
    { 'machine_name':'clicks_unique','label':'Unique clicks','sortable':true },
  ];
}
window.localStorage.setItem('metricVisibleFields', JSON.stringify(metricVisibleFields));

const state = {
  isLoading: false,
  hasMore: true,
  page: 0,
  sourceProfileId: null,
  searchTerm: '',
  orderBy: 'created_at',
  order: 'desc',
  visibleFields,
  metricVisibleFields,
  list: [],
};

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  hasMore(state) {
    return state.hasMore;
  },
  page(state) {
    return state.page;
  },
  sourceProfileId(state) {
    return state.sourceProfileId;
  },
  searchTerm(state) {
    return state.searchTerm;
  },
  orderBy(state) {
    return state.orderBy;
  },
  order(state) {
    return state.order;
  },
  visibleFields(state) {
    return state.visibleFields;
  },
  metricVisibleFields(state) {
    return state.metricVisibleFields;
  },
  list(state) {
    return state.list;
  },
};

const actions = {
  resetView(store, keepTerm) {
    store.commit('setIsLoading', false);
    store.commit('setHasMore', true);
    store.commit('setPage', 0);
    store.commit('setList', []);

    if (!keepTerm) {
      store.commit('setSourceProfileId', null);
      store.commit('setSearchTerm', '');
    }
  },
  loadMoreData(store) {
    if (
      store.getters['isLoading']
      || !store.getters['hasMore']
    ) {
      return Promise.resolve(false);
    }

    store.commit('setIsLoading', true);
    store.commit('increasePage');

    const params = {
      term: store.getters['searchTerm'],
      sourceProfileId: store.getters['sourceProfileId'],
      orderBy: store.getters['orderBy'],
      order: store.getters['order'],
      page: store.getters['page'],
      limit: ITEM_PER_PAGE,
    };

    return PostService.getList(params)
      .then(resp => {
        const list = store.getters['list'];
        resp.forEach(item => list.push(item));

        store.commit('setHasMore', resp.length === ITEM_PER_PAGE);

        return resp;
      })
      .catch(err => {
        store.commit('setHasMore', false);
        throw err;
      })
      .finally(() => store.commit('setIsLoading', false));
  },
};

const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setHasMore(state, value) {
    state.hasMore = value;
  },
  setPage(state, value) {
    state.page = value;
  },
  increasePage(state) {
    state.page += 1;
  },
  setSourceProfileId(state, value) {
    state.sourceProfileId = value;
  },
  setSearchTerm(state, value) {
    state.searchTerm = value;
  },
  setOrderBy(state, value) {
    state.orderBy = value;
  },
  setOrder(state, value) {
    state.order = value;
  },
  setVisibleFields(state, value) {
    state.visibleFields = value;
    window.localStorage.setItem('visibleFields', JSON.stringify(state.visibleFields));
  },
  setMetricVisibleFields(state, value) {
    state.metricVisibleFields = value;
    window.localStorage.setItem('metricVisibleFields', JSON.stringify(state.metricVisibleFields));
  },
  setList(state, value) {
    state.list = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
