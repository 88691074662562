var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-history-details-component" },
    [
      _c("sorted-table", {
        staticClass: "payment-history-items-table row-hover-shadow",
        attrs: { data: _vm.entity.items },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("table-head", { attrs: { col: "title" } }),
                _c(
                  "table-head",
                  { staticClass: "cost", attrs: { col: "net", width: "80px" } },
                  [_vm._v(_vm._s(_vm.tr("Net")))]
                ),
                _c(
                  "table-head",
                  {
                    staticClass: "cost",
                    attrs: { col: "gross", width: "80px" },
                  },
                  [_vm._v(_vm._s(_vm.tr("Gross")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ sort }) {
              return _vm._l(sort, function (row) {
                return _c(
                  "table-row",
                  { key: row.id },
                  [
                    _c("table-data", { attrs: { col: "title" } }, [
                      _vm._v(" " + _vm._s(row.title) + " "),
                    ]),
                    _c(
                      "table-data",
                      { staticClass: "cost", attrs: { col: "net" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.formatPrice(row.net, "€")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "table-data",
                      { staticClass: "cost", attrs: { col: "gross" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.formatPrice(row.gross, "€")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
      _c("div", { staticClass: "total" }, [
        _vm._v(
          " " +
            _vm._s(_vm.tr("Total net")) +
            ": " +
            _vm._s(_vm.formatPrice(_vm.entity.net, "€"))
        ),
        _c("br"),
        _vm._v(
          " " +
            _vm._s(_vm.tr("Total gross")) +
            ": " +
            _vm._s(_vm.formatPrice(_vm.entity.gross, "€")) +
            " "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }