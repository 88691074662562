import BaseEntity from '@/entities/BaseEntity';

export default class Coupon extends BaseEntity {
  id = null;
  code = null;
  discount = null;
  valid_from = null;
  valid_to = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
