var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-page auth-view" }, [
    _c("div", { staticClass: "content-wrapper" }, [
      _c("div", { staticClass: "input-form-container" }, [
        _c("h1", [_vm._v(_vm._s(_vm.tr("User identification")))]),
        _c("div", { staticClass: "auth-form-view login-form-view" }, [
          _c(
            "form",
            {
              staticClass: "form",
              on: { submit: ($event) => _vm.onFormSubmit($event) },
            },
            [
              _c("div", { staticClass: "input-label" }, [
                _c("span", [_vm._v(_vm._s(_vm.tr("User ID")))]),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.userId,
                    expression: "userId",
                  },
                ],
                attrs: {
                  type: "text",
                  min: "36",
                  max: "36",
                  placeholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                  autofocus: "",
                  disabled: _vm.isLoading,
                },
                domProps: { value: _vm.userId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.userId = $event.target.value
                  },
                },
              }),
              _c(
                "button",
                { staticClass: "primary", attrs: { type: "submit" } },
                [
                  _vm.isLoading
                    ? _c("i", {
                        staticClass: "icon loader white",
                        attrs: {
                          loading: _vm.isLoading,
                          color: "#ffffff",
                          size: "6px",
                        },
                      })
                    : [_vm._v(" " + _vm._s(_vm.tr("Login")) + " ")],
                ],
                2
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "logout-link",
              attrs: { title: _vm.tr("Log out") },
              on: { click: () => _vm.logout() },
            },
            [
              _c("i", { staticClass: "i icon logout" }),
              _c("span", [_vm._v(_vm._s(_vm.tr("Log out")))]),
            ]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "placeholder" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }