var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "placement-selector" }, [
    _c(
      "div",
      { staticClass: "upper" },
      _vm._l(_vm.platforms, function (platform, key) {
        return _c(
          "three-state-checkbox",
          {
            key: "top-checkbox-" + key,
            attrs: {
              items: _vm.adPlacements[platform.machineName].map(
                (fbAdPlacement) => fbAdPlacement.machine_name
              ),
              disabled: _vm.disabled || _vm.isDisabledPlatform(platform),
            },
            on: { change: () => _vm.$nextTick(() => _vm.$forceUpdate()) },
            model: {
              value:
                _vm.entity.settings.adset_targeting_positions[
                  platform.machineName
                ],
              callback: function ($$v) {
                _vm.$set(
                  _vm.entity.settings.adset_targeting_positions,
                  platform.machineName,
                  $$v
                )
              },
              expression:
                "entity.settings.adset_targeting_positions[platform.machineName]",
            },
          },
          [_vm._v(" " + _vm._s(platform.label) + " ")]
        )
      }),
      1
    ),
    _c("div", { staticClass: "divider" }),
    _c(
      "div",
      { staticClass: "under" },
      _vm._l(_vm.platforms, function (platform, key) {
        return _c(
          "div",
          {
            key: "bottom-checkbox-" + key,
            staticClass: "checkbox-list vertical",
          },
          _vm._l(
            _vm.adPlacements[platform.machineName],
            function (fbAdPlacement, key) {
              return _c(
                "label",
                {
                  key: "fb-ad-placement-" + key,
                  staticClass: "fb-placement-list",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.entity.settings.adset_targeting_positions[
                            platform.machineName
                          ],
                        expression:
                          "entity.settings.adset_targeting_positions[platform.machineName]",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      disabled:
                        _vm.disabled || _vm.isDisabledPlatform(platform),
                    },
                    domProps: {
                      value: fbAdPlacement.machine_name,
                      checked: Array.isArray(
                        _vm.entity.settings.adset_targeting_positions[
                          platform.machineName
                        ]
                      )
                        ? _vm._i(
                            _vm.entity.settings.adset_targeting_positions[
                              platform.machineName
                            ],
                            fbAdPlacement.machine_name
                          ) > -1
                        : _vm.entity.settings.adset_targeting_positions[
                            platform.machineName
                          ],
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a =
                              _vm.entity.settings.adset_targeting_positions[
                                platform.machineName
                              ],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = fbAdPlacement.machine_name,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.entity.settings.adset_targeting_positions,
                                  platform.machineName,
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.entity.settings.adset_targeting_positions,
                                  platform.machineName,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.entity.settings.adset_targeting_positions,
                              platform.machineName,
                              $$c
                            )
                          }
                        },
                        function ($event) {
                          _vm.$nextTick(() => _vm.$forceUpdate())
                        },
                      ],
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.tr(fbAdPlacement.label)) + " "),
                ]
              )
            }
          ),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }