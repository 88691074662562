<template>
  <div class="rule-switch-modal-content-component">
    <div class="modal-title">
      {{ tr('Would you like to turn on the campaign too?') }}
    </div>

    <div class="modal-content">
      <p>{{ tr('The campaign is currently inactive, in which the desired automation is being worked to be turned on.') }}</p>
      <p>{{ tr('Turning on automation also entails reactivating the campaign, thus the existing advertisements within it will become active again.') }}</p>
    </div>

    <div class="modal-action-group">
      <button
        class="secondary"
        @click="() => close()"
        :disabled="isLoading"
      >
        {{ tr('Cancel') }}
      </button>
      <button
        class="primary"
        @click="() => confirm()"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="icon loader white" />
        <template v-else>
          {{ tr('Turn on Automation and Campaign') }}
        </template>
      </button>
    </div>

  </div>
</template>

<script>
import Rule from '@/entities/Rule';
import RuleService from '@/services/Rule.js';

export default {
  name: 'RuleCampaignStatusChangeModalContent',
  props: {
    rule: {
      type: Rule,
      required: true,
    },
  },
  data: () => ({
    loadingRule: false,
    isLoading: false,
  }),
  computed: {
    id() {
      return this.rule.id;
    },
    newStatus() {
      return !this.rule.active;
    },
  },
  methods: {
    close() {
      this.$modal.hide('rule-with-inactive-campaign-switch-on-modal');
    },
    confirm() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      RuleService.changeStatus(this.id, this.newStatus, true)
        .then((rule) => this.onSuccess(rule))
          .catch(err => {
            this.error(err);
            this.rule.active = !this.newStatus;
          })
          .finally(() => this.isLoading = false);
    },
    onSuccess(newValues) {
      this.rule.updateWith(newValues);
      this.close();
    },
  }
};
</script>
