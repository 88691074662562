var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "font-size": "17px", "font-weight": "800" } },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _c("button", { on: { click: () => _vm.openModal() } }, [
        _vm._v("Open new modal"),
      ]),
      _c("button", { on: { click: () => _vm.$emit("close") } }, [
        _vm._v("Close"),
      ]),
      _c("button", { on: { click: () => _vm.$emit("close-all") } }, [
        _vm._v("Close all"),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }