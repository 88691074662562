<template>
  <div class="auth-code-input">
    <input
      v-for="(n, index) in code"
      :key="index"
      :id="'input_' + index"
      ref="input"
      type="number"
      pattern="\d*"
      maxlength="1"
      v-model="code[index]"
      @input="handleInput"
      @keypress="isNumber"
      @keydown.enter="handleEnter"
      @keyup.delete="handleDelete"
      @paste="onPaste"
      :disabled="disabled"
    />
    <div class="separator"></div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      code: Array(6),
      dataFromPaste: null,
      keysAllowed: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    };
  },
  methods: {
    focus() {
      this.$refs['input'][0].focus();
    },
    isNumber(event) {
      if (event.key === 'v' && event.metaKey) {
        return;
      }

      event.currentTarget.value = '';
      const keyPressed = event.key;
      if (!this.keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    handleInput(event) {
      const inputType = event.inputType;
      let currentActiveElement = event.target;

      if (inputType === 'insertText') {
        if (currentActiveElement.value.length > 1) {
          this.dataFromPaste = currentActiveElement.value.trim().split('');

          return this.handleInput({
            inputType: 'insertFromPaste',
            target: currentActiveElement,
          });
        }

        currentActiveElement.nextElementSibling?.focus();
      }

      else if (inputType === 'insertFromPaste' && this.dataFromPaste) {
        if (this.dataFromPaste.length === 6) {
          currentActiveElement = this.$refs.input[0];
        }

        for (const num of this.dataFromPaste) {
          const id = parseInt(currentActiveElement.id.split('_')[1]);

          this.code[id] = num;

          if (currentActiveElement.nextElementSibling) {
            currentActiveElement = currentActiveElement.nextElementSibling;
          }
        }

        currentActiveElement.focus();

        this.dataFromPaste = null;
      }

      this.emitValue();
    },
    handleDelete(event) {
      const value = event.target.value;
      let currentActiveElement = event.target;
      if (!value) {
        currentActiveElement.previousElementSibling?.focus();
      }
    },
    handleEnter(event) {
      event.preventDefault();

      let currentActiveElement = event.target;
      if (!currentActiveElement.nextElementSibling || currentActiveElement.nextElementSibling.tagName !== 'INPUT') {
        this.$emit('enter');
      }
    },
    onPaste(event) {
      this.dataFromPaste = event.clipboardData
        ?.getData('text')
        .trim()
        .split('');

      if (this.dataFromPaste) {
        for (const num of this.dataFromPaste) {
          if (!this.keysAllowed.includes(num)) {
            event.preventDefault();

            this.dataFromPaste = null;
          }
        }
      }
    },
    emitValue() {
      const codeValue = this.code.join('');
      this.$emit('input', codeValue);
    },
  },
};
</script>
