export function getObjectValue(object, fieldPath) {
  if (typeof object !== 'object') {
    throw new Error('Invalid value in "object" argument.');
  }

  let currentValue = object;
  const parts = fieldPath.split('.');
  for (let part of parts) {
    if (!(part in currentValue)) {
      return null;
    }

    currentValue = currentValue[part];
  }

  return currentValue;
}
