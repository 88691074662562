<template>
  <div class="rule-form-step rule-form-step--ai-mode rule-form-step--ai-mode--step-1">
    <form-block
      :title="tr('Your goal')"
      :description="tr('Depending on what you want to achieve with your ad, different settings will be the most optimal for running ads. By defining the goal, Infinite∞Ad will make these settings automatically.')"
    >
      <div class="checkbox-list vertical">
        <label>
          <input v-model="goal" type="radio" name="goal" value="conversion" />
          {{ tr('You have a webshop, or you sell products or services online') }}
        </label>
        <label>
          <input v-model="goal" type="radio" name="goal" value="traffic" />
          {{ tr('You have a website and you want to drive visitors there') }}
        </label>
        <label>
          <input v-model="goal" type="radio" name="goal" value="app_promotion" />
          {{ tr('You have an application and you want it to achieve more results') }}
        </label>
        <label>
          <input v-model="goal" type="radio" name="goal" value="engagement" />
          {{ tr('You would like your Facebook or Instagram posts to reach more people and receive more reactions') }}
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <ad-account-block
      :entity="entity"
      :description="tr('You can choose the ad account in which you want to launch your advertisement. (This is useful if you manage multiple accounts.)')"
    />

    <conversion-odax-block
      v-if="conversionEventSettingsAvailable"
      :entity="entity"
      :disabled="$parent.readonlyMode"
      minimal
    />

    <div class="divider" />

    <campaign-budget-block
      :entity="entity"
      :title="tr('Daily budget')"
      :description="tr('You can determine the maximum daily budget that you allocate for advertising.')"
      :switchable="false"
      minimal
    />

    <form-block
      v-if="scalingBudgetIsAvailable"
      :title="tr('Daily budget for high-performing ads') + ' (' + tr('optional') + ')'"
      :description="tr('You can determine the maximum daily budget that you would allocate to those ads that have already proven to perform well.')"
    >
      <div>
        <money-input
          v-model="scalingBudget"
          :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : 'EUR'"
          :min="entity.adAccountMinDailyBugdet"
        />

        <info-text v-if="entity.adAccountCurrency">
          {{ tr('Minimum daily budget') }}:
          {{ entity.adAccountCurrency.symbol }} {{ formatNumber(entity.adAccountMinDailyBugdet) }}
        </info-text>
      </div>
    </form-block>

    <div class="divider" />

    <dsa-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <source-block :entity="entity" :disabled="$parent.readonlyMode" single />

    <form-block v-if="goal === 'conversion'">
      <div>
        <label class="input-label">
          {{ tr('Product or service sales link') }}
        </label>
        <input
          v-model="entity.settings.cta_url"
          type="text"
          :placeholder="tr('Enter the URL you want to promote')"
        />
      </div>
    </form-block>

    <div class="divider" />

    <div>
      <demographic-target-block
        :title="tr('Target audience')"
        :entity="entity"
        :disabled="$parent.readonlyMode"
      />

      <label class="input-label geotarget-label">{{ tr('Geotargeting') }}</label>
      <geotarget-block
        :entity="entity"
        :disabled="$parent.readonlyMode"
        title=""
      />
    </div>

    <div class="divider" />

    <campaign-special-ad-categories-block :entity="entity" :disabled="!entity.isNew" />
  </div>
</template>

<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import AdAccountBlock from '@/components/RuleForm/Blocks/AdAccountBlock.vue';
import CampaignBudgetBlock from '@/components/RuleForm/Blocks/CampaignBudgetBlock.vue';
import CampaignSpecialAdCategoriesBlock from '@/components/RuleForm/Blocks/CampaignSpecialAdCategoriesBlock.vue';
import ConversionOdaxBlock from '@/components/RuleForm/Blocks/ConversionOdaxBlock.vue';
import DemographicTargetBlock from '@/components/RuleForm/Blocks/DemographicTargetBlock.vue';
import DsaBlock from '@/components/RuleForm/Blocks/DsaBlock.vue';
import GeotargetBlock from '@/components/RuleForm/Blocks/GeotargetBlock.vue';
import SourceBlock from '@/components/RuleForm/Blocks/SourceBlock.vue';

import FormBlock from '@/components/FormBlock.vue';
import InfoText from '@/components/InfoText.vue';
import MoneyInput from '@/components/MoneyInput.vue';

import FacebookTargeting from '@/descriptors/FacebookTargeting';

import RuleService from '@/services/Rule.js';

import { formatNumber } from '@/utils/number.js';
import { isValidURL } from '@/utils/validators';

export default {
  name: 'AiModeRuleFormStep1',
  mixins: [ RuleFormStepMixin ],
  components: {
    AdAccountBlock,
    CampaignBudgetBlock,
    CampaignSpecialAdCategoriesBlock,
    ConversionOdaxBlock,
    DemographicTargetBlock,
    DsaBlock,
    GeotargetBlock,
    SourceBlock,
    FormBlock,
    InfoText,
    MoneyInput,
  },
  data: () => ({
    goal: null,
    budget: null,
    scalingBudget: null,
  }),
  computed: {
    conversionEventSettingsAvailable() {
      return this.goal === 'conversion' || this.goal === 'app_promotion';
    },
    scalingBudgetIsAvailable() {
      return this.goal === 'conversion' || this.goal === 'traffic';
    },
  },
  watch: {
    goal(val) {
      this.entity.settings.cta_url = null;

      switch (val) {
        case 'conversion':
          this.entity.output_campaign.objective = 'OUTCOME_SALES';
          break;

        case 'traffic':
          this.entity.output_campaign.objective = 'OUTCOME_TRAFFIC';
          break;

        case 'app_promotion':
          this.entity.output_campaign.objective = 'OUTCOME_APP_PROMOTION';
          break;

        case 'engagement':
          this.entity.output_campaign.objective = 'OUTCOME_ENGAGEMENT';
          break;
      }
    },
    conversionEventSettingsAvailable(val) {
      if (!val) {
        this.entity.settings.adset_conversion_app_id = null;
        this.entity.settings.adset_conversion_app_store_url = null;
        this.entity.settings.adset_conversion_pixel_id = null;
        this.entity.settings.adset_conversion_event = null;
      }
    },
    scalingBudgetIsAvailable(val) {
      if (!val) {
        this.scalingBudget = null;
      }
    },
    'entity.source_profiles': {
      deep: true,
      handler(val) {
        if (!val || !val[0] || !val[0].id) {
          return;
        }

        this.entity.settings.adset_targeting = new FacebookTargeting(val[0].targeting);
      },
    }
  },
  created() {
    this.entity.dsa_beneficiary = this.$root.currentCompany.billing_company;
    if (!this.entity.dsa_beneficiary) {
      this.entity.dsa_beneficiary = this.$root.currentCompany.name;
    }
  },
  methods: {
    formatNumber,
    validate() {
      if (!this.goal) {
        this.error(this.tr('Defining the goal is mandatory.'));
        return false;
      }

      if (!this.validateBlocks()) {
        return false;
      }

      if (this.goal === 'conversion' && !isValidURL(this.entity.settings.cta_url)) {
        this.error(this.tr('Invalid call to action URL.'));
        return false
      }

      return true;
    },
    save() {
      return RuleService.createAiMode(this.entity, this.goal, this.scalingBudget);
    },
  },
};
</script>
