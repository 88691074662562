<template>
  <form-block
    class="rule-form-block name-block-component"
    :title="tr('Automation name')"
    :description="description"
  >
    <input
      type="text"
      v-model="entity.name"
      :disabled="disabled"
    />
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'NameBlock',
  mixins: [ RuleFormBlockMixin ],
  props: {
    description: {
      type: String | null,
      required: false,
    },
  },
  methods: {
    validate() {
      if (!this.entity.name) {
        this.error(this.tr('The {field} field cannot be empty!', { '{field}': this.tr('Name') }));
        return false;
      }

      return true;
    },
  },
};
</script>
