import BaseEntity from '@/entities/BaseEntity';

import ImprovedCopy from '@/entities/ImprovedCopy';
import PostAiMeta from '@/entities/PostAiMeta';
import PostMetric from '@/entities/PostMetric';
import SourceProfile from '@/entities/SourceProfile';

import { AI_STATUS } from '@/consts';

export default class Post extends BaseEntity
{
  id = '';
  platform = '';
  external_id = '';
  type = '';
  media_type = '';
  text = '';
  image_url = '';
  details = null;
  source_profile = null;
  metric = null;
  ai_evaluation_possible = false;
  ai_value = null;
  ai_meta_status = null;
  ai_meta = [];
  ai_content_actuality_status = null;
  ai_content_actuality = null;
  improved_copy = null;
  created_at = new Date();
  deleted_at = null;

  comment_moderation = false;
  comment_moderation_after = null;
  comment_count = 0;
  reviewed_comment_count = 0;
  moderated_comment_count = 0;

  _entityFields = {
    improved_copy: ImprovedCopy,
    metric: PostMetric,
    source_profile: SourceProfile,
  };

  _entityArrayFields = {
    ai_meta: PostAiMeta,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  updateWith(newValues) {
    if (!super.updateWith(newValues)) {
      return;
    }

    for (const [key, value] of Object.entries(newValues)) {
      if (!(key in this)) {
        continue;
      }

      if (
        key === 'ai_content_actuality'
        && !!value
      ) {
        this[key] = new Date(value);
      }
    }
  }

  get shortText() {
    if (!this.text) {
      return '';
    }

    const lengthLimit = 120;

    let text = this.text.replace(/(?:\r\n|\r|\n)/g, ' ');

    if (text.length < lengthLimit) {
      return text;
    }

    text = text.slice(0, lengthLimit);
    let lastSpaceIndex = text.lastIndexOf(' ');
    if (lastSpaceIndex === -1) {
      lastSpaceIndex = lengthLimit;
    }

    text = this.text.slice(0, lastSpaceIndex);
    text += '...';

    return text;
  }

  get url() {
    if (this.platform === 'facebook' && this.external_id) {
      return 'https://www.facebook.com/' + this.external_id;
    }
    if (this.platform === 'instagram') {
      if (this.type === 'story' && this.details && this.details.ig_id) {
        return 'https://www.instagram.com/stories/' + this.source_profile.name + '/' + this.details.ig_id;
      }
      else if (this.details && this.details.shortcode) {
        return 'https://www.instagram.com/p/' + this.details.shortcode;
      }
    }

    return null;
  }

  get ai_meta_in_progress() {
    return this.ai_meta_status == AI_STATUS.IN_PROGRESS;
  }

  get ai_meta_error() {
    return this.ai_meta_status == AI_STATUS.ERROR;
  }

  get ai_content_actuality_checked() {
    return this.ai_content_actuality_status == AI_STATUS.DONE;
  }
}
