
import { getObjectValue } from '@/utils/object.js';

export function conditionMatch(conditions, object) {
  const matchers = {
    inOrEquals(currentValue, expectedValue) {
      if (Array.isArray(expectedValue)) {
        if (
          !expectedValue.includes(currentValue)
          && JSON.stringify(expectedValue) !== JSON.stringify(currentValue)
        ) {
          return false;
        }
      }
      else {
        if (currentValue !== expectedValue) {
          return false;
        }
      }

      return true;
    },

    notInOrEquals(currentValue, expectedValue) {
      return !this.inOrEquals(currentValue, expectedValue);
    },
  };

  for (let key in conditions) {
    let value = getObjectValue(object, key);
    let expectedValue = conditions[key];

    let matcherName = 'inOrEquals';
    if (
      typeof expectedValue === 'object'
      && !Array.isArray(expectedValue)
      && expectedValue !== null
      && Object.keys(expectedValue).length === 1
    ) {
      let conditionKey = Object.keys(expectedValue)[0];
      switch (conditionKey) {
        case 'not':
          matcherName = 'notInOrEquals';
          expectedValue = expectedValue[conditionKey];
          break;
      }
    }

    if (!matchers[matcherName](value, expectedValue)) {
      return false;
    }
  }

  return true;
}

/*
kampánybüdzsénél
- "cost per result goal" és "bid control" campaign bid strategy-nál érhető el adset szinten bid control / cost per result goal érték kötelezően
- "mximize VALUE of conversions" adset performance goal érték csak "highest volume or value" és "roas goal" campaign bid strategy-nál érhető el
---- előbbinél goal mező tiltva/nincs
---- utóbbinál kötelező
- "roas goal" campaign bid strategy-nél csak "mximize VALUE of conversions" adset performance goal érhető el

adset büdzsénél
- "maximize VALUE of conversions"-nél elérhető roas goal mező, optional
- "maximize NUMBER of conversions"-nél elérhető cost per result goal mező, optional

adset bid strategy már nem érhető el egyáltalán



TODO:
- bidAmountIsAvailable értéket megvizsgálni, mert az utolsó esetek közül valahol még true a háttérben
- újra futtatni az összes rule-t hogy minden rendben van-e
*/
