<template>
  <div class="connected-platforms-meta-view">
    <sticky-header :title="tr('Meta Business')">
      <template v-slot:buttons>
        <button
          v-if="connectButtonVisible"
          :class="connectProfileButtonClasses"
          @click="() => connect(true)"
          :disabled="isConnecting"
        >
          <i v-if="isConnecting" class="icon loader white" />
          {{ tr('Connect Profile') }}
        </button>
      </template>

      <form-step-tab :items="tabs" />
    </sticky-header>

    <router-view
      @connect="() => connect(true)"
      @reconnect="(profile) => reconnect(profile)"
    />
  </div>
</template>

<script>
import ConnectFacebookProfileMixin from '@/mixins/ConnectFacebookProfile';

import FormStepTab from '@/components/FormStepTab.vue';
import StickyHeader from '@/components/StickyHeader.vue';

export default {
  name: 'ConnectedPlatformsMetaView',
  mixins: [ ConnectFacebookProfileMixin ],
  components: {
    FormStepTab,
    StickyHeader,
  },
  computed: {
    tabs() {
      if (this.$app.isAd) {
        return [
          {
            label: this.tr('Ad Accounts'),
            link: '/connected-platforms/meta/ad-accounts',
          },
          {
            label: this.tr('Facebook Pages'),
            link: '/connected-platforms/meta/facebook-pages',
          },
          {
            label: this.tr('Instagram Accounts'),
            link: '/connected-platforms/meta/instagram-accounts',
          },
          {
            label: this.tr('Profiles'),
            link: '/connected-platforms/meta/profiles',
          },
        ];
      }
      else {
        return [
          {
            label: this.tr('Facebook Pages'),
            link: '/connected-platforms/meta/facebook-pages',
          },
          {
            label: this.tr('Instagram Accounts'),
            link: '/connected-platforms/meta/instagram-accounts',
          },
          {
            label: this.tr('Profiles'),
            link: '/connected-platforms/meta/profiles',
          },
        ]
      }
    },
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
  },
};
</script>
