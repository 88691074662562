var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block campaign-name-block-component",
      attrs: {
        title: _vm.tr("Campaign name"),
        description: _vm.tr(
          "The campaign name is automatically generated from the name of the automation. However, you have the option to set a custom name prefix for the campaign, allowing for easier identification and management of your campaigns."
        ),
      },
    },
    [
      _c("div", [
        _c("label", { staticClass: "input-label" }, [
          _vm._v(_vm._s(_vm.tr("Name prefix"))),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.entity.settings.campaign_name_prefix,
              expression: "entity.settings.campaign_name_prefix",
            },
          ],
          attrs: {
            type: "text",
            placeholder: "[Infinite.ad]",
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.entity.settings.campaign_name_prefix },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(
                _vm.entity.settings,
                "campaign_name_prefix",
                $event.target.value
              )
            },
          },
        }),
        _c("div", { staticClass: "preview" }, [
          _c("span", [
            _c("label", [
              _vm._v(_vm._s(_vm.tr("Campaign name preview")) + ":"),
            ]),
            _vm._v(" " + _vm._s(_vm.entity.campaignName) + " "),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }