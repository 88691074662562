import BaseEntity from '@/entities/BaseEntity';

import PaymentHistoryItem from '@/entities/PaymentHistoryItem';

export default class PaymentHistory extends BaseEntity
{
  id = null;
  status = 0;
  fail_reason = null;
  invoice_number = null;
  invoice_download_url = null;
  currency = 'EUR';
  net = 0;
  gross = 0;
  items = [];
  created_at = new Date();

  _entityArrayFields = {
    items: PaymentHistoryItem,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
