var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.insideValue,
        expression: "insideValue",
      },
    ],
    attrs: {
      type: "text",
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
    },
    domProps: { value: _vm.insideValue },
    on: {
      focus: ($event) => _vm.$emit("focus", $event),
      blur: ($event) => _vm.onBlur($event),
      keydown: ($event) => _vm.onKeyDown($event),
      keyup: ($event) => _vm.$emit("keyup", $event),
      paste: ($event) => _vm.onPaste($event),
      input: function ($event) {
        if ($event.target.composing) return
        _vm.insideValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }