<template>
  <form-block
    v-model="entity.activity_management"
    class="rule-form-block activity-management-block-component"
    :title='tr("Activity management")'
    :description='tr("Keep control of automation activities! Anyone set in the \"Members to be notified about activities\" will receive a notification with an option to approve or reject before the automation executes an activity.")'
    switchable
    :disabled="disabled"
  >
    <div v-if="entity.activity_management">
      <label class="input-label">
        {{ tr('Interpretation of the lack of instruction as') }}
      </label>

      <v-select
        v-model="entity.activity_management_auto_handling"
        :options="autoHandlingOptions"
        :reduce="(obj)=> obj.value"
        :searchable="false"
        :clearable="false"
        :disabled="disabled"
      />
    </div>

    <div v-if="entity.activity_management_auto_handling !== null">
      <label class="input-label">
        {{ tr('Deadline for waiting for instructions before automatic action') }}
      </label>

      <v-select
        v-model="entity.activity_management_auto_handling_after"
        :options="autoHandlingAfterOptions"
        :reduce="(obj)=> obj.value"
        :searchable="false"
        :clearable="false"
        :disabled="disabled"
      />
    </div>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'ActivityManagementBlock',
  mixins: [ RuleFormBlockMixin ],
  computed: {
    hasNotifiableUsers() {
      return this.entity.notifiable_users.length !== 0;
    },
    autoHandlingOptions() {
      return [
        {
          label: 'neutral - no automatic action should be taken',
          value: null,
        },
        {
          label: 'acceptance',
          value: 'accept',
        },
        {
          label: 'rejection',
          value: 'reject',
        },
      ];
    },
    autoHandlingAfterOptions() {
      let options = [
        {
          label: this.tr(':num minutes', { ':num': '15' }),
          value: 15,
        },
        {
          label: this.tr(':num minutes', { ':num': '30' }),
          value: 30,
        },
        {
          label: this.tr(':num hour', { ':num': '1' }),
          value: 60,
        },
      ];

      for (let i = 2; i < 24; ++i) {
        options.push({
          label: this.tr(':num hours', { ':num': i }),
          value: i * 60,
        });
      }

      options.push({
        label: this.tr(':num day', { ':num': '1' }),
        value: 24 * 60,
      });

      for (let i = 2; i <= 5; ++i) {
        options.push({
          label: this.tr(':num days', { ':num': i }),
          value: i * 24 * 60,
        });
      }

      return options;
    },
  },
  watch: {
    hasNotifiableUsers(val) {
      if (!val) {
        this.entity.activity_management = false;
      }
    },
    'entity.activity_management'(val) {
      if (val && !this.hasNotifiableUsers) {
        this.$toast.info(this.tr('The function cannot be used until you set up at least one person to notify.'));
        this.$nextTick(() => this.entity.activity_management = false);
      }
      if (!val) {
        this.entity.activity_management_auto_handling = null;
      }
    },
    'entity.activity_management_auto_handling'(val) {
      if (val !== null) {
        this.entity.activity_management_auto_handling_after = 12 * 60;
      }
      else {
        this.entity.activity_management_auto_handling_after = null;
      }
    }
  },
}
</script>
