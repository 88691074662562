var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-page auth-view" }, [
    _c("div", { staticClass: "header" }, [
      _c("a", { attrs: { href: _vm.mainSiteUrl } }, [
        _c("i", { staticClass: "logo-inline" }),
      ]),
    ]),
    _c("div", { staticClass: "content-wrapper" }, [
      _c(
        "div",
        { staticClass: "input-form-container" },
        [_c("h1", [_vm._v(_vm._s(_vm.pageTitle))]), _c("router-view")],
        1
      ),
    ]),
    _c("div", { staticClass: "placeholder" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }