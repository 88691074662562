<template>
  <div ref="wrapper" class="onboarding-component onboarding--ad" :style="wrapperStyle">
    <div class="content">
      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 0" class="step step--0 step--welcome">
          <i class="icon primary trumpet" />
          <div
            class="title"
            v-html="tr('Get started with Infinite∞Ad')"
          />
          <div class="divider" />
          <div class="onboarding-main-content">
            <div class="textual">
              <p>{{ tr('Welcome to smarter advertising! As an official Meta Tech Provider, we ensure your ads are managed securely and safely. Let\'s set up your first automated campaigns in three simple steps - we\'ll guide you through everything while maintaining the highest security standards.') }}</p>
            </div>
          </div>
          <div class="divider" />
          <div class="bottom-button">
            <button class="primary" @click="() => next()">{{ tr('Let start it!') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 1 && !skipFacebookProfile" class="step step--1 step--profile">
          <i class="icon primary meta-v2" />

          <div class="title">{{ tr('Connect Meta Profile') }}</div>
          <div class="divider" />
          <div class="onboarding-main-content scroll-wrapper">
            <div class="textual">
              <p>
                {{ tr('To begin using our AI-powered automation features, you\'ll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising.') }}
              </p>
            </div>
          </div>
          <div class="divider" />
          <div class="bottom-button">
            <button
              :class="connectProfileButtonClasses"
              :disabled="isConnecting"
              @click="() => connect()"
            >
              <i v-if="isConnecting" class="icon loader white" />
              {{ tr('Connect your Meta profile') }}
            </button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 2" class="step step--2 step--ad-account">
          <i class="icon primary ad-lift" />
          <div class="title">{{ tr('Connect Ad account') }}</div>
          <div class="divider" />
          <div class="onboarding-main-content scroll-wrapper">
            <div class="textual">
              <p>
                {{ tr('In the next step, connect the ad accounts where you want to run your ads.') }}
              </p>
            </div>
            <integrations-meta-ad-accounts-view
              minimal
              @loading="(value) => disableNavigationButtons(value)"
            />
          </div>
          <div class="divider" />
          <div class="bottom-button space-between">
            <button class="bordered gray" :disabled="disabledButtons" @click="() => back()">
              <i class="icon arrow-left" />
              {{ tr('Back') }}
            </button>
            <button class="primary" :disabled="disabledButtons" @click="() => next()">{{ tr('Next') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 3" class="step step--3 step--source-profile">
          <i class="icon primary facebook" />
          <div class="title">{{ tr('Add Facebook Pages') }}</div>
          <div class="divider" />
          <div class="onboarding-main-content scroll-wrapper">
            <div class="textual">
              <p v-html="tr('In the next step, connect the Facebook pages you want to use for advertising.')" />
            </div>

            <integrations-meta-facebook-items-view
              platform-from-prop="facebook"
              minimal
              @loading="(value) => disableNavigationButtons(value)"
            />
          </div>
          <div class="divider" />
          <div class="bottom-button space-between">
            <button class="bordered gray" :disabled="disabledButtons" @click="() => back()">
              <i class="icon arrow-left" />
              {{ tr('Back') }}
            </button>
            <button class="primary" :disabled="disabledButtons" @click="() => next()">{{ tr('Next') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 4" class="step step--4 step--source-profile">
          <i class="icon primary instagram" />
          <div class="title">{{ tr('Add Instagram profiles') }}</div>
          <div class="divider" />
          <div class="onboarding-main-content scroll-wrapper">
            <div class="textual">
              <p v-html="tr('In the next step, connect the Instagram profiles you want to use for advertising.')" />
            </div>

            <integrations-meta-facebook-items-view
              platform-from-prop="instagram"
              minimal
              @loading="(value) => disableNavigationButtons(value)"
            />
          </div>
          <div class="divider" />
          <div class="bottom-button space-between">
            <button class="bordered gray" :disabled="disabledButtons" @click="() => back()">
              <i class="icon arrow-left" />
              {{ tr('Back') }}
            </button>
            <button class="primary" :disabled="disabledButtons" @click="() => next()">{{ tr('Finish') }}</button>
          </div>
        </div>
      </transition>

      <transition name="slide-right-to-left" mode="out-in">
        <div v-show="step === 5" class="step step--5 step--done">
          <i class="icon primary trumpet" />
          <div class="title">{{ tr('Congratulations, you\'re all set!') }}</div>
          <div class="divider" />
          <div class="onboarding-main-content scroll-wrapper">
            <div class="textual">
              <p v-html="tr('You\'ve successfully connected all your accounts. Now it\'s time to create your first automation and let our AI optimize your advertising campaigns.')"></p>
            </div>
            <create-new-rule-cards :showCards="[ 0 ]" @click="() => $emit('close')" />
            <router-link to="/automation/new/start" class="primary-link" @click.native="() => $emit('close')">
              {{ tr('Create Ad Creation automation in manual mode') }}
            </router-link>
          </div>
          <div class="divider" />
          <div class="bottom-button">
            <button class="primary" :disabled="disabledButtons" @click="() => $emit('close')">{{ tr('Close') }}</button>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-show="step >= 0 && step <= 4" class="dots">
          <div :class="{ 'dot': true, 'active': step === 0 }" />
          <div v-if="!skipFacebookProfile" :class="{ 'dot': true, 'active': step === 1 }" />
          <div :class="{ 'dot': true, 'active': step === 2 }" />
          <div :class="{ 'dot': true, 'active': step === 3 }" />
          <div :class="{ 'dot': true, 'active': step === 4 }" />
        </div>
      </transition>
    </div>
    <transition name="fade" mode="out-in">
      <img class="meta-badge" src="~@/assets/img/onboarding/meta-badge.png" />
    </transition>
  </div>
</template>

<script>
import CreateNewRuleCards from '@/components/CreateNewRuleCards.vue';
import HasNoAssetsModalContent from '@/components/HasNoAssetsModalContent.vue';
import ConnectFacebookProfileMixin from '@/mixins/ConnectFacebookProfile';
import IntegrationsMetaAdAccountsView from '@/views/IntegrationsMetaAdAccountsView.vue';
import IntegrationsMetaFacebookItemsView from '@/views/IntegrationsMetaFacebookItemsView.vue';

export default {
  name: 'OnboardingAd',
  components: {
    CreateNewRuleCards,
    IntegrationsMetaAdAccountsView,
    IntegrationsMetaFacebookItemsView,
  },
  mixins: [ ConnectFacebookProfileMixin ],
  data: () => ({
    step: 0,
    forceHeight: null,
    disableHeightTransition: false,
    disabledButtons: false,
  }),
  computed: {
    steps() {
      return [
        this.tr('Connect Facebook Profile'),
        this.tr('Connect Ad account'),
        this.tr('Connect Datasource'),
      ];
    },
    wrapperStyle() {
      const list = {
        'min-height': (this.forceHeight ? this.forceHeight : 0) + 'px',
      };

      if (this.disableHeightTransition) {
        list['transition'] = 'none';
      }

      return list;
    },
    skipFacebookProfile() {
      return this.$store.getters['dashboard/skipFacebookProfile'];
    },
  },
  watch: {
    step() {
      this.$store.dispatch('dashboard/fetch', this.$root.currentCompany.id).catch(err => this.error(err));
    },
  },
  mounted() {
    this.updateMinHeight();
  },
  methods: {
    onFacebookProfileConnected() {
      if (this.$root.loggedInUser.connected_profiles[0].details.ad_accounts.length === 0) {
        this.$emit('close');
        this.$fullscreenModal.open(
          this,
          HasNoAssetsModalContent,
          {
            text: 'has-no-assets-modal',
            center: true,
            classes: 'has-no-assets-modal',
          },
        );
        return;
      }
      if (
        this.$root.loggedInUser.connected_profiles[0].details.pages.length === 0
        && this.$root.loggedInUser.connected_profiles[0].details.instagram_accounts.length === 0
      ) {
        this.$emit('close');
        this.$fullscreenModal.open(
          this,
          HasNoAssetsModalContent,
          {
            text: 'has-no-assets-modal',
            center: true,
            classes: 'has-no-assets-modal',
          },
        );
        return;
      }
      this.next();
    },
    updateMinHeight() {
      this.forceHeight = this.$refs['wrapper'].offsetHeight;
    },
    beforeChangeStep() {
      this.disableHeightTransition = true;
      this.updateMinHeight();
      setTimeout(() => {
        this.disableHeightTransition = false;
        this.$nextTick(() => this.forceHeight = null);
      }, 400);
    },
    next() {
      this.$toast.clear();

      if (this.skipFacebookProfile && this.step === 0) {
        this.step = 2;
        this.beforeChangeStep();
        return;
      }

      if (this.step === 2) {
        if (this.$root.currentCompany.ad_accounts.length === 0) {
          this.$toast.error(this.tr('At least one advertising account connection is required.'));
          return;
        }
      }
      if (this.step === 4) {
        if (this.$root.currentCompany.source_profiles.length === 0) {
          this.$toast.error(this.tr('At least one platform (Facebook Page or Instagram account) connection is required.'));
          return;
        }
      }

      this.beforeChangeStep();
      this.$nextTick(() => ++this.step);
    },
    back() {
      this.beforeChangeStep();
      if (this.skipFacebookProfile && this.step === 2) {
        this.step = 0;
        return;
      }
      this.$nextTick(() => --this.step);
    },
    close() {
      if (this.step === this.steps.length + 2) {
        this.$emit('close');
        return;
      }

      if (this.disabledButtons || this.isConnecting) {
        this.infoToast(this.tr('Please wait until the process is completed.'));
        return;
      }

      this.$confirmation.open({
        className: 'onboarding-close-confirm',
        imageClass: 'onboarding-close-image',
        title: this.tr('Are you sure you want to close the setup wizard?'),
        content: this.tr('You can reopen the installation wizard anytime from the Dashboard menu if you need assistance with setting up the system.'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Close Wizard'),
        confirm: () => this.$emit('close'),
      });
    },
    disableNavigationButtons(value) {
      this.disabledButtons = value;
    },
  },
};
</script>
