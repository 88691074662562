var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-list-view" },
    [
      _c("sticky-header", {
        attrs: {
          title: _vm.tr("Automations"),
          info: _vm.tr(
            "Unlock the Full Power of Your Social Media with Advanced Automations. 🚀 Seamlessly manage and optimize your Facebook and Instagram ads with automations for creation, scaling and stopping. 💪"
          ),
          bordered: "",
        },
      }),
      _c("system-level-notifications", { attrs: { "space-top": "" } }),
      !_vm.hasConnectedProfileInCompany
        ? _c("empty-screen", {
            attrs: {
              icon: "play",
              title: _vm.tr("Connect your accounts to start automating"),
              "flag-text": _vm.tr("Connect profiles"),
              description: _vm.tr(
                "To begin using our AI-powered automation features, you'll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising."
              ),
            },
            on: { click: () => _vm.openOnboardingModal() },
          })
        : _vm._e(),
      _vm.showNewRuleCards &&
      _vm.isListWithoutFiltersEmpty &&
      !_vm.listIsLoading
        ? _c("empty-screen", {
            attrs: {
              icon: "rocket",
              title: _vm.tr(
                "Add your first advertising automation and start growing"
              ),
              "flag-text": _vm.tr("Start creating automations"),
              description: _vm.tr(
                "Create AI-powered ad automation rules that will manage your post's promotion. Set up your first automation rule, define your goals and budget, and let our AI optimize your advertising campaigns automatically."
              ),
            },
          })
        : _vm._e(),
      _c(
        "transition-expand",
        [_vm.showNewRuleCards ? _c("create-new-rule-cards") : _vm._e()],
        1
      ),
      _vm.showNewRuleCards
        ? _c(
            "button",
            {
              staticClass: "primary create-new-automation-button",
              on: { click: _vm.openCreateNewRuleModal },
            },
            [_vm._v(" + " + _vm._s(_vm.tr("Create new automation")) + " ")]
          )
        : _vm._e(),
      _vm.showFilters
        ? _c(
            "div",
            { staticClass: "filters" },
            [
              _c("rule-action-filter", {
                attrs: { disabled: _vm.listIsLoading },
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                staticClass: "term-filter icon--search",
                attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
              _c("v-select", {
                staticClass: "ad-account-select",
                attrs: {
                  options: _vm.adAccountOptions,
                  reduce: (item) => item.id,
                  label: "name",
                },
                model: {
                  value: _vm.adAccountId,
                  callback: function ($$v) {
                    _vm.adAccountId = $$v
                  },
                  expression: "adAccountId",
                },
              }),
              _c("v-select", {
                staticClass: "status-select",
                attrs: {
                  options: _vm.statusOptions,
                  reduce: (item) => item.value,
                  searchable: false,
                },
                model: {
                  value: _vm.statusFilter,
                  callback: function ($$v) {
                    _vm.statusFilter = $$v
                  },
                  expression: "statusFilter",
                },
              }),
              _c(
                "dropdown-menu",
                {
                  attrs: { transition: "slide-top-to-bottom", interactive: "" },
                  model: {
                    value: _vm.filtersIsOpen,
                    callback: function ($$v) {
                      _vm.filtersIsOpen = $$v
                    },
                    expression: "filtersIsOpen",
                  },
                },
                [
                  _c("button", { staticClass: "filter-button white" }, [
                    _c("i", { staticClass: "icon adjustments light-black" }),
                    _vm._v(" " + _vm._s(_vm.tr("Filters")) + " "),
                  ]),
                  _c("div", { attrs: { slot: "dropdown" }, slot: "dropdown" }, [
                    _c(
                      "div",
                      { staticClass: "filter-input-block" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.tr("Status")))]),
                        _c("v-select", {
                          staticClass: "status-select",
                          attrs: {
                            options: _vm.statusOptions,
                            reduce: (item) => item.value,
                            searchable: false,
                          },
                          model: {
                            value: _vm.statusFilter,
                            callback: function ($$v) {
                              _vm.statusFilter = $$v
                            },
                            expression: "statusFilter",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "filter-input-block" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.tr("Ad Account")))]),
                        _c("v-select", {
                          staticClass: "ad-account-select",
                          attrs: {
                            options: _vm.adAccountOptions,
                            reduce: (item) => item.id,
                            label: "name",
                          },
                          model: {
                            value: _vm.adAccountId,
                            callback: function ($$v) {
                              _vm.adAccountId = $$v
                            },
                            expression: "adAccountId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.listIsLoading && _vm.isListEmpty
        ? _c(
            "div",
            [
              !_vm.isEmptyFilters
                ? _c("empty-screen", {
                    attrs: {
                      icon: "sad",
                      title: _vm.tr(
                        "No automations match your current filters"
                      ),
                      "flag-text": _vm.tr("Adjust filters"),
                      description: _vm.tr(
                        "We couldn't find any automations that match your selected filters. Try adjusting your filter settings to see more results in your automation list."
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("sorted-table", {
            attrs: {
              data: _vm.list,
              "initial-sort": { key: _vm.orderBy, direction: _vm.order },
            },
            on: { sort: _vm.sortTable },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("table-head", {
                      attrs: { col: "status", width: "50px" },
                    }),
                    _c("table-head", {
                      attrs: { col: "automation-type", width: "35px" },
                    }),
                    _c("table-head", { attrs: { col: "name", sortable: "" } }, [
                      _vm._v(_vm._s(_vm.tr("Name"))),
                    ]),
                    _c(
                      "table-head",
                      { attrs: { col: "last-run", width: "130px" } },
                      [_vm._v(_vm._s(_vm.tr("Last Run")))]
                    ),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "created_at",
                          width: "150px",
                          sortable: "",
                        },
                      },
                      [_vm._v(_vm._s(_vm.tr("Date created")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "center",
                        attrs: { col: "triggered", width: "90px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Task Done")))]
                    ),
                    _c("table-head", {
                      staticClass: "center",
                      attrs: { col: "actions", width: "30px" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return [
                    _vm._l(sort, function (row) {
                      return _c(
                        "table-row",
                        {
                          key: row.id,
                          class: ["card--" + row.action, _vm.rowClasses(row)],
                          attrs: {
                            "header-color": "rule-header--" + row.action,
                          },
                        },
                        [
                          _c(
                            "table-data",
                            {
                              staticClass: "status-container",
                              attrs: { col: "status" },
                            },
                            [
                              _c("switches", {
                                attrs: {
                                  disabled:
                                    _vm.$root.loggedInUser.isRestrictedViewer ||
                                    _vm.$root.isSupportMode ||
                                    _vm.deletedCampaignFlagIsVisible(row),
                                  "emit-on-mount": false,
                                },
                                on: {
                                  input: () => _vm.onChangeRuleStatus(row),
                                },
                                model: {
                                  value: row.active,
                                  callback: function ($$v) {
                                    _vm.$set(row, "active", $$v)
                                  },
                                  expression: "row.active",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "automation-type-container",
                              attrs: { col: "automation-type" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "automation-type-flag" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "automation-type-flag--icon",
                                    },
                                    [
                                      _c("i", {
                                        class:
                                          "icon white " +
                                          _vm.automationTypeIcon(row.action),
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "automation-type-flag--label",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.automationTypeLabel(row.action)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  row.group_id !== null
                                    ? _c("i", {
                                        staticClass: "icon magic-stars",
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "name-container",
                              attrs: { col: "name" },
                            },
                            [
                              _c("label", [_vm._v(_vm._s(_vm.tr("Name")))]),
                              _c(
                                "access-handled-router-link",
                                {
                                  attrs: {
                                    to: "/automation/" + row.id + "/overview",
                                  },
                                },
                                [_vm._v(" " + _vm._s(row.name) + " ")]
                              ),
                              row.ad_account &&
                              _vm.facebookAdAccountName(row.ad_account.id)
                                ? _c("div", { staticClass: "flag" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.facebookAdAccountName(
                                            row.ad_account.id
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.disconnectedAdAccountFlagIsVisible(row)
                                ? _c("div", { staticClass: "flag red" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Automation Stopped")) +
                                        " - " +
                                        _vm._s(
                                          _vm.tr("Disconnected Ad Account")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.deletedCampaignFlagIsVisible(row)
                                ? _c("div", { staticClass: "flag red" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Automation Stopped")) +
                                        " - " +
                                        _vm._s(
                                          _vm.tr(
                                            "Campaign Deleted on Ads Manager"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm.inactiveCampaignFlagIsVisible(row)
                                ? _c("div", { staticClass: "flag orange" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.tr("Inactive Campaign")) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "last-run-content",
                              attrs: { col: "last-run" },
                            },
                            [
                              _c("div", { staticClass: "divider" }),
                              _c("label", [_vm._v(_vm._s(_vm.tr("Last Run")))]),
                              _c(
                                "div",
                                [
                                  row.active
                                    ? _c(
                                        "rule-next-run-tooltip",
                                        {
                                          staticClass: "icon-wrapper",
                                          attrs: { entity: row },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon clock-filled light-black",
                                          }),
                                        ]
                                      )
                                    : _c("div", { staticClass: "no-clock" }),
                                  _c(
                                    "div",
                                    [
                                      row.last_run
                                        ? [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      content:
                                                        _vm.formatToYMD(
                                                          row.last_run
                                                        ) +
                                                        ". " +
                                                        _vm.formatTime(
                                                          row.last_run
                                                        ),
                                                      classes: [""],
                                                    },
                                                    expression:
                                                      "{ content: formatToYMD(row.last_run) + '. ' + formatTime(row.last_run), classes: [ '' ] }",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.timeAgo(
                                                        row.last_run,
                                                        _vm.tr
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : [_vm._v(" - ")],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "date-created-content",
                              attrs: { col: "created_at" },
                            },
                            [
                              _c("div", { staticClass: "divider" }),
                              _c("label", [
                                _vm._v(_vm._s(_vm.tr("Date created"))),
                              ]),
                              _c(
                                "div",
                                [
                                  row.created_at
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content:
                                                    _vm.formatToYMD(
                                                      row.created_at
                                                    ) +
                                                    ". " +
                                                    _vm.formatTime(
                                                      row.created_at
                                                    ),
                                                  classes: [""],
                                                },
                                                expression:
                                                  "{ content: formatToYMD(row.created_at) + '. ' + formatTime(row.created_at), classes: [ '' ] }",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.timeAgo(
                                                    row.created_at,
                                                    _vm.tr
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [_vm._v(" - ")],
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "center triggered",
                              attrs: { col: "triggered" },
                            },
                            [
                              _c("div", { staticClass: "divider" }),
                              _c("label", [
                                _vm._v(_vm._s(_vm.tr("Task done"))),
                              ]),
                              _vm._v(" " + _vm._s(row.triggered) + " "),
                            ]
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "center actions-container",
                              attrs: { col: "actions" },
                            },
                            [
                              _c("navigation-arrow", {
                                attrs: {
                                  link: "/automation/" + row.id + "/overview",
                                  color: row.action,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm.listIsLoading || _vm.hasMore
                      ? _vm._l(
                          _vm.isListEmpty
                            ? ["start", "scaling_up", "stop"]
                            : ["start", "scaling_up"],
                          function (action) {
                            return _c(
                              "table-row",
                              {
                                key: "automations-loader-skeleton--" + action,
                                ref: "loadMore",
                                refInFor: true,
                                attrs: { "header-color": "white" },
                              },
                              [
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "status-container",
                                    attrs: { col: "status" },
                                  },
                                  [
                                    _c("switches", {
                                      staticStyle: { opacity: "0.5" },
                                      attrs: { value: "1", disabled: "" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "automation-type-container",
                                    attrs: { col: "automation-type" },
                                  },
                                  [
                                    _c("skeleton", {
                                      staticClass: "automation-type-flag",
                                      attrs: {
                                        height: "20px",
                                        "min-width": "20px",
                                        "max-width": "20px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass:
                                      "name-container name-container--skeleton",
                                    attrs: { col: "name" },
                                  },
                                  [
                                    _c("skeleton", {
                                      staticClass: "name-skeleton",
                                      attrs: {
                                        height: "17px",
                                        "min-width": "200px",
                                        "max-width": "400px",
                                      },
                                    }),
                                    _c("skeleton", {
                                      staticClass: "flag",
                                      attrs: {
                                        width: "70px",
                                        height: "16px",
                                        radius: "5px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "last-run-content",
                                    attrs: { col: "last-run" },
                                  },
                                  [
                                    _c("div", { staticClass: "divider" }),
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.tr("Last Run"))),
                                    ]),
                                    _c("skeleton", {
                                      attrs: {
                                        width: "60%",
                                        "max-width": "100px",
                                        height: "18px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "date-created-content",
                                    attrs: { col: "created_at" },
                                  },
                                  [
                                    _c("div", { staticClass: "divider" }),
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.tr("Date created"))),
                                    ]),
                                    _c("skeleton", {
                                      attrs: {
                                        width: "60%",
                                        "max-width": "100px",
                                        height: "18px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "table-data",
                                  {
                                    staticClass: "center triggered",
                                    attrs: { col: "triggered" },
                                  },
                                  [
                                    _c("div", { staticClass: "divider" }),
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.tr("Task done"))),
                                    ]),
                                    _c("skeleton", {
                                      attrs: { width: "26px", height: "18px" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
      !_vm.$root.loggedInUser.isRestrictedViewer && !_vm.$root.isSupportMode
        ? [
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _c("floating-create-new-rule-modal", {
                  ref: "FloatingCreateNewRuleModal",
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }