<template>
  <div class="rule-list-view">
    <sticky-header
      :title="tr('Automations')"
      :info="tr('Unlock the Full Power of Your Social Media with Advanced Automations. 🚀 Seamlessly manage and optimize your Facebook and Instagram ads with automations for creation, scaling and stopping. 💪')"
      bordered
    />

    <system-level-notifications space-top />

    <transition-expand>
      <create-new-rule-cards
        v-if="showNewRuleCards"
        ref="createNewRuleCards"
      />
    </transition-expand>

    <div v-if="!isListEmpty || !isEmptyFilters || isLoading" class="filters">
      <input
        v-model="searchTerm"
        type="text"
        class="term-filter icon--search new-input-theming"
        :placeholder="tr('Search') + '...'"
      />
      <v-select
        v-model="actionFilter"
        :options="actionOptions"
        :reduce="(item) => item.machine_name"
        :searchable="false"
        class="action-select new-input-theming"
      />
      <v-select
        v-model="adAccountId"
        :options="adAccountOptions"
        :reduce="(item) => item.id"
        label="name"
        class="ad-account-select new-input-theming"
      />
      <v-select
        v-model="statusFilter"
        :options="statusOptions"
        :reduce="(item) => item.value"
        :searchable="false"
        class="status-select new-input-theming"
      />

      <dropdown-menu v-model="filtersIsOpen" transition="slide-top-to-bottom" interactive>
        <button class="filter-button white">
          <i class="icon adjustments light-black" />
          {{ tr('Filters') }}
        </button>

        <div slot="dropdown">
          <div class="filter-input-block">
            <label>{{ tr('Automation type') }}</label>
            <v-select
              v-model="actionFilter"
              :options="actionOptions"
              :reduce="(item) => item.machine_name"
              :searchable="false"
              class="action-select new-input-theming"
            />
          </div>

          <div class="filter-input-block">
            <label>{{ tr('Status') }}</label>
            <v-select
              v-model="statusFilter"
              :options="statusOptions"
              :reduce="(item) => item.value"
              :searchable="false"
              class="status-select new-input-theming"
            />
          </div>

          <div class="filter-input-block">
            <label>{{ tr('Ad Account') }}</label>
            <v-select
              v-model="adAccountId"
              :options="adAccountOptions"
              :reduce="(item) => item.id"
              label="name"
              class="ad-account-select new-input-theming"
            />
          </div>
        </div>
      </dropdown-menu>
    </div>

    <div v-if="!isLoading && isListEmpty">
      <notification-card
        v-if="!isEmptyFilters"
        :image="require('@/assets/img/sad_new.png')"
        class="no-result"
        empty-screen
      >
        {{ tr('There are no results matching your search criteria.') }}
      </notification-card>
      <notification-card
        v-else
        :image="require('@/assets/img/new-rule-card/ai.png')"
        empty-screen
      >
        {{ tr('Manage your advertising with automation on Facebook or Instagram.') }}

        <router-link v-if="!$root.loggedInUser.isRestrictedViewer" class="button primary" to="/automation/new/ai-mode">
          + {{ tr('Create your first Automation') }}
        </router-link>
      </notification-card>
    </div>

    <new-sorted-table
      v-else
      :data="list"
      :initial-sort="{ key: orderBy, direction: order }"
      @sort="sortTable"
    >
      <template #header>
        <table-head col="status" width="50px" />
        <table-head col="name" min-width="250px" sortable>{{ tr('Name') }}</table-head>
        <table-head col="last-run" width="150px">{{ tr('Last Run') }}</table-head>
        <table-head col="triggered" width="82px" class="center">{{ tr('Task Done') }}</table-head>
      </template>

      <template #default="{ sort }">
        <table-row
          v-for="row in sort"
          :key="row.id"
          :header-color="'rule-header--' + row.action"
        >
          <template #header>
            <i :class="'icon white ' + automationTypeIcon(row.action)" />
            {{ automationTypeLabel(row.action) }}
          </template>

          <template #header-decorator v-if="row.group_id !== null">
            <i class="icon magic-stars white" />
            {{ tr('Created in Ai mode') }}
          </template>

          <table-data col="status">
            <switches
              v-model="row.active"
              :disabled="$root.loggedInUser.isRestrictedViewer || $root.isSupportMode || deletedCampaignFlagIsVisible(row)"
              :emit-on-mount="false"
              @input="() => onChangeRuleStatus(row)"
            />
          </table-data>

          <table-data col="name" class="name-container">
            <access-handled-router-link :to="'/automation/' + row.id + '/overview'">
              {{ row.name }}
            </access-handled-router-link>
            <div v-if="row.ad_account && facebookAdAccountName(row.ad_account.id)" class="flag">{{ facebookAdAccountName(row.ad_account.id) }}</div>

            <div
              v-if="disconnectedAdAccountFlagIsVisible(row)"
              class="flag red"
            >
              {{ tr('Automation Stopped') }} - {{ tr('Disconnected Ad Account') }}
            </div>
            <div
              v-else-if="deletedCampaignFlagIsVisible(row)"
              class="flag red"
            >
              {{ tr('Automation Stopped') }} - {{ tr('Campaign Deleted on Ads Manager') }}
            </div>
            <div
              v-else-if="inactiveCampaignFlagIsVisible(row)"
              class="flag orange"
            >
              {{ tr('Inactive Campaign') }}
            </div>
          </table-data>

          <table-data col="last-run" class="last-run-content">
            <rule-next-run-tooltip v-if="row.active" :entity="row" class="icon-wrapper">
              <i class="icon clock-filled light-black" />
            </rule-next-run-tooltip>
            <div v-else class="no-clock"></div>
            <div>
              <template v-if="row.last_run">
                <span v-tooltip="{ content: formatToYMD(row.last_run) + '. ' + formatTime(row.last_run), classes: [ '' ] }">
                  {{ timeAgo(row.last_run, tr) }}
                </span>
              </template>
              <template v-else>
                -
              </template>
            </div>
          </table-data>

          <table-data col="triggered" class="center triggered">
            {{ row.triggered }}
          </table-data>
        </table-row>

        <template v-if="isLoading || hasMore">
          <table-row
            v-for="action in (isListEmpty ? [ 'start', 'scaling_up', 'stop' ] : [ 'start', 'scaling_up' ])"
            :key="'automations-loader-skeleton--' + action"
            :header-color="'rule-header--' + action"
            ref="loadMore"
          >
            <template #header>
              <i class="icon white loader" />
              {{ tr('Loading...') }}
            </template>

            <table-data col="status">
              <switches value="1" disabled style="opacity: 0.5" />
            </table-data>

            <table-data col="name" class="name-container">
              <skeleton class="name-skeleton" height="15px" min-width="250px" max-width="400px" />
              <skeleton class="flag" width="70px" height="13px" radius="5px" />
            </table-data>
            <table-data col="last-run">
              <skeleton width="60%" height="18px" />
            </table-data>
            <table-data col="triggered" class="center">
              <skeleton width="26px" height="18px" />
            </table-data>
          </table-row>
        </template>
      </template>
    </new-sorted-table>

    <template v-if="!$root.loggedInUser.isRestrictedViewer && !$root.isSupportMode">
      <transition name="fade">
        <floating-create-new-rule-button v-show="floatingAddButtonIsVisible" />
      </transition>
    </template>
  </div>
</template>

<script>
import RuleSwitchMixin from '@/mixins/RuleSwitch.js';

import CreateNewRuleCards from '@/components/CreateNewRuleCards.vue';
import DropdownMenu from '@innologica/vue-dropdown-menu';
import FloatingCreateNewRuleButton from '@/components/FloatingCreateNewRuleButton.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import RuleNextRunTooltip from '@/components/RuleNextRunTooltip.vue';
import Skeleton from '@/components/Skeleton.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import Switches from 'vue-switches';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';
import TopImageContent from '@/components/TopImageContent.vue';
import TransitionExpand from '@/components/TransitionExpand.vue';

import debounce from '@/utils/debounce.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';

export default {
  name: 'RuleListView',
  mixins: [ RuleSwitchMixin ],
  components: {
    CreateNewRuleCards,
    DropdownMenu,
    FloatingCreateNewRuleButton,
    NotificationCard,
    RuleNextRunTooltip,
    Skeleton,
    StickyHeader,
    Switches,
    SystemLevelNotifications,
    TopImageContent,
    TransitionExpand,
  },
  data() {
    return {
      disableWatchers: false,
      debouncedLoadData: debounce(() => this.loadData(), 500),

      filtersIsOpen: false,
      floatingAddButtonIsVisible: false,
      createNewRuleCardVisibilityObserver: null,
    };
  },
  computed: {
    isListEmpty() {
      return this.list.length == 0;
    },
    showNewRuleCards() {
      return !this.$root.loggedInUser.isRestrictedViewer && !this.$root.isSupportMode && this.hasAnyRules;
    },
    adAccountList() {
      return this.$root.currentCompany.ad_accounts;
    },
    isEmptyFilters() {
      return this.searchTerm.trim().length === 0
        && this.adAccountId === null
        && this.actionFilter === null
        && this.statusFilter === null;
    },
    actionOptions() {
      return [
        {
          machine_name: null,
          label: this.tr('All types of automation'),
        },
        {
          machine_name: 'start',
          label: this.tr('automation_type_start'),
        },
        {
          machine_name: 'scaling_up',
          label: this.tr('automation_type_scaling_up'),
        },
        {
          machine_name: 'stop',
          label: this.tr('automation_type_stop'),
        },
      ];
    },
    adAccountOptions() {
      const options = [{ id: null, name: this.tr('All ad accounts') }];
      options.push(...this.adAccountList);
      return options;
    },
    statusOptions() {
      return [
        {
          value: null,
          label: this.tr('All statuses'),
        },
        {
          value: 1,
          label: this.tr('Active'),
        },
        {
          value: 0,
          label: this.tr('Inactive'),
        },
      ];
    },
    isLoading() {
      return this.$store.getters['rules/isLoading'];
    },
    hasMore() {
      return this.$store.getters['rules/hasMore'];
    },
    list() {
      return this.$store.getters['rules/list'];
    },
    hasAnyRules() {
      return this.$store.getters['rules/hasAnyRules'];
    },
    searchTerm: {
      get() {
        return this.$store.getters['rules/searchTerm'];
      },
      set(value) {
        this.$store.commit('rules/setSearchTerm', value);
      },
    },
    actionFilter: {
      get() {
        return this.$store.getters['rules/actionFilter'];
      },
      set(value) {
        this.$store.commit('rules/setActionFilter', value);
      },
    },
    adAccountId: {
      get() {
        return this.$store.getters['rules/adAccountId'];
      },
      set(value) {
        this.$store.commit('rules/setAdAccountId', value);
      },
    },
    statusFilter: {
      get() {
        return this.$store.getters['rules/statusFilter'];
      },
      set(value) {
        this.$store.commit('rules/setStatusFilter', value);
      },
    },
    order: {
      get() {
        return this.$store.getters['rules/order'];
      },
      set(value) {
        this.$store.commit('rules/setOrder', value);
      },
    },
    orderBy: {
      get() {
        return this.$store.getters['rules/orderBy'];
      },
      set(value) {
        this.$store.commit('rules/setOrderBy', value);
      },
    },
  },
  watch: {
    searchTerm(val) {
      if (this.disableWatchers) {
        return;
      }

      if (!val || val.trim().length === 0) {
        this.loadData();
      }
      else {
        this.debouncedLoadData();
      }
    },
    actionFilter() {
      if (this.disableWatchers) {
        return;
      }

      this.loadData();
    },
    adAccountId() {
      if (this.disableWatchers) {
        return;
      }

      this.loadData();
    },
    statusFilter() {
      if (this.disableWatchers) {
        return;
      }

      this.loadData();
    },
    showNewRuleCards: {
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }

        this.$nextTick(() => {
          this.createNewRuleCardVisibilityObserver = new IntersectionObserver(entry => {
            this.floatingAddButtonIsVisible = !entry[0].isIntersecting;
          }, { rootMargin: '-100px' });

          this.createNewRuleCardVisibilityObserver.observe(this.$refs.createNewRuleCards.$el);
        });
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.temporaryDisableWatchers();

      if (!from || !from.name || vm.isListEmpty) {
        vm.loadNewData();
      }
      else if (from.path.split('/')[1] !== to.path.split('/')[1]) {
        vm.$store.dispatch('rules/resetView');
        vm.loadNewData();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.temporaryDisableWatchers();

    next();

    this.$store.dispatch('rules/resetView');
    this.loadData();
  },
  beforeDestroy() {
    if (this.createNewRuleCardVisibilityObserver) {
      this.createNewRuleCardVisibilityObserver.disconnect();
    }
  },
  methods: {
    formatToYMD,
    formatTime,
    timeAgo,
    automationTypeIcon(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return 'blister';

        case 'start':
          return 'rocket';

        case 'scaling_up':
          return 'scaling-up';
      }

      return null;
    },
    automationTypeLabel(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return this.tr('automation_type_stop');

        case 'start':
          return this.tr('automation_type_start');

        case 'scaling_up':
          return this.tr('automation_type_scaling_up');
      }
    },
    temporaryDisableWatchers() {
      this.disableWatchers = true;
      setTimeout(() => this.disableWatchers = false, 100);
    },
    facebookAdAccount(id) {
      return this.$root.currentCompany.ad_accounts.find(
        (item) => item.id === id
      );
    },
    facebookAdAccountName(id) {
      let adAccount = this.facebookAdAccount(id);
      return adAccount ? adAccount.name : null;
    },
    loadData() {
      this.$store.dispatch('rules/resetView', true);
      this.loadNewData();
    },
    loadNewData() {
      if (this.isLoading) {
        return;
      }

      const loadMore = this.$store.dispatch('rules/loadMoreData');
      if (loadMore) {
        loadMore
          .finally(() => {
            if (this.hasMore) {
              this.$nextTick(() => this.initInfiniteScroll());
            }
          })
          .catch(err => this.error(err));
      }
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0].$el);
      }
    },
    sortTable({ key, direction }) {
      this.$store.commit('rules/setOrderBy', key);
      this.$store.commit('rules/setOrder', direction);
      this.loadData();
    },
    deletedCampaignFlagIsVisible(rule) {
      return rule.campaign_status === 'deleted';
    },
    disconnectedAdAccountFlagIsVisible(rule) {
      let adAccount = this.facebookAdAccount(rule.ad_account.id);
      return !adAccount;
    },
    inactiveCampaignFlagIsVisible(rule) {
      return rule.campaign_status === 'inactive';
    },
    activityManagementTooltipContent(rule) {
      let content = this.tr('Pending Ads');
      if (rule.pending_items_count_in_activity_management) {
        content += '<br><small>(';
        if (rule.pending_items_count_in_activity_management === 1) {
          content += this.tr(':num pending item', { ':num': rule.pending_items_count_in_activity_management });
        }
        else {
          content += this.tr(':num pending items', { ':num': rule.pending_items_count_in_activity_management });
        }
        content += ')</small>';
      }

      return content;
    },
  },
};
</script>
