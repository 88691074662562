<template>
  <div class="rule-ai-risk-review-view">
    <template v-if="rule && !isLoading && isListEmpty">
      <notification-card
        v-if="rule && rule.settings.ai_review"
        :image="require('@/assets/img/thumbsup.png')"
        :title="tr('Super!')"
        empty-screen
      >
          {{ tr('The Infinite∞Ai has not yet found any content problematic for advertising.') }}
          <br/>
          {{ tr('The Infinite∞Ai will continue to monitor the content and will notify you.') }}
      </notification-card>
      <notification-card
        v-else
        :image="require('@/assets/img/sad_new.png')"
        empty-screen
      >
        {{ tr('This automation does not have "Checking for compliance with Meta Advertising Standards" enabled.') }}
      </notification-card>
    </template>

    <div v-else class="items">
      <card v-for="(item, key) in items" :key="'ai-risk-review-item--' + key + '--' + item.post.id" :class="{ 'is-not-actual': !item.is_current }">
        <div class="datetime">
          <span v-tooltip="formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)">
            {{ timeAgo(item.created_at, tr) }}
          </span>
          <span v-if="!item.is_current" class="is-not-actual">
            - {{ tr('The result is no longer relevant because the automation or the entry has been modified since the review.') }}
          </span>
        </div>

        <div class="ai-action" v-html="buildAction(item)" />

        <div v-html="item.post.shortText" class="post" />

        <div class="reason-title">{{ tr('Reasoning of Infinite∞Ai') }}:</div>
        <div v-if="item.reason" v-html="item.reason" class="reason quote" />

        <div class="buttons">
          <a
            v-if="rule.action === 'start'"
            :href="'/post-copy-analysis/posts/' + item.post.id"
            target="_blank"
            class="button secondary small go-to-post-button"
          >
            {{ tr('Go to Post') }}
          </a>
          <button
            v-if="item.is_current && !item.isHandled"
            class="primary small"
            @click="() => openOverruleModal(item)"
            :disabled="loadingId || $root.isSupportMode"
          >
            <i v-if="loadingId == item.id" class="icon loader white" />
            <i v-else class="icon gavel white" />
            {{ tr('Override AI') }}
          </button>
        </div>

        <div v-if="item.isHandled" class="status-wrapper">
          <div class="status">
            {{ tr('Overruled') }}
            <template v-if="item.handled_by">
              {{ tr('by :name', { ':name': item.handled_by.fullname }) }}
            </template>
          </div>
          <span
            v-if="item.handled_at"
            v-tooltip="formatToYMD(item.handled_at) + ', ' + formatTime(item.handled_at)"
            class="datetime"
          >
            {{ timeAgo(item.handled_at, tr) }}
          </span>
        </div>
      </card>

      <template v-if="hasMore">
        <card v-for="i in (isListEmpty ? [ 1, 2, 3 ] : [ 1, 2 ])" :key="'rule-ai-risk-review-loader-skeleton--' + i" ref="loadMore">
          <skeleton width="120px" height="12px" class="datetime" />
          <skeleton width="70%" max-width="600px" class="ai-action" />
          <skeleton width="70%" max-width="600px" height="12px" class="post" />

          <skeleton width="140px" class="reason-title" />
          <skeleton width="80%" class="reason" />

          <div class="buttons">
            <skeleton width="120px" height="30px" radius="10px" />
            <skeleton width="120px" height="30px" radius="10px" />
          </div>
        </card>
      </template>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import Skeleton from '@/components/Skeleton.vue';

import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';
import RuleAiManagementService from '@/services/RuleAiManagement.js';

import { formatToYMD, formatTime, timeAgo } from '@/utils/date.js';
import { ucfirst } from '@/utils/string';

const ITEM_PER_PAGE = 20;

export default {
  name: 'RuleAiManagementView',
  components: {
    Card,
    NotificationCard,
    Skeleton,
  },
  data:() => ({
    isLoading: false,
    hasMore: true,
    page: 0,
    items: [],

    itemIdInQuery: null,
    postIdInQuery: null,
    loadingId: null,
  }),
  computed: {
    ruleId() {
      return this.$route.params.id;
    },
    isListEmpty() {
      return this.items.length === 0;
    },
    rule() {
      return this.$parent.rule;
    },
  },
  watch: {
    rule: {
      immediate: true,
      handler(val) {
        if (val && this.rule.settings.ai_review) {
          this.loadNewData();
        }
      },
    },
    isLoading(val) {
      if (val) {
        return;
      }

      if (this.itemIdInQuery && this.postIdInQuery) {
        let item = this.items.find(i => i.id == this.itemIdInQuery);
        console.log(this.items);
        if (!item) {
          item = new PostAiProhibitedContent({
            id: this.itemIdInQuery,
            post: { id: this.postIdInQuery }
          });
        }

        this.overruleResult(item);

        this.itemIdInQuery = null;
        this.postIdInQuery = null;
      }
    },
  },
  created() {
    this.itemIdInQuery = this.$route.query['aid'];
    this.postIdInQuery = this.$route.query['pid'];
    if (this.itemIdInQuery || this.postIdInQuery) {
      this.$router.replace(this.$route.path);
    }
  },
  methods: {
    formatToYMD,
    formatTime,
    timeAgo,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        ruleId: this.ruleId,
        page: this.page,
        limit: ITEM_PER_PAGE,
      };

      RuleAiManagementService.getList(params)
        .then(resp => {
          resp.forEach(item => this.items.push(item));
          this.hasMore = resp.length === ITEM_PER_PAGE;
        })
        .catch((err) => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    buildAction(item) {
      return this.tr('Infinite∞Ai prevented the creation of the advertisement from the following <strong>\":name\"</strong> :platform post.', {
        ':name': item.post.source_profile.name,
        ':platform': ucfirst(item.post.source_profile.platform),
      });
    },
    openOverruleModal(item) {
      this.$confirmation.open({
        title: this.tr('Override AI'),
        content: this.tr('Infinite∞Ai prevented the creation of the ad<br/>for the following reason') + ':' +
          '<div class="quote">' + item.reason + '</div>' +
          this.tr('If you disagree with the decision, overriding it will cause the automation to attempt to create the advertisement.'),
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Override AI'),
        confirm: () => this.overruleResult(item)
      });
    },
    overruleResult(item) {
      if (this.loadingId) {
        return;
      }

      this.loadingId = item.id;

      return RuleAiManagementService.overruleResult(this.rule.id, item)
        .then(resp => {
          item.updateWith(resp);

          this.$toast.clear();
          this.$toast.success(this.tr('Decision successfully overrided. During the next run of the automation, the automation will attempt to create the advertisement.'));
        })
        .catch(err => this.error(err))
        .finally(() => this.loadingId = null);
    },
  }
};
</script>
