<template>
  <component
    v-bind="props"
    :is="component"
    :class="wrapperClasses"
    @click="($event) => $emit('click', $event)"
  >
    <div class="column left">
      <i
        :class="icon"
        class="icon white"
      />
      <div>
        <h1 class="title">{{ title }}</h1>
        <div class="flag">{{ flagText }}</div>
      </div>
    </div>
    <div class="column right">
      <div class="description">
        {{ description }}
      </div>
      <div v-if="$slots['under-description']" class="under-description">
        <slot name="under-description" />
      </div>
    </div>
    <navigation-arrow v-if="path || hasClickEvent" :to="path"></navigation-arrow>
  </component>
</template>

<script>
import NavigationArrow from '@/components/NavigationArrow.vue';

export default {
  name: 'EmptyScreen',
  components: {
    NavigationArrow,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    flagText: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    mobile: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    isExternalLink() {
      return this.path.startsWith('http');
    },
    component() {
      if (this.path) {
        return this.isExternalLink ? 'a' : 'router-link';
      }

      return 'div';
    },
    props() {
      if (this.path) {
        if (this.isExternalLink) {
          return {
            href: this.path,
            target: '_blank',
          };
        }

        return {
          to: this.path,
        };
      }

      return {};
    },
    wrapperClasses() {
      return {
        'empty-screen-component': true,
        'link': this.path || this.hasClickEvent,
        'mobile': this.mobile,
      };
    },
    hasClickEvent() {
      return !!this.$listeners.click;
    },
  },
};
</script>
