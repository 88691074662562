var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-steps" },
    _vm._l(_vm.cards, function (card, cardIndex) {
      return _c("step-card", {
        key: cardIndex,
        attrs: {
          "default-text": card.defaultText,
          "done-text": card.doneText,
          step: _vm.tr(`Step ${cardIndex + 1}`),
          "button-text": card.buttonText,
          "button-route": card.buttonRoute,
          "is-card-disabled": cardIndex !== 0 && !_vm.isCardDone(cardIndex - 1),
          "is-card-done": _vm.isCardDone(cardIndex),
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }