<template>
  <div>
    <div class="dashboard-ad-view container">
      <div class="left">
        <button
          v-if="showSteps && showReopenOnboardingButton"
          class="reopen-onboarding-button primary"
          @click="reopenOnboarding"
        >
          <i class="icon magic white" />
          {{ tr('Open Onboarding Wizard') }}
        </button>

        <dashboard-steps
          v-if="showSteps"
          :profile-connected="dashboardData.profile_connected"
          :ad-account-connected="dashboardData.ad_account_connected"
          :source-profile-connected="dashboardData.source_profile_connected"
          :post-boosting-created="dashboardData.post_boosting_rule_created"
          :rule-created="dashboardData.rule_created"
        />
        <latest-activities
          v-else
          :activities="dashboardData.rule_activity_log"
          :loading="isLoading"
        />
      </div>

      <div class="right">
        <div class="active-boosts">
          <active-boosts-card
            :loading="isLoading"
            icon="blister"
            :number="dashboardData.total_active_rule_count ?? 0"
            :number-text="dashboardData.total_active_rule_count === 1 ? tr('active automation') : tr('active automations')"
            :last-activity="dashboardData.last_activity"
            link="/automation"
            :link-text="tr('Go to my automations')"
          />
        </div>

        <blog-box v-if="$root.language === 'hu'" />
      </div>
    </div>
  </div>
</template>

<script>
import ActiveBoostsCard from '@/components/ActiveBoostsCard.vue';
import BlogBox from '@/components/BlogBox';
import DashboardSteps from '@/components/DashboardSteps.vue';
import LatestActivities from '@/components/LatestActivities.vue';
import OnboardingAd from '@/components/OnboardingAd.vue';
import DashboardMixin from '@/mixins/Dashboard.js';
// import CompanyService from '@/services/Company.js';

export default {
  name: 'DashboardView',
  components: {
    ActiveBoostsCard,
    BlogBox,
    DashboardSteps,
    LatestActivities,
  },
  mixins: [ DashboardMixin ],
  computed: {
    showSteps() {
      return 'rule_activity_log' in this.dashboardData && this.dashboardData.rule_activity_log.length === 0;
    },
    showReopenOnboardingButton() {
      return !this.dashboardData.profile_connected ||
        !this.dashboardData.ad_account_connected ||
        !this.dashboardData.source_profile_connected;
    },
  },
  created() {
    this.$router.replace('/automation');

    // this.$store.dispatch('dashboard/fetch', this.$root.currentCompany.id).catch(err => this.error(err));
  },
  methods: {
    reopenOnboarding() {
      this.$fullscreenModal.open(
        this,
        OnboardingAd,
        {},
        { closable: false },
      );
    },
  },
};
</script>
