var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "change-password-modal",
        overlayTransition: "fade-modal-overlay",
      },
      on: { closed: _vm.modalClosed },
    },
    [
      _c("div", { staticClass: "modal-title" }, [
        _vm._v(" " + _vm._s(_vm.tr("Change password")) + " "),
      ]),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", [
          _c("span", [_vm._v(" " + _vm._s(_vm.tr("Current password")) + " ")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentPassword,
                expression: "currentPassword",
              },
            ],
            attrs: {
              type: "password",
              placeholder: _vm.tr("Enter current password here"),
            },
            domProps: { value: _vm.currentPassword },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.currentPassword = $event.target.value
              },
            },
          }),
        ]),
        _c("div", [
          _c("span", [_vm._v(" " + _vm._s(_vm.tr("New password")) + " ")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newPassword,
                expression: "newPassword",
              },
            ],
            attrs: {
              type: "password",
              placeholder: _vm.tr("Enter new password here"),
            },
            domProps: { value: _vm.newPassword },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.newPassword = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "modal-action-group" }, [
        _c(
          "button",
          { staticClass: "secondary", on: { click: () => _vm.close() } },
          [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "primary",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.submit() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : [_vm._v(" " + _vm._s(_vm.tr("Change")) + " ")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }