<template>
  <div class="settings-payment-history-view">
    <sorted-table
      :data="list"
      class="payment-history-table row-hover-shadow"
    >
      <template #header>
        <table-head col="date" minWidth="100px">{{ tr('Date') }}</table-head>
        <table-head col="net" minWidth="80px">{{ tr('Net') }}</table-head>
        <table-head col="gross" minWidth="80px">{{ tr('Gross') }}</table-head>
        <table-head col="status" minWidth="120px">{{ tr('Status') }}</table-head>
        <table-head col="invoice_number" minWidth="120px">{{ tr('Invoice number') }}</table-head>
        <table-head col="actions" width="24px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="paymentHistory in sort" :key="paymentHistory.id">
          <table-data col="date">{{ formatToYMD(paymentHistory.created_at) }}</table-data>
          <table-data col="net">{{ formatPrice(paymentHistory.net, '€') }}</table-data>
          <table-data col="gross">{{ formatPrice(paymentHistory.gross, '€') }}</table-data>
          <table-data col="status">
            <div :class="'status-flag ' + paymentHistory.status">
              {{ tr(paymentHistory.status) }}
            </div>
          </table-data>
          <table-data col="invoice_number">{{ paymentHistory.invoice_number ? paymentHistory.invoice_number : '-' }}</table-data>
          <table-data col="actions">
            <navigation-arrow @click="() => openDetails(paymentHistory)" />
          </table-data>
        </table-row>
      </template>
    </sorted-table>

    <div v-if="list.length === 0 && !isLoading" class="empty-list">
      {{ tr('There are no items to display.') }}
    </div>

    <div v-if="hasMore" ref="loadMore" class="load-more">
      <i class="icon loader primary" /> {{ tr('Loading...') }}
    </div>

    <modal
      name="payment-history-details-modal"
      class="payment-history-details-modal"
      @closed="() => openedPaymentHistory = null"
    >
      <i
        class="icon close"
        @click="() => $modal.hide('payment-history-details-modal')"
      />
      <form-block v-if="openedPaymentHistory" :title="tr('Payment details')">
        <div class="informations">
          <div>
            <label>{{ tr('Date') }}:</label> {{ formatToYMD(openedPaymentHistory.created_at) }}
          </div>

          <div>
            <label>{{ tr('Status') }}:</label>
            <div :class="'status-flag ' + openedPaymentHistory.status">
              {{ tr(openedPaymentHistory.status) }}
            </div>
          </div>

          <div v-if="openedPaymentHistory.fail_reason">
            <label>{{ tr('Fail reason') }}:</label> {{ openedPaymentHistory.fail_reason }}
          </div>
        </div>

        <payment-history-details :entity="openedPaymentHistory" />

        <div class="buttons">
          <button
            v-if="openedPaymentHistory.invoice_number"
            class="primary"
            :disabled="invoiceDownloading"
            @click="() => downloadInvoice(openedPaymentHistory)"
          >
            <i v-if="invoiceDownloading === openedPaymentHistory.id" class="icon loader white" />
            {{ tr('Download invoice') }}
          </button>

          <button
            v-else-if="openedPaymentHistory.status === 'failed'"
            class="primary"
            @click="() => payNow(openedPaymentHistory)"
          >
            {{ tr('Pay now') }}
          </button>
        </div>
      </form-block>
    </modal>
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import PaymentHistoryDetails from '@/components/PaymentHistoryDetails.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import PaymentHistoryService from '@/services/PaymentHistory';
import { formatToYMD } from '@/utils/date';
import { formatPrice } from '@/utils/number';

const LIMIT_PER_PAGE = 20;

export default {
  name: 'SettingsInvoicesView',
  components: {
    FormBlock,
    NavigationArrow,
    PaymentHistoryDetails,
  },
  data: () => ({
    page: 1,
    isLoading: false,
    hasMore: true,
    list: [],

    invoiceDownloading: null,
    openedPaymentHistory: null,
  }),
  computed: {
    isListEmpty() {
      return this.list.length === 0;
    },
  },
  mounted() {
    this.initInfiniteScroll();
  },
  methods: {
    formatToYMD,
    formatPrice,
    loadMore() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;

      PaymentHistoryService.getList({ page: this.page, limit: LIMIT_PER_PAGE })
        .then(resp => {
          this.list.push(...resp);

          this.hasMore = resp.length === LIMIT_PER_PAGE;
          ++this.page;

          this.$nextTick(() => this.initInfiniteScroll());

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadMore();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    downloadInvoice(paymentHistory) {
      if (this.invoiceDownloading) {
        return;
      }

      this.invoiceDownloading = paymentHistory.id;

      PaymentHistoryService.downloadInvoice(paymentHistory.id, paymentHistory.invoice_number + '.pdf')
        .catch(err => this.error(err))
        .finally(() => this.invoiceDownloading = null);
    },
    openDetails(paymentHistory) {
      this.openedPaymentHistory = paymentHistory;
      this.$modal.show('payment-history-details-modal');
    },
    payNow(paymentHistory) {
      this.$modal.show(
        PaymentModal,
        { paymentHistory },
        { class: '' },
        {
          error: err => this.error(err),
        },
      );
    },
  },
};
</script>
