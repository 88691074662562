<template>
  <div>
    <form-block
      v-model="entity.settings.custom_cta_settings"
      class="rule-form-block cta-block-component"
      :title="title"
      :description="description"
      :switchable="switchable"
      :disabled="disabled"
    >
      <info-text v-if="disabledText">
        {{ disabledText }}
      </info-text>
      <info-text v-else-if="entity.settings.adset_conversion_location === 'instagram_profile'">
        {{ tr('You have chosen Instagram profile as the conversion location, so you cannot set a custom CTA.') }}
      </info-text>
      <template v-else>
        <info-text v-if="campaignObjectiveLabel" class="first">
          {{ tr('If you do not set a CTA Button URL, no ad will be created from those posts that do not contain a URL, as this is required by the campaign\'s goal (:goal).', { ':goal': campaignObjectiveLabel }) }}
        </info-text>

        <div>
          <label class="input-label">
            {{ tr('Button type') }}
          </label>
          <v-select
            v-model="entity.settings.cta_button"
            :options="ctaList"
            :reduce="(obj) => obj.machine_name"
            :searchable="false"
            :clearable="false"
            :disabled="disabled"
            label="label"
          />
        </div>
        <div v-if="hasSelectedCtaButton">
          <label class="input-label">
            {{ tr('Button URL') }}
          </label>
          <input
            v-model="entity.settings.cta_url"
            type="text"
            :class="{ 'error': urlError }"
            :placeholder="tr('Enter the URL you want to promote')"
            :disabled="disabled || entity.isAppPromo"
            @blur="() => urlValidation()"
          />
        </div>

        <div v-if="hasSelectedCtaButton && !entity.isAppPromo">
          <div class="checkbox-list horizontal">
            <label>
              <input
                type="checkbox"
                v-model="entity.settings.smart_cta_url"
                :disabled="disabled"
              />
              {{ tr('Smart URL') }}
            </label>
          </div>
          <small>
            {{ tr('If the post contains at least one link, we will use it as the Button URL to create the ad. In this case, the URL provided here will only be used if the post does not contain any links.') }}
          </small>
        </div>
      </template>
    </form-block>

    <info-text v-if="switchable && !entity.settings.custom_cta_settings && disabledText">
      {{ disabledText }}
    </info-text>
  </div>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

import InfoText from '@/components/InfoText.vue';

import { isValidURL } from '@/utils/validators.js';

export default {
  name: 'CtaBlock',
  mixins: [ RuleFormBlockMixin ],
  components: {
    InfoText,
  },
  props: {
    disabledText: {
      type: String | null,
      required: false,
      default: () => '',
    },
    switchable: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  data: () => ({
    urlError: false,
  }),
  computed: {
    title() {
      if (this.switchable) {
        return this.tr('Custom call to action');
      }

      return this.tr('Call to action');
    },
    description() {
      if (this.switchable) {
        return this.tr('By overriding the original ad settings, you can set the CTA that best suits your scaling campaign.')
        + ' '
        + this.tr('You can choose the text for the button from the dropdown list and give the URL you want to promote.');
      }

      return this.tr('If the creative used for the ad doesn\'t have a clickable button to encourage clicks, you can add a CTA button here.')
        + ' '
        + this.tr('You can choose the text for the button from the dropdown list and give the URL you want to promote.')
        + '<br/>'
        + this.tr('(Except Facebook post to Facebook Feed placement.)');
    },
    ctaList() {
      let list = this.$store.getters['app/facebookCallToActions'];
      if (this.entity.isAppPromo) {
        list = list.filter(i => i.machine_name !== 'NO_BUTTON');
      }

      return list;
    },
    hasSelectedCtaButton() {
      return this.entity.settings.cta_button !== 'NO_BUTTON';
    },
    campaignObjectiveLabel() {
      if ([ 'CONVERSIONS', 'OUTCOME_SALES', 'OUTCOME_LEADS' ].includes(this.entity.output_campaign.objective)) {
        return this.tr('Conversion');
      }
      else if ([ 'LINK_CLICKS', 'OUTCOME_TRAFFIC' ].includes(this.entity.output_campaign.objective)) {
        return this.tr('Traffic');
      }

      return null;
    },
  },
  watch: {
    'entity.settings.custom_cta_settings'(val) {
      if (!val) {
        this.entity.settings.cta_button = null;
        this.entity.settings.cta_url = '';
        this.entity.settings.smart_cta_url = false;
      }
      else {
        this.entity.settings.cta_button = this.ctaList[0].machine_name;
      }
    },
    'entity.settings.cta_button'(newValue, oldVal) {
      if (newValue === 'NO_BUTTON') {
        this.entity.settings.cta_url = '';
      }
      else if (oldVal === 'NO_BUTTON') {
        this.entity.settings.smart_cta_url = true;
      }
    },
    'entity.settings.cta_url'(val) {
      if (val.trim().length === 0 && this.entity.settings.cta_button !== 'NO_BUTTON') {
        this.entity.settings.smart_cta_url = true;
      }
    },
  },
  methods: {
    validate() {
      this.urlValidation();

      console.log(this.urlError);

      if (this.urlError !== '') {
        this.error(this.tr('A CTA URL is required if a CTA button is used.'));
        return false;
      }

      return true;
    },
    urlValidation() {
      if (this.switchable && !this.entity.settings.custom_cta_settings) {
        this.urlError = '';
        return;
      }

      if (this.entity.settings.cta_button !== 'NO_BUTTON' && !isValidURL(this.entity.settings.cta_url)) {
        this.urlError = 'error';
      }
      else {
        this.urlError = '';
      }
    },
  },
};
</script>
