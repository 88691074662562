/* eslint-disable filenames/match-regex */

const state = {
  deletedAdCopyMagicItem: false,
};

const getters = {
  deletedAdCopyMagicItem(state) {
    return state.deletedAdCopyMagicItem;
  },
};

const actions = {
  resetView(store) {
    store.commit('deletedAdCopyMagicItem', false);
  },
  updateDeletedAdCopyMagicItem(store) {
    store.commit('deletedAdCopyMagicItem', true);
  },
};

const mutations = {
  setDeletedAdCopyMagicItem(state, value) {
    state.deletedAdCopyMagicItem = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
