import Api from '@/services/Api';

import User from '@/entities/User';

export default {
  update(id, { firstname, lastname, email }) {
    let data = {
      firstname,
      lastname,
      email,
    };

    return Api().put('/user/' + id, data).then(resp => new User(resp));
  },

  changeLevel(id, { level, visibleAdAccountIds, visibleSourceProfileIds }) {
    let data = {
      level,
    };

    if (typeof visibleAdAccountIds !== 'undefined') {
      data['visible_ad_account_ids'] = visibleAdAccountIds;
    }
    if (typeof visibleSourceProfileIds !== 'undefined') {
      data['visible_source_profile_ids'] = visibleSourceProfileIds;
    }

    return Api().put('/user/' + id + '/change-level', data).then(resp => new User(resp));
  },

  changeLanguage(id, language) {
    let data = { language};

    return Api().put('/user/' + id + '/language', data);
  },

  delete(id) {
    return Api().delete('/user/' + id);
  },
};
