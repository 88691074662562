<template>
  <div class="latest-activities">
    <div class="title">{{ tr('Your latest Activities') }}</div>

    <div class="activity-wrapper" v-if="loading">
      <div class="activities" v-for="i in [0, 1, 2]" :key="i">
        <div class="date-time">
          <skeleton width="32px" height="30px" light />
        </div>
        <div class="metadata">
          <skeleton width="200px" height="15px" light />
          <skeleton height="15px" light />
          <skeleton width="160px" height="15px" light />
        </div>
      </div>
    </div>
    <div class="activity-wrapper" v-else-if="activities.length != 0">
      <div class="activities" v-for="activity in activities" :key="activity.id">
        <div class="date-time">
          <div class="icon-wrapper">
            <i
              :class="{
                'icon': true,
                'primary': true,
                'play': activity.action === 'start',
                'pause': activity.action === 'stop' || activity.action === 'smart_stop' || activity.action === 'delete',
                'scaling-up': activity.action === 'scaling_up',
              }"
            />
          </div>
          <div class="date">{{ formatToMD(activity.created_at) }}</div>
          <div class="time">{{ formatTime(activity.created_at) }}</div>
        </div>
        <div class="metadata">
          <div class="action">
            {{ activityLabel(activity.action) }}
          </div>

          <template v-if="activity.ad !== null">
            <div class="name">{{ activity.ad.name }}</div>
            <div class="ad-platform">
              {{ tr('Ad platform') }}: {{ activity.ad.platform }}
            </div>
          </template>

          <div class="text" v-if="activity.text">{{ activity.text }}</div>

          <template v-if="activity.post !== null">
            <div class="source">
              {{ tr('Post source') }}: {{ activity.post.source_profile.platform }}
            </div>
            <a
              :href="`https://www.facebook.com/${activity.post.external_id}`"
              target="_blank"
              class="activity-button"
            >
              {{ tr('Go to Post') }}
            </a>
          </template>
          <router-link
            :to="`/automation/${activity.rule.id}`"
            class="activity-button"
          >
            {{ tr('Go to the Automation') }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="no-activity">
      {{ tr('No automation has performed any activities yet.') }}
    </div>

    <div class="buttons">
      <router-link to="/automation">
        <i class="icon blister" />
        {{ tr('Go to all Automations') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue';
import { formatTime, formatToMD } from '@/utils/date';

export default {
  name: 'LatestActivities',
  components: {
    Skeleton,
  },
  props: {
    activities: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  methods: {
    formatTime,
    formatToMD,
    activityLabel(action) {
      switch (action) {
        case 'start':
          return this.tr('New ad was created');

        case 'stop':
        case 'smart_stop':
          return this.tr('Your ad was stopped');

        case 'delete':
          return this.tr('Your ad was removed');
      }

      return '';
    }
  }
};
</script>
