var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block operating-area-block-component",
      attrs: { title: _vm.tr("Operating area") },
    },
    [
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Ads created by the following automations"))),
          ]),
          _c("div", { staticClass: "description" }, [
            _vm._v(
              _vm._s(
                _vm.tr(
                  "Here, you can select the start automation that was previously set for this ad management automation to work in."
                )
              )
            ),
          ]),
          _vm.isLoading
            ? _c("div", { staticClass: "inline-loader" }, [
                _c("i", { staticClass: "icon loader" }),
                _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
              ])
            : _c("multi-select", {
                attrs: {
                  options: _vm.ruleCampaignsByAdAccount,
                  disabled: _vm.disabled || !_vm.entity.hasSelectedAdAccount,
                  placeholder: _vm.tr("Select an automation..."),
                  "deleted-plus-label":
                    _vm.tr("Automation Stopped") +
                    " - " +
                    _vm.tr("Campaign Deleted on Ads Manager"),
                  "disabled-click-message": _vm.disabledOptionMessage,
                },
                model: {
                  value: _vm.entity.input_campaigns,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity, "input_campaigns", $$v)
                  },
                  expression: "entity.input_campaigns",
                },
              }),
        ],
        1
      ),
      !_vm.isLoading
        ? [
            _vm.entity.hasSelectedAdAccount &&
            _vm.ruleCampaignsByAdAccount.length === 0
              ? _c("info-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "There are no start automations available in the selected ad account(s)."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm.disabledOptionMessage
              ? _c("info-text", [
                  _vm._v(" " + _vm._s(_vm.disabledOptionMessage) + " "),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }