<template>
  <div class="moderation-view">
    <sticky-header
      :title="tr('Moderation')"
      :info="info"
      :bordered="!tabsIsVisible"
    >
      <template #buttons>
        <router-link
          v-if="isPostView"
          class="button gray"
          to="/moderation/post"
        >
          {{ tr('Back') }}
        </router-link>
      </template>

      <form-step-tab v-if="tabsIsVisible" :items="tabs" />
    </sticky-header>

    <div class="transition-position-fix">
      <transition name="fade--fast">
        <router-view ref="child" />
      </transition>
    </div>
  </div>
</template>

<script>
import FormStepTab from '@/components/FormStepTab.vue';
import StickyHeader from '@/components/StickyHeader.vue';

export default {
  name: 'ModerationView',
  components: {
    FormStepTab,
    StickyHeader,
  },
  computed: {
    info() {
      return this.tr('The <b class="primary">Infinite∞Ai</b> evaluates comments under your posts, and, depending on your settings, moderates them. 🧠🤖');
    },
    tabs() {
      return [
        {
          label: this.tr('Protected Accounts'),
          link: '/moderation/account',
        },
        {
          label: this.tr('Protected Posts'),
          link: '/moderation/post',
        },
      ];
    },
    isPostView() {
      return this.$route.name === 'ProtectedPostView'
    },
    tabsIsVisible() {
      return !this.isPostView;
    },
  },
};
</script>
