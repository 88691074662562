import BaseEntity from '@/entities/BaseEntity';
import { parseNumber } from '@/utils/number.js';
import { AD_CREATION_METHOD } from '@/consts.js';

export default class ScalingUpRuleSettings extends BaseEntity
{
  name_prefix = '[Infinite.ad]';
  campaign_name_prefix = '[Infinite.ad]';
  adset_name = '{{month}}.{{date}}. {{time}} - {{post.message}}';
  stop_original = false;
  custom_cta_settings = false;
  cta_button = null;
  cta_url = null;
  smart_url = true;
  url_params = 'utm_source=facebook&utm_medium=cpc&utm_campaign={{campaign.name}}';
  dont_scale_expired_ads = true;
  _adset_budget = null;
  adset_budget_period = null;
  max_active_ads = null;

  ad_creation_method = AD_CREATION_METHOD.CREATIVE;
  ai_cta_content = false;
  ai_cta_button = false;
  ai_primary_text = false;
  ad_variants = 1;
  ai_ad_with_original_content = true;
  ap_creative_features = [];

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get adset_budget() {
    return this._adset_budget;
  }

  set adset_budget(value) {
    this._adset_budget = parseNumber(value);
  }

  toObject() {
    let output = super.toObject();
    if ('campaign' in output) {
      output.campaign_id = output.campaign.id;
      delete output.campaign;
    }

    return output;
  }
}
