import FullscreenModalComponent from '@/components/FullscreenModal.vue';

/* eslint-disable-next-line local-rules/custom-naming-pattern */
const FullscreenModal = {
  install(Vue) {
    const modalConstructor = Vue.extend(FullscreenModalComponent);

    Vue.prototype.$fullscreenModal = {
      instances: [],

      open(parentVm, component, properties = {}, options = {}) {
        const modalInstance = new modalConstructor({
          parent: parentVm,
          propsData: { component, properties, ...options },
        });

        const modalDiv = document.createElement('div');
        document.body.appendChild(modalDiv);
        modalInstance.$mount(modalDiv);

        modalInstance.open();

        this.instances.push(modalInstance);

        return modalInstance;
      },

      closed(instance) {
        this.instances = this.instances.filter(i => i !== instance);
      },

      closeAll() {
        this.instances.forEach(instance => instance.close());
        this.instances = [];
      },
    };

    document.addEventListener('keyup', event => {
      if (event.key === 'Escape') {
        const countOfModals = Vue.prototype.$fullscreenModal.instances.length;
        if (countOfModals === 0) {
          return;
        }

        Vue.prototype.$fullscreenModal.instances[countOfModals - 1].close();
      }
    });
  },
};

export default FullscreenModal;
