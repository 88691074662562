var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-edit-history-view" },
    [
      _c(
        "transition",
        { attrs: { name: "fade--fast" } },
        [
          _vm.isLoading && _vm.isListEmpty
            ? _c("div", { staticClass: "loader" }, [
                _c("i", { staticClass: "icon loader black" }),
              ])
            : _vm.isListEmpty
            ? _c(
                "notification-card",
                { staticClass: "no-result", attrs: { "empty-screen": "" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr("The automation has not yet been changed.")
                      ) +
                      " "
                  ),
                ]
              )
            : _c(
                "div",
                { staticClass: "logs" },
                [
                  _vm._l(_vm.logs, function (item, key) {
                    return _c(
                      "div",
                      { key: "item-" + key, staticClass: "log-item" },
                      [
                        _c("div", { staticClass: "title-with-date" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.buildTitle(item, key))),
                          ]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value:
                                    _vm.formatToYMD(item.created_at) +
                                    ", " +
                                    _vm.formatTime(item.created_at),
                                  expression:
                                    "formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)",
                                },
                              ],
                              staticClass: "date",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.timeAgo(item.created_at, _vm.tr)) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        item.changes.length !== 0 || key !== _vm.lastItemKey
                          ? _c("rule-change-log-fields", {
                              attrs: { entity: item },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _c("div", { staticClass: "side side--original" }),
                ],
                2
              ),
        ],
        1
      ),
      !_vm.isListEmpty && _vm.hasMore
        ? _c("div", { ref: "loadMore", staticClass: "load-more" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }