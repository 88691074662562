<template>
  <card :class="wrapperClasses">
    <top-image-content v-if="image || showImageWrapper">
      <img v-if="image" :src="image" />
    </top-image-content>

    <div class="textual">
      <h2 v-if="title">{{ title }}</h2>
      <slot />
    </div>
  </card>
</template>

<script>
import Card from '@/components/Card.vue';
import TopImageContent from '@/components/TopImageContent.vue';

export default {
  name: 'NotificationCard',
  components: {
    Card,
    TopImageContent,
  },
  props: {
    image: {
      type: Object | null,
      required: false,
      default: () => null,
    },
    title: {
      type: String | null,
      required: false,
      default: () => null,
    },
    text: {
      type: String | null,
      required: false,
      default: () => null,
    },
    emptyScreen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    showImageWrapper: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'notification-card-component': true,
        'empty-screen': this.emptyScreen,
        'with-image': this.image !== null
      };
    },
  },
};
</script>
