import BaseEntity from '@/entities/BaseEntity';

export default class Pixel extends BaseEntity
{
  id = null;
  name = '';
  is_unavailable = false;
  active_events = [];
  active_standard_conversions = [];
  last_fired_time = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);

    for (const [key, value] of Object.entries(rawData)) {
      if (!(key in this)) {
        continue;
      }

      if (
        key === 'last_fired_time'
        && !!value
      ) {
        this[key] = new Date(value);
      }
    }
  }
}
