<template>
  <div class="connected-platforms-meta-profiles-view">
    <notification-card
      v-if="!isProfilesEmpty || true"
      :image="require('@/assets/img/onboarding/step-profile.png')"
      empty-screen
    >
      {{ tr('First, connect your Meta Profile, then you\'ll see all of your assets') }}

      <button
        v-if="connectButtonVisible"
        :class="connectProfileButtonClasses"
        :disabled="isConnecting"
        @click="$emit('connect')"
      >
        <i v-if="isConnecting" class="icon loader white" />
        <template v-else>+</template>
        {{ tr('Connect Your Profile') }}
      </button>
    </notification-card>

    <div v-else class="profiles">
      <connected-profile-item
        v-for="profile in profiles"
        :key="profile.id"
        :entity="profile"
        @remove="() => removeProfile(profile)"
        @reconnect="$emit('reconnect', profile)"
      />
    </div>

    <disconnect-confirm-modal ref="disconnectConfirmModal" />
  </div>
</template>

<script>
import ConnectedProfileItem from '@/components/ConnectedProfileItem';
import DisconnectConfirmModal from '@/components/DisconnectConfirmModal.vue';
import NotificationCard from '@/components/NotificationCard.vue';

import ConnectedProfile from '@/entities/ConnectedProfile';

import ToolService from '@/services/Tool.js';

export default {
  name: 'ConnectedPlatformsMetaProfilesView',
  components: {
    ConnectedProfileItem,
    DisconnectConfirmModal,
    NotificationCard,
  },
  computed: {
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
    connectProfileButtonClasses() {
      return {
        button: true,
        primary: true,
        'connect-new-profile': true,
        loading: this.isConnecting,
      };
    },
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    isProfilesEmpty() {
      return this.profiles.length == 0;
    },
    profiles() {
      let profileItems = this.$root.currentCompany.connected_profiles.filter(
        (profile) => profile.platform === 'facebook'
      );
      let entityProfiles = [];
      for (let profile of profileItems) {
        entityProfiles.push(new ConnectedProfile(profile));
      }
      return entityProfiles;
    },
  },
  methods: {
    removeProfile(connectedProfile) {
      if (!this.$app.isAd) {
        return this.disconnect(connectedProfile);
      }

      ToolService.disconnectPrecheck({ connectedProfileId: connectedProfile.id })
        .then(resp => {
          if (resp.defunc_rules.length !== 0) {
            this.$refs.disconnectConfirmModal.open(
              { connectedProfile },
              resp.defunc_rules,
              () => this.disconnect(connectedProfile)
            );
          }
          else {
            return this.disconnect(connectedProfile);
          }
        })
        .catch((err) => this.error(err));
    },
    disconnect(profile) {
      return this.$store.dispatch('auth/removeProfile', profile.id)
        .then(() => {
          this.$toast.success('Profile was successfully disconnected.');
        })
        .catch((err) => this.error(err));
    }
  },
};
</script>
