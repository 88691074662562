<template>
  <div class="rule-waiting-list-status-component">
    <template v-if="isActivityManagementItem">
      <div v-if="!entity.isHandled && !entity.is_current" class="is-not-actual">
        {{ tr('The item affected by the activity is no longer available.') }}
      </div>
      <template v-else>
        <div class="top">
          <template v-if="isHandlingButtonVisible">
            <button
              class="primary"
              :disabled="disabled"
              @click="() => activityManagementAccept()"
            >
              <i v-if="loadingButton === entity.id + '--accept'" class="icon loader white" />
              {{ tr('Accept') }}
            </button>
            <button
              class="red"
              :disabled="disabled"
              @click="() => activityManagementReject()"
            >
              <i v-if="loadingButton === entity.id + '--reject'" class="icon loader" />
              <i v-else class="icon delete" />
            </button>
          </template>

          <template v-else>
            <i v-if="entity.isAccepted" class="icon checkmark-circle primary" />
            <i v-else-if="entity.isRejected" class="icon close-circle red" />
            <span v-tooltip="formatToYMD(entity.handled_at) + ' ' + formatTime(entity.handled_at)">
              {{ activityManagementHandledAt }}
            </span>
          </template>
        </div>

        <div :class="{ 'bottom': true, 'is-handled': !isHandlingButtonVisible }">
          {{ activityManagementStatus }}
        </div>
      </template>
    </template>

    <template v-else-if="isAiProhibitedItem">
      <div v-if="!entity.isHandled && !entity.is_current" class="is-not-actual">
        {{ tr('The result is no longer relevant because the automation or the entry has been modified since the review.') }}
      </div>
      <template v-else>
        <div class="top">
          <template v-if="isHandlingButtonVisible">
            <button
              v-if="entity.is_current && !entity.isHandled"
              class="primary"
              :disabled="loadingButton !== null || $root.isSupportMode"
              @click="() => overrideAi()"
            >
              <i v-if="loadingButton == entity.id + '--override'" class="icon loader white" />
              {{ tr('Override') }}
            </button>
          </template>
          <template v-else>
            <i class="icon checkmark-circle primary" />
            <span v-tooltip="formatToYMD(entity.handled_at) + ' ' + formatTime(entity.handled_at)">
              {{ aiOverrideHandledAt }}
            </span>
          </template>
        </div>

        <div :class="{ 'bottom': true, 'is-handled': !isHandlingButtonVisible }">
          {{ aiOverrideStatus }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { EVENT_BUS_EVENTS, RULE_ACTION, RULE_ACTIVITY_MANAGEMENT_INSTRUCTION } from '@/consts.js';
import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';
import Rule from '@/entities/Rule.js';
import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';
import RuleActivityManagementService from '@/services/RuleActivityManagement.js';
import RuleAiManagementService from '@/services/RuleAiManagement.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date.js';
import EventBus from '@/utils/eventBus.js';

export default {
  name: 'RuleWaitingListStatus',
  props: {
    rule: {
      type: Rule,
      required: true,
    },
    entity: {
      type: [ PostAiProhibitedContent, RuleActivityManagementQueueItem ],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    loadingButton: null,
  }),
  computed: {
    isActivityManagementItem() {
      return this.entity instanceof RuleActivityManagementQueueItem;
    },
    isAiProhibitedItem() {
      return this.entity instanceof PostAiProhibitedContent;
    },
    isHandlingButtonVisible() {
      return !this.entity.isHandled && !this.$root.loggedInUser.isRestrictedViewer;
    },
    activityManagementStatus() {
      if (!this.entity.isHandled) {
        if (this.rule.action === RULE_ACTION.START || this.rule.action === RULE_ACTION.SCALING_UP) {
          return this.tr('The ad launch is awaiting your approval');
        }

        return this.tr('The ad stoppage is awaiting your approval');
      }

      if (this.entity.handled_by === null) {
        switch (this.entity.instruction) {
          case RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT:
            return this.tr('Accepted') + ' ' + this.tr('automatically');

          case RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT:
            return this.tr('Rejected') + ' ' + this.tr('automatically');
        }
      }

      switch (this.entity.instruction) {
        case RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT:
          return this.tr('by :name', { ':name': this.entity.handled_by.fullname });

        case RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT:
          return this.tr('by :name', { ':name': this.entity.handled_by.fullname });
      }

      return null;
    },
    activityManagementHandledAt() {
      if (!this.entity.handled_at) {
        return null;
      }

      let result = '';
      switch (this.entity.instruction) {
        case RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT:
          result += this.tr('Accepted');
          break;

        case RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT:
          result += this.tr('Rejected');
          break;
      }

      result += ' ' + timeAgo(this.entity.handled_at, this.tr);

      return result;
    },
    aiOverrideHandledAt() {
      if (!this.entity.handled_at) {
        return null;
      }

      return this.tr('Overrided') + ' ' + timeAgo(this.entity.handled_at, this.tr);
    },
    aiOverrideStatus() {
      if (!this.entity.isHandled) {
        return this.tr('The content does not comply with Meta\'s policy');
      }

      const name = this.entity.handled_by ? this.entity.handled_by.fullname : '???';

      return this.tr('Overrided') + ' ' + this.tr('by :name', { ':name': name });
    },
  },
  watch: {
    loadingButton(val) {
      this.$emit('loading-button', val);
    },
  },
  methods: {
    formatToYMD,
    formatTime,
    activityManagementAccept() {
      this.activityManagementInstruct(RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT);
    },
    activityManagementReject() {
      this.activityManagementInstruct(RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT);
    },
    activityManagementInstruct(instruction) {
      if (this.disabled) {
        return;
      }

      if (
        instruction !== RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT
        && instruction !== RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT
      ) {
        return;
      }
      this.loadingButton = this.entity.id + '--' + instruction;

      RuleActivityManagementService.instruct(this.rule.id, this.entity.id, instruction)
        .then(resp => {
          this.entity.updateWith(resp);

          this.$toast.clear();
          if (instruction === RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT) {
            this.$toast.success(this.tr('Activity accepted successfully. The activity will run in a few minutes.'));
            EventBus.$emit(EVENT_BUS_EVENTS.WAITING_ACTIVITY_ACCEPTED, resp);
          }
          else if (instruction === RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT) {
            this.$toast.success(this.tr('Activity rejected successfully.'));
            EventBus.$emit(EVENT_BUS_EVENTS.WAITING_ACTIVITY_REJECTED, resp);
          }

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.loadingButton = null);
    },
    overrideAi() {
      if (this.$root.isSupportMode) {
        return;
      }

      if (this.loadingButton !== null) {
        this.infoToast(this.tr('Please wait until the previous operation is completed.'));
        return;
      }

      this.loadingButton = this.entity.id + '--override';

      RuleAiManagementService.overrideResult(this.rule.id, this.entity)
        .then(resp => {
          this.entity.updateWith(resp);

          this.$toast.clear();
          this.$toast.success(this.tr('Decision successfully overrided. During the next run of the automation, the automation will attempt to create the advertisement.'));

          EventBus.$emit(EVENT_BUS_EVENTS.PROPROHIBITED_CONTENT_OVERRIDDEN, resp);

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.loadingButton = null);
    },
  },
};
</script>
