import SourceProfile from '@/entities/SourceProfile';
import api from '@/services/Api';

export default {
  connect(platform, id, country, chargeExtraAsset) {
    const data = {
      platform,
      id,
      country,
      charge_extra_assets: chargeExtraAsset,
    };
    return api().post('/source-profile', data)
      .then(resp => new SourceProfile(resp));
  },

  disconnect(sourceProfileId) {
    return api().delete('/source-profile/' + sourceProfileId);
  },

  get(id) {
    return api().get('/source-profile/' + id)
      .then(resp => new SourceProfile(resp));
  },

  update(entity) {
    return api().put('/source-profile/' + entity.id, entity.toObject())
      .then(resp => new SourceProfile(resp));
  },

  refresh(id) {
    return api().post('/source-profile/' + id + '/refresh');
  },

  changeStatus(id, active) {
    return api().put('/source-profile/' + id + '/status', { active });
  },

  generatePostAiScores(id) {
    return api().post('/source-profile/' + id + '/generate-post-ai-scores');
  },
};
