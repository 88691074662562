import Api from '@/services/Api';

import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';

export default {
  getList({ ruleId, status, handled_by, page, limit }) {
    const data = {};
    if (typeof status !== 'undefined') {
      data['status'] = status;
    }
    if (handled_by) {
      data['handled_by'] = handled_by;
    }
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/rule/' + ruleId + '/activity-management/list?' + query)
      .then((resp) => {
        return resp.map(i => new RuleActivityManagementQueueItem(i));
      });
  },

  instruct(ruleId, id, instruction) {
    return Api().put('/rule/' + ruleId + '/activity-management/' + id + '/' + instruction)
      .then((resp) => new RuleActivityManagementQueueItem(resp));
  }
};
