export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPcs(value) {
  if (value === null) {
    return this.tr('Unlimited') + ' ∞';
  }

  return value + ' ' + this.tr('pcs');
}
