var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--ai-mode rule-form-step--ai-mode--step-1",
    },
    [
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Your goal"),
            description: _vm.tr(
              "Depending on what you want to achieve with your ad, different settings will be the most optimal for running ads. By defining the goal, Infinite∞Ad will make these settings automatically."
            ),
          },
        },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "conversion" },
                domProps: { checked: _vm._q(_vm.goal, "conversion") },
                on: {
                  change: function ($event) {
                    _vm.goal = "conversion"
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have a webshop, or you sell products or services online"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "traffic" },
                domProps: { checked: _vm._q(_vm.goal, "traffic") },
                on: {
                  change: function ($event) {
                    _vm.goal = "traffic"
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have a website and you want to drive visitors there"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "app_promotion" },
                domProps: { checked: _vm._q(_vm.goal, "app_promotion") },
                on: {
                  change: function ($event) {
                    _vm.goal = "app_promotion"
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You have an application and you want it to achieve more results"
                    )
                  ) +
                  " "
              ),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.goal,
                    expression: "goal",
                  },
                ],
                attrs: { type: "radio", name: "goal", value: "engagement" },
                domProps: { checked: _vm._q(_vm.goal, "engagement") },
                on: {
                  change: function ($event) {
                    _vm.goal = "engagement"
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "You would like your Facebook or Instagram posts to reach more people and receive more reactions"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c("ad-account-block", {
        attrs: {
          entity: _vm.entity,
          description: _vm.tr(
            "You can choose the ad account in which you want to launch your advertisement. (This is useful if you manage multiple accounts.)"
          ),
        },
      }),
      _vm.conversionEventSettingsAvailable
        ? _c("conversion-odax-block", {
            attrs: {
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
              minimal: "",
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("campaign-budget-block", {
        attrs: {
          entity: _vm.entity,
          title: _vm.tr("Daily budget"),
          description: _vm.tr(
            "You can determine the maximum daily budget that you allocate for advertising."
          ),
          switchable: false,
          minimal: "",
        },
      }),
      _vm.scalingBudgetIsAvailable
        ? _c(
            "form-block",
            {
              attrs: {
                title:
                  _vm.tr("Daily budget for high-performing ads") +
                  " (" +
                  _vm.tr("optional") +
                  ")",
                description: _vm.tr(
                  "You can determine the maximum daily budget that you would allocate to those ads that have already proven to perform well."
                ),
              },
            },
            [
              _c(
                "div",
                [
                  _c("money-input", {
                    attrs: {
                      currency: _vm.entity.adAccountCurrency
                        ? _vm.entity.adAccountCurrency.code
                        : "EUR",
                      min: _vm.entity.adAccountMinDailyBugdet,
                    },
                    model: {
                      value: _vm.scalingBudget,
                      callback: function ($$v) {
                        _vm.scalingBudget = $$v
                      },
                      expression: "scalingBudget",
                    },
                  }),
                  _vm.entity.adAccountCurrency
                    ? _c("info-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tr("Minimum daily budget")) +
                            ": " +
                            _vm._s(_vm.entity.adAccountCurrency.symbol) +
                            " " +
                            _vm._s(
                              _vm.formatNumber(
                                _vm.entity.adAccountMinDailyBugdet
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c("dsa-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c("source-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode,
          single: "",
        },
      }),
      _vm.goal === "conversion"
        ? _c("form-block", [
            _c("div", [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(
                  " " + _vm._s(_vm.tr("Product or service sales link")) + " "
                ),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.cta_url,
                    expression: "entity.settings.cta_url",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.tr("Enter the URL you want to promote"),
                },
                domProps: { value: _vm.entity.settings.cta_url },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.entity.settings,
                      "cta_url",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "divider" }),
      _c(
        "div",
        [
          _c("demographic-target-block", {
            attrs: {
              title: _vm.tr("Target audience"),
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
            },
          }),
          _c("label", { staticClass: "input-label geotarget-label" }, [
            _vm._v(_vm._s(_vm.tr("Geotargeting"))),
          ]),
          _c("geotarget-block", {
            attrs: {
              entity: _vm.entity,
              disabled: _vm.$parent.readonlyMode,
              title: "",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "divider" }),
      _c("campaign-special-ad-categories-block", {
        attrs: { entity: _vm.entity, disabled: !_vm.entity.isNew },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }