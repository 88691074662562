import BaseEntity from '@/entities/BaseEntity';

export default class FacebookTargetingGeolocationRegion extends BaseEntity
{
  key = null;
  meta = {};

  constructor(rawData) {
    super();

    if ('type' in rawData) {
      delete rawData.type;
    }

    this.updateWith(rawData);
  }

  get type() {
    return 'region';
  }
}
