<template>
  <modal
    name="disconnect-confirm"
    class="disconnect-confirm-modal"
    overlayTransition="fade-modal-overlay"
  >
    <i
      class="icon close"
      @click="() => close()"
    />

    <form-block
      :title="tr('Disconnect Confirmation')"
      :description="modalDescription + ' ' + tr('Do you really want to disconnect it?')"
    >
      <new-sorted-table
        :data="defuncRules"
        :initial-sort="{ key: 'name', direction: 'asc' }"
      >
        <template #header>
          <table-head col="status" width="30px" class="status"/>
          <table-head col="name" sortable>{{ tr('Name') }}</table-head>
        </template>

        <template #default="{ sort }">
          <table-row
            v-for="row in sort"
            :key="row.id"
            :header-color="'rule-header--' + row.action"
          >
            <template #header>
              <i :class="'icon white ' + automationTypeIcon(row.action)" />
              {{ automationTypeLabel(row.action) }}
            </template>

            <table-data col="status" class="status">
              <div class="switch-wrapper">
                <switches v-model="row.active" disabled />
              </div>
            </table-data>
            <table-data col="name">
              {{ row.name }}
            </table-data>
          </table-row>
        </template>
      </new-sorted-table>

      <div class="buttons">
        <button :disabled="isLoading" @click="() => close()">
          {{ tr('Cancel') }}
        </button>
        <button class="red" :disabled="isLoading" @click="() => confirm()">
          <i v-if="isLoading" class="icon loader white" /> {{ tr('Confirm') }}
        </button>
      </div>
    </form-block>
  </modal>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import Switches from 'vue-switches';

export default {
  name: 'DisconnectConfirmModal',
  components: {
    FormBlock,
    Switches,
  },
  data: () => ({
    isLoading: false,
    connectedProfile: null,
    sourceProfile: null,
    adAccount: null,
    defuncRules: [],
    confirmCallback: null,
  }),
  computed: {
    modalDescription() {
      if (this.connectedProfile) {
        return this.tr('The following automations will become inactive with the disconnection of the following profile: :profileName.', {
          ':profileName': this.connectedProfile.name,
        });
      }

      let assetName = '';
      let assetTypeString = '';
      if (this.sourceProfile) {
        assetName = this.sourceProfile.name;
        switch (this.sourceProfile.platform) {
          case 'facebook':
            assetTypeString = this.tr('Facebook Page');
            break;

          case 'instagram':
            assetTypeString = this.tr('Instagram Account');
            break;
        }
      }
      else if (this.adAccount) {
        assetName = this.adAccount.name;
        assetTypeString = this.tr('Ad account');
      }

      return this.tr('The following automations will become inactive with the disconnection of the following asset: :assetName (:assetType).', {
        ':assetName': assetName,
        ':assetType': assetTypeString,
      });
    },
  },
  methods: {
    open({ connectedProfile, sourceProfile, adAccount }, defuncRules, confirmCallback) {
      this.connectedProfile = connectedProfile;
      this.sourceProfile = sourceProfile;
      this.adAccount = adAccount;

      this.defuncRules = defuncRules;
      this.confirmCallback = confirmCallback;

      this.$modal.show('disconnect-confirm');
    },
    close() {
      this.$modal.hide('disconnect-confirm');
    },
    confirm() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      this.confirmCallback()
        .then(() => this.close())
        .catch((err) => this.error(err))
        .finally(() => this.isLoading = false);
    },
    automationTypeIcon(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return 'blister';

        case 'start':
          return 'rocket';

        case 'scaling_up':
          return 'scaling-up';
      }

      return null;
    },
    automationTypeLabel(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return this.tr('automation_type_stop');

        case 'start':
          return this.tr('automation_type_start');

        case 'scaling_up':
          return this.tr('automation_type_scaling_up');
      }
    },
  }
};
</script>
