<template>
  <div class="protected-post-view list-view">
    <div class="preview">
      <div class="sticky-wrapper">
        <h3>
          {{ tr('Post preview') }}
        </h3>

        <div v-if="embed" v-html="embed" class="embed" />
        <div v-else class="embed-loader">
          <i class="icon loader light-black" />
        </div>
      </div>
    </div>

    <div class="listed-comments">
      <h3>{{ tr('Comments') }}</h3>

      <div class="actions">
        <input
          v-model="searchTerm"
          type="text"
          class="text"
          :placeholder="tr('Comment search')"
        />
      </div>

      <div v-if="isListEmpty && !hasMore && searchTerm" class="empty-text">
        {{ tr('There are no results matching your search criteria.') }}
      </div>
      <div v-else-if="isListEmpty && !hasMore && !searchTerm" class="empty-text">
        {{ tr('There are no comments under this post.') }}
      </div>

      <new-sorted-table
        v-else-if="!isListEmpty"
        :data="commentList"
      >
        <template #header>
          <table-head col="author" />
          <table-head col="posted" class="posted-column" width="120px">{{ tr('Posted') }}</table-head>
          <table-head col="classification" class="classification-column" width="150px">{{ tr('Classification') }}</table-head>
          <table-head col="moderated" class="moderated-by-ai-column" width="68px">{{ tr('Moterated') }}</table-head>
          <table-head col="actions" v-if="!$root.loggedInUser.isRestrictedViewer" width="30px" />
        </template>

        <template #default="{ sort }">
          <table-row v-for="comment in sort" :key="comment.id">
            <table-data col="author">
              <div class="author">{{ comment.author.name }}</div>
              <div class="comment">{{ comment.shortMessage }}</div>
            </table-data>
            <table-data col="posted" class="posted-column">
              <span v-tooltip="formatToYMD(comment.created_at) + ', ' + formatTime(comment.created_at)">
                {{ timeAgo(comment.created_at, tr) }}
              </span>
            </table-data>
            <table-data col="classification" class="classification-column">
              <template v-if="!commentIsResultIsVisible(comment)">
                -
              </template>
              <ai-result v-else :status="comment.ai_status" :result="comment.ai_result" />
            </table-data>
            <table-data col="moderated" class="moderated-by-ai-column">
              {{ moderatedBeautify(comment.is_hidden_by_ai) }}
            </table-data>
            <table-data col="actions" v-if="!$root.loggedInUser.isRestrictedViewer">
              <div class="action-group">
                <i
                  v-if="isLoadingComment === comment.id"
                  class="icon loader light-black"
                />
                <i
                  v-else-if="!comment.is_hidden"
                  :class="'icon hide light-black ' + (isLoadingComment !== null ? 'disabled': '')"
                  v-tooltip="tr('Hide comment')"
                  @click="() => hideComment(comment)"
                />
                <i
                  v-else
                  :class="'icon restore light-black ' + (isLoadingComment !== null ? 'disabled': '')"
                  v-tooltip="tr('Restore comment')"
                  @click="() => restoreComment(comment)"
                />
              </div>
            </table-data>
          </table-row>
        </template>
      </new-sorted-table>

      <div v-if="hasMore" ref="loadMore" class="load-more">
        <i class="icon loader" /> {{ tr('Loading...') }}
      </div>
    </div>
  </div>
</template>

<script>
import AiResult from '@/components/AiResult.vue';

import CommentService from '@/services/Comment.js';
import PostService from '@/services/Post.js';

import debounce from '@/utils/debounce.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';

const ITEM_PER_PAGE = 20;

export default {
  name: 'ProtectedPostView',
  components: {
    AiResult,
  },
  data() {
    return {
      post: null,
      embed: null,
      commentList: [],
      page: 1,
      hasMore: true,
      searchTerm: '',
      searchTermUpdater: debounce(() => this.getComments(), 300),
      isLoadingComment: null,
    };
  },
  computed: {
    postId() {
      return this.$route.params.id;
    },
    isListEmpty() {
      return this.commentList.length === 0;
    },
  },
  created() {
    this.getPost().then(() => this.getEmbed());
    this.getComments();
  },
  watch: {
    'searchTerm'() {
      this.resetPage();
      this.searchTermUpdater();
    },
  },
  methods: {
    formatTime,
    formatToYMD,
    timeAgo,
    resetPage() {
      this.commentList = [];
      this.hasMore = true;
      this.page = 1;
    },
    getPost() {
      return PostService.get(this.postId)
        .then(resp => this.post = resp)
        .catch(err => this.error(err));
    },
    getEmbed() {
      let parser = null;

      switch (this.post.platform) {
        case 'facebook':
          this.$store.dispatch('app/loadFbSdk');
          parser = 'window.FB.XFBML.parse()';
          break;

        case 'instagram':
          this.$store.dispatch('app/loadIgSdk');
          parser = 'window.instgrm.Embeds.process()';
          break;

        default:
          return;
      }

      parser = `(function runOembedParser() {
        try { ${parser} }
        catch (err) {
          console.log(err);
          setTimeout(() => runOembedParser(), 100);
        }
      })()`;

      return PostService.getEmbed(this.postId)
        .then(resp => this.embed = resp.embed)
        .then(() => eval(parser))
        .catch(err => this.error(err));
    },
    getComments() {
      return PostService.getComments(this.postId, {
        term: this.searchTerm,
        limit: ITEM_PER_PAGE,
        page: this.page,
      })
      .then(resp => {
        this.hasMore = resp.length === ITEM_PER_PAGE;
        this.commentList.push(...resp);
      })
      .catch(e => {
        this.error(e);
        this.hasMore = false;
      })
      .finally(() => {
        if (this.hasMore) {
          this.initInfiniteScroll();
        }
      })
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.page++;
          this.getComments();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    commentIsResultIsVisible(comment) {
      if (comment.ai_status > 0) {
        return true;
      }

      return this.post !== null && this.post.comment_moderation_after <= comment.created_at;
    },
    moderatedBeautify(status) {
      return status ? this.tr('Yes') : '-';
    },
    hideComment(comment) {
      this.isLoadingComment = comment.id;

      CommentService.hide(comment)
        .then(resp => comment.updateWith(resp))
        .catch(err => this.error(err))
        .finally(() => this.isLoadingComment = null);
    },
    restoreComment(comment) {
      this.isLoadingComment = comment.id;

      CommentService.unhide(comment)
        .then(resp => comment.updateWith(resp))
        .catch(err => this.error(err))
        .finally(() => this.isLoadingComment = null);
    },
  },
};
</script>
