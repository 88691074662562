var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "communicator-debug-component",
      attrs: { title: _vm.title },
    },
    [
      _c(
        "div",
        { staticClass: "request" },
        [
          _c("v-select", {
            attrs: {
              options: _vm.availableMethods,
              loading: _vm.availableMethods.length === 0,
              disabled: _vm.availableMethods.length === 0,
              clearable: false,
              searchable: false,
            },
            scopedSlots: _vm._u([
              {
                key: "selected-option",
                fn: function (option) {
                  return [
                    _c("div", [
                      _vm.showStatusIcon
                        ? _c("i", { class: _vm.statusIconClasses })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(option.label) + " "),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedMethod,
              callback: function ($$v) {
                _vm.selectedMethod = $$v
              },
              expression: "selectedMethod",
            },
          }),
          _c(
            "button",
            {
              staticClass: "primary small",
              attrs: { disabled: _vm.isLoading },
              on: { click: () => _vm.sendCommunicatorRequest() },
            },
            [_vm._v(" " + _vm._s(_vm.tr("Send")) + " ")]
          ),
        ],
        1
      ),
      _vm.selectedMethod !== null
        ? _c("div", { staticClass: "main-block" }, [
            _c(
              "div",
              { staticClass: "param-inputs" },
              [
                Object.keys(_vm.selectedMethod.params).length === 0
                  ? _c("div", { staticClass: "has-no-params" }, [
                      _vm._v(
                        " " + _vm._s(_vm.tr("No params for this method")) + " "
                      ),
                    ])
                  : _vm._l(_vm.selectedMethod.params, function (param, key) {
                      return _c(
                        "div",
                        { key: key },
                        [
                          param.type === "bool"
                            ? [
                                _c("label", { staticClass: "checkbox-label" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: param.value,
                                        expression: "param.value",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: param.default
                                        ? "Default: " + param.default
                                        : "",
                                      type: "checkbox",
                                      disabled: _vm.isLoading,
                                    },
                                    domProps: {
                                      checked: Array.isArray(param.value)
                                        ? _vm._i(param.value, null) > -1
                                        : param.value,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = param.value,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                param,
                                                "value",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                param,
                                                "value",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(param, "value", $$c)
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(key) +
                                      _vm._s(param.default ? "" : "*") +
                                      " "
                                  ),
                                ]),
                              ]
                            : [
                                _c("label", { staticClass: "input-label" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(key) +
                                      _vm._s(param.default ? "" : "*") +
                                      " "
                                  ),
                                ]),
                                param.type === "int"
                                  ? _c("number-input", {
                                      attrs: {
                                        placeholder: param.default
                                          ? "Default: " + param.default
                                          : "",
                                        disabled: _vm.isLoading,
                                      },
                                      model: {
                                        value: param.value,
                                        callback: function ($$v) {
                                          _vm.$set(param, "value", $$v)
                                        },
                                        expression: "param.value",
                                      },
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: param.value,
                                          expression: "param.value",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: param.default
                                          ? "Default: " + param.default
                                          : "",
                                        disabled: _vm.isLoading,
                                        type: "text",
                                      },
                                      domProps: { value: param.value },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            param,
                                            "value",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                              ],
                        ],
                        2
                      )
                    }),
                _c("div", { staticClass: "spacer" }),
                _vm.showAccessTokenButton && _vm.$root.supportHasL3Access
                  ? _c("div", { staticClass: "access-token-block" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(" " + _vm._s(_vm.accessTokenEntityName) + " "),
                      ]),
                      _vm.accessToken
                        ? _c("div", { staticClass: "access-token-container" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.accessToken,
                                  expression: "accessToken",
                                },
                              ],
                              ref: "accessTokenInput",
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: _vm.accessToken },
                              on: {
                                click: () =>
                                  _vm.selectAccessTokenInputContent(),
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.accessToken = $event.target.value
                                },
                              },
                            }),
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.showCheckmark
                                    ? _vm.tr("Copied")
                                    : _vm.tr("Copy to clipboard"),
                                  expression:
                                    "showCheckmark ? tr('Copied') : tr('Copy to clipboard')",
                                },
                              ],
                              staticClass: "icon",
                              class: _vm.copyIconName,
                              on: {
                                click: () =>
                                  _vm.copyToClipboard(_vm.accessToken),
                              },
                            }),
                          ])
                        : _c(
                            "button",
                            {
                              staticClass: "primary small",
                              attrs: { disabled: _vm.isAccessTokenLoading },
                              on: { click: () => _vm.sendAccessTokenRequest() },
                            },
                            [
                              _vm.isAccessTokenLoading
                                ? _c("i", { staticClass: "icon loader white" })
                                : _c("i", { staticClass: "icon key white" }),
                              _vm._v(
                                " " + _vm._s(_vm.tr("Get Access Token")) + " "
                              ),
                            ]
                          ),
                      _c(
                        "button",
                        {
                          staticClass: "secondary small log",
                          on: {
                            click: function ($event) {
                              return _vm.openTokenRequestLog()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "icon diary" }),
                          _vm._v(
                            " " + _vm._s(_vm.tr("Token Request Log")) + " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "response-wrapper" },
              [
                _vm.response !== null
                  ? _c("vue-json-pretty", {
                      attrs: {
                        data: _vm.response.error ?? _vm.response.response,
                        deep: 3,
                        "show-line-number": "",
                        "show-icon": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }