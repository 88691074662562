var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    class: { skeleton: true, light: _vm.light },
    style: _vm.wrapperStyle,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }