const KEY_UTM_SOURCE = 'utmSource';
const KEY_COUPON_CODE = 'couponCode';

export function saveUrlParameters() {
  saveUtmSourceParameter();
  saveCouponCode();
}

export function saveUtmSourceParameter() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('utm_source')) {
    const utmSourceValue = urlParams.get('utm_source');
    window.sessionStorage.setItem(KEY_UTM_SOURCE, utmSourceValue);
  }
}

export function getUtmSource() {
  return window.sessionStorage.getItem(KEY_UTM_SOURCE);
}

export function saveCouponCode() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('coupon')) {
    const couponCode = urlParams.get('coupon');
    window.localStorage.setItem(KEY_COUPON_CODE, couponCode);
  }
}

export function getCouponCode() {
  return window.localStorage.getItem(KEY_COUPON_CODE);
}

export function clearCouponCode() {
  window.localStorage.removeItem(KEY_COUPON_CODE);
}
