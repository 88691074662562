<template>
  <div :class="'condition-group-wrapper--level-' + level">
    <div v-if="value && value.items" :class="wrapperClasses">
      <condition-connector
        v-model="value.logic"
        :item-count="value.items.length"
        :allow-add-new-group="level < 4"
        :disabled="disabled"
        @open-dropdown="() => onOpenDropdown()"
        @close-dropdown="() => onCloseDropdown()"
        @add-item="addItem"
        @add-group="addGroup"
      />

      <div class="items">
        <template v-for="(item, key) in value.items">
          <condition-group
            v-if="!!item.logic"
            v-model="value.items[key]"
            :key="'level-' + level + '-item-' + key + '-first'"
            :level="level + 1"
            :disabled="disabled"
            :action="action"
            :platforms="platforms"
            :ad-account="adAccount"
            @open-dropdown="() => onOpenDropdown()"
            @close-dropdown="() => onCloseDropdown()"
          />
          <condition-row
            v-else
            v-model="value.items[key]"
            :key="'level-' + level + '-item-' + key + '-second'"
            :action="action"
            :platforms="platforms"
            :ad-account="adAccount"
            :removable="level !== 1 || value.items.length !== 1"
            :disabled="disabled"
            :subfields="item.field in subfields ? subfields[item.field] : []"
            :loadingSubfields="loadingSubfields === item.field || !(item.field in subfields)"
            @loadSubfields="() => loadSubfields(item.field)"
            @remove="() => removeItem(key)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ConditionConnector from '@/components/RuleForm/Condition/ConditionConnector';
import ConditionRow from '@/components/RuleForm/Condition/ConditionRow';

import AdAccount from '@/entities/AdAccount';

import AppService from '@/services/App.js';

export default {
  name: 'condition-group',
  components: {
    ConditionConnector,
    ConditionRow,
  },
  props: {
    value: {
      required: true,
    },
    action: {
      type: String,
      required: false,
    },
    platforms: {
      type: Array,
      required: false,
      default: () => ['facebook', 'instagram'],
    },
    level: {
      type: Number,
      required: false,
      default: () => 1,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    adAccount: {
      type: AdAccount,
      required: false,
    },
  },
  data: () => ({
    hasOpenDropdown: false,
    loadingSubfields: null,
    subfields: {},
  }),
  computed: {
    wrapperClasses() {
      return {
        'condition-group-component': true,
        'scrollable': this.level === 1 && !this.hasOpenDropdown,
      };
    },
  },
  watch: {
    adAccount() {
      let loadedSubfields = Object.keys(this.subfields);

      this.subfields = {};

      loadedSubfields.forEach(field => this.loadSubfields(field));
    },
    'value.items': {
      handler() {
        if (this.value.items.indexOf(null) !== -1) {
          let newValue = JSON.parse(JSON.stringify(this.value));
          newValue.items = newValue.items.filter(item => item !== null);

          this.$emit('input', newValue);
        }
      }
    },
  },
  created() {
    if (!this.value || !this.value.items) {
      this.$emit('input', JSON.parse(JSON.stringify(INITIAL_VALUE)));
    }
  },
  methods: {
    addItem() {
      let newValue = JSON.parse(JSON.stringify(this.value));
      newValue.items.push(JSON.parse(JSON.stringify(EMPTY_ITEM)));

      this.$emit('input', newValue);
    },
    addGroup() {
      let newValue = JSON.parse(JSON.stringify(this.value));
      newValue.items.push(JSON.parse(JSON.stringify(EMPTY_GROUP)));

      this.$emit('input', newValue);
    },
    loadSubfields(field) {
      if (field in this.subfields) {
        return;
      }

      this.loadingSubfields = field;
      AppService.getDynamicSubfields(field, this.adAccount.id)
        .then(fields => this.subfields[field] = fields)
        .catch((err) => this.error(err))
        .finally(() => this.loadingSubfields = null);
    },
    removeItem(key) {
      if (this.value.items.length === 1) {
        this.$emit('input', null);
      }
      else {
        let newValue = JSON.parse(JSON.stringify(this.value));
        newValue.items.splice(key, 1);

        this.$emit('input', newValue);
      }
    },
    onOpenDropdown() {
      this.hasOpenDropdown = true;
      this.$emit('open-dropdown');
    },
    onCloseDropdown() {
      this.hasOpenDropdown = false;
      this.$emit('close-dropdown');
    },
    validate() {
      for (let child of this.$children) {
        if (typeof child.validate !== 'function') {
          continue;
        }

        if (!child.validate()) {
          return false;
        }
      }

      return true;
    },
  }
};

const EMPTY_ITEM = {
  field: '',
  subfield: null,
  date_period: null,
  comparison: '',
  value: '',
  settings: {
    dynamic_operator_direction: null,
    dynamic_operator_area: null,
  },
};

const EMPTY_GROUP = {
  logic: 'AND',
  items: [ EMPTY_ITEM, EMPTY_ITEM ],
};

const INITIAL_VALUE = {
  logic: 'AND',
  items: [ EMPTY_ITEM ],
};
</script>
