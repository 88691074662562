var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.parentElement,
    { tag: "component", class: _vm.classes, attrs: { to: _vm.link } },
    [
      _c("div", { staticClass: "content" }, [
        _c("i", { class: "icon white " + _vm.computedIcon }),
        _c("div", {
          staticClass: "text",
          domProps: { innerHTML: _vm._s(_vm.$slots.default[0].text) },
        }),
      ]),
      _vm.computedLink
        ? _c(
            "router-link",
            { staticClass: "link", attrs: { to: _vm.computedLink } },
            [_vm._v(" " + _vm._s(_vm.tr(_vm.buttonLabel)) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }