var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-form-view login-form-view" },
    [
      _c(
        "form",
        {
          staticClass: "form",
          on: { submit: ($event) => _vm.onFormSubmit($event) },
        },
        [
          _c("div", { staticClass: "input-label" }, [
            _c("span", [_vm._v(_vm._s(_vm.tr("E-mail")))]),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email",
              },
            ],
            attrs: { type: "email", placeholder: _vm.tr("you@gmail.com") },
            domProps: { value: _vm.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.email = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "input-label" }, [
            _c("span", [_vm._v(_vm._s(_vm.tr("Password")))]),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password",
              },
            ],
            attrs: {
              type: "password",
              placeholder: _vm.tr("Enter at least 8 characters..."),
            },
            domProps: { value: _vm.password },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.password = $event.target.value
              },
            },
          }),
          _c(
            "button",
            { staticClass: "primary", attrs: { type: "submit" } },
            [
              _vm.isLoading
                ? _c("i", {
                    staticClass: "icon loader white",
                    attrs: {
                      loading: _vm.isLoading,
                      color: "#ffffff",
                      size: "6px",
                    },
                  })
                : [_vm._v(" " + _vm._s(_vm.tr("Login")) + " ")],
            ],
            2
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "link", attrs: { to: "/auth/forgotten-password" } },
        [_c("u", [_vm._v(_vm._s(_vm.tr("Forgot password?")))])]
      ),
      _c(
        "router-link",
        { staticClass: "link", attrs: { to: "/auth/signup" } },
        [
          _c("u", [
            _vm._v(
              _vm._s(
                _vm.tr("Don't have an account? Try it now for 7 days for free!")
              )
            ),
          ]),
        ]
      ),
      _c(
        "modal",
        { staticClass: "twofa-modal", attrs: { name: "google-2fa-modal" } },
        [
          _c("google-2fa-code-form", {
            attrs: {
              title: _vm.tr("Two-factor authentication"),
              "cancel-button-label": _vm.tr("Cancel"),
              "is-loading": _vm.isLoading,
              "trust-option": "",
            },
            on: {
              submit: ({ trust }) => _vm.onFormSubmit(null, trust),
              cancel: () => _vm.$modal.hide("google-2fa-modal"),
            },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }