var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      class: { "three-state-checkbox-component": true, disabled: _vm.disabled },
      on: { click: () => _vm.toggle() },
    },
    [_c("i", { class: _vm.iconClasses }), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }