<template>
  <form-block
    class="rule-form-block source-block-component"
    :title="tr('Source')"
    :description="tr('Please choose which Facebook and/or Instagram account you want to create ads from. You can only select the ones that are linked to the above ad account in the Meta Business Manager.')"
  >
    <div v-if="!entity.ad_account.id" class="blocker-text">
      {{ tr('Please, select an ad account.') }}
    </div>
    <info-text v-else-if="availableSources.length === 0" type="error">
      {{ tr('No data sources are available for the selected advertising account.') }}
    </info-text>
    <template v-else>
      <info-text v-if="entity.isAppPromo">
        {{ tr('When advertising an application, only an Instagram account can be used as a source.') }}
      </info-text>
      <source-select
        :entity="entity"
        :sources="availableSources"
        :disabled="disabled"
        :disabled-facebook="entity.isAppPromo"
        :limit="single ? 1 : null"
      />
    </template>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

import InfoText from '@/components/InfoText.vue';
import SourceSelect from '@/components/RuleForm/SourceSelect.vue';

export default {
  name: 'PostTypeBlock',
  mixins: [ RuleFormBlockMixin ],
  components: {
    InfoText,
    SourceSelect,
  },
  props: {
    single: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    facebookPostTypes() {
      return this.$store.getters['app/facebookPostTypes'];
    },
    facebookPostMediaTypes() {
      return this.$store.getters['app/facebookPostMediaTypes'];
    },
    instagramPostTypes() {
      return this.$store.getters['app/instagramPostTypes'];
    },
    instagramPostMediaTypes() {
      return this.$store.getters['app/instagramPostMediaTypes'];
    },
    availableSources() {
      if (!this.entity.selectedAdAccount) {
        return [];
      }

      const company = this.$root.currentCompany;
      return company.source_profiles.filter(sourceProfile => sourceProfile.owner.id === this.entity.selectedAdAccount.owner.id);
    },
  },
  watch: {
    availableSources() {
      const sourceIdList = this.availableSources.map(item => item.id);
      this.entity.source_profiles = this.entity.source_profiles.filter(i => sourceIdList.includes(i.id));

      const availableSourceIds = this.availableSources.map(item => item.id);
      const newSelectedSources = this.entity.source_profiles.filter(item => availableSourceIds.includes(item.id));

      if (newSelectedSources.length === 0 && this.availableSources.length === 1) {
        newSelectedSources.push(this.availableSources[0]);
      }

      if (newSelectedSources.length !== this.entity.source_profiles.length) {
        this.entity.source_profiles = newSelectedSources;
      }
    },
    'entity.isAppPromo'(val) {
      if (val) {
        this.entity.source_profiles = this.entity.source_profiles.filter(i => !i.platform || i.platform === 'instagram');
      }
    },
  },
  methods: {
    validate() {
      if (this.entity.source_profiles.length > 1) {
        let hasValidSource = false;
        let hasInvalidSource = false;
        for (let item of this.entity.source_profiles) {
          if (item.id) {
            hasValidSource = true;
          }
          else {
            hasInvalidSource = true;
          }
        }

        if (hasValidSource && hasInvalidSource) {
          this.entity.source_profiles = this.entity.source_profiles.filter(item => item.id);
        }
      }

      for (let item of this.entity.source_profiles) {
        if (!item.id) {
          this.error(this.tr('At least one source must be selected for each platform!'));
          return false;
        }
      }

      return true;
    },
  },
};
</script>
