import('./assets/scss/styles.scss');

/* IMPORT SCRIPTS */
import Vue from 'vue';
import { router } from '@/router';
import { store } from '@/store';

import VModal from 'vue-js-modal';
Vue.use(VModal);

import vSelect from 'vue-select';
import selectPositionCalculator from '@/utils/selectPositionCalculator.js';
vSelect.props.calculatePosition.default = selectPositionCalculator;
vSelect.props.dropdownShouldOpen.default = function ({noDrop, open, mutableLoading}) {
  const isOpen = noDrop ? false : open && !mutableLoading;
  if (!isOpen && this.$refs.search) {
    this.$nextTick(() => this.$refs.search.blur());
  }

  return isOpen;
};
vSelect.props.appendToBody.default = true;
vSelect.props.getOptionLabel.default = function (option) {
  if (
    typeof option === 'object'
    && option !== null
    && !Array.isArray(option)
  ) {
    let label = this.label || 'label';
    if (label in option) {
      return this.tr(option[label]);
    }
  }

  return option;
}
Vue.component('v-select', vSelect);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);

import SortedTablePlugin from 'vue-sorted-table';
Vue.use(SortedTablePlugin);

require('@/components/sorted-table/register.js');

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.component('vue-toast', VueToast);
Vue.use(VueToast, {
  position: 'bottom-left',
  duration: 10000,
  dismissible: true,
});

import ConfirmationModal from '@/plugins/confirmation';
Vue.use(ConfirmationModal);

import Translator from '@/mixins/Translator';
Vue.mixin(Translator);

import ConditionalFilteredList from '@/mixins/ConditionalFilteredList';
Vue.mixin(ConditionalFilteredList);

import ErrorNotifier from '@/mixins/ErrorNotifier';
Vue.mixin(ErrorNotifier);

import AccessHandledRouterLink from '@/components/AccessHandledRouterLink';
Vue.component('AccessHandledRouterLink', AccessHandledRouterLink);

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import App from './App.vue';

Vue.config.productionTip = false;

Vue.prototype.$store = store;
Vue.prototype.$app = {
  mode: process.env.VUE_APP_MODE,
  isAd: process.env.VUE_APP_MODE === 'ad',
  isBuzz: process.env.VUE_APP_MODE === 'buzz',
};

const appInstance = new Vue({
  router,
  store,
  data: () => ({
    screenWidth: window.innerWidth,
  }),
  computed: {
    language() {
      return store.getters['app/language'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
    loggedInUser() {
      return store.getters['auth/user'];
    },
    currentCompany() {
      return store.getters['auth/company'];
    },
    isInOriginalCompany() {
      return this.loggedInUser.company.id === this.currentCompany.id;
    },
    isSupportMode() {
      return process.env.VUE_APP_SUPPORT_BUILD == 1;
    },
  },
  created() {
    this.updateScreenWidth();
    window.addEventListener('resize', () => this.screenWidth = window.innerWidth);

    if (this.isSupportMode) {
      const urlParams = new URLSearchParams(window.location.search);
      const userId = urlParams.get('user_id');

      if (userId) {
        this.$store.commit('support/setAutoLoginUserId', userId);

        if (this.$root.isLoggedIn) {
          this.$store.dispatch('auth/logout');
        }
      }
    }
  },
  mounted() {
    this.updateScreenWidth();
    setTimeout(() => this.updateScreenWidth(), 250);

    this.initInputAutoTrimmer();
  },
  methods: {
    initInputAutoTrimmer() {
      let observer = new MutationObserver((mutations) => {
        mutations.forEach(mutation => {
          if (mutation.addedNodes.length === 0) {
            return;
          }

          mutation.addedNodes.forEach(node => {
            if (!(node instanceof Element)) {
              return;
            }

            if (node instanceof HTMLInputElement) {
              node.removeEventListener('blur', this.inputAutoTrim);
              node.addEventListener('blur', this.inputAutoTrim);
            }
            else {
              const inputs = node.querySelectorAll('input');
              inputs.forEach(input => {
                input.removeEventListener('blur', this.inputAutoTrim);
                input.addEventListener('blur', this.inputAutoTrim);
              });
            }
          });
        });
      });

      observer.observe(document.body, { subtree: true, childList: true });
    },
    inputAutoTrim(e) {
      const ariaControlsValue = e.target.getAttribute('aria-controls');
      if (typeof ariaControlsValue === 'string' && ariaControlsValue.indexOf('autosuggest') !== -1) {
        return;
      }

      this.$nextTick(() => {
        e.target.value = e.target.value.trim();
        e.target.dispatchEvent(new Event('input', { bubbles: true }));
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    fetchUserData() {
      this.$store.commit('auth/isFetching', true);

      return this.$store.dispatch('auth/fetch')
        .finally(() => this.$nextTick(() => this.$store.commit('auth/isFetching', false)));
    },
    fetchSubscriptionData() {
      return this.$store.dispatch('auth/fetchSubscription');
    },
    fetchSupportUserData() {
      this.$store.commit('support/isFetching', true);

      return this.$store.dispatch('support/fetch')
        .finally(() => this.$nextTick(() => this.$store.commit('support/isFetching', false)));
    },
  },
  render: (h) => h(App),
}).$mount('#app');

import { ValidationError } from '@/errors';
ValidationError.appInstance = appInstance;

if (process.env.NODE_ENV !== 'production') {
  window.AppInstance = appInstance;
}

export const AppInstance = appInstance;

import * as Sentry from '@sentry/vue';
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'beta') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/app\.infinite\.ad/,
      /^https:\/\/beta\.app\.8ad\.io/,
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
