<template>
  <div class="rule-form-view">
    <transition name="fade--fast">
      <div v-if="isLoading" class="loader">
        <i class="icon loader black" />
      </div>
    </transition>

    <template v-if="!isLoading">
      <sticky-header :title="stickyHeaderTitle" :bordered="maxStepNumber <= 1">
        <template #buttons>
          <router-link :to="cancelButtonLink" class="button bordered gray">
            <i class="icon arrow-left" />
            {{ tr('Cancel') }}
          </router-link>
          <button
            class="primary"
            :loading="isSaving"
            :disabled="$root.isSupportMode && isLastStep"
            @click="() => nextButtonAction()"
          >
            <i v-if="isSaving" class="icon loader white" />
            <template v-else>
              {{ stepButtonLabel }}
            </template>
          </button>
        </template>

        <form-step-tab
          v-if="maxStepNumber > 1"
          v-model="step"
          :items="formStepTabs"
          :allowed-index="allowedStep - 1"
        />
      </sticky-header>

      <component :is="renderedView" ref="formStep" />
    </template>
  </div>
</template>

<script>
import FormStepTab from '@/components/FormStepTab.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import RuleService from '@/services/Rule.js';

export default {
  name: 'RuleFormView',
  components: {
    FormStepTab,
    StickyHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isSaving) {
      this.infoToast(this.tr('Please wait until the save is complete'));
      next(false);
      return;
    }

    this.$store.commit('ruleForm/reset');

    next();
  },
  data: () => ({
    isSaving: false,
    isLoading: false,
    skipHashChangeWatcher: false,
  }),
  computed: {
    id() {
      return this.$route.params.id ?? null;
    },
    entity() {
      return this.$store.getters['ruleForm/entity'];
    },
    isNew() {
      return this.$route.path.indexOf('/new/') !== -1;
    },
    isDuplicate() {
      return !this.isNew && this.$route.path.endsWith('/duplicate');
    },
    isAiMode() {
      return this.$store.getters['ruleForm/isAiMode'];
    },
    readonlyMode() {
      return this.$root.loggedInUser.level === 'restricted_viewer';
    },
    action() {
      if (!this.entity) {
        return null;
      }

      let { action } = this.entity; // .replace('_', '-');
      if (action === 'delete' || action === 'smart_stop') {
        action = 'stop';
      }

      return action;
    },
    formStepTabs() {
      return this.$store.getters['ruleForm/formStepTabs'];
    },
    maxStepNumber() {
      return this.$store.getters['ruleForm/maxStepNumber'];
    },
    step() {
      return this.$store.getters['ruleForm/step'];
    },
    allowedStep() {
      return this.$store.getters['ruleForm/allowedStep'];
    },
    isLastStep() {
      return this.step >= this.maxStepNumber;
    },
    renderedView() {
      return this.$store.getters['ruleForm/renderedView'];
    },
    stickyHeaderTitle() {
      if (this.isNew) {
        return this.tr('Create new :action automation', { ':action': this.tr('automation_type_' + this.action) });
      }
      if (this.isDuplicate) {
        return this.tr('Duplicate :action automation', { ':action': this.tr('automation_type_' + this.action) });
      }

      return this.tr('Edit :action automation', { ':action': this.tr('automation_type_' + this.action) });
    },
    cancelButtonLink() {
      if (this.isNew) {
        return '/automation';
      }

      return '/automation/' + this.id;
    },
    stepButtonLabel() {
      if (this.step !== this.maxStepNumber) {
        return this.tr('Next');
      }

      if (this.isNew || this.isDuplicate) {
        return this.tr('Create');
      }

      if (this.readonlyMode) {
        return this.tr('Close');
      }

      return this.tr('Save');
    },
  },
  watch: {
    '$route.hash'(val, oldVal) {
      if (this.skipHashChangeWatcher) {
        this.skipHashChangeWatcher = false;
        return;
      }

      this.updateStepByUrlHash(val, oldVal);
    },
    async step() {
      await this.$nextTick();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    'entity.ad_account.id'(adAccountId) {
      if (this.action !== 'start') {
        return;
      }

      if (!adAccountId) {
        this.$store.commit('ruleForm/setAudiences', []);
        return;
      }

      this.$store.dispatch('ruleForm/fetchAudiences', adAccountId)
        .catch(err => this.error(err));
    },
    'entity.bidAmountIsAvailable'(val) {
      if (!val) {
        this.entity.settings.adset_bid_amount = null;
      }
    },
    'entity.isRoasGoalAvailable'(val) {
      if (!val) {
        this.entity.settings.adset_roas_goal = null;
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      const initParams = {};
      if (this.isNew) {
        const actionInUrl = this.$route.params.action;
        const isAiMode = actionInUrl === 'ai-mode';

        const allowedActions = this.$store.getters['ruleForm/allowedActions'];
        if (!allowedActions.includes(actionInUrl) && !isAiMode) {
          this.$router.replace('/automation');
          return;
        }

        initParams.isNew = true;
        initParams.isAiMode = isAiMode;
        initParams.action = isAiMode ? 'start' : actionInUrl;
      }
      else {
        initParams.id = this.id;
        initParams.isDuplicate = this.isDuplicate;
      }

      this.$store.dispatch('ruleForm/init', initParams)
        .then(resp => {
          if (this.isNew) {
            this.$router.replace({ hash: 'step-1' });
          }
          else {
            this.updateStepByUrlHash();
          }

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    updateStepByUrlHash(val, oldVal) {
      const regex = /^#step-\d$/;
      if (!regex.test(this.$route.hash)) {
        this.$router.replace({ hash: 'step-' + this.step });
        return;
      }

      if (regex.test(oldVal) && val) {
        if (val > oldVal && !this.$refs.formStep.validate()) {
          this.skipHashChangeWatcher = true;
          this.$router.replace({ hash: oldVal });
          return;
        }
      }

      const stepInHash = this.$route.hash.replace('#step-', '');
      let newStepValue = parseInt(stepInHash);
      if (isNaN(newStepValue)) {
        newStepValue = 1;
      }

      if (this.step === this.allowedStep && this.step + 1 === newStepValue) {
        this.$store.commit('ruleForm/increaseAllowedStep');
      }

      this.$store.dispatch('ruleForm/goToStep', newStepValue)
        .then(success => {
          if (success) {
            return;
          }

          if (regex.test(oldVal)) {
            this.$router.replace({ hash: oldVal });
          }
          else {
            this.$router.replace({ hash: 'step-1' });
          }

          return;
        })
        .catch(err => this.error(err));
    },
    nextButtonAction() {
      if (!this.$refs.formStep.validate()) {
        return;
      }

      if (!this.isLastStep) {
        this.$router.push({ hash: 'step-' + (this.step + 1) });
        return;
      }

      if (this.readonlyMode) {
        this.$router.push('/automation');
      }
      else {
        this.save();
      }
    },
    async save() {
      this.isSaving = true;

      let savePromise;

      const isCreate = this.isNew || this.isDuplicate;
      if (typeof this.$refs.formStep.save === 'function') {
        savePromise = this.$refs.formStep.save();
      }
      else if (this.isAiMode) {
        savePromise = RuleService.createAiMode(
          this.entity,
          this.$store.getters['ruleForm/goal'],
          this.$store.getters['ruleForm/scalingBudget'],
          this.$store.getters['ruleForm/aiAssist'],
        );
      }
      else if (isCreate) {
        savePromise = RuleService.create(this.entity);
      }
      else {
        savePromise = RuleService.update(this.entity);
      }

      savePromise
        .then(resp => {
          const keepTermsOnListView = !this.isNew && !this.isDuplicate;
          this.$store.dispatch('rules/resetView', keepTermsOnListView);

          this.$toast.clear();
          this.$toast.success(this.tr('Successful save'));
          if (this.isNew || this.isDuplicate) {
            if (window.fbq) {
              window.fbq('trackCustom', 'PostBoostCreated');
              window.fbq('trackCustom', 'AutomationCreated');
            }
            if (window.ttq) {
              window.ttq.track('PostBoostCreated');
              window.ttq.track('AutomationCreated');
            }

            let hasCreatedAutomation = this.$store.getters['rules/hasAnyRules'];
            if (!hasCreatedAutomation) {
              hasCreatedAutomation = window.localStorage.getItem('hasCreatedAutomation');
            }
            if (hasCreatedAutomation) {
              window.localStorage.setItem('hasCreatedAutomation', 1);
            }
            if (!hasCreatedAutomation) {
              if (window.fbq) {
                window.fbq('trackCustom', 'FirstAutomationCreated');
              }
              if (window.ttq) {
                window.ttq.track('FirstAutomationCreated');
              }
            }
          }

          this.isSaving = false;
          if (resp) {
            this.$router.replace('/automation/' + resp.id);
          }
          else {
            this.$router.replace('/automation');
          }

          return resp;
        })
        .catch(err => {
          this.error(this.tr('An error occurred while saving the automation') + ':<br/>' + err.message);
        })
        .finally(() => this.isSaving = false);
    },
  },
};
</script>
