import Rule from '@/entities/Rule.js';
import api from '@/services/Api';

export default {
  getExampleAdsetName({ prefix, template, adAccountId, sourceProfileId, campaign }) {
    const data = {
      prefix,
      template,
      ad_account_id: adAccountId,
      source_profile_id: sourceProfileId,
      campaign,
    };

    return api().post('/tools/example-adset-name', data)
      .then(resp => resp.example);
  },

  getExampleUrlParams({ template, sourceProfileId, campaign }) {
    const data = {
      template,
      campaign,
    };

    if (sourceProfileId) {
      data['source_profile_id'] = sourceProfileId;
    }

    return api().post('/tools/example-url-params', data)
      .then(resp => resp.example);
  },

  autosuggestBeneficiaryOrPayer(name) {
    const params = {};
    if (name) {
      params['name'] = name;
    }

    return api().get('/tools/autosuggest/beneficiary-or-payer?' + (new URLSearchParams(params)).toString())
      .then(resp => {
        if (resp instanceof Error) {
          throw resp;
        }

        return resp;
      });
  },

  disconnectPrecheck({ adAccountId, sourceProfileId, connectedProfileId }) {
    const params = {};
    if (adAccountId) {
      params['ad_account_id'] = adAccountId;
    }
    if (sourceProfileId) {
      params['source_profile_id'] = sourceProfileId;
    }
    if (connectedProfileId) {
      params['connected_profile_id'] = connectedProfileId;
    }

    return api().get('/tools/disconnect-precheck?' + (new URLSearchParams(params)).toString())
      .then(resp => {
        resp.defunc_rules.map(item => new Rule(item));

        return resp;
      });
  },

  facebookAdGeolocationSearch(q) {
    const params = { q };
    return api().get('/tools/facebook/ad-geolocation/search?' + (new URLSearchParams(params)).toString());
  },

  facebookAdGeolocationMetadata(type, key) {
    return api().get('/tools/facebook/ad-geolocation/' + type + '/' + key);
  },

  getMinimumBudget(adAccountId, level, period, startTime, endTime, lifespanMaxDays) {
    const params = {
      ad_account_id: adAccountId,
      level,
      period,
      start_time: startTime,
      end_time: endTime,
    };

    if (lifespanMaxDays) {
      params['lifespan_max_days'] = lifespanMaxDays;
    }

    return api().get('/tools/minimum-budget?' + (new URLSearchParams(params)).toString());
  },
};
