var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "campaign-spending",
      attrs: {
        title: _vm.tr("Campaign spending limit"),
        description: _vm.tr(
          "Set an overall spending limit for your ad campaign. This means your ad sets in the campaign will stop once you've reached your spending limit."
        ),
        disabled: !_vm.entity.isNew,
        switchable: "",
      },
      model: {
        value: _vm.isEnabled,
        callback: function ($$v) {
          _vm.isEnabled = $$v
        },
        expression: "isEnabled",
      },
    },
    [
      _c("money-input", {
        attrs: {
          disabled: !_vm.entity.isNew,
          currency: _vm.entity.adAccountCurrency
            ? _vm.entity.adAccountCurrency.code
            : "",
        },
        model: {
          value: _vm.entity.output_campaign.spend_cap,
          callback: function ($$v) {
            _vm.$set(_vm.entity.output_campaign, "spend_cap", $$v)
          },
          expression: "entity.output_campaign.spend_cap",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }