var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses, on: { click: () => _vm.open() } },
    [
      _vm.image
        ? _c(
            "div",
            { staticClass: "image", on: { click: () => _vm.close() } },
            [_c("img", { style: _vm.imageStyle, attrs: { src: _vm.image } })]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("div", { staticClass: "first-line" }, [
            _c("i", {
              class: "action-icon icon " + _vm.icon + " " + _vm.color,
            }),
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _vm.flag
              ? _c("div", { staticClass: "flag" }, [_vm._v(_vm._s(_vm.flag))])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
          _c("div", { staticClass: "spacer" }),
          _c(
            "router-link",
            { staticClass: "button", attrs: { to: _vm.path } },
            [_vm._v(" " + _vm._s(_vm.buttonLabel ?? _vm.tr("Begin")) + " ")]
          ),
        ],
        1
      ),
      _vm.toggleable
        ? [
            _c("transition", { attrs: { name: "fade" } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isOpen,
                      expression: "!isOpen",
                    },
                  ],
                  staticClass: "closed-content",
                },
                [
                  _c("i", { class: "action-icon icon white " + _vm.icon }),
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _vm.flag
                    ? _c("div", { staticClass: "flag" }, [
                        _vm._v(_vm._s(_vm.flag)),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        : _vm._e(),
      _vm.toggleable
        ? _c("i", {
            staticClass: "toggle-icon icon arrow white",
            on: { click: () => _vm.close() },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }