var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    _vm._b(
      {
        tag: "component",
        class: _vm.wrapperClasses,
        on: { click: ($event) => _vm.$emit("click", $event) },
      },
      "component",
      _vm.props,
      false
    ),
    [
      _c("div", { staticClass: "column left" }, [
        _c("i", { staticClass: "icon white", class: _vm.icon }),
        _c("div", [
          _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "flag" }, [_vm._v(_vm._s(_vm.flagText))]),
        ]),
      ]),
      _c("div", { staticClass: "column right" }, [
        _c("div", { staticClass: "description" }, [
          _vm._v(" " + _vm._s(_vm.description) + " "),
        ]),
        _vm.$slots["under-description"]
          ? _c(
              "div",
              { staticClass: "under-description" },
              [_vm._t("under-description")],
              2
            )
          : _vm._e(),
      ]),
      _vm.path || _vm.hasClickEvent
        ? _c("navigation-arrow", { attrs: { to: _vm.path } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }