/* eslint-disable filenames/match-regex */

import Rule from '@/entities/Rule.js';
import { tr } from '@/mixins/Translator.js';
import AudienceService from '@/services/Audience.js';
import RuleService from '@/services/Rule.js';
import { convertOldCampaignObjectiveToOdax } from '@/utils/facebook.js';
import RuleFormReviewView from '@/views/RuleForm/RuleFormReviewView.vue';

const aiForm = require('@/views/RuleForm/AiMode').default;
const formsByAction = {
  'start': require('@/views/RuleForm/Start').default,
  'stop': require('@/views/RuleForm/Stop').default,
  'scaling_up': require('@/views/RuleForm/ScalingUp').default,
};

const state = {
  entity: null,
  step: 1,
  allowedStep: 1,
  audiences: [],
  loadingAudiences: false,

  // For Ai Mode
  isAiMode: false,
  goal: null,
  aiAssist: [],
  budget: null,
  scalingBudget: null,
};

const getters = {
  allowedActions() {
    return Object.keys(formsByAction);
  },
  entity(state) {
    return state.entity;
  },
  isNew(state) {
    return state.entity !== null && state.entity.id === null;
  },
  step(state) {
    return state.step;
  },
  allowedStep(state) {
    return state.allowedStep;
  },
  audiences(state) {
    return state.audiences;
  },
  loadingAudiences(state) {
    return state.loadingAudiences;
  },
  isAiMode() {
    return state.isAiMode;
  },
  goal(state) {
    return state.goal;
  },
  aiAssist(state) {
    return state.aiAssist;
  },
  budget(state) {
    return state.budget;
  },
  scalingBudget(state) {
    return state.scalingBudget;
  },
  formStepTabs(state, getters, rootState, rootGetters) {
    if (state.entity === null) {
      return [];
    }

    let tabs = [];

    if (state.isAiMode) {
      tabs = aiForm;
    }
    else {
      if (!(state.entity.action in formsByAction)) {
        return [];
      }

      tabs = formsByAction[state.entity.action];
    }

    tabs = tabs.map((step, key) => {
      const stepNumber = key + 1;

      return {
        label: stepNumber + '. ' + tr(step.label, null, rootGetters['app/language']),
        view: step.view,
        link: window.location.pathname + '#step-' + stepNumber,
      };
    });

    tabs.push({
      label: (tabs.length + 1) + '. ' + tr('Overview', null, rootGetters['app/language']),
      view: RuleFormReviewView,
      link: window.location.pathname + '#step-' + (tabs.length + 1),
    });

    return tabs;
  },
  maxStepNumber(state, getters) {
    return getters.formStepTabs.length;
  },
  renderedView(state, getters) {
    if (getters.maxStepNumber === 0) {
      return null;
    }

    return getters.formStepTabs[state.step - 1].view;
  },
};

const actions = {
  init(store, { id, action, isNew, isAiMode, isDuplicate }) {
    if (isNew) {
      store.commit('setIsAiMode', isAiMode);
      return initNew(store, action);
    }

    return loadEntity(store, id, isDuplicate);
  },
  goToStep(store, value) {
    if (value > store.getters.maxStepNumber || value < 1) {
      return false;
    }

    if (value > store.getters.allowedStep) {
      return false;
    }

    store.commit('setStep', value);

    return true;
  },
  fetchAudiences(store, adAccountId) {
    if (store.getters['loadingAudiences']) {
      return Promise.resolve(false);
    }

    store.commit('setLoadingAudiences', true);

    return AudienceService.getList({ id: adAccountId })
      .then(resp => store.commit('setAudiences', resp))
      .finally(() => store.commit('setLoadingAudiences', false));
  },
};

const mutations = {
  reset(state) {
    state.entity = null;
    state.step = 1;
    state.allowedStep = 1;
    state.audiences = [];
    state.loadingAudiences = false;

    state.isAiMode = false;
    state.goal = null;
    state.aiAssist = [];
    state.budget = null;
    state.scalingBudget = null;
  },
  setEntity(state, value) {
    state.entity = value;
  },
  setStep(state, value) {
    state.step = value;
  },
  setAllowedStep(state, value) {
    state.allowedStep = value;
  },
  increaseAllowedStep(state) {
    ++state.allowedStep;
  },
  setAudiences(state, value) {
    if (state.loadingAudiences) {
      state.audiences = value;
    }
  },
  setLoadingAudiences(state, value) {
    state.loadingAudiences = !!value;
  },
  setIsAiMode(state, value) {
    state.isAiMode = !!value;
  },
  setGoal(state, value) {
    state.goal = value;
  },
  setAiAssist(state, value) {
    state.aiAssist = value;
  },
  setBudget(state, value) {
    state.budget = value;
  },
  setScalingBudget(state, value) {
    state.scalingBudget = value;
  },
};

function initNew(store, action) {
  const entity = new Rule({ action: action.replace('-', '_') });

  store.commit('setEntity', entity);

  return Promise.resolve(entity);
}

function loadEntity(store, id, isDuplicate = false) {
  return RuleService.get(id)
    .then(resp => {
      const entity = new Rule(resp);

      if (isDuplicate) {
        entity.id = null;
        entity.name = entity.name + ' - ' + tr('Copy', null, store.rootGetters['app/language']);

        if ('is_fb_odax_version' in entity.settings && !entity.settings.is_fb_odax_version) {
          entity.settings.is_fb_odax_version = true;
          entity.settings.adset_optimization_goal = null;
          entity.settings.adset_attribution_window = null;
          entity.settings.adset_bid_strategy = null;

          switch (entity.output_campaign.objective) {
            case 'POST_ENGAGEMENT':
              entity.settings.adset_conversion_location = 'ad_engagement';
              break;

            case 'VIDEO_VIEWS':
              entity.settings.adset_conversion_location = 'ad_video';
              break;
          }

          entity.output_campaign.objective = convertOldCampaignObjectiveToOdax(entity.output_campaign.objective, entity.settings.adset_conversion_event);
        }
      }

      store.commit('setEntity', entity);
      store.commit('setAllowedStep', store.getters.maxStepNumber);

      return resp;
    });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
