import BaseEntity from '@/entities/BaseEntity';

import ImprovedCopy from '@/entities/ImprovedCopy';
import User from '@/entities/User';

import { AI_STATUS } from '@/consts';

export default class AdCopyMagicItem extends BaseEntity
{
  id = null;
  owner = null;
  goal = '';
  title = '';
  description = '';
  text = '';
  evaluation_status = null;
  policy_check_status = null;
  content_actuality_check_status = null;
  value = null;
  details = null;
  policy_check_is_prohibited = null;
  policy_check_reason = null;
  expiry_date = null;
  improved_copy = null;
  created_at = new Date();

  _entityFields = {
    improved_copy: ImprovedCopy,
    owner: User,
  };

  constructor(rawData) {
    super();

    if (rawData && rawData.expiry_date) {
      rawData.expiry_date = new Date(rawData.expiry_date);
    }

    this.updateWith(rawData);
  }

  get shortText() {
    const lengthLimit = 120;

    let text = this.text.replace(/(?:\r\n|\r|\n)/g, ' ');

    if (text.length < lengthLimit) {
      return text;
    }

    text = text.slice(0, lengthLimit);
    const lastSpaceIndex = text.lastIndexOf(' ');
    text = this.text.slice(0, lastSpaceIndex);
    text += '...';

    return text;
  }

  get inProgress() {
    return this.evaluation_status === AI_STATUS.WAITING || this.evaluation_status === AI_STATUS.IN_PROGRESS;
  }

  get policyCheckInProgress() {
    return this.policy_check_status === AI_STATUS.WAITING || this.policy_check_status === AI_STATUS.IN_PROGRESS;
  }

  get contentActualityCheckInProgress() {
    return this.content_actuality_check_status === AI_STATUS.WAITING || this.content_actuality_check_status === AI_STATUS.IN_PROGRESS;
  }

  get improveInProgress() {
    return this.improved_copy && this.improved_copy.inProgress;
  }

  get hasInProgressParts() {
    return this.inProgress
      || this.policyCheckInProgress
      || this.contentActualityCheckInProgress
      || this.improveInProgress;
  }
}
