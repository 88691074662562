<template>
  <div class="rule-type-flag-component">
    <div :class="[ wrapperClasses, flagClass ]">
      <i v-if="flagIcon" :class="[ 'icon', flagIcon ]" />
      {{ flagLabel }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'RuleTypeFlag',
  props: {
    action: {
      type: String,
      required: true,
    },
    withoutBackground: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    flagLabel: '',
    flagClass: '',
    flagIcon: '',
  }),
  computed: {
    wrapperClasses() {
      return {
        'flag': true,
        'without-background': this.withoutBackground,
      };
    },
  },
  watch: {
    action: {
      immediate: true,
      handler() {
        this.getFlag();
      },
    },
    '$root.language'() {
      this.getFlag();
    },
  },
  methods: {
    getFlag() {
      switch (this.action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          this.flagLabel = this.tr('Ad Stop');
          this.flagClass = 'stop';
          this.flagIcon = 'blister';
          break;

        case 'start':
          this.flagLabel = this.tr('Ad Creation');
          this.flagClass = 'post-boost';
          this.flagIcon = 'rocket';
          break;

        case 'scaling_up':
          this.flagLabel = this.tr('Ad Scaling');
          this.flagClass = 'scaling';
          this.flagIcon = 'scaling-up';
          break;

        default:
          this.flagLabel =  '';
          this.flagClass = '';
          this.flagIcon = '';
          break;
      }
    },
  },
};
</script>
