var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "post-type-selector-component" },
    [
      _c("label", { staticClass: "input-label platform-title" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm.postTypes.length > 1
        ? [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Post type"))),
            ]),
            _c(
              "div",
              { staticClass: "checkbox-list horizontal" },
              _vm._l(_vm.postTypes, function (postType, key) {
                return _c(
                  "label",
                  { key: _vm.platform + "-post-type-" + key },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.entity[_vm.postTypeProperty],
                          expression: "entity[postTypeProperty]",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        disabled:
                          _vm.disabled && postType.machine_name === "reels",
                      },
                      domProps: {
                        value: postType.machine_name,
                        checked: Array.isArray(_vm.entity[_vm.postTypeProperty])
                          ? _vm._i(
                              _vm.entity[_vm.postTypeProperty],
                              postType.machine_name
                            ) > -1
                          : _vm.entity[_vm.postTypeProperty],
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.entity[_vm.postTypeProperty],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = postType.machine_name,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.entity,
                                  _vm.postTypeProperty,
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.entity,
                                  _vm.postTypeProperty,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.entity, _vm.postTypeProperty, $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" " + _vm._s(_vm.tr(postType.label)) + " "),
                  ]
                )
              }),
              0
            ),
          ]
        : _vm._e(),
      _vm.postMediaTypes.length > 1
        ? [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Media type"))),
            ]),
            _c(
              "div",
              { staticClass: "checkbox-list horizontal" },
              [
                _vm._l(_vm.postMediaTypes, function (mediaType, key) {
                  return [
                    _vm.mediaTypeIsAvailabe(mediaType)
                      ? _c(
                          "label",
                          { key: _vm.platform + "-media-type-" + key },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.entity[_vm.postMediaTypeProperty],
                                  expression: "entity[postMediaTypeProperty]",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.disabled,
                              },
                              domProps: {
                                value: mediaType.machine_name,
                                checked: Array.isArray(
                                  _vm.entity[_vm.postMediaTypeProperty]
                                )
                                  ? _vm._i(
                                      _vm.entity[_vm.postMediaTypeProperty],
                                      mediaType.machine_name
                                    ) > -1
                                  : _vm.entity[_vm.postMediaTypeProperty],
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.entity[_vm.postMediaTypeProperty],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = mediaType.machine_name,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.entity,
                                          _vm.postMediaTypeProperty,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.entity,
                                          _vm.postMediaTypeProperty,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.entity,
                                      _vm.postMediaTypeProperty,
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.tr(mediaType.label)) + " "),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }