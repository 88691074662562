export default {
  data: () => ({
    visibleAdAccountIds: [],
    visibleSourceProfileIds: [],
  }),
  computed: {
    adAccounts() {
      return this.$root.currentCompany.ad_accounts;
    },
    sourceProfiles() {
      return this.$root.currentCompany.source_profiles;
    },
    computedVisibleSourceProfileIds() {
      return JSON.parse(JSON.stringify(this.visibleSourceProfileIds));
    },
  },
  watch: {
    computedVisibleSourceProfileIds(newVal, oldVal) {
      if (!this.$app.isAd) {
        return;
      }

      if (!Array.isArray(newVal) || !Array.isArray(oldVal)) {
        return;
      }

      const newId = newVal.find(item => !oldVal.includes(item));
      const removedId = oldVal.find(item => !newVal.includes(item));
      if (!newId && !removedId) {
        return;
      }

      const action = newId ? 'add' : 'remove';
      const currentId = newId ? newId : removedId;
      const currentItem = this.sourceProfiles.find(sourceProfile => sourceProfile.id === currentId);

      let additionalId = null;

      if (currentItem.usable_with_this) {
        additionalId = currentItem.usable_with_this;
      }
      else {
        for (let sourceProfile of this.sourceProfiles) {
          if (
            (newId && sourceProfile.usable_with_this === newId)
            || (removedId && sourceProfile.usable_with_this === removedId)
          ) {
            additionalId = sourceProfile.id;
            break;
          }
        }
      }

      if (additionalId) {
        if (action === 'add' && !this.visibleSourceProfileIds.includes(additionalId)) {
          this.visibleSourceProfileIds.push(additionalId);
        }
        else if (action === 'remove' && this.visibleSourceProfileIds.includes(additionalId)) {
          this.visibleSourceProfileIds = this.visibleSourceProfileIds.filter(item => item !== additionalId);
        }
      }
    },
  },
  methods: {
    validateRestrictions() {
      this.$toast.clear();

      if (this.$app.isAd) {
        if (this.visibleAdAccountIds.length === 0) {
          this.$toast.error(this.tr('Specifying at least one Ad Account is required.'));
          return false;
        }
      }
      if (this.visibleSourceProfileIds.length === 0) {
        this.$toast.error(this.tr('Specifying at least one Source Profile is required.'));
        return false;
      }

      return true;
    },
  }
};
