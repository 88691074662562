var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "headerCell",
      class: ["header-cell", { sortable: _vm.sortable }],
      style: _vm.columnStyle,
    },
    [
      _c(
        "span",
        { on: { click: _vm.handleClick } },
        [
          _vm._t("default"),
          _vm.sortable && _vm.isSorted
            ? _c("i", {
                staticClass: "icon up-arrow",
                style: {
                  transform:
                    _vm.sortDirection === "asc"
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }