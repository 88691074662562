var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multi-select" },
    [
      _c("v-select", {
        attrs: {
          options: _vm.filteredOptions,
          reduce: (option) => option[_vm.valueKey],
          placeholder: _vm.placeholder,
          label: _vm.labelKey,
          disabled: _vm.disabled,
          "append-to-body": _vm.appendToBody,
          loading: _vm.loading,
        },
        on: { "option:selected": (item) => _vm.onSelectOption(item) },
        scopedSlots: _vm._u([
          {
            key: "option",
            fn: function (option) {
              return [
                _c(
                  "span",
                  { class: { "disabled-option": option[_vm.disabledKey] } },
                  [
                    option.platform
                      ? _c("i", { class: "icon " + option.platform })
                      : _vm._e(),
                    "status" in option
                      ? [
                          option.status
                            ? _c("i", { staticClass: "icon point green" })
                            : _c("i", { staticClass: "icon point red" }),
                        ]
                      : _vm._e(),
                    _vm._v(" " + _vm._s(option[_vm.labelKey]) + " "),
                  ],
                  2
                ),
                _vm.value.includes(option.id)
                  ? _c("span", { staticClass: "checkmark" }, [_vm._v("✓")])
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.insideValue,
          callback: function ($$v) {
            _vm.insideValue = $$v
          },
          expression: "insideValue",
        },
      }),
      _vm.options.length !== 0
        ? _vm._l(_vm.value, function (item, key) {
            return _c(
              "div",
              {
                key: "value-object" + key,
                staticClass: "multi-select-selected-value",
                style: _vm.selectedValueBackground(item),
              },
              [
                _c(
                  "div",
                  { staticClass: "label-wrapper" },
                  [
                    _vm.valueOption(item) !== null &&
                    "status" in _vm.valueOption(item)
                      ? [
                          _vm.valueOption(item).status
                            ? _c("i", { staticClass: "icon point green" })
                            : _c("i", { staticClass: "icon point red" }),
                        ]
                      : _vm._e(),
                    _c("div", {
                      staticClass: "label",
                      domProps: { innerHTML: _vm._s(_vm.valueLabel(item)) },
                    }),
                  ],
                  2
                ),
                !_vm.disabled
                  ? _c("i", {
                      staticClass: "icon deselect white",
                      on: { click: () => _vm.removeItem(item) },
                    })
                  : _vm._e(),
              ]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }