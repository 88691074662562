
import { leadFunnelEvents, salesFunnelEvents } from '@/utils/facebook.js';

export default {
  computed: {
    conversionEvents() {
      let standardEvents = JSON.parse(JSON.stringify(this.$store.getters['app/facebookConversionEvents']));
      let customEvents = [];

      if (this.entity.output_campaign.objective === 'OUTCOME_SALES') {
        standardEvents = standardEvents.filter(i => salesFunnelEvents.includes(i.machine_name));
      }
      else if (this.entity.output_campaign.objective === 'OUTCOME_LEADS') {
        standardEvents = standardEvents.filter(i => leadFunnelEvents.includes(i.machine_name));
      }

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      const selectedPixelId = this.entity.settings.adset_conversion_pixel_id;
      if (selectedPixelId && this.entity.selectedAdAccount) {
        customEvents = this.entity.selectedAdAccount.customconversions
          .filter(i => i.pixel_id === selectedPixelId)
          .map(item => {
            return {
              status: item.last_fired_time > yesterday,
              label: item.name + ' - ' + this.tr('Custom conversion'),
              machine_name: 'OTHER_' + item.id,
            };
          });
      }

      let result = standardEvents.concat(customEvents);

      if (selectedPixelId) {
        const selectedPixel = this.entity.pixelList.find(i => i.id === selectedPixelId);
        if (selectedPixel) {
          selectedPixel.active_events.forEach(item => {
            result.push({
              status: true,
              label: item,
              machine_name: 'CUSTOMEVENT_' + item,
            });
          });

          result.forEach(item => {
            if (!('status' in item)) {
              item.status = selectedPixel.active_standard_conversions.includes(item.machine_name);
            }
          });
        }
      }

      return result;
    },
  },
  watch: {
    'entity.settings.adset_conversion_pixel_id'() {
      let selectedConversionIsAvailable = false;
      for (let event of this.conversionEvents) {
        if (event.machine_name === this.entity.settings.adset_conversion_event) {
          selectedConversionIsAvailable = true;
          break;
        }
      }

      if (!selectedConversionIsAvailable) {
        this.entity.settings.adset_conversion_event = null;
      }
    },
  },
};
