var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "disconnect-confirm-modal",
      attrs: {
        name: "disconnect-confirm",
        overlayTransition: "fade-modal-overlay",
      },
    },
    [
      _c("i", { staticClass: "icon close", on: { click: () => _vm.close() } }),
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Disconnect Confirmation"),
            description:
              _vm.modalDescription +
              " " +
              _vm.tr("Do you really want to disconnect it?"),
          },
        },
        [
          _c("new-sorted-table", {
            attrs: {
              data: _vm.defuncRules,
              "initial-sort": { key: "name", direction: "asc" },
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("table-head", {
                      staticClass: "status",
                      attrs: { col: "status", width: "30px" },
                    }),
                    _c("table-head", { attrs: { col: "name", sortable: "" } }, [
                      _vm._v(_vm._s(_vm.tr("Name"))),
                    ]),
                    _c(
                      "table-head",
                      { attrs: { col: "task", width: "80px" } },
                      [_vm._v(_vm._s(_vm.tr("Task")))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (row) {
                    return _c(
                      "table-row",
                      { key: row.id },
                      [
                        _c(
                          "table-data",
                          { staticClass: "status", attrs: { col: "status" } },
                          [
                            _c(
                              "div",
                              { staticClass: "switch-wrapper" },
                              [
                                _c("switches", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: row.active,
                                    callback: function ($$v) {
                                      _vm.$set(row, "active", $$v)
                                    },
                                    expression: "row.active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c("table-data", { attrs: { col: "name" } }, [
                          _vm._v(" " + _vm._s(row.name) + " "),
                        ]),
                        _c("table-data", { attrs: { col: "task" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.actionLabel(row.action)) + " "
                          ),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                attrs: { disabled: _vm.isLoading },
                on: { click: () => _vm.close() },
              },
              [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "red",
                attrs: { disabled: _vm.isLoading },
                on: { click: () => _vm.confirm() },
              },
              [
                _vm.isLoading
                  ? _c("i", { staticClass: "icon loader white" })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.tr("Confirm")) + " "),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }