<template>
  <div class="protected-posts-view list-view">
    <div class="actions">
      <v-select
        v-model="sourceProfileId"
        :options="sourceProfileList"
        :reduce="(option) => option.id"
        :placeholder="tr('Datasource')"
        label="name"
        class="source-select"
      >
        <template #option="option">
          <span>
            <i v-if="option.platform" :class="'icon ' + option.platform" />
            {{ option.name }}
          </span>
        </template>
        <template #selected-option="option">
          <span>
            <i v-if="option.platform" :class="'icon ' + option.platform" />
            {{ option.name }}
          </span>
        </template>
      </v-select>

      <input
        v-model="searchTerm"
        type="text"
        class="text"
        :placeholder="tr('Search') + '...'"
      />

      <div class="checkbox-list horizontal">
        <label>
          <input v-model="onlyWithComments" type="checkbox" />
          {{ tr('Content with comments') }}
        </label>

        <label>
          <input v-model="onlyWithModeratedComments" type="checkbox" />
          {{ tr('Content with moderated comments') }}
        </label>
      </div>
    </div>

    <notification-card v-if="isListEmpty && !hasMore && !isEmptyFilters" class="no-result" empty-screen>
      {{ tr('There are no results matching your search criteria.') }}
    </notification-card>
    <notification-card v-else-if="isListEmpty && !hasMore && isEmptyFilters" empty-screen show-image-wrapper>
      {{ tr('There are no posts under this account at this time.') }}
    </notification-card>
    <new-sorted-table
      v-else-if="!isListEmpty"
      :data="list"
    >
      <template #header>
        <table-head col="status" class="status" width="42px" />
        <table-head col="name" minWidth="200px"/>
        <table-head col="created_at" width="150px">{{ tr('Posted') }}</table-head>
        <table-head col="comment_count" class="number-column" width="100px">{{ tr('Comments') }}</table-head>
        <table-head col="reviewed_comment_count" class="number-column" width="100px">{{ tr('Reviewed') }}</table-head>
        <table-head col="moderated_comment_count" class="number-column" width="100px">{{ tr('Moderated') }}</table-head>
        <table-head col="actions" width="74px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="post in sort" :key="post.id">
          <table-data col="status" class="status">
            <switches
              v-model="post.comment_moderation"
              @click.native="() => onChangeModerationStatus(post)"
              :disabled="$root.loggedInUser.isRestrictedViewer"
            />
          </table-data>
          <table-data col="name" class="name-column">
            <router-link :to="`/moderation/post/${post.id}`">
              <div class="name-with-icon">
                <i :class="'icon ' + post.source_profile.platform" />
                <div class="name">{{ post.source_profile.name }}</div>
              </div>

              <div class="post-text">
                {{ post.shortText }}
              </div>
            </router-link>
          </table-data>
          <table-data col="created_at">
            <span v-tooltip="formatToYMD(post.created_at) + ', ' + formatTime(post.created_at)">
              {{ timeAgo(post.created_at, tr) }}
            </span>
          </table-data>
          <table-data col="comment_count" class="number-column">
            {{ formatNumber(post.comment_count) }}
          </table-data>
          <table-data col="reviewed_comment_count" class="number-column">
            {{ formatNumber(post.reviewed_comment_count) }}
          </table-data>
          <table-data col="moderated_comment_count" class="number-column">
            {{ formatNumber(post.moderated_comment_count) }}
          </table-data>
          <table-data col="actions">
            <div class="action-group">
              <template v-if="!$root.loggedInUser.isRestrictedViewer">
                <refresh-button
                  :label="tr('Update comments')"
                  :success-message="tr('Comments of the selected post updated successfully.')"
                  :promise="() => updateComments(post)"
                  color-class="light-black"
                  label-as-tooltip
                />

                <div
                  v-if="$root.currentCompany.enabled_manual_run && $root.loggedInUser.level === 'admin'"
                  class="icon-wrapper"
                  v-tooltip="{ content: tr('Moderate Now'), classes: [ '' ] }"
                  @click="() => moderatePost(post)"
                >
                  <i :class="runIconClasses(post)" />
                </div>
              </template>
            </div>
          </table-data>
        </table-row>
      </template>
    </new-sorted-table>

    <div v-if="hasMore" ref="loadMore" class="load-more">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
  </div>
</template>

<script>
import RefreshButton from '@/components/RefreshButton.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import Switches from 'vue-switches';

import PostService from '@/services/Post.js';

import debounce from '@/utils/debounce.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import { formatNumber } from '@/utils/number.js';

export default {
  name: 'ProtectedPostsView',
  components: {
    RefreshButton,
    NotificationCard,
    Switches,
  },
  data() {
    return {
      debouncedLoadData: debounce(() => this.loadData(), 500),
      tmpVisibleFields: [],
      runningPost: null,
    };
  },
  computed: {
    hasMore() {
      return this.$store.getters['protectedPosts/hasMore'];
    },
    sourceProfileId: {
      get() {
        return this.$store.getters['protectedPosts/sourceProfileId'];
      },
      set(value) {
        this.$store.commit('protectedPosts/setSourceProfileId', value);
      },
    },
    searchTerm: {
      get() {
        return this.$store.getters['protectedPosts/searchTerm'];
      },
      set(value) {
        this.$store.commit('protectedPosts/setSearchTerm', value);
      },
    },
    onlyWithComments: {
      get() {
        return this.$store.getters['protectedPosts/withCommentsFilter'];
      },
      set(value) {
        this.$store.commit('protectedPosts/setWithCommentsFilter', value);
      },
    },
    onlyWithModeratedComments: {
      get() {
        return this.$store.getters['protectedPosts/withModeratedCommentsFilter'];
      },
      set(value) {
        this.$store.commit('protectedPosts/setWithModeratedCommentsFilter', value);
      },
    },
    list() {
      return this.$store.getters['protectedPosts/list'];
    },
    isListEmpty() {
      return this.list.length == 0;
    },
    sourceProfileList() {
      let list = this.$root.currentCompany.source_profiles;
      if (this.$root.loggedInUser.isRestricted) {
        list = list.filter(item => this.$root.loggedInUser.visible_source_profile_ids.includes(item.id));
      }

      const options = [{ id: null, name: 'All datasources' }];
      options.push(...list);

      return options;
    },
    isEmptyFilters() {
      return this.searchTerm.trim().length === 0
        && !this.sourceProfileId
        && !this.onlyWithComments
        && !this.onlyWithModeratedComments;
    },
  },
  watch: {
    searchTerm(val) {
      if (!val || val.trim().length === 0) {
        this.loadData();
      }
      else {
        this.debouncedLoadData();
      }
    },
    sourceProfileId() {
      this.loadData();
    },
    onlyWithComments() {
      this.loadData();
    },
    onlyWithModeratedComments() {
      this.loadData();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to && to.name && !to.name.startsWith('ProtectedPost')) {
      this.$store.dispatch('protectedPosts/resetView');
    }

    next();
  },
  created() {
    this.loadData();
  },
  methods: {
    formatToYMD,
    formatTime,
    formatNumber,
    timeAgo,
    loadData() {
      this.$store.dispatch('protectedPosts/resetView', true);
      this.loadMoreData();
    },
    loadMoreData() {
      if (!this.hasMore) {
        return;
      }

      this.$store.dispatch('protectedPosts/loadMoreData')
        .catch(err => {
          this.error(err)
        })
        .finally(() => {
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        })
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadMoreData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    runIconClasses(post) {
      const loader = this.runningPost === post.id;
      const disabled = this.runningPost !== null && !loader;

      return {
        'icon': true,
        'play': this.runningPost !== post.id,
        'loader': loader,
        'light-black': this.runningPost === null || loader,
        'gray': disabled,
        'disabled': disabled,
      };
    },
    onChangeModerationStatus(post) {
      const id = post.id;
      const newStatus = !post.comment_moderation;

      PostService.changeStatus(id, newStatus)
        .catch(err => {
          this.error(err);
          post.comment_moderation = !newStatus;
        });
    },
    updateComments(post) {
      this.$toast.info(this.tr('We will start updating the comments. Be patient, this may take up to a minute.'));

      return PostService.refresh(post.id)
        .then(resp => post.updateWith(resp));
    },
    moderatePost(post) {
      if (this.runningPost !== null) {
        return;
      }

      this.runningPost = post.id;

      PostService.moderate(post.id)
        .then((resp) => {
          post.updateWith(resp);
          this.$toast.success(this.tr('Comment moderation has been completed successfully.'))
        })
        .catch(err => this.error(err))
        .finally(() => this.runningPost = null);
    },
  },
};
</script>
