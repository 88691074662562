<template>
  <form-block class="communicator-debug-component" :title="title">
    <div class="request">
      <v-select
        v-model="selectedMethod"
        :options="availableMethods"
        :loading="availableMethods.length === 0"
        :disabled="availableMethods.length === 0"
        :clearable="false"
        :searchable="false"
      >
        <template #selected-option="option">
          <div>
            <i v-if="showStatusIcon" :class="statusIconClasses" /> {{ option.label }}
          </div>
        </template>
      </v-select>

      <button
        class="primary small"
        :disabled="isLoading"
        @click="() => sendCommunicatorRequest()"
      >
        {{ tr('Send') }}
      </button>
    </div>

    <div v-if="selectedMethod !== null" class="main-block">
      <div class="param-inputs">
        <div v-if="Object.keys(selectedMethod.params).length === 0" class="has-no-params">
          {{ tr('No params for this method') }}
        </div>
        <template v-else>
          <div v-for="(param, key) in selectedMethod.params" :key="key">
            <template v-if="param.type === 'bool'">
              <label class="checkbox-label">
                <input
                  v-model="param.value"
                  :placeholder="param.default ? 'Default: ' + param.default : ''"
                  type="checkbox"
                  :disabled="isLoading"
                />
                {{ key }}{{ param.default ? '' : '*' }}
              </label>
            </template>

            <template v-else>
              <label class="input-label">
                {{ key }}{{ param.default ? '' : '*' }}
              </label>
              <number-input
                v-if="param.type === 'int'"
                v-model="param.value"
                :placeholder="param.default ? 'Default: ' + param.default : ''"
                :disabled="isLoading"
              />
              <input
                v-else
                v-model="param.value"
                :placeholder="param.default ? 'Default: ' + param.default : ''"
                :disabled="isLoading"
                type="text"
              />
            </template>
          </div>
        </template>

        <div class="spacer" />

        <div v-if="showAccessTokenButton && $root.supportHasL3Access" class="access-token-block">
          <div class="title">
            {{ accessTokenEntityName }}
          </div>

          <div v-if="accessToken" class="access-token-container">
            <input
              ref="accessTokenInput"
              v-model="accessToken"
              type="text"
              readonly
              @click="() => selectAccessTokenInputContent()"
            />
            <i
              class="icon"
              :class="copyIconName"
              @click="() => copyToClipboard(accessToken)"
              v-tooltip="showCheckmark ? tr('Copied') : tr('Copy to clipboard')"
            />
          </div>
          <button
            v-else
            class="primary small"
            :disabled="isAccessTokenLoading"
            @click="() => sendAccessTokenRequest()"
          >
            <i v-if="isAccessTokenLoading" class="icon loader white" />
            <i v-else class="icon key white" />

            {{ tr('Get Access Token') }}
          </button>
          <button
            class="secondary small log"
            @click="openTokenRequestLog()"
          >
            <i class="icon diary" />
            {{ tr('Token Request Log') }}
          </button>
        </div>
      </div>

      <div class="response-wrapper">
        <vue-json-pretty
          v-if="response !== null" :data="response.error ?? response.response"
          :deep="3"
          show-line-number
          show-icon
        />
      </div>
    </div>
  </form-block>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import NumberInput from '@/components/NumberInput.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import AdAccount from '@/entities/AdAccount';
import ConnectedProfile from '@/entities/ConnectedProfile';
import Post from '@/entities/Post.js';
import SourceProfile from '@/entities/SourceProfile.js';
import SupportService from '@/services/Support.js';
import TokenRequestLogModal from './TokenRequestLog.vue';

import { ucfirst } from '@/utils/string';

export default {
  name: 'CommunicatorDebug',
  props: {
    entity: {
      type: [SourceProfile, AdAccount, ConnectedProfile, Post],
      required: true,
    },
  },
  components: {
    FormBlock,
    NumberInput,
    VueJsonPretty,
  },
  data() {
    return {
      availableMethods: [],
      selectedMethod: null,
      isLoading: false,
      isAccessTokenLoading: false,
      response: null,
      accessToken: null,
      showCheckmark: false,
      isTokenRequestLogOpen: false,
    };
  },
  watch: {
    selectedMethod() {
      this.response = null;

      for (const paramKey in this.selectedMethod.params) {
        const param = this.selectedMethod.params[paramKey];
        if (param.default) {
          param.value = param.default;
        }
        else {
          param.value = null;
        }
      }
    },
  },
  computed: {
    entityType() {
      if (this.entity instanceof SourceProfile) return 'source_profile';
      if (this.entity instanceof AdAccount) return 'ad_account';
      if (this.entity instanceof ConnectedProfile) return 'connected_profile';
      if (this.entity instanceof Post) return 'post';
      throw new Error('Invalid entity type');
    },
    statusIconClasses() {
      const loader = this.isLoading;
      const checkmark = !this.isLoading && this.response && this.response.success;
      const error = !this.isLoading && this.response && !this.response.success;

      let classes = {
        loader,
        primary: loader,
        checkmark,
        green: checkmark,
        close: error,
        red: error,
      };

      if (!Object.values(classes).includes(true)) {
        return {};
      }

      classes['icon'] = true;

      return classes;
    },
    showStatusIcon() {
      return Object.keys(this.statusIconClasses).length !== 0;
    },
    title() {
      return (this.entity.name || this.entity.external_id) + ' ' + this.tr('debug');
    },
    buildInitEntity() {
      if (this.entity instanceof Post) {
        return {
          entityType: 'source_profile',
          entityId: this.entity.source_profile.id,
        };
      }

      return {
        entityType: this.entityType,
        entityId: this.entity.id,
      };
    },
    showAccessTokenButton() {
      return this.entity instanceof ConnectedProfile
        || this.entity instanceof SourceProfile;
    },
    accessTokenEntityName() {
      return ucfirst(this.entity.platform) + ' ' + this.entity.constructor.name;
    },
    copyIconName() {
      return this.showCheckmark ? 'checkmark' : 'copy';
    },
  },
  methods: {
    fetchAvailableMethods() {
      SupportService.getCommunicatorMethods(this.entity.platform, this.buildInitEntity.entityType, this.buildInitEntity.entityId)
        .then((response) => {
          this.availableMethods = response;
          this.selectedMethod = this.availableMethods[0];
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sendAccessTokenRequest() {
      if (this.isAccessTokenLoading || !this.$root.supportHasL2Access) {
        return;
      }

      this.isAccessTokenLoading = true;

      SupportService.getAccessToken(this.buildInitEntity.entityType, this.buildInitEntity.entityId)
        .then((response) => {
          this.accessToken = response.access_token;
        })
        .catch((error) => this.error(error))
        .finally(() => this.isAccessTokenLoading = false);
    },
    sendCommunicatorRequest() {
      if (this.isLoading) {
        return;
      }

      if (!this.$root.supportHasL2Access) {
        this.$toast.error('Not enough support level');
        return;
      }

      const method = this.selectedMethod.method;
      const params = this.selectedMethod.params;

      for (const paramKey in params) {
        const param = params[paramKey];
        if (param.required && !param.value) {
          this.error(this.tr(':field is required!', { ':field': paramKey }));
          return;
        }
      }

      this.isLoading = true;

      const paramsObj = {};
      for (const key in params) {
        paramsObj[key] = params[key].value;
      }

      SupportService.getCommunicatorResponse(
        this.entity.platform,
        this.buildInitEntity.entityType,
        this.buildInitEntity.entityId,
        method,
        paramsObj
      )
        .then((response) => {
          this.response = response;
        })
        .catch((error) => this.error(error))
        .finally(() => this.isLoading = false);
    },
    selectAccessTokenInputContent() {
      this.$refs.accessTokenInput.select();
    },
    async copyToClipboard(text) {
      this.$refs.accessTokenInput.select();

      try {
        await navigator.clipboard.writeText(text);
        this.showCheckmark = true;
        setTimeout(() => {
          this.showCheckmark = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    },
    openTokenRequestLog() {
      this.$modal.show(
        TokenRequestLogModal,
        {
          entityType: this.buildInitEntity.entityType,
          entityId: this.buildInitEntity.entityId,
          name: this.entity.name,
        },
        {class: 'modal-without-padding modal-without-scroll modal-height--640 modal-width--960'}
      )
    },
    closeTokenRequestLog() {
      this.isTokenRequestLogOpen = false;
    },
  },
  created() {
    this.fetchAvailableMethods();
  },
};
</script>
