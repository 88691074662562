<template>
  <form-block class="token-request-log" :title="tr('Access Token Request Log')">
    <div class="description">
      {{ tr('Access token requests made for') }} {{ name }}
      <div class="entity-info">{{ entityType }} #{{ entityId }}</div>
    </div>
    <div class="token-request-table">
      <table>
        <thead>
          <tr>
            <th>{{ tr('Requested At') }}</th>
            <th>{{ tr('Support User') }}</th>
            <th>{{ tr('Email') }}</th>
            <th>{{ tr('Level') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="request in requests" :key="request.id">
            <td>{{ formatDateTime(request.created_at) }}</td>
            <td>
              <div class="user-info">
                <span class="user-name">{{ request.support_user.firstname }}</span>
                <span class="user-id text-secondary">({{ request.support_user.id }})</span>
              </div>
            </td>
            <td>{{ request.support_user.email }}</td>
            <td>{{ request.support_user.level }}</td>
          </tr>
          <tr v-if="!hasMore && requests.length > 0">
            <td colspan="6" class="text-center">
              {{ tr('End of log') }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="requests.length === 0 && !isLoading" class="empty-state">
        <i class="icon diary" />
        {{ tr('No access token requests have been made for this entity.') }}
      </div>
      <div v-if="isLoading" class="loading-state">
        <i class="icon loader" />
      </div>
      <div 
        v-if="hasMore" 
        ref="observerTarget"
        class="observer-target"
        style="height: 20px; margin-top: 10px;"
      ></div>
    </div>
    <button class="button primary close" @click="$emit('close')">
      {{ tr('Close') }}
    </button>
  </form-block>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import SupportService from '@/services/Support';
import { formatDateTime } from '@/utils/date';

const ITEMS_PER_PAGE = 10;

export default {
  name: 'TokenRequestLogModal',
  components: {
    FormBlock,
  },
  props: {
    entityType: {
      type: String,
      required: true,
    },
    entityId: {
      type: [Number, String],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    requests: [],
    page: 0,
    hasMore: true,
    isLoading: false,
    scrollPagerObserver: null,
  }),
  methods: {
    formatDateTime,
    modalClosed() {
      this.requests = [];
      this.disconnectScrollPager();
    },
    resetPagination() {
      this.requests = [];
      this.page = 0;
      this.hasMore = true;
      this.disconnectScrollPager();
      this.initScrollPager();
    },
    async loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;

      try {
        ++this.page;
        const response = await SupportService.getTokenRequestLog(
          this.entityType,
          this.entityId,
          this.page,
          ITEMS_PER_PAGE
        );
        
        this.requests.push(...response);
        this.hasMore = response.length === ITEMS_PER_PAGE;
        
        // Only reinitialize if we have more data to load
        if (this.hasMore) {
          this.$nextTick(() => {
            this.initScrollPager();
          });
        }
      } catch (error) {
        this.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    initScrollPager() {
      this.disconnectScrollPager();
      
      if (!this.hasMore) {
        return;
      }

      this.scrollPagerObserver = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !this.isLoading) {
          this.loadNewData();
        }
      }, {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
      });

      const target = this.$refs.observerTarget;
      if (target) {
        this.scrollPagerObserver.observe(target);
      }
    },
    disconnectScrollPager() {
      if (this.scrollPagerObserver) {
        this.scrollPagerObserver.disconnect();
        this.scrollPagerObserver = null;
      }
    },
  },
  mounted() {
    this.loadNewData();
  },
  beforeDestroy() {
    this.disconnectScrollPager();
  },
};
</script>