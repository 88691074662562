<template>
  <div class="dev-view">
    <multi-select
      v-model="multiSelectValue"
      :options="testArrayObject"
    />
  </div>
</template>

<script>
import MultiSelect from '@/components/MultiSelect.vue';

export default  {
  name: 'DevView',
  components: {
    MultiSelect,
  },
  data:() => ({
    multiSelectValue: [],
    testArrayObject: [
      {
        label: 'Test 1',
        value: 'test_1'
      },
      {
        label: 'Test 2',
        value: 'test_2'
      },
    ],
  }),
};
</script>
