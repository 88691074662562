<template>
  <form-block
    class="rule-form-block url-params-block-component"
    :title="tr('URL parameters')"
    :description="tr('A query string that follows the main URL in a web address. This parameter helps in analyzing and tracking advertisements as it allows us to determine exactly which ad has brought the visitor to the website. You can easily set this parameter with the help of the macros.')"
  >
    <templateable-input
      v-model="entity.settings.url_params"
      :templateParts="urlTemplateableParts"
      :disabled="disabled"
    />

    <div v-if="entity.settings.url_params" class="url-params-preview">
      <span>
        <label>{{ tr('Preview') }}:</label> {{ urlParamsPreview }}
      </span>
      <i v-if="urlParamsIsLoading" class="icon loader" />
    </div>
  </form-block>
</template>

<script>
import TemplateableInput from '@/components/TemplateableInput.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import ToolService from '@/services/Tool.js';
import debounce from '@/utils/debounce.js';

export default {
  name: 'UrlParamsBlock',
  components: {
    TemplateableInput,
  },
  mixins: [ RuleFormBlockMixin ],
  data() {
    return {
      isMounted: false,
      urlParamsInputClasses: '',
      urlParamsPreviewGenerator: debounce(() => this.generateUrlParamsPreview(), 500),
      urlParamsPreview: '-',
      urlParamsIsLoading: false,
    };
  },
  computed: {
    ctaList() {
      let list = this.$store.getters['app/facebookCallToActions'];
      if (this.entity.isAppPromo) {
        list = list.filter(i => i.machine_name !== 'NO_BUTTON');
      }

      return list;
    },
    hasSelectedCtaButton() {
      return this.entity.settings.cta_button !== 'NO_BUTTON';
    },
    urlTemplateableParts() {
      const parts = [];
      let previousGroup = '';
      for (const item of this.$store.getters['app/urlParamMacros']) {
        if (item.group !== previousGroup) {
          parts.push({ title: item.group });
          previousGroup = item.group;
        }
        parts.push({ label: item.label, value: item.machine_name });
      }
      return parts;
    },
  },
  watch: {
    'entity.settings.url_params'() {
      this.urlParamsPreviewGenerator();
    },
  },
  mounted() {
    this.isMounted = true;
  },
  beforeDestroy() {
    this.isMounted = false;
  },
  created() {
    this.generateUrlParamsPreview();
  },
  methods: {
    validate() {
      // TODO: url query parameter validation.

      return true;
    },
    generateUrlParamsPreview() {
      if (this.urlParamsIsLoading) {
        return;
      }

      this.urlParamsIsLoading = true;

      const campaign = this.entity.output_campaign.toObject();
      campaign.ad_account = this.entity.ad_account.toObject();

      let sourceProfileId = null;
      if (
        Array.isArray(this.entity.source_profiles)
        && this.entity.source_profiles.length > 0
      ) {
        sourceProfileId = this.entity.source_profiles[0].id;
      }

      ToolService.getExampleUrlParams({
        template: this.entity.settings.url_params,
        sourceProfileId,
        campaign,
      })
        .then(resp => {
          if (!this.isMounted) {
            return resp;
          }

          if (resp) {
            this.urlParamsPreview = resp;
          }
          else {
            this.urlParamsPreview = '-';
          }

          return resp;
        })
        .catch(() => this.urlParamsPreview = '-')
        .finally(() => this.urlParamsIsLoading = false);
    },
  },
};
</script>
