var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "blog-box" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.tr("Latest post from Infinite∞Blog"))),
      ]),
      _vm.isLoading
        ? _c("div", { staticClass: "loading" }, [
            _c("i", { staticClass: "icon loader primary" }),
          ])
        : _vm.posts.length === 0
        ? _c("div", { staticClass: "empty-text" }, [
            _vm._v(" " + _vm._s(_vm.tr("There are no items available.")) + " "),
          ])
        : _vm._e(),
      _vm._l(_vm.posts, function (post) {
        return _c("div", { key: post.id, staticClass: "post" }, [
          _c(
            "a",
            {
              staticClass: "post-title",
              attrs: { href: post.link, target: "_blank" },
            },
            [_vm._v(_vm._s(post.title.rendered) + " →")]
          ),
          _c("div", { staticClass: "post-date" }, [
            _vm._v(
              _vm._s(_vm.formatToStringYMD(new Date(post.date_gmt), _vm.tr))
            ),
          ]),
        ])
      }),
      _c(
        "a",
        {
          staticClass: "button primary",
          attrs: { href: "https://8ad.io/blog/", target: "_blank" },
        },
        [_vm._v(" " + _vm._s(_vm.tr("Read more from Infinite∞Blog")) + " ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }