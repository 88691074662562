<template>
  <div class="protected-post-view list-view">
    <div class="preview">
      <div class="sticky-wrapper">
        <h3>
          {{ tr('Post preview') }}
        </h3>
        <facebook-post-preview
          v-if="post && post.platform==='facebook'"
          :post="post"
        />
        <instagram-post-preview
          v-if="post && post.platform==='instagram'"
          :post="post"
        />
      </div>
    </div>

    <div class="listed-comments">
      <h3>{{ tr('Comments') }}</h3>

      <div class="actions">
        <input
          v-model="searchTerm"
          type="text"
          class="text icon--search"
          :placeholder="tr('Comment search')"
        />
      </div>

      <empty-screen
        v-if="isListEmpty && !hasMore && searchTerm"
        icon="search"
        :title="tr('No results match your current search criteria')"
        :flag-text="tr('Modify search')"
        :description="tr('Currently there are no items that match your criteria. The items you\'re looking for might appear here later, or try different parameters to see currently available items.')"
      />
      <empty-screen
        v-else-if="isListEmpty && !hasMore && !searchTerm"
        icon="search"
        :title="tr('There are no comments under this post.')"
        :flag-text="tr('Modify search')"
        :description="tr('Currently there are no items that match your criteria. The items you\'re looking for might appear here later, or try different parameters to see currently available items.')"
      />

      <sorted-table
        v-else-if="!isListEmpty"
        :data="commentList"
      >
        <template #header>
          <table-head col="author" />
          <table-head col="posted" class="posted-column" width="120px">{{ tr('Posted') }}</table-head>
          <table-head col="classification" class="classification-column" width="150px">{{ tr('Classification') }}</table-head>
          <table-head col="moderated" class="moderated-by-ai-column" width="68px">{{ tr('Moterated') }}</table-head>
          <table-head v-if="!$root.loggedInUser.isRestrictedViewer" col="actions" width="30px" />
        </template>

        <template #default="{ sort }">
          <table-row v-for="comment in sort" :key="comment.id">
            <table-data col="author">
              <div class="author">{{ comment.author.name }}</div>
              <div class="comment">{{ comment.shortMessage }}</div>
            </table-data>
            <table-data col="posted" class="posted-column">
              <span v-tooltip="formatToYMD(comment.created_at) + ', ' + formatTime(comment.created_at)">
                {{ timeAgo(comment.created_at, tr) }}
              </span>
            </table-data>
            <table-data col="classification" class="classification-column">
              <template v-if="!commentIsResultIsVisible(comment)">
                -
              </template>
              <ai-result v-else :status="comment.ai_status" :result="comment.ai_result" />
            </table-data>
            <table-data col="moderated" class="moderated-by-ai-column">
              {{ moderatedBeautify(comment.is_hidden_by_ai) }}
            </table-data>
            <table-data v-if="!$root.loggedInUser.isRestrictedViewer" col="actions">
              <div class="action-group">
                <i
                  v-if="isLoadingComment === comment.id"
                  class="icon loader light-black"
                />
                <i
                  v-else-if="!comment.is_hidden"
                  v-tooltip="tr('Hide comment')"
                  :class="'icon hide light-black ' + (isLoadingComment !== null ? 'disabled': '')"
                  @click="() => hideComment(comment)"
                />
                <i
                  v-else
                  v-tooltip="tr('Restore comment')"
                  :class="'icon restore light-black ' + (isLoadingComment !== null ? 'disabled': '')"
                  @click="() => restoreComment(comment)"
                />
              </div>
            </table-data>
          </table-row>
        </template>
      </sorted-table>

      <div v-if="hasMore" ref="loadMore" class="load-more">
        <i class="icon loader" /> {{ tr('Loading...') }}
      </div>
    </div>
  </div>
</template>

<script>
import AiResult from '@/components/AiResult.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import FacebookPostPreview from '@/components/FacebookPostPreview.vue';
import InstagramPostPreview from '@/components/InstagramPostPreview.vue';
import CommentService from '@/services/Comment.js';
import PostService from '@/services/Post.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import debounce from '@/utils/debounce.js';

const ITEM_PER_PAGE = 20;

export default {
  name: 'ProtectedPostView',
  components: {
    AiResult,
    EmptyScreen,
    FacebookPostPreview,
    InstagramPostPreview,
  },
  data() {
    return {
      post: null,
      commentList: [],
      page: 1,
      hasMore: true,
      searchTerm: '',
      searchTermUpdater: debounce(() => this.getComments(), 300),
      isLoadingComment: null,
    };
  },
  computed: {
    postId() {
      return this.$route.params.id;
    },
    isListEmpty() {
      return this.commentList.length === 0;
    },
  },
  watch: {
    'searchTerm'() {
      this.resetPage();
      this.searchTermUpdater();
    },
  },
  created() {
    this.getComments();
    this.getPost()
      .catch(err => this.error(err));
  },
  methods: {
    formatTime,
    formatToYMD,
    timeAgo,
    resetPage() {
      this.commentList = [];
      this.hasMore = true;
      this.page = 1;
    },
    getPost() {
      return PostService.get(this.postId)
        .then(resp => this.post = resp)
        .catch(err => this.error(err));
    },
    getComments() {
      return PostService.getComments(this.postId, {
        term: this.searchTerm,
        limit: ITEM_PER_PAGE,
        page: this.page,
      })
        .then(resp => {
          this.hasMore = resp.length === ITEM_PER_PAGE;
          this.commentList.push(...resp);

          return resp;
        })
        .catch(e => {
          this.error(e);
          this.hasMore = false;
        })
        .finally(() => {
          if (this.hasMore) {
            this.initInfiniteScroll();
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.page++;
          this.getComments();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    commentIsResultIsVisible(comment) {
      if (comment.ai_status > 0) {
        return true;
      }

      return this.post !== null && this.post.comment_moderation_after <= comment.created_at;
    },
    moderatedBeautify(status) {
      return status ? this.tr('Yes') : '-';
    },
    hideComment(comment) {
      this.isLoadingComment = comment.id;

      CommentService.hide(comment)
        .then(resp => comment.updateWith(resp))
        .catch(err => this.error(err))
        .finally(() => this.isLoadingComment = null);
    },
    restoreComment(comment) {
      this.isLoadingComment = comment.id;

      CommentService.unhide(comment)
        .then(resp => comment.updateWith(resp))
        .catch(err => this.error(err))
        .finally(() => this.isLoadingComment = null);
    },
  },
};
</script>
