<template>
  <div class="rule-waiting-list-view view">
    <form-block :description="tr('Here you can see the contents on the waiting list that require attention due to rules or advertising policy issues. Click on individual items for detailed information. You can approve or reject activities, or override AI decisions.')" />

    <template v-if="rule && !isLoading && isListEmpty">
      <empty-screen
        icon="clock-sleepy"
        :title="tr('No ads waiting for manual review')"
        :flag-text="tr('Full control over your ads')"
        :description="tr('When you enable manual review in your automation settings, all new ads will appear here before going live. This safety feature gives you complete control over which automated campaigns can start running on your account, letting you review and approve each campaign before spending begins.')"
      />
    </template>

    <sorted-table v-else :data="items" disable-scroll>
      <template #header>
        <table-head col="source" width="220px">
          {{ tr('Source') }}
        </table-head>
        <table-head col="content" min-width="200px">
          {{ contentLabel }}
        </table-head>
        <table-head col="status" width="250px">
          {{ tr('Status') }}
        </table-head>
        <table-head col="navigation" width="40px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="'waiting-list--' + row.id">
          <table-data col="source">
            <social-page :source-profile="row.post.source_profile" :date="row.created_at" />
          </table-data>
          <table-data col="content" class="content-col">
            <inline-social-content :entity="row.ad ?? row.post" />
          </table-data>
          <table-data col="status" class="status-col">
            <rule-waiting-list-status
              :rule="rule"
              :entity="row"
              :disabled="loadingButton !== null || $root.isSupportMode"
              @loading-button="(value) => loadingButton = value"
            />
          </table-data>
          <table-data col="navigation">
            <navigation-arrow @click="() => openItem(row)" />
          </table-data>
        </table-row>

        <template v-if="hasMore || isLoading">
          <table-row
            v-for="i in [ 1, 2, 3 ]"
            :key="'skeleton--' + i"
            ref="loadMore"
          >
            <table-data col="source">
              <social-page />
            </table-data>
            <table-data col="content" class="content-col">
              <inline-social-content />
            </table-data>
            <table-data col="status" class="status-col">
              <skeleton width="100%" />
              <skeleton width="80%" height="12px" />
            </table-data>
            <table-data col="navigation" />
          </table-row>
        </template>
      </template>
    </sorted-table>
  </div>
</template>

<script>
import EmptyScreen from '@/components/EmptyScreen.vue';
import FormBlock from '@/components/FormBlock.vue';
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import RuleWaitingListItemModalContent from '@/components/ModalContents/RuleWaitingListItemModalContent.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import RuleWaitingListStatus from '@/components/RuleWaitingListStatus.vue';
import Skeleton from '@/components/Skeleton.vue';
import SocialPage from '@/components/SocialPage.vue';
import { RULE_ACTION, RULE_ACTIVITY_MANAGEMENT_INSTRUCTION } from '@/consts.js';
import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';
import RuleService from '@/services/Rule.js';
import RuleActivityManagementService from '@/services/RuleActivityManagement.js';

const ITEM_PER_PAGE = 20;

export default {
  name: 'RuleWaitingListView',
  components: {
    EmptyScreen,
    FormBlock,
    Skeleton,
    InlineSocialContent,
    NavigationArrow,
    RuleWaitingListStatus,
    SocialPage,
  },
  data:() => ({
    isLoading: false,
    hasMore: true,
    page: 0,
    items: [],
    loadingButton: null,

    itemIdInQuery: null,
    doInQuery: null,
  }),
  computed: {
    ruleId() {
      return this.$route.params.id;
    },
    isListEmpty() {
      return this.items.length === 0;
    },
    rule() {
      return this.$parent.rule;
    },
    contentLabel() {
      if (this.rule) {
        switch (this.rule.action) {
          case RULE_ACTION.START:
            return this.tr('Post content');

          case RULE_ACTION.SCALING_UP:
          case RULE_ACTION.STOP:
            return this.tr('Ad content');
        }
      }

      return this.tr('Content');
    },
  },
  watch: {
    rule: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadNewData();
        }
      },
    },
    isLoading(val) {
      if (!val && this.itemIdInQuery && this.doInQuery) {
        this.autoHandleByQueryParams(this.itemIdInQuery, this.doInQuery);

        this.itemIdInQuery = null;
        this.doInQuery = null;
      }
    },
  },
  created() {
    this.itemIdInQuery = this.$route.query['item-id'];
    this.doInQuery = this.$route.query['do'];
    if (this.itemIdInQuery) {
      this.$router.replace(this.$route.path);
    }
  },
  methods: {
    autoHandleByQueryParams(itemId, instruction) {
      let item = this.items.find(i => i.id === itemId);
      if (!item) {
        item = new RuleActivityManagementQueueItem({ id: itemId });
      }

      if (
        instruction !== RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT
        && instruction !== RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT
      ) {
        return;
      }
      this.loadingButton = itemId + '--' + instruction;

      RuleActivityManagementService.instruct(this.rule.id, item.id, instruction)
        .then(resp => {
          item.updateWith(resp);

          this.$toast.clear();
          if (instruction === RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.ACCEPT) {
            this.$toast.success(this.tr('Activity accepted successfully. The activity will run in a few minutes.'));
          }
          else if (instruction === RULE_ACTIVITY_MANAGEMENT_INSTRUCTION.REJECT) {
            this.$toast.success(this.tr('Activity rejected successfully.'));
          }

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.loadingButton = null);
    },
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        ruleId: this.ruleId,
        page: this.page,
        limit: ITEM_PER_PAGE,
      };

      RuleService.getWaitingList(params)
        .then(resp => {
          resp.forEach(item => this.items.push(item));
          this.hasMore = resp.length === ITEM_PER_PAGE;

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore[0].$el);
      }
    },
    openItem(item) {
      this.$fullscreenModal.open(
        this,
        RuleWaitingListItemModalContent,
        {
          rule: this.rule,
          entity: item,
        },
        { closeWithOutclick: false },
      );
    },
  },
};
</script>
