import NonCriticalError from '@/errors/NonCriticalError.js';

class PaymentRequiredError extends NonCriticalError {
  constructor(price) {
    super('Payment required');
    this.price = price;
  }

  getPrice() {
    return this.price;
  }
}

export default PaymentRequiredError;
