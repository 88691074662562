var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.paymentHistory
    ? _c(
        "form-block",
        { attrs: { title: _vm.tr("Online bank card payment") } },
        [
          _c("i", {
            staticClass: "icon close",
            on: { click: () => _vm.$emit("close") },
          }),
          _c("payment-history-details", {
            attrs: { entity: _vm.paymentHistory },
          }),
          !_vm.clientSecret || !_vm.stripeCardInputReady
            ? _c("i", { staticClass: "icon loader primary center-loader" })
            : _vm._e(),
          _vm.clientSecret
            ? [
                _c("stripe-card-input", {
                  ref: "stripeForm",
                  attrs: { "client-secret": _vm.clientSecret },
                  on: {
                    ready: () => (_vm.stripeCardInputReady = true),
                    success: () => {
                      _vm.onSuccess()
                      _vm.$emit("close")
                    },
                    error: (err) => _vm.error(err),
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "primary",
                    attrs: { disabled: _vm.isPaying },
                    on: { click: () => _vm.$refs.stripeForm.save() },
                  },
                  [
                    _vm.isPaying
                      ? _c("i", { staticClass: "icon loader white" })
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.tr("Pay")) +
                        " - " +
                        _vm._s(_vm.formatPrice(_vm.paymentHistory.gross)) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }