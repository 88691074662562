export function getObjectValue(object, fieldPath) {
  if (typeof object !== 'object') {
    throw new Error('Invalid value in "object" argument.');
  }

  let currentValue = object;
  const parts = fieldPath.split('.');
  for (const part of parts) {
    if (!(part in currentValue)) {
      return null;
    }

    currentValue = currentValue[part];
  }

  return currentValue;
}

export function freeze(obj) {
  const propNames = Object.getOwnPropertyNames(obj);

  propNames.forEach(name => {
    const prop = obj[name];

    if (prop && typeof prop === 'object') {
      freeze(prop);
    }
  });

  return Object.freeze(obj);
}
