<template>
  <div class="multi-select">
    <v-select
      v-model="insideValue"
      :options="filteredOptions"
      :reduce="(option) => option[valueKey]"
      :placeholder="placeholder"
      :label="labelKey"
      :disabled="disabled"
      :append-to-body="appendToBody"
      :loading="loading"
      @option:selected="(item) => onSelectOption(item)"
    >
      <template #option="option">
        <span :class="{ 'disabled-option': option[disabledKey] }">
          <i v-if="option.platform" :class="'icon ' + option.platform" />

          <template v-if="'status' in option">
            <i v-if="option.status" class="icon point green" />
            <i v-else class="icon point red" />
          </template>

          {{ option[labelKey] }}
        </span>
        <span v-if="value.includes(option.id)" class="checkmark">&#10003;</span>
      </template>
    </v-select>

    <template v-if="options.length !== 0">
      <div
        v-for="(item, key) in value"
        :key="'value-object' + key"
        class="multi-select-selected-value"
        :style="selectedValueBackground(item)"
      >
        <div class="label-wrapper">
          <template v-if="valueOption(item) !== null && 'status' in valueOption(item)">
            <i v-if="valueOption(item).status" class="icon point green" />
            <i v-else class="icon point red" />
          </template>
          <div class="label" v-html="valueLabel(item)" />
        </div>

        <i
          v-if="!disabled"
          @click="() => removeItem(item)"
          class="icon deselect white"
        />
      </div>
    </template>
  </div>
</template>

<script>
import debounce from '@/utils/debounce.js';

export default  {
  name: 'MultiSelect',
  props: {
    value: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select targeting',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    color: {
      type: String,
      default: 'var(--primary-color)',
    },
    deletedColor: {
      type: String,
      default: '#e6564d',
    },
    deletedPlusLabel: {
      type: String,
      default: '',
    },
    valueKey: {
      type: String,
      required: false,
      default: 'value',
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label',
    },
    disabledKey: {
      type: String,
      required: false,
      default: 'disabled',
    },
    disabledClickMessage: {
      type: String,
      required: false,
      default: () => '',
    },
    appendToBody: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      debouncedInitSelectedValues: debounce(() => this.initSelectedValues(), 50),
      insideValue: null,
    };
  },
  watch: {
    options: {
      immediate: true,
      handler() {
        this.debouncedInitSelectedValues();
      },
    },
    loading(val) {
      if (!val) {
        this.debouncedInitSelectedValues();
      }
    },
    insideValue() {
      if (this.insideValue === null) {
        return;
      }

      if (!this.itemIsSelected(this.insideValue)) {
        this.value.push(this.insideValue);
        this.$emit('input', this.value);
      }

      this.insideValue = null;
    },
  },
  computed: {
    filteredOptions() {
      return this.options.filter(item => !this.itemIsSelected(item[this.valueKey]));
    },
  },
  methods: {
    initSelectedValues() {
      if (this.value.length !== 0 && !this.loading) {
        const optionValueList = this.options.map(i => i[this.valueKey]);
        let value = [ ...JSON.parse(JSON.stringify(this.value)) ];
        value = value.filter(item => {
          if (typeof item === 'object' && 'id' in item) {
            return optionValueList.map(i => i.id).includes(item.id);
          }

          return optionValueList.includes(item);
        });

        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', value);
        }
      }
    },
    itemIsSelected(item) {
      return this.value.some((obj) => {
        return (typeof obj === 'object' && 'id' in obj && obj.id === item.id) || JSON.stringify(obj) === JSON.stringify(item);
      });
    },
    removeItem(removableItem) {
      let newValue = this.value.filter(item => item !== removableItem);
      this.$emit('input', newValue);
    },
    valueOption(value) {
      return this.options.find(item => {
        if (typeof item[this.valueKey] === 'object' && 'id' in item[this.valueKey]) {
          return item[this.valueKey].id == value.id;
        }

        return JSON.stringify(item[this.valueKey]) == JSON.stringify(value)
      }) ?? null;
    },
    valueLabel(value) {
      const valueOption = this.valueOption(value);
      if (!valueOption) {
        return null;
      }

      let result = valueOption[this.labelKey];

      if (
        typeof value === 'object'
        && 'deleted_at' in value
        && value.deleted_at !== null
        && this.deletedPlusLabel
      ) {
        const deletedText = valueOption.deletedPlusLabel ?? this.deletedPlusLabel;
        result = '<div>' + result + '<br/><small>(' + deletedText + ')</small></div>';
      }

      if ('platform' in valueOption) {
        result = '<i class="icon white ' + valueOption.platform + '"></i> ' + result;
      }

      return result;
    },
    selectedValueBackground(item) {
      if (typeof item === 'object' && 'deleted_at' in item && item.deleted_at !== null) {
        return 'background: ' + this.deletedColor;
      }

      return 'background: ' + this.color;
    },
    onSelectOption(selectedOption) {
      if (selectedOption[this.disabledKey]) {
        if (this.disabledClickMessage) {
          this.$toast.clear();
          this.$toast.error(this.disabledClickMessage);
        }

        this.$nextTick(() => {
          const filteredValue = this.value.filter(i => JSON.stringify(i) !== JSON.stringify(selectedOption[this.valueKey]));
          this.$emit('input', filteredValue);
        });
      }
    },
  },
};
</script>
