import ToolService from '@/services/Tool.js';
import debounce from '@/utils/debounce.js';
import { formatPrice } from '@/utils/number.js';

export default {
  data() {
    return {
      minBudget: null,
      isBudgetRequestRunning: false,
      debouncedGetMinBudget: debounce(this.getMinBudget, 500),
    };
  },
  computed: {
    budgetPeriods() {
      return this.$store.getters['app/budgetPeriods'];
    },
    budgetLevel() {
      return this.isCampaignLevel ? 'campaign' : 'adset';
    },
    budgetPeriod() {
      switch (this.budgetLevel) {
        case 'campaign':
          return this.entity.output_campaign.budget_period;

        case 'adset':
          return this.entity.settings.adset_budget_period;
      }

      return null;
    },
    budget() {
      switch (this.budgetLevel) {
        case 'campaign':
          return this.entity.output_campaign.budget;

        case 'adset':
          return this.entity.settings.adset_budget;
      }

      return null;
    },
    invalidBudget() {
      return !this.isBudgetRequestRunning && this.budget && this.budget < this.minBudget;
    },
    invalidLifetimeRange() {
      if (this.budgetPeriod !== 'lifetime') {
        return false;
      }

      if (!this.entity.settings.adset_start_time || !this.entity.settings.adset_end_time) {
        return true;
      }

      return this.entity.settings.adset_end_time.getTime() - this.entity.settings.adset_start_time.getTime() < 86400000;
    },
    minBudgetInfoText() {
      let text = '';

      if (this.budgetPeriod === 'daily') {
        text = this.tr('Minimum daily budget') + ': ';
      }
      else {
        text = this.tr('Minimum budget') + ': ';
      }

      if (this.minBudget === null) {
        text += '...';
      }
      else {
        text += formatPrice(this.minBudget, this.entity.adAccountCurrency.symbol);
      }

      return text;
    },
    invalidBudgetInfoText() {
      return this.tr('Budget must be more than <span class="budget">:value</span>!', { ':value' : formatPrice(this.minBudget, this.entity.adAccountCurrency.symbol) });
    },
    invalidLifetimeRangeInfoText() {
      if (this.entity.settings.adset_start_time >= this.entity.settings.adset_end_time) {
        return this.tr('End date must be greater than start date!');
      }
      if (Math.abs(this.entity.settings.adset_end_time - this.entity.settings.adset_start_time) < 86400000) {
        return this.tr('The difference between the start and end date must be at least 24 hours!');
      }

      return null;
    },
  },
  watch: {
    budgetPeriod(val) {
      if (val === 'lifetime') {
        const now = new Date();
        now.setSeconds(0);
        now.setMilliseconds(0);

        this.entity.settings.adset_start_time = now;
        this.entity.settings.adset_end_time = new Date(now.getTime() + 86400000);
      }
      else {
        this.entity.settings.adset_start_time = null;
        this.entity.settings.adset_end_time = null;
        this.entity.settings.adset_lifespan_max_days = null;
      }

      this.debouncedGetMinBudget();
    },
    'entity.settings.adset_start_time'(val) {
      if (val) {
        this.debouncedGetMinBudget();
      }
    },
    'entity.settings.adset_end_time'(val) {
      if (val) {
        this.debouncedGetMinBudget();
      }
    },
    'entity.ad_account.id'(val) {
      if (val) {
        this.getMinBudget();
      }
    },
  },
  methods: {
    validateBudget() {
      if (this.isBudgetRequestRunning) {
        this.infoToast(this.tr('Please wait until the process is completed.'));
        return false;
      }

      if (this.invalidLifetimeRange) {
        this.error(this.invalidLifetimeRangeInfoText);
        return false;
      }
      if (!this.budget || this.invalidBudget) {
        this.error(this.invalidBudgetInfoText);
        return false;
      }

      return true;
    },
    getMinBudget() {
      if (
        !this.budgetPeriod
        || this.invalidLifetimeRange
        || !this.entity.ad_account
        || !this.entity.ad_account.id
      ) {
        return;
      }

      this.isBudgetRequestRunning = true;
      this.minBudget = null;

      let startTime = null;
      let endTime = null;
      let lifespanMaxDays = null;
      if (this.budgetPeriod === 'lifetime') {
        startTime = this.entity.settings.adset_start_time.toISOString();
        endTime = this.entity.settings.adset_end_time.toISOString();
        lifespanMaxDays = this.entity.settings.adset_lifespan_max_days;
      }

      ToolService.getMinimumBudget(
        this.entity.ad_account.id,
        this.budgetLevel,
        this.budgetPeriod,
        startTime,
        endTime,
        lifespanMaxDays,
      )
        .then(response => {
          this.minBudget = response.min_budget;
          return response.min_budget;
        })
        .catch(err => this.error(err))
        .finally(() => this.isBudgetRequestRunning = false);
    },
  },
};
