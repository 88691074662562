<template>
  <card v-bind="$attrs" class="table-row">
    <template v-for="(slotContent, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>
  </card>
</template>

<script>
import Card from '@/components/Card.vue';

export default {
  name: 'TableRow',
  components: {
    Card,
  },
};
</script>
