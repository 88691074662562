import Api from '@/services/Api';

export default {
  hide(entity) {
    return Api().put('/comment/' + entity.id + '/hide');
  },

  unhide(entity) {
    return Api().put('/comment/' + entity.id + '/unhide');
  }
};
