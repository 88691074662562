<template>
  <div class="rule-edit-history-view">
    <transition name="fade--fast">
      <div v-if="isLoading && isListEmpty" class="loader">
        <i class="icon loader black" />
      </div>
      <notification-card v-else-if="isListEmpty" class="no-result" empty-screen>
        {{ tr('The automation has not yet been changed.') }}
      </notification-card>
      <div v-else class="logs">
        <div
          v-for="(item, key) in logs"
          :key="'item-' + key"
          class="log-item"
        >
          <div class="title-with-date">
            <div class="title">{{ buildTitle(item, key) }}</div>
            <span
              v-tooltip="formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)"
              class="date"
            >
              {{ timeAgo(item.created_at, tr) }}
            </span>
          </div>
          <rule-change-log-fields v-if="item.changes.length !== 0 || key !== lastItemKey" :entity="item" />
        </div>
        <div class="side side--original">
        </div>
      </div>
    </transition>

    <div v-if="!isListEmpty && hasMore" ref="loadMore" class="load-more">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
  </div>
</template>

<script>
import NotificationCard from '@/components/NotificationCard.vue';
import RuleChangeLogFields from '@/components/RuleChangeLogFields.vue';

import RuleService from '@/services/Rule.js';

import { formatToYMD, formatTime, timeAgo } from '@/utils/date.js';

const ITEM_PER_PAGE = 20;

export default {
  name: 'RuleChangeLogView',
  components: {
    NotificationCard,
    RuleChangeLogFields,
  },
  data:() => ({
    isLoading: false,
    hasMore: true,
    page: 0,
    logs: [],
  }),
  computed: {
    ruleId() {
      return this.$route.params.id;
    },
    isListEmpty() {
      return this.logs.length === 0;
    },
    lastItemKey() {
      return this.logs.length - 1;
    },
  },
  created() {
    this.loadNewData();
  },
  methods: {
    timeAgo,
    formatToYMD,
    formatTime,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        id: this.ruleId,
        page: this.page,
        limit: ITEM_PER_PAGE,
      };

      RuleService.getChangeLogs(params)
        .then(resp => {
          resp.forEach(item => this.logs.push(item));
          this.hasMore = resp.length === ITEM_PER_PAGE;
        })
        .catch((err) => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore) {
        observer.observe(this.$refs.loadMore);
      }
    },
    buildTitle(item, key) {
      if (key === this.lastItemKey && item.changes.length === 0) {
        return this.tr(':name created the automation', { ':name': item.user.fullname });
      }

      return this.tr(':name modified the automation', { ':name': item.user.fullname });
    }
  }
};
</script>
