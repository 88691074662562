var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "frame", staticClass: "lazy-image-component" },
    [
      _vm.isVisible
        ? [
            !_vm.isError
              ? _c("img", {
                  attrs: { src: _vm.src },
                  on: { load: () => _vm.onLoad(), error: () => _vm.onError() },
                })
              : _vm._e(),
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader dark-gray" })
              : _vm.isError
              ? _c("i", { staticClass: "icon image-off gray" })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }