<template>
  <div :class="wrapperClasses">
    <img :src="entity.picture" />
    <div class="textual">
      <span class="name">{{ entity.name }}</span>
      <!-- <span class="id">{{ entity.external_id }}</span> -->
      <span v-if="entity.token_expire_at || this.hasMissingPermission" class="warn-message">
        <template v-if="entity.token_expire_at">
          <template v-if="hasMissingPermission && !isExpired">
            {{ tr('Missing permission.') }}
            {{ tr('Reconnection is required!') }}
          </template>
          <template v-else-if="!isExpired">
          {{ tr('Expires in :time', { ':time': expireIn }) }}
          </template>
          <template v-else>
            {{ tr('Reconnection is required!') }}
          </template>
        </template>
      </span>
    </div>

    <i
      v-if="entity.owner.id === $root.loggedInUser.id"
      class="icon refresh"
      @click="() => reconnectProfile()"
      v-tooltip="tr('Reconnect')"
    />
    <i
      v-if="disconnectButtonVisible"
      class="icon close"
      @click="() => removeProfile()"
      v-tooltip="tr('Disconnect')"
    />
  </div>
</template>

<script>
import ConnectedProfile from '@/entities/ConnectedProfile.js';

export default {
  name: 'ConnectedProfileItem',
  props: {
    entity: {
      type: ConnectedProfile,
      required: true,
    },
  },
  data: () => ({
    isReconnecting: false,
  }),
  computed: {
    wrapperClasses() {
      return {
        'connected-profile-item': true,
        'warning': this.hasMissingPermission || this.willBeExpiredWarning,
        'error': this.isExpired,
        'is-reconnecting': this.isReconnecting,
      }
    },
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    expireInSeconds() {
      if (this.entity.token_expire_at === null) {
        return null;
      }

      return Math.floor((this.entity.token_expire_at.getTime() - (new Date()).getTime()) / 1000);
    },
    hasMissingPermission() {
      return this.entity.missing_scope;
    },
    isExpired() {
      return this.expireInSeconds !== null && this.expireInSeconds <= 0;
    },
    willBeExpiredWarning() {
      return this.expireInSeconds > 0 && this.expireInSeconds < 86400 * 7;
    },
    expireIn() {
      const inDays = Math.floor(this.expireInSeconds / 86400);
      if (inDays >= 2) {
        return this.tr(':num days', { ':num': inDays });
      }

      const inHours = Math.floor(this.expireInSeconds / 3600);
      if (inHours >= 2) {
        return this.tr(':num hours', { ':num': inHours });
      }


      return this.tr(':num minutes', { ':num': Math.floor(this.expireInSeconds / 60) });
    },
    disconnectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
  },
  watch: {
    isConnecting(val) {
      if (!val) {
        this.isReconnecting = false;
      }
    },
  },
  methods: {
    removeProfile() {
      if (this.isConnecting) {
        return;
      }

      this.$emit('remove');
    },
    reconnectProfile() {
      if (this.isConnecting) {
        return;
      }

      this.isReconnecting = true;
      this.$emit('reconnect');
    },
  },
};
</script>
