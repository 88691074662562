var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-activity-review-modal-content" },
    [
      _c("social-page", {
        attrs: {
          "source-profile": _vm.item.post.source_profile,
          date: _vm.item.entity.created_at,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "div",
        { staticClass: "modal-main-content scroll-wrapper" },
        [
          _c(
            "div",
            [
              _c("h3", [_vm._v(_vm._s(_vm.shortLabel))]),
              _c("inline-social-content", {
                attrs: { entity: _vm.item.entity },
              }),
            ],
            1
          ),
          _vm.whyNotText
            ? _c("div", [
                _vm.whyNotQuestion
                  ? _c("h3", [_vm._v(_vm._s(_vm.whyNotQuestion))])
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.whyNotText) + " "),
              ])
            : _vm._e(),
          _vm.item.isProhibitedContent
            ? _c("div", [
                _c("h3", [_vm._v(_vm._s(_vm.tr("Reasoning of Infinite∞Ai")))]),
                _vm._v(
                  " " + _vm._s(_vm.item.related_entity.reason_translation) + " "
                ),
              ])
            : _vm._e(),
          _vm.whatToDoText
            ? _c("div", [
                _c("h3", [_vm._v(_vm._s(_vm.tr("What can you do?")))]),
                _vm._v(" " + _vm._s(_vm.whatToDoText) + " "),
              ])
            : _vm._e(),
          _vm.item.related_entity !== null
            ? _c("rule-waiting-list-status", {
                attrs: {
                  rule: _vm.rule,
                  entity: _vm.item.related_entity,
                  disabled: _vm.$root.isSupportMode,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }