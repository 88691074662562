<template>
  <form-block
    v-if="isAvailable"
    class="rule-form-block conversion-block-component"
    :title="tr('Conversion')"
  >
    <template #top-right>
      <refresh-button
        v-if="!entity.disconnectedAdAccount"
        :label="tr('Update events')"
        :success-message="tr('Conversion events updated successfully.')"
        :promise="() => refreshSelectedAdAccount()"
      />
    </template>

    <info-text v-if="entity.disconnectedAdAccount" type="error">
      {{ tr('The selected ad account has been disconnected.') }}
    </info-text>
    <template v-else>
      <div>
        <div class="checkbox-list vertical">
          <label v-for="(location, key) in conversionLocations" :key="key + '-conversion-key'">
            <input
              v-model="entity.settings.adset_conversion_location"
              :value="location.machine_name"
              :disabled="disabled || conversionLocationOptionIsDisabled(location)"
              type="radio"
              name="conversion-location-radio"
            />
            {{ tr(location.label) }}
          </label>
        </div>
      </div>

      <template v-if="entity.settings.adset_conversion_location === 'website'">
        <v-select
          v-model="entity.settings.adset_conversion_pixel_id"
          :options="entity.pixelListForSelect"
          :reduce="option => option.value"
          :placeholder="tr('Select a pixel')"
          :disabled="disabled"
        >
          <template #option="option">
            <div>
              <template v-if="'status' in option">
                <i v-if="option.status" class="icon point green" />
                <i v-else class="icon point red" />
              </template>

              {{ option.label }}
            </div>
          </template>

          <template #selected-option="option">
            <div>
              <template v-if="'status' in option">
                <i v-if="option.status" class="icon point green" />
                <i v-else class="icon point red" />
              </template>

              {{ option.label }}
            </div>
          </template>
        </v-select>

        <v-select
          v-model="entity.settings.adset_conversion_event"
          :options="conversionEvents"
          :reduce="(obj) => obj.machine_name"
          :placeholder="tr('Select a conversion')"
          :disabled="disabled"
        >
          <template #option="option">
            <div>
              <template v-if="'status' in option">
                <i v-if="option.status" class="icon point green" />
                <i v-else class="icon point red" />
              </template>

              {{ option.label }}
            </div>
          </template>

          <template #selected-option="option">
            <div>
              <template v-if="'status' in option">
                <i v-if="option.status" class="icon point green" />
                <i v-else class="icon point red" />
              </template>

              {{ option.label }}
            </div>
          </template>
        </v-select>

        <a href="https://business.facebook.com/settings/custom-conversions" class="facebook-link" target="_blank">{{tr('Create new custom conversion')}}</a>
      </template>
      <v-select
        v-else-if="entity.settings.adset_conversion_location === 'app'"
        v-model="entity.settings.adset_conversion_app_id"
        :options="entity.appList"
        :reduce="(obj) => obj.id"
        :placeholder="tr('Select an app')"
        :disabled="disabled"
        label="name"
      />
    </template>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import ConversionBlockMixin from '@/mixins/RuleForm/Blocks/ConversionBlock.js';

import RefreshButton from '@/components/RefreshButton.vue';

export default {
  name: 'ConversionBlock',
  mixins: [ RuleFormBlockMixin, ConversionBlockMixin ],
  components: {
    RefreshButton,
  },
  computed: {
    isAvailable() {
      return this.entity.output_campaign.objective === 'CONVERSIONS';
    },
    conversionLocations() {
      return this.$store.getters['app/conversionLocations'];
    },
    facebookAdsetOptimizationGoals() {
      const items = this.$store.getters['app/facebookAdsetOptimizationGoals'];
      return this.filterSelectValuesByConditions(items);
    },
  },
  watch: {
    isAvailable: {
      immediate: true,
      handler(val) {
        if (this.isFbOdax) {
          return;
        }

        if (!val) {
          this.entity.settings.adset_conversion_location = null;
          this.entity.settings.adset_conversion_event = null;
          this.entity.settings.adset_conversion_pixel_id = null;
          this.entity.settings.adset_conversion_app_id = null;
          this.entity.settings.adset_conversion_app_store_url = null;
        }
        else if (!this.entity.settings.adset_conversion_location) {
          if (this.entity.pixelList.length !== 0) {
            this.entity.settings.adset_conversion_location = 'website';
          }
          else if (this.entity.appList.length !== 0) {
            this.entity.settings.adset_conversion_location = 'app';
          }
        }
      },
    },
  },
  created() {
    if (!this.entity.settings.adset_optimization_goal) {
      let allowedValues = this.facebookAdsetOptimizationGoals.map(item => item.machine_name);
      if (allowedValues.includes(this.entity.output_campaign.objective)) {
        this.entity.settings.adset_optimization_goal = this.entity.output_campaign.objective;
      }
      else if (this.entity.output_campaign.objective === 'CONVERSIONS') {
        this.entity.settings.adset_optimization_goal = 'OFFSITE_CONVERSIONS';
      }
      else {
        this.entity.settings.adset_optimization_goal = allowedValues[0];
      }
    }
  },
  methods: {
    validate() {
      if (!this.isAvailable) {
        return true;
      }

      if (this.entity.settings.adset_conversion_location === 'website') {
        if (!this.entity.settings.adset_conversion_pixel_id) {
          this.error('When a website is used as a conversion location, Pixel must be added!');
          return false;
        }
        if (!this.entity.settings.adset_conversion_event) {
          this.error('When a website is used as a conversion location, conversion event must be added!');
          return false;
        }
      }
      else if (this.entity.settings.adset_conversion_location === 'app') {
        if (!this.entity.settings.adset_conversion_app_id) {
          this.error('When an application is used as a conversion location, App must be added!');
          return false;
        }
      }
      else {
        this.error('Invalid conversion location.');
        return false;
      }

      return true;
    },
    conversionLocationOptionIsDisabled(location) {
      switch (location.machine_name) {
        case 'website':
          return this.entity.pixelList.length === 0;

        case 'app':
          return this.entity.appList.length === 0;
      }

      return true;
    },
  },
};
</script>
