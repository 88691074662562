<template>
  <div class="rule-error-log-view view">
    <error-logs :rule="$parent.rule" wait-for-rule />
  </div>
</template>

<script>
import ErrorLogs from '@/components/ErrorLogs.vue';

export default {
  name: 'RuleErrorLogView',
  components: {
    ErrorLogs,
  },
};
</script>
