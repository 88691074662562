const state = {
};

const getters = {
  token() {
    return null;
  },

  loggedIn() {
    return false;
  },
};

const actions = {
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
