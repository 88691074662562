var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-ad-details-component" }, [
    _c("div", { staticClass: "details" }, [
      _vm.ad.post
        ? _c(
            "div",
            { staticClass: "post" },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.tr("Post source")) + ":"),
              ]),
              _vm._v(" " + _vm._s(_vm.ucfirst(_vm.ad.post.platform))),
              _c("br"),
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.tr("Post ID")) + ":"),
              ]),
              _vm._v(" " + _vm._s(_vm.ad.post.external_id) + " "),
              _vm.ad.post.url
                ? _c(
                    "a",
                    {
                      staticClass: "post-link",
                      attrs: { href: _vm.ad.post.url, target: "_blank" },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr("Go to Post")) + " ")]
                  )
                : _vm._e(),
              _vm.$root.isLoggedIn
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/post-copy-analysis/posts/" + _vm.ad.post.id,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr("Go to Post Analysis")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.ad.campaign
        ? _c("div", [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.tr("Campaign")) + ":"),
            ]),
            _vm._v(" " + _vm._s(_vm.ad.campaign.name) + " "),
          ])
        : _vm._e(),
      _vm.ad.adset
        ? _c(
            "div",
            { staticClass: "placements" },
            _vm._l(
              _vm.ad.adset.targeting_publisher_platforms,
              function (platform) {
                return _c(
                  "div",
                  { key: _vm.ad.external_id + "--placement--" + platform },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.tr(":platform placements", {
                            ":platform": _vm.ucfirst(platform),
                          })
                        ) + ":"
                      ),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.getPlacements(_vm.ad, platform).join(", ")) +
                        " "
                    ),
                  ]
                )
              }
            ),
            0
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "preview" },
      [
        _c("v-select", {
          attrs: {
            options: _vm.adFormats,
            reduce: (item) => item.machine_name,
            clearable: false,
            disabled: _vm.previewIsLoading,
          },
          model: {
            value: _vm.previewAdFormat,
            callback: function ($$v) {
              _vm.previewAdFormat = $$v
            },
            expression: "previewAdFormat",
          },
        }),
        _vm.previewIsLoading
          ? _c("i", { staticClass: "icon loader black" })
          : !_vm.previewIsLoaded
          ? _c("div", { staticClass: "error" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr("An error occurred while loading the preview.")
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _c("div", {
          ref: "iframeWrapper",
          staticClass: "preview-iframe-wrapper",
          style: _vm.previewWrapperStyle,
          domProps: { innerHTML: _vm._s(_vm.previewIframe) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }