var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "image-input-component" }, [
    _c("div", { staticClass: "button-wrapper" }, [
      _c(
        "label",
        {
          class: _vm.buttonClasses,
          on: { click: ($event) => _vm.onClickButton($event) },
        },
        [
          _c("i", { class: _vm.buttonIconClasses }),
          _vm._v(" " + _vm._s(_vm.buttonLabel) + " "),
          _c("input", {
            ref: "fileInput",
            attrs: {
              type: "file",
              accept: "image/jpeg, image/png, image/webp",
            },
            on: { change: _vm.onFileChange },
          }),
        ]
      ),
      _c("div", { staticClass: "note" }, [
        _vm.showUploadedFileName
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr("Uploaded: :fileName", {
                      ":fileName": _vm.uploadedFileName,
                    })
                  ) +
                  " "
              ),
            ])
          : _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr("Maximum upload size :limit (jpg, png or webp)", {
                      ":limit": _vm.readableLimit,
                    })
                  ) +
                  " "
              ),
            ]),
      ]),
    ]),
    _vm.showImagePreview
      ? _c("div", { staticClass: "image-wrapper" }, [
          _c("img", { attrs: { src: _vm.imagePreview } }),
          _c(
            "div",
            {
              staticClass: "remove-button",
              on: { click: () => _vm.removeImage() },
            },
            [_c("i", { staticClass: "icon close white" })]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }