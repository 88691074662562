<template>
  <div :class="{ 'skeleton': true, 'light': light }" :style="wrapperStyle" />
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    width: {
      required: false,
      type: String,
      default: () => '100%',
    },
    minWidth: {
      required: false,
      type: String || null,
      default: () => null,
    },
    maxWidth: {
      required: false,
      type: String || null,
      default: () => null,
    },
    height: {
      required: false,
      type: String,
      default: () => '15px',
    },
    radius: {
      required: false,
      type: String,
      default: () => '6px',
    },
    light: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    wrapperStyle() {
      return {
        'width': this.width,
        'min-width': this.minWidth,
        'max-width': this.maxWidth,
        'height': this.height,
        'border-radius': this.radius,
      };
    },
  },
};
</script>
