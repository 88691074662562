var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: "formblock " + _vm.extraClass }, [
    _vm.title || _vm.switchable || _vm.info
      ? _c(
          "div",
          { staticClass: "formtitle" },
          [
            _c("div", { staticClass: "main" }, [
              _vm.switchable
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkboxValue,
                        expression: "checkboxValue",
                      },
                    ],
                    attrs: { type: "checkbox", disabled: _vm.disabled },
                    domProps: {
                      checked: Array.isArray(_vm.checkboxValue)
                        ? _vm._i(_vm.checkboxValue, null) > -1
                        : _vm.checkboxValue,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.checkboxValue,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkboxValue = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkboxValue = $$c
                        }
                      },
                    },
                  })
                : _vm._e(),
              _vm.title
                ? _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } })
                : _vm._e(),
              _vm.info
                ? _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.right",
                        value: _vm.info,
                        expression: "info",
                        modifiers: { right: true },
                      },
                    ],
                    staticClass: "icon gray tooltip",
                  })
                : _vm._e(),
            ]),
            _vm._t("top-right"),
          ],
          2
        )
      : _vm._e(),
    _vm.description
      ? _c("div", {
          staticClass: "description",
          domProps: { innerHTML: _vm._s(_vm.description) },
        })
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.switchable || _vm.checkboxValue,
            expression: "!switchable || checkboxValue",
          },
        ],
        staticClass: "slot",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }