import DashboardMixin from '@/mixins/Dashboard.js';

export default {
  mixins: [ DashboardMixin ],
  watch: {
    onboardingNeccessary: {
      immediate: true,
      handler(val, oldVal) {
        this.$nextTick(() => {
          if (!this.isLoading && val && oldVal !== val) {
            this.openOnboardingModal();
          }
        });
      },
    },
  },
};
