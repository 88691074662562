<template>
  <div :class="wrapperClasses">
    <system-level-notifications v-if="!minimal" />

    <info-text v-if="!minimal" icon="meta" class="meta-tech">
      {{ techProviderText }}
    </info-text>

    <div v-if="!minimal" class="page-header">
      <div class="title">{{ tr('Meta Ad Account Connection') }}</div>
      <div class="description">
        {{ tr('Connect your Meta Ad Account to let Infinite∞Ad help you get better results from your advertising budget. Our smart AI system will automatically manage your ads to help you reach more customers while spending less. As an official Meta partner, we ensure your account remains completely safe and protected at all times.') }}
      </div>
    </div>

    <div v-if="list.length > 10" class="actions">
      <input
        v-model="searchTerm"
        type="text"
        class="text icon--search"
        :placeholder="tr('Search') + '...'"
      />
    </div>

    <template v-if="list.length === 0">
      <empty-screen
        v-if="!hasConnectedProfile"
        :icon="emptyScreenIcon"
        :title="tr('Connect your accounts to start automating')"
        :flag-text="tr('Connect profiles')"
        :description="tr('To begin using our AI-powered automation features, you\'ll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising.')"
        :mobile="minimal"
        @click="handleClick"
      />
      <empty-screen
        v-else
        icon="arrow-up"
        :title="tr('Ad account required to start automating')"
        :flag-text="tr('Watch tutorial')"
        :description="tr('While your Meta profile is connected, we couldn\'t find any ad accounts linked to it. To use Infinite∞Ad\'s automation features, you\'ll need an active ad account. Watch our tutorial video to learn how to create a new ad account or add an existing one to your Meta Business profile in just a few minutes.')"
        :mobile="minimal"
        :path="tr('https://www.youtube.com/watch?v=bRPw0X_1MGc')"
      />
    </template>
    <empty-screen
      v-else-if="filteredList.length === 0"
      icon="search"
      :title="tr('No results match your current search criteria')"
      :flag-text="tr('Modify search')"
      :description="tr('Currently there are no items that match your criteria. The items you\'re looking for might appear here later, or try different parameters to see currently available items.')"
      :mobile="minimal"
    />
    <sorted-table
      v-else
      :data="filteredList"
      :initial-sort="{ key: 'name', direction: 'asc' }"
      class="row-hover-shadow"
    >
      <template #header>
        <table-head col="name" class="name-cell" sortable>{{ tr('Name') }}</table-head>
        <table-head col="is_active" width="100px" class="status-cell">{{ tr('Status') }}</table-head>
        <table-head
          v-if="!minimal"
          col="external_id"
          width="180px"
          class="external-id-cell"
          sortable
        >
          {{ tr('Account ID') }}
        </table-head>
        <table-head v-if="!minimal" col="connected_by" width="180px">{{ tr('Connected by') }}</table-head>
        <table-head v-if="!minimal && $app.isAd && $root.isSupportMode" col="actions" max-width="100px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.external_id" :class="{ 'active': selected.includes(row.id) }">
          <table-data col="name" class="name-cell">
            <label>
              <input
                v-if="checkboxIsDisabled(row)"
                v-tooltip.right="tr('Not allowed')"
                type="checkbox"
                disabled
              />
              <i v-else-if="loadingId === row.external_id" class="icon loader" />
              <input
                v-else
                type="checkbox"
                :disabled="!!loadingId || !isModifiable(row)"
                :checked="selected.includes(row.id)"
                @change="($event) => checkboxChanged($event, row)"
              />

              <div class="name-wrapper" :class="{ 'disabled': row.account_status !== 1 && !isUnsettled(row) }">
                <div class="name">
                  {{ row.name }}
                </div>
              </div>
            </label>
          </table-data>
          <table-data col="is_active" class="status-cell">
            <span v-if="row.account_status != 1 || selected.includes(row.id)" :class="statusClasses(row)">
              {{ statusLabel(row) }}
            </span>
          </table-data>
          <table-data v-if="!minimal" col="external_id" class="external-id-cell">
            {{ row.external_id }}
          </table-data>
          <table-data v-if="!minimal" col="connected_by">
            {{ selected.includes(row.id) && row.owner ? row.owner.fullname : '-' }}
          </table-data>
          <table-data v-if="!minimal && $app.isAd && $root.isSupportMode" col="actions">
            <div v-if="isConnected(row)" class="action-group">
              <button
                v-if="$root.supportHasL2Access"
                class="bordered gray small"
                @click="() => openCommunicatorDebug(row)"
              >
                {{ tr('Debug') }}
              </button>
            </div>
          </table-data>
        </table-row>
      </template>
    </sorted-table>

    <disconnect-confirm-modal ref="disconnectConfirmModal" />
    <connect-unsettled-ad-account-modal
      ref="connectUnsettledAdAccountModal"
      :ad-account="accountInModal"
      @closed="() => accountInModal = null"
      @settled="(adAccount) => { accountInModal.status = adAccount.status; checkboxChanged(null, adAccount); }"
    />
    <connect-ad-account-without-payment-method-modal
      ref="connectAdAccountWithoutPaymentMethodModal"
      :ad-account="accountInModal"
      @closed="() => accountInModal = null"
      @setted="(adAccount) => { accountInModal.has_payment_method = adAccount.has_payment_method; checkboxChanged(null, adAccount); }"
    />
  </div>
</template>

<script>
import CommunicatorDebug from '@/components/CommunicatorDebug.vue';
import ConnectAdAccountWithoutPaymentMethodModal from '@/components/ConnectAdAccountWithoutPaymentMethodModal.vue';
import ConnectUnsettledAdAccountModal from '@/components/ConnectUnsettledAdAccountModal.vue';
import DisconnectConfirmModal from '@/components/DisconnectConfirmModal.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import InfoText from '@/components/InfoText.vue';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';
import User from '@/entities/User.js';
import { PaymentRequiredError } from '@/errors';
import AdAccountService from '@/services/AdAccount.js';
import ToolService from '@/services/Tool.js';
import { formatPrice } from '@/utils/number.js';

const PLATFORM = 'facebook';

export default {
  name: 'IntegrationsMetaAdAccountsView',
  components: {
    ConnectAdAccountWithoutPaymentMethodModal,
    ConnectUnsettledAdAccountModal,
    DisconnectConfirmModal,
    EmptyScreen,
    SystemLevelNotifications,
    InfoText,
  },
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchTerm: '',
    loadingId: null,
    accountInModal: null,
  }),
  computed: {
    wrapperClasses() {
      return {
        'integrations-meta-ad-accounts-view': true,
        'minimal': this.minimal,
      };
    },
    techProviderText() {
      return this.tr('<strong>Infinite∞Ad is a Meta Tech Provider</strong> verified and approved by Meta to ensure the highest security standards.');
    },
    emptyScreenIcon() {
      return this.isConnecting ? 'loader' : 'plug';
    },
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
    hasConnectedProfile() {
      const profileItems = this.$root.loggedInUser.connected_profiles.filter(profile => profile.platform === 'facebook');

      return profileItems.length !== 0;
    },
    list() {
      const adAccounts = [];
      const processedAdAccountIds = [];

      const companyAdAccounts = this.$root.currentCompany.ad_accounts.filter(adAcc => adAcc.platform === PLATFORM);
      companyAdAccounts.forEach(adAcc => {
        processedAdAccountIds.push(adAcc.external_id);
        adAccounts.push(adAcc);
      });

      const connectedProfiles = this.$root.loggedInUser.connected_profiles.filter(profile => profile.platform === PLATFORM);
      connectedProfiles.forEach(profile => {
        profile.details.ad_accounts.forEach(adAcc => {
          if (processedAdAccountIds.includes(adAcc.id)) {
            return;
          }

          adAcc.external_id = adAcc.id;
          adAccounts.push(adAcc);
          processedAdAccountIds.push(adAcc.external_id);
        });
      });

      return adAccounts;
    },
    filteredList() {
      const term = this.searchTerm.trim().toLowerCase();
      if (term.length === 0) {
        return this.list;
      }

      return this.list.filter(i => i.name.toLowerCase().includes(term));
    },
    selected() {
      return this.$root.currentCompany.ad_accounts
        .filter(i => i.platform === PLATFORM)
        .filter(i => i.deleted_at === null)
        .map(i => i.id);
    },
    adAccountLabels() {
      const result = {};

      this.$store.getters['app/facebookAdAccountStatuses'].forEach(i => result[i.machine_name] = i.label);

      return result;
    },
  },
  methods: {
    formatPrice,
    handleClick() {
      if (!this.isConnecting) {
        this.$emit('connect');
      }
    },
    statusClasses(item) {
      return {
        'status': true,
        'active': this.selected.includes(item.id),
        'not-connected': !this.selected.includes(item.id),
        'disabled': item.account_status != 1,
      };
    },
    statusLabel(item) {
      if (item.account_status != 1) {
        return this.tr(this.adAccountLabels[item.account_status]);
      }
      else if (item.has_funding_source === false) {
        return this.tr('Missing payment method');
      }

      return this.selected.includes(item.id) ? this.tr('Active') : this.tr('Not connected');
    },
    checkboxIsDisabled(adAccount) {
      if (this.selected.includes(adAccount.id)) {
        return false;
      }

      return adAccount.account_status !== 1 && !this.isUnsettled(adAccount);
    },
    isUnsettled(item) {
      return item.account_status === 3 || item.account_status === 8;
    },
    hasNoPaymentMethod(item) {
      return item.has_funding_source === false;
    },
    isModifiable(item) {
      return !this.selected.includes(item.id) || (item.owner.id === this.$root.loggedInUser.id);
    },
    checkboxChanged(event, adAccount) {
      if (event && event.target.checked && this.isUnsettled(adAccount)) {
        if (event) {
          event.target.checked = false;
        }

        this.accountInModal = adAccount;
        this.$refs.connectUnsettledAdAccountModal.open();
        return;
      }

      if (event && event.target.checked && this.hasNoPaymentMethod(adAccount)) {
        if (event) {
          event.target.checked = false;
        }

        this.accountInModal = adAccount;
        this.$refs.connectAdAccountWithoutPaymentMethodModal.open();
        return;
      }

      this.loadingId = adAccount.external_id;
      const company = this.$root.currentCompany;
      if (!company.current_subscription?.package) {
        this.$toast.clear();
        this.$toast.error(this.tr('No active plan found. Please subscribe to a plan before connecting new assets.'));
        this.loadingId = null;
        event.target.checked = false;
        return;
      }

      if (!this.selected.includes(adAccount.id)) {
        this.$emit('loading', true);
        const connectProcessCallback = async chargeExtraAsset => {
          let connectedAdAccount = null;
          try {
            connectedAdAccount = await AdAccountService.connect(PLATFORM, adAccount.external_id, chargeExtraAsset);
            connectedAdAccount.owner = new User({
              id: this.$root.loggedInUser.id,
              firstname: this.$root.loggedInUser.firstname,
              lastname: this.$root.loggedInUser.lastname,
            });
            company.ad_accounts.push(connectedAdAccount);
          }
          catch (err) {
            if (err instanceof PaymentRequiredError) {
              let priceMessage = '';
              if (err.getNetPrice() === err.getGrossPrice()) {
                priceMessage = this.tr(
                  'We will attempt to automatically deduct the prorated amount of :currentGrossPrice for this billing period from your registered credit card.',
                  { ':currentGrossPrice': this.formatPrice(err.getGrossPrice(), '€') },
                );
              }
              else {
                priceMessage = this.tr(
                  'We will attempt to automatically deduct the prorated amount of :currentGrossPrice (:currentNetPrice + VAT) for this billing period from your registered credit card.',
                  {
                    ':currentNetPrice': this.formatPrice(err.getNetPrice(), '€'),
                    ':currentGrossPrice': this.formatPrice(err.getGrossPrice(), '€'),
                  },
                );
              }

              this.$confirmation.open({
                title: this.tr('Connecting new extra asset'),
                content: this.tr(
                  'You are attempting to connect a new asset, but your current subscription allows a maximum of :maxAssets assets.',
                  { ':maxAssets': company.max_assets },
                ) + '<br/>' +

                this.tr(
                  'The cost to use an additional asset is :price.',
                  { ':price': this.formatPrice(company.current_subscription?.package?.extra_asset_price, '€', company.current_subscription?.package?.period) },
                ) + '<br/><br/>' +

                priceMessage,
                labelCancel: this.tr('Cancel'),
                labelConfirm: this.tr('Confirm'),
                cancel: () => {
                  this.loadingId = null;
                },
                confirm: () => connectProcessCallback(true)
                  .then(resp => {
                    if (company.max_assets !== null && company.max_assets === company.usedAssetsCount - 1) {
                      ++company.max_assets;
                      ++company.current_subscription.extra_assets;
                      ++company.paid_subscription.extra_assets;
                    }

                    return resp;
                  }),
              });
            }
            else {
              this.error(err);
            }
          }
          finally {
            this.loadingId = null;
            this.$emit('loading', false);
          }

          return connectedAdAccount;
        };

        connectProcessCallback(false);
      }
      else {
        ToolService.disconnectPrecheck({ adAccountId: adAccount.id })
          .then(async resp => {
            if (resp.defunc_rules.length !== 0) {
              this.$refs.disconnectConfirmModal.open(
                { adAccount },
                resp.defunc_rules,
                () => this.disconnect(adAccount),
              );
            }
            else {
              await this.disconnect(adAccount);
            }

            return resp;
          })
          .catch(err => this.error(err))
          .finally(() => this.loadingId = null);
      }
    },
    disconnect(adAccount) {
      this.$emit('loading', true);
      const company = this.$root.currentCompany;

      return AdAccountService.disconnect(adAccount.id)
        .then(resp => {
          const index = company.ad_accounts.findIndex(obj => obj.id === adAccount.id && obj.platform === PLATFORM);
          company.ad_accounts.splice(index, 1);

          this.$toast.success(this.tr('Ad account was successfully disconnected.'));

          return resp;
        })
        .finally(() => this.$emit('loading', false));
    },
    isConnected(adAccount) {
      return this.selected.includes(adAccount.id);
    },
    openCommunicatorDebug(adAccount) {
      this.$modal.show(
        CommunicatorDebug,
        { entity: adAccount },
        { class: 'modal-without-padding modal-without-scroll modal-height--640 modal-width--960' },
      );
    },
  },
};
</script>
