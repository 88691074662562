<template>
  <div class="improved-content-component">
    <div v-if="title" class="meta">
      <div class="title">
        {{ tr('Title') }}
        <i
          class="icon copy primary"
          @click="copyToClipboard($event)"
        />
      </div>
      <div class="details">{{ title }}</div>
    </div>
    <div v-if="description" class="meta">
      <div class="title">
        {{ tr('Description') }}
        <i
          class="icon copy primary"
          @click="copyToClipboard($event)"
        />
      </div>
      <div class="details">{{ description }}</div>
    </div>
    <div v-if="postText" class="meta">
      <div class="title">
        {{ tr('Post text') }}
        <i
          class="icon copy primary"
          @click="copyToClipboard($event)"
        />
      </div>
      <div class="details">{{ postText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImprovedContent',
  props: {
    title: {
      type: String,
      required: false,
      default:() => null,
    },
    description: {
      type: String,
      required: false,
      default:() => null,
    },
    postText: {
      type: String,
      required: false,
      default:() => null,
    },
  },
  data() {
    return {
      copiedIndex: null,
    };
  },
  methods: {
    copyToClipboard(event) {
      const metaElement = event.target.closest('.meta');
      if (metaElement) {
        const detailsText = metaElement.querySelector('.details').innerText;

        navigator.clipboard.writeText(detailsText).then(() => {
          this.$toast.success(this.tr('The text has been copied to the clipboard.'));
          return null;
        }).catch(err => this.error(err));
      }
    },
  },
};
</script>
