<template>
  <div :class="wrapperClasses">
    <template v-if="isSkeleton">
      <skeleton width="62px" height="62px" radius="10px" />
      <div class="text">
        <skeleton width="100%" />
        <skeleton width="98%" />
      </div>
    </template>
    <template v-else>
      <lazy-image :src="imageUrl" />
      <div class="text">
        {{ computedText }}
      </div>
    </template>
  </div>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue';
import Skeleton from '@/components/Skeleton.vue';
import Ad from '@/entities/Ad.js';
import AdCopyMagicItem from '@/entities/AdCopyMagicItem.js';
import Post from '@/entities/Post.js';

export default {
  name: 'InlineSocialContent',
  components: {
    LazyImage,
    Skeleton,
  },
  props: {
    entity: {
      type: [ Ad, Post ],
      required: false,
      default: () => null,
    },
    text: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  computed: {
    wrapperClasses() {
      return {
        'inline-social-content-component': true,
        'is-skeleton': this.isSkeleton,
      };
    },
    isSkeleton() {
      return this.entity === null;
    },
    imageUrl() {
      if (this.entity instanceof Ad && this.entity.post) {
        return this.entity.post.image_url;
      }
      else if (this.entity instanceof Post) {
        return this.entity.image_url;
      }
      else if (this.entity instanceof AdCopyMagicItem) {
        return this.entity.image?.url;
      }

      return null;
    },
    computedText() {
      if (this.text) {
        return this.text;
      }

      if (this.entity instanceof Ad) {
        return this.entity.text ?? this.entity.name;
      }
      else if (this.entity instanceof Post) {
        return this.entity.text;
      }
      else if (this.entity instanceof AdCopyMagicItem) {
        return this.entity.text;
      }

      return null;
    },
  },
};
</script>
