import BaseEntity from '@/entities/BaseEntity';

export default class SupportUser extends BaseEntity {
  id = null;
  firstname = '';
  email = '';
  level = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
