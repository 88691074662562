var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-change-log-fields-component" },
    [
      _c("div", { staticClass: "field-row header" }, [
        _c("div", { staticClass: "field" }),
        _c("div", { staticClass: "value value--original" }, [
          _vm._v(" " + _vm._s(_vm.tr("Original value")) + " "),
        ]),
        _c("div", { staticClass: "value value--new" }, [
          _vm._v(" " + _vm._s(_vm.tr("New value")) + " "),
        ]),
      ]),
      _vm._l(_vm.entity.changes, function (item) {
        return _c(
          "div",
          {
            key: "field-item--" + item.id + "-" + item.field,
            staticClass: "field-row",
          },
          [
            _c("div", { staticClass: "field" }, [
              _vm._v(_vm._s(_vm.tr(item.field))),
            ]),
            _c(
              "div",
              { staticClass: "value value--original" },
              [
                item.field === "field.tasks.conditions" ||
                item.field === "field.conditions"
                  ? _c(
                      "div",
                      [
                        _c("condition-group", {
                          attrs: {
                            action: _vm.entity.rule.action,
                            "ad-account": _vm.adAccount,
                            disabled: true,
                          },
                          model: {
                            value: item.original,
                            callback: function ($$v) {
                              _vm.$set(item, "original", $$v)
                            },
                            expression: "item.original",
                          },
                        }),
                      ],
                      1
                    )
                  : Array.isArray(item.original) && item.original.length
                  ? _vm._l(
                      _vm.getDiffedArray(item.original, item.new),
                      function (row, rowKey) {
                        return _c(
                          "div",
                          {
                            key:
                              "field-item--" +
                              item.id +
                              "-" +
                              item.field +
                              "--original--" +
                              rowKey,
                          },
                          [
                            _c(
                              "span",
                              {
                                class:
                                  "value-item " +
                                  (row.highlightable
                                    ? "value-item--changed"
                                    : ""),
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.formatValue(row.value)) + " "
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    )
                  : _c("span", {
                      staticClass: "value-item value-item--changed",
                      domProps: {
                        innerHTML: _vm._s(_vm.formatValue(item.original)),
                      },
                    }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "value value--new" },
              [
                item.field === "field.tasks.conditions" ||
                item.field === "field.conditions"
                  ? _c("condition-group", {
                      attrs: {
                        action: _vm.entity.rule.action,
                        "ad-account": _vm.adAccount,
                        disabled: true,
                      },
                      model: {
                        value: item.new,
                        callback: function ($$v) {
                          _vm.$set(item, "new", $$v)
                        },
                        expression: "item.new",
                      },
                    })
                  : Array.isArray(item.new) && item.new.length
                  ? _vm._l(
                      _vm.getDiffedArray(item.new, item.original),
                      function (row, rowKey) {
                        return _c(
                          "div",
                          {
                            key:
                              "field-item--" +
                              item.id +
                              "-" +
                              item.field +
                              "--new--" +
                              rowKey,
                          },
                          [
                            _c(
                              "span",
                              {
                                class:
                                  "value-item " +
                                  (row.highlightable
                                    ? "value-item--changed"
                                    : ""),
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.formatValue(row.value)) + " "
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    )
                  : _c("span", {
                      staticClass: "value-item value-item--changed",
                      domProps: {
                        innerHTML: _vm._s(_vm.formatValue(item.new)),
                      },
                    }),
              ],
              2
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }