var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "improved-copy-component" },
    [
      _vm.showTitle
        ? _c("h3", [_vm._v(_vm._s(_vm.tr("Improved copy")))])
        : _vm._e(),
      _c("div", {
        staticClass: "text",
        domProps: {
          innerHTML: _vm._s(
            _vm.entity.improved_text.replace(/(?:\r\n|\r|\n)/g, "<br>")
          ),
        },
      }),
      _c("ai-score", {
        attrs: { value: _vm.entity.value, label: _vm.tr("AI Score") + ":" },
      }),
      _vm._l(_vm.entity.details, function (row, key) {
        return _c(
          "div",
          { key: "improved-copy-meta-" + key, staticClass: "aspect" },
          [
            _vm._v(
              " " +
                _vm._s(_vm.tr(row.title)) +
                ": " +
                _vm._s(row.value) +
                " / 10 "
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }