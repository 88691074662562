<template>
  <div class="auth-page auth-view">
    <div class="header">
      <a :href="mainSiteUrl">
        <i class="logo-inline" />
      </a>
    </div>
    <div class="content-wrapper">
      <div class="input-form-container">
        <h1>{{ pageTitle }}</h1>
        <router-view />
      </div>
    </div>
    <div class="placeholder" />
  </div>
</template>

<script>
export default {
  name: 'AuthView',
  computed: {
    mainSiteUrl() {
      return this.$app.isAd ? 'https://infinite.ad' : 'https://infinite.buzz';
    },
    pageTitle() {
      let path = this.$route.path;
      let page = '';

      path.includes('login') ? (page = this.tr('Log in')) : '';
      path.includes('signup') ? (page = this.tr('Signup')) : '';
      path.includes('forgotten-password')
        ? (page = this.tr('Forgotten password'))
        : '';
      path.includes('password-reset') ? (page = this.tr('Password reset')) : '';

      return page;
    },
  },
};
</script>
