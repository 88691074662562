var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rule-type-flag-component" }, [
    _c("div", { class: [_vm.wrapperClasses, _vm.flagClass] }, [
      _vm.flagIcon ? _c("i", { class: ["icon", _vm.flagIcon] }) : _vm._e(),
      _vm._v(" " + _vm._s(_vm.flagLabel) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }