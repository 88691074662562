var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-waiting-list-status-component" },
    [
      _vm.isActivityManagementItem
        ? [
            !_vm.entity.isHandled && !_vm.entity.is_current
              ? _c("div", { staticClass: "is-not-actual" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "The item affected by the activity is no longer available."
                        )
                      ) +
                      " "
                  ),
                ])
              : [
                  _c(
                    "div",
                    { staticClass: "top" },
                    [
                      _vm.isHandlingButtonVisible
                        ? [
                            _c(
                              "button",
                              {
                                staticClass: "primary",
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: () => _vm.activityManagementAccept(),
                                },
                              },
                              [
                                _vm.loadingButton === _vm.entity.id + "--accept"
                                  ? _c("i", {
                                      staticClass: "icon loader white",
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(_vm.tr("Accept")) + " "),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "red",
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: () => _vm.activityManagementReject(),
                                },
                              },
                              [
                                _vm.loadingButton === _vm.entity.id + "--reject"
                                  ? _c("i", { staticClass: "icon loader" })
                                  : _c("i", { staticClass: "icon delete" }),
                              ]
                            ),
                          ]
                        : [
                            _vm.entity.isAccepted
                              ? _c("i", {
                                  staticClass: "icon checkmark-circle primary",
                                })
                              : _vm.entity.isRejected
                              ? _c("i", {
                                  staticClass: "icon close-circle red",
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.formatToYMD(_vm.entity.handled_at) +
                                      " " +
                                      _vm.formatTime(_vm.entity.handled_at),
                                    expression:
                                      "formatToYMD(entity.handled_at) + ' ' + formatTime(entity.handled_at)",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.activityManagementHandledAt) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      class: {
                        bottom: true,
                        "is-handled": !_vm.isHandlingButtonVisible,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.activityManagementStatus) + " ")]
                  ),
                ],
          ]
        : _vm.isAiProhibitedItem
        ? [
            !_vm.entity.isHandled && !_vm.entity.is_current
              ? _c("div", { staticClass: "is-not-actual" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "The result is no longer relevant because the automation or the entry has been modified since the review."
                        )
                      ) +
                      " "
                  ),
                ])
              : [
                  _c(
                    "div",
                    { staticClass: "top" },
                    [
                      _vm.isHandlingButtonVisible
                        ? [
                            _vm.entity.is_current && !_vm.entity.isHandled
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "primary",
                                    attrs: {
                                      disabled:
                                        _vm.loadingButton !== null ||
                                        _vm.$root.isSupportMode,
                                    },
                                    on: { click: () => _vm.overrideAi() },
                                  },
                                  [
                                    _vm.loadingButton ==
                                    _vm.entity.id + "--override"
                                      ? _c("i", {
                                          staticClass: "icon loader white",
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(_vm.tr("Override")) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : [
                            _c("i", {
                              staticClass: "icon checkmark-circle primary",
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.formatToYMD(_vm.entity.handled_at) +
                                      " " +
                                      _vm.formatTime(_vm.entity.handled_at),
                                    expression:
                                      "formatToYMD(entity.handled_at) + ' ' + formatTime(entity.handled_at)",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.aiOverrideHandledAt) + " "
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      class: {
                        bottom: true,
                        "is-handled": !_vm.isHandlingButtonVisible,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.aiOverrideStatus) + " ")]
                  ),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }