<template>
  <div class="instagram-post-preview-component post-preview">
    <div class="top">
      <div class="profile">
        <lazy-image
          v-if="post && post.source_profile.picture"
          :src="post.source_profile.picture"
          class="image"
        />
        <div class="profile-data">
          <div class="name">
            {{ post.source_profile.name }}
          </div>
          <a
            :href="post.url"
            target="_blank"
            class="date"
          >
            {{ formattedDate(post.created_at) }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="post.attachments" :class="post.media_type" class="attachment">
      <lazy-image
        v-if="isPhoto || isAlbum"
        :src="imageSrc"
      />
      <video
        v-if="isVideo"
        :src="videoSrc"
        autoplay
        loop
        muted
      ></video>
    </div>
    <div class="social">
      <div class="reactions">
        <i class="icon ig-like light-black" />
        <i class="icon ig-comment light-black" />
        <i class="icon ig-send light-black" />
        <i class="icon ig-bookmark light-black" />
      </div>
      <span v-if="likesStr">
        {{ likesStr }}
      </span>
    </div>
    <div class="text-section">
      <span class="profile-name">
        {{ post.source_profile.name }}
      </span>
      <span v-if="post.text" class="main-text" v-html="post.text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
    </div>
    <div v-if="commentsStr" class="comments">
      {{ commentsStr }}
    </div>
  </div>
</template>

<script>
import LazyImage from '@/components/LazyImage.vue';
import Post from '@/entities/Post.js';
import { formatTime, formatToStringMD } from '@/utils/date.js';

export default {
  components: {
    LazyImage,
  },
  props: {
    post: {
      type: Post,
      required: true,
    },
  },
  computed: {
    isPhoto() {
      return this.post.media_type === 'photo';
    },
    isAlbum() {
      return this.post.media_type === 'album';
    },
    isVideo() {
      return this.post.media_type === 'video';
    },
    imageSrc() {
      if (this.post.attachments[0].media.image) {
        return this.post.attachments[0].media.image.src;
      }
      if (this.post.attachments[0].subattachments[0].media.image) {
        return this.post.attachments[0].subattachments[0].media.image.src;
      }

      return null;
    },
    videoSrc() {
      if (this.post.attachments[0].media.source) {
        return this.post.attachments[0].media.source;
      }

      return null;
    },
    likesStr() {
      if (this.post.metric.reactions === 1) {
        return this.post.metric.reactions + ' ' + this.tr('like');
      }
      else if (this.post.metric.reactions > 1) {
        return this.post.metric.reactions + ' ' + this.tr('likes');
      }

      return null;
    },
    commentsStr() {
      if (this.post.comment_count === 1) {
        return this.tr('View all') + ' ' + this.post.comment_count + ' ' + this.tr('comment');
      }
      else if (this.post.comment_count > 1) {
        return this.tr('View all') + ' ' + this.post.comment_count + ' ' + this.tr('comments');
      }

      return null;
    },
  },
  methods: {
    formatTime,
    formattedDate(date) {
      return formatToStringMD(date, this.tr);
    },
  },
};
</script>

