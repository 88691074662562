<template>
  <div class="magic-tool-item-details-component">
    <form-step-tab :items="tabs" />

    <div v-if="isLoading" class="inline-loader">
      <i class="icon loader" /> {{ tr('Loading...') }}
    </div>
    <div v-else-if="entity !== null" class="scroll-wrapper">
      <div v-if="isOriginal" class="original">
        <div class="title">
          {{ tr('Original text') }}
        </div>

        <facebook-ad-preview
          :text="entity.text"
          :cta-title="entity.title"
          :cta-description="entity.description"
          :cta-button="previewCtaButton"
          :img-url="entity.image ? entity.image.url : null"
        />

        <div class="ai-values">
          <ai-score :value="entity.value" :label="tr('AI Score') + ':'" />

          <div v-if="entity.content_actuality_check_status !== null" class="ai-inline-value">
            <template v-if="entity.contentActualityCheckInProgress || entity.expiry_date">
              <b>{{ tr('Content expired at') }}:</b>

              <i v-if="entity.contentActualityCheckInProgress" class="icon loader" />
              <span v-else>
                {{ formatToYMD(entity.expiry_date) + ' / ' + formatTime(entity.expiry_date)}}
              </span>
            </template>
            <b v-else>
              {{ tr('The content has no defined expiration date.') }}
            </b>
          </div>

          <div class="ai-inline-value">
            <b>{{ tr('Meta policy check') }}:</b>

            <i v-if="entity.policyCheckInProgress" class="icon loader" />
            <post-content-policy-check-status v-else :value="entity.policy_check_is_prohibited" />
          </div>

          <div v-if="entity.policy_check_is_prohibited && entity.policy_check_reason" class="ai-non-inline-value">
            <b>{{ tr('Reason for denial') + ':' }}</b>
            {{ entity.policy_check_reason }}
          </div>
        </div>

        <copy-evaluation v-if="entity.details_translation" :details="entity.details_translation" />

        <button
          class="button red full-width"
          @click="() => openDeleteConfirmationModal()"
        >
          {{ tr('Delete evaluation')}}
        </button>
      </div>

      <div class="vertical-divider" />

      <div v-if="isImproved" class="improved">
        <div v-if="!entity.improved_copy || entity.improved_copy.inProgress" class="request-improved">
          {{ tr('Click on \'Generate improved copy\' to get your ad\'s improved version.') }}

          <button
            class="primary"
            :disabled="entity.improved_copy"
            @click="() => improveCopy()"
          >
            <i v-if="entity.improved_copy" class="icon loader white" />
            {{ tr('Generate improved copy') }}
          </button>

          <div v-if="entity.improved_copy && entity.improved_copy.inProgress">
            {{ tr('We are already working on it! The evaluation will be completed within 1-2 minutes.') }}
          </div>
        </div>

        <template v-if="entity.improved_copy && entity.improved_copy.isDone">
          <div class="title">
            {{ tr('Improved text') }}
          </div>
          <facebook-ad-preview
            :text="entity.improved_copy.improved_text"
            :cta-title="entity.improved_copy.improved_title"
            :cta-description="entity.improved_copy.improved_description"
            :img-url="entity.image ? entity.image.url : null"
            :cta-button="previewCtaButton"
          />

          <div class="title">{{ tr('Improved Content Scoring:') }}</div>
          <ai-score :value="entity.improved_copy.value" />
          <copy-evaluation v-if="entity.improved_copy.details_translation" :details="entity.improved_copy.details_translation" />
          <improved-content
            :title="entity.improved_copy.improved_title"
            :description="entity.improved_copy.improved_description"
            :post-text="entity.improved_copy.improved_text"
          />
        </template>

        <template v-if="entity.improved_copy && !entity.improved_copy.isDone && !entity.improved_copy.inProgress">
          {{ tr('An error occurred while creating the corrected version.') }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import CopyEvaluation from '@/components/CopyEvaluation.vue';
import FacebookAdPreview from '@/components/FacebookAdPreview.vue';
import FormStepTab from '@/components/FormStepTab.vue';
import ImprovedContent from '@/components/ImprovedContent.vue';
import MagicToolItemDelete from '@/components/ModalContents/MagicToolItemDelete.vue';
import PostContentPolicyCheckStatus from '@/components/PostContentPolicyCheckStatus.vue';
import { AI_STATUS } from '@/consts';
import ImprovedCopy from '@/entities/ImprovedCopy.js';
import MagicToolService from '@/services/MagicTool.js';
import { formatTime, formatToYMD } from '@/utils/date';

export default {
  name: 'MagicToolItemDetails',
  components: {
    AiScore,
    CopyEvaluation,
    FacebookAdPreview,
    FormStepTab,
    ImprovedContent,
    PostContentPolicyCheckStatus,
  },
  data: () => ({
    isLoading: false,
    entity: null,
    fetchTimer: null,
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    currentTab() {
      return this.$route.params.tab;
    },
    isOriginal() {
      return this.currentTab === 'original';
    },
    isImproved() {
      return this.currentTab === 'improved';
    },
    tabs() {
      const tabs = [];

      tabs.push({
        label: this.tr('Original text'),
        link: `/magic-tool/${this.id}/original`,
      });

      tabs.push({
        label: this.tr('Improved text'),
        link: `/magic-tool/${this.id}/improved`,
      });

      return tabs;
    },
    previewCtaButton() {
      switch (this.entity.goal) {
        case 'conversion':
          return this.tr('Shop Now');

        case 'traffic':
          return this.tr('Learn more');

        case 'app_promotion':
          return this.tr('Use App');
      }

      return null;
    },
  },
  created() {
    this.loadData();
  },
  beforeDestroy() {
    this.destroyFetchTimer();
  },
  methods: {
    formatTime,
    formatToYMD,
    loadData() {
      return MagicToolService.get(this.id)
        .then(resp => {
          this.entity = resp;
          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
    improveCopy() {
      if (this.entity.improved_copy) {
        return;
      }

      this.entity.improved_copy = new ImprovedCopy({ status: AI_STATUS.IN_PROGRESS });

      MagicToolService.improve({ adCopyMagicItemId: this.entity.id })
        .then(resp => {
          this.entity.improved_copy = resp;

          this.$toast.clear();
          this.$toast.success(this.tr('We are already working on it! The improved copy will be ready within 1-2 minutes.'));

          this.initFetchTimer();

          return resp;
        })
        .catch(err => {
          this.entity.improved_copy = null;
          this.error(err);
        });
    },
    openDeleteConfirmationModal() {
      this.$fullscreenModal.open(
        this,
        MagicToolItemDelete,
        {
          text: 'MagicToolItemDelete',
          entity: this.entity,
        },
        {
          classes: 'magic-tool-item-delete-modal confirm-modal magic-tool-item-delete-modal--active',
        },
      );
    },
    initFetchTimer() {
      this.fetchTimer = setInterval(async () => {
        await this.loadData();
        if (!this.entity.improved_copy?.inProgress) {
          this.destroyFetchTimer();
        }
      }, 5000);
    },
    destroyFetchTimer() {
      if (this.fetchTimer !== null) {
        clearTimeout(this.fetchTimer);
        this.fetchTimer = null;
      }
    },
  },
};
</script>
