var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--stop rule-form-step--stop--step-1",
    },
    [
      _c("name-block", {
        attrs: {
          entity: _vm.entity,
          description: _vm.tr(
            "This will be the name of your currently created automation. The name helps identify and easily manage the different automations you have set up."
          ),
          disabled: _vm.$parent.readonlyMode,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c("notifiable-users-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("activity-management-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        {
          staticClass: "ai-assist-block",
          attrs: {
            title: _vm.tr("AI Assist") + "<div class='beta-flag'>Beta</div>",
          },
        },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c(
              "label",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.entity.settings.stop_outdated_ads,
                      expression: "entity.settings.stop_outdated_ads",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    disabled: _vm.$parent.readonlyMode,
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.entity.settings.stop_outdated_ads
                    )
                      ? _vm._i(_vm.entity.settings.stop_outdated_ads, null) > -1
                      : _vm.entity.settings.stop_outdated_ads,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.entity.settings.stop_outdated_ads,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "stop_outdated_ads",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "stop_outdated_ads",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.entity.settings, "stop_outdated_ads", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.tr("Stop outdated ads")) + " "),
                _c("info-popup", [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.tr(
                          "Automatically stop advertisements whose content becomes outdated."
                        )
                      ),
                    },
                  }),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c("ad-account-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode || !_vm.entity.isNew,
          description: _vm.tr(
            "You can choose the ad account in which you want to manage your advertisement. (This is useful if you manage multiple accounts.)"
          ),
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c("operation-area-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c("post-type-block", {
        attrs: {
          entity: _vm.entity,
          description: _vm.tr(
            "Here you can select what type of posts and appearances should be handled by the automation. Please note that the automation will handle only those post ads that you created using Infinite∞Ad."
          ),
          disabled: _vm.$parent.readonlyMode,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        {
          staticClass: "filters",
          attrs: {
            title: _vm.tr("Filters"),
            description: _vm.tr(
              'You can set different filters to determine the conditions under which the ads should stop or scaling up. You can add multiple conditions to an ad by clicking on the "+" sign, and you can connect them with the "and" or "or" options.'
            ),
          },
        },
        [
          _c("condition-group", {
            attrs: {
              action: _vm.entity.action,
              platforms: _vm.entity.selectedSourcePlatforms,
              "ad-account": _vm.entity.selectedAdAccount,
              disabled: !_vm.filterIsAvailable || _vm.$parent.readonlyMode,
            },
            model: {
              value: _vm.entity.conditions,
              callback: function ($$v) {
                _vm.$set(_vm.entity, "conditions", $$v)
              },
              expression: "entity.conditions",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }