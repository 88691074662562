var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "connect-unsettled-ad-account-modal",
      attrs: {
        name: "connect-unsettled-ad-account",
        overlayTransition: "fade-modal-overlay",
      },
      on: { closed: () => _vm.$emit("closed") },
    },
    [
      _c("i", { staticClass: "icon close", on: { click: () => _vm.close() } }),
      _vm.adAccount !== null
        ? _c(
            "form-block",
            {
              attrs: {
                title: _vm.tr("Unsettled Ad Account"),
                description: _vm.modalDescription,
              },
            },
            [
              _c("div", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "You can view and settle your debt at Meta via the following link:"
                      )
                    )
                ),
                _c("br"),
                _c("a", { attrs: { href: _vm.metaLink, target: "_blank" } }, [
                  _vm._v(_vm._s(_vm.metaLink)),
                ]),
              ]),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.isLoading },
                    on: { click: () => _vm.close() },
                  },
                  [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "primary",
                    attrs: { disabled: _vm.isLoading },
                    on: { click: () => _vm.tryAgain() },
                  },
                  [
                    _vm.isLoading
                      ? _c("i", { staticClass: "icon loader white" })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(_vm.tr("Paid, let's try again")) + " "),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }