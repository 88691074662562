<template>
  <div :class="wrapperClasses">
    <system-level-notifications v-if="!minimal" space-top />

    <div v-if="list.length > 10 && !minimal" class="actions">
      <input
        v-model="searchTerm"
        type="text"
        class="text"
        :placeholder="tr('Search') + '...'"
      />
    </div>

    <template v-if="list.length === 0">
      <notification-card
        v-if="!hasConnectedProfile"
        :image="require('@/assets/img/onboarding/step-profile.png')"
        empty-screen
      >
        {{ tr('If you want to use an ad account, first you should connect you Meta Profile here') }}

        <button
          v-if="connectButtonVisible"
          class="button primary"
          @click="() => $emit('connect')"
        >
          + {{ tr('Connect Your Profile') }}
        </button>
      </notification-card>
      <notification-card v-else empty-screen>
        {{ tr('There are no Ad accounts connected to this user\'s company.') }}
      </notification-card>
    </template>
    <notification-card v-else-if="filteredList.length === 0" class="no-result" empty-screen>
      {{ tr('There are no results matching your search criteria.') }}
    </notification-card>
    <new-sorted-table
      v-else
      :data="filteredList"
      :initial-sort="{ key: 'name', direction: 'asc' }"
    >
      <template #header>
        <table-head col="name" min-width="200px" sortable>{{ tr('Name') }}</table-head>
        <table-head col="is_active" width="180px" class="status-cell">{{ tr('Status') }}</table-head>
        <table-head v-if="!minimal" col="external_id" width="160px" class="external-id-cell" sortable>{{ tr('Account ID') }}</table-head>
        <table-head v-if="!minimal" col="connected_by" width="180px">{{ tr('Connected by') }}</table-head>
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.external_id">
          <table-data col="name">
            <label>
              <input type="checkbox" disabled v-if="row.account_status !== 1 && !isUnsettled(row)" v-tooltip.right="tr('Not allowed')" />
              <i v-else-if="loadingId === row.external_id" class="icon loader" />
              <input
                v-else
                type="checkbox"
                :disabled="!!loadingId || !isModifiable(row)"
                :checked="selected.includes(row.id)"
                @change="($event) => checkboxChanged($event, row)"
              />

              <div class="name-wrapper" :class="{ 'disabled': row.account_status !== 1 && !isUnsettled(row) }">
                <div class="name">
                  {{ row.name }}
                </div>
              </div>
            </label>
          </table-data>
          <table-data col="is_active" class="status-cell">
            <span :class="statusClasses(row)">
              {{ statusLabel(row) }}
            </span>
          </table-data>
          <table-data v-if="!minimal" col="external_id" class="external-id-cell">
            {{ row.external_id }}
          </table-data>
          <table-data v-if="!minimal" col="connected_by">
            {{ selected.includes(row.id) && row.owner ? row.owner.fullname : '-' }}
          </table-data>
        </table-row>
      </template>
    </new-sorted-table>

    <disconnect-confirm-modal ref="disconnectConfirmModal" />
    <connect-unsettled-ad-account-modal
      ref="connectUnsettledAdAccountModal"
      :ad-account="accountInModal"
      @closed="() => accountInModal = null"
      @settled="(adAccount) => { accountInModal.status = adAccount.status; checkboxChanged(null, adAccount); }"
    />
    <connect-ad-account-without-payment-method-modal
      ref="connectAdAccountWithoutPaymentMethodModal"
      :ad-account="accountInModal"
      @closed="() => accountInModal = null"
      @setted="(adAccount) => { accountInModal.has_payment_method = adAccount.has_payment_method; checkboxChanged(null, adAccount); }"
    />
  </div>
</template>

<script>
import ConnectAdAccountWithoutPaymentMethodModal from '@/components/ConnectAdAccountWithoutPaymentMethodModal.vue';
import ConnectUnsettledAdAccountModal from '@/components/ConnectUnsettledAdAccountModal.vue';
import DisconnectConfirmModal from '@/components/DisconnectConfirmModal.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';

import AdAccountService from '@/services/AdAccount.js';
import User from '@/entities/User.js';
import ToolService from '@/services/Tool.js';

import { formatPrice } from '@/utils/string.js';
import { PaymentRequiredError } from '@/errors';

const PLATFORM = 'facebook';

export default {
  name: 'ConnectedPlatformsMetaAdAccountsView',
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConnectAdAccountWithoutPaymentMethodModal,
    ConnectUnsettledAdAccountModal,
    DisconnectConfirmModal,
    NotificationCard,
    SystemLevelNotifications,
  },
  data: () => ({
    searchTerm: '',
    loadingId: null,
    accountInModal: null,
  }),
  computed: {
    wrapperClasses() {
      return {
        'connected-platforms-meta-ad-accounts-view': true,
        'minimal': this.minimal,
      };
    },
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
    hasConnectedProfile() {
      let profileItems = this.$root.loggedInUser.connected_profiles.filter(
        (profile) => profile.platform === 'facebook'
      );

      return profileItems.length !== 0;
    },
    list() {
      let adAccounts = [];
      let processedAdAccountIds = [];

      let companyAdAccounts = this.$root.currentCompany.ad_accounts.filter(adAcc => adAcc.platform === PLATFORM);
      companyAdAccounts.forEach(adAcc => {
        processedAdAccountIds.push(adAcc.external_id);
        adAccounts.push(adAcc);
      });

      let connectedProfiles = this.$root.loggedInUser.connected_profiles.filter(profile => profile.platform === PLATFORM);
      connectedProfiles.forEach(profile => {
        profile.details.ad_accounts.forEach(adAcc => {
          if (processedAdAccountIds.includes(adAcc.id)) {
            return;
          }

          adAcc.external_id = adAcc.id;
          adAccounts.push(adAcc);
        });
      });

      return adAccounts;
    },
    filteredList() {
      let term = this.searchTerm.trim().toLowerCase();
      if (term.length === 0) {
        return this.list;
      }

      return this.list.filter(i => i.name.toLowerCase().includes(term));
    },
    selected() {
      return this.$root.currentCompany.ad_accounts
        .filter(i => i.platform === PLATFORM)
        .map(i => i.id);
    },
    adAccountLabels() {
      let result = {};

      this.$store.getters['app/facebookAdAccountStatuses'].forEach(i => result[i.machine_name] = i.label);

      return result;
    }
  },
  methods: {
    formatPrice,
    statusClasses(item) {
      return {
        'status': true,
        'active': this.selected.includes(item.id),
        'not-connected': !this.selected.includes(item.id),
        'disabled': item.account_status != 1,
      };
    },
    statusLabel(item) {
      if (item.account_status != 1) {
        return this.tr('Account status') + ': ' + this.tr(this.adAccountLabels[item.account_status]);
      }
      else if (item.has_funding_source === false) {
        return this.tr('Missing payment method');
      }

      return this.selected.includes(item.id) ? this.tr('Active') : this.tr('Not connected');
    },
    isUnsettled(item) {
      return item.account_status === 3 || item.account_status === 8;
    },
    hasNoPaymentMethod(item) {
      return item.has_funding_source === false;
    },
    isModifiable(item) {
      return !this.selected.includes(item.id) || (item.owner.id === this.$root.loggedInUser.id);
    },
    checkboxChanged(event, adAccount) {
      if (event && event.target.checked && this.isUnsettled(adAccount)) {
        if (event) {
          event.target.checked = false;
        }

        this.accountInModal = adAccount;
        this.$refs.connectUnsettledAdAccountModal.open();
        return;
      }

      if (event && event.target.checked && this.hasNoPaymentMethod(adAccount)) {
        if (event) {
          event.target.checked = false;
        }

        this.accountInModal = adAccount;
        this.$refs.connectAdAccountWithoutPaymentMethodModal.open();
        return;
      }

      this.loadingId = adAccount.external_id;
      const company = this.$root.currentCompany

      if (!company.current_active_package) {
        this.$toast.clear();
        this.$toast.error(this.tr('No active plan found. Please subscribe to a plan before connecting new assets.'));
        this.loadingId = null;
        event.target.checked = false;
        return;
      }

      if (!this.selected.includes(adAccount.id)) {
        this.$emit('loading', true);
        const connectProcessCallback = (chargeExtraAsset) => AdAccountService.connect(PLATFORM, adAccount.external_id, chargeExtraAsset)
          .then(adAccount => {
            adAccount.owner = new User({
              id: this.$root.loggedInUser.id,
              firstname: this.$root.loggedInUser.firstname,
              lastname: this.$root.loggedInUser.lastname,
            });
            company.ad_accounts.push(adAccount);
          })
          .catch((err) => {
            if (err instanceof PaymentRequiredError) {
              this.$confirmation.open({
                title: this.tr('Connecting new extra asset'),
                content: this.tr(
                  'You are attempting to connect a new asset, but your current subscription allows a maximum of :maxAssets assets.',
                  { ':maxAssets': company.max_assets }
                ) + '<br/>' +

                this.tr(
                  'The cost to use an additional asset is :price.',
                  { ':price': this.formatPrice(company.current_active_package.extra_asset_price, company.current_active_package.period) }
                ) + '<br/><br/>' +

                this.tr(
                  'We will attempt to automatically deduct the prorated amount of :currentPrice for this billing period from your registered credit card.',
                  { ':currentPrice': this.formatPrice(err.getPrice()) }
                ),
                labelCancel: this.tr('Cancel'),
                labelConfirm: this.tr('Confirm'),
                cancel: () => { this.loadingId = null },
                confirm: () => {
                  return connectProcessCallback(true)
                    .then(() => {
                      if (company.max_assets !== null && company.max_assets === company.usedAssetsCount - 1) {
                        ++company.max_assets;
                        ++company.current_extra_assets;
                        ++company.paid_extra_assets;
                      }
                    });
                }
              });
            }
            else {
              this.error(err);
            }
          })
          .finally(
            () => {
              this.loadingId = null;
              this.$emit('loading', false);
            }
          );

        connectProcessCallback(false);
      }
      else {
        ToolService.disconnectPrecheck({ adAccountId: adAccount.id })
          .then(resp => {
            if (resp.defunc_rules.length !== 0) {
              this.$refs.disconnectConfirmModal.open(
                { adAccount },
                resp.defunc_rules,
                () => this.disconnect(adAccount)
              );
            }
            else {
              return this.disconnect(adAccount);
            }
          })
          .catch((err) => this.error(err))
          .finally(() => this.loadingId = null);
      }
    },
    disconnect(adAccount) {
      this.$emit('loading', true);
      const company = this.$root.currentCompany;

      return AdAccountService.disconnect(adAccount.id)
        .then(() => {
          let index = company.ad_accounts.findIndex(
            (obj) => obj.id === adAccount.id && obj.platform === PLATFORM
          );
          company.ad_accounts.splice(index, 1);

          this.$toast.success(this.tr('Data source was successfully disconnected.'));
        })
        .finally(() => this.$emit('loading', false));
    }
  },
};
</script>
