var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-billing-view" },
    [
      _c("p", { staticClass: "top-text" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.tr(
                "Changes to these preferences will apply to future invoices only. If you need a past invoice reissued, please contact hello@infinite.ad."
              )
            ) +
            " "
        ),
      ]),
      _c(
        "form-block",
        { attrs: { title: _vm.tr("Billing name") + "<sup>*</sup>" } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.billing_company,
                expression: "model.billing_company",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.model.billing_company },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "billing_company", $event.target.value)
              },
            },
          }),
        ]
      ),
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Business tax ID"),
            description: _vm.tr(
              "If you are a business tax registrant, please enter your business tax ID here."
            ),
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.billing_vat_number,
                expression: "model.billing_vat_number",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.model.billing_vat_number },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "billing_vat_number", $event.target.value)
              },
            },
          }),
        ]
      ),
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Primary business address") + "<sup>*</sup>",
            description: _vm.primaryBusinessAddressDescription,
          },
        },
        [
          _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Country"))),
                _c("sup", [_vm._v("*")]),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.countriesList,
                  reduce: (item) => item.iso_a2,
                  "get-option-label": (item) => _vm.tr(item.name),
                  clearable: false,
                },
                model: {
                  value: _vm.model.billing_country,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "billing_country", $$v)
                  },
                  expression: "model.billing_country",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("City"))),
              _c("sup", [_vm._v("*")]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.billing_city,
                  expression: "model.billing_city",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.model.billing_city },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.model, "billing_city", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Postcode / ZIP"))),
              _c("sup", [_vm._v("*")]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.billing_zip,
                  expression: "model.billing_zip",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.model.billing_zip },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.model, "billing_zip", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Street address"))),
              _c("sup", [_vm._v("*")]),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.billing_address,
                  expression: "model.billing_address",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.model.billing_address },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.model, "billing_address", $event.target.value)
                },
              },
            }),
          ]),
        ]
      ),
      _c(
        "form-block",
        {
          attrs: {
            title: _vm.tr("Billing email") + "<sup>*</sup>",
            description: _vm.tr(
              "Invoices and other billing notifications will be sent here."
            ),
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.billing_email,
                expression: "model.billing_email",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.model.billing_email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.model, "billing_email", $event.target.value)
              },
            },
          }),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c("change-subscription-form", {
        ref: "subscriptionForm",
        attrs: {
          "billing-country": _vm.model.billing_country,
          "vat-number": _vm.model.billing_vat_number,
        },
      }),
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(_vm._s(_vm.tr("Payment method"))),
          ]),
          _vm.currentPaymentMethodIsLoading
            ? _c("i", { staticClass: "icon loader primary" })
            : [
                _vm.currentPaymentMethodHasError
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "An unexpected error occurred while retrieving data."
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm.currentPaymentMethod
                  ? _c("div", { staticClass: "payment-method-item" }, [
                      _c("div", { staticClass: "payment-method-details" }, [
                        _c("div", { staticClass: "number" }, [
                          _vm.currentCardIconAvailable
                            ? _c("img", {
                                staticClass: "card-icon",
                                attrs: {
                                  src: require(`@/assets/img/cards/${_vm.currentPaymentMethod.brand}.png`),
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            " •••• " +
                              _vm._s(_vm.currentPaymentMethod.last4) +
                              " "
                          ),
                        ]),
                        _c("div", { staticClass: "expires" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr("Expires :at", {
                                  ":at": _vm.currentCardExpireDate,
                                })
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "red small",
                          on: { click: () => _vm.openDeleteConfirmModal() },
                        },
                        [_vm._v(" " + _vm._s(_vm.tr("Remove")) + " ")]
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr("There is no registered payment method.")
                          ) +
                          " "
                      ),
                    ]),
              ],
        ],
        2
      ),
      !_vm.currentPaymentMethodIsLoading && !_vm.currentPaymentMethodHasError
        ? [
            _vm.showStripeForm
              ? _c(
                  "div",
                  { staticClass: "add-payment-method-form" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.tr("Add payment method"))),
                    ]),
                    _c("stripe-card-input", {
                      ref: "stripeForm",
                      on: {
                        success: () => _vm.onNewPaymentMethodSaved(),
                        error: (err) => _vm.onNewPaymentMethodError(err),
                      },
                    }),
                    _vm.currentPaymentMethod !== null
                      ? _c(
                          "button",
                          {
                            staticClass: "primary add-credit-card-button",
                            attrs: { disabled: _vm.isLoading },
                            on: { click: () => _vm.save() },
                          },
                          [
                            _vm.isLoading
                              ? _c("i", { staticClass: "icon loader white" })
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.tr("Save payment method")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "button",
                  {
                    staticClass: "primary add-new-payment-method-button",
                    on: { click: () => (_vm.showStripeForm = true) },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentPaymentMethod
                            ? _vm.tr("Replace payment method")
                            : _vm.tr("Add payment method")
                        ) +
                        " "
                    ),
                  ]
                ),
          ]
        : _vm._e(),
      _c("cancel-subscription-modal-button"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }