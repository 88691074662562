var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-view" },
    [
      _c(
        "sticky-header",
        {
          attrs: { title: _vm.tr("Settings") },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function () {
                return [
                  _vm.hasSaveButton
                    ? _c(
                        "button",
                        {
                          staticClass: "primary",
                          attrs: { loading: _vm.isSaving },
                          on: { click: () => _vm.save() },
                        },
                        [
                          _vm.isSaving
                            ? _c("i", { staticClass: "icon loader white" })
                            : [_vm._v(" " + _vm._s(_vm.tr("Save")) + " ")],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.$router.currentRoute.path === "/settings/team"
                    ? _c(
                        "button",
                        {
                          staticClass: "primary open-invite-modal-button",
                          attrs: { disabled: !_vm.canInviteNewMember },
                          on: { click: () => _vm.$modal.show("invite-member") },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.tr("Invite a teammember")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("form-step-tab", { attrs: { items: _vm.formStepTabs } })],
        1
      ),
      _c("router-view", {
        ref: "childView",
        on: {
          canInviteNewMember: function ($event) {
            return _vm.setCanInviteNewMember($event)
          },
        },
      }),
      _c("invite-member-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }