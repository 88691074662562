<template>
  <div class="integrations-meta-profiles-view">
    <empty-screen
      v-if="isProfilesEmpty"
      :icon="emptyScreenIcon"
      :title="tr('Connect your accounts to start automating')"
      :flag-text="tr('Connect profiles')"
      :description="tr('To begin using our AI-powered automation features, you\'ll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising.')"
      @click="handleClick"
    />
    <div v-else class="profiles">
      <connected-profile-item
        v-for="profile in profiles"
        :key="profile.id"
        :entity="profile"
        @remove="() => removeProfile(profile)"
        @reconnect="$emit('reconnect', profile)"
      />
    </div>

    <disconnect-confirm-modal ref="disconnectConfirmModal" />
  </div>
</template>

<script>
import ConnectedProfileItem from '@/components/ConnectedProfileItem';
import DisconnectConfirmModal from '@/components/DisconnectConfirmModal.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import ToolService from '@/services/Tool.js';

export default {
  name: 'IntegrationsMetaProfilesView',
  components: {
    ConnectedProfileItem,
    DisconnectConfirmModal,
    EmptyScreen,
  },
  computed: {
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
    connectProfileButtonClasses() {
      return {
        button: true,
        primary: true,
        'connect-new-profile': true,
        loading: this.isConnecting,
      };
    },
    emptyScreenIcon() {
      return this.isConnecting ? 'loader' : 'plug';
    },
    isConnecting() {
      return this.$store.getters['app/isConnecting'];
    },
    isProfilesEmpty() {
      return this.profiles.length == 0;
    },
    profiles() {
      return this.$root.currentCompany.connected_profiles.filter(profile => profile.platform === 'facebook');
    },
  },
  methods: {
    handleClick() {
      if (!this.isConnecting) {
        this.$emit('connect');
      }
    },
    removeProfile(connectedProfile) {
      if (!this.$app.isAd) {
        return this.disconnect(connectedProfile);
      }

      return ToolService.disconnectPrecheck({ connectedProfileId: connectedProfile.id })
        .then(resp => {
          if (resp.defunc_rules.length !== 0) {
            this.$refs.disconnectConfirmModal.open(
              { connectedProfile },
              resp.defunc_rules,
              () => this.disconnect(connectedProfile),
            );
          }
          else {
            return this.disconnect(connectedProfile);
          }

          return resp;
        })
        .catch(err => this.error(err));
    },
    disconnect(profile) {
      return this.$store.dispatch('auth/removeProfile', profile.id)
        .then(resp => {
          this.$toast.success('Profile was successfully disconnected.');

          return resp;
        })
        .catch(err => this.error(err));
    },
  },
};
</script>
