<template>
  <div class="post-copy-analysis-parent">
    <sticky-header
      :title="title"
      :info="info"
    >
      <template v-if="viewIsPostCopyAnalysisList" #buttons>
        <button class="primary" @click="() => $refs.child.openFieldsModal()">
          {{ tr('Edit view') }}
        </button>
      </template>

      <template v-else-if="viewIsPostCopyAnalysis" #buttons>
        <router-link
          to="/post-copy-analysis"
          class="button bordered secondary"
          v-slot="{ href, navigate }"
          :custom="true"
        >
          <a :href="href" @click="($ev) => $refs.child.onClickToBack($ev, navigate)">
            {{ tr('Back') }}
          </a>
        </router-link>
      </template>

      <form-step-tab :items="tabs" />
    </sticky-header>

    <div class="transition-position-fix">
      <transition name="fade--fast">
        <router-view ref="child" />
      </transition>
    </div>
  </div>
</template>

<script>
import FormStepTab from '@/components/FormStepTab.vue';
import StickyHeader from '@/components/StickyHeader.vue';

export default {
  name: 'PostCopyAnalysisListView',
  components: {
    FormStepTab,
    StickyHeader,
  },
  computed: {
    viewIsPostCopyAnalysisList() {
      return this.$route.name === 'PostCopyAnalysisListView';
    },
    viewIsPostCopyAnalysis() {
      return this.$route.name === 'PostCopyAnalysisView';
    },
    viewIsAdCopyAnalysisMagicTool() {
      return this.$route.name === 'AdCopyMagicToolView';
    },
    title() {
      return this.tr('Infinite∞Ai');
    },
    info() {
      return this.tr('The <b class="primary">Infinite∞Ai</b> evaluates your posts based on 5 criteria and provides suggestions for the text to make them as sought after as possible. 🧠🤖');
    },
    tabs() {
      let tabs = [
        {
          label: this.tr('Published Content'),
          link: '/post-copy-analysis/posts',
        },
      ];

      if (this.$root.isInOriginalCompany) {
        tabs.push({
          label: this.tr('Magic Tool'),
          link: '/post-copy-analysis/magic-tool',
        });
      }

      return tabs;
    },
  },
};
</script>
