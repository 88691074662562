var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-view" },
    [
      _c(
        "sticky-header",
        { attrs: { title: _vm.rule ? _vm.rule.name : _vm.tr("Loading...") } },
        [_c("form-step-tab", { attrs: { items: _vm.tabs } })],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }