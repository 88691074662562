<template>
  <modal
    name="edit-user-restrictions"
    class="edit-user-restrictions-modal"
    @before-close="() => runBackupIfNecessary()"
  >
    <div class="modal-title">
      {{ tr('Edit user restrictions') }}
    </div>
    <div class="modal-content">
      <div v-if="$app.isAd">
        <label class="input-label">
          {{ tr('Visible Ad Accounts') }}
        </label>
        <multi-select
          v-model="visibleAdAccountIds"
          :options="adAccounts"
          :append-to-body="false"
          value-key="id"
          label-key="name"
        />
      </div>

      <div>
        <label class="input-label">
          {{ tr('Visible Datasources') }}
        </label>
        <multi-select
          v-model="visibleSourceProfileIds"
          :options="sourceProfiles"
          :append-to-body="false"
          value-key="id"
          label-key="name"
        />
      </div>
    </div>

    <div class="modal-action-group">
      <button
        class="secondary"
        @click="() => close()"
      >
        {{ tr('Cancel') }}
      </button>
      <button
        class="primary"
        @click="() => submitForm()"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="icon loader white" />
        <template v-else> {{ tr('Save') }} </template>
      </button>
    </div>
  </modal>
</template>

<script>
import UserRestrictionsFormMixin from '@/mixins/UserRestrictionsForm.js';

import MultiSelect from '@/components/MultiSelect.vue';

import UserService from '@/services/User.js';

export default {
  name: 'EditUserRestrictionsModal',
  mixins: [ UserRestrictionsFormMixin ],
  components: {
    MultiSelect,
  },
  data: () => ({
    successfullySaved: false,
    user: null,
    newLevel: null,
    backupMethod: () => {},
    isLoading: false,
  }),
  methods: {
    open(user, newLevel, backupMethod) {
      this.successfullySaved = false;
      this.backupMethod = typeof backupMethod === 'function' ? backupMethod : () => { };
      this.newLevel = newLevel;
      this.user = user;

      this.visibleAdAccountIds = this.user.visible_ad_account_ids ?? [];
      this.visibleSourceProfileIds = this.user.visible_source_profile_ids ?? [];

      this.$modal.show('edit-user-restrictions');
    },
    close() {
      this.$modal.hide('edit-user-restrictions');
    },
    runBackupIfNecessary() {
      if (!this.successfullySaved) {
        this.backupMethod();
      }
    },
    submitForm() {
      if (!this.validateRestrictions()) {
        return;
      }

      this.isLoading = true;

      const data = {
        level: this.newLevel,
        visibleAdAccountIds: this.visibleAdAccountIds,
        visibleSourceProfileIds: this.visibleSourceProfileIds,
      };

      UserService.changeLevel(this.user.id, data)
        .then((resp) => {
          this.successfullySaved = true;

          this.user.level = resp.level;
          this.user.visible_ad_account_ids = resp.visible_ad_account_ids;
          this.user.visible_source_profile_ids = resp.visible_source_profile_ids;

          this.close();
          this.$toast.success(this.tr('The user\'s access level has been successfully changed.'));
        })
        .catch((err) => this.error(err))
        .finally(() => this.isLoading = false);
    },
  }
};
</script>
