var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "geolocation-target-selector-component" }, [
    _vm.hasSelectedValue
      ? _c(
          "div",
          { staticClass: "selected-values" },
          [
            _vm._l(
              ["geo_locations", "excluded_geo_locations"],
              function (targetType) {
                return _vm._l(_vm.value[targetType].all, function (row, key) {
                  return _c(
                    "div",
                    {
                      key:
                        "geolocation-target-selector--row--" +
                        key +
                        "--" +
                        row.key,
                      staticClass: "row",
                    },
                    [
                      !row.meta.name
                        ? _c("i", {
                            staticClass:
                              "icon option-icon loader primary small",
                          })
                        : _c("i", {
                            class:
                              "option-icon marker-" +
                              (targetType === "geo_locations"
                                ? "include"
                                : "exclude"),
                          }),
                      _c(
                        "div",
                        { staticClass: "name" },
                        [
                          row.meta.name
                            ? [
                                _vm._v(
                                  " " + _vm._s(_vm.getName(row.meta)) + " "
                                ),
                              ]
                            : [
                                _vm._v(
                                  " " + _vm._s(_vm.tr("Loading...")) + " "
                                ),
                              ],
                        ],
                        2
                      ),
                      "radius" in row
                        ? _c("distance-radius-selector", {
                            attrs: { min: row.radiusMin, max: row.radiusMax },
                            on: { input: () => _vm.delayedRedrawFullMap() },
                            model: {
                              value: row.radius,
                              callback: function ($$v) {
                                _vm.$set(row, "radius", $$v)
                              },
                              expression: "row.radius",
                            },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "actions" }, [
                        _c("i", {
                          staticClass: "icon close",
                          on: {
                            click: () =>
                              _vm.value[targetType].remove(row.type, row.key),
                          },
                        }),
                      ]),
                    ],
                    1
                  )
                })
              }
            ),
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "autosuggest-input-wrapper" },
      [
        _c("v-select", {
          attrs: {
            options: _vm.includeOrExcludeOptions,
            clearable: false,
            searchable: false,
            "append-to-body": false,
            reduce: (option) => option.value,
          },
          scopedSlots: _vm._u([
            {
              key: "selected-option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "include-exclude-option" }, [
                    _c("i", { class: "option-icon " + option.icon }),
                    _vm._v(" " + _vm._s(option.label) + " "),
                  ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "include-exclude-option" }, [
                    _c("i", { class: "option-icon " + option.icon }),
                    _vm._v(" " + _vm._s(option.label) + " "),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.includeOrExclude,
            callback: function ($$v) {
              _vm.includeOrExclude = $$v
            },
            expression: "includeOrExclude",
          },
        }),
        _c("vue-autosuggest", {
          attrs: {
            "input-props": {
              id: "autosuggest",
              placeholder: _vm.tr("Search locations"),
            },
            suggestions: [{ data: _vm.suggestions }],
          },
          on: {
            input: _vm.updateSuggestions,
            selected: _vm.onSelectSuggestion,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ suggestion }) {
                return [
                  _c(
                    "div",
                    {
                      on: {
                        mousedown: () => _vm.onMouseDownSuggestion(suggestion),
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.getName(suggestion.item)) + " ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.autosuggestInputValue,
            callback: function ($$v) {
              _vm.autosuggestInputValue = $$v
            },
            expression: "autosuggestInputValue",
          },
        }),
        _vm.isLoading
          ? _c("i", { staticClass: "icon loader primary" })
          : _vm._e(),
      ],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedValue,
          expression: "hasSelectedValue",
        },
      ],
      ref: "map",
      staticClass: "map",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }