var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block notifiable-users-block-component",
      attrs: {
        title: _vm.tr("Members to be notified about activities"),
        description: _vm.tr(
          "The selected individuals will receive email notifications when an advertisement starts based on the configured rule."
        ),
      },
    },
    [
      _c("multi-select", {
        attrs: {
          options: _vm.options,
          placeholder: _vm.tr("Search member"),
          disabled: _vm.disabled || _vm.isLoading,
          loading: _vm.isLoading,
          "label-key": "fullname",
          "value-key": "id",
        },
        model: {
          value: _vm.insideValue,
          callback: function ($$v) {
            _vm.insideValue = $$v
          },
          expression: "insideValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }