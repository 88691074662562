var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "company-selector-component" },
    [
      _c(
        "dropdown-menu",
        {
          attrs: { interactive: true, transition: "slide-top-to-bottom" },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c("div", { staticClass: "main" }, [
            _c("label", [_vm._v(" " + _vm._s(_vm.currentCompanyLabel) + " ")]),
            _c("i", { staticClass: "icon arrow-right dark-gray" }),
          ]),
          _c(
            "div",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.term,
                    expression: "term",
                  },
                ],
                attrs: {
                  type: "text",
                  autocomplete: "off",
                  placeholder: _vm.tr("Search") + "...",
                },
                domProps: { value: _vm.term },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.term = $event.target.value
                  },
                },
              }),
              _vm._l(_vm.filteredCompanyies, function (company) {
                return _c(
                  "div",
                  {
                    key: "company-selector-option-" + company.id,
                    class: { active: _vm.currentCompanyId === company.id },
                    on: { click: () => _vm.select(company.id) },
                  },
                  [
                    _vm.loadingCompanyId === company.id
                      ? _c("i", { staticClass: "icon loader" })
                      : company.id === _vm.$root.loggedInUser.company.id
                      ? _c("i", {
                          class:
                            "icon star " +
                            (_vm.currentCompanyId === company.id
                              ? "white"
                              : "light-black"),
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(company.name))]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }