<template>
  <div class="rule-view">
    <sticky-header :title="rule ? rule.name : tr('Loading...')">
      <template #buttons>
        <router-link to="/automation" class="button bordered secondary">
          {{ tr('Back') }}
        </router-link>
      </template>

      <form-step-tab :items="tabs" />
    </sticky-header>

    <router-view />
  </div>
</template>

<script>
import FormStepTab from '@/components/FormStepTab.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import Switches from 'vue-switches';

import RuleService from '@/services/Rule.js';

export default {
  name: 'RuleView',
  components: {
    FormStepTab,
    StickyHeader,
    Switches,
  },
  data: () => ({
    rule: null,
  }),
  beforeRouteUpdate(to, from, next) {
    next();

    if (to.name === from.name && to.path !== from.path) {
      this.rule = null;
      this.fetch();
    }
  },
  created() {
    this.fetch();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    tabs() {
      let tabs = [];

      tabs.push({
        label: this.tr('Overview'),
        link: `/automation/${this.id}/overview`,
      });

      if (this.rule && (this.rule.action === 'start')) {
        tabs.push({
          label: this.tr('Ai Risk Review'),
          link: `/automation/${this.id}/ai-risk-review`,
        });
      }

      if (this.rule && (this.rule.action === 'start' || this.rule.action === 'scaling_up')) {
        tabs.push({
          label: this.tr('Pending Ads'),
          link: `/automation/${this.id}/pending-ads`,
        });
      }

      tabs.push({
        label: this.tr('Edit history'),
        link: `/automation/${this.id}/edit-history`,
      });

      if (this.rule && (this.rule.action === 'start' || this.rule.action === 'scaling_up')) {
        tabs.push({
          label: this.tr('Report'),
          link: `/automation/${this.id}/report`,
        });
      }

      tabs.push({
        label: this.tr('Error log'),
        link: `/automation/${this.id}/error-logs`,
      });

      return tabs;
    },
  },
  methods: {
    fetch() {
      RuleService.get(this.id)
        .then(response => this.rule = response)
        .catch(error => this.error(error));
    },
  },
};
</script>
