var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "improved-content-component" }, [
    _vm.title
      ? _c("div", { staticClass: "meta" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.tr("Title")) + " "),
            _c("i", {
              staticClass: "icon copy primary",
              on: {
                click: function ($event) {
                  return _vm.copyToClipboard($event)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "details" }, [_vm._v(_vm._s(_vm.title))]),
        ])
      : _vm._e(),
    _vm.description
      ? _c("div", { staticClass: "meta" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.tr("Description")) + " "),
            _c("i", {
              staticClass: "icon copy primary",
              on: {
                click: function ($event) {
                  return _vm.copyToClipboard($event)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "details" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
        ])
      : _vm._e(),
    _vm.postText
      ? _c("div", { staticClass: "meta" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.tr("Post text")) + " "),
            _c("i", {
              staticClass: "icon copy primary",
              on: {
                click: function ($event) {
                  return _vm.copyToClipboard($event)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "details" }, [_vm._v(_vm._s(_vm.postText))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }