<template>
  <div :class="wrapperClasses">
    <div class="date-field-container">
      <input
        ref="dateInput"
        :value="formattedDate"
        type="text"
        class="date-field"
        readonly
        :disabled="disabled"
        @focus="(e) => onFocusDateInput(e)"
      />
    </div>
    <div class="time-field-container">
      <number-input
        ref="hourInput"
        v-model="hourInput"
        :min="0"
        :max="23"
        :disabled="disabled"
        @focus="(e) => onFocusInput(e, true)"
        @blur="() => onBlurInput()"
      />
      <span>:</span>
      <number-input
        v-model="minuteInput"
        :min="0"
        :max="59"
        :disabled="disabled"
        @focus="(e) => onFocusInput(e, true)"
        @blur="() => onBlurInput()"
      />
    </div>
    <v-date-picker
      v-if="showDatePicker"
      ref="datePicker"
      v-model="internalDate"
      title-position="left"
      class="date-picker"
      @input="onDateSelected"
      @close="showDatePicker = false"
    />
  </div>
</template>

<script>
import NumberInput from '@/components/NumberInput.vue';
import debounce from '@/utils/debounce.js';

export default {
  name: 'DateTimeInput',
  components: {
    NumberInput,
  },
  props: {
    value: {
      type: Date,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    const internalDate = new Date(this.value);
    internalDate.setSeconds(0);
    internalDate.setMilliseconds(0);

    return {
      debouncedFocusedWrapperUpdater: debounce(val => this.focusedWrapper = val, 25),
      focusedWrapper: false,
      formattedDate: null,
      internalDate,
      hourInput: null,
      minuteInput: null,
      showDatePicker: false,
    };
  },
  computed: {
    wrapperClasses() {
      return {
        'date-time-input-component': true,
        'inline-input-wrapper': true,
        'active': this.focusedWrapper,
        'disabled': this.disabled,
      };
    },
  },
  watch: {
    value(newVal) {
      newVal = new Date(newVal);
      newVal.setSeconds(0);
      newVal.setMilliseconds(0);

      this.internalDate = newVal;
    },
    hourInput(val, oldVal) {
      if (oldVal === null) {
        return;
      }

      val = parseInt(val);
      oldVal = parseInt(oldVal);

      if (isNaN(val)) {
        val = 0;
      }

      if (val !== oldVal) {
        this.internalDate.setHours(val);
        this.updateValue();
      }
    },
    minuteInput(val, oldVal) {
      if (oldVal === null) {
        return;
      }

      val = parseInt(val);
      oldVal = parseInt(oldVal);

      if (isNaN(val)) {
        val = 0;
      }

      if (val !== oldVal) {
        this.internalDate.setMinutes(val);
        this.updateValue();
      }
    },
  },
  created() {
    this.setDateInputValue();
    this.paddingTimeInputs();
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    paddingTimeInputs() {
      this.hourInput = ('0' + this.internalDate.getHours()).slice(-2);
      this.minuteInput = ('0' + this.internalDate.getMinutes()).slice(-2);
    },
    setDateInputValue() {
      this.formattedDate = this.internalDate.toISOString().split('T')[0];
    },
    onDateSelected(date) {
      this.internalDate.setFullYear(date.getFullYear());
      this.internalDate.setMonth(date.getMonth());
      this.internalDate.setDate(date.getDate());
      this.updateValue();
      this.setDateInputValue();
      this.showDatePicker = false;

      this.$refs.hourInput.focus();
    },
    updateValue() {
      const newValue = new Date(this.internalDate);
      newValue.setSeconds(0);
      newValue.setMilliseconds(0);

      this.$emit('input', newValue);
    },
    handleOutsideClick(event) {
      const isClickInside = this.$el.contains(event.target) || this.$refs.datePicker?.$el.contains(event.target);
      if (!isClickInside) {
        this.showDatePicker = false;
        this.debouncedFocusedWrapperUpdater(false);
      }
    },
    onFocusDateInput(e) {
      this.showDatePicker = true;
      this.onFocusInput(e);
    },
    onFocusInput(e, select) {
      this.debouncedFocusedWrapperUpdater(true);
      if (select) {
        e.target.select();
      }

      this.$emit('focus');
    },
    onBlurInput() {
      this.paddingTimeInputs();
      this.debouncedFocusedWrapperUpdater(false);
    },
  },
};
</script>
