var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: ["facebook-ad-preview-component", _vm.imageLayoutClass] },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "div",
            { staticClass: "profile" },
            [
              _vm.name
                ? _c("div", { staticClass: "image" }, [
                    _c("i", { staticClass: "icon infinite white" }),
                  ])
                : _c("skeleton", {
                    staticClass: "image profile-image-skeleton",
                    attrs: { width: "32px", height: "32px" },
                  }),
              _vm.name
                ? _c("div", { staticClass: "name" }, [
                    _vm._v(" " + _vm._s(_vm.name) + " "),
                  ])
                : _c("skeleton", {
                    staticClass: "name",
                    attrs: { width: "100px", height: "18px" },
                  }),
            ],
            1
          ),
          _vm.text
            ? _c(
                "div",
                { staticClass: "main-text" },
                [
                  _c("read-more-text", {
                    attrs: {
                      text: _vm.text.replace(/(?:\r\n|\r|\n)/g, "<br>"),
                    },
                  }),
                ],
                1
              )
            : _vm.showAllSkeleton
            ? _c("skeleton", { attrs: { width: "80%", height: "18px" } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { class: ["image", _vm.checkImageClass] },
        [
          _vm.imgUrl
            ? _c("img", { attrs: { src: _vm.imgUrl, alt: "Ad Image" } })
            : _c("skeleton", {
                staticClass: "ad-image-skeleton",
                attrs: { width: "100%", "min-height": "300px" },
              }),
        ],
        1
      ),
      _vm.showCtaSection
        ? _c(
            "div",
            { staticClass: "cta-section" },
            [
              _c(
                "div",
                { staticClass: "texts" },
                [
                  _vm.ctaTitle
                    ? _c("div", { staticClass: "cta-title" }, [
                        _vm._v(_vm._s(_vm.ctaTitle)),
                      ])
                    : _vm.showAllSkeleton
                    ? _c("skeleton", {
                        staticClass: "cta-title",
                        attrs: { width: "150px", height: "18px" },
                      })
                    : _vm._e(),
                  _vm.ctaDescription
                    ? _c("div", { staticClass: "cta-description" }, [
                        _vm._v(_vm._s(_vm.ctaDescription)),
                      ])
                    : _vm.showAllSkeleton
                    ? _c("skeleton", {
                        staticClass: "cta-description",
                        attrs: { width: "120px", height: "18px" },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.ctaButton
                ? _c("div", { staticClass: "cta-button" }, [
                    _vm._v(_vm._s(_vm.ctaButton)),
                  ])
                : _vm.showAllSkeleton
                ? _c("skeleton", {
                    staticClass: "cta-button",
                    attrs: { width: "70px", height: "36px" },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }