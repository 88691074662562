var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      _c("div", { staticClass: "navigation" }, [
        _vm.title
          ? _c(
              "div",
              { staticClass: "title" },
              [
                _vm._t("beforeTitle"),
                _vm._v(" " + _vm._s(_vm.title) + " "),
                _vm.info
                  ? _c("info-popup", [
                      _c("div", { domProps: { innerHTML: _vm._s(_vm.info) } }),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "description" },
                  [_vm._t("description")],
                  2
                ),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "nav-buttons" }, [_vm._t("buttons")], 2),
      ]),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }