import AudienceService from '@/services/Audience.js';
import RuleService from '@/services/Rule.js';

import Rule from '@/entities/Rule.js';

import { tr } from '@/mixins/Translator.js';
import { convertOldCampaignObjectiveToOdax } from '@/utils/facebook.js';

const aiForm = require('@/views/RuleForm/AiMode').default;
const formsByAction = {
  'start': require('@/views/RuleForm/Start').default,
  'stop': require('@/views/RuleForm/Stop').default,
  'scaling_up': require('@/views/RuleForm/ScalingUp').default,
};

const state = {
  isAiMode: false,
  entity: null,
  step: 1,
  allowedStep: 1,
  audiences: [],
  loadingAudiences: false,
};

const getters = {
  allowedActions() {
    return Object.keys(formsByAction);
  },
  isAiMode() {
    return state.isAiMode;
  },
  entity(state) {
    return state.entity
  },
  isNew(state) {
    return state.entity !== null && state.entity.id === null;
  },
  step(state) {
    return state.step
  },
  allowedStep(state) {
    return state.allowedStep
  },
  audiences(state) {
    return state.audiences;
  },
  loadingAudiences(state) {
    return state.loadingAudiences;
  },
  formStepTabs(state, getters, rootState, rootGetters) {
    if (state.entity === null) {
      return [];
    }

    if (state.isAiMode) {
      return aiForm;
    }

    if (!(state.entity.action in formsByAction)) {
      return [];
    }

    return formsByAction[state.entity.action].map((step, key) => {
      const stepNumber = key + 1;

      return {
        label: stepNumber + ' ' + tr(step.label, null, rootGetters['app/language']),
        view: step.view,
        link: window.location.pathname + '#step-' + stepNumber,
      };
    });
  },
  maxStepNumber(state, getters) {
    return getters.formStepTabs.length;
  },
  renderedView(state, getters) {
    if (getters.maxStepNumber === 0) {
      return null;
    }

    return getters.formStepTabs[state.step - 1].view;
  },
};

const actions = {
  init(store, { id, action, isNew, isAiMode, isDuplicate }) {
    if (isNew) {
      store.commit('setIsAiMode', isAiMode);
      return initNew(store, action);
    }
    else {
      return loadEntity(store, id, isDuplicate);
    }
  },
  goToStep(store, value) {
    if (value > store.getters.maxStepNumber || value < 1) {
      return false;
    }

    if (value > store.getters.allowedStep) {
      return false;
    }

    store.commit('setStep', value);

    return true;
  },
  fetchAudiences(store, adAccountId) {
    if (store.getters['loadingAudiences']) {
      return new Promise((resolve, reject) => reject());
    }

    store.commit('setLoadingAudiences', true);

    return AudienceService.getList({ id: adAccountId })
      .then(resp => store.commit('setAudiences', resp))
      .finally(() => store.commit('setLoadingAudiences', false));
  },
};

const mutations = {
  reset(state) {
    state.isAiMode = false;
    state.entity = null;
    state.step = 1;
    state.allowedStep = 1;
    state.audiences = [];
    state.loadingAudiences = false;
  },
  setIsAiMode(state, value) {
    state.isAiMode = !!value;
  },
  setEntity(state, value) {
    state.entity = value;
  },
  setStep(state, value) {
    state.step = value;
  },
  setAllowedStep(state, value) {
    state.allowedStep = value;
  },
  increaseAllowedStep(state) {
    ++state.allowedStep;
  },
  setAudiences(state, value) {
    if (state.loadingAudiences) {
      state.audiences = value;
    }
  },
  setLoadingAudiences(state, value) {
    state.loadingAudiences = !!value;
  },
};

function initNew(store, action) {
  const entity = new Rule({ action: action.replace('-', '_') });

  store.commit('setEntity', entity);

  return new Promise(resolve => resolve(entity));
}

function loadEntity(store, id, isDuplicate = false) {
  return RuleService.get(id)
    .then(resp => {
      const entity = new Rule(resp);

      if (isDuplicate) {
        entity.id = null;
        entity.name = entity.name + ' - ' + tr('Copy', null, store.rootGetters['app/language']);

        if ('is_fb_odax_version' in entity.settings && !entity.settings.is_fb_odax_version) {
          entity.settings.is_fb_odax_version = true;
          entity.settings.adset_optimization_goal = null;
          entity.settings.adset_attribution_window = null;
          entity.settings.adset_bid_strategy = null

          switch (entity.output_campaign.objective) {
            case 'POST_ENGAGEMENT':
              entity.settings.adset_conversion_location = 'ad_engagement';
              break;

            case 'VIDEO_VIEWS':
              entity.settings.adset_conversion_location = 'ad_video';
              break;
          }

          entity.output_campaign.objective = convertOldCampaignObjectiveToOdax(entity.output_campaign.objective, entity.settings.adset_conversion_event);
        }
      }

      store.commit('setEntity', entity);
      store.commit('setAllowedStep', store.getters.maxStepNumber);
    });
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
