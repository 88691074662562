var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c(
        "div",
        {
          class: _vm.wrapperClasses,
          on: { click: () => _vm.onClickBackground() },
        },
        [
          _c("div", { staticClass: "fullscreen-modal--wrapper" }, [
            _vm.closable
              ? _c(
                  "div",
                  {
                    staticClass: "fullscreen-modal--close-button",
                    on: { click: () => _vm.close() },
                  },
                  [_c("i", { staticClass: "icon close primary" })]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "fullscreen-modal--container",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "fullscreen-modal--content" },
                  [
                    _c(
                      _vm.component,
                      _vm._b(
                        {
                          tag: "component",
                          on: {
                            close: () => _vm.close(),
                            "close-all": () => _vm.closeAll(),
                          },
                        },
                        "component",
                        _vm.properties,
                        false
                      )
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }