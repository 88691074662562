import RuleActivityReviewItem from '@/descriptors/RuleActivityReviewItem.js';
import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';
import Rule from '@/entities/Rule.js';
import RuleActivityLog from '@/entities/RuleActivityLog.js';
import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';
import RuleChangeLog from '@/entities/RuleChangeLog.js';
import api from '@/services/Api';

export default {
  getList({ page, limit, term, status, action, actions, adAccountId, orderBy, order }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }
    if (term) {
      data['term'] = term;
    }
    if (typeof status !== 'undefined') {
      data['status'] = status ? 1 : 0;
    }
    if (adAccountId) {
      data['ad_account_id'] = adAccountId;
    }
    if (action) {
      data['action'] = action;
    }
    if (orderBy) {
      data['order_by'] = orderBy;
    }
    if (order) {
      data['order'] = order.toUpperCase();
    }
    const query = new URLSearchParams(data);

    if (Array.isArray(actions)) {
      actions
        .filter(action => !!action)
        .map(action => query.append('actions[]', action));
    }

    return api().get('/rules?' + query.toString())
      .then(resp => resp.map(i => new Rule(i)));
  },

  getWaitingList({ page, limit, ruleId }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }
    if (ruleId) {
      data['rule_id'] = ruleId;
    }

    const query = (new URLSearchParams(data)).toString();

    return api().get('/rule/waiting-list?' + query.toString())
      .then(resp => resp.map(i => {
        switch (i.entity_type) {
          case 'post_ai_prohibited_content':
            return new PostAiProhibitedContent(i);

          case 'rule_activity_management_queue_item':
            return new RuleActivityManagementQueueItem(i);

          default:
            return i;
        }
      }));
  },

  get(id) {
    return api().get('/rule/' + id)
      .then(resp => new Rule(resp));
  },

  create(entity) {
    return api().post('/rule', entity.toObject())
      .then(resp => new Rule(resp));
  },

  createAiMode(entity, goal, scalingBudget, aiAssist) {
    const data = entity.toObject();
    data['goal'] = goal;
    if (scalingBudget) {
      data['scaling_budget'] = scalingBudget;
    }
    if (aiAssist) {
      data['ai_assist'] = aiAssist;
    }

    return api().post('/rule/ai-mode', data);
  },

  update(entity) {
    return api().put('/rule/' + entity.id, entity.toObject())
      .then(resp => new Rule(resp));
  },

  delete(id, deleteCampaign) {
    return api().delete('/rule/' + id, { data: { delete_output_campaign: deleteCampaign } });
  },

  generateActivityReview(entity) {
    return api().post('/rule/generate-activity-review', entity.toObject())
      .then(resp => resp.map(item => new RuleActivityReviewItem(item)));
  },

  generateActivityReviewForAiMode(entity, goal, scalingBudget, aiAssist) {
    const data = entity.toObject();
    data['goal'] = goal;
    if (scalingBudget) {
      data['scaling_budget'] = scalingBudget;
    }
    if (aiAssist) {
      data['ai_assist'] = aiAssist;
    }

    return api().post('/rule/generate-activity-review/ai-mode', data)
      .then(resp => resp.map(item => new RuleActivityReviewItem(item)));
  },

  changeStatus(id, active, changeCampaignStatus) {
    const data = { active };
    if (changeCampaignStatus) {
      data['change_campaign_status'] = true;
    }

    return api().put('/rule/' + id + '/status', data)
      .then(resp => new Rule(resp));
  },

  run(id) {
    return api().post('/rule/' + id + '/run');
  },

  getLogs({ id, page, limit }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rule/' + id + '/logs?' + query)
      .then(resp => resp.map(i => new RuleActivityLog(i)));
  },

  getChangeLogs({ id, page, limit }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }

    const query = new URLSearchParams(data).toString();

    return api().get('/rule/' + id + '/change-logs?' + query)
      .then(resp => resp.map(i => new RuleChangeLog(i)));
  },

  getRuleCampaignPairs() {
    return api().get('/rule-campaign-pairs');
  },
};
