var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "distance-radius-selector-component" }, [
    _c("div", { staticClass: "main", on: { click: () => _vm.toggle() } }, [
      _vm._v(" + " + _vm._s(_vm.value) + " km "),
    ]),
    _vm.isOpen
      ? _c("div", { ref: "sliderWrapper", staticClass: "slider-wrapper" }, [
          _c("div", { staticClass: "min-label" }, [_vm._v(_vm._s(_vm.min))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.innerValue,
                expression: "innerValue",
              },
            ],
            attrs: { type: "range", min: _vm.min, max: _vm.max },
            domProps: { value: _vm.innerValue },
            on: {
              __r: function ($event) {
                _vm.innerValue = $event.target.value
              },
            },
          }),
          _c("div", { staticClass: "min-label" }, [_vm._v(_vm._s(_vm.max))]),
          _c(
            "div",
            { staticClass: "number-input-wrapper" },
            [
              _c("number-input", {
                attrs: { step: 1, min: _vm.min, max: _vm.max },
                model: {
                  value: _vm.innerValue,
                  callback: function ($$v) {
                    _vm.innerValue = $$v
                  },
                  expression: "innerValue",
                },
              }),
              _c("span", [_vm._v("km")]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }