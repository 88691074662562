<template>
  <form-block
    v-if="facebookAttributionWindows.length !== 0"
    class="rule-form-block attribution-window-block-component"
    :title="tr('Attribution window')"
  >
    <div class="checkbox-list vertical">
      <label>
        <input
          v-model="isCustom"
          type="radio"
          name="attribution"
          :value="false"
          :disabled="disabled"
        />
        {{ tr('Default') }}
      </label>
      <label>
        <input
          v-model="isCustom"
          type="radio"
          name="attribution"
          :value="true"
          :disabled="disabled"
        />
        {{ tr('Custom') }}
      </label>
      <div v-if="isCustom" class="checkbox-list vertical">
        <div>
          <span>
            {{ tr('The number of days between when a person viewed or clicked your ad and subsequently took an action.') }}
            <a href="https://www.facebook.com/business/help/2198119873776795?id=768381033531365" target="_blank">{{ tr('Learn more') }}</a>
          </span>
        </div>
        <div class="checkbox-list vertical">
          <v-select
            v-model="entity.settings.adset_attribution_window"
            :options="availableAttributionWindows"
            :reduce="(obj) => obj.machine_name"
            :disabled="disabled"
            label="label"
          />
          <div>
            <span>{{ tr('By default Ads Manager uses ad set attribution window, also known as unified attribution window. You may see discrepancies in conversion data if this setting is disabled.') }}</span>
          </div>
        </div>
      </div>
    </div>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'AttributionWindowOdaxBlock',

  mixins: [ RuleFormBlockMixin ],

  data: () => ({
    isCustom: null,
  }),

  computed: {
    campaignObjective() {
      return this.entity.output_campaign?.objective;
    },

    optimizationGoal() {
      return this.entity.settings?.adset_optimization_goal;
    },

    facebookAttributionWindows() {
      return this.$store.getters['app/facebookAttributionWindows'];
    },

    availableAttributionWindows() {
      const objectives = this.$store.getters['app/facebookOdaxMap'];

      if (!this.campaignObjective || !this.optimizationGoal) {
        return [];
      }

      const objective = objectives.find(obj => obj.machine_name === this.campaignObjective);
      if (!objective) {
        return [];
      }

      let allowedWindows = [];
      for (const destType of objective.destination_types) {
        const goal = destType.optimization_goals.find(g => g.machine_name === this.optimizationGoal);
        if (goal) {
          allowedWindows = goal.allowed_attribution_windows;
          break;
        }
      }

      return this.facebookAttributionWindows.filter(window =>
        allowedWindows.includes(window.machine_name));
    },
  },

  watch: {
    isCustom(val, oldVal) {
      if (oldVal === null || val === oldVal) {
        return;
      }

      if (val) {
        this.getAvailableWindows();
      }
      else {
        this.entity.settings.adset_attribution_window = null;
      }
    },
    optimizationGoal() {
      this.getAvailableWindows();
    },
  },

  created() {
    this.isCustom = !!this.entity.settings.adset_attribution_window;
  },

  methods: {
    validate() {
      if (!this.entity.settings.adset_attribution_window) {
        return true;
      }

      return this.availableAttributionWindows.some(window => window.machine_name === this.entity.settings.adset_attribution_window);
    },
    getAvailableWindows() {
      const availableWindows = this.availableAttributionWindows;
      if (availableWindows.length > 0) {
        this.entity.settings.adset_attribution_window = availableWindows[0].machine_name;
      }
    },
  },
};
</script>
