import Api from '@/services/Api';

import Comment from '@/entities/Comment.js';
import Company from '@/entities/Company.js';
import ErrorLog from '@/entities/ErrorLog.js';
import RuleActivityLog from '@/entities/RuleActivityLog.js';
import SubscriptionPackage from '@/entities/SubscriptionPackage';
import User from '@/entities/User.js';

export default {
  getDashboardAd(companyId) {
    return Api().get('/company/' + companyId + '/dashboard')
      .then((resp) => {
        resp.rule_activity_log = resp.rule_activity_log.map(i => new RuleActivityLog(i));
        if (resp.last_post_boosting_activity) {
          resp.last_post_boosting_activity = new Date(resp.last_post_boosting_activity);
        }
        if (resp.last_rule_activity) {
          resp.last_rule_activity = new Date(resp.last_rule_activity);
        }
        if (resp.last_activity) {
          resp.last_activity = new Date(resp.last_activity);
        }

        return resp;
      });
  },

  getDashboardBuzz(companyId, { sourceProfileId, dateFrom, dateTo }) {
    const data = {};
    if (sourceProfileId) {
      data['source_profile_id'] = sourceProfileId;
    }
    if (dateFrom) {
      data['date_from'] = dateFrom.toISOString().split('T')[0];
    }
    if (dateTo) {
      data['date_to'] = dateTo.toISOString().split('T')[0];
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/company/' + companyId + '/dashboard?' + query)
      .then((resp) => {
        resp.reviewed_comments.map(i => i.date = new Date(i.date));
        resp.moderated_comments.map(i => i.date = new Date(i.date));
        resp.quality_scores.map(i => i.date = new Date(i.date));
        resp.latest_activities = resp.latest_activities.map(i => new Comment(i));

        return resp;
      });
  },

  get(id) {
    return Api().get('/company/' + id)
      .then((resp) => new Company(resp));
  },

  getSubscription(id) {
    return Api().get('/company/' + id + '/subscription')
      .then((resp) => {
        if (resp.current_active_package !== null) {
          resp.current_active_package = new SubscriptionPackage(resp.current_active_package);
        }
        if (resp.paid_active_package !== null) {
          resp.paid_active_package = new SubscriptionPackage(resp.paid_active_package);
        }

        return resp;
      });
  },

  update(id, company) {
    const object = company.toObject();

    const deletableKeys = [ 'ad_accounts', 'connected_profiles', 'source_profiles', 'managed_companies', 'current_active_package', 'paid_active_package', 'next_payment_date', 'next_billing_date', 'vat_rate' ];
    deletableKeys.forEach(key => {
      if (key in object) {
        delete object[key];
      }
    });

    return Api().put('/company/' + id, object)
      .then((resp) => new Company(resp));
  },

  delete(id) {
    return Api().delete('/company/' + id);
  },

  getErrorLogs({ id, page, limit, ruleId }) {
    const data = {};
    if (page) {
      data['page'] = page;
    }
    if (limit) {
      data['limit'] = limit;
    }
    if (ruleId) {
      data['rule_id'] = ruleId;
    }

    const query = new URLSearchParams(data).toString();

    return Api().get('/company/' + id + '/error-logs?' + query)
      .then((resp) => {
        return resp.map(i => new ErrorLog(i));
      });
  },

  getMembers(id) {
    return Api().get('/company/' + id + '/members')
      .then((resp) => {
        return resp.map(i => new User(i));
      });
  },
};
