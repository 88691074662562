import BaseEntity from '@/entities/BaseEntity';
import FacebookTargeting from '@/descriptors/FacebookTargeting';
import { parseNumber } from '@/utils/number.js';
import { AD_CREATION_METHOD } from '@/consts.js';

export default class StartRuleSettings extends BaseEntity
{
  is_fb_odax_version = true;
  ai_review = true;
  dont_create_expired_ads = true;
  create_ai_stop_rule = true;
  max_active_ads = null;
  cta_button = 'NO_BUTTON';
  cta_url = '';
  smart_cta_url = true;
  url_params = 'utm_source=facebook&utm_medium=cpc&utm_campaign={{campaign.name}}';
  name_prefix = '[Infinite.ad]';
  campaign_name_prefix = '[Infinite.ad]';
  adset_name = '{{month}}.{{date}}. {{time}} - {{post.message}}';
  _adset_budget = null;
  adset_budget_period = null;
  adset_bid_strategy = null;
  adset_bid_amount = null;
  adset_roas_goal = null;
  _adset_conversion_location = null;
  adset_conversion_event = null;
  adset_conversion_app_id = null;
  adset_conversion_app_store_url = null;
  adset_conversion_pixel_id = null;
  adset_optimization_goal = '';
  adset_billing_event = '';
  adset_attribution_window = '';
  adset_included_custom_audiences = [];
  adset_excluded_custom_audiences = [];
  adset_targeting = new FacebookTargeting();
  adset_targeting_advantage_plus_audience = true;
  adset_targeting_audiences = [];
  adset_targeting_devices = [];
  adset_targeting_user_os = [];
  adset_targeting_positions = {
    facebook: [],
    instagram: [],
  };

  ad_creation_method = AD_CREATION_METHOD.POST_BOOST;
  ai_cta_content = false;
  ai_cta_button = false;
  ai_primary_text = false;
  ad_variants = 1;
  ai_ad_with_original_content = true;
  ap_creative_features = [];

  _entityFields = {
    adset_targeting: FacebookTargeting,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get adset_conversion_location() {
    return this._adset_conversion_location;
  }

  set adset_conversion_location(value) {
    const hasValue = !!this._adset_conversion_location;
    this._adset_conversion_location = value;

    if (hasValue) {
      this.adset_conversion_app_id = null;
      this.adset_conversion_pixel_id = null;
      this.adset_conversion_event = null;
    }
  }

  get adset_budget() {
    return this._adset_budget;
  }

  set adset_budget(value) {
    this._adset_budget = parseNumber(value);
  }
}
