import { conditionMatch } from '@/utils/conditions.js';

export default {
  methods: {
    conditionalFilteredList(list) {
      return list.filter(item => 'conditions' in item ? conditionMatch(item.conditions, this.entity) : true);
    },
    conditionalFilteredTranslatedList(list, labelKey) {
      return this.translateOptionList(this.conditionalFilteredList(list), labelKey);
    },
  },
};
