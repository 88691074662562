<template>
  <div ref="frame" class="lazy-image-component">
    <template v-if="isVisible">
      <img
        v-if="!isError"
        :src="src"
        @load="() => onLoad()"
        @error="() => onError()"
      />

      <i v-if="isLoading" class="icon loader dark-gray" />
      <i v-else-if="isError" class="icon image-off gray" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'LazyImage',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    observer: null,
    isVisible: false,
    isLoading: true,
    isError: false,
  }),
  mounted() {
    this.observer = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        this.isVisible = true;
        this.observer.disconnect();
      }
    });

    this.observer.observe(this.$refs.frame);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    onLoad() {
      this.isLoading = false;
    },
    onError() {
      this.isError = true;
      this.isLoading = false;
    },
  },
};
</script>
