<template>
  <form-block
    v-model="isEnabled"
    class="rule-form-block campaign-special-ad-categories-block-component"
    :title="tr('Special ad categories')"
    :description="description"
    :disabled="disabled"
    switchable
  >
    <div>
      <label class="input-label">{{ tr('Categories') }}</label>
      <v-select
        v-model="entity.output_campaign.special_ad_categories"
        :options="facebookSpecialAdCategories"
        :reduce="(obj)=> obj.machine_name"
        :disabled="disabled"
        :searchable="false"
        multiple
      />
    </div>

    <div>
      <label class="input-label">{{ tr('Countries') }}</label>
      <v-select
        v-model="entity.output_campaign.special_ad_category_country"
        :options="facebookSpecialAdCountries"
        :reduce="(obj)=> obj.iso_a2"
        :disabled="disabled"
        label="name"
        multiple
      />
    </div>
  </form-block>
</template>

<script>
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';

export default {
  name: 'CampaignSpecialAdCategoriesBlock',
  mixins: [ RuleFormBlockMixin ],
  data: () => ({
    isEnabled: false,
  }),
  computed: {
    description() {
      return this.tr('You\'re required to declare if your ads are related to credit, employment or housing opportunities, or related to social issues, elections or politics.') +
        '<br/>' +
        '<a href="https://www.facebook.com/business/help/298000447747885" target="_blank">' + this.tr('Learn more') + '</a>';
    },
    facebookSpecialAdCategories() {
      return this.$store.getters['app/facebookSpecialAdCategories'];
    },
    facebookSpecialAdCountries() {
      return this.$store.getters['app/countries'];
    },
  },
  watch: {
    isEnabled(val) {
      if (!val) {
        this.entity.output_campaign.special_ad_categories = [];
        this.entity.output_campaign.special_ad_category_country = [];
        this.entity.settings.adset_targeting_advantage_plus_audience = true;
      }
      else {
        this.entity.settings.adset_targeting_advantage_plus_audience = false;
      }
    },
  },
  created() {
    if (this.entity && this.entity.output_campaign) {
      this.isEnabled = this.entity.output_campaign.special_ad_categories.length !== 0 || this.entity.output_campaign.special_ad_category_country.length !== 0;
    }
  },
  methods: {
    validate() {
      if (this.isEnabled) {
        if (this.entity.output_campaign.special_ad_categories.length === 0) {
          this.error(this.tr('Country of special ad categories cannot be empty!'));
          return false;
        }
        if (this.entity.output_campaign.special_ad_category_country.length === 0) {
          this.error(this.tr('Country of special ad category country cannot be empty!'));
          return false;
        }
      }

      return true;
    },
  },
};
</script>
