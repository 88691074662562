import ConfirmationModal from '../components/Modal';

const Api = (Vue, globalOptions = {}) => {
  return {
    open(options) {
      let content;
      if (typeof options === 'string') {
        content = options;
      }

      const defaultOptions = {
        content
      };

      const propsData = Object.assign({}, defaultOptions, globalOptions, options);

      return new (Vue.extend(ConfirmationModal))({
        el: document.createElement('div'),
        propsData
      })
    },
  };
};

export default Api;
