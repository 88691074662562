var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error-logs-view" },
    [
      _c("sticky-header", {
        attrs: { title: _vm.tr("Error log"), bordered: "" },
      }),
      _c("error-logs"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }