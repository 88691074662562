var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapperClasses,
      attrs: { "data-items": _vm.itemCount },
      on: { click: () => _vm.changeValue() },
    },
    [
      _c("i", { staticClass: "placeholder" }),
      _vm.itemCount > 1
        ? _c("div", { staticClass: "value" }, [
            _vm._v(_vm._s(_vm.tr(_vm.value))),
          ])
        : _vm._e(),
      !_vm.disabled
        ? _c(
            "dropdown-menu",
            {
              model: {
                value: _vm.dropdownIsOpen,
                callback: function ($$v) {
                  _vm.dropdownIsOpen = $$v
                },
                expression: "dropdownIsOpen",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "plus-button",
                  on: {
                    click: _vm.onClickPlus,
                    mouseover: () => (_vm.plusButtonHovered = true),
                    mouseleave: () => (_vm.plusButtonHovered = false),
                  },
                },
                [_c("i", { staticClass: "i icon plus" })]
              ),
              _c("div", { attrs: { slot: "dropdown" }, slot: "dropdown" }, [
                _c("div", { on: { click: _vm.addNewItem } }, [
                  _vm._v(_vm._s(_vm.tr("Add new condition row"))),
                ]),
                _c("div", { on: { click: _vm.addNewGroup } }, [
                  _vm._v(_vm._s(_vm.tr("Add new group"))),
                ]),
              ]),
            ]
          )
        : _c("i", { staticClass: "placeholder" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }