import BaseEntity from '@/entities/BaseEntity';
import Coupon from '@/entities/Coupon';
import SubscriptionPackage from '@/entities/SubscriptionPackage';

export default class Subscription extends BaseEntity {
  id = null;
  package = null;
  coupon = null;
  extra_assets = null;
  feature_managed_account = null;

  _entityFields = {
    package: SubscriptionPackage,
    coupon: Coupon,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
