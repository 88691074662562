var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      staticClass: "edit-user-restrictions-modal",
      attrs: { name: "edit-user-restrictions" },
      on: { "before-close": () => _vm.runBackupIfNecessary() },
    },
    [
      _c("div", { staticClass: "modal-title" }, [
        _vm._v(" " + _vm._s(_vm.tr("Edit user restrictions")) + " "),
      ]),
      _c("div", { staticClass: "modal-content" }, [
        _vm.$app.isAd
          ? _c(
              "div",
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(" " + _vm._s(_vm.tr("Visible Ad Accounts")) + " "),
                ]),
                _c("multi-select", {
                  attrs: {
                    options: _vm.adAccounts,
                    "append-to-body": false,
                    "value-key": "id",
                    "label-key": "name",
                  },
                  model: {
                    value: _vm.visibleAdAccountIds,
                    callback: function ($$v) {
                      _vm.visibleAdAccountIds = $$v
                    },
                    expression: "visibleAdAccountIds",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(" " + _vm._s(_vm.tr("Visible Datasources")) + " "),
            ]),
            _c("multi-select", {
              attrs: {
                options: _vm.sourceProfiles,
                "append-to-body": false,
                "value-key": "id",
                "label-key": "name",
              },
              model: {
                value: _vm.visibleSourceProfileIds,
                callback: function ($$v) {
                  _vm.visibleSourceProfileIds = $$v
                },
                expression: "visibleSourceProfileIds",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "modal-action-group" }, [
        _c(
          "button",
          { staticClass: "bordered gray", on: { click: () => _vm.close() } },
          [_vm._v(" " + _vm._s(_vm.tr("Cancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "primary",
            attrs: { disabled: _vm.isLoading },
            on: { click: () => _vm.submitForm() },
          },
          [
            _vm.isLoading
              ? _c("i", { staticClass: "icon loader white" })
              : [_vm._v(" " + _vm._s(_vm.tr("Save")) + " ")],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }