var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block ad-account-block-component",
      attrs: { title: _vm.tr("Ad account"), description: _vm.description },
    },
    [
      _vm.entity.disconnectedAdAccount
        ? _c("info-text", { attrs: { type: "error" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr("The selected ad account has been disconnected.")
                ) +
                " "
            ),
          ])
        : [
            _c("v-select", {
              attrs: {
                options: _vm.adAccounts,
                reduce: (obj) => obj.id,
                placeholder: _vm.tr("Search ad accounts"),
                disabled: _vm.disabled,
                clearable: false,
                label: "name",
              },
              model: {
                value: _vm.entity.ad_account.id,
                callback: function ($$v) {
                  _vm.$set(_vm.entity.ad_account, "id", $$v)
                },
                expression: "entity.ad_account.id",
              },
            }),
            _c(
              "router-link",
              {
                staticClass: "facebook-link",
                attrs: { to: "/connected-platforms/meta/ad-accounts" },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.tr("Can't find your ad account? Connect a new one")
                  )
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }