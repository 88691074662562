<template>
  <form-block
    class="rule-form-block budget-block-component adset-budget-block-component"
    :title="tr('Budget')"
  >
    <info-text v-if="!isAvailable">
      {{ tr('You have set a campaign budget, so the budget at ad set level is not available.') }}
    </info-text>
    <template v-else>
      <div>
        <label class="input-label">{{ tr('Ad set budget') }}</label>
        <div class="inline-input-wrapper inline-input-select">
          <money-input
            v-model="entity.settings.adset_budget"
            :disabled="disabled"
            :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : 'EUR'"
          />
          <v-select
            v-model="entity.settings.adset_budget_period"
            :options="budgetPeriods"
            :reduce="(obj)=> obj.machine_name"
            :disabled="disabled || !entity.isNew"
            :searchable="false"
            :clearable="false"
          />
        </div>
        <div v-if="budgetPeriod === 'lifetime'" class="lifetime-budget-date-wrapper">
          <div class="date-time date-time--start">
            <label class="input-label">{{ tr('Start date') }}</label>
            <date-time-input
              v-model="entity.settings.adset_start_time"
              :disabled="disabled || !entity.isNew"
            />
          </div>
          <div class="date-time date-time--end">
            <label class="input-label">{{ tr('End date') }}</label>
            <date-time-input
              v-model="entity.settings.adset_end_time"
              :disabled="disabled || !entity.isNew"
            />
          </div>
        </div>

        <info-text v-if="invalidLifetimeRange" type="error">
          {{ invalidLifetimeRangeInfoText }}
        </info-text>
        <info-text v-else-if="invalidBudget" type="error">
          {{ invalidBudgetInfoText }}
        </info-text>
        <info-text v-else :icon="isBudgetRequestRunning ? 'loader' : null">
          {{ minBudgetInfoText }}
        </info-text>
      </div>

      <div v-if="budgetPeriod === 'lifetime'">
        <label class="input-label">
          {{ tr('Ad lifespan (max. days)') }} {{ '(' + tr('optional') + ')' }}
        </label>
        <input
          v-model="entity.settings.adset_lifespan_max_days"
          type="number"
          min="1"
          :max="adsetLifespanMaxDaysMaxValue"
          step="1"
          class="max-number"
          :disabled="disabled"
        />
      </div>

      <div>
        <label class="input-label">
          {{ tr('Maximum number of posts promoted at once') }} {{ '(' + tr('optional') + ')' }}
        </label>
        <input
          v-model="entity.settings.max_active_ads"
          type="number"
          min="1"
          step="1"
          class="max-number"
          :disabled="disabled"
        />
      </div>

      <slot />
    </template>
  </form-block>
</template>

<script>
import DateTimeInput from '@/components/DateTimeInput.vue';
import InfoText from '@/components/InfoText.vue';
import MoneyInput from '@/components/MoneyInput.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import BudgetBlockMixin from '@/mixins/RuleForm/Blocks/Budget.js';

export default {
  name: 'AdsetBudgetBlock',
  components: {
    DateTimeInput,
    InfoText,
    MoneyInput,
  },
  mixins: [ RuleFormBlockMixin, BudgetBlockMixin ],
  computed: {
    isCampaignLevel() {
      return !this.isAdsetLevel;
    },
    isAdsetLevel() {
      return this.isAvailable;
    },
    isAvailable() {
      return !this.entity.output_campaign.budget;
    },
    adsetLifespanMaxDaysMaxValue() {
      if (this.budgetPeriod !== 'lifetime') {
        return null;
      }

      const result = Math.floor((this.entity.settings.adset_end_time.getTime() - this.entity.settings.adset_start_time.getTime()) / 1000 / 86400);
      if (isNaN(result) || result < 1) {
        return 1;
      }

      return result;
    },
  },
  watch: {
    'entity.settings.adset_lifespan_max_days'() {
      this.debouncedGetMinBudget();
    },
  },
  created() {
    if (this.isAvailable && !this.entity.settings.adset_budget_period) {
      this.entity.settings.adset_budget_period = this.budgetPeriods[0].machine_name;
    }

    if (this.isAvailable) {
      this.$nextTick(() => this.getMinBudget());
    }
  },
  methods: {
    validate() {
      if (this.isAvailable) {
        if (!this.entity.settings.adset_budget) {
          this.error(this.tr('Ad set budget is required because you have not set a campain level budget!'));
          return false;
        }

        if (!this.validateBudget()) {
          return false;
        }

        if (
          this.entity.settings.max_active_ads !== null
          && this.entity.settings.max_active_ads !== ''
          && this.entity.settings.max_active_ads < 1
        ) {
          this.error(this.tr('Maximum number of active ads at the same time cannot be less than one!'));
          return false;
        }
      }

      return true;
    },
  },
};
</script>
