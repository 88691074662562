export const PLATFORMS = [
  {
    label: 'Facebook',
    machineName: 'facebook',
  },
  {
    label: 'Instagram',
    machineName: 'instagram',
  },
];

export const AI_STATUS = {
  WAITING: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3,
};
