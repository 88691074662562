<template>
  <div ref="wrapper" :class="wrapperClasses">
    <div ref="checkboxes" class="checkboxes">
      <checkbox-input
        v-for="(action, index) in actionOptions"
        :key="'rule-action-filter--' + index"
        :value="actionFilter.includes(action.machine_name)"
        :icon="action.icon"
        :label="action.label"
        :color="action.color"
        :disabled="disabled"
        @input="toggleAction(action.machine_name, $event)"
      />
    </div>
  </div>
</template>

<script>
import CheckboxInput from '@/components/CheckboxInput.vue';
import { RULE_ACTION } from '@/consts.js';

export default {
  name: 'RuleActionFilter',
  components: {
    CheckboxInput,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data: () => ({
    labelVisibilityCheckInProgress: false,
    hasEnoughtSpaceForLabels: true,
  }),
  computed: {
    actionFilter() {
      return this.$store.getters['rules/actionFilter'];
    },
    actionOptions() {
      return [
        {
          label: this.showLabels ? this.tr('Ad Creation') : null,
          icon: this.showLabels ? null : 'rocket',
          machine_name: RULE_ACTION.START,
          color: 'primary',
        },
        {
          label: this.showLabels ? this.tr('Ad Scaling') : null,
          icon: this.showLabels ? null : 'scaling-up',
          machine_name: RULE_ACTION.SCALING_UP,
          color: 'pink',
        },
        {
          label: this.showLabels ? this.tr('Ad Stop') : null,
          icon: this.showLabels ? null : 'blister',
          machine_name: RULE_ACTION.STOP,
          color: 'orange',
        },
      ];
    },
    showLabels() {
      return this.hasEnoughtSpaceForLabels || this.labelVisibilityCheckInProgress;
    },
    wrapperClasses() {
      return {
        'rule-action-filter-component': true,
        'show-labels': this.showLabels,
      };
    },
  },
  mounted() {
    this.calculateLabelVisibility();
    window.addEventListener('resize', this.calculateLabelVisibility);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateLabelVisibility);
  },
  methods: {
    calculateLabelVisibility() {
      this.labelVisibilityCheckInProgress = true;
      this.$nextTick(() => {
        const wrapperWidth = this.$refs.wrapper.offsetWidth;
        const checkboxesWidth = this.$refs.checkboxes.offsetWidth;

        this.hasEnoughtSpaceForLabels = wrapperWidth >= checkboxesWidth;
        this.labelVisibilityCheckInProgress = false;
      });
    },
    toggleAction(machineName, event) {
      const isChecked = event.target.checked;
      let updatedFilter = [ ...this.actionFilter ];

      if (isChecked) {
        if (!updatedFilter.includes(machineName)) {
          updatedFilter.push(machineName);
        }
      }
      else {
        updatedFilter = updatedFilter.filter(item => item !== machineName);
      }

      if (updatedFilter.length === 0) {
        event.target.checked = true;
        this.infoToast(this.tr('At least one automation type must be selected.'));
        return;
      }

      this.$store.commit('rules/setActionFilter', updatedFilter);
    },
  },
};
</script>
