var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block geotarget-block-component",
      attrs: {
        title: _vm.title !== null ? _vm.title : _vm.tr("Geotargeting"),
        description: _vm.description,
      },
    },
    [
      _vm.targetAudienceBlockIsLoading
        ? _c("div", { staticClass: "inline-loader" }, [
            _c("i", { staticClass: "icon loader" }),
            _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
          ])
        : _vm.hasGeotargetingInSelectedAudiences
        ? _c("info-text", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr(
                    "You have chosen a target audience where geographic targeting is specified."
                  )
                ) +
                " "
            ),
          ])
        : _c("geolocation-target-selector", {
            ref: "geolocationTargetSelector",
            attrs: { disabled: _vm.disabled },
            model: {
              value: _vm.entity.settings.adset_targeting,
              callback: function ($$v) {
                _vm.$set(_vm.entity.settings, "adset_targeting", $$v)
              },
              expression: "entity.settings.adset_targeting",
            },
          }),
      _c(
        "div",
        [
          _c("label", { staticClass: "input-label" }, [
            _vm._v(" " + _vm._s(_vm.tr("Spoken languages")) + " "),
            _c("small", [_vm._v("(" + _vm._s(_vm.tr("optional")) + ")")]),
          ]),
          _c("v-select", {
            attrs: {
              options: _vm.languages,
              reduce: (obj) => obj.id,
              label: "name",
              multiple: "",
            },
            model: {
              value: _vm.entity.settings.adset_targeting.locales,
              callback: function ($$v) {
                _vm.$set(_vm.entity.settings.adset_targeting, "locales", $$v)
              },
              expression: "entity.settings.adset_targeting.locales",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }