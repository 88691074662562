import { freeze } from '@/utils/object.js';

export const EVENT_BUS_EVENTS = freeze({
  PROHIBITED_CONTENT_OVERRIDDEN: 'prohibited_content_overridden',
  WAITING_ACTIVITY_ACCEPTED: 'waiting_activity_accepted',
  WAITING_ACTIVITY_REJECTED: 'waiting_activity_rejected',
});

export const PLATFORMS = freeze([
  {
    label: 'Facebook',
    machineName: 'facebook',
  },
  {
    label: 'Instagram',
    machineName: 'instagram',
  },
]);

export const RULE_ACTION = freeze({
  START: 'start',
  STOP: 'stop',
  SCALING_UP: 'scaling_up',
});

export const AI_STATUS = freeze({
  WAITING: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3,
  MANUAL: 4,
});

export const CAMPAIGN_STATUS = freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
});

export const AD_CREATION_METHOD = freeze({
  POST_BOOST: 'post_boost',
  COPY: 'copy',
  CREATIVE: 'creative',
});

export const ORIGINAL_POST_LANGUAGE = 'original_post_language';
export const TARGETED_COUNTRY_LANGUAGE = 'targeted_country_language';

export const AI_ASSIST_OPTIONS = freeze({
  CREATE_CREATIVES: 'create_creatives',
});

export const RULE_ACTIVITY_MANAGEMENT_INSTRUCTION = {
  ACCEPT: 'accept',
  REJECT: 'reject',
};

export const RULE_REVIEW_CODE = freeze({
  UNKNOWN: -1,
  WAITING_FOR_PROCESS: 0,
  WAITING_FOR_PROCESS_IN_ACTIVITY_MANAGEMENT: 17,
  IN_ACTIVITY_MANAGEMENT_QUEUE: 1,
  REJECTED_MANUALLY_IN_ACTIVITY_MANAGEMENT: 2,
  REJECTED_AUTOMATICALLY_IN_ACTIVITY_MANAGEMENT: 3,
  ALREADY_PROCESSED: 4,
  CONDITION_NOT_MET: 5,
  FILTERED_BY_POST_TYPE: 6,
  FILTERED_BY_POST_MEDIA_TYPE: 7,
  NOT_ENOUGH_BUDGET: 8,
  MAX_ACTIVE_ADS_LIMIT: 16,
  PROHIBITED_CONTENT: 9,
  EXPIRED_CONTENT: 10,
  VIDEO_AD_REQUIRES_VIDEO: 11,
  TRAFFIC_AND_CONVERSION_AD_REQUIRES_LINK: 12,
  ENGAGEMENT_CAMPAIGN_NOT_SUPPORTS_INSTAGRAM_ALBUM: 13,
  CODE_PAGE_LIKE_ENGAGEMENT_CAMPAIGN_NOT_SUPPORTS_ALBUM: 15,
  VIDEO_TOO_LONG: 14,
});

export const RULE_REVIEW_CODE_TYPE = freeze({
  WAITING: 'waiting',
  COMPLETED: 'completed',
  FILTERED_OUT: 'filtered_out',
  CONTENT_ISSUE: 'content_issue',
  TECHNICAL_RESTRICTION: 'technical_restriction',
});

export const RULE_REVIEW_FILTER_DEFINITIONS = freeze([
  {
    code: RULE_REVIEW_CODE.UNKNOWN,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'Post cannot be used',
      [RULE_ACTION.STOP]: 'Ad will not be stopped',
      [RULE_ACTION.SCALING_UP]: 'Ad cannot be scaled up',
    },
    label: {
      [RULE_ACTION.START]: 'This post cannot be used for advertising due to an unknown technical restriction',
      [RULE_ACTION.STOP]: 'This ad will not be stopped as the reason for processing cannot be determined',
      [RULE_ACTION.SCALING_UP]: 'This ad cannot be scaled up due to an unknown technical restriction',
    },
    whyNot: {
      [RULE_ACTION.START]: 'We encountered an unexpected technical issue that prevents using this post.',
      [RULE_ACTION.STOP]: 'We encountered an unexpected technical issue that prevents stopping this ad.',
      [RULE_ACTION.SCALING_UP]: 'We encountered an unexpected technical issue that prevents scaling up this ad.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Please try again later or contact support if the issue persists.',
      [RULE_ACTION.STOP]: 'Please try again later or contact support if the issue persists.',
      [RULE_ACTION.SCALING_UP]: 'Please try again later or contact support if the issue persists.',
    },
  },
  {
    code: RULE_REVIEW_CODE.WAITING_FOR_PROCESS,
    type: RULE_REVIEW_CODE_TYPE.WAITING,
    shortLabel: {
      [RULE_ACTION.START]: 'Ad will be created',
      [RULE_ACTION.STOP]: 'Ad will be stopped',
      [RULE_ACTION.SCALING_UP]: 'Ad will be scaled up',
    },
    label: {
      [RULE_ACTION.START]: 'This post is scheduled to be processed for ad creation',
      [RULE_ACTION.STOP]: 'This ad is scheduled to be processed for deactivation',
      [RULE_ACTION.SCALING_UP]: 'This ad is scheduled to be processed for scaling up',
    },
  },
  {
    code: RULE_REVIEW_CODE.WAITING_FOR_PROCESS_IN_ACTIVITY_MANAGEMENT,
    type: RULE_REVIEW_CODE_TYPE.WAITING,
    shortLabel: {
      [RULE_ACTION.START]: 'Ad will be created',
      [RULE_ACTION.STOP]: 'Ad will be stopped',
      [RULE_ACTION.SCALING_UP]: 'Ad will be scaled up',
    },
    label: {
      [RULE_ACTION.START]: 'This post is scheduled to be processed for ad creation',
      [RULE_ACTION.STOP]: 'This ad is scheduled to be processed for deactivation',
      [RULE_ACTION.SCALING_UP]: 'This ad is scheduled to be processed for scaling up',
    },
  },
  {
    code: RULE_REVIEW_CODE.IN_ACTIVITY_MANAGEMENT_QUEUE,
    type: RULE_REVIEW_CODE_TYPE.WAITING,
    shortLabel: {
      [RULE_ACTION.START]: 'Waiting for approval',
      [RULE_ACTION.STOP]: 'Waiting for approval',
      [RULE_ACTION.SCALING_UP]: 'Waiting for approval',
    },
    label: {
      [RULE_ACTION.START]: 'This post is currently in the queue for ad creation',
      [RULE_ACTION.STOP]: 'This ad is currently in the queue for deactivation',
      [RULE_ACTION.SCALING_UP]: 'This ad is currently in the queue for scaling up',
    },
    whyNot: {
      [RULE_ACTION.START]: 'You have enabled activity management in the automation, and the ad creation is now waiting for your approval.',
      [RULE_ACTION.STOP]: 'You have enabled activity management in the automation, and stopping the ad is now only waiting for your approval.',
      [RULE_ACTION.SCALING_UP]: 'You have enabled activity management in the automation, and the ad creation is now waiting for your approval.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Accept or reject the activity.',
      [RULE_ACTION.STOP]: 'Accept or reject the activity.',
      [RULE_ACTION.SCALING_UP]: 'Accept or reject the activity.',
    },
  },
  {
    code: RULE_REVIEW_CODE.REJECTED_MANUALLY_IN_ACTIVITY_MANAGEMENT,
    type: RULE_REVIEW_CODE_TYPE.FILTERED_OUT,
    shortLabel: {
      [RULE_ACTION.START]: 'Manually rejected',
      [RULE_ACTION.STOP]: 'Manually rejected',
      [RULE_ACTION.SCALING_UP]: 'Manually rejected',
    },
    label: {
      [RULE_ACTION.START]: 'Ad creation manually rejected',
      [RULE_ACTION.STOP]: 'Ad stop manually rejected',
      [RULE_ACTION.SCALING_UP]: 'Ad creation manually rejected',
    },
    whyNot: {
      [RULE_ACTION.START]: 'You enabled activity management in the automation and manually rejected the ad creation.',
      [RULE_ACTION.STOP]: 'In the automation, you enabled activity management and manually rejected the ad stoppage.',
      [RULE_ACTION.SCALING_UP]: 'You enabled activity management in the automation and manually rejected the ad creation.',
    },
  },
  {
    code: RULE_REVIEW_CODE.REJECTED_AUTOMATICALLY_IN_ACTIVITY_MANAGEMENT,
    type: RULE_REVIEW_CODE_TYPE.FILTERED_OUT,
    shortLabel: {
      [RULE_ACTION.START]: 'Automatically rejected',
      [RULE_ACTION.STOP]: 'Automatically rejected',
      [RULE_ACTION.SCALING_UP]: 'Automatically rejected',
    },
    label: {
      [RULE_ACTION.START]: 'Ad creation automatically rejected',
      [RULE_ACTION.STOP]: 'Ad stop automatically rejected',
      [RULE_ACTION.SCALING_UP]: 'Ad creation automatically rejected',
    },
    whyNot: {
      [RULE_ACTION.START]: 'You enabled activity management in the automation and automatically rejected the ad creation.',
      [RULE_ACTION.STOP]: 'In the automation, you enabled activity management, and the ad stop was automatically rejected.',
      [RULE_ACTION.SCALING_UP]: 'You enabled activity management in the automation and automatically rejected the ad creation.',
    },
  },
  {
    code: RULE_REVIEW_CODE.ALREADY_PROCESSED,
    type: RULE_REVIEW_CODE_TYPE.COMPLETED,
    shortLabel: {
      [RULE_ACTION.START]: 'Ad already created',
      [RULE_ACTION.STOP]: 'Ad already stopped',
      [RULE_ACTION.SCALING_UP]: 'Ad already scaled up',
    },
    label: {
      [RULE_ACTION.START]: 'An ad has already been created from this post',
      [RULE_ACTION.STOP]: 'This ad has already been deactivated',
      [RULE_ACTION.SCALING_UP]: 'This ad has already been scaled up',
    },
  },
  {
    code: RULE_REVIEW_CODE.CONDITION_NOT_MET,
    type: RULE_REVIEW_CODE_TYPE.FILTERED_OUT,
    shortLabel: {
      [RULE_ACTION.START]: 'Post excluded by filters',
      [RULE_ACTION.STOP]: 'Ad excluded by filters',
      [RULE_ACTION.SCALING_UP]: 'Ad excluded by filters',
    },
    label: {
      [RULE_ACTION.START]: 'This post does not match the selection criteria set for ad creation in the automation',
      [RULE_ACTION.STOP]: 'This ad does not match the selection criteria set for deactivation in the automation',
      [RULE_ACTION.SCALING_UP]: 'This ad does not match the selection criteria set for scaling up in the automation',
    },
    whyNot: {
      [RULE_ACTION.START]: 'Your automation is set up to create ads only from posts that match specific criteria. This post does not meet these criteria.',
      [RULE_ACTION.STOP]: 'Your automation is set up to stop only ads that match specific criteria. This ad does not meet these criteria.',
      [RULE_ACTION.SCALING_UP]: 'Your automation is set up to scale up only ads that match specific criteria. This ad does not meet these criteria.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Review and adjust your automation filters if you want this post to be included.',
      [RULE_ACTION.STOP]: 'Review and adjust your automation filters if you want this ad to be included.',
      [RULE_ACTION.SCALING_UP]: 'Review and adjust your automation filters if you want this ad to be included.',
    },
  },
  {
    code: RULE_REVIEW_CODE.EXPIRED_CONTENT,
    type: RULE_REVIEW_CODE_TYPE.CONTENT_ISSUE,
    shortLabel: {
      [RULE_ACTION.START]: 'Post content expired',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Ad content expired',
    },
    label: {
      [RULE_ACTION.START]: 'This post has expired and cannot be used for creating new ads',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'This ad contains expired content and cannot be scaled up',
    },
    whyNot: {
      [RULE_ACTION.START]: 'The content in this post has an expiration date that has already passed. We cannot create ads from expired content.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'The content used in this ad has expired. We cannot scale up ads with expired content.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'If you disagree with the expiration assessment, you can edit the content expiration date in the post settings. Alternatively, you can disable the "Do not create ads from posts with expired content" AI Assist feature if you want to proceed with this ad.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'If you disagree with the expiration assessment, you can edit the content expiration date in the post settings. Alternatively, you can disable the "Do not scale up ads with expired content" AI Assist feature if you want to proceed.',
    },
  },
  {
    code: RULE_REVIEW_CODE.PROHIBITED_CONTENT,
    type: RULE_REVIEW_CODE_TYPE.CONTENT_ISSUE,
    shortLabel: {
      [RULE_ACTION.START]: 'Prohibited content',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
    label: {
      [RULE_ACTION.START]: 'This post contains prohibited content and cannot be used for advertising',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
    whyNot: {
      [RULE_ACTION.START]: 'The content in this post does not comply with Meta\'s advertising policies. This might include restricted products, services, or other content prohibited by Meta.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'If you disagree with this assessment, you can override the AI\'s decision using the button below.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
  },
  {
    code: RULE_REVIEW_CODE.FILTERED_BY_POST_TYPE,
    type: RULE_REVIEW_CODE_TYPE.FILTERED_OUT,
    shortLabel: {
      [RULE_ACTION.START]: 'Post excluded by filters',
      [RULE_ACTION.STOP]: 'Ad excluded by filters',
      [RULE_ACTION.SCALING_UP]: 'Ad excluded by filters',
    },
    label: {
      [RULE_ACTION.START]: 'This post type is excluded from ad creation by the automation settings',
      [RULE_ACTION.STOP]: 'This post type is excluded from ad deactivation by the automation settings',
      [RULE_ACTION.SCALING_UP]: 'This post type is excluded from ad scaling up by the automation settings',
    },
    whyNot: {
      [RULE_ACTION.START]: 'Your automation is set up to handle only specific types of posts (e.g., photos, videos, links). This post type is not included.',
      [RULE_ACTION.STOP]: 'Your automation is set up to handle only specific types of posts. This ad\'s post type is not included.',
      [RULE_ACTION.SCALING_UP]: 'Your automation is set up to handle only specific types of posts. This ad\'s post type is not included.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Include this post type in your automation settings if you want it to be processed.',
      [RULE_ACTION.STOP]: 'Include this post type in your automation settings if you want it to be processed.',
      [RULE_ACTION.SCALING_UP]: 'Include this post type in your automation settings if you want it to be processed.',
    },
  },
  {
    code: RULE_REVIEW_CODE.FILTERED_BY_POST_MEDIA_TYPE,
    type: RULE_REVIEW_CODE_TYPE.FILTERED_OUT,
    shortLabel: {
      [RULE_ACTION.START]: 'Post excluded by filters',
      [RULE_ACTION.STOP]: 'Ad excluded by filters',
      [RULE_ACTION.SCALING_UP]: 'Ad excluded by filters',
    },
    label: {
      [RULE_ACTION.START]: 'This post media type is excluded from ad creation by the automation settings',
      [RULE_ACTION.STOP]: 'This post media type is excluded from ad deactivation by the automation settings',
      [RULE_ACTION.SCALING_UP]: 'This post media type is excluded from ad scaling up by the automation settings',
    },
    whyNot: {
      [RULE_ACTION.START]: 'Your automation is set up to handle only specific media types. The media in this post is not included.',
      [RULE_ACTION.STOP]: 'Your automation is set up to handle only specific media types. The media in this ad\'s post is not included.',
      [RULE_ACTION.SCALING_UP]: 'Your automation is set up to handle only specific media types. The media in this ad\'s post is not included.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Include this media type in your automation settings if you want it to be processed.',
      [RULE_ACTION.STOP]: 'Include this media type in your automation settings if you want it to be processed.',
      [RULE_ACTION.SCALING_UP]: 'Include this media type in your automation settings if you want it to be processed.',
    },
  },
  {
    code: RULE_REVIEW_CODE.NOT_ENOUGH_BUDGET,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'Insufficient budget',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Insufficient budget',
    },
    label: {
      [RULE_ACTION.START]: 'There is not enough campaign budget to create an ad from this post',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'There is not enough campaign budget to scale up this ad',
    },
    whyNot: {
      [RULE_ACTION.START]: 'The campaign\'s current budget is too low to create new ads.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'The target campaign\'s budget is too low to add this ad.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Increase your campaign budget if you want this ad to be created.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Increase your campaign budget if you want this ad to be scaled up.',
    },
  },
  {
    code: RULE_REVIEW_CODE.MAX_ACTIVE_ADS_LIMIT,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'Max active ads limit reached',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Max active ads limit reached',
    },
    label: {
      [RULE_ACTION.START]: 'An ad will not be created from this post because the maximum active ads limit you set has been reached',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'This ad will not be scaled up because the maximum active ads limit you set has been reached',
    },
    whyNot: {
      [RULE_ACTION.START]: 'You have set a maximum number of active ads in the Adset budget settings, and this limit has been reached.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'You have set a maximum number of active ads in the Adset budget settings, and this limit has been reached.',
    },
    whatToDo: {
      [RULE_ACTION.START]: 'Increase the maximum active ads limit in your Adset budget settings if you want this ad to be created.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Increase the maximum active ads limit in your Adset budget settings if you want this ad to be scaled up.',
    },
  },
  {
    code: RULE_REVIEW_CODE.VIDEO_AD_REQUIRES_VIDEO,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'No video content',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'No video content',
    },
    label: {
      [RULE_ACTION.START]: 'This post cannot be used for video ads as it does not contain video content',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'This ad cannot be scaled up as a video ad because the original post does not contain video content',
    },
    whyNot: {
      [RULE_ACTION.START]: 'For this campaign objective, only posts containing video content can be used to create ads.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'For this campaign objective, only ads created from posts containing video content can be scaled up.',
    },
  },
  {
    code: RULE_REVIEW_CODE.TRAFFIC_AND_CONVERSION_AD_REQUIRES_LINK,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'No link provided',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'No link provided',
    },
    label: {
      [RULE_ACTION.START]: 'This post cannot be used for traffic or conversion ads as it does not contain a link',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'This ad cannot be scaled up for traffic or conversion as the original post does not contain a link',
    },
    whyNot: {
      [RULE_ACTION.START]: 'For traffic and conversion campaign objectives, only posts containing a clickable link can be used to create ads.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'For traffic and conversion campaign objectives, only ads created from posts containing a clickable link can be scaled up.',
    },
  },
  {
    code: RULE_REVIEW_CODE.ENGAGEMENT_CAMPAIGN_NOT_SUPPORTS_INSTAGRAM_ALBUM,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'Unsupported format',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Unsupported format',
    },
    label: {
      [RULE_ACTION.START]: 'This Instagram album post cannot be used for engagement campaign ads',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
    whyNot: {
      [RULE_ACTION.START]: 'Instagram album posts cannot be used for engagement campaign ads due to platform limitations.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
  },
  {
    code: RULE_REVIEW_CODE.CODE_PAGE_LIKE_ENGAGEMENT_CAMPAIGN_NOT_SUPPORTS_ALBUM,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'Unsupported format',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Unsupported format',
    },
    label: {
      [RULE_ACTION.START]: 'This album post cannot be used for page like engagement campaign ads',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
    whyNot: {
      [RULE_ACTION.START]: 'Album posts cannot be used for page like engagement campaign ads due to platform limitations.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
  },
  {
    code: RULE_REVIEW_CODE.VIDEO_TOO_LONG,
    type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
    shortLabel: {
      [RULE_ACTION.START]: 'Video too long',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'Video too long',
    },
    label: {
      [RULE_ACTION.START]: 'The video in this post exceeds the maximum allowed duration of 300 seconds for advertising',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: 'The video in the original post exceeds the maximum allowed duration of 300 seconds for advertising',
    },
    whyNot: {
      [RULE_ACTION.START]: 'The Infinite∞Ad platform only accepts videos up to 300 seconds (5 minutes) in length.',
      [RULE_ACTION.STOP]: '',
      [RULE_ACTION.SCALING_UP]: '',
    },
  },
]);
