import BaseEntity from '@/entities/BaseEntity';
import AdAccount from '@/entities/AdAccount';
import ConnectedProfile from '@/entities/ConnectedProfile';
import SourceProfile from '@/entities/SourceProfile';
import SubscriptionPackage from '@/entities/SubscriptionPackage';

export default class Company extends BaseEntity
{
  id = null;
  name = '';
  required_2fa = false;
  enabled_manual_run = false;
  connected_profiles = [];
  source_profiles = [];
  ad_accounts = [];
  managed_companies = [];
  has_manager_company = false;

  start_of_subscription = null;
  next_billing_date = null;
  last_payment_is_incomplete = false;

  current_active_package = null;
  current_extra_users = null;
  current_extra_assets = null;
  current_feature_managed_account = null;

  paid_active_package = null;
  paid_extra_users = null;
  paid_extra_assets = null;
  paid_feature_managed_account = null;

  max_users = null;
  max_assets = null;

  next_payment_date = null;
  grace_period_end_date = null;
  feature_managed_account_cancellable_at = null;
  vat_rate = null;
  billing_first_name = '';
  billing_last_name = '';
  billing_company = '';
  billing_vat_number = '';
  billing_country = '';
  billing_zip = '';
  billing_city = '';
  billing_address = '';
  billing_email = '';
  timezone = 'UTC';
  created_at = new Date();
  deleted_at = null;

  _entityFields = {
    current_active_package: SubscriptionPackage,
    paid_active_package: SubscriptionPackage,
  };
  _entityArrayFields = {
    ad_accounts: AdAccount,
    source_profiles: SourceProfile,
    connected_profiles: ConnectedProfile,
    managed_companies: Company,
  };

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Deleted] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }

  updateWith(newValues) {
    if (!super.updateWith(newValues)) {
      return;
    }

    if (this.next_payment_date && typeof this.next_payment_date === 'string') {
      this.next_payment_date = new Date(this.next_payment_date);
    }
    if (this.grace_period_end_date && typeof this.grace_period_end_date === 'string') {
      this.grace_period_end_date = new Date(this.grace_period_end_date);
    }
    if (this.feature_managed_account_cancellable_at && typeof this.feature_managed_account_cancellable_at === 'string') {
      this.feature_managed_account_cancellable_at = new Date(this.feature_managed_account_cancellable_at);
    }
  }

  updateAdAccount(newValues) {
    for (let adAccount of this.ad_accounts) {
      if (adAccount['id'] === newValues['id']) {
        adAccount.updateWith(newValues);
        break;
      }
    }
  }

  get usedAssetsCount() {
    const numberOfConnectedSourceProfiles = this.source_profiles.filter(entity => entity.deleted_at === null).length;
    const numberOfConnectedAdAccounts = this.ad_accounts.filter(entity => entity.deleted_at === null).length;

    return numberOfConnectedSourceProfiles + numberOfConnectedAdAccounts;
  }
}
