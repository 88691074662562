var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "auth-page" }, [
    _c("div", { staticClass: "content-wrapper" }, [
      _c(
        "div",
        { staticClass: "input-form-container" },
        [
          _vm.created
            ? _c("enable-google-2fa", {
                attrs: {
                  description:
                    _vm.tr(
                      "The use of two-factor authentication is mandatory in the organization."
                    ) + "<br/><br/>",
                },
                on: {
                  success: () => _vm.onSuccess(),
                  cancel: () => _vm.logout(),
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }