import axios from 'axios';
import { store } from '@/store';
import { router } from '@/router';
import { ApiError, ValidationError, Required2faError, UnauthorizedError, NotFoundError, ForbiddenError, PaymentRequiredError } from '@/errors';
import { setupNavigationBlocker } from '@/utils/navigationBlocker';

const isDevelopment = (process.env.NODE_ENV === 'development');

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    'Accept': '*/*',
    'Content-Type': 'application/json',
  },
});

let message = store.getters['app/language'] === 'en' ? 'Changes you made may not be saved. Are you sure you want to leave this page?' : 'Az oldalon végzett változtatások nem biztos, hogy el lesznek mentve. Biztosan el akarja hagyni az oldalt?';
setupNavigationBlocker(instance, message);

instance.interceptors.response.use(
  // 2xx
  function (response) {
    if (response.status === 200 && response.data instanceof Blob) {
      return response.data;
    }

    if (response.data.code === 200) {
      return response.data.payload;
    }

    if (response.data.code === 403) {
      throw new ForbiddenError(response.data.errors);
    }

    if (response.data.code === 404) {
      throw new NotFoundError(response.data.errors);
    }

    if (response.data.code === 422) {
      throw new ValidationError(response.data.errors);
    }

    throw new Error(response.data && response.data.errors && response.data.errors.length ? response.data.errors : 'Unknown error');
  },
  // 3xx-4xx
  function (error) {
    if (isDevelopment) {
      console.error(error);
      console.error(error.response);
    }

    if (error.response && error.response.status === 401) {
      if (
        error.request.responseURL.indexOf('auth/login') === -1
        && error.request.responseURL.indexOf('support/') === -1
      ) {
        store.commit('auth/logout');
      }

      throw new UnauthorizedError(error.message);
    }
    else if (error.response && error.response.status === 422) {
      throw new ValidationError(error.response.data.errors);
    }
    else if (error.response && error.response.status === 404) {
      throw new NotFoundError(error.response.data.errors);
    }
    else if (error.response && error.response.status === 402) {
      throw new PaymentRequiredError(error.response.data.payload.price);
    }
    else if (error.response && error.response.status === 423) {
      if (window.location.pathname !== '/enable-2fa') {
        router.replace('/enable-2fa');
      }

      throw new Required2faError();
    }
    else if (error.response && error.response.data && error.response.data.errors && error.response.data.errors === 'PHP Error: Post max size exceeded.') {
      console.error(error.response.data.errors);
      throw error;
    }
    else if (error.response && error.response.data && error.response.data.payload && typeof error.response.data.payload === 'string') {
      throw new ApiError(error.response.data.payload);
    }
    else if (error.response && error.response.data && error.response.data.errors && typeof error.response.data.errors === 'string') {
      throw new ApiError(error.response.data.errors);
    }
    else {
      throw (isDevelopment ? error : new Error('Ismeretlen hiba'))
    }
  }
)

export default() => {
  const token = store.getters['auth/token'];
  if (token) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }
  else {
    delete instance.defaults.headers.common['Authorization'];
  }

  const supportToken = store.getters['support/token'];
  if (supportToken) {
    instance.defaults.headers.common['Support-Authorization'] = supportToken;
  }
  else {
    delete instance.defaults.headers.common['Support-Authorization'];
  }

  const companyId = store.getters['auth/selectedCompanyId'];
  if (companyId) {
    instance.defaults.headers.common['Company-Id'] = companyId;
  }
  else {
    delete instance.defaults.headers.common['Company-Id'];
  }

  const language = store.getters['app/language'];
  instance.defaults.headers.common['Accept-Language'] = language;

  return instance;
}
