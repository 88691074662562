<template>
  <form-block
    class="rule-form-block notifiable-users-block-component"
    :title="tr('Members to be notified about activities')"
    :description="description"
  >
    <multi-select
      v-model="insideValue"
      :options="options"
      :placeholder="tr('Search member')"
      :disabled="disabled || isLoading"
      :loading="isLoading"
      label-key="fullname"
      value-key="id"
    />
  </form-block>
</template>

<script>
import MultiSelect from '@/components/MultiSelect.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import CompanyService from '@/services/Company.js';

export default {
  name: 'NotifiableUsersBlock',
  components: {
    MultiSelect,
  },
  mixins: [ RuleFormBlockMixin ],
  data: () => ({
    isLoading: true,
    userList: [],
    insideValue: [],
  }),
  computed: {
    options() {
      return this.userList.map(user => ({
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        fullname: user.firstname + ' ' + user.lastname,
      }));
    },
    description() {
      if (this.entity.action === 'stop') {
        return this.tr('The selected individuals will receive email notifications when an advertisement stops based on the configured rule.');
      }

      return this.tr('The selected individuals will receive email notifications when an advertisement starts based on the configured rule.');
    },
  },
  watch: {
    isLoading(val) {
      if (!val) {
        this.insideValue = this.entity.notifiable_users.map(user => user.id);
      }
    },
    value(val) {
      if (val && !this.isLoading) {
        const currentIds1 = JSON.parse(JSON.stringify(this.insideValue)).sort();
        const currentIds2 = this.entity.notifiable_users.map(user => user.id).sort();

        if (JSON.stringify(currentIds1) !== JSON.stringify(currentIds2)) {
          this.insideValue = this.entity.notifiable_users.map(user => user.id);
        }
      }
    },
    insideValue(val) {
      if (!this.isLoading) {
        const currentIds1 = JSON.parse(JSON.stringify(this.insideValue)).sort();
        const currentIds2 = this.entity.notifiable_users.map(user => user.id).sort();

        if (JSON.stringify(currentIds1) !== JSON.stringify(currentIds2)) {
          const newValue = val.map(userId => this.options.find(user => user.id === userId));
          this.entity.notifiable_users = newValue;
        }
      }
    },
  },
  created() {
    CompanyService
      .getMembers(this.$root.currentCompany.id)
      .then(users => this.userList = users)
      .catch(() => this.error(this.tr('Failed to load members.')))
      .finally(() => this.isLoading = false);
  },
  methods: {
    validate() {
      const availableUserIds = this.userList.map(user => user.id);

      for (const userId of this.insideValue) {
        if (!availableUserIds.includes(userId)) {
          this.error(this.tr('One of the selected users is no longer available.'));
          return false;
        }
      }

      return true;
    },
  },
};
</script>
