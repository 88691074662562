var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { class: [_vm.colorClass, _vm.wrapperClasses] }, [
    _c("input", {
      attrs: { type: "checkbox", disabled: _vm.disabled },
      domProps: { checked: _vm.value },
      on: {
        change: function ($event) {
          return _vm.$emit("input", $event)
        },
      },
    }),
    _vm.icon
      ? _c("i", { class: "icon " + _vm.icon + " " + _vm.colorClass })
      : _vm._e(),
    _vm._v(" " + _vm._s(_vm.label) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }