var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      class: _vm.wrapperClasses,
      on: { click: (e) => _vm.onClickWrapper(e) },
    },
    [
      _vm.isOpen || _vm.isClosing
        ? [
            _c("create-new-rule-cards", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen || _vm.isClosing,
                  expression: "isOpen || isClosing",
                },
              ],
              class: { closing: _vm.isClosing },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }