var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-ad-table-component" },
    [
      !_vm.adAccountId
        ? _c("empty-screen", {
            attrs: {
              icon: "stat",
              title: _vm.tr("Select ad account to view performance data"),
              "flag-text": _vm.tr("View your insights"),
              description: _vm.tr(
                "Your accounts are connected and ready for analysis. Choose a specific ad account and automation rule to see detailed metrics and discover how your automated campaigns are performing."
              ),
            },
          })
        : _vm.isListEmpty && !_vm.isLoading
        ? _c("empty-screen", {
            attrs: {
              icon: "stat",
              title: _vm.tr(
                "Your analytics dashboard is ready and waiting for data"
              ),
              "flag-text": _vm.tr("Analytics pending"),
              description: _vm.tr(
                "Your analytics dashboard will show performance metrics once your automated ads start running. Check back here after your campaigns have gathered enough data to display meaningful insights."
              ),
            },
          })
        : _c("sorted-table", {
            staticClass: "row-hover-shadow",
            attrs: {
              data: _vm.list,
              "initial-sort": { key: _vm.orderBy, direction: _vm.order },
            },
            on: { sort: _vm.sortTable },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c("table-head", {
                      attrs: { col: "status", width: "20px" },
                    }),
                    _c("table-head", {
                      attrs: {
                        col: "name",
                        sortable: "",
                        "min-width": "400px",
                      },
                    }),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "metric.reach",
                          sortable: "",
                          width: "70px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.tr("Reach")) + " ")]
                    ),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "metric.actions." + _vm.action,
                          sortable: "",
                          width: "70px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.tr("Actions")) + " ")]
                    ),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "metric.cost_per_action_type." + _vm.action,
                          sortable: "",
                          width: "70px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.tr("CPA")) + " ")]
                    ),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "metric.spend",
                          sortable: "",
                          width: "70px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.tr("Spend")) + " ")]
                    ),
                    _c(
                      "table-head",
                      {
                        attrs: {
                          col: "created_at",
                          sortable: "",
                          width: "150px",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.tr("Date created")) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (row) {
                    return _c(
                      "table-row",
                      { key: "report-ad-table--ad-" + row.external_id },
                      [
                        _c("table-data", { attrs: { col: "status" } }, [
                          _c("div", {
                            class: {
                              status: true,
                              green: row.effective_status === 1,
                              red: row.effective_status !== 1,
                            },
                          }),
                        ]),
                        _c("table-data", { attrs: { col: "name" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "ad-name",
                              on: { click: () => _vm.toggle(row.external_id) },
                            },
                            [_vm._v(" " + _vm._s(row.name) + " ")]
                          ),
                        ]),
                        _c("table-data", { attrs: { col: "metric.reach" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.metric
                                  ? _vm.formatNumber(row.metric.reach)
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "table-data",
                          { attrs: { col: "metric.actions." + _vm.action } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.metric && row.metric.actions
                                    ? _vm.formatNumber(
                                        row.metric.actions[_vm.action]
                                      )
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "table-data",
                          {
                            attrs: {
                              col: "metric.cost_per_action_type." + _vm.action,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatNumber(
                                    _vm.calculateCpa(row),
                                    _vm.currency,
                                    3
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c("table-data", { attrs: { col: "'metric.spend" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                row.metric
                                  ? _vm.formatNumber(
                                      row.metric.spend,
                                      _vm.currency
                                    )
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                        _c("table-data", { attrs: { col: "created_at" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.formatToYMD(row.created_at)) +
                              " " +
                              _vm._s(_vm.formatTime(row.created_at)) +
                              " "
                          ),
                        ]),
                        _c("transition-expand", [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.openedAd === row.external_id,
                                  expression: "openedAd === row.external_id",
                                },
                              ],
                              key:
                                "report-ad-table--ad-" +
                                row.external_id +
                                "--details",
                              staticClass: "details-row",
                            },
                            [
                              _vm.renderedAdDetails.includes(row.external_id)
                                ? _c("report-ad-details", {
                                    attrs: {
                                      "token-part1": _vm.tokenPart1,
                                      "token-part2": _vm.tokenPart2,
                                      "ad-account-id": _vm.adAccountId,
                                      ad: row,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }