import OnboardingAd from '@/components/OnboardingAd.vue';
import OnboardingBuzz from '@/components/OnboardingBuzz.vue';

export default {
  data: () => ({
    isLoading: true,
  }),
  computed: {
    onboardingNeccessary() {
      if (this.isLoading || !this.$root.isInOriginalCompany) {
        return false;
      }

      return !this.dashboardData.profile_connected
        && !this.dashboardData.source_profile_connected;
    },
  },
  watch: {
    onboardingNeccessary: {
      immediate: true,
      handler(val) {
        if (val) {
          let onboardingComponent = null;
          if (this.$app.isAd) {
            onboardingComponent = OnboardingAd;
          }
          else if (this.$app.isBuzz) {
            onboardingComponent = OnboardingBuzz;
          }
          else {
            return;
          }

          this.$modal.show(
            onboardingComponent,
            null,
            {
              name: 'onboarding-modal',
              class: 'onboarding-modal',
              clickToClose: false,
            }
          );
        }
      },
    },
  },
};
