import AuthService from '@/services/Auth';
import User from '@/entities/User';

const state = {
  list: [],
};
const getters = {
  list(state) {
    return state.list;
  },
};
const actions = {
  fetch(store) {
    if (state.list.length == 0) {
      return AuthService.fetchInvitations().then((resp) => {
        store.commit('setList', resp);
        return resp;
      });
    }
  },
  createInvitation(store, data) {
    let promise;
    promise = AuthService.createInvitation(data);

    return promise.then((resp) => {
      store.commit('addToList', resp);

      return resp;
    });
  },
  deleteInvitation(store, entity) {
    return AuthService.deleteInvitation(entity.id).then(
      store.commit('deleteFromList', entity)
    );
  },
  resendInvitation(store, entity) {
    return AuthService.resendInvitation(entity.id);
  },
};
const mutations = {
  setList(state, list) {
    state.list = [];

    for (let item of list) {
      if (item instanceof User) {
        state.list.push(item);
      } else {
        state.list.push(new User(item));
      }
    }
  },
  addToList(state, entity) {
    state.list.push(entity);
  },
  deleteFromList(state, entity) {
    for (var i = 0; i < state.list.length; i++) {
      if (state.list[i].id === entity.id) {
        state.list.splice(i, 1);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
