var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gender-selector" }, [
    _c("div", { staticClass: "checkbox-list horizontal" }, [
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.insideValue,
              expression: "insideValue",
            },
          ],
          attrs: { type: "radio", name: "gender", value: "all" },
          domProps: { checked: _vm._q(_vm.insideValue, "all") },
          on: {
            change: function ($event) {
              _vm.insideValue = "all"
            },
          },
        }),
        _vm._v(" " + _vm._s(_vm.tr("All")) + " "),
      ]),
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.insideValue,
              expression: "insideValue",
            },
          ],
          attrs: { type: "radio", name: "gender", value: "men" },
          domProps: { checked: _vm._q(_vm.insideValue, "men") },
          on: {
            change: function ($event) {
              _vm.insideValue = "men"
            },
          },
        }),
        _vm._v(" " + _vm._s(_vm.tr("Men")) + " "),
      ]),
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.insideValue,
              expression: "insideValue",
            },
          ],
          attrs: { type: "radio", name: "gender", value: "women" },
          domProps: { checked: _vm._q(_vm.insideValue, "women") },
          on: {
            change: function ($event) {
              _vm.insideValue = "women"
            },
          },
        }),
        _vm._v(" " + _vm._s(_vm.tr("Women")) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }