<template>
  <div class="create-new-rule-cards-component">
    <create-new-rule-card
      v-for="(card, index) in filteredCards"
      :key="'create-new-rule-card--' + index"
      v-bind="card"
      ref="cards"
      :disabled="disabled"
      @click.native="() => onClickCard()"
    />
  </div>
</template>

<script>
import CreateNewRuleCard from '@/components/CreateNewRuleCard.vue';
import OnboardingAd from '@/components/OnboardingAd.vue';

export default {
  name: 'CreateNewRuleCards',
  components: {
    CreateNewRuleCard,
  },
  props: {
    showCards: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    allTypesOfAssetsConnected() {
      return this.$store.getters['dashboard/allTypesOfAssetsConnected'];
    },
    disabled() {
      return this.$store.getters['dashboard/isLoading'];
    },
    cards() {
      const cards = [
        {
          color: 'tools-gradient',
          icon: 'magic-stars',
          title: this.tr('Ai Ad Creation'),
          description: this.tr('Streamlined automation creation, powered by AI to minimize manual work.'),
          path: '/automation/new/ai-mode',
        },
        {
          color: 'primary',
          icon: 'rocket',
          title: this.tr('Ad Creation'),
          description: this.tr('Continuously create ads from your posts, based on your settings, reducing manual work.'),
          path: '/automation/new/start',
        },
        {
          color: 'pink',
          icon: 'scaling-up',
          title: this.tr('Ad Scaling'),
          description: this.tr('Scale your ads to maximize results, following your custom rules and settings, ensuring optimal reach.'),
          path: '/automation/new/scaling_up',
        },
        {
          color: 'orange',
          icon: 'blister',
          title: this.tr('Ad Stop'),
          description: this.tr('Automatically stop ads based on your conditions, optimizing ad spend and campaign performance.'),
          path: '/automation/new/stop',
        },
      ];

      if (!this.allTypesOfAssetsConnected) {
        cards.forEach(card => card.path = null);
      }

      return cards;
    },
    filteredCards() {
      if (!this.showCards.length) {
        return this.cards;
      }
      return this.cards.filter((_, index) => this.showCards.includes(index));
    },
  },
  methods: {
    onClickCard() {
      if (!this.allTypesOfAssetsConnected) {
        this.$fullscreenModal.open(
          this,
          OnboardingAd,
          {},
          { closable: false },
        );
      }
      else {
        this.$emit('click');
      }
    },
  },
};
</script>
