<template>
  <div ref="wrapper" :class="wrapperClasses" :style="wrapperStyle">
    <slot />
  </div>
</template>

<script>
let lastScrollPosition = 0;
let currentScrollPosition = 0;

export default {
  name: 'StickyContainer',
  props: {
    bottomPadding: {
      type: Number,
      default: 20,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isMounted: false,
    mutationObserver: null,
    windowHeight: 0,
    elementHeight: 0,
    initTop: 0,
    topPosition: 0,
  }),
  computed: {
    fullIsVisible() {
      if (!this.isMounted || !this.$refs.wrapper) {
        return true;
      }

      return this.topPosition >= this.initTop
        && this.elementHeight + this.topPosition + this.bottomPadding <= this.windowHeight;
    },
    computedActive() {
      if (!this.isMounted) {
        return false;
      }

      return this.active && !this.fullIsVisible;
    },
    wrapperClasses() {
      return {
        'sticky-container-component': true,
        'sticky-container-component--active': this.active,
      };
    },
    wrapperStyle() {
      if (!this.active) {
        return {};
      }

      return { 'top': this.topPosition + 'px' };
    },
  },
  watch: {
    computedActive(value) {
      if (value) {
        window.addEventListener('scroll', this.onScroll, { passive: true });
      }
      else {
        window.removeEventListener('scroll', this.onScroll);
      }
    },
  },
  created() {
    this.onResizeWindow();
    window.addEventListener('resize', this.onResizeWindow);
  },
  mounted() {
    this.$nextTick(() => {
      this.initMutationObserver();

      const elementParams = this.$refs.wrapper.getBoundingClientRect();
      this.elementHeight = this.$refs.wrapper.offsetHeight;
      this.initTop = elementParams.top;
      this.topPosition = this.initTop;

      this.isMounted = true;
    });
  },
  beforeDestroy() {
    this.destroyMutationObserver();
    window.removeEventListener('resize', this.onResizeWindow);
  },
  methods: {
    initMutationObserver() {
      this.mutationObserver = new MutationObserver(() => this.elementHeight = this.$refs.wrapper.offsetHeight);
      this.mutationObserver.observe(this.$refs.wrapper, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    },
    destroyMutationObserver() {
      if (this.mutationObserver !== null) {
        this.mutationObserver.disconnect();
        this.mutationObserver = null;
      }
    },
    onResizeWindow() {
      const diff = window.innerHeight - this.windowHeight;
      const currentSizeIsLarger = diff > 0;
      if (currentSizeIsLarger && this.topPosition < this.initTop) {
        let newTopPosition = this.topPosition + diff;
        if (newTopPosition > this.initTop) {
          newTopPosition = this.initTop;
        }

        this.topPosition = newTopPosition;
      }

      this.windowHeight = window.innerHeight;
    },
    onScroll() {
      if (!this.$refs.wrapper) {
        return;
      }

      lastScrollPosition = currentScrollPosition;
      currentScrollPosition = window.scrollY;

      let newTopPosition = this.topPosition;

      const scrollSize = currentScrollPosition - lastScrollPosition;
      // Scroll down.
      if (scrollSize > 0) {
        if (!this.fullIsVisible) {
          const bottomLimit = (this.windowHeight - this.elementHeight - this.bottomPadding);

          if (newTopPosition > bottomLimit) {
            newTopPosition -= scrollSize;
            if (newTopPosition < bottomLimit) {
              newTopPosition = bottomLimit;
            }
          }
        }
      }
      // Scroll up.
      else {
        if (newTopPosition < this.initTop) {
          newTopPosition -= scrollSize;

          if (newTopPosition > this.initTop) {
            newTopPosition = this.initTop;
          }
        }
      }

      this.topPosition = newTopPosition;
    },
  },
};
</script>
