<template>
  <div :class="wrapperClasses">
    <system-level-notifications v-if="!minimal" space-top />

    <div v-if="!minimal && list.length !== 0" class="page-textual">
      {{ text }}
    </div>

    <div v-if="list.length > 10 && !minimal" class="actions">
      <input
        v-model="searchTerm"
        type="text"
        class="text"
        :placeholder="tr('Search') + '...'"
      />
    </div>

    <template v-if="list.length === 0">
      <notification-card
        v-if="!hasConnectedProfile"
        :image="require('@/assets/img/onboarding/step-profile.png')"
        empty-screen
      >
        <template v-if="platform === 'facebook'">
          {{ tr('If you want to use an Facebook Page, first you should connect you Meta Profile here') }}
        </template>
        <template v-else-if="platform === 'instagram'">
          {{ tr('If you want to use an Instagram Account, first you should connect you Meta Profile here') }}
        </template>

        <button
          v-if="connectButtonVisible"
          class="button primary"
          @click="() => $emit('connect')"
        >
          + {{ tr('Connect Your Profile') }}
        </button>
      </notification-card>
      <notification-card v-else-if="platform === 'facebook'" empty-screen>
        {{ tr('There are no available Facebook pages') }}
      </notification-card>
      <notification-card v-else-if="platform === 'instagram'" empty-screen>
        {{ tr('There are no available Instagram accounts') }}
      </notification-card>
    </template>
    <notification-card v-else-if="filteredList.length === 0" class="no-result" empty-screen>
      {{ tr('There are no results matching your search criteria.') }}
    </notification-card>
    <new-sorted-table
      v-else
      :data="filteredList"
      :initial-sort="{ key: 'name', direction: 'asc' }"
    >
      <template #header>
        <table-head col="name" min-width="200px" sortable>{{ tr('Name') }}</table-head>
        <table-head col="status" width="160px" class="status-cell">{{ tr('Status') }}</table-head>
        <table-head col="id" v-if="!minimal" width="180px" class="external-id-cell" sortable>{{ idLabel }}</table-head>
        <table-head col="connected_by" v-if="!minimal" width="180px">{{ tr('Connected by') }}</table-head>
        <table-head col="actions" v-if="!minimal && $app.isAd" min-width="120px" max-width="200px" />
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.id">
          <table-data col="name">
            <label>
              <i
                v-if="isLoading && loadingId === row.id"
                class="icon loader"
              />
              <input
                v-else
                type="checkbox"
                :value="row.id"
                :checked="isConnected(row)"
                :disabled="isDisabled(row)"
                @change="() => checkboxChanged(row)"
              />
              <div class="name-wrapper">
                <i v-if="minimal" :class="'icon ' + platform" />
                <div class="name">
                  <span>{{ row.name }}</span>
                </div>
              </div>
            </label>
          </table-data>
          <table-data col="status" class="status-cell">
            <span :class="statusClasses(row)">{{ isConnected(row) ? tr('Active') : tr('Not connected') }}</span>
          </table-data>
          <table-data v-if="!minimal" col="id" class="external-id-cell">
            {{ row.id }}
          </table-data>
          <table-data v-if="!minimal" col="connected_by">
            {{ row.owner ? row.owner.fullname : '-' }}
          </table-data>
          <table-data v-if="!minimal && $app.isAd" col="actions">
            <div v-if="isConnected(row)" class="action-group">
              <button class="gray small" @click="() => openSourceProfileSettings(row)">
                {{ tr('Fine tuning') }}
              </button>
              <button
                v-if="$root.supportHasL2Access"
                class="gray small"
                @click="() => openCommunicatorDebug(row)"
              >
                {{ tr('Debug') }}
              </button>
            </div>
          </table-data>
        </table-row>
      </template>
    </new-sorted-table>

    <disconnect-confirm-modal ref="disconnectConfirmModal" />
  </div>
</template>

<script>
import DisconnectConfirmModal from '@/components/DisconnectConfirmModal.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import SourceProfileModerationSettings from '@/components/SourceProfileModerationSettings.vue';
import SourceProfileSettings from '@/components/SourceProfileSettings.vue';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';
import CommunicatorDebug from '@/components/CommunicatorDebug.vue';

import User from '@/entities/User';
import SourceProfileService from '@/services/SourceProfile';
import ToolService from '@/services/Tool.js';

import { formatPrice } from '@/utils/string.js';
import { getUserCountry } from '@/utils/date';
import { PaymentRequiredError } from '@/errors';

export default {
  name: 'ConnectedPlatformsMetaFacebookItemsView',
  components: {
    DisconnectConfirmModal,
    NotificationCard,
    SystemLevelNotifications,
  },
  props: {
    platformFromProp: {
      type: String,
      required: false,
      default: null,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchTerm: '',
    loadingId: null,
    editing: null,
  }),
  computed: {
    wrapperClasses() {
      return {
        'connected-platforms-meta-facebook-items-view': true,
        'minimal': this.minimal,
      };
    },
    connectButtonVisible() {
      return this.$root.isInOriginalCompany;
    },
    hasConnectedProfile() {
      let profileItems = this.$root.loggedInUser.connected_profiles.filter(
        (profile) => profile.platform === 'facebook'
      );

      return profileItems.length !== 0;
    },
    platform() {
      if (this.platformFromProp) {
        return this.platformFromProp;
      }

      switch (this.$route.params.itemType) {
        case 'instagram-accounts':
          return 'instagram';

        case 'facebook-pages':
        default:
          return 'facebook';
      }
    },
    profileDetailsKey() {
      switch (this.platform) {
        case 'instagram':
          return 'instagram_accounts';

        case 'facebook':
        default:
          return 'pages';
      }
    },
    text() {
      if (this.$app.isAd) {
        switch (this.platform) {
          case 'instagram':
            return this.tr('Connect Instagram Accounts you want Infinite∞Ad to work with.');

          case 'facebook':
            return this.tr('Connect Facebook Pages you want Infinite∞Ad to work with.');
        }
      }
      if (this.$app.isBuzz) {
        switch (this.platform) {
          case 'instagram':
            return this.tr('Connect Instagram Accounts you want Infinite∞Buzz to work with.');

          case 'facebook':
            return this.tr('Connect Facebook Pages you want Infinite∞Buzz to work with.');
        }
      }

      return '';
    },
    idLabel() {
      switch (this.platform) {
        case 'instagram':
          return this.tr('Account ID');

        case 'facebook':
        default:
          return this.tr('Page ID');
      }
    },
    isLoading() {
      return this.loadingId !== null;
    },
    list() {
      let profileItems = this.$root.loggedInUser.connected_profiles.filter(
        (profile) => profile.platform === 'facebook'
      );

      let listItems = [];
      let currentListItemIds = [];

      profileItems.forEach((profile) => {
        if (profile.details[this.profileDetailsKey]) {
          profile.details[this.profileDetailsKey].forEach(item => {
            if (!currentListItemIds.includes(item.id)) {
              currentListItemIds.push(item.id);
              listItems.push(item);
            }
          });

          listItems.map(item => {
            if (this.isConnected(item) && this.sourceProfileIsMine(item)) {
              item.owner = new User({
                id: this.$root.loggedInUser.id,
                firstname: this.$root.loggedInUser.firstname,
                lastname: this.$root.loggedInUser.lastname,
              });
            }
            else {
              const sourceProfile = this.getSourceProfile(item.id);
              if (sourceProfile) {
                item.owner = sourceProfile.owner;
              }
              else {
                item.owner = null;
              }
            }
          });
        }
      });
      const sourceProfiles = this.$root.currentCompany.source_profiles
        .filter(i => i.platform === this.platform)
        .map(i => {
          i = JSON.parse(JSON.stringify(i));
          i.id = i.external_id;
          delete i.external_id;
          i.owner = new User(i.owner);

          return i;
        });

      sourceProfiles.forEach(i => {
        if (!currentListItemIds.includes(i.id)) {
          listItems.push(i);
        }
      });

      return listItems;
    },
    filteredList() {
      let term = this.searchTerm.trim().toLowerCase();
      if (term.length === 0) {
        return this.list;
      }

      return this.list.filter(i => i.name.toLowerCase().includes(term));
    },
    selectedItems() {
      return this.$root.currentCompany.source_profiles
        .filter(i => i.platform === this.platform)
        .filter(i => i.deleted_at === null)
        .map(i => i.external_id);
    },
  },
  methods: {
    formatPrice,
    statusClasses(item) {
      return {
        'status': true,
        'active': this.selectedItems.includes(item.id),
        'not-connected': !this.selectedItems.includes(item.id),
      };
    },
    checkboxChanged(page) {
      this.loadingId = page.id;
      const company = this.$root.currentCompany;

      if (!this.isConnected(page)) {
        this.$emit('loading', true);
        const connectProcessCallback = (chargeExtraAsset) => SourceProfileService.connect(this.platform, page.id, this.getUserCountryCode(), chargeExtraAsset)
          .then(resp => {
            company.source_profiles.push(resp);
            this.openSourceProfileSettings(resp);
          })
          .catch((err) => {
            if (err instanceof PaymentRequiredError) {
              this.$confirmation.open({
                title: this.tr('Connecting new extra asset'),
                content: this.tr(
                  'You are attempting to connect a new asset, but your current subscription allows a maximum of :maxAssets assets.',
                  { ':maxAssets': company.max_assets }
                ) + '<br/>' +

                this.tr(
                  'The cost to use an additional asset is :price.',
                  { ':price': this.formatPrice(company.current_active_package.extra_asset_price, company.current_active_package.period) }
                ) + '<br/><br/>' +

                this.tr(
                  'We will attempt to automatically deduct the prorated amount of :currentPrice for this billing period from your registered credit card.',
                  { ':currentPrice': this.formatPrice(err.getPrice()) }
                ),
                labelCancel: this.tr('Cancel'),
                labelConfirm: this.tr('Confirm'),
                cancel: () => { this.loadingId = null },
                confirm: () => {
                  return connectProcessCallback(true)
                    .then(() => {
                      if (company.max_assets !== null && company.max_assets === company.usedAssetsCount - 1) {
                        ++company.max_assets;
                        ++company.current_extra_assets;
                        ++company.paid_extra_assets;
                      }
                    });
                }
              });
            }
            else {
              this.error(err);
            }
          })
          .finally(
            () => {
              this.loadingId = null;
              this.$emit('loading', false);
            }
          );

        connectProcessCallback(false);
      }
      else {
        const sourceProfile = this.getSourceProfile(page.id);
        if (!sourceProfile) {
          this.loadingId = null;
          return;
        }

        if (!this.$app.isAd) {
          return this.disconnect(sourceProfile)
            .catch((err) => this.error(err))
            .finally(() => this.loadingId = null);
        }

        ToolService.disconnectPrecheck({ sourceProfileId: sourceProfile.id })
          .then(resp => {
            if (resp.defunc_rules.length !== 0) {
              this.$refs.disconnectConfirmModal.open(
                { sourceProfile },
                resp.defunc_rules,
                () => this.disconnect(sourceProfile)
              );
            }
            else {
              return this.disconnect(sourceProfile);
            }
          })
          .catch((err) => this.error(err))
          .finally(() => this.loadingId = null);
      }
    },
    disconnect(sourceProfile) {
      this.$emit('loading', true);
      const company = this.$root.currentCompany;

      return SourceProfileService.disconnect(sourceProfile.id)
        .then(() => {
          const index = company.source_profiles.findIndex(
            (obj) => obj.id === sourceProfile.id
          );

          if (index !== -1) {
            company.source_profiles.splice(index, 1);
          }

          this.$toast.success('Data source was successfully disconnected.');
        }).finally(() => {
          this.$emit('loading', false);
        });
    },
    isConnected(page) {
      return this.selectedItems.includes(page.id);
    },
    isDisabled(page) {
      if (this.isLoading) {
        return true;
      }

      if (this.isConnected(page) && !this.sourceProfileIsMine(page)) {
        return true;
      }

      return false;
    },
    sourceProfileIsMine(page) {
      const sourceProfile = this.getSourceProfile(page.id);
      if (!sourceProfile) {
        return false;
      }

      return sourceProfile.owner.id === this.$root.loggedInUser.id;
    },
    getSourceProfile(externalId) {
      return this.$root.currentCompany.source_profiles
        .find(i => i.platform === this.platform && i.external_id === externalId);
    },
    openSourceProfileSettings(sourceProfile) {
      if ('external_id' in sourceProfile) {
        this.editing = sourceProfile;
      }
      else {
        this.editing = this.getSourceProfile(sourceProfile.id);
      }

      if (!this.minimal) {
        const modalMaxHeight = this.$app.isBuzz ? 640 : 540;
        this.$modal.show(
          this.$app.isBuzz ? SourceProfileModerationSettings : SourceProfileSettings,
          { entity: this.editing },
          {  class: 'modal-without-padding modal-without-scroll modal-height--' + modalMaxHeight },
          { closed: () => this.editing = null }
        );
      }
    },
    openCommunicatorDebug(sourceProfile) {
      const sourceProfileEntity = this.getSourceProfile(sourceProfile.id);

      this.$modal.show(
        CommunicatorDebug,
        { entity: sourceProfileEntity },
        { class: 'modal-without-padding modal-without-scroll modal-height--640 modal-width--960' }
      );
    },
    getUserCountryCode() {
      const userCountry = getUserCountry();
      if (userCountry) {
        const userCountryItem = this.$store.getters['app/countries'].find(c => c.name === userCountry);
        if (userCountryItem) {
          return userCountryItem.iso_a2;
        }
      }

      return null;
    }
  },
};
</script>
