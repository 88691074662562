import BaseEntity from '@/entities/BaseEntity';
import Adset from '@/entities/Adset';
import AdMetric from '@/entities/AdMetric';
import Campaign from '@/entities/Campaign';
import Post from '@/entities/Post';

export default class Ad extends BaseEntity
{
  _entityFields = {
    campaign: Campaign,
    adset: Adset,
    post: Post,
    metric: AdMetric,
  };

  _entityArrayFields = {
    metrics: AdMetric,
  };

  id = '';
  platform = '';
  external_id = '';
  name = '';
  campaign = null;
  adset = null;
  post = null;
  status = null;
  effective_status = null;
  metric = null;
  metrics = [];
  created_at = null;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }
}
