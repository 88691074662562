<template>
  <div class="age-selector">
    <v-select
      v-model="insideValue"
      :reduce="i => i.value"
      :options="ageOptions"
      :searchable="false"
      :clearable="false"
    />
  </div>
</template>

<script>
export default {
  name: 'AgeSelector',
  props: {
    value: {
      required: false,
    },
  },
  data:() => ({
    insideValue: '',
  }),
  watch: {
    insideValue(val, oldVal) {
      if (!oldVal) {
        return;
      }
      this.$emit('input', this.insideValue);
    },
    value: {
      immediate: true,
      handler() {
        this.insideValue = this.value;
      }
    },
  },
  computed: {
    ageOptions() {
      let ages = [];

      for (let i = 18; i <= 65; i++) {
        let label = i === 65 ? '65+' : i;

        ages.push({
          label,
          value: i
        })
      }

      return ages;
    }
  },
};
</script>
