<template>
  <div class="meta-ap-creative-features-component">
    <label class="input-label">{{ tr('Advantage+ Creative Features') }}</label>
    <div class="checkbox-list vertical">
      <label
        v-for="(item, key) in apCreativeFeatures"
        :key="'ap-creative-feature--' + key"
        v-tooltip.right="tr(item.info)"
      >
        <input
          v-model="value[item.machine_name]"
          type="checkbox"
          :disabled="disabled"
        />

        {{ tr(item.label) }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MetaApCreativeFeatures',
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    apCreativeFeatures() {
      return this.$store.getters['app/facebookApCreativeFeatures'];
    },
  },
};
</script>
