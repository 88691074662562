<template>
  <div class="rule-form-step rule-form-step--start rule-form-step--start--step-4">
    <cta-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <url-params-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>
<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import CtaBlock from '@/components/RuleForm/Blocks/CtaBlock.vue';
import UrlParamsBlock from '@/components/RuleForm/Blocks/UrlParamsBlock.vue';

export default {
  name: 'StartRuleFormStep4',
  mixins: [ RuleFormStepMixin ],
  components: {
    CtaBlock,
    UrlParamsBlock,
  },
};
</script>
