<template>
  <label :class="[colorClass, wrapperClasses]">
    <input
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="$emit('input', $event)"
    />
    <i v-if="icon" :class="'icon ' + icon + ' ' + colorClass" />
    {{ label }}
  </label>
</template>

<script>
export default {
  name: 'CheckboxInput',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    icon: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    color: {
      type: String,
      required: false,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    colorClass() {
      return this.color ? this.color : '';
    },
    wrapperClasses() {
      return {
        'checkbox-input-component': true,
        disabled: this.disabled,
        checked: this.value,
      };
    },
  },
};
</script>
