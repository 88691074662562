var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "language-selector-component" },
    [
      _c(
        "dropdown-menu",
        {
          attrs: { interactive: true, transition: "slide-bottom-to-up" },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c("div", { staticClass: "main" }, [
            _c("i", { staticClass: "icon globe" }),
            _c("label", [_vm._v(" " + _vm._s(_vm.currentLanguageLabel) + " ")]),
            _c("i", { staticClass: "icon arrow-right dark-gray" }),
          ]),
          _c(
            "div",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.availableLanguages, function (label, langcode) {
              return _c(
                "div",
                {
                  key: "language-selector-option-" + langcode,
                  on: { click: () => _vm.select(langcode) },
                },
                [_vm._v(" " + _vm._s(label) + " ")]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }