var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkAccessByPath(_vm.to)
    ? _c("router-link", { attrs: { to: _vm.to } }, [_vm._t("default")], 2)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }