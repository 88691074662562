<template>
  <div class="improved-copy-component">
    <h3 v-if="showTitle">{{ tr('Improved copy') }}</h3>

    <div class="text" v-html="entity.improved_text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />

    <ai-score :value="entity.value" :label="tr('AI Score') + ':'" />

    <div
      v-for="(row, key) in entity.details"
      :key="'improved-copy-meta-' + key"
      class="aspect"
    >
      {{ tr(row.title) }}: {{ row.value }} / 10
    </div>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import ImprovedCopy from '@/entities/ImprovedCopy.js';

export default {
  name: 'ImprovedCopy',
  components: {
    AiScore,
  },
  props: {
    entity: {
      type: ImprovedCopy,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
