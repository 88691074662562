var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.wrapperClasses,
      on: { click: ($event) => _vm.floatingSidebarAutoCloseHandler($event) },
    },
    [
      _vm.showOpenAppInBrowserScreen
        ? _c("open-in-browser", {
            attrs: { "is-ios": _vm.isIos },
            on: { open: () => _vm.openInBrowser() },
          })
        : [
            _vm.$root.isSupportMode
              ? _c(
                  "div",
                  { staticClass: "support-mode-bar" },
                  [
                    !_vm.$root.loggedInUser
                      ? [_vm._v(" " + _vm._s(_vm.tr("Support mode")) + " ")]
                      : [
                          _vm._v(" " + _vm._s(_vm.tr("Company")) + ": "),
                          _vm.$root.currentCompany.name
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$root.currentCompany.name) +
                                    " "
                                ),
                              ]
                            : _c("i", [
                                _vm._v(_vm._s(_vm.tr("unnamed company"))),
                              ]),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _vm.isSidebarVisible
              ? _c("sidebar", {
                  ref: "sidebar",
                  attrs: { floating: _vm.floatingSidebar },
                  nativeOn: {
                    click: function ($event) {
                      return (($event) =>
                        _vm.floatingSidebarAutoCloseHandler($event)).apply(
                        null,
                        arguments
                      )
                    },
                  },
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "router-frame" },
              [
                _vm.isLoading
                  ? _c("div", { staticClass: "loader" }, [_c("i")])
                  : _c("router-view"),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }