export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPrice(price, period) {
  if (!price) {
    return this.tr('Free');
  }

  price = parseFloat(parseFloat(price).toFixed(2));

  let result = '€' + price;

  if (period === 'monthly') {
    result += ' / ' + this.tr('month');
  }
  if (period === 'annual') {
    result += ' / ' + this.tr('year');
  }

  return result;
}

export function formatPcs(value) {
  if (value === null) {
    return this.tr('Unlimited') + ' ∞';
  }

  return value + ' ' + this.tr('pcs');
}
