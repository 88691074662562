<template>
  <div
    ref="headerCell"
    :class="['header-cell', { sortable }]"
    :style="columnStyle"
  >
    <span @click="handleClick">
      <slot />
      <i
        v-if="sortable && isSorted"
        class="icon up-arrow"
        :style="{ transform: sortDirection === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)' }"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableHead',
  inject: ['sortedTable'],
  props: {
    col: {
      type: String,
      required: true
    },
    sortable: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: null
    },
    maxWidth: {
      type: String,
      default: null
    },
    width: {
      type: String,
      default: null
    },
  },
  data: () => ({
    calculatedWidth: 'auto',
  }),
  computed: {
    columnStyle() {
      if (this.width) {
        return {
          width: this.width,
          minWidth: this.width,
          maxWidth: this.width,
        };
      }

      if (this.calculatedWidth === 'auto') {
        return {
          minWidth: this.minWidth,
          maxWidth: this.maxWidth,
        };
      }

      let width = this.width ?? this.calculatedWidth;
      if (this.minWidth && width < this.minWidth) {
        width = this.minWidth;
      }
      else if (this.maxWidth && width > this.maxWidth) {
        width = this.maxWidth;
      }

      return {
        minWidth: width,
        maxWidth: width,
        width,
      };
    },
    isSorted() {
      return this.sortedTable.currentSort.key === this.col;
    },
    sortDirection() {
      return this.sortedTable.currentSort.direction;
    },
  },
  methods: {
    setWidth(value) {
      this.calculatedWidth = value;
    },
    handleClick() {
      if (this.sortable) {
        this.sortedTable.handleSort(this.col);
      }
    },
  },
  created() {
    this.sortedTable.registerColumn(this);
  },
  beforeDestroy() {
    this.sortedTable.unregisterColumn(this);
  },
};
</script>
