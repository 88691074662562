var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "enable-google-2fa-component" },
    [
      _vm.step === 1
        ? _c(
            "div",
            [
              _c(
                "form-block",
                {
                  staticClass: "step step--1",
                  attrs: {
                    title: _vm.tr("Set up Google Authenticator"),
                    description:
                      _vm.description +
                      _vm.tr(
                        "Set up your two factor authentication by scanning the barcode below with the Google Authenticator app. Alternatively, you can use the code :code",
                        { ":code": "<strong>" + _vm.secret + "</strong>" }
                      ),
                  },
                },
                [
                  _vm.qrCodeUrl
                    ? _c("div", {
                        staticClass: "qr-code",
                        domProps: { innerHTML: _vm._s(_vm.qrCodeUrl) },
                      })
                    : _c(
                        "div",
                        { staticClass: "qr-code-loading-placeholder" },
                        [_c("i", { staticClass: "icon loader primary" })]
                      ),
                ]
              ),
              _c("div", { staticClass: "bottom-button" }, [
                _c(
                  "button",
                  {
                    staticClass: "bordered gray",
                    on: { click: () => _vm.prev() },
                  },
                  [
                    _c("i", { staticClass: "icon arrow-left" }),
                    _vm._v(" " + _vm._s(_vm.tr("Back")) + " "),
                  ]
                ),
                _c(
                  "button",
                  { staticClass: "primary", on: { click: () => _vm.next() } },
                  [_vm._v(" " + _vm._s(_vm.tr("Next")) + " ")]
                ),
              ]),
            ],
            1
          )
        : _vm.step === 2
        ? _c("google-2fa-code-form", {
            attrs: {
              title: _vm.tr("Set up Google Authenticator"),
              "cancel-button-label": _vm.tr("Previous"),
              "is-loading": _vm.isSaving,
            },
            on: { submit: () => _vm.confirm(), cancel: () => _vm.prev() },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }