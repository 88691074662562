var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "adset-budget-block",
    { ref: "mainBlock", attrs: { entity: _vm.entity, disabled: _vm.disabled } },
    [
      _c("label", { staticClass: "input-label" }, [
        _vm._v(" " + _vm._s(_vm.tr("Optimization goal")) + " "),
      ]),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          _vm._s(
            _vm.tr(
              "It refers to the specific objective or outcome that you aim to achieve through your advertising campaign."
            )
          )
        ),
      ]),
      _c("v-select", {
        attrs: {
          options: _vm.facebookAdsetOptimizationGoals,
          reduce: (obj) => obj.machine_name,
          searchable: false,
          clearable: false,
          placeholder: _vm.tr("Set an optimization goal"),
          disabled: !_vm.entity.isNew || _vm.disabled,
          label: "label",
        },
        model: {
          value: _vm.entity.settings.adset_optimization_goal,
          callback: function ($$v) {
            _vm.$set(_vm.entity.settings, "adset_optimization_goal", $$v)
          },
          expression: "entity.settings.adset_optimization_goal",
        },
      }),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.tr(
              "You will not be able to change this setting of this automation after the campaign has started."
            )
          )
        ),
      ]),
      !_vm.campaignHasBidStrategy
        ? _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Bid strategy"))),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.facebookAdsetBidStrategies,
                  reduce: (obj) => obj.machine_name,
                  searchable: false,
                  clearable: false,
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.entity.settings.adset_bid_strategy,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity.settings, "adset_bid_strategy", $$v)
                  },
                  expression: "entity.settings.adset_bid_strategy",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.entity.bidAmountIsAvailable
        ? _c(
            "div",
            [
              _c(
                "label",
                { staticClass: "input-label" },
                [
                  _vm._v(" " + _vm._s(_vm.bidAmountLabel) + " "),
                  !_vm.entity.bidAmountIsRequired
                    ? [_vm._v(" (" + _vm._s(_vm.tr("optional")) + ") ")]
                    : _vm._e(),
                ],
                2
              ),
              !_vm.entity.isBidCapStrategy
                ? _c("div", { staticClass: "description" }, [
                    _vm._v(
                      _vm._s(
                        _vm.tr(
                          "A type of advertising metric that is used to measure the effectiveness and efficiency of an advertising campaign. It represents the average cost that you pay for a specific result, such as a click, an impression, a lead, or a sale."
                        )
                      )
                    ),
                  ])
                : _vm._e(),
              _c("money-input", {
                attrs: {
                  currency: _vm.entity.adAccountCurrency.code,
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.entity.settings.adset_bid_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.entity.settings, "adset_bid_amount", $$v)
                  },
                  expression: "entity.settings.adset_bid_amount",
                },
              }),
              !_vm.entity.bidAmountIsRequired
                ? _c("info-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tr(
                            "Meta will aim to spend your entire budget and get the most link clicks using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal."
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }