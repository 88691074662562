var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "change-subscription-form-component" },
    [
      _c(
        "form-block",
        {
          staticClass: "change-subscription-form",
          attrs: {
            title: _vm.tr("Payment"),
            description: _vm.blockDescription,
          },
        },
        [
          _vm.availablePackages.length === 0
            ? _c("div", { staticClass: "inline-loader" }, [
                _c("i", { staticClass: "icon loader" }),
                _vm._v(" " + _vm._s(_vm.tr("Loading...")) + " "),
              ])
            : [
                _c("div", [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(_vm._s(_vm.tr("Billing period"))),
                  ]),
                  _c("div", { staticClass: "checkbox-list horizontal" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.billingPeriod,
                            expression: "billingPeriod",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "billing_period",
                          value: "monthly",
                          disabled: _vm.billingPeriodSelectorDisabled,
                        },
                        domProps: {
                          checked: _vm._q(_vm.billingPeriod, "monthly"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.billingPeriod = "monthly"
                          },
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.tr("Monthly")) + " "),
                    ]),
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.billingPeriod,
                            expression: "billingPeriod",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          name: "billing_period",
                          value: "annual",
                          disabled: _vm.billingPeriodSelectorDisabled,
                        },
                        domProps: {
                          checked: _vm._q(_vm.billingPeriod, "annual"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.billingPeriod = "annual"
                          },
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.tr("Annual")) + " "),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.tr("Subscription package"))),
                    ]),
                    _c("v-select", {
                      attrs: {
                        options: _vm.selectablePackages,
                        placeholder: _vm.tr("Choose a subscription plan"),
                        clearable: false,
                        searchable: false,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.name) +
                                  " - " +
                                  _vm._s(
                                    _vm.formatPrice(
                                      option.price,
                                      "€",
                                      option.period
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(option.name) +
                                  " - " +
                                  _vm._s(
                                    _vm.formatPrice(
                                      option.price,
                                      "€",
                                      option.period
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedPackage,
                        callback: function ($$v) {
                          _vm.selectedPackage = $$v
                        },
                        expression: "selectedPackage",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.tr("Connectable assets"))),
                    ]),
                    _vm.$app.isBuzz
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.selectedPackage
                                  ? _vm.formatPcs(
                                      _vm.selectedPackage.max_assets
                                    )
                                  : "-"
                              ) +
                              " "
                          ),
                        ]
                      : [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.requiredAssetCount,
                                expression: "requiredAssetCount",
                              },
                            ],
                            attrs: {
                              type: "number",
                              step: "1",
                              min: _vm.selectedPackage
                                ? _vm.selectedPackage.max_assets
                                : null,
                              disabled: _vm.requiredAssetCountDisabled,
                            },
                            domProps: { value: _vm.requiredAssetCount },
                            on: {
                              change: () => _vm.correctingRequiredAssetsCount(),
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.requiredAssetCount = $event.target.value
                              },
                            },
                          }),
                          _vm.selectedPackage
                            ? _c(
                                "small",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.tr(
                                          "Of which :number included in the subscription package.",
                                          {
                                            ":number": _vm.formatPcs(
                                              _vm.selectedPackage.max_assets
                                            ),
                                          }
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.selectedPackage.extra_asset_price
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.tr(
                                                "Each additional asset costs :price.",
                                                {
                                                  ":price": _vm.formatPrice(
                                                    _vm.selectedPackage
                                                      .extra_asset_price,
                                                    "€",
                                                    _vm.selectedPackage.period
                                                  ),
                                                }
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
                _vm.requiredAssetCount !== null &&
                _vm.company.usedAssetsCount > _vm.requiredAssetCount
                  ? _c("info-text", { attrs: { type: "warning" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tr(
                              "Your company currently has :num connected assets.",
                              { ":num": _vm.company.usedAssetsCount }
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$app.isAd
                              ? _vm.tr(
                                  "If you reduce the number of connectable assets below this number, it will cause the automations to stop."
                                )
                              : ""
                          ) +
                          " " +
                          _vm._s(
                            _vm.$app.isBuzz
                              ? _vm.tr(
                                  "If you reduce the number of connectable assets below this number, it will cause the moderation to stop."
                                )
                              : ""
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                !_vm.$app.isBuzz
                  ? _c("div", [
                      _c("label", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.tr("Maximum users"))),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedPackage
                              ? _vm.formatPcs(_vm.selectedPackage.max_users)
                              : "-"
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.featureListIsVisible
                  ? _c("div", [
                      _c("label", { staticClass: "input-label" }, [
                        _vm._v(_vm._s(_vm.tr("Extra services"))),
                      ]),
                      _c("div", { staticClass: "checkbox-list vertical" }, [
                        _vm.featureManagedAccountIsAvailable
                          ? _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.requiredFeatureManagedAccount,
                                    expression: "requiredFeatureManagedAccount",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: _vm.managedAccountCheckboxDisabled,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.requiredFeatureManagedAccount
                                  )
                                    ? _vm._i(
                                        _vm.requiredFeatureManagedAccount,
                                        null
                                      ) > -1
                                    : _vm.requiredFeatureManagedAccount,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.requiredFeatureManagedAccount,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.requiredFeatureManagedAccount =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.requiredFeatureManagedAccount =
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.requiredFeatureManagedAccount = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.tr("Managed Account Service")) +
                                  " "
                              ),
                              _c("small", [
                                _vm._v(
                                  "+" +
                                    _vm._s(
                                      _vm.formatPrice(
                                        _vm.selectedPackage
                                          .feature_managed_account_price,
                                        "€",
                                        _vm.selectedPackage.period
                                      )
                                    )
                                ),
                              ]),
                              _c("i", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: _vm.tr(
                                        "Get up to 1 hour of expert assistance per month (in two 30-minute sessions) to optimize your Infinite∞Ad campaigns and maximize your advertising success."
                                      ),
                                      classes: ["textonly"],
                                    },
                                    expression:
                                      "{ content: tr('Get up to 1 hour of expert assistance per month (in two 30-minute sessions) to optimize your Infinite∞Ad campaigns and maximize your advertising success.'), classes: [ 'textonly' ] }",
                                  },
                                ],
                                staticClass: "icon info light-black",
                              }),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "extra-services-info-block" },
                  [
                    _vm.showManagedAccountContactMessage
                      ? _c("info-text", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "Our partner will soon contact you regarding the Managed Account service you requested."
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.managedAccountCheckboxDisabled
                      ? _c("info-text", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  'The "Managed Account Service" can be canceled no earlier than :date',
                                  { ":date": _vm.managedAccountCancellableDate }
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm.showManagedAccountCancelInfoBefore
                      ? _c("info-text", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  'When requesting the "Managed Account Service", the first fee will be determined proportionally according to your billing cycle, but you will not have the opportunity to cancel the service before the next billing. If you request it now, the first opportunity to cancel the service will be on :date',
                                  { ":date": _vm.managedAccountCancellableDate }
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "coupon-code-block" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.tr("Coupon code"))),
                    ]),
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.couponCode,
                            expression: "couponCode",
                          },
                        ],
                        class: {
                          error:
                            !_vm.couponValidation.isValid &&
                            _vm.couponValidation.message,
                        },
                        attrs: {
                          type: "text",
                          disabled: _vm.hadPreviousSubscription,
                        },
                        domProps: { value: _vm.couponCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.couponCode = $event.target.value
                          },
                        },
                      }),
                      _vm.couponValidation.isLoading
                        ? _c("i", { staticClass: "icon loader primary" })
                        : _vm._e(),
                    ]),
                    _vm.hadPreviousSubscription && !_vm.couponCode
                      ? _c("info-text", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "You can only use a coupon code on your first subscription"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm.couponValidation.isValid
                      ? _c("info-text", { attrs: { type: "info" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.tr(
                                  "This coupon provides :discount% discount on the base subscription price",
                                  { ":discount": _vm.couponValidation.discount }
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm.couponValidation.error
                      ? _c("info-text", { attrs: { type: "error" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.couponValidation.error) + " "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "bottom-bar" }, [
                  _c("div", { staticClass: "cost-wrapper" }, [
                    _c("label", [_vm._v(_vm._s(_vm.tr("Cost")))]),
                    _vm.costIsLoading
                      ? _c("i", { staticClass: "icon loader primary" })
                      : _c(
                          "span",
                          { staticClass: "price" },
                          [
                            _vm.selectedPackage && _vm.firstCostIsLoaded
                              ? [
                                  _vm.vatRate
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                _vm.totalCost *
                                                  (1 + _vm.vatRate),
                                                "€",
                                                _vm.selectedPackage.period
                                              )
                                            ) +
                                            " "
                                        ),
                                        _vm.totalCost > 0
                                          ? _c("small", [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm.tr("net") +
                                                      " " +
                                                      _vm.formatPrice(
                                                        _vm.totalCost,
                                                        "€"
                                                      )
                                                  ) +
                                                  " + " +
                                                  _vm._s(_vm.vatRate * 100) +
                                                  "% " +
                                                  _vm._s(_vm.tr("VAT")) +
                                                  ") "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                _vm.totalCost,
                                                "€",
                                                _vm.selectedPackage.period
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                ]
                              : [_vm._v(" - ")],
                          ],
                          2
                        ),
                  ]),
                  _vm.payableNow
                    ? _c(
                        "div",
                        { staticClass: "broken-period-cost" },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.tr("Payable now")) + ":"),
                          ]),
                          _vm.vatRate
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPrice(
                                        _vm.payableNow * (1 + _vm.vatRate),
                                        "€"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("small", [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm.tr("net") +
                                          " " +
                                          _vm.formatPrice(_vm.payableNow, "€")
                                      ) +
                                      " + " +
                                      _vm._s(_vm.vatRate * 100) +
                                      "% " +
                                      _vm._s(_vm.tr("VAT")) +
                                      ") "
                                  ),
                                ]),
                              ]
                            : [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPrice(_vm.payableNow, "€")
                                    ) +
                                    " "
                                ),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }