<template>
  <div class="cancel-subscription-modal-button-component">
    <info-text v-if="isCancelled" class="cancelled-text" type="error">
      {{ tr('The subscription has been cancelled.') }}
    </info-text>
    <template v-else-if="hasPaidPackage">
      <button
        class="red cancel-subscription-button"
        @click="() => $modal.show('cancel-subscription-modal')"
      >
        {{ tr('Cancel subscription') }}
      </button>

      <modal
        name="cancel-subscription-modal"
        class="cancel-subscription-modal"
        overlayTransition="fade-modal-overlay"
      >
        <i
          class="icon close"
          @click="() => $modal.hide('cancel-subscription-modal')"
        />

        <form-block
          class="change-subscription-form"
          :title="tr('Cancel subscription')"
          :description="tr('If you cancel your subscription, you can still use the service during the billing cycle you have already paid for. If you change your mind, you can subscribe to our package that suits you best at any time after cancellation.')"
        >
          <div class="buttons">
            <button :disabled="isLoading" @click="() => closeModal()">
              {{ tr('Cancel') }}
            </button>
            <button class="red" :disabled="isLoading" @click="() => confirm()">
              <i v-if="isLoading" class="icon loader white" /> {{ tr('Confirm') }}
            </button>
          </div>
        </form-block>
      </modal>
    </template>
  </div>
</template>

<script>
import FormBlock from '@/components/FormBlock.vue';
import InfoText from '@/components/InfoText.vue';

import SubscriptionService from '@/services/Subscription';

export default {
  name: 'CancelSubscriptionModalButton',
  components: {
    FormBlock,
    InfoText,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    hasPaidPackage() {
      return this.$root.currentCompany.paid_active_package !== null;
    },
    isCancelled() {
      return !this.hasPaidPackage
        && this.$root.currentCompany.current_active_package
        && this.$root.currentCompany.current_active_package.price > 0;
    },
  },
  methods: {
    closeModal() {
      if (this.isLoading) {
        return;
      }

      this.$modal.hide('cancel-subscription-modal');
    },
    confirm() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      SubscriptionService.cancel()
        .then(() => {
          this.$toast.clear();
          this.$toast.success(this.tr('The subscription has been cancelled.'));
          this.$root.fetchUserData();
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoading = false);
    },
  }
};
</script>
