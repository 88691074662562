<template>
  <div class="rule-activity-review-modal-content">
    <social-page
      :source-profile="item.post.source_profile"
      :date="item.entity.created_at"
    />

    <div class="divider" />

    <div class="modal-main-content scroll-wrapper">
      <div>
        <h3>{{ shortLabel }}</h3>

        <inline-social-content :entity="item.entity" />
      </div>

      <div v-if="whyNotText">
        <h3 v-if="whyNotQuestion">{{ whyNotQuestion }}</h3>
        {{ whyNotText }}
      </div>

      <div v-if="item.isProhibitedContent">
        <h3>{{ tr('Reasoning of Infinite∞Ai') }}</h3>
        {{ item.related_entity.reason_translation }}
      </div>

      <div v-if="whatToDoText">
        <h3>{{ tr('What can you do?') }}</h3>
        {{ whatToDoText }}
      </div>

      <rule-waiting-list-status
        v-if="item.related_entity !== null"
        :rule="rule"
        :entity="item.related_entity"
        :disabled="$root.isSupportMode"
      />
    </div>
  </div>
</template>

<script>
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import RuleWaitingListStatus from '@/components/RuleWaitingListStatus.vue';
import SocialPage from '@/components/SocialPage.vue';
import { RULE_ACTION, RULE_REVIEW_FILTER_DEFINITIONS } from '@/consts.js';
import RuleActivityReviewItem from '@/descriptors/RuleActivityReviewItem.js';
import Rule from '@/entities/Rule.js';

export default {
  name: 'RuleActivityReviewFullscreenModal',
  components: {
    InlineSocialContent,
    RuleWaitingListStatus,
    SocialPage,
  },
  props: {
    rule: {
      type: Rule,
      required: true,
    },
    item: {
      type: RuleActivityReviewItem,
      required: true,
    },
  },
  computed: {
    definition() {
      return RULE_REVIEW_FILTER_DEFINITIONS.find(item => item.code === this.item.code);
    },
    label() {
      return this.tr(this.definition.label);
    },
    shortLabel() {
      return this.tr(this.definition.shortLabel[this.rule.action]);
    },
    whyNotQuestion() {
      switch (this.rule.action) {
        case RULE_ACTION.START:
          return this.tr('Why isn\'t an ad being created?');

        case RULE_ACTION.STOP:
          return this.tr('Why isn\'t this ad being stopped?');

        case RULE_ACTION.SCALING_UP:
          return this.tr('Why isn\'t this ad being scaled up?');
      }

      return null;
    },
    whyNotText() {
      if (this.definition.whyNot && this.definition.whyNot[this.rule.action]) {
        return this.tr(this.definition.whyNot[this.rule.action]);
      }

      return null;
    },
    whatToDoText() {
      if (this.definition.whatToDo && this.definition.whatToDo[this.rule.action]) {
        return this.tr(this.definition.whatToDo[this.rule.action]);
      }

      return null;
    },
  },
};
</script>
