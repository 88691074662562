var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.wrapperClasses }, [
    _vm._v(" " + _vm._s(_vm.text) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }