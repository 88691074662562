var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "age-selector" },
    [
      _c("v-select", {
        attrs: {
          reduce: (i) => i.value,
          options: _vm.ageOptions,
          searchable: false,
          clearable: false,
        },
        model: {
          value: _vm.insideValue,
          callback: function ($$v) {
            _vm.insideValue = $$v
          },
          expression: "insideValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }