<template>
  <form-block
    v-model="isEnabled"
    class="rule-form-block budget-block-component campaign-budget-block-component"
    :title="title !== null ? title : tr('Advantage campaign budget')"
    :description="description !== null ? description : tr('If you enable this feature, it will automatically distribute your campaign`s budget across different ad sets to get more results depending on your delivery optimization choices and bid strategy. The purpose of this is to ensure the best overall result, so the budget may not be evenly distributed among the individual ad sets. Your posts, so to speak, will `compete` with each other. For example, if you have 2 active ad sets in your campaign, it`s possible that the majority of your budget will be allocated to one ad set if it achieves the best overall performance.')"
    :disabled="disabled || !entity.isNew"
    :switchable="switchable"
  >
    <div>
      <label v-if="!minimal" class="input-label">{{ tr('Campaign budget') }}</label>

      <div v-if="!hasSelectedAdAccount" class="blocker-text">
        {{ tr('Please, select an ad account.') }}
      </div>
      <template v-else>
        <div :class="{ 'inline-input-wrapper inline-input-select': !minimal }">
          <money-input
            v-model="entity.output_campaign.budget"
            :disabled="disabled"
            :currency="entity.adAccountCurrency ? entity.adAccountCurrency.code : 'EUR'"
          />
          <v-select
            v-if="!minimal"
            v-model="entity.output_campaign.budget_period"
            :options="budgetPeriods"
            :reduce="(obj)=> obj.machine_name"
            :disabled="disabled || !entity.isNew"
            :searchable="false"
            :clearable="false"
          />
        </div>
        <div
          v-if="isEnabled && budgetPeriod === 'lifetime'"
          class="lifetime-budget-date-wrapper"
        >
          <div class="date-time date-time--start">
            <label class="input-label">{{ tr('Start date') }}</label>
            <date-time-input
              v-model="entity.settings.adset_start_time"
              :disabled="disabled || !entity.isNew"
            />
          </div>
          <div class="date-time date-time--end">
            <label class="input-label">{{ tr('End date') }}</label>
            <date-time-input
              v-model="entity.settings.adset_end_time"
              :disabled="disabled || !entity.isNew"
            />
          </div>
        </div>
        <info-text v-if="originalCampaignBudget && originalCampaignBudget > entity.output_campaign.budget">
          {{ tr('Reducing the campaign budget may result in the suspension of some already running ads.') }}
        </info-text>

        <info-text v-if="invalidLifetimeRange" type="error">
          {{ invalidLifetimeRangeInfoText }}
        </info-text>
        <info-text v-else-if="invalidBudget" type="error">
          {{ invalidBudgetInfoText }}
        </info-text>
        <info-text v-else :icon="isBudgetRequestRunning ? 'loader' : null">
          {{ minBudgetInfoText }}
        </info-text>

        <info-text v-if="availableAdsFromBudget > 0">
          <template v-if="availableAdsFromBudget !== 1">
            {{ tr('With this budget, you will be able to run :num ads simultaneously.', { ':num': availableAdsFromBudget }) }}
          </template>
          <template v-else>
            {{ tr('With this budget, you will be able to run 1 ad simultaneously.') }}
          </template>
        </info-text>
      </template>
    </div>

    <div v-if="!minimal && !hasSelectedAdAccount">
      <label class="input-label">{{ tr('Bid strategy') }}</label>
      <v-select
        v-model="entity.output_campaign.bid_strategy"
        :options="facebookCampaignBidStrategies"
        :reduce="(obj)=> obj.machine_name"
        :searchable="false"
        :clearable="false"
        :disabled="disabled || !entity.isNew"
      />
    </div>
  </form-block>
</template>

<script>
import DateTimeInput from '@/components/DateTimeInput.vue';
import InfoText from '@/components/InfoText.vue';
import MoneyInput from '@/components/MoneyInput.vue';
import RuleFormBlockMixin from '@/mixins/RuleForm/Block.js';
import BudgetBlockMixin from '@/mixins/RuleForm/Blocks/Budget.js';

export default {
  name: 'CampaignBudgetBlock',
  components: {
    DateTimeInput,
    InfoText,
    MoneyInput,
  },
  mixins: [ RuleFormBlockMixin, BudgetBlockMixin ],
  props: {
    title: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    description: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    switchable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    minimal: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      isEnabled: !this.switchable || !this.entity.settings.adset_budget,
      originalCampaignBudget: !this.entity.isNew ? this.entity.output_campaign.budget : null,
    };
  },
  computed: {
    isCampaignLevel() {
      return this.isEnabled;
    },
    isAdsetLevel() {
      return !this.isCampaignLevel;
    },
    hasSelectedAdAccount() {
      return this.entity.ad_account && this.entity.ad_account.id;
    },
    currencyDetails() {
      return this.$store.getters['app/currencies'].find(i => i.code === this.entity.adAccountCurrency.code);
    },
    availableAdsFromBudget() {
      if (this.budgetPeriod === 'daily') {
        if (this.entity.output_campaign.budget >= this.entity.adAccountMinDailyBugdet) {
          return Math.floor(this.entity.output_campaign.budget / this.entity.adAccountMinDailyBugdet);
        }
      }
      else {
        if (
          this.minBudget !== null
          && !this.invalidBudget
          && !this.invalidLifetimeRange
          && this.entity.output_campaign.budget >= this.minBudget
        ) {
          return Math.floor(this.entity.output_campaign.budget / this.minBudget);
        }
      }

      return null;
    },
    facebookCampaignBidStrategies() {
      const list = this.$store.getters['app/facebookCampaignBidStrategies'];
      return this.conditionalFilteredList(list);
    },
  },
  watch: {
    isEnabled: {
      immediate: true,
      handler(val) {
        if (val) {
          this.entity.settings.adset_budget = null;
          this.entity.settings.adset_bid_strategy = null;

          if (this.entity.output_campaign.bid_strategy === null) {
            this.entity.output_campaign.budget_period = this.budgetPeriods[0].machine_name;
            this.entity.output_campaign.bid_strategy = this.facebookCampaignBidStrategies[0].machine_name;
          }
        }
        else {
          this.entity.output_campaign.budget = null;
          this.entity.output_campaign.budget_period = null;
          this.entity.output_campaign.bid_strategy = null;
        }
      },
    },
    'entity.output_campaign.budget'() {
      this.entity.settings.adset_budget = null;
      this.entity.settings.adset_budget_period = null;
      this.entity.settings.max_active_ads = null;
    },
  },
  created() {
    if (this.isEnabled) {
      this.$nextTick(() => this.getMinBudget());
    }
  },
  methods: {
    validate() {
      if (this.isEnabled) {
        return this.validateBudget();
      }

      return true;
    },
  },
};
</script>
