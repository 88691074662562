var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "copy-evaluation-component" },
    _vm._l(_vm.details, function (row, key) {
      return _c("div", { key: "ai-meta-" + key, staticClass: "meta" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.tr(row.title)) + ": " + _vm._s(row.value) + " / 10"
          ),
        ]),
        row.details
          ? _c("div", { staticClass: "details" }, [
              _vm._v(" " + _vm._s(row.details) + " "),
            ])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }