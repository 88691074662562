var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source-select-component" },
    [
      _vm._l(_vm.entity.source_profiles, function (sourceProfile, key) {
        return _c(
          "div",
          { key: "source-list-row-" + key, staticClass: "select-wrapper" },
          [
            _c("v-select", {
              staticClass: "social",
              attrs: {
                options: _vm.availablePlatformOptions,
                reduce: (option) => option.machineName,
                searchable: false,
                clearable: false,
                placeholder: _vm.tr("Platform"),
                disabled: _vm.disabledPlatformSelector,
              },
              on: { "option:selecting": () => (sourceProfile.id = null) },
              scopedSlots: _vm._u(
                [
                  {
                    key: "no-options",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.tr("No elements found")) + " "),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: sourceProfile.platform,
                callback: function ($$v) {
                  _vm.$set(sourceProfile, "platform", $$v)
                },
                expression: "sourceProfile.platform",
              },
            }),
            _c("v-select", {
              staticClass: "source",
              attrs: {
                options: _vm.filteredSources(sourceProfile.platform),
                reduce: (option) => option.id,
                clearable: false,
                disabled: _vm.disabled || !sourceProfile.platform,
                placeholder: _vm.sourcePlaceholder(sourceProfile),
                label: "name",
              },
              on: {
                "option:selecting": (option) =>
                  sourceProfile.updateWith(option),
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "no-options",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(_vm.tr("No elements found")) + " "),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: sourceProfile.id,
                callback: function ($$v) {
                  _vm.$set(sourceProfile, "id", $$v)
                },
                expression: "sourceProfile.id",
              },
            }),
            _vm.showDeleteButton
              ? _c("i", {
                  staticClass: "icon delete dark-gray",
                  on: { click: () => _vm.removeRow(key) },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.canAddSource
        ? _c(
            "button",
            { staticClass: "big primary", on: { click: () => _vm.addRow() } },
            [_vm._v(" + " + _vm._s(_vm.tr("Add Source")) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }