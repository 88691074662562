<template>
  <div :class="{ 'step-card': true, 'disabled': isCardDisabled, 'done': isCardDone }">
    <div class="counter">{{ step }}</div>
    <div class="title" v-if="!isCardDone" v-html="defaultText" />
    <div class="title" v-else v-html="doneText" />

    <i v-if="isCardDone" class="icon double-checkmark primary" />
    <component
      v-else
      :is="!isCardDisabled ? 'router-link' : 'button'"
      :to="!isCardDisabled ? buttonRoute : null"
      class="button"
      :disabled="isCardDisabled"
    >
      + {{ buttonText }}
    </component>
  </div>
</template>

<script>
export default {
  name: 'StepCards',

  props: {
    isCardDisabled: {
      type: Boolean,
      required: true,
    },
    isCardDone: {
      type: Boolean,
      required: true,
    },
    defaultText: {
      type: String,
      required: true,
    },
    doneText: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonRoute: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
  },
};
</script>
