<template>
  <div class="rule-form-step rule-form-step--review">
    <form-block
      :title="tr('Overview')"
      :description="description"
    />

    <div v-if="(!isListEmpty || isLoading) && !isAiMode" class="filters">
      <input
        v-model="searchTerm"
        type="text"
        class="term-filter icon--search"
        :placeholder="tr('Search') + '...'"
        :disabled="isLoading"
      />
      <v-select
        v-model="codeFilter"
        :options="codeFilterOptions"
        :reduce="(item) => item.codes"
        :searchable="false"
        :disabled="isLoading"
        class="code-select"
      />
    </div>
    <template v-if="!isLoading && filteredList.length === 0">
      <empty-screen
        v-if="isAiMode"
        icon="hourglass"
        :title="tr('Infinite∞Ai is still analyzing your contents')"
        :flag-text="tr('Wait a moment')"
        :description="aiEmptyScreenDescription"
      >
        <template #under-description>
          <button class="primary" @click="() => loadActivityOverview()">
            {{ tr('Refresh') }}
          </button>
        </template>
      </empty-screen>
      <empty-screen
        v-else
        icon="search"
        :title="tr('No results match your current search criteria')"
        :flag-text="tr('Modify search')"
        :description="tr('Currently there are no items that match your criteria. The items you\'re looking for might appear here later, or try different parameters to see currently available items.')"
      />
    </template>
    <sorted-table v-else :data="renderedList">
      <template #header>
        <table-head col="source" width="180px">{{ tr('Source') }}</table-head>
        <table-head col="content" class="content-cell" min-width="400px">{{ contentLabel }}</table-head>
        <table-head col="actions" width="40px" />
      </template>

      <template #default="{ sort }">
        <template v-if="isLoading">
          <table-row
            v-for="i in [ 1, 2, 3 ]"
            :key="'rule-activitity-overview-skeleton-' + i"
            header-color="white"
          >
            <template #header>
              <skeleton height="13px" width="150px" />
            </template>

            <table-data col="source">
              <social-page />
            </table-data>

            <table-data col="content" class="content-cell">
              <inline-social-content />
            </table-data>

            <table-data col="actions" />
          </table-row>
        </template>

        <table-row
          v-for="item in sort"
          :key="item.entity.id"
          :header-color="getHeaderColor(item.code)"
          @click.native="() => openFullscreenModal(item)"
        >
          <template #header>
            <i v-if="getHeaderIcon(item.code) !== null" :class="'icon white ' + getHeaderIcon(item.code)" />
            {{ getHeaderLabel(item.code)}}
          </template>

          <table-data col="source">
            <social-page
              :source-profile="item.post.source_profile"
              :date="item.entity.created_at"
              :date-tooltip-prefix="getDateTooltipPrefix(item)"
            />
          </table-data>

          <table-data col="content" class="content-cell">
            <inline-social-content :entity="item.entity" />
          </table-data>

          <table-data col="actions" class="actions">
            <navigation-arrow v-if="isOpenable(item.code)" color="error" />
          </table-data>
        </table-row>
      </template>
    </sorted-table>

    <div ref="showMore" />
  </div>
</template>

<script>
import EmptyScreen from '@/components/EmptyScreen.vue';
import FormBlock from '@/components/FormBlock.vue';
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import RuleActivityReview from '@/components/ModalContents/RuleActivityReview.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import Skeleton from '@/components/Skeleton.vue';
import SocialPage from '@/components/SocialPage.vue';
import { EVENT_BUS_EVENTS, RULE_ACTION, RULE_REVIEW_CODE, RULE_REVIEW_CODE_TYPE, RULE_REVIEW_FILTER_DEFINITIONS } from '@/consts.js';
import RuleService from '@/services/Rule.js';
import debounce from '@/utils/debounce.js';
import EventBus from '@/utils/eventBus.js';

const ITEMS_PER_PAGE = 10;

export default {
  name: 'RuleFormReviewView',
  components: {
    FormBlock,
    InlineSocialContent,
    NavigationArrow,
    EmptyScreen,
    Skeleton,
    SocialPage,
  },
  data() {
    return {
      debouncedRecalculateFilteredList: debounce(() => this.recalculateFilteredList(), 500),
      isLoading: false,
      searchTerm: '',
      codeFilter: '[]',
      list: [],
      filteredList: [],
      renderedList: [],
    };
  },
  computed: {
    isAiMode() {
      return this.$store.getters['ruleForm/isAiMode'];
    },
    aiEmptyScreenDescription() {
      return this.tr('Infinite∞Ai is still searching for your best-performing content. Please wait a few moments and click the "Refresh" button, or you can start preparing your AI-supported automations using the "Create" button above.');
    },
    entity() {
      return this.$store.getters['ruleForm/entity'];
    },
    description() {
      if (this.isAiMode) {
        return this.tr('Based on the provided settings and Infinite∞Ai analysis, these contents will initially be used to create ads. Subsequently, the Ai will continuously review your content and automatically create new ads from the best-performing ones.');
      }

      switch (this.entity.action) {
        case RULE_ACTION.START:
          return this.tr('Here is the list of content currently being used to create ads based on the set criteria. Items marked with a red notification bar cannot be used as ads or are experiencing issues that prevent them from running. Click on these items for more details.');

        case RULE_ACTION.STOP:
          return this.tr('Here is the list of ads that match your criteria for being stopped. Items marked with a red notification bar cannot be stopped or are experiencing issues that prevent them from being processed. Click on these items for more details.');

        case RULE_ACTION.SCALING_UP:
          return this.tr('Here is the list of ads that match your criteria for being scaled up. Items marked with a red notification bar cannot be scaled up or are experiencing issues that prevent them from being processed. Click on these items for more details.');
      }

      return null;
    },
    codeFilterOptions() {
      const typeGroupLabels = [
        {
          label: 'Waiting for Processing',
          type: RULE_REVIEW_CODE_TYPE.WAITING,
        },
        {
          label: 'Completed',
          type: RULE_REVIEW_CODE_TYPE.COMPLETED,
        },
        {
          label: 'Excluded by Filters',
          type: RULE_REVIEW_CODE_TYPE.FILTERED_OUT,
        },
        {
          label: 'Content Issue',
          type: RULE_REVIEW_CODE_TYPE.CONTENT_ISSUE,
        },
        {
          label: 'Technical Restriction',
          type: RULE_REVIEW_CODE_TYPE.TECHNICAL_RESTRICTION,
        },
      ];

      const result = [];

      typeGroupLabels.forEach(group => {
        const codes = RULE_REVIEW_FILTER_DEFINITIONS.filter(item => item.type === group.type);

        result.push({
          label: group.label,
          codes: JSON.stringify(codes.map(item => item.code)),
        });
      });

      result.unshift({
        label: 'All',
        codes: JSON.stringify([]),
      });

      return result;
    },
    contentLabel() {
      if (this.entity.action === RULE_ACTION.START) {
        return this.tr('Post Content');
      }

      return this.tr('Ad Content');
    },
    isListEmpty() {
      return this.list.length === 0;
    },
    hasMore() {
      return this.renderedList.length !== this.filteredList.length;
    },
  },
  watch: {
    list() {
      this.recalculateFilteredList();
    },
    searchTerm() {
      this.debouncedRecalculateFilteredList();
    },
    codeFilter() {
      this.recalculateFilteredList();
    },
  },
  created() {
    this.loadActivityOverview();
  },
  mounted() {
    this.initInfiniteScroll();

    EventBus.$on(EVENT_BUS_EVENTS.PROHIBITED_CONTENT_OVERRIDDEN, this.onProhibitedContentOverridden);
    EventBus.$on(EVENT_BUS_EVENTS.WAITING_ACTIVITY_ACCEPTED, this.onWaitingActivityAccepted);
    EventBus.$on(EVENT_BUS_EVENTS.WAITING_ACTIVITY_REJECTED, this.onWaitingActivityRejected);
  },
  beforeDestroy() {
    EventBus.$off(EVENT_BUS_EVENTS.PROHIBITED_CONTENT_OVERRIDDEN, this.onProhibitedContentOverridden);
    EventBus.$off(EVENT_BUS_EVENTS.WAITING_ACTIVITY_ACCEPTED, this.onWaitingActivityAccepted);
    EventBus.$off(EVENT_BUS_EVENTS.WAITING_ACTIVITY_REJECTED, this.onWaitingActivityRejected);
  },
  methods: {
    onProhibitedContentOverridden(postAiProhibitedContentEntity) {
      this.changeItemCode(postAiProhibitedContentEntity, RULE_REVIEW_CODE.WAITING_FOR_PROCESS);
    },
    onWaitingActivityAccepted(ruleActivityManagementQueueItemEntity) {
      this.changeItemCode(ruleActivityManagementQueueItemEntity, RULE_REVIEW_CODE.WAITING_FOR_PROCESS);
    },
    onWaitingActivityRejected(ruleActivityManagementQueueItemEntity) {
      this.changeItemCode(ruleActivityManagementQueueItemEntity, RULE_REVIEW_CODE.REJECTED_MANUALLY_IN_ACTIVITY_MANAGEMENT);
    },
    changeItemCode(relatedEntity, newCode) {
      const item = this.list.find(item => item.related_entity && item.related_entity.id === relatedEntity.id);
      if (!item) {
        return;
      }

      item.code = newCode;
      item.related_entity.updateWith(relatedEntity);
    },
    initInfiniteScroll() {
      if (!this.$refs.showMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.showMore();
        }
      });

      if (this.$refs.showMore) {
        observer.observe(this.$refs.showMore);
      }
    },
    recalculateFilteredList() {
      let result = [ ...this.list ];

      if (this.searchTerm.trim().length > 0) {
        const searchTerm = this.searchTerm.toLowerCase();
        result = result.filter(item => {
          const content = this.getContent(item).toLowerCase();
          return content.includes(searchTerm);
        });
      }

      if (this.codeFilter !== '[]') {
        const selectedCodes = JSON.parse(this.codeFilter);
        result = result.filter(item => selectedCodes.includes(item.code));
      }

      this.filteredList = result;
      this.renderedList = [];

      this.showMore();
    },
    showMore() {
      if (!this.hasMore) {
        return;
      }

      const currentPageItems = this.filteredList.slice(this.renderedList.length, this.renderedList.length + ITEMS_PER_PAGE);
      this.renderedList.push(...currentPageItems);
    },
    loadActivityOverview() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      let promise;
      if (this.isAiMode) {
        promise = RuleService.generateActivityReviewForAiMode(
          this.entity,
          this.$store.getters['ruleForm/goal'],
          this.$store.getters['ruleForm/scalingBudget'],
          this.$store.getters['ruleForm/aiAssist'],
        );
      }
      else {
        promise = RuleService.generateActivityReview(this.entity);
      }

      promise
        .then(resp => this.list = resp)
        // eslint-disable-next-line no-console
        .catch(err => console.error(err))
        .finally(() => this.isLoading = false);
    },
    getRuleActivityItemDefinition(code) {
      return RULE_REVIEW_FILTER_DEFINITIONS.find(item => item.code === code);
    },
    isOpenable(code) {
      if (code === RULE_REVIEW_CODE.WAITING_FOR_PROCESS) {
        return false;
      }

      const definition = this.getRuleActivityItemDefinition(code);
      if (!definition) {
        return false;
      }

      return definition.type !== RULE_REVIEW_CODE_TYPE.COMPLETED;
    },
    getHeaderColor(code) {
      const definition = this.getRuleActivityItemDefinition(code);
      if (!definition) {
        return 'gray';
      }

      switch (definition.type) {
        case RULE_REVIEW_CODE_TYPE.WAITING:
          return 'orange';

        case RULE_REVIEW_CODE_TYPE.COMPLETED:
          return 'primary';

        default:
          return 'error';
      }
    },
    getHeaderIcon(code) {
      const definition = this.getRuleActivityItemDefinition(code);
      if (!definition) {
        return null;
      }

      switch (definition.type) {
        case RULE_REVIEW_CODE_TYPE.WAITING:
          return 'waiting';

        case RULE_REVIEW_CODE_TYPE.COMPLETED:
          return 'checkmark-circle';

        default:
          return 'close-circle';
      }
    },
    getHeaderLabel(code) {
      const definition = this.getRuleActivityItemDefinition(code);

      if (!definition) {
        return '';
      }

      return this.tr(definition.shortLabel[this.entity.action]);
    },
    getContent(item) {
      if (item.entity_type === 'ad') {
        return item.entity.text ?? item.entity.name ?? '';
      }

      return item.entity.text ?? '';
    },
    getDateTooltipPrefix(item) {
      if (item.entity_type === 'ad') {
        return this.tr('Ad created on') + '<br/>';
      }

      return this.tr('Post created on') + '<br/>';
    },
    openFullscreenModal(item) {
      if (!this.isOpenable(item.code)) {
        return;
      }

      this.$fullscreenModal.open(
        this,
        RuleActivityReview,
        {
          item,
          rule: this.entity,
        },
      );
    },
    validate() {
      return true;
    },
  },
};
</script>
