<template>
  <component
    v-bind="props"
    :is="component"
    class="link-with-icon-component"
  >
    <i :class="['icon primary', icon ]" />
    <span>
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  name: 'LinkWithIcon',
  props: {
    icon: {
      type: String,
      required: false,
      default: () => 'link',
    },
    link: {
      type: String,
      required: false,
      default: () => '#',
    },
  },
  computed: {
    isExternal() {
      return this.link.startsWith('http');
    },
    component() {
      if (this.isExternal) {
        return 'a';
      }

      return 'router-link';
    },
    props() {
      if (this.isExternal) {
        return {
          href: this.link,
          target: '_blank',
        };
      }

      return {
        to: this.link,
      };
    },
  },
};
</script>
