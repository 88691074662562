var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-organization-view" },
    [
      _c(
        "form-block",
        { attrs: { title: _vm.tr("Organization details") } },
        [
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Organization name"))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.name,
                  expression: "model.name",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.model.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.model, "name", $event.target.value)
                },
              },
            }),
          ]),
          _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(_vm._s(_vm.tr("Time zone"))),
              ]),
              _c("v-select", {
                attrs: { reduce: (obj) => obj.name, options: _vm.timezones },
                model: {
                  value: _vm.model.timezone,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "timezone", $$v)
                  },
                  expression: "model.timezone",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _c("label", { staticClass: "input-label" }, [
              _vm._v(_vm._s(_vm.tr("Required two-factor authentication"))),
            ]),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model.required_2fa,
                    expression: "model.required_2fa",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  disabled: !_vm.$root.loggedInUser.google_2fa_enabled,
                },
                domProps: {
                  checked: Array.isArray(_vm.model.required_2fa)
                    ? _vm._i(_vm.model.required_2fa, null) > -1
                    : _vm.model.required_2fa,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.model.required_2fa,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.model, "required_2fa", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.model,
                            "required_2fa",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.model, "required_2fa", $$c)
                    }
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.tr("Require 2FA from users in the organization")) +
                  " "
              ),
            ]),
          ]),
          !_vm.model.required_2fa && !_vm.$root.loggedInUser.google_2fa_enabled
            ? _c("info-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "To enable this function, you must first enable two-factor authentication in your own user account on the Account page."
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.$root.loggedInUser.company.is_demo
        ? _c("form-block", { attrs: { title: _vm.tr("Reset organization") } }, [
            _c(
              "button",
              {
                staticClass: "button red",
                on: { click: () => _vm.openResetConfirmDialog() },
              },
              [_vm._v(" " + _vm._s(_vm.tr("Reset")) + " ")]
            ),
          ])
        : _c(
            "form-block",
            {
              attrs: {
                title: _vm.tr("Delete organization"),
                description: _vm.deleteDescription,
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "button red",
                  on: { click: () => _vm.openDeleteConfirmDialog() },
                },
                [_vm._v(" " + _vm._s(_vm.tr("Delete")) + " ")]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }