/* eslint-disable filenames/match-regex */

import Company from '@/entities/Company';
import User from '@/entities/User';
import { Login2faNeccessaryError } from '@/errors';
import { router } from '@/router';
import AuthService from '@/services/Auth';
import CompanyService from '@/services/Company';
import ConnectedProfileService from '@/services/ConnectedProfile';
import UserService from '@/services/User';
import { store as $rootStore } from '@/store';

const token = localStorage.getItem('token');
const hasToken = typeof token === 'string' && token.length > 0;

const selectedCompanyId = localStorage.getItem('companyId');

const state = {
  isFetching: false,
  selectedCompanyId,
  user: null,
  company: null,
  token,
  tokenReceivedAt: hasToken
    ? parseInt(localStorage.getItem('tokenReceivedAt'))
    : 0,
};

const getters = {
  isFetching(state) {
    return state.isFetching;
  },

  hasToken(state) {
    return state.token !== null;
  },

  token(state) {
    return state.token;
  },

  tokenReceivedAt(state) {
    return state.tokenReceivedAt;
  },

  loggedIn(state) {
    return state.user !== null;
  },

  user(state) {
    return state.user;
  },

  company() {
    return state.company;
  },

  selectedCompanyId() {
    if (state.selectedCompanyId) {
      return state.selectedCompanyId;
    }

    return state.user ? state.user.company.id : null;
  },
};

const actions = {
  fetch(store) {
    store.commit('isFetching', true);

    return AuthService.fetch()
      .then(async user => {
        store.commit('setUser', user);

        if (selectedCompanyId === null || user.company.id === selectedCompanyId) {
          await store.commit('setCompany', user.company);
        }
        else {
          const companyResp = await CompanyService.get(selectedCompanyId);
          store.commit('setCompany', companyResp);
        }

        store.commit('isFetching', false);

        return user;
      })
      .catch(error => {
        store.commit('isFetching', false);

        throw error;
      });
  },

  signup(store, { hash, firstname, lastname, email, companyName, timezone, password, newsletter, recaptchaToken, source }) {
    return AuthService.signup({ hash, firstname, lastname, email, companyName, timezone, password, newsletter, recaptchaToken, source }).then(resp => {
      store.commit('setToken', resp.access_token);
      store.commit('setUser', resp.user);

      return resp;
    });
  },

  login(store, { email, password, browser, code, trust }) {
    return AuthService.login(email, password, browser, code, trust).then(resp => {
      if ('2fa' in resp) {
        throw new Login2faNeccessaryError();
      }

      store.commit('setToken', resp.access_token);
      store.commit('setUser', resp.user);

      return resp;
    });
  },

  refresh(store) {
    if (!store.getters['loggedIn']) {
      return Promise.reject(new Error('User is not logged in'));
    }

    return AuthService.refreshToken().then(resp => {
      store.commit('setToken', resp.access_token);

      return resp;
    });
  },

  logout(store) {
    return AuthService.logout()
      .then(resp => {
        store.commit('logout');
        $rootStore.dispatch('dashboard/reset');
        $rootStore.dispatch('rules/resetView');
        return resp;
      });
  },

  connectProfile(store, { platform, accessToken }) {
    return ConnectedProfileService.connectProfile(platform, accessToken)
      .then(resp => {
        store.commit('addProfile', resp);
        return resp;
      });
  },

  removeProfile(store, id) {
    return ConnectedProfileService.removeProfile(id).then(resp => {
      store.dispatch('fetch');
      store.commit('deleteProfile');
      return resp;
    });
  },

  updateAccount(store, details) {
    return UserService.update(store.getters['user'].id, details)
      .then(resp => {
        store.commit('setUser', resp);
        return resp;
      });
  },

  changeCompany(store, companyId) {
    store.commit('isFetching', true);

    return CompanyService.get(companyId)
      .then(company => {
        store.commit('setCompany', company);
        this.$store.dispatch('posts/resetView', true);
        store.commit('isFetching', false);
        return company;
      })
      .catch(error => {
        store.commit('isFetching', false);
        throw error;
      });
  },
};

const mutations = {
  isFetching(state, value) {
    state.isFetching = value;
  },

  logout(state) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenReceivedAt');
    localStorage.removeItem('companyId');
    state.token = null;
    state.tokenReceivedAt = 0;
    state.loggedIn = false;
    state.user = null;
    state.company = null;
    state.selectedCompanyId = null;

    router.replace('/auth/login');
  },

  setToken(state, accessToken) {
    const now = new Date().getTime();

    localStorage.setItem('token', accessToken);
    localStorage.setItem('tokenReceivedAt', now);
    state.token = accessToken;
    state.tokenReceivedAt = now;
  },

  setUser(state, user) {
    if (!(user instanceof User) && typeof user === 'object') {
      user = new User(user);
    }

    if (!state.selectedCompanyId) {
      state.company = user.company;
    }

    state.user = user;
  },

  setCompany(state, company) {
    if (!(company instanceof Company) && typeof company === 'object') {
      company = new Company(company);
    }

    state.company = company;
  },

  updateCompany(state, company) {
    state.user.company.updateWith(company);
  },

  addProfile(state, profile) {
    let profilesByMachineName = {};
    state.user.connected_profiles.forEach(p => {
      profilesByMachineName[p.machine_name] = p;
    });
    profilesByMachineName[profile.machine_name] = profile;
    state.user.connected_profiles = Object.values(profilesByMachineName);

    profilesByMachineName = {};
    state.user.company.connected_profiles.forEach(p => {
      profilesByMachineName[p.machine_name] = p;
    });
    profilesByMachineName[profile.machine_name] = profile;
    state.user.company.connected_profiles = Object.values(profilesByMachineName);

    state.company.connected_profiles = state.user.company.connected_profiles;
  },

  deleteProfile(state, profile) {
    state.user.connected_profiles.splice(profile, 1);
  },

  selectCompany(state, value) {
    if (state.user && state.user.company.id === value) {
      value = null;
    }

    if (value === null) {
      window.localStorage.removeItem('companyId');
    }
    else {
      window.localStorage.setItem('companyId', value);
      state.selectedCompanyId = value;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
