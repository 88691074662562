var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block activity-management-block-component",
      attrs: {
        title: _vm.tr("Activity management"),
        description: _vm.tr(
          'Keep control of automation activities! Anyone set in the "Members to be notified about activities" will receive a notification with an option to approve or reject before the automation executes an activity.'
        ),
        switchable: "",
        disabled: _vm.disabled,
      },
      model: {
        value: _vm.entity.activity_management,
        callback: function ($$v) {
          _vm.$set(_vm.entity, "activity_management", $$v)
        },
        expression: "entity.activity_management",
      },
    },
    [
      _vm.entity.activity_management && !_vm.disableBlockInAiMode
        ? _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr("Interpretation of the lack of instruction as")
                    ) +
                    " "
                ),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.autoHandlingOptions,
                  reduce: (obj) => obj.value,
                  searchable: false,
                  clearable: false,
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.entity.activity_management_auto_handling,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.entity,
                      "activity_management_auto_handling",
                      $$v
                    )
                  },
                  expression: "entity.activity_management_auto_handling",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.entity.activity_management_auto_handling !== null &&
      !_vm.disableBlockInAiMode
        ? _c(
            "div",
            [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "Deadline for waiting for instructions before automatic action"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("v-select", {
                attrs: {
                  options: _vm.autoHandlingAfterOptions,
                  reduce: (obj) => obj.value,
                  searchable: false,
                  clearable: false,
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.entity.activity_management_auto_handling_after,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.entity,
                      "activity_management_auto_handling_after",
                      $$v
                    )
                  },
                  expression: "entity.activity_management_auto_handling_after",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }