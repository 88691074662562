var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-waiting-list-item-modal-content" },
    [
      _c("social-page", {
        attrs: {
          "source-profile": _vm.entity.post.source_profile,
          date: _vm.entity.created_at,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "div",
        { staticClass: "modal-main-content scroll-wrapper" },
        [
          _c(
            "div",
            [
              _c("h3", [_vm._v(_vm._s(_vm.title))]),
              _c("inline-social-content", {
                attrs: { entity: _vm.entity.ad ?? _vm.entity.post },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("h3", [_vm._v(_vm._s(_vm.tr("Automation")))]),
              _c(
                "link-with-icon",
                {
                  attrs: { link: "/automation/" + _vm.rule.id + "/overview" },
                  nativeOn: {
                    click: function ($event) {
                      return (() => _vm.$emit("close")).apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.rule.name) + " ")]
              ),
            ],
            1
          ),
          _vm.whyText
            ? _c("div", [
                _c("h3", [_vm._v(_vm._s(_vm.whyTitle))]),
                _vm._v(" " + _vm._s(_vm.whyText) + " "),
              ])
            : _vm._e(),
          _vm.isAiProhibitedItem
            ? _c("div", [
                _c("h3", [_vm._v(_vm._s(_vm.tr("Reasoning of Infinite∞Ai")))]),
                _vm._v(
                  " " +
                    _vm._s(_vm.entity.reason_translation ?? _vm.entity.reason) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.whatToDoText && !_vm.entity.isHandled
            ? _c("div", [
                _c("h3", [_vm._v(_vm._s(_vm.tr("What can you do?")))]),
                _vm._v(" " + _vm._s(_vm.whatToDoText) + " "),
              ])
            : _vm._e(),
          _c("rule-waiting-list-status", {
            attrs: {
              rule: _vm.rule,
              entity: _vm.entity,
              disabled: _vm.$root.isSupportMode,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }