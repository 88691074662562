import SupportUser from '@/entities/SupportUser';
import api from '@/services/Api';

export default {
  fetch() {
    return api().get('/support/user').then(resp => new SupportUser(resp));
  },

  login(email, password, code) {
    const data = {
      email,
      password,
      code,
    };

    return api().post('/support/auth/login', data, { withCredentials: true });
  },

  logout() {
    return api().delete('/support/auth/logout');
  },

  masquerade(userId) {
    return api().post('/support/masquerade', { user_id: userId }, { withCredentials: true });
  },

  getCommunicatorResponse(platform, initEntityType, initEntityId, method, params) {
    // Prepare params as a json string
    if (params) {
      params = JSON.stringify(params);
    }
    return api().get('/support/communicator-response', {
      params: {
        platform,
        init_entity_type: initEntityType,
        init_entity_id: initEntityId,
        method,
        params,
      },
    });
  },

  getCommunicatorMethods(platform, entityType, entityId) {
    return api().get('/support/communicator-methods', {
      params: {
        platform,
        entity_type: entityType,
        entity_id: entityId,
      },
    });
  },

  getAccessToken(entityType, entityId) {
    return api().get('/support/access-token', {
      params: {
        entity_type: entityType,
        id: entityId,
      },
    });
  },

  getTokenRequestLog(entityType, entityId, page, limit) {
    return api().get('/support/token-request-log', {
      params: {
        entity_type: entityType,
        id: entityId,
        page,
        limit,
      },
    });
  },
};
