<template>
  <div class="ai-result-component" v-tooltip="{ content: tooltipContent, classes: [ 'textonly' ] }">

    <i v-if="status === 1" class="icon loader" />
    <i v-else-if="status === 3" class="icon close red" />
    <div v-else-if="status === 2" class="emoji">{{ emoji }}</div>
    {{ label }}

    <template v-if="status === 2">
      ({{ result }})
    </template>
  </div>
</template>

<script>
export default {
  name: 'result',
  props: {
    status: {
      type: Number,
      required: true,
    },
    result: {
      type: Number,
      required: false,
    },
  },
  computed: {
    emoji() {
      switch (this.result) {
        case 0:
          return '🤍';
        case 1:
          return '😠';
        case 2:
          return '😤';
        case 3:
          return '😡';
        case 4:
          return '🤬';
        case 5:
          return '⚠️';
      }

      return null;
    },
    label() {
      switch (this.status) {
        case 0:
          return this.tr('Waiting');

        case 1:
          return this.tr('In progress');

        case 3:
          return this.tr('Failed');
      }

      switch (this.result) {
        case 0:
          return this.tr('Safe');
        case 1:
          return this.tr('Low risk');
        case 2:
          return this.tr('Medium risk');
        case 3:
          return this.tr('High risk');
        case 4:
          return this.tr('Very high risk');
        case 5:
          return this.tr('Dangerous');
      }

      return null;
    },
    tooltipContent() {
      switch (this.status) {
        case 0:
          return this.tr('Waiting');

        case 1:
          return this.tr('In progress...');

        case 3:
          return this.tr('Failed');
      }

      switch (this.result) {
        case 0:
          return this.tr('No need to intervene.');
        case 1:
          return this.tr('Expresses dissatisfaction in a polite manner, showing some resentment in opinion.');
        case 2:
          return this.tr('Expresses dissatisfaction in a slightly impolite manner, showing some resentment in opinion.');
        case 3:
          return this.tr('Accuses of lying or uses improper or vulgar language, indicating some use of offensive language.');
        case 4:
          return this.tr('Accuses of lying or uses improper or vulgar language, indicating a significant use of offensive language.');
        case 5:
          return this.tr('Contains words indicating abuse, or uses vulgar language, involves online fraud, or false information. It can be highly offensive, especially related to race or sexual orientation.');
      }

      return null;
    },
  },
};
</script>
