var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "activity-log-component",
      attrs: { "hidable-classes": ["event"] },
    },
    [
      !_vm.isListEmpty || _vm.hasMore
        ? _c(
            "div",
            { staticClass: "events" },
            [
              _vm._l(_vm.logs, function (event, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "event" },
                  [
                    _c("div", { staticClass: "time" }, [
                      _vm.showDate(index)
                        ? _c("div", { staticClass: "date" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formattedDate(event.created_at)) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "vertical-line" }),
                      _c("div", { staticClass: "content-container" }, [
                        _c(
                          "div",
                          { class: _vm.actionIconContainerColor(event) },
                          [_c("i", { class: _vm.actionIconClasses(event) })]
                        ),
                        _vm._v(
                          " " + _vm._s(_vm.formatTime(event.created_at)) + " "
                        ),
                      ]),
                      _c("div", { staticClass: "vertical-line bottom" }),
                    ]),
                    _c("card", {
                      staticClass: "event-card",
                      class: { "with-date": _vm.cardWithDate(index) },
                      attrs: { "header-color": _vm.headerColor(event) },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                event.action === "error"
                                  ? _c("div", { staticClass: "event-title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tr(
                                              "Could not create an ad from this content."
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "action-message" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "icon-container" },
                                          [
                                            _c("i", {
                                              class:
                                                "icon checkmark " +
                                                _vm.headerColor(event),
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "event-title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.loggedActionText(event)
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.getAdFromEvent(event).variation
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "event-variation",
                                              },
                                              [
                                                _vm._v(
                                                  " - v" +
                                                    _vm._s(
                                                      _vm.getAdFromEvent(event)
                                                        .variation
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                              ]
                            },
                            proxy: true,
                          },
                          event.created_ad?.created_with_ai
                            ? {
                                key: "header-decorator",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "icon magic-stars white",
                                    }),
                                    !_vm.$root.isMobile
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tr("Created with Ai")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              }
                            : null,
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("inline-social-content", {
                                  attrs: {
                                    entity: event.post,
                                    text: _vm.getText(event),
                                  },
                                }),
                                (event.created_ad && event.created_ad.url) ||
                                (event.post && event.post.url)
                                  ? _c(
                                      "div",
                                      { staticClass: "links" },
                                      [
                                        event.created_ad && event.created_ad.url
                                          ? _c(
                                              "link-with-icon",
                                              {
                                                attrs: {
                                                  link: event.created_ad.url,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.tr("Go to created Ad")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        event.post && event.post.url
                                          ? _c(
                                              "link-with-icon",
                                              {
                                                attrs: { link: event.post.url },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.tr(
                                                        "Go to Original Post"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              }),
              _vm.hasMore
                ? _vm._l([1, 2], function (i) {
                    return _c(
                      "div",
                      {
                        key: "activity-log-loader-skeleton--" + i,
                        ref: "loadMore",
                        refInFor: true,
                        staticClass: "event",
                      },
                      [
                        _c("div", { staticClass: "time" }, [
                          i === 1
                            ? _c(
                                "div",
                                { staticClass: "date" },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "100%", height: "20px" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "vertical-line" }),
                          _c(
                            "div",
                            { staticClass: "content-container" },
                            [
                              _c(
                                "div",
                                { staticClass: "icon-container secondary" },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "100%", height: "100%" },
                                  }),
                                ],
                                1
                              ),
                              _c("skeleton", {
                                attrs: { width: "50px", height: "14px" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "vertical-line bottom" }),
                        ]),
                        _c("card", {
                          class: { "event-card": true, "with-date": i === 1 },
                          attrs: { "header-color": "white" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c("skeleton", {
                                      attrs: {
                                        width: "60%",
                                        "max-width": "160px",
                                        height: "12px",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [_c("inline-social-content")]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  })
                : _vm._e(),
            ],
            2
          )
        : _c("empty-screen", {
            attrs: {
              icon: "rocket",
              title: _vm.tr("Your automation history will appear here"),
              "flag-text": _vm.tr("Monitoring active"),
              description: _vm.tr(
                "When your automation starts working, you'll see a chronological list of all activities here, including started and stopped ads, policy checks, and other automated actions."
              ),
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }