<template>
  <div class="rule-view">
    <sticky-header
      :title="rule ? rule.name : tr('Loading...')"
    >
      <template #description>
        <skeleton v-if="isLoading" width="80px" height="15px" />
        <rule-type-flag v-else :action="rule.action" />
        <div v-if="!isLoading && deletedCampaignFlagIsVisible" class="flag red">
          {{ tr('Automation Stopped') }} - {{ tr('Campaign Deleted on Ads Manager') }}
        </div>
        <div v-else-if="!isLoading && inactiveCampaignFlagIsVisible" class="flag orange">
          {{ tr('Inactive Campaign') }}
        </div>
      </template>
      <template #buttons>
        <router-link to="/automation" class="button bordered gray">
          <i class="icon arrow-left" />
          {{ tr('Back') }}
        </router-link>
      </template>

      <form-step-tab :items="tabs" />
    </sticky-header>

    <router-view />
  </div>
</template>

<script>
import FormStepTab from '@/components/FormStepTab.vue';
import RuleTypeFlag from '@/components/RuleTypeFlag.vue';
import Skeleton from '@/components/Skeleton.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import { CAMPAIGN_STATUS } from '@/consts.js';
import RuleService from '@/services/Rule.js';

export default {
  name: 'RuleView',
  components: {
    FormStepTab,
    RuleTypeFlag,
    Skeleton,
    StickyHeader,
  },
  beforeRouteUpdate(to, from, next) {
    next();

    if (to.name === from.name && to.path !== from.path) {
      this.rule = null;
      this.fetch();
    }
  },
  data: () => ({
    rule: null,
    isLoading: true,
  }),
  computed: {
    id() {
      return this.$route.params.id;
    },
    tabs() {
      const tabs = [];

      tabs.push({
        label: this.tr('Overview'),
        link: `/automation/${this.id}/overview`,
      });

      tabs.push({
        label: this.tr('Waiting list'),
        link: `/automation/${this.id}/waiting-list`,
      });

      tabs.push({
        label: this.tr('Edit history'),
        link: `/automation/${this.id}/edit-history`,
      });

      if (this.rule && (this.rule.action === 'start' || this.rule.action === 'scaling_up')) {
        tabs.push({
          label: this.tr('Report'),
          link: `/automation/${this.id}/report`,
        });
      }

      tabs.push({
        label: this.tr('Error log'),
        link: `/automation/${this.id}/error-logs`,
      });

      return tabs;
    },
    deletedCampaignFlagIsVisible() {
      return this.rule.campaign_status === CAMPAIGN_STATUS.DELETED;
    },
    inactiveCampaignFlagIsVisible() {
      return this.rule.campaign_status === CAMPAIGN_STATUS.INACTIVE;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.isLoading = true;

      RuleService.get(this.id)
        .then(response => this.rule = response)
        .catch(error => this.error(error))
        .finally(() => this.isLoading = false);
    },
  },
};
</script>
