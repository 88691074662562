var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-form-view reset-password-view" },
    [
      _c("div", { staticClass: "input-label" }, [
        _c("span", [_vm._v(_vm._s(_vm.tr("E-mail")))]),
      ]),
      _c("input", {
        attrs: { type: "email", disabled: "" },
        domProps: { value: _vm.email },
      }),
      _c("div", { staticClass: "input-label" }, [
        _c("span", [_vm._v(_vm._s(_vm.tr("New password")))]),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newPassword,
            expression: "newPassword",
          },
        ],
        attrs: {
          type: "password",
          placeholder: _vm.tr("Enter at least 8 characters..."),
        },
        domProps: { value: _vm.newPassword },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.newPassword = $event.target.value
          },
        },
      }),
      _c("div", { staticClass: "input-label" }, [
        _c("span", [_vm._v(_vm._s(_vm.tr("New password again")))]),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newPasswordAgain,
            expression: "newPasswordAgain",
          },
        ],
        attrs: {
          type: "password",
          placeholder: _vm.tr("Repeat your password"),
        },
        domProps: { value: _vm.newPasswordAgain },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.newPasswordAgain = $event.target.value
          },
        },
      }),
      _c(
        "button",
        { staticClass: "primary", on: { click: _vm.submit } },
        [
          _vm.isLoading
            ? _c("i", {
                staticClass: "icon loader white",
                attrs: {
                  loading: _vm.isLoading,
                  color: "#ffffff",
                  size: "6px",
                },
              })
            : [_vm._v(" " + _vm._s(_vm.tr("Change")) + " ")],
        ],
        2
      ),
      _c(
        "modal",
        { staticClass: "twofa-modal", attrs: { name: "google-2fa-modal" } },
        [
          _c("google-2fa-code-form", {
            attrs: {
              title: _vm.tr("Two-factor authentication"),
              "cancel-button-label": _vm.tr("Cancel"),
              "is-loading": _vm.isLoading,
              "trust-option": "",
            },
            on: {
              submit: ({ trust }) => _vm.loginWith2fa(trust),
              cancel: () => _vm.$modal.hide("google-2fa-modal"),
            },
            model: {
              value: _vm.code,
              callback: function ($$v) {
                _vm.code = $$v
              },
              expression: "code",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }