var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-error-log-view view" },
    [
      _c("error-logs", {
        attrs: { rule: _vm.$parent.rule, "wait-for-rule": "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }