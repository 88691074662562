<template>
  <modal
    name="invite-member"
    class="invite-member-modal"
    @closed="() => resetForm()"
  >
    <div class="modal-title">
      {{ tr('Invite a teammember') }}
    </div>
    <div class="modal-content">
      <div>
        <label class="input-label">
          {{ tr('Access level') }}
        </label>
        <v-select
          v-model="level"
          :options="userLevels"
          :reduce="(val) => val.value"
          :searchable="false"
          :clearable="false"
          :appendToBody="false"
        />
      </div>

      <div>
        <label class="input-label">
          {{ tr('First name') }}
        </label>
        <input v-model="firstname" type="text" />
      </div>

      <div>
        <label class="input-label">
          {{ tr('Last name') }}
        </label>
        <input v-model="lastname" type="text" />
      </div>

      <div>
        <label class="input-label">
          {{ tr('E-mail') }}
        </label>
        <input v-model="email" type="email" />
      </div>

      <template v-if="level === 'restricted_viewer' || level === 'restricted_editor'">
        <div v-if="$app.isAd">
          <label class="input-label">
            {{ tr('Visible Ad Accounts') }}
          </label>
          <multi-select
            v-model="visibleAdAccountIds"
            :options="adAccounts"
            :append-to-body="false"
            value-key="id"
            label-key="name"
          />
        </div>

        <div>
          <label class="input-label">
            {{ tr('Visible Datasources') }}
          </label>
          <multi-select
            v-model="visibleSourceProfileIds"
            :options="sourceProfiles"
            :append-to-body="false"
            value-key="id"
            label-key="name"
          />
        </div>
      </template>
    </div>
    <div class="modal-action-group">
      <button
        class="secondary"
        @click="() => close()"
      >
        {{ tr('Cancel') }}
      </button>
      <button
        class="primary"
        @click="() => submitForm()"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="icon loader white" />
        <template v-else> {{ tr('Invite') }} </template>
      </button>
    </div>
  </modal>
</template>

<script>
import UserRestrictionsFormMixin from '@/mixins/UserRestrictionsForm.js';

import MultiSelect from '@/components/MultiSelect.vue';

import { isValidEmail } from '@/utils/validators';

export default {
  name: 'InviteMemberMoval',
  mixins: [ UserRestrictionsFormMixin ],
  components: {
    MultiSelect,
  },
  data:() => ({
    email: '',
    firstname: '',
    lastname: '',
    level: 'normal',
    isLoading: false,
  }),
  computed: {
    userLevels() {
      return [
        { label: 'Admin', value: 'admin' },
        { label: 'Normal', value: 'normal' },
        { label: 'Restricted Editor', value: 'restricted_editor' },
        { label: 'Restricted Viewer', value: 'restricted_viewer' },
      ];
    },
  },
  methods: {
    resetForm() {
      this.firstname = '';
      this.lastname = '';
      this.email = '';
      this.level = 'normal';
      this.visibleAdAccountIds = [];
      this.visibleSourceProfileIds = [];
    },
    close() {
      this.$modal.hide('invite-member');
    },
    submitForm() {
      if (!this.validate()) {
        return;
      }

      const data = {
        firstname: this.firstname.trim(),
        lastname: this.lastname.trim(),
        email: this.email,
        level: this.level,
        visibleAdAccountIds: this.visibleAdAccountIds,
        visibleSourceProfileIds: this.visibleSourceProfileIds,
      };

      this.isLoading = true;
      this.$store
        .dispatch('invites/createInvitation', data)
        .then(() => {
          this.close();
          this.$toast.success(this.tr('Invitation has been sent successfully.'));
        })
        .catch((err) => this.error(err))
        .finally(() => this.isLoading = false);
    },
    validate() {
      this.$toast.clear();

      if (!isValidEmail(this.email)) {
        this.$toast.error(this.tr('E-mail provided has an incorrect format!'));
        return false;
      }
      if (this.firstname.trim().length === 0 || this.lastname.trim().length === 0) {
        this.$toast.error(this.tr('Name field cannot be empty!'));
        return false;
      }
      if (this.level !== 'normal' && this.level !== 'admin' && !this.validateRestrictions()) {
        return false;
      }

      return true;
    },
  }
};
</script>
