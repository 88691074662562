var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-no-assets-modal-content-component" },
    [
      _c("top-image-content", [
        _c("img", { attrs: { src: require("@/assets/img/sad.png") } }),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.tr("Ouch..."))),
        ]),
        _c("div", { staticClass: "textual" }, [
          _vm._v(
            " " + _vm._s(_vm.textPart1) + " " + _vm._s(_vm.textPart2) + " "
          ),
        ]),
        _c(
          "button",
          {
            staticClass: "primary",
            on: { click: () => _vm.$modal.hide("has-no-assets-modal") },
          },
          [_vm._v(" " + _vm._s(_vm.tr("Close")) + " ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }