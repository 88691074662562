import BaseEntity from '@/entities/BaseEntity';

export default class FacebookTargetingGeolocationCountry extends BaseEntity
{
  key = null;
  meta = {};

  constructor(rawData) {
    super();

    if (typeof rawData === 'string') {
      rawData = { key: rawData };
    }
    else if ('type' in rawData) {
      delete rawData.type;
    }

    this.updateWith(rawData);
  }

  get type() {
    return 'country';
  }

  toObject() {
    return this.key;
  }
}
