<template>
  <div ref="wrapper" :class="wrapperClasses" @click="(e) => onClickWrapper(e)">
    <template v-if="isOpen || isClosing">
      <create-new-rule-cards v-show="isOpen || isClosing" :class="{ 'closing': isClosing }" />
    </template>

    <button class="floating-create-new-rule-button" @click="() => toggle()">
      <i class="icon plus-slim white" />
    </button>
  </div>
</template>

<script>
import CreateNewRuleCards from '@/components/CreateNewRuleCards.vue';

export default {
  name: 'FloatingCreateNewRuleButton',
  components: {
    CreateNewRuleCards,
  },
  data: () => ({
    isOpen: false,
    isClosing: false,
    isClosingTimer: null,
  }),
  computed: {
    wrapperClasses() {
      return {
        'floating-create-new-rule-button-component': true,
        'is-open': this.isOpen,
        'closing': this.isClosing,
      };
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        document.body.classList.add('disable-scroll');
        document.addEventListener('keydown', this.onKeyDown);
      }
      else {
        document.body.classList.remove('disable-scroll');
        document.removeEventListener('keydown', this.onKeyDown);
      }
    },
  },
  mounted() {
    this.$nextTick(() => this.isMounted = true);
  },
  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      }
      else {
        this.isOpen = true;
      }
    },
    close() {
      if (!this.isOpen) {
        return;
      }

      clearTimeout(this.isClosingTimer);
      this.isClosingTimer = setTimeout(() => this.isClosing = false, 200);
      this.isClosing = true;

      this.isOpen = false;
    },
    onClickWrapper(e) {
      if (!this.isOpen) {
        return;
      }

      if (e.target === this.$refs.wrapper) {
        this.toggle();
      }
    },
    onKeyDown(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
  },
};
</script>
