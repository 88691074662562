<template>
  <div class="post-list-view list-view view">
    <sticky-header :title="tr('Posts')" bordered />

    <form-block
      :description="tr('Here you can see all posts from your connected Facebook pages and Instagram profiles. Click on any post to view its detailed performance metrics. The list shows your content from all connected sources in one unified view.')"
    >
      <div v-if="!isListEmpty || !isEmptyFilters || isLoading" class="actions">
        <input
          v-model="searchTerm"
          type="text"
          class="text icon--search"
          :placeholder="tr('Search') + '...'"
        />
        <v-select
          v-model="sourceProfileId"
          :options="sourceProfileList"
          :reduce="(option) => option.id"
          :placeholder="tr('Select Social Profile')"
          label="name"
          class="source-select"
        >
          <template #option="option">
            <span>
              <i v-if="option.platform" :class="'icon ' + option.platform" />
              {{ option.name }}
            </span>
          </template>
          <template #selected-option="option">
            <span>
              <i v-if="option.platform" :class="'icon ' + option.platform" />
              {{ option.name }}
            </span>
          </template>
        </v-select>
      </div>
    </form-block>

    <template v-if="!isLoading && isListEmpty">
      <empty-screen
        v-if="!isEmptyFilters"
        icon="adjustments"
        :title="tr('No posts match your current filters')"
        :flag-text="tr('Adjust filters')"
        :description="tr('We couldn\'t find any posts that match your selected filters. Try adjusting your filter settings to see more results in your post list.')"
      />
      <empty-screen
        v-else
        icon="posts"
        :title="tr('Analyze your Facbook and Instagram posts with AI.')"
        :flag-text="tr('Connect platform')"
        :description="tr('Connect your Facebook and Instagram accounts to see all your posts here. Once connected, our AI will help you analyze your content and create automated advertising campaigns for better performance.')"
        path="/integrations/meta/facebook-pages"
      />
    </template>

    <sorted-table
      :data="list"
      :initial-sort="{ key: orderBy, direction: order }"
      :style="{ display: !isLoading && isListEmpty ? 'none' : 'block' }"
      class="row-hover-shadow"
      @sort="sortTable"
    >
      <template #header>
        <table-head col="source" width="200px">{{ tr('Source') }}</table-head>
        <table-head
          v-for="(field, key) in availableFields"
          :key="'field-th-' + key"
          :col="field.machine_name"
          :sortable="field.sortable"
          :minWidth="field.machine_name === 'text' ? 'calc(100% - 550px)' : ''"
          :width="field.machine_name === 'text' ? 'calc(100% - 550px)' : ''"
        >
          {{ tr(field.label) }}
        </table-head>
        <table-head col="actions" width="32px" />
      </template>

      <template #default="{ sort }">
        <table-row
          v-for="row in sort"
          :key="row.id"
        >
          <table-data col="source" class="source">
            <social-page
              :source-profile="row.source_profile"
              :date="row.created_at"
            />
          </table-data>
          <table-data
            v-for="(field, key) in availableFields"
            :key="'field-td-' + key + '-' + row.id"
            :col="field.machine_name"
            :class="field.machine_name"
          >
            <template v-if="field.machine_name === 'text'">
              <router-link :to="'/posts/' + row.id">
                <inline-social-content :entity="row" />
              </router-link>
              <div class="divider"></div>
            </template>

            <template v-else-if="field.machine_name === 'ai_value'">
              <label>{{ tr(field.label) }}</label>
              <template v-if="row.ai_value">
                <ai-score :value="row.ai_value" />
              </template>
              <template v-else-if="row.ai_meta_in_progress">
                <i class="icon loader" />
              </template>
              <template v-else>
                -
              </template>
            </template>

            <template v-else-if="field.machine_name === 'created_at'">
              <span v-tooltip="formatToYMD(row.created_at) + ', ' + formatTime(row.created_at)">
                <label>{{ tr(field.label) }}</label>
                {{ timeAgo(row.created_at, tr) }}
              </span>
            </template>

            <template v-else-if="field.machine_name === 'ai_content_actuality'">
              <skeleton v-if="row.contentActualityCheckInProgress" />
              <template v-else-if="row.ai_content_actuality">
                <label>{{ tr(field.label) }}</label>
                <span>{{ formatToYMD(row.ai_content_actuality) }}</span>
                <span>{{ formatTime(row.ai_content_actuality) }}</span>
              </template>
              <template v-else>
                -
              </template>
            </template>

            <template v-else>
              <label>{{ tr(field.label) }}</label>
              {{ formatNumber(row.metric[field.machine_name], field.machine_name === 'ctr' ? '%' : '') }}
            </template>
          </table-data>
          <table-data col="actions">
            <div class="action-group">
              <navigation-arrow :link="'/posts/' + row.id" />
              <button
                v-if="$root.supportHasL2Access"
                class="bordered gray small"
                @click="() => openCommunicatorDebug(row)"
              >
                {{ tr('Debug') }}
              </button>
            </div>
          </table-data>
        </table-row>

        <template v-if="isLoading || hasMore">
          <table-row
            v-for="i in (isListEmpty ? [ 1, 2, 3 ] : [ 1, 2 ])"
            :key="'posts-loader-skeleton--' + i"
            ref="loadMore"
          >
            <table-data col="source" class="source">
              <social-page />
            </table-data>
            <table-data
              v-for="(field, key) in availableFields"
              :key="'field-td-' + key + '-loadmore-' + i"
              :col="field.machine_name"
              :class="field.machine_name"
            >
              <a v-if="field.machine_name === 'text'">
                <inline-social-content />
              </a>

              <skeleton v-else-if="field.machine_name === 'ai_value'" width="40px" />
              <skeleton v-else-if="field.machine_name === 'created_at'" width="80px" />
              <skeleton v-else-if="field.machine_name === 'ai_content_actuality'" width="80px" />
              <skeleton v-else width="40px" />
            </table-data>
            <table-data col="actions">
              <div class="action-group">
                <skeleton
                  width="26px"
                  height="26px"
                  radius="10px"
                />
              </div>
            </table-data>
          </table-row>
        </template>
      </template>
    </sorted-table>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import CommunicatorDebug from '@/components/CommunicatorDebug.vue';
import EmptyScreen from '@/components/EmptyScreen.vue';
import FormBlock from '@/components/FormBlock.vue';
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import PostModalContent from '@/components/ModalContents/PostModalContent.vue';
import NavigationArrow from '@/components/NavigationArrow.vue';
import Skeleton from '@/components/Skeleton.vue';
import SocialPage from '@/components/SocialPage.vue';
import StickyHeader from '@/components/StickyHeader.vue';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import debounce from '@/utils/debounce.js';
import { formatNumber } from '@/utils/number.js';

export default {
  name: 'PostListView',
  components: {
    AiScore,
    EmptyScreen,
    FormBlock,
    InlineSocialContent,
    NavigationArrow,
    Skeleton,
    SocialPage,
    StickyHeader,
  },
  data() {
    return {
      debouncedLoadData: debounce(() => this.loadData(), 500),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['posts/isLoading'];
    },
    hasMore() {
      return this.$store.getters['posts/hasMore'];
    },
    sourceProfileId: {
      get() {
        return this.$store.getters['posts/sourceProfileId'];
      },
      set(value) {
        this.$store.commit('posts/setSourceProfileId', value);
      },
    },
    searchTerm: {
      get() {
        return this.$store.getters['posts/searchTerm'];
      },
      set(value) {
        this.$store.commit('posts/setSearchTerm', value);
      },
    },
    orderBy: {
      get() {
        return this.$store.getters['posts/orderBy'];
      },
      set(value) {
        this.$store.commit('posts/setOrderBy', value);
      },
    },
    order: {
      get() {
        return this.$store.getters['posts/order'];
      },
      set(value) {
        this.$store.commit('posts/setOrder', value);
      },
    },
    list() {
      return this.$store.getters['posts/list'];
    },
    isListEmpty() {
      return this.list.length == 0;
    },
    sourceProfileList() {
      return this.$root.currentCompany.source_profiles;
    },
    isEmptyFilters() {
      return this.searchTerm.trim().length === 0 && !this.sourceProfileId;
    },
    availableFields() {
      const result = [
        { machine_name: 'text', label: 'Content', sortable: true },
        { machine_name: 'ai_value', label: 'Rating', sortable: true },
        { machine_name: 'created_at', label: 'Posted', sortable: true },
        { machine_name: 'ai_content_actuality', label: 'Expiration date', sortable: true },
      ];

      return result;
    },
  },
  watch: {
    searchTerm(val) {
      if (!val || val.trim().length === 0) {
        this.loadData();
      }
      else {
        this.debouncedLoadData();
      }
    },
    sourceProfileId() {
      this.loadData();
    },
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.openModal();
        }
        else {
          this.$fullscreenModal.closeAll();
        }
      },
    },
    '$fullscreenModal.isVisible'(newVal) {
      if (!newVal) {
        this.$router.replace('/posts');
      }
    },
  },
  mounted() {
    if (this.list.length === 0) {
      this.loadData();
    }
    else {
      this.$nextTick(() => this.initInfiniteScroll());
    }
  },
  methods: {
    formatToYMD,
    formatTime,
    formatNumber,
    timeAgo,
    loadData() {
      this.$store.dispatch('posts/resetView', true);
      this.loadMoreData();
    },
    loadMoreData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.$store.dispatch('posts/loadMoreData')
        .catch(err => this.error(err))
        .finally(() => {
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver(entry => {
        if (entry[0].isIntersecting) {
          this.loadMoreData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0].$el);
      }
    },
    sortTable({ key, direction }) {
      this.orderBy = key;
      this.order = direction;
      this.loadData();
    },
    linkOrSpan(field) {
      return field.sortable ? 'sort-link' : 'span';
    },
    isConnected(adAccount) {
      return this.selected.includes(adAccount.id);
    },
    openCommunicatorDebug(post) {
      this.$modal.show(
        CommunicatorDebug,
        { entity: post },
        { class: 'modal-without-padding modal-without-scroll modal-height--640 modal-width--960' },
      );
    },
    openModal() {
      const modal = this.$fullscreenModal.open(
        this,
        PostModalContent,
        {  },
        { closeWithOutclick: false },
      );

      if (modal) {
        modal.$on('closed', () => {
          if (this.$route.path !== '/posts') {
            this.$router.push('/posts');
          }
        });
      }
    },
  },
};
</script>
