import BaseEntity from '@/entities/BaseEntity';

export default class SubscriptionPackage extends BaseEntity
{
  id = null;
  period = '';
  name = '';
  machine_name = '';
  max_users = null;
  max_assets = null;
  max_comments = null;
  price = null;
  extra_asset_price = null;
  extra_comment_price = null;
  feature_managed_account_price = null;
  features = null;
  is_free_trial = false;

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  hasFeature(feature) {
    return Array.isArray(this.features) && this.features.includes(feature);
  }
}
