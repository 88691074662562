<template>
  <div class="rule-form-step rule-form-step--stop rule-form-step--stop--step-1">
    <name-block
      :entity="entity"
      :description="tr('This will be the name of your currently created automation. The name helps identify and easily manage the different automations you have set up.')"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <notifiable-users-block :entity="entity" :disabled="$parent.readonlyMode" />
    <activity-management-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <form-block
      class="ai-assist-block"
      :title="tr('AI Assist') + '<div class=\'beta-flag\'>Beta</div>'"
    >
      <div class="checkbox-list vertical">
        <label>
          <input
            type="checkbox"
            v-model="entity.settings.stop_outdated_ads"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Stop outdated ads') }}

          <info-popup>
            <div v-html="tr('Automatically stop advertisements whose content becomes outdated.')" />
          </info-popup>
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <ad-account-block
      :entity="entity"
      :disabled="$parent.readonlyMode || !entity.isNew"
      :description="tr('You can choose the ad account in which you want to manage your advertisement. (This is useful if you manage multiple accounts.)')"
    />

    <div class="divider" />

    <operation-area-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <post-type-block
      :entity="entity"
      :description="tr('Here you can select what type of posts and appearances should be handled by the automation. Please note that the automation will handle only those post ads that you created using Infinite∞Ad.')"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <form-block
      :title="tr('Filters')"
      :description='tr("You can set different filters to determine the conditions under which the ads should stop or scaling up. You can add multiple conditions to an ad by clicking on the \"+\" sign, and you can connect them with the \"and\" or \"or\" options.")'
      class="filters"
    >
      <condition-group
        v-model="entity.conditions"
        :action="entity.action"
        :platforms="entity.selectedSourcePlatforms"
        :ad-account="entity.selectedAdAccount"
        :disabled="!filterIsAvailable || $parent.readonlyMode"
      />
    </form-block>
  </div>
</template>

<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import ActivityManagementBlock from '@/components/RuleForm/Blocks/ActivityManagementBlock.vue';
import AdAccountBlock from '@/components/RuleForm/Blocks/AdAccountBlock.vue';
import NameBlock from '@/components/RuleForm/Blocks/NameBlock.vue';
import NotifiableUsersBlock from '@/components/RuleForm/Blocks/NotifiableUsersBlock.vue';
import OperationAreaBlock from '@/components/RuleForm/Blocks/OperationAreaBlock.vue';
import PostTypeBlock from '@/components/RuleForm/Blocks/PostTypeBlock.vue';
import ConditionGroup from '@/components/RuleForm/Condition/ConditionGroup.vue';
import InfoPopup from '@/components/InfoPopup.vue'

export default {
  name: 'StopRuleFormStep1',
  mixins: [ RuleFormStepMixin ],
  components: {
    ActivityManagementBlock,
    AdAccountBlock,
    NameBlock,
    NotifiableUsersBlock,
    OperationAreaBlock,
    PostTypeBlock,
    ConditionGroup,
    InfoPopup,
  },
  created() {
    if (this.isNew) {
      this.entity.facebook_post_types = this.facebookPostTypes.map(item => item.machine_name).filter(item => item !== 'reels');
      this.entity.facebook_post_media_types = this.facebookPostMediaTypes.map(item => item.machine_name);
      this.entity.instagram_post_types = this.instagramPostTypes.map(item => item.machine_name);
      this.entity.instagram_post_media_types = this.instagramPostMediaTypes.map(item => item.machine_name);
    }
  },
  computed: {
    facebookPostTypes() {
      return this.$store.getters['app/facebookPostTypes'];
    },
    facebookPostMediaTypes() {
      return this.$store.getters['app/facebookPostMediaTypes'];
    },
    instagramPostTypes() {
      return this.$store.getters['app/instagramPostTypes'];
    },
    instagramPostMediaTypes() {
      return this.$store.getters['app/instagramPostMediaTypes'];
    },
    filterIsAvailable() {
      return this.entity.hasSelectedAdAccount && this.entity.input_campaigns.length !== 0;
    },
  },
  methods:{
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      if (!this.entity.settings.stop_outdated_ads || !this.entity.isEmptyConditions) {
        if (!this.entity.isValidConditions) {
          this.error(this.tr('All Filter fields must be filled in!'));
          return false;
        }
      }

      return true;
    },
  },
};
</script>
