var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    _vm._b(
      { tag: "component", staticClass: "link-with-icon-component" },
      "component",
      _vm.props,
      false
    ),
    [
      _c("i", { class: ["icon primary", _vm.icon] }),
      _c("span", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }