<template>
  <div class="activity-log-component" :hidable-classes="['event']">
    <div v-if="!isListEmpty || hasMore" class="events">
      <div v-for="(event, index) in logs" :key="index" class="event">
        <div class="time">
          <div v-if="showDate(index)" class="date">
            {{ formattedDate(event.created_at) }}
          </div>

          <div class="vertical-line" />

          <div class="content-container">
            <div :class="actionIconContainerColor(event)">
              <i :class="actionIconClasses(event)" />
            </div>
            {{ formatTime(event.created_at) }}
          </div>

          <div class="vertical-line bottom" />
        </div>

        <card
          class="event-card"
          :class="{ 'with-date': cardWithDate(index) }"
          :header-color="headerColor(event)"
          subrow-color="white"
        >
          <template #header>
            <div class="event-title" v-if="event.action === 'error'">
              {{ tr('Could not create an ad from this content.') }}
            </div>
            <div v-else class="action-message">
              <div class="icon-container">
                <i class="icon checkmark blue" />
              </div>
              <div class="event-title">{{ loggedActionText(event) }}</div>
            </div>
          </template>

          <template #default>
            <div class="event-description">
              <lazy-image
                v-if="event.post && event.post.image_url"
                :src="event.post.image_url"
                class="event-image"
              />
              <div class="text">
                {{ getText(event) }}
              </div>
            </div>

            <div class="event-details">
              <div v-if="getAdFromEvent(event)" class="ad-details">
                <div>{{ tr('Ad Platform') }}: {{ ucfirst(getAdFromEvent(event).platform) }}</div>
                <div>{{ tr('Ad ID') }}: {{ getAdFromEvent(event).external_id }}</div>
              </div>

              <div v-if="event.post" class="source-details">
                <div class="source">
                  {{ tr('Source') }}:
                  <source-profile-flag
                    :name="event.post.source_profile.name"
                    :platform="event.post.source_profile.platform"
                  />
                </div>
                <div>{{ tr('Post ID') }}: {{ event.post.external_id }}</div>
              </div>
            </div>

            <router-link
              :to="'/post-copy-analysis/posts/' + event.post.id"
              class="analysis-link"
              v-if="event.post"
            >
              {{ tr('Go to post Analysis') }}
            </router-link>
          </template>

          <!-- <template #subrow-hidable>
            <div class="error-details">
              részletekmajdide
            </div>
          </template> -->
        </card>
      </div>

      <template v-if="hasMore">
        <div
          v-for="i in [ 1, 2 ]"
          :key="'activity-log-loader-skeleton--' + i"
          ref="loadMore"
          class="event"
        >
          <div class="time">
            <div v-if="i === 1" class="date">
              <skeleton width="100%" height="20px" />
            </div>

            <div class="vertical-line" />

            <div class="content-container">
              <div class="icon-container secondary">
                <skeleton width="100%" height="100%" />
              </div>
              <skeleton width="50px" height="14px" />
            </div>

            <div class="vertical-line bottom" />
          </div>

          <card
            :class="{ 'event-card': true, 'with-date': i === 1 }"
            header-color="white"
            subrow-color="white"
          >
            <template #header>
              <skeleton width="60%" max-width="160px" height="12px" />
            </template>

            <template #default>
              <div class="event-description">
                <skeleton class="event-image" />
                <div class="text">
                  <skeleton width="100%" height="16px" />
                  <skeleton width="60%" height="16px" />
                </div>
              </div>

              <div class="event-details">
                <div class="ad-details">
                  <skeleton width="60%" max-width="160px" height="14px" />
                  <skeleton width="60%" max-width="140px" height="14px" />
                  <skeleton width="60%" max-width="200px" height="14px" />
                </div>
              </div>
            </template>

            <template #subrow>
              <skeleton width="120px" height="12px" class="expand-button" />
            </template>
          </card>
        </div>
      </template>
    </div>

    <notification-card v-else emptyScreen>
      {{ tr('No activity logs available') }}
    </notification-card>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import LazyImage from '@/components/LazyImage.vue';
import NotificationCard from '@/components/NotificationCard.vue';
import Skeleton from '@/components/Skeleton.vue';
import SourceProfileFlag from '@/components/SourceProfileFlag.vue';

import RuleActivityLog from '@/entities/RuleActivityLog.js';
import RuleService from '@/services/Rule.js';

import { formatTime, formatToStringMD } from '@/utils/date.js';
import { ucfirst } from '@/utils/string';

const ITEM_PER_PAGE = 10;

export default {
  name: 'ActivityLog',
  props: {
    ruleId: {
      type: String | null,
      default: null,
    },
    limit: {
      type: Number | null,
      default: null,
    },
  },
  components: {
    Card,
    LazyImage,
    NotificationCard,
    Skeleton,
    SourceProfileFlag,
  },
  data: () => ({
    isLoading: false,
    hasMore: true,
    page: 0,
    logs: [],
  }),
  computed: {
    computedIsLoading() {
      return this.isLoading || this.ruleId === null;
    },
    isListEmpty() {
      return this.logs.length === 0;
    },
  },
  watch: {
    ruleId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.refresh();
        }
      },
    }
  },
  methods: {
    ucfirst,
    formatTime,
    loadNewData() {
      if (this.isLoading || !this.hasMore) {
        return;
      }

      this.isLoading = true;
      ++this.page;

      const params = {
        id: this.ruleId,
        page: this.page,
        limit: ITEM_PER_PAGE,
      };

      RuleService.getLogs(params)
        .then((resp) => {
          resp.forEach((item) => this.logs.push(new RuleActivityLog(item)));
          this.hasMore = resp.length === ITEM_PER_PAGE;
        })
        .catch((err) => {
          this.hasMore = false;
          this.error(err);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.hasMore) {
            this.$nextTick(() => this.initInfiniteScroll());
          }
        });
    },
    refresh() {
      this.logs = [];
      this.page = 0;
      this.hasMore = true;
      this.loadNewData();
    },
    getAdFromEvent(event) {
      if (event.created_ad) {
        return event.created_ad;
      }

      return event.ad;
    },
    getText(event) {
      if (event.post) {
        return event.post.text;
      }

      const ad = this.getAdFromEvent(event);
      if (ad) {
        return ad.name;
      }

      return null;
    },
    headerColor(event) {
      switch (event.action) {
        case 'start':
        case 'stop':
        case 'smart_stop':
        case 'scaling_up':
          return 'primary';

        case 'delete':
        case 'error':
          return 'secondary';
      }
    },
    actionIconContainerColor(event) {
      let entityAction = event.action;
      let containerColor = '';

      switch (entityAction) {
        case 'start':
        case 'stop':
        case 'smart_stop':
        case 'scaling_up':
          containerColor = 'primary';
          break;

        case 'delete':
        case 'error':
          containerColor = 'red';
          break;
      }

      return 'icon-container ' + containerColor;
    },
    actionIconClasses(event) {
      let entityAction = event.action;
      let iconType = '';

      switch (entityAction) {
        case 'start':
          iconType = 'play';
          break;

        case 'stop':
        case 'smart_stop':
        case 'delete':
          iconType = 'pause';
          break;

        case 'scaling_up':
          iconType = 'scaling-up';
          break;

        // TODO what is this case for?
        case 'error':
          iconType = 'warning';
          break;
      }

      return 'icon white ' + iconType;
    },
    loggedActionText(event) {
      let entityAction = event.action;
      let actionText = '';

      switch (entityAction) {
        case 'start':
        case 'scaling_up':
          actionText = this.tr('New ad was created');
          break;

        case 'stop':
        case 'smart_stop':
          actionText = this.tr('Your ad was stopped');
          break;

        case 'delete':
          actionText = this.tr('An ad was deleted');
          break;
      }

      return actionText;
    },
    initInfiniteScroll() {
      if (!this.hasMore || !this.$refs.loadMore) {
        return;
      }

      const observer = new IntersectionObserver((entry) => {
        if (entry[0].isIntersecting) {
          this.loadNewData();
          observer.unobserve(entry[0].target);
        }
      });

      if (this.$refs.loadMore[0]) {
        observer.observe(this.$refs.loadMore[0]);
      }
    },
    formattedDate(date) {
      return formatToStringMD(date, this.tr);
    },
    showDate(index) {
      if (index === 0) {
        return true;
      }
      if (index > 0) {
        const currentDate = this.formattedDate(this.logs[index].created_at);
        const previousDate = this.formattedDate(this.logs[index - 1].created_at);
        return currentDate !== previousDate;
      }
      return true;
    },
    cardWithDate(index) {
      return this.showDate(index);
    },
  },
};
</script>
