var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block target-audience-block-component",
      attrs: { title: _vm.tr("Target audience") },
      scopedSlots: _vm._u([
        {
          key: "top-right",
          fn: function () {
            return [
              !_vm.entity.disconnectedAdAccount
                ? _c("refresh-button", {
                    attrs: {
                      label: _vm.tr("Update audiences"),
                      "success-message": _vm.tr(
                        "Saved and custom target audiences updated successfully."
                      ),
                      promise: () => _vm.refreshAudiencesInSelectedAdAccount(),
                    },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.entity.disconnectedAdAccount
        ? _c("info-text", { attrs: { type: "error" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr("The selected ad account has been disconnected.")
                ) +
                " "
            ),
          ])
        : [
            _c("div", {
              staticClass: "description",
              domProps: { innerHTML: _vm._s(_vm.description) },
            }),
            _c("div", [
              _c("label", { staticClass: "input-label" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value:
                        _vm.entity.settings
                          .adset_targeting_advantage_plus_audience,
                      expression:
                        "entity.settings.adset_targeting_advantage_plus_audience",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.entity.settings
                        .adset_targeting_advantage_plus_audience
                    )
                      ? _vm._i(
                          _vm.entity.settings
                            .adset_targeting_advantage_plus_audience,
                          null
                        ) > -1
                      : _vm.entity.settings
                          .adset_targeting_advantage_plus_audience,
                  },
                  on: {
                    change: function ($event) {
                      var $$a =
                          _vm.entity.settings
                            .adset_targeting_advantage_plus_audience,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_targeting_advantage_plus_audience",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_targeting_advantage_plus_audience",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.entity.settings,
                          "adset_targeting_advantage_plus_audience",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.tr("Advantage+ Audience")) + " "),
              ]),
            ]),
            _c(
              "div",
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(
                    " " + _vm._s(_vm.tr("Use saved target audiences")) + " "
                  ),
                ]),
                _c("multiple-audience-select", {
                  attrs: {
                    "external-audiences": _vm.savedAudiences,
                    disabled: _vm.disabled,
                    "is-loading": _vm.isLoading,
                  },
                  model: {
                    value: _vm.entity.settings.adset_targeting_audiences,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.entity.settings,
                        "adset_targeting_audiences",
                        $$v
                      )
                    },
                    expression: "entity.settings.adset_targeting_audiences",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(" " + _vm._s(_vm.tr("Use custom audiences")) + " "),
                ]),
                _c("multi-select", {
                  attrs: {
                    options: _vm.customAudiences,
                    loading: _vm.isLoading,
                    placeholder: _vm.tr("Search existing custom audiences"),
                    disabled: _vm.disabled,
                    color: "#94DA88",
                    "label-key": "name",
                    "value-key": "id",
                  },
                  model: {
                    value: _vm.entity.settings.adset_included_custom_audiences,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.entity.settings,
                        "adset_included_custom_audiences",
                        $$v
                      )
                    },
                    expression:
                      "entity.settings.adset_included_custom_audiences",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(" " + _vm._s(_vm.tr("Exclude")) + " "),
                ]),
                _c("multi-select", {
                  attrs: {
                    options: _vm.customAudiences,
                    loading: _vm.isLoading,
                    placeholder: _vm.tr("Search existing custom audiences"),
                    disabled: _vm.disabled,
                    color: "#FF7272",
                    "label-key": "name",
                    "value-key": "id",
                  },
                  model: {
                    value: _vm.entity.settings.adset_excluded_custom_audiences,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.entity.settings,
                        "adset_excluded_custom_audiences",
                        $$v
                      )
                    },
                    expression:
                      "entity.settings.adset_excluded_custom_audiences",
                  },
                }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }