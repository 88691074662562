<template>
  <div class="rule-waiting-list-item-modal-content">
    <social-page
      :source-profile="entity.post.source_profile"
      :date="entity.created_at"
    />

    <div class="divider" />

    <div class="modal-main-content scroll-wrapper">
      <div>
        <h3>{{ title }}</h3>

        <inline-social-content :entity="entity.ad ?? entity.post" />
      </div>

      <div>
        <h3>{{ tr('Automation') }}</h3>
        <link-with-icon
          :link="'/automation/' + rule.id + '/overview'"
          @click.native="() => $emit('close')"
        >
          {{ rule.name }}
        </link-with-icon>
      </div>

      <div v-if="whyText">
        <h3>{{ whyTitle }}</h3>
        {{ whyText }}
      </div>

      <div v-if="isAiProhibitedItem">
        <h3>{{ tr('Reasoning of Infinite∞Ai') }}</h3>
        {{ entity.reason_translation ?? entity.reason }}
      </div>

      <div v-if="whatToDoText && !entity.isHandled">
        <h3>{{ tr('What can you do?') }}</h3>
        {{ whatToDoText }}
      </div>

      <rule-waiting-list-status
        :rule="rule"
        :entity="entity"
        :disabled="$root.isSupportMode"
      />
    </div>
  </div>
</template>

<script>
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import LinkWithIcon from '@/components/LinkWithIcon.vue';
import RuleWaitingListStatus from '@/components/RuleWaitingListStatus.vue';
import SocialPage from '@/components/SocialPage.vue';
import { RULE_REVIEW_CODE, RULE_REVIEW_FILTER_DEFINITIONS } from '@/consts.js';
import PostAiProhibitedContent from '@/entities/PostAiProhibitedContent.js';
import Rule from '@/entities/Rule.js';
import RuleActivityManagementQueueItem from '@/entities/RuleActivityManagementQueueItem.js';

export default {
  name: 'RuleWaitingListItemModalContent',
  components: {
    InlineSocialContent,
    LinkWithIcon,
    RuleWaitingListStatus,
    SocialPage,
  },
  props: {
    rule: {
      type: Rule,
      required: true,
    },
    entity: {
      type: [ PostAiProhibitedContent, RuleActivityManagementQueueItem ],
      required: true,
    },
  },
  computed: {
    isActivityManagementItem() {
      return this.entity instanceof RuleActivityManagementQueueItem;
    },
    isAiProhibitedItem() {
      return this.entity instanceof PostAiProhibitedContent;
    },
    title() {
      if (this.isActivityManagementItem) {
        const action = this.tr('automation_type_' + this.rule.action);

        if (this.entity.isHandled) {
          if (this.entity.isAccepted) {
            return this.tr('The :action activity was approved!', { ':action': action });
          }

          return this.tr('The :action activity was rejected!', { ':action': action });
        }

        return this.tr('The :action activity is waiting for approval!', { ':action': action });
      }
      else if (this.isAiProhibitedItem) {
        return this.tr('Meta Policy Violation Detected');
      }

      return null;
    },
    whyTitle() {
      if (this.isActivityManagementItem) {
        return this.tr('Why is this activity waiting for approval?');
      }
      else if (this.isAiProhibitedItem) {
        return this.tr('Why was this post flagged?');
      }

      return null;
    },
    whyText() {
      if (this.isActivityManagementItem && !this.entity.isHandled) {
        const definition = RULE_REVIEW_FILTER_DEFINITIONS.find(item => item.code === RULE_REVIEW_CODE.IN_ACTIVITY_MANAGEMENT_QUEUE);
        const text = definition.whyNot[this.rule.action];

        return text ? this.tr(text) : null;
      }
      else if (this.isAiProhibitedItem) {
        const definition = RULE_REVIEW_FILTER_DEFINITIONS.find(item => item.code === RULE_REVIEW_CODE.PROHIBITED_CONTENT);
        const text = definition.whyNot[this.rule.action];

        return text ? this.tr(text) : null;
      }

      return null;
    },
    whatToDoText() {
      if (this.isActivityManagementItem) {
        const definition = RULE_REVIEW_FILTER_DEFINITIONS.find(item => item.code === RULE_REVIEW_CODE.IN_ACTIVITY_MANAGEMENT_QUEUE);
        const text = definition.whatToDo[this.rule.action];

        return text ? this.tr(text) : null;
      }
      else if (this.isAiProhibitedItem) {
        const definition = RULE_REVIEW_FILTER_DEFINITIONS.find(item => item.code === RULE_REVIEW_CODE.PROHIBITED_CONTENT);
        const text = definition.whatToDo[this.rule.action];

        return text ? this.tr(text) : null;
      }

      return null;
    },
  },
  watch: {
    '$route.path'() {
      this.$emit('close');
    },
  },
};
</script>
