var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "magic-tool-form-component",
      attrs: {
        title: _vm.tr("Analyze your ad copy"),
        description: _vm.tr(
          "Insert your ad text and wait for the system to evaluate it. The system evaluates your ad based on several aspects (creativity, attention-grabbing, etc.), and gives a short assessment for each aspect. In addition, you can also create a new version of your ad text generated by the system."
        ),
      },
    },
    [
      _c("v-select", {
        attrs: {
          options: _vm.goalOptionsList,
          reduce: (obj) => obj.machine_name,
          placeholder: _vm.tr("Select a goal for your ad (optional)"),
          clearable: true,
          searchable: false,
        },
        model: {
          value: _vm.goal,
          callback: function ($$v) {
            _vm.goal = $$v
          },
          expression: "goal",
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.title,
            expression: "title",
          },
        ],
        attrs: {
          type: "text",
          placeholder: _vm.tr("Paste your ad title here (optional)"),
        },
        domProps: { value: _vm.title },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.title = $event.target.value
          },
        },
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.description,
            expression: "description",
          },
        ],
        attrs: {
          type: "text",
          placeholder: _vm.tr("Paste your ad description here (optional)"),
        },
        domProps: { value: _vm.description },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.description = $event.target.value
          },
        },
      }),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.text,
            expression: "text",
          },
        ],
        attrs: { placeholder: _vm.tr("Paste your ad copy here") },
        domProps: { value: _vm.text },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.text = $event.target.value
          },
        },
      }),
      _c(
        "button",
        {
          staticClass: "primary",
          attrs: { disabled: _vm.isLoading },
          on: { click: () => _vm.submit() },
        },
        [
          _vm.isLoading
            ? _c("i", { staticClass: "icon loader white" })
            : [_vm._v(" " + _vm._s(_vm.tr("Get copy analysis")) + " ")],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }