import BaseEntity from '@/entities/BaseEntity';

export default class FacebookTargetingGeolocationPlace extends BaseEntity
{
  key = null;
  radius = 2;
  meta = {};

  constructor(rawData) {
    super();

    if ('type' in rawData) {
      delete rawData.type;
    }

    this.updateWith(rawData);
  }

  get type() {
    return 'place';
  }

  get radiusMin() {
    return 1;
  }

  get radiusMax() {
    return 80;
  }
}
