var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "token-request-log",
      attrs: { title: _vm.tr("Access Token Request Log") },
    },
    [
      _c("div", { staticClass: "description" }, [
        _vm._v(
          " " +
            _vm._s(_vm.tr("Access token requests made for")) +
            " " +
            _vm._s(_vm.name) +
            " "
        ),
        _c("div", { staticClass: "entity-info" }, [
          _vm._v(_vm._s(_vm.entityType) + " #" + _vm._s(_vm.entityId)),
        ]),
      ]),
      _c("div", { staticClass: "token-request-table" }, [
        _c("table", [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.tr("Requested At")))]),
              _c("th", [_vm._v(_vm._s(_vm.tr("Support User")))]),
              _c("th", [_vm._v(_vm._s(_vm.tr("Email")))]),
              _c("th", [_vm._v(_vm._s(_vm.tr("Level")))]),
            ]),
          ]),
          _c(
            "tbody",
            [
              _vm._l(_vm.requests, function (request) {
                return _c("tr", { key: request.id }, [
                  _c("td", [
                    _vm._v(_vm._s(_vm.formatDateTime(request.created_at))),
                  ]),
                  _c("td", [
                    _c("div", { staticClass: "user-info" }, [
                      _c("span", { staticClass: "user-name" }, [
                        _vm._v(_vm._s(request.support_user.firstname)),
                      ]),
                      _c("span", { staticClass: "user-id text-secondary" }, [
                        _vm._v("(" + _vm._s(request.support_user.id) + ")"),
                      ]),
                    ]),
                  ]),
                  _c("td", [_vm._v(_vm._s(request.support_user.email))]),
                  _c("td", [_vm._v(_vm._s(request.support_user.level))]),
                ])
              }),
              !_vm.hasMore && _vm.requests.length > 0
                ? _c("tr", [
                    _c(
                      "td",
                      { staticClass: "text-center", attrs: { colspan: "6" } },
                      [_vm._v(" " + _vm._s(_vm.tr("End of log")) + " ")]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm.requests.length === 0 && !_vm.isLoading
          ? _c("div", { staticClass: "empty-state" }, [
              _c("i", { staticClass: "icon diary" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "No access token requests have been made for this entity."
                    )
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _vm.isLoading
          ? _c("div", { staticClass: "loading-state" }, [
              _c("i", { staticClass: "icon loader" }),
            ])
          : _vm._e(),
        _vm.hasMore
          ? _c("div", {
              ref: "observerTarget",
              staticClass: "observer-target",
              staticStyle: { height: "20px", "margin-top": "10px" },
            })
          : _vm._e(),
      ]),
      _c(
        "button",
        {
          staticClass: "button primary close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.tr("Close")) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }