var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass:
        "rule-form-block budget-block-component adset-budget-block-component",
      attrs: { title: _vm.tr("Budget") },
    },
    [
      !_vm.isAvailable
        ? _c("info-text", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr(
                    "You have set a campaign budget, so the budget at ad set level is not available."
                  )
                ) +
                " "
            ),
          ])
        : [
            _c(
              "div",
              [
                _c("label", { staticClass: "input-label" }, [
                  _vm._v(_vm._s(_vm.tr("Ad set budget"))),
                ]),
                _c(
                  "div",
                  { staticClass: "inline-input-wrapper inline-input-select" },
                  [
                    _c("money-input", {
                      attrs: {
                        disabled: _vm.disabled,
                        currency: _vm.entity.adAccountCurrency
                          ? _vm.entity.adAccountCurrency.code
                          : "EUR",
                      },
                      model: {
                        value: _vm.entity.settings.adset_budget,
                        callback: function ($$v) {
                          _vm.$set(_vm.entity.settings, "adset_budget", $$v)
                        },
                        expression: "entity.settings.adset_budget",
                      },
                    }),
                    _c("v-select", {
                      attrs: {
                        options: _vm.budgetPeriods,
                        reduce: (obj) => obj.machine_name,
                        disabled: _vm.disabled || !_vm.entity.isNew,
                        searchable: false,
                        clearable: false,
                      },
                      model: {
                        value: _vm.entity.settings.adset_budget_period,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.entity.settings,
                            "adset_budget_period",
                            $$v
                          )
                        },
                        expression: "entity.settings.adset_budget_period",
                      },
                    }),
                  ],
                  1
                ),
                _vm.budgetPeriod === "lifetime"
                  ? _c("div", { staticClass: "lifetime-budget-date-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "date-time date-time--start" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(_vm._s(_vm.tr("Start date"))),
                          ]),
                          _c("date-time-input", {
                            attrs: {
                              disabled: _vm.disabled || !_vm.entity.isNew,
                            },
                            model: {
                              value: _vm.entity.settings.adset_start_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.entity.settings,
                                  "adset_start_time",
                                  $$v
                                )
                              },
                              expression: "entity.settings.adset_start_time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "date-time date-time--end" },
                        [
                          _c("label", { staticClass: "input-label" }, [
                            _vm._v(_vm._s(_vm.tr("End date"))),
                          ]),
                          _c("date-time-input", {
                            attrs: {
                              disabled: _vm.disabled || !_vm.entity.isNew,
                            },
                            model: {
                              value: _vm.entity.settings.adset_end_time,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.entity.settings,
                                  "adset_end_time",
                                  $$v
                                )
                              },
                              expression: "entity.settings.adset_end_time",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.invalidLifetimeRange
                  ? _c("info-text", { attrs: { type: "error" } }, [
                      _vm._v(
                        " " + _vm._s(_vm.invalidLifetimeRangeInfoText) + " "
                      ),
                    ])
                  : _vm.invalidBudget
                  ? _c("info-text", { attrs: { type: "error" } }, [
                      _vm._v(" " + _vm._s(_vm.invalidBudgetInfoText) + " "),
                    ])
                  : _c(
                      "info-text",
                      {
                        attrs: {
                          icon: _vm.isBudgetRequestRunning ? "loader" : null,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.minBudgetInfoText) + " ")]
                    ),
              ],
              1
            ),
            _vm.budgetPeriod === "lifetime"
              ? _c("div", [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.tr("Ad lifespan (max. days)")) +
                        " " +
                        _vm._s("(" + _vm.tr("optional") + ")") +
                        " "
                    ),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.entity.settings.adset_lifespan_max_days,
                        expression: "entity.settings.adset_lifespan_max_days",
                      },
                    ],
                    staticClass: "max-number",
                    attrs: {
                      type: "number",
                      min: "1",
                      max: _vm.adsetLifespanMaxDaysMaxValue,
                      step: "1",
                      disabled: _vm.disabled,
                    },
                    domProps: {
                      value: _vm.entity.settings.adset_lifespan_max_days,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.entity.settings,
                          "adset_lifespan_max_days",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _c("div", [
              _c("label", { staticClass: "input-label" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.tr("Maximum number of posts promoted at once")) +
                    " " +
                    _vm._s("(" + _vm.tr("optional") + ")") +
                    " "
                ),
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.entity.settings.max_active_ads,
                    expression: "entity.settings.max_active_ads",
                  },
                ],
                staticClass: "max-number",
                attrs: {
                  type: "number",
                  min: "1",
                  step: "1",
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.entity.settings.max_active_ads },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.entity.settings,
                      "max_active_ads",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._t("default"),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }