import BaseEntity from '@/entities/BaseEntity';

import AdvertisableApplication from '@/entities/AdvertisableApplication';
import Customconversion from '@/entities/Customconversion';
import Pixel from '@/entities/Pixel';
import SourceProfile from '@/entities/SourceProfile';
import User from '@/entities/User';

import { AppInstance } from '@/main.js';

export default class AdAccount extends BaseEntity
{
  id = null;
  owner = null;
  platform = null;
  external_id = null;
  name = '';
  account_status = '';
  age = 0;
  currency = null;
  min_daily_budget = null;
  has_payment_method = null;
  advertisable_applications = [];
  customconversions = [];
  pixels = [];
  pages = [];
  instagram_accounts = [];
  deleted_at = null;

  _entityFields = {
    owner: User,
  };

  _entityArrayFields = {
    advertisable_applications: AdvertisableApplication,
    customconversions: Customconversion,
    pixels: Pixel,
    pages: SourceProfile,
    instagram_accounts: SourceProfile,
  };

  constructor(rawData) {
    super();
    if (rawData?.deleted_at) {
      rawData.name = `[Disconnected] ${rawData.name}`;
    }
    this.updateWith(rawData);
  }

  get currencyDetails() {
    return AppInstance.$store.getters['app/currencies'].find(i => i.code === this.currency);
  }
}
