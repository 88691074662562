var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.wrapperClasses },
    [
      !_vm.minimal ? _c("system-level-notifications") : _vm._e(),
      !_vm.minimal
        ? _c(
            "info-text",
            { staticClass: "meta-tech", attrs: { icon: "meta" } },
            [_vm._v(" " + _vm._s(_vm.techProviderText) + " ")]
          )
        : _vm._e(),
      !_vm.minimal
        ? _c("div", { staticClass: "page-header" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.tr("Meta Ad Account Connection"))),
            ]),
            _c("div", { staticClass: "description" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.tr(
                      "Connect your Meta Ad Account to let Infinite∞Ad help you get better results from your advertising budget. Our smart AI system will automatically manage your ads to help you reach more customers while spending less. As an official Meta partner, we ensure your account remains completely safe and protected at all times."
                    )
                  ) +
                  " "
              ),
            ]),
          ])
        : _vm._e(),
      _vm.list.length > 10
        ? _c("div", { staticClass: "actions" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              staticClass: "text icon--search",
              attrs: { type: "text", placeholder: _vm.tr("Search") + "..." },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.list.length === 0
        ? [
            !_vm.hasConnectedProfile
              ? _c("empty-screen", {
                  attrs: {
                    icon: _vm.emptyScreenIcon,
                    title: _vm.tr("Connect your accounts to start automating"),
                    "flag-text": _vm.tr("Connect profiles"),
                    description: _vm.tr(
                      "To begin using our AI-powered automation features, you'll need to connect your Meta Business accounts. Link your Facebook pages, Instagram profiles, and ad accounts to unlock the full potential of automated advertising."
                    ),
                    mobile: _vm.minimal,
                  },
                  on: { click: _vm.handleClick },
                })
              : _c("empty-screen", {
                  attrs: {
                    icon: "arrow-up",
                    title: _vm.tr("Ad account required to start automating"),
                    "flag-text": _vm.tr("Watch tutorial"),
                    description: _vm.tr(
                      "While your Meta profile is connected, we couldn't find any ad accounts linked to it. To use Infinite∞Ad's automation features, you'll need an active ad account. Watch our tutorial video to learn how to create a new ad account or add an existing one to your Meta Business profile in just a few minutes."
                    ),
                    mobile: _vm.minimal,
                    path: _vm.tr("https://www.youtube.com/watch?v=bRPw0X_1MGc"),
                  },
                }),
          ]
        : _vm.filteredList.length === 0
        ? _c("empty-screen", {
            attrs: {
              icon: "search",
              title: _vm.tr("No results match your current search criteria"),
              "flag-text": _vm.tr("Modify search"),
              description: _vm.tr(
                "Currently there are no items that match your criteria. The items you're looking for might appear here later, or try different parameters to see currently available items."
              ),
              mobile: _vm.minimal,
            },
          })
        : _c("sorted-table", {
            staticClass: "row-hover-shadow",
            attrs: {
              data: _vm.filteredList,
              "initial-sort": { key: "name", direction: "asc" },
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      {
                        staticClass: "name-cell",
                        attrs: { col: "name", sortable: "" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Name")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "status-cell",
                        attrs: { col: "is_active", width: "100px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Status")))]
                    ),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          {
                            staticClass: "external-id-cell",
                            attrs: {
                              col: "external_id",
                              width: "180px",
                              sortable: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.tr("Account ID")) + " ")]
                        )
                      : _vm._e(),
                    !_vm.minimal
                      ? _c(
                          "table-head",
                          { attrs: { col: "connected_by", width: "180px" } },
                          [_vm._v(_vm._s(_vm.tr("Connected by")))]
                        )
                      : _vm._e(),
                    !_vm.minimal && _vm.$app.isAd && _vm.$root.isSupportMode
                      ? _c("table-head", {
                          attrs: { col: "actions", "max-width": "100px" },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return _vm._l(sort, function (row) {
                    return _c(
                      "table-row",
                      {
                        key: row.external_id,
                        class: { active: _vm.selected.includes(row.id) },
                      },
                      [
                        _c(
                          "table-data",
                          { staticClass: "name-cell", attrs: { col: "name" } },
                          [
                            _c("label", [
                              _vm.checkboxIsDisabled(row)
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: _vm.tr("Not allowed"),
                                        expression: "tr('Not allowed')",
                                        modifiers: { right: true },
                                      },
                                    ],
                                    attrs: { type: "checkbox", disabled: "" },
                                  })
                                : _vm.loadingId === row.external_id
                                ? _c("i", { staticClass: "icon loader" })
                                : _c("input", {
                                    attrs: {
                                      type: "checkbox",
                                      disabled:
                                        !!_vm.loadingId ||
                                        !_vm.isModifiable(row),
                                    },
                                    domProps: {
                                      checked: _vm.selected.includes(row.id),
                                    },
                                    on: {
                                      change: ($event) =>
                                        _vm.checkboxChanged($event, row),
                                    },
                                  }),
                              _c(
                                "div",
                                {
                                  staticClass: "name-wrapper",
                                  class: {
                                    disabled:
                                      row.account_status !== 1 &&
                                      !_vm.isUnsettled(row),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(" " + _vm._s(row.name) + " "),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "table-data",
                          {
                            staticClass: "status-cell",
                            attrs: { col: "is_active" },
                          },
                          [
                            row.account_status != 1 ||
                            _vm.selected.includes(row.id)
                              ? _c("span", { class: _vm.statusClasses(row) }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.statusLabel(row)) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              {
                                staticClass: "external-id-cell",
                                attrs: { col: "external_id" },
                              },
                              [_vm._v(" " + _vm._s(row.external_id) + " ")]
                            )
                          : _vm._e(),
                        !_vm.minimal
                          ? _c(
                              "table-data",
                              { attrs: { col: "connected_by" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selected.includes(row.id) && row.owner
                                        ? row.owner.fullname
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.minimal && _vm.$app.isAd && _vm.$root.isSupportMode
                          ? _c("table-data", { attrs: { col: "actions" } }, [
                              _vm.isConnected(row)
                                ? _c("div", { staticClass: "action-group" }, [
                                    _vm.$root.supportHasL2Access
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "bordered gray small",
                                            on: {
                                              click: () =>
                                                _vm.openCommunicatorDebug(row),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.tr("Debug")) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
      _c("disconnect-confirm-modal", { ref: "disconnectConfirmModal" }),
      _c("connect-unsettled-ad-account-modal", {
        ref: "connectUnsettledAdAccountModal",
        attrs: { "ad-account": _vm.accountInModal },
        on: {
          closed: () => (_vm.accountInModal = null),
          settled: (adAccount) => {
            _vm.accountInModal.status = adAccount.status
            _vm.checkboxChanged(null, adAccount)
          },
        },
      }),
      _c("connect-ad-account-without-payment-method-modal", {
        ref: "connectAdAccountWithoutPaymentMethodModal",
        attrs: { "ad-account": _vm.accountInModal },
        on: {
          closed: () => (_vm.accountInModal = null),
          setted: (adAccount) => {
            _vm.accountInModal.has_payment_method = adAccount.has_payment_method
            _vm.checkboxChanged(null, adAccount)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }