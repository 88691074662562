var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "step-card": true,
        disabled: _vm.isCardDisabled,
        done: _vm.isCardDone,
      },
    },
    [
      _c("div", { staticClass: "counter" }, [_vm._v(_vm._s(_vm.step))]),
      !_vm.isCardDone
        ? _c("div", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.defaultText) },
          })
        : _c("div", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.doneText) },
          }),
      _vm.isCardDone
        ? _c("i", { staticClass: "icon double-checkmark primary" })
        : _c(
            !_vm.isCardDisabled ? "router-link" : "button",
            {
              tag: "component",
              staticClass: "button",
              attrs: {
                to: !_vm.isCardDisabled ? _vm.buttonRoute : null,
                disabled: _vm.isCardDisabled,
              },
            },
            [_vm._v(" + " + _vm._s(_vm.buttonText) + " ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }