var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block source-block-component",
      attrs: {
        title: _vm.tr("Source"),
        description: _vm.tr(
          "Please choose which Facebook and/or Instagram account you want to create ads from. You can only select the ones that are linked to the above ad account in the Meta Business Manager."
        ),
      },
    },
    [
      !_vm.entity.ad_account.id
        ? _c("div", { staticClass: "blocker-text" }, [
            _vm._v(" " + _vm._s(_vm.tr("Please, select an ad account.")) + " "),
          ])
        : _vm.availableSources.length === 0
        ? _c("info-text", { attrs: { type: "error" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.tr(
                    "No data sources are available for the selected advertising account."
                  )
                ) +
                " "
            ),
          ])
        : [
            _vm.entity.isAppPromo
              ? _c("info-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "When advertising an application, only an Instagram account can be used as a source."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c("source-select", {
              attrs: {
                entity: _vm.entity,
                sources: _vm.availableSources,
                disabled: _vm.disabled,
                "disabled-facebook": _vm.entity.isAppPromo,
                limit: _vm.single ? 1 : null,
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }