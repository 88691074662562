<template>
  <component
    :is="component"
    :to="link"
    :class="'color--' + color"
    class="navigation-arrow"
    @click="$emit('click')"
  >
    <i class="icon arrow-right" />
  </component>
</template>

<script>

export default {
  name: 'NavigationArrow',
  props: {
    link: {
      type: [ String, null ],
      required: false,
      default: () => null,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    component() {
      if (this.link) {
        return 'access-handled-router-link';
      }

      return 'div';
    },
  },
};
</script>
