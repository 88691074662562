var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAvailable
    ? _c(
        "form-block",
        {
          staticClass: "rule-form-block conversion-block-component",
          attrs: { title: _vm.tr("Conversion") },
          scopedSlots: _vm._u(
            [
              {
                key: "top-right",
                fn: function () {
                  return [
                    !_vm.entity.disconnectedAdAccount
                      ? _c("refresh-button", {
                          attrs: {
                            label: _vm.tr("Update events"),
                            "success-message": _vm.tr(
                              "Conversion events updated successfully."
                            ),
                            promise: () => _vm.refreshSelectedAdAccount(),
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            990574558
          ),
        },
        [
          _vm.entity.disconnectedAdAccount
            ? _c("info-text", { attrs: { type: "error" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr("The selected ad account has been disconnected.")
                    ) +
                    " "
                ),
              ])
            : [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "checkbox-list vertical" },
                    _vm._l(_vm.conversionLocations, function (location, key) {
                      return _c("label", { key: key + "-conversion-key" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.entity.settings.adset_conversion_location,
                              expression:
                                "entity.settings.adset_conversion_location",
                            },
                          ],
                          attrs: {
                            disabled:
                              _vm.disabled ||
                              _vm.conversionLocationOptionIsDisabled(location),
                            type: "radio",
                            name: "conversion-location-radio",
                          },
                          domProps: {
                            value: location.machine_name,
                            checked: _vm._q(
                              _vm.entity.settings.adset_conversion_location,
                              location.machine_name
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.entity.settings,
                                "adset_conversion_location",
                                location.machine_name
                              )
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.tr(location.label)) + " "),
                      ])
                    }),
                    0
                  ),
                ]),
                _vm.entity.settings.adset_conversion_location === "website"
                  ? [
                      _c("v-select", {
                        attrs: {
                          options: _vm.entity.pixelListForSelect,
                          reduce: (option) => option.value,
                          placeholder: _vm.tr("Select a pixel"),
                          disabled: _vm.disabled,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      "status" in option
                                        ? [
                                            option.status
                                              ? _c("i", {
                                                  staticClass:
                                                    "icon point green",
                                                })
                                              : _c("i", {
                                                  staticClass: "icon point red",
                                                }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.label) + " "),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      "status" in option
                                        ? [
                                            option.status
                                              ? _c("i", {
                                                  staticClass:
                                                    "icon point green",
                                                })
                                              : _c("i", {
                                                  staticClass: "icon point red",
                                                }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.label) + " "),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          542659085
                        ),
                        model: {
                          value: _vm.entity.settings.adset_conversion_pixel_id,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_conversion_pixel_id",
                              $$v
                            )
                          },
                          expression:
                            "entity.settings.adset_conversion_pixel_id",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          options: _vm.conversionEvents,
                          reduce: (obj) => obj.machine_name,
                          placeholder: _vm.tr("Select a conversion"),
                          disabled: _vm.disabled,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      "status" in option
                                        ? [
                                            option.status
                                              ? _c("i", {
                                                  staticClass:
                                                    "icon point green",
                                                })
                                              : _c("i", {
                                                  staticClass: "icon point red",
                                                }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.label) + " "),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selected-option",
                              fn: function (option) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      "status" in option
                                        ? [
                                            option.status
                                              ? _c("i", {
                                                  staticClass:
                                                    "icon point green",
                                                })
                                              : _c("i", {
                                                  staticClass: "icon point red",
                                                }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(option.label) + " "),
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          542659085
                        ),
                        model: {
                          value: _vm.entity.settings.adset_conversion_event,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.entity.settings,
                              "adset_conversion_event",
                              $$v
                            )
                          },
                          expression: "entity.settings.adset_conversion_event",
                        },
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "facebook-link",
                          attrs: {
                            href: "https://business.facebook.com/settings/custom-conversions",
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.tr("Create new custom conversion")))]
                      ),
                    ]
                  : _vm.entity.settings.adset_conversion_location === "app"
                  ? _c("v-select", {
                      attrs: {
                        options: _vm.entity.appList,
                        reduce: (obj) => obj.id,
                        placeholder: _vm.tr("Select an app"),
                        disabled: _vm.disabled,
                        label: "name",
                      },
                      model: {
                        value: _vm.entity.settings.adset_conversion_app_id,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.entity.settings,
                            "adset_conversion_app_id",
                            $$v
                          )
                        },
                        expression: "entity.settings.adset_conversion_app_id",
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }