var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: { content: _vm.tooltipContent, classes: ["textonly"] },
          expression: "{ content: tooltipContent, classes: [ 'textonly' ] }",
        },
      ],
      staticClass: "ai-result-component",
    },
    [
      _vm.status === 1
        ? _c("i", { staticClass: "icon loader" })
        : _vm.status === 3
        ? _c("i", { staticClass: "icon close red" })
        : _vm.status === 2
        ? _c("div", { staticClass: "emoji" }, [_vm._v(_vm._s(_vm.emoji))])
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.label) + " "),
      _vm.status === 2 ? [_vm._v(" (" + _vm._s(_vm.result) + ") ")] : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }