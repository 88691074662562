<template>
  <div class="dev-view">
    <multi-select
      v-model="multiSelectValue"
      :options="testArrayObject"
    />

    <button @click="() => openModal()">open</button>
  </div>
</template>

<script>
import Kecske from '@/components/Demo/Kecske.vue';
import MultiSelect from '@/components/MultiSelect.vue';

export default  {
  name: 'DevView',
  components: {
    MultiSelect,
  },
  data:() => ({
    multiSelectValue: [],
    testArrayObject: [
      {
        label: 'Test 1',
        value: 'test_1',
      },
      {
        label: 'Test 2',
        value: 'test_2',
      },
    ],
  }),
  methods: {
    openModal() {
      this.$fullscreenModal.open(
        this,
        Kecske,
        {
          text: 'Kecske Kecske Kecske Kecske Kecske Kecske Kecske Kecske Kecske Kecske',
        },
        {
          top: 100,
          classes: 'kecske kecske--active',
        },
      );
    },
  },
};
</script>
