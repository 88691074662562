import Api from '@/services/Api';

import ConnectedProfile from '@/entities/ConnectedProfile';

export default {
  signup({ hash, firstname, lastname, email, companyName, timezone, password, newsletter, recaptchaToken }) {
    if (hash) {
      return Api().post('/auth/invitation/' + hash, { password, newsletter });
    }
    else {
      const data = {
        company_name: companyName,
        timezone,
        firstname,
        lastname,
        email,
        password,
        newsletter,
        recaptcha_token: recaptchaToken,
      };

      return Api().post('/auth/signup', data);
    }
  },

  login(email, password, browser, code, trust) {
    let data = {
      email,
      password,
      browser,
    };
    if (code) {
      data.code = code;
    }
    if (trust) {
      data.trust = 1;
    }

    return Api().post('/auth/login', data, { withCredentials: true });
  },

  logout() {
    return Api().delete('/auth/logout');
  },

  forgottenPassword(email) {
    const data = { email };
    return Api().post('/auth/forgotten-password', data);
  },

  resetPassword(entity) {
    const data = {
      email: entity.email,
      password: entity.password,
    };
    return Api().post('/auth/reset-password/' + entity.token, data);
  },

  updatePassword(entity) {
    const data = {
      current_password: entity.oldPassword,
      new_password: entity.newPassword,
    };
    return Api().put('/user/' + entity.id + '/password', data);
  },

  refreshToken() {
    return Api().post('/auth/refresh');
  },

  fetch() {
    return Api().get('/auth/user');
  },

  connectProfile(platform, accessToken) {
    const data = {
      platform,
      access_token: accessToken,
    };

    return Api().post('/auth/connect-profile', data)
      .then((resp) => new ConnectedProfile(resp));
  },

  removeProfile(id) {
    return Api().delete('/auth/connected-profile/' + id);
  },

  revokeProfilePermissions(id) {
    return Api().delete('/auth/connected-profile/' + id + '/permissions');
  },

  enableGoogle2fa() {
    return Api().post('/auth/2fa/google/enable');
  },

  confirmEnableGoogle2fa(secret, code) {
    return Api().post('/auth/2fa/google/enable/confirm', { secret, code });
  },

  disableGoogle2fa(code) {
    return Api().post('/auth/2fa/google/disable', { code });
  },

  invitationValidate(hash) {
    return Api().get('/auth/invitation/' + hash + '/validate');
  },

  createInvitation({ firstname, lastname, email, level, visibleAdAccountIds, visibleSourceProfileIds }) {
    const data = {
      firstname,
      lastname,
      email,
      level,
      visible_ad_account_ids: visibleAdAccountIds,
      visible_source_profile_ids: visibleSourceProfileIds,
    };
    return Api().post('/user-invitation', data);
  },

  deleteInvitation(id) {
    return Api().delete('/user-invitation/' + id);
  },

  resendInvitation(id) {
    return Api().post('/user-invitation/' + id + '/resend');
  },

  fetchInvitations() {
    return Api().get('/user-invitations');
  },
};
