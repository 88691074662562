var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-view" },
    [
      _c(
        "sticky-header",
        {
          attrs: { title: _vm.rule ? _vm.rule.name : _vm.tr("Loading...") },
          scopedSlots: _vm._u([
            {
              key: "description",
              fn: function () {
                return [
                  _vm.isLoading
                    ? _c("skeleton", {
                        attrs: { width: "80px", height: "15px" },
                      })
                    : _c("rule-type-flag", {
                        attrs: { action: _vm.rule.action },
                      }),
                  !_vm.isLoading && _vm.deletedCampaignFlagIsVisible
                    ? _c("div", { staticClass: "flag red" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.tr("Automation Stopped")) +
                            " - " +
                            _vm._s(_vm.tr("Campaign Deleted on Ads Manager")) +
                            " "
                        ),
                      ])
                    : !_vm.isLoading && _vm.inactiveCampaignFlagIsVisible
                    ? _c("div", { staticClass: "flag orange" }, [
                        _vm._v(" " + _vm._s(_vm.tr("Inactive Campaign")) + " "),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "button bordered gray",
                      attrs: { to: "/automation" },
                    },
                    [
                      _c("i", { staticClass: "icon arrow-left" }),
                      _vm._v(" " + _vm._s(_vm.tr("Back")) + " "),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("form-step-tab", { attrs: { items: _vm.tabs } })],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }