var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--start rule-form-step--start--step-3",
    },
    [
      _c("adset-name-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _vm.isFbOdax
        ? _c("conversion-odax-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          })
        : _c("conversion-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          }),
      _c("div", { staticClass: "divider" }),
      _c("target-audience-block", {
        ref: "targetAudienceBlock",
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c("demographic-target-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode,
          "target-audience-block": _vm.isMounted
            ? _vm.$refs.targetAudienceBlock
            : null,
          "target-audience-block-is-loading": _vm.isMounted
            ? _vm.$refs.targetAudienceBlock.isLoading
            : true,
        },
      }),
      _c("geotarget-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode,
          "target-audience-block": _vm.isMounted
            ? _vm.$refs.targetAudienceBlock
            : null,
          "target-audience-block-is-loading": _vm.isMounted
            ? _vm.$refs.targetAudienceBlock.isLoading
            : true,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _vm.isFbOdax
        ? _c("adset-budget-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          })
        : _c("adset-budget-non-odax-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          }),
      _c("div", { staticClass: "divider" }),
      _vm.isFbOdax
        ? _c("attribution-window-odax-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          })
        : _c("attribution-window-block", {
            attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
          }),
      _c("div", { staticClass: "divider" }),
      _c("dsa-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c("ad-placement-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }