<template>
  <div class="connected-platforms-view">
    <sticky-header :title="tr('Connected platforms')">
      <form-step-tab :items="formStepTabs" />
    </sticky-header>

    <div class="platforms">
      <router-link to="/connected-platforms/meta" class="button primary">
        <i class="icon meta white" />Meta Business
      </router-link>
      <div v-if="$app.isAd" class="button disabled" disabled>
        <i class="icon tiktok white" />TikTok Ads
        <div class="coming-soon">{{ tr('Coming Soon') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue';
import FormStepTab from '@/components/FormStepTab.vue';
export default {
  name: 'ConnectedPlatformsView',
  components: { StickyHeader, FormStepTab },
  computed: {
    formStepTabs() {
      let firstTab = '';
      if (this.$app.isAd) {
        firstTab = this.tr('Platforms connected to Infinite∞Ad');
      }
      else if (this.$app.isBuzz) {
        firstTab = this.tr('Platforms connected to Infinite∞Buzz');
      }

      return [
        firstTab,
      ];
    },
  },
};
</script>
