var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "settings-team-view" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "loader" }, [
            _c("i", { staticClass: "icon loader black" }),
          ])
        : [
            _vm._l(_vm.users, function (user) {
              return _c("div", { key: user.id, staticClass: "user" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "main-details" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(" " + _vm._s(user.fullname) + " "),
                    ]),
                    _c("span", { staticClass: "email" }, [
                      _vm._v(" " + _vm._s(user.email) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _vm.userBeingUpdated === user.id
                        ? _c("i", { staticClass: "icon loader" })
                        : _vm.tmpUserLevels[user.id] === "restricted_viewer" ||
                          _vm.tmpUserLevels[user.id] === "restricted_editor"
                        ? _c("i", {
                            staticClass: "icon edit",
                            on: {
                              click: () =>
                                _vm.$refs.editUserRestrictionsModal.open(
                                  user,
                                  _vm.tmpUserLevels[user.id]
                                ),
                            },
                          })
                        : _vm._e(),
                      _c("v-select", {
                        attrs: {
                          options: _vm.userLevelList,
                          reduce: (val) => val.value,
                          searchable: false,
                          clearable: false,
                          appendToBody: false,
                          disabled:
                            _vm.userBeingUpdated !== null ||
                            _vm.$root.loggedInUser.id === user.id,
                        },
                        on: {
                          "option:selected": () => _vm.onChangeUserLevel(user),
                        },
                        model: {
                          value: _vm.tmpUserLevels[user.id],
                          callback: function ($$v) {
                            _vm.$set(_vm.tmpUserLevels, user.id, $$v)
                          },
                          expression: "tmpUserLevels[user.id]",
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "icon",
                          attrs: {
                            disabled:
                              _vm.userBeingUpdated !== null ||
                              _vm.$root.loggedInUser.id === user.id,
                          },
                          on: {
                            click: () => _vm.openUserDeleteConfirmModal(user),
                          },
                        },
                        [_c("i", { staticClass: "icon delete" })]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            _vm._l(_vm.invites, function (invite) {
              return _c("div", { key: invite.id, staticClass: "user" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "main-details" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            (invite.firstname + " " + invite.lastname).trim()
                          ) +
                          " "
                      ),
                      _c("span", { staticClass: "pending" }, [
                        _vm._v(" - " + _vm._s(_vm.tr("Pending invitation"))),
                      ]),
                    ]),
                    _c("span", { staticClass: "email" }, [
                      _vm._v(" " + _vm._s(invite.email) + " "),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.userLevelList,
                          reduce: (val) => val.value,
                          disabled: "",
                        },
                        model: {
                          value: invite.level,
                          callback: function ($$v) {
                            _vm.$set(invite, "level", $$v)
                          },
                          expression: "invite.level",
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "icon",
                          attrs: {
                            disabled:
                              _vm.resendInvitationId !== null ||
                              _vm.revokeInvitationId !== null,
                          },
                          on: { click: () => _vm.revokeInvitation(invite) },
                        },
                        [
                          _vm.revokeInvitationId === invite.id
                            ? _c("i", { staticClass: "icon loader" })
                            : _c("i", { staticClass: "icon delete" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "small primary",
                      attrs: {
                        disabled:
                          _vm.resendInvitationId !== null ||
                          _vm.revokeInvitationId !== null,
                      },
                      on: { click: () => _vm.resendInvitation(invite) },
                    },
                    [
                      _vm.resendInvitationId === invite.id
                        ? _c("i", { staticClass: "icon loader white" })
                        : [_vm._v(" " + _vm._s(_vm.tr("Resend")) + " ")],
                    ],
                    2
                  ),
                ]),
              ])
            }),
            !_vm.canInviteNewMember
              ? _c("info-text", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.tr(
                          "You cannot invite more members. Switch to a larger subscription plan, or delete another user's access first."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ],
      _c("edit-user-restrictions-modal", { ref: "editUserRestrictionsModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }