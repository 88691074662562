var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-block",
    {
      staticClass: "rule-form-block name-block-component",
      attrs: { title: _vm.tr("Automation name"), description: _vm.description },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.entity.name,
            expression: "entity.name",
          },
        ],
        attrs: { type: "text", disabled: _vm.disabled },
        domProps: { value: _vm.entity.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.entity, "name", $event.target.value)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }