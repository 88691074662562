var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stripe-card-input-component" },
    [
      _vm.stripeLoaded
        ? _c(
            _vm.clientSecret ? "stripe-element-payment" : "stripe-element-card",
            {
              ref: "stripeForm",
              tag: "component",
              attrs: {
                "icon-style": "solid",
                pk: _vm.publishableKey,
                "elements-options": _vm.elementsOptions,
                "create-options": _vm.createOptions,
                "confirm-params": {},
                locale: _vm.$root.language,
                "hide-postal-code": true,
                disabled: _vm.isLoading,
              },
              on: {
                "element-ready": () => _vm.$emit("ready"),
                error: (val) => (_vm.stripeError = val),
                "element-change": (val) => _vm.onElementChange(val),
              },
            }
          )
        : _vm._e(),
      _vm.isLoading || !_vm.stripeLoaded
        ? _c("div", { staticClass: "loading-blocker-layer" }, [
            !_vm.clientSecret
              ? _c("i", { staticClass: "icon loader primary" })
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }