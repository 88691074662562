<template>
  <div class="payment-history-details-component">
    <new-sorted-table
      :data="entity.items"
      class="payment-history-items-table"
    >
      <template #header>
        <table-head col="title" />
        <table-head col="net" width="80px" class="cost">{{ tr('Net') }}</table-head>
        <table-head col="gross" width="80px" class="cost">{{ tr('Gross') }}</table-head>
      </template>

      <template #default="{ sort }">
        <table-row v-for="row in sort" :key="row.id">
          <table-data col="title">
            {{ row.title }}
          </table-data>
          <table-data col="net" class="cost">
            {{ formatPrice(row.net) }}
          </table-data>
          <table-data col="gross" class="cost">
            {{ formatPrice(row.gross) }}
          </table-data>
        </table-row>
      </template>
    </new-sorted-table>

    <div class="total">
      {{ tr('Total net') }}: {{ formatPrice(entity.net) }}<br/>
      {{ tr('Total gross') }}: {{ formatPrice(entity.gross) }}
    </div>
  </div>
</template>

<script>
import PaymentHistory from '@/entities/PaymentHistory';

import { formatPrice } from '@/utils/string';

export default {
  name: 'PaymentHistoryDetails',
  props: {
    entity: {
      required: true,
      type: PaymentHistory,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
