var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "rule-form-step rule-form-step--start rule-form-step--start--step-1",
    },
    [
      _c("name-block", {
        attrs: {
          entity: _vm.entity,
          description: _vm.tr(
            "This will be the name of your currently created automation as well as the name of the campaign. The automation and campaign name will help you identify and easily manage the different automations you've created."
          ),
          disabled: _vm.$parent.readonlyMode,
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        {
          staticClass: "objective",
          attrs: {
            title: _vm.tr("Objective"),
            description: _vm.tr(
              "Here you can choose the specific goal that you want to achieve with your posts, such as increasing brand awareness, driving website traffic, promoting engagement with posts, etc."
            ),
          },
        },
        [
          _c("v-select", {
            attrs: {
              options: _vm.facebookCampaignObjectives,
              reduce: (obj) => obj.machine_name,
              placeholder: _vm.tr("Set an objective for the campaign"),
              disabled: !_vm.entity.isNew,
            },
            model: {
              value: _vm.entity.output_campaign.objective,
              callback: function ($$v) {
                _vm.$set(_vm.entity.output_campaign, "objective", $$v)
              },
              expression: "entity.output_campaign.objective",
            },
          }),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.tr(
                  "You will not be able to change this setting of this automation after the campaign has started."
                )
              )
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "divider" }),
      _c("notifiable-users-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("activity-management-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        {
          staticClass: "ai-assist-block",
          attrs: {
            title: _vm.tr("AI Assist") + "<div class='beta-flag'>Beta</div>",
          },
        },
        [
          _c("div", { staticClass: "checkbox-list vertical" }, [
            _c(
              "label",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.entity.settings.ai_review,
                      expression: "entity.settings.ai_review",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    disabled: _vm.$parent.readonlyMode,
                  },
                  domProps: {
                    checked: Array.isArray(_vm.entity.settings.ai_review)
                      ? _vm._i(_vm.entity.settings.ai_review, null) > -1
                      : _vm.entity.settings.ai_review,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.entity.settings.ai_review,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "ai_review",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "ai_review",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.entity.settings, "ai_review", $$c)
                      }
                    },
                  },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "Checking for compliance with Meta Advertising Standards"
                      )
                    ) +
                    " "
                ),
                _c("info-popup", [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.tr(
                          "Before an advertisement is created from a post, the Infinite∞Ai reviews the textual and visual elements appearing on advertising platforms and prevents advertisements from being made from content that could violate the platform's advertising policies."
                        )
                      ),
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "label",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.entity.settings.dont_create_expired_ads,
                      expression: "entity.settings.dont_create_expired_ads",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    disabled: _vm.$parent.readonlyMode,
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.entity.settings.dont_create_expired_ads
                    )
                      ? _vm._i(
                          _vm.entity.settings.dont_create_expired_ads,
                          null
                        ) > -1
                      : _vm.entity.settings.dont_create_expired_ads,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.entity.settings.dont_create_expired_ads,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "dont_create_expired_ads",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "dont_create_expired_ads",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.entity.settings,
                          "dont_create_expired_ads",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.tr(
                        "Do not create ads from posts with expired content"
                      )
                    ) +
                    " "
                ),
                _c("info-popup", [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.tr(
                          "Infinite∞Ai reviews the content of your posts, and no ads are created for those that have lost their relevance."
                        )
                      ),
                    },
                  }),
                ]),
              ],
              1
            ),
            _c(
              "label",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.entity.settings.create_ai_stop_rule,
                      expression: "entity.settings.create_ai_stop_rule",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    disabled: _vm.$parent.readonlyMode || !_vm.entity.isNew,
                  },
                  domProps: {
                    checked: Array.isArray(
                      _vm.entity.settings.create_ai_stop_rule
                    )
                      ? _vm._i(_vm.entity.settings.create_ai_stop_rule, null) >
                        -1
                      : _vm.entity.settings.create_ai_stop_rule,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.entity.settings.create_ai_stop_rule,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "create_ai_stop_rule",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.entity.settings,
                              "create_ai_stop_rule",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.entity.settings,
                          "create_ai_stop_rule",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.tr("Create Stop automation")) + " "),
                _c("info-popup", [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.tr(
                          "The Stop automation automatically stops advertisements whose content becomes outdated."
                        )
                      ),
                    },
                  }),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "divider" }),
      _c("ad-account-block", {
        attrs: {
          entity: _vm.entity,
          disabled: _vm.$parent.readonlyMode || !_vm.entity.isNew,
          description: _vm.tr(
            "You can choose the ad account in which you want to manage your advertisement. (This is useful if you manage multiple accounts.)"
          ),
        },
      }),
      _c("div", { staticClass: "divider" }),
      _c("source-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c("post-type-block", {
        attrs: { entity: _vm.entity, disabled: _vm.$parent.readonlyMode },
      }),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        {
          staticClass: "filters",
          attrs: {
            title: _vm.tr("Filters"),
            description: _vm.tr(
              'You can set different filters to determine the conditions under which the ad should start. You can add multiple conditions to an ad by clicking on the "+" sign, and you can connect them with "and" or "or" options.'
            ),
          },
        },
        [
          _c("condition-group", {
            attrs: {
              action: _vm.entity.action,
              platforms: _vm.entity.selectedSourcePlatforms,
              "ad-account": _vm.entity.selectedAdAccount,
              "disable-post-metrics": _vm.entity.disablePostMetrics,
              disabled: !_vm.filterIsAvailable || _vm.$parent.readonlyMode,
            },
            model: {
              value: _vm.entity.conditions,
              callback: function ($$v) {
                _vm.$set(_vm.entity, "conditions", $$v)
              },
              expression: "entity.conditions",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "divider" }),
      _c(
        "form-block",
        {
          staticClass: "preview-block",
          attrs: { title: _vm.tr("Filtered items preview") },
        },
        [_c("rule-preview", { attrs: { entity: _vm.entity } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }