var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "facebook-post-preview-component post-preview" },
    [
      _c("div", { staticClass: "top" }, [
        _c(
          "div",
          { staticClass: "profile" },
          [
            _vm.post && _vm.post.source_profile.picture
              ? _c("lazy-image", {
                  staticClass: "image",
                  attrs: { src: _vm.post.source_profile.picture },
                })
              : _vm._e(),
            _c("div", { staticClass: "profile-data" }, [
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(_vm.post.source_profile.name) + " "),
              ]),
              _c(
                "a",
                {
                  staticClass: "date",
                  attrs: { href: _vm.post.url, target: "_blank" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.formattedDate(_vm.post.created_at)) +
                      " " +
                      _vm._s(_vm.formatTime(_vm.post.created_at)) +
                      " "
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
        _vm.post.text
          ? _c("div", {
              staticClass: "main-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.post.text.replace(/(?:\r\n|\r|\n)/g, "<br>")
                ),
              },
            })
          : _vm._e(),
      ]),
      _vm.post.attachments
        ? _c(
            "div",
            { staticClass: "attachment", class: _vm.post.media_type },
            [
              _vm.post.attachments[0].media.image &&
              (_vm.post.media_type === "photo" ||
                _vm.post.media_type === "link")
                ? _c("lazy-image", {
                    attrs: { src: _vm.post.attachments[0].media.image.src },
                  })
                : _vm._e(),
              _vm.post.attachments[0].media.source
                ? _c("video", {
                    attrs: {
                      src: _vm.post.attachments[0].media.source,
                      autoplay: "",
                      loop: "",
                      muted: "",
                    },
                    domProps: { muted: true },
                  })
                : _vm._e(),
              _vm.post.media_type === "link"
                ? _c("div", { staticClass: "link-details" }, [
                    _vm.extractedDomain
                      ? _c("div", { staticClass: "domain" }, [
                          _vm._v(" " + _vm._s(_vm.extractedDomain) + " "),
                        ])
                      : _vm._e(),
                    _vm.post.attachments[0].title
                      ? _c("div", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.post.attachments[0].title) + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.post.attachments[0].description
                      ? _c("div", { staticClass: "description" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.post.attachments[0].description) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "social" }, [
        _vm.post.metric.reactions > 0
          ? _c("div", { staticClass: "reactions" }, [
              _vm.post.metric.reactions_like > 0
                ? _c("img", {
                    attrs: { src: require("@/assets/img/icons/fb-like.png") },
                  })
                : _vm._e(),
              _vm.post.metric.reactions_love > 0
                ? _c("img", {
                    attrs: { src: require("@/assets/img/icons/fb-love.png") },
                  })
                : _vm._e(),
              _vm.post.metric.reactions_haha > 0
                ? _c("img", {
                    attrs: { src: require("@/assets/img/icons/fb-haha.png") },
                  })
                : _vm._e(),
              _vm.post.metric.reactions_wow > 0
                ? _c("img", {
                    attrs: { src: require("@/assets/img/icons/fb-wow.png") },
                  })
                : _vm._e(),
              _vm.post.metric.reactions_sad > 0
                ? _c("img", {
                    attrs: { src: require("@/assets/img/icons/fb-sad.png") },
                  })
                : _vm._e(),
              _vm.post.metric.reactions_angry > 0
                ? _c("img", {
                    attrs: { src: require("@/assets/img/icons/fb-angry.png") },
                  })
                : _vm._e(),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.post.metric.reactions) + " "),
              ]),
            ])
          : _vm._e(),
        _vm.commentsStr
          ? _c("div", { staticClass: "comments" }, [
              _vm._v(" " + _vm._s(_vm.commentsStr) + " "),
            ])
          : _vm._e(),
        _vm.shareStr
          ? _c("div", { staticClass: "shares" }, [
              _vm._v(" " + _vm._s(_vm.shareStr) + " "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }