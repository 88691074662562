<template>
  <div :class="wrapperClasses">
    <create-new-rule-card
      v-for="(card, index) in cards"
      :key="'create-new-rule-card--' + index"
      v-bind="card"
      ref="cards"
      @open="onCardOpen"
    />

    <!--
      :color="card.color"
      :icon="card.icon"
      :flag="card.flag ?? null"
      :title="card.title"
      :description="card.description"
      :buttonLabel="card.buttonLabel"
      :path="card.path"
      :image="card.image"
      :image-position="card.imagePosition ?? null"
      -->
  </div>
</template>

<script>
import CreateNewRuleCard from '@/components/CreateNewRuleCard.vue';

export default {
  name: 'CreateNewRuleCards',
  components: {
    CreateNewRuleCard,
  },
  data: () => ({
    windowWidth: window.innerWidth,
  }),
  computed: {
    useLongLabels() {
      return this.windowWidth >= 1024 || this.toggleable;
    },
    toggleable() {
      return this.windowWidth < 640;
    },
    wrapperClasses() {
      return {
        'create-new-rule-cards-component': true,
        'toggleable': this.toggleable,
      };
    },
    cards() {
      return [
        {
          color: 'purple',
          icon: 'magic-stars',
          flag: this.tr('For beginners'),
          title: this.tr('Ai Mode'),
          description: this.tr('Streamlined automation creation for beginners, powered by AI to minimize manual work.'),
          buttonLabel: this.tr('Launch'),
          path: '/automation/new/ai-mode',
          image: require('@/assets/img/new-rule-card/ai.png'),
          flagOnImage: !this.useLongLabels,
          toggleable: this.toggleable,
        },
        {
          color: 'primary',
          icon: 'rocket',
          title: this.tr('Ad Creation'),
          description: this.tr('Continuously create ads from your posts, based on your settings, reducing manual work.'),
          path: '/automation/new/start',
          image: require('@/assets/img/new-rule-card/creation.png'),
          imagePosition: 'right',
          toggleable: this.toggleable,
        },
        {
          color: 'primary--light-1',
          icon: 'scaling-up',
          title: this.tr('Ad Scaling'),
          description: this.tr('Scale your ads to maximize results, following your custom rules and settings, ensuring optimal reach.'),
          path: '/automation/new/scaling_up',
          image: require('@/assets/img/new-rule-card/scaling.png'),
          toggleable: this.toggleable,
        },
        {
          color: 'primary--light-2',
          icon: 'blister',
          title: this.tr('Ad Stop'),
          description: this.tr('Automatically stop ads based on your conditions, optimizing ad spend and campaign performance.'),
          path: '/automation/new/stop',
          image: require('@/assets/img/new-rule-card/stop.png'),
          toggleable: this.toggleable,
        },
      ];
    },
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onCardOpen(openedCard) {
      if (!this.$refs.cards) {
        return;
      }

      this.$refs.cards.forEach(card => {
        if (card !== openedCard) {
          card.close();
        }
      });
    },
  },
};
</script>
