<template>
  <div :class="wrapperClasses" @click="() => open()">
    <div v-if="image" class="image" @click="() => close()">
      <img :src="image" :style="imageStyle" />
    </div>

    <div class="bottom">
      <div class="first-line">
        <i :class="'action-icon icon ' + icon + ' ' + color" />
        <div class="title">{{ title }}</div>
        <div v-if="flag" class="flag">{{ flag }}</div>
      </div>

      <div class="description">{{ description }}</div>

      <div class="spacer" />

      <router-link :to="path" class="button">
        {{ buttonLabel ?? tr('Begin') }}
      </router-link>
    </div>

    <template v-if="toggleable">
      <transition name="fade">
        <div v-show="!isOpen" class="closed-content">
          <i :class="'action-icon icon white ' + icon" />
          <div class="title">{{ title }}</div>

          <div v-if="flag" class="flag">{{ flag }}</div>
        </div>
      </transition>
    </template>

    <i v-if="toggleable" class="toggle-icon icon arrow white" @click="() => close()" />
  </div>
</template>

<script>
export default {
  name: 'CreateNewRuleCard',
  props: {
    color:  {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    flag: {
      type: String | null,
      required: false,
      default: () => null,
    },
    description: {
      type: String,
      required: true,
    },
    imagePosition: {
      type: String,
      default: () => 'center',
    },
    path: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String | null,
      required: false,
      default: () => null,
    },
    image: {
      type: String | null,
      required: false,
      default: () => null,
    },
    flagOnImage: {
      type: Boolean,
      default: false,
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    wrapperClasses() {
      let result = {};
      result['create-new-rule-card-component'] = true;
      result['color--' + this.color] = true;
      result['flag-on-image'] = this.flagOnImage && !this.toggleable;
      result['toggleable'] = this.toggleable;

      if (this.toggleable) {
        result['is-open'] = this.isOpen;
      }

      return result;
    },
    imageStyle() {
      let result = {};
      if (this.imagePosition !== 'center') {
        result['object-position'] = this.imagePosition + ' top';
      }

      return result;
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$emit('open', this);
      }
    },
  },
  methods: {
    open() {
      if (this.toggleable && !this.isOpen) {
        this.isOpen = true;
      }
    },
    close() {
      if (!this.isOpen) {
        return;
      }

      setTimeout(() => this.isOpen = false, 0);
    },
  }
};
</script>
