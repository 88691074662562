var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "post-copy-analysis-magic-tool-view view" },
    [
      _c(
        "div",
        { staticClass: "form-with-preview" },
        [
          _c("magic-tool-form", {
            ref: "form",
            on: { "entity-created": (entity) => _vm.onEntityCreated(entity) },
          }),
          _vm.showPreview
            ? _c("facebook-ad-preview", {
                attrs: {
                  text: _vm.$refs.form.text,
                  "cta-title": _vm.$refs.form.title,
                  "cta-description": _vm.$refs.form.description,
                  "cta-button": _vm.previewCtaButton,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { ref: "scrollHereAfterSubmit", staticClass: "divider" }),
      _c("h3", [_vm._v(_vm._s(_vm.tr("Completed evaluations")))]),
      !_vm.isLoading && _vm.list.length === 0
        ? _c("div", { staticClass: "empty-text" }, [
            _vm._v(
              " " +
                _vm._s(_vm.tr("There are no evaluation requests yet.")) +
                " "
            ),
          ])
        : _c("new-sorted-table", {
            attrs: { data: _vm.list },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      "table-head",
                      { attrs: { col: "post", "min-width": "200px" } },
                      [_vm._v(_vm._s(_vm.tr("Content")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "centered-text",
                        attrs: { col: "ai-score", width: "100px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("AI Score")))]
                    ),
                    _c(
                      "table-head",
                      { attrs: { col: "owner", width: "180px" } },
                      [_vm._v(_vm._s(_vm.tr("Owner")))]
                    ),
                    _c(
                      "table-head",
                      { attrs: { col: "requested", width: "150px" } },
                      [_vm._v(_vm._s(_vm.tr("Requested")))]
                    ),
                    _c(
                      "table-head",
                      { attrs: { col: "expiry-date", width: "150px" } },
                      [_vm._v(_vm._s(_vm.tr("Expiry date")))]
                    ),
                    _c(
                      "table-head",
                      {
                        staticClass: "centered-text",
                        attrs: { col: "policy-check", width: "140px" },
                      },
                      [_vm._v(_vm._s(_vm.tr("Policy check")))]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function ({ sort }) {
                  return [
                    _vm._l(sort, function (item) {
                      return _c(
                        "table-row",
                        {
                          key: item.id,
                          attrs: {
                            "subrow-color": "primary",
                            "subrow-opened-color": "white",
                            "subrow-opener-label": _vm.tr("More information"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "subrow",
                                fn: function () {
                                  return [
                                    item.inProgress
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tr(
                                                  "The content is being analyzed! Please wait..."
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tr(
                                                  "Explore in-depth content analysis metrics and insights"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ],
                                  ]
                                },
                                proxy: true,
                              },
                              !item.inProgress
                                ? {
                                    key: "subrow-hidable",
                                    fn: function () {
                                      return [
                                        _c("magic-tool-item-details", {
                                          attrs: { entity: item },
                                          on: {
                                            "entity-improve-begin": (entity) =>
                                              _vm.onEntityImproveBegin(entity),
                                            "entity-deleted": (entity) =>
                                              _vm.onEntityDeleted(entity),
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "table-data",
                            {
                              staticClass: "post-content",
                              attrs: { col: "post" },
                            },
                            [
                              _c("div", { staticClass: "post-content-text" }, [
                                _vm._v(_vm._s(item.text)),
                              ]),
                            ]
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "col-center",
                              attrs: { col: "ai-score" },
                            },
                            [_c("ai-score", { attrs: { value: item.value } })],
                            1
                          ),
                          _c("table-data", { attrs: { col: "owner" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  item.owner.firstname +
                                    " " +
                                    item.owner.lastname
                                )
                              ),
                            ]),
                          ]),
                          _c("table-data", { attrs: { col: "requested" } }, [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value:
                                      _vm.formatToYMD(item.created_at) +
                                      ", " +
                                      _vm.formatTime(item.created_at),
                                    expression:
                                      "formatToYMD(item.created_at) + ', ' + formatTime(item.created_at)",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timeAgo(item.created_at, _vm.tr)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "table-data",
                            { attrs: { col: "expiry-date" } },
                            [
                              item.contentActualityCheckInProgress
                                ? _c("skeleton", {
                                    attrs: { width: "80px", height: "16px" },
                                  })
                                : item.expiry_date
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatToYMD(item.expiry_date)
                                        )
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.formatTime(item.expiry_date))
                                      ),
                                    ]),
                                  ])
                                : [_vm._v(" - ")],
                            ],
                            2
                          ),
                          _c(
                            "table-data",
                            {
                              staticClass: "policy-check-cell",
                              attrs: { col: "policy-check" },
                            },
                            [
                              item.policyCheckInProgress
                                ? _c("skeleton", {
                                    attrs: { width: "50px", height: "16px" },
                                  })
                                : _c("post-content-policy-check-status", {
                                    attrs: {
                                      value: item.policy_check_is_prohibited,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm.isLoading || _vm.hasMore
                      ? _vm._l([1, 2], function (i) {
                          return _c(
                            "table-row",
                            {
                              key: "magic-tool-loader-skeleton-" + i,
                              ref: "loadMore",
                              refInFor: true,
                              staticClass: "skeleton-row",
                            },
                            [
                              _c(
                                "table-data",
                                {
                                  staticClass: "post-content",
                                  attrs: { col: "post" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "post-content-text" },
                                    [
                                      _c("skeleton", {
                                        attrs: {
                                          width: "100%",
                                          height: "10px",
                                        },
                                      }),
                                      _c("skeleton", {
                                        attrs: { width: "95%", height: "10px" },
                                      }),
                                      _c("skeleton", {
                                        attrs: { width: "90%", height: "10px" },
                                      }),
                                      _c("skeleton", {
                                        attrs: { width: "95%", height: "10px" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "table-data",
                                {
                                  staticClass: "col-center",
                                  attrs: { col: "ai-score" },
                                },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "32px", height: "28px" },
                                  }),
                                ],
                                1
                              ),
                              _c("table-data", { attrs: { col: "owner" } }, [
                                _c(
                                  "span",
                                  [
                                    _c("skeleton", {
                                      attrs: { width: "100px", height: "16px" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "table-data",
                                { attrs: { col: "requested" } },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "100px", height: "16px" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "table-data",
                                { attrs: { col: "expiry-date" } },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "80px", height: "16px" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "table-data",
                                {
                                  staticClass: "policy-check-cell",
                                  attrs: { col: "policy-check" },
                                },
                                [
                                  _c("skeleton", {
                                    attrs: { width: "50px", height: "16px" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }