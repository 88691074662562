<template>
  <router-link v-if="checkAccessByPath(to)" :to="to">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'AccessHandledRouterLink',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkAccessByPath(path) {
      const match = this.$router.matcher.match(path);
      if (!match) {
        return false;
      }

      for (let m of match.matched) {
        if (m.meta && m.meta.accessHandler && !m.meta.accessHandler()) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>
