var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "open-in-browser-component" }, [
    _c("i", { staticClass: "logo-inline" }),
    _c(
      "span",
      { staticClass: "text" },
      [
        _vm.$root.language === "hu"
          ? [
              _vm._v(" Az " + _vm._s(_vm.appName) + " használatához kérjük,"),
              _c("br"),
              _vm._v(" nyisd be az "),
              _c(
                "span",
                {
                  staticClass: "address",
                  on: { click: () => _vm.copyLinkToClipboard() },
                },
                [_vm._v(_vm._s(_vm.domain))]
              ),
              _vm._v(" címet"),
              _c("br"),
              _c("strong", [_vm._v("közvetlenül egy böngészőből")]),
              _vm._v(". "),
            ]
          : [
              _vm._v(" To use " + _vm._s(_vm.appName) + ","),
              _c("br"),
              _vm._v(" please open the "),
              _c(
                "span",
                {
                  staticClass: "address",
                  on: { click: () => _vm.copyLinkToClipboard() },
                },
                [_vm._v(_vm._s(_vm.domain))]
              ),
              _vm._v(" address"),
              _c("br"),
              _c("strong", [_vm._v("directly in a browser")]),
              _vm._v(". "),
            ],
      ],
      2
    ),
    !_vm.isIos
      ? _c(
          "button",
          { staticClass: "primary", on: { click: () => _vm.$emit("open") } },
          [_vm._v(" " + _vm._s(_vm.tr("Open in browser")) + " ")]
        )
      : _c(
          "button",
          {
            staticClass: "primary",
            on: { click: () => _vm.copyLinkToClipboard() },
          },
          [_vm._v(" " + _vm._s(_vm.tr("Copy link to clipboard")) + " ")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }