<template>
  <div class="dashboard-view">
    <sticky-header :title="tr('Dashboard')" bordered>
      <template>
        <div class="links">
          <!--
          <a href="">🚀 {{ tr('Supercharge your workflow with autmated rules') }}</a>
          <a href="">👩‍🏫 {{ tr('Latest case studies') }}</a>
          <a href="">🪄 {{ tr('Tips and tricks to create greate rules') }}</a>
          -->
        </div>
      </template>
    </sticky-header>

    <system-level-notifications space-top />

    <dashboard-ad-view v-if="$app.isAd" />
    <dashboard-buzz-view v-else-if="$app.isBuzz" />
  </div>
</template>

<script>
import StickyHeader from '@/components/StickyHeader.vue';
import SystemLevelNotifications from '@/components/SystemLevelNotifications.vue';

import DashboardAdView from '@/views/DashboardAdView.vue';
import DashboardBuzzView from '@/views/DashboardBuzzView.vue';

export default {
  name: 'DashboardView',
  components: {
    DashboardAdView,
    DashboardBuzzView,
    StickyHeader,
    SystemLevelNotifications,
  },
};
</script>
