import BaseEntity from '@/entities/BaseEntity';

import Post from '@/entities/Post';
import SourceProfile from '@/entities/SourceProfile';
export default class Comment extends BaseEntity
{
  id = '';
  external_id = '';
  source_profile = null;
  post = null;
  message = '';
  is_hidden = false;
  is_hidden_by_ai = false;
  ai_status = 0;
  ai_result = 0;
  author = '';
  created_at = new Date();

  _entityFields = {
    post: Post,
    source_profile: SourceProfile,
  };

  constructor(rawData) {
    super();
    this.updateWith(rawData);
  }

  get aiResultText() {
    switch (this.ai_result) {
      case 0:
        return 'safe';

      case 1:
        return 'low risk';

      case 2:
        return 'medium risk';

      case 3:
        return 'high risk';

      case 4:
        return 'very high risk';

      case 5:
        return 'dangerous';
    }

    return this.ai_result;
  }

  get shortMessage() {
    if (!this.message) {
      return '';
    }

    const lengthLimit = 120;

    let message = this.message.replace(/(?:\r\n|\r|\n)/g, ' ');

    if (message.length < lengthLimit) {
      return message;
    }

    message = message.slice(0, lengthLimit);
    let lastSpaceIndex = message.lastIndexOf(' ');
    if (lastSpaceIndex === -1) {
      lastSpaceIndex = lengthLimit;
    }

    message = this.message.slice(0, lastSpaceIndex);
    message += '...';

    return message;
  }
}
