import ApiError from '@/errors/ApiError';
import ValidationError from '@/errors/ValidationError';

export default {
  methods: {
    error(error, fallbackMessage) {
      // eslint-disable-next-line no-console
      console.error(error);

      let message = this.tr(fallbackMessage ?? 'An unexpected error occurred.');
      if (typeof error === 'string') {
        message = error;
      }
      else if (
        error instanceof ValidationError
        || error instanceof ApiError
        || (error !== null && typeof error === 'object' && 'message' in error)
      ) {
        message = error.message;
      }

      this.showToast('error', message);
    },
    infoToast(message) {
      this.showToast('info', message);
    },
    showToast(type, message) {
      this.$toast.clear();
      this.$toast[type](message);
    },
  },
};
