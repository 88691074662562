var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    {
      tag: "component",
      staticClass: "navigation-arrow",
      class: "color--" + _vm.color,
      attrs: { to: _vm.link },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_c("i", { staticClass: "icon arrow-right" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }