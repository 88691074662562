<template>
  <div :class="wrapperClasses">
    <div v-if="$slots['header']" class="header" :class="headerColor">
      <slot name="header" />
      <div v-if="$slots['header-decorator']" :class="'header-decorator ' + headerDecoratorColor">
        <slot name="header-decorator" />
      </div>
    </div>

    <div class="card-content">
      <slot />
    </div>

    <div v-if="$slots['subrow'] || $slots['subrow-hidable']" class="sub-row" :class="backgroundClass">
      <div class="main-text">
        <slot v-if="$slots['subrow']" name="subrow" />
        <div v-else></div>

        <div
          @click="toggleExpand"
          class="expand-button"
          v-if="$slots['subrow-hidable']"
        >
          {{ isExpanded ? tr('Hide') : translatedSubrowOpenerLabel }}
          <i class="icon arrow white" :class="{ expanded: isExpanded }" />
        </div>
      </div>

      <transition-expand v-if="$slots['subrow-hidable']">
        <div v-if="isExpanded" class="hidable-text">
          <slot name="subrow-hidable" />
        </div>
      </transition-expand>
    </div>
  </div>
</template>

<script>
import TransitionExpand from '@/components/TransitionExpand.vue';

export default {
  name: 'TableRow',
  components: {
    TransitionExpand,
  },
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
    headerDecoratorColor: {
      type: String,
      default: 'purple',
    },
    subrowColor: {
      type: String,
      default: 'primary',
    },
    subrowOpenedColor: {
      type: String,
      required: false,
    },
    subrowOpenerLabel: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    wrapperClasses() {
      let result = { 'card': true };

      if (this.$slots['subrow']) {
        result['has-sub-row'] = true;
      }

      return result;
    },
    backgroundClass() {
      return this.isExpanded && this.subrowOpenedColor
        ? this.subrowOpenedColor
        : this.subrowColor;
    },
    translatedSubrowOpenerLabel() {
      return this.subrowOpenerLabel ?? this.tr('More information');
    },
  },
  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
