<template>
  <component :is="component" :to="path" :class="wrapperClasses" @click="($event) => $emit('click', $event)">
    <div class="bottom">
      <div class="first-line">
        <i :class="'action-icon icon ' + icon + ' ' + color" />
        <div class="title">{{ title }}</div>
      </div>

      <div class="description">{{ description }}</div>

      <transition name="fade">
        <navigation-arrow v-show="!disabled" :link="path" :color="navigationArrowColor" />
      </transition>
    </div>
  </component>
</template>

<script>
import NavigationArrow from '@/components/NavigationArrow.vue';

export default {
  name: 'CreateNewRuleCard',
  components: {
    NavigationArrow,
  },
  props: {
    color:  {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: false,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    component() {
      return this.path && !this.disabled ? 'router-link' : 'div';
    },
    wrapperClasses() {
      const result = {};
      result['create-new-rule-card-component'] = true;
      result['color--' + this.color] = true;
      result['disabled'] = this.disabled;
      return result;
    },
    navigationArrowColor() {
      if (this.color === 'tools-gradient') {
        return 'purple';
      }

      return this.color;
    },
  },
};
</script>
