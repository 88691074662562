import Api from '@/services/Api';

import AdAccount from '@/entities/AdAccount.js';

export default {
  connect(platform, id, chargeExtraAsset) {
    const data = {
      platform,
      id,
      charge_extra_assets: chargeExtraAsset,
    };

    return Api().post('/ad-account', data)
      .then(resp => new AdAccount(resp));
  },

  disconnect(id) {
    return Api().delete('/ad-account/' + id);
  },

  refresh(id) {
    return Api().post('/ad-account/' + id + '/refresh')
      .then((resp) => new AdAccount(resp));
  },
};
