<template>
  <div class="rule-form-step rule-form-step--start rule-form-step--start--step-1">
    <name-block
      :entity="entity"
      :description="tr('This will be the name of your currently created automation as well as the name of the campaign. The automation and campaign name will help you identify and easily manage the different automations you\'ve created.')"
      :disabled="$parent.readonlyMode"
    />

    <div class="divider" />

    <form-block
      :title="tr('Objective')"
      :description="tr('Here you can choose the specific goal that you want to achieve with your posts, such as increasing brand awareness, driving website traffic, promoting engagement with posts, etc.')"
      class="objective"
    >
      <v-select
        v-model="entity.output_campaign.objective"
        :options="facebookCampaignObjectives"
        :reduce="(obj) => obj.machine_name"
        :placeholder="tr('Set an objective for the campaign')"
        :disabled="!entity.isNew"
      />
      <span>{{ tr('You will not be able to change this setting of this automation after the campaign has started.') }}</span>

    </form-block>

    <div class="divider" />

    <notifiable-users-block :entity="entity" :disabled="$parent.readonlyMode" />
    <activity-management-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <form-block
      class="ai-assist-block"
      :title="tr('AI Assist') + '<div class=\'beta-flag\'>Beta</div>'"
    >
      <div class="checkbox-list vertical">
        <label>
          <input
            type="checkbox"
            v-model="entity.settings.ai_review"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Checking for compliance with Meta Advertising Standards') }}

          <info-popup>
            <div v-html="tr('Before an advertisement is created from a post, the Infinite∞Ai reviews the textual and visual elements appearing on advertising platforms and prevents advertisements from being made from content that could violate the platform\'s advertising policies.')" />
          </info-popup>
        </label>

        <label>
          <input
            v-model="entity.settings.dont_create_expired_ads"
            type="checkbox"
            :disabled="$parent.readonlyMode"
          />
          {{ tr('Do not create ads from posts with expired content') }}

          <info-popup>
            <div v-html="tr('Infinite∞Ai reviews the content of your posts, and no ads are created for those that have lost their relevance.')" />
          </info-popup>
        </label>

        <label>
          <input
            v-model="entity.settings.create_ai_stop_rule"
            type="checkbox"
            :disabled="$parent.readonlyMode || !entity.isNew"
          />
          {{ tr('Create Stop automation') }}

          <info-popup>
            <div v-html="tr('The Stop automation automatically stops advertisements whose content becomes outdated.')" />
          </info-popup>
        </label>
      </div>
    </form-block>

    <div class="divider" />

    <ad-account-block
      :entity="entity"
      :disabled="$parent.readonlyMode"
      :description="tr('You can choose the ad account in which you want to manage your advertisement. (This is useful if you manage multiple accounts.)')"
    />

    <div class="divider" />

    <source-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <post-type-block :entity="entity" :disabled="$parent.readonlyMode" />

    <div class="divider" />

    <form-block
      :title="tr('Filters')"
      :description='tr("You can set different filters to determine the conditions under which the ad should start. You can add multiple conditions to an ad by clicking on the \"+\" sign, and you can connect them with \"and\" or \"or\" options.")'
      class="filters"
    >
      <condition-group
        v-model="entity.conditions"
        :action="entity.action"
        :platforms="entity.selectedSourcePlatforms"
        :ad-account="entity.selectedAdAccount"
        :disabled="!filterIsAvailable || $parent.readonlyMode"
      />
    </form-block>

    <div class="divider" />

    <form-block
      :title="tr('Filtered items preview')"
      class="preview-block"
    >
      <rule-preview :entity="entity" />
    </form-block>
  </div>
</template>

<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import RulePreview from '@/components/RulePreview.vue';
import ActivityManagementBlock from '@/components/RuleForm/Blocks/ActivityManagementBlock.vue';
import AdAccountBlock from '@/components/RuleForm/Blocks/AdAccountBlock.vue';
import NameBlock from '@/components/RuleForm/Blocks/NameBlock.vue';
import NotifiableUsersBlock from '@/components/RuleForm/Blocks/NotifiableUsersBlock.vue';
import PostTypeBlock from '@/components/RuleForm/Blocks/PostTypeBlock.vue';
import SourceBlock from '@/components/RuleForm/Blocks/SourceBlock.vue';
import ConditionGroup from '@/components/RuleForm/Condition/ConditionGroup.vue';
import InfoPopup from '@/components/InfoPopup.vue';

export default {
  name: 'StartRuleFormStep1',
  mixins: [ RuleFormStepMixin ],
  components: {
    RulePreview,
    ActivityManagementBlock,
    AdAccountBlock,
    NameBlock,
    NotifiableUsersBlock,
    PostTypeBlock,
    SourceBlock,
    ConditionGroup,
    InfoPopup,
  },
  computed: {
    facebookCampaignObjectives() {
      let conversions = [];

      if (this.isFbOdax) {
        conversions = this.fbOdaxMap.map(item => { return { label: item.label, machine_name: item.machine_name }});
      }
      else {
        conversions = this.$store.getters['app/facebookCampaignObjectives'];
      }

      return conversions;
    },
    hasSelectedSourceProfile() {
      return this.entity.source_profiles.filter(i => i.id).length !== 0;
    },
    filterIsAvailable() {
      return this.entity.hasSelectedAdAccount && this.hasSelectedSourceProfile;
    },
    facebookCampaignBidStrategies() {
      const list = this.$store.getters['app/facebookCampaignBidStrategies'];
      return this.conditionalFilteredList(list);
    },
  },
  watch: {
    'entity.ad_account.id'() {
      this.entity.settings.adset_conversion_pixel_id = null;
    },
    'entity.output_campaign.objective'() {
      this.entity.settings.adset_conversion_location = null;
      this.entity.settings.adset_optimization_goal = null;
      this.entity.settings.adset_attribution_window = null;
      this.entity.settings.adset_conversion_pixel_id = null;
      this.entity.settings.adset_conversion_event = null;
      this.entity.settings.adset_conversion_app_id = null;

      this.$nextTick(() => {
        const bidStrategy = this.entity.output_campaign.bid_strategy;
        if (
          bidStrategy !== null
          && !this.facebookCampaignBidStrategies.map(i => i.machine_name).includes(bidStrategy)
        ) {
          this.entity.output_campaign.bid_strategy = this.facebookCampaignBidStrategies[0].machine_name;
        }
      });
    },
  },
  methods:{
    validate() {
      if (!this.validateBlocks()) {
        return false;
      }

      if (!this.entity.output_campaign.objective) {
        this.error(this.tr('Objective cannot be empty!'));
        return false;
      }

      if (!this.entity.isValidConditions) {
        this.error(this.tr('All Filter fields must be filled in!'));
        return false;
      }

      return true;
    },
  },
};
</script>
