<template>
  <div class="rule-form-step rule-form-step--scaling-up rule-form-step--scaling-up--step-2">
    <campaign-name-block :entity="entity" :disabled="!entity.isNew" />

    <div class="divider" />

    <campaign-budget-block
      :title="tr('Budget')"
      :entity="entity"
      :disabled="$parent.readonlyMode"
      switchable
    />

    <div class="divider" />

    <campaign-spending-limit-block :entity="entity" :disabled="$parent.readonlyMode" />
  </div>
</template>
<script>
import RuleFormStepMixin from '@/mixins/RuleForm/Step.js';

import CampaignBudgetBlock from '@/components/RuleForm/Blocks/CampaignBudgetBlock.vue';
import CampaignNameBlock from '@/components/RuleForm/Blocks/CampaignNameBlock.vue';
import CampaignSpendingLimitBlock from '@/components/RuleForm/Blocks/CampaignSpendingLimitBlock.vue';

export default {
  name: 'ScalingUpRuleFormStep2',
  mixins: [ RuleFormStepMixin ],
  components: {
    CampaignBudgetBlock,
    CampaignNameBlock,
    CampaignSpendingLimitBlock,
  },
};
</script>
