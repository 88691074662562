import NewSortedTable from './SortedTable.vue';
import TableHead from './TableHead.vue';
import TableRow from './TableRow.vue';
import TableData from './TableData.vue';
import Vue from 'vue';

Vue.component('NewSortedTable', NewSortedTable);
Vue.component('TableHead', TableHead);
Vue.component('TableRow', TableRow);
Vue.component('TableData', TableData);
