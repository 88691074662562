<template>
  <div
    ref="headerCell"
    :class="['header-cell', { sortable }]"
    :style="columnStyle"
  >
    <span :style="spanStyle" @click="handleClick">
      <slot />
      <i
        v-if="sortable && isSorted"
        class="icon sort-up"
        :style="{ transform: sortDirection === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)' }"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'TableHead',
  inject: [ 'sortedTable' ],
  props: {
    col: {
      type: String,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    calculatedWidth: 'auto',
  }),
  computed: {
    columnStyle() {
      if (this.width && this.width !== 'fit-content') {
        return {
          width: this.width,
          minWidth: this.width,
          maxWidth: this.width,
        };
      }
      else if (this.width === 'fit-content' && this.calculatedWidth === 'auto') {
        return {
          width: 'fit-content',
          minWidth: this.minWidth,
          maxWidth: this.maxWidth,
        };
      }

      if (this.calculatedWidth === 'auto') {
        return {
          minWidth: this.minWidth,
          maxWidth: this.maxWidth,
        };
      }

      const minWithInt = this.minWidth ? parseInt(this.minWidth.toString().replace('px', '')) : null;
      const maxWidthInt = this.maxWidth ? parseInt(this.maxWidth.toString().replace('px', '')) : null;

      let width = this.width ?? this.calculatedWidth;
      if (this.width === 'fit-content') {
        width = this.calculatedWidth;
      }
      const widthInt = width ? parseInt(width.toString().replace('px', '')) : null;

      if (minWithInt && widthInt < minWithInt) {
        width = minWithInt + 'px';
      }
      else if (maxWidthInt && widthInt > maxWidthInt) {
        width = maxWidthInt;
      }

      return {
        minWidth: width,
        maxWidth: width,
        width,
      };
    },
    spanStyle() {
      if (this.width === 'fit-content' && this.calculatedWidth === 'auto') {
        return {
          whiteSpace: 'nowrap',
          paddingRight: '20px',
        };
      }

      return null;
    },
    isSorted() {
      return this.sortedTable.currentSort.key === this.col;
    },
    sortDirection() {
      return this.sortedTable.currentSort.direction;
    },
  },
  created() {
    this.sortedTable.registerColumn(this);
  },
  beforeDestroy() {
    this.sortedTable.unregisterColumn(this);
  },
  methods: {
    handleClick() {
      if (this.sortable) {
        this.sortedTable.handleSort(this.col);
      }
    },
    setWidth(value) {
      this.calculatedWidth = value;
    },
  },
};
</script>
