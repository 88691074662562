<template>
  <div style="font-size: 17px; font-weight: 800;">
    {{ text }}

    <button @click="() => openModal()">Open new modal</button>
    <button @click="() => $emit('close')">Close</button>
    <button @click="() => $emit('close-all')">Close all</button>
  </div>
</template>

<script>
import Kecske from '@/components/Demo/Kecske.vue';

export default {
  name: 'Kecske2View',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$fullscreenModal.open(
        this,
        Kecske,
        {
          text: 'Kecske Kecske Kecske Kecske Kecske Kecske Kecske Kecske Kecske Kecske',
        },
        {
          top: 100,
          classes: 'kecske kecske--active',
        },
      );
    },
  },
};
</script>
