import Vue from 'vue';
import Vuex from 'vuex';

import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import invites from '@/store/modules/invites';
import postCopyAnalysis from '@/store/modules/postCopyAnalysis';
import protectedPosts from '@/store/modules/protectedPosts';
import ruleForm from '@/store/modules/ruleForm';
import rules from '@/store/modules/rules';
import support from '@/store/modules/support';

Vue.use(Vuex);

let modules = {
  app,
  auth,
  invites,
  postCopyAnalysis,
  protectedPosts,
  ruleForm,
  rules,
}

if (process.env.VUE_APP_SUPPORT_BUILD == 1) {
  modules.support = support;
}

export const store = new Vuex.Store({
  modules,
  plugins: [],
});
