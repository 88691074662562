var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "magic-tool-item-delete-modal-content" },
    [
      [
        _c("i", { staticClass: "icon mid delete primary" }),
        _c("h3", [
          _vm._v(
            _vm._s(_vm.tr("Are you sure you want to delete this evaluation?"))
          ),
        ]),
        _c("div", { staticClass: "divider" }),
        _c("div", { staticClass: "scroll-wrapper" }, [
          _c("div", { staticClass: "textual" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.tr(
                    "Warning: This post will be permanently deleted from Facebook. Once deleted, this action cannot be undone and the content cannot be recovered. Please ensure you want to proceed with deleting this post as it will be completely removed from your Facebook page."
                  )
                )
              ),
            ]),
          ]),
          _c(
            "button",
            {
              staticClass: "primary full-width",
              on: { click: () => _vm.deleteItem() },
            },
            [_vm._v(" " + _vm._s(_vm.tr("Confirm")) + " ")]
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }