var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "post-list-view list-view view" },
    [
      _c("sticky-header", { attrs: { title: _vm.tr("Posts"), bordered: "" } }),
      _c(
        "form-block",
        {
          attrs: {
            description: _vm.tr(
              "Here you can see all posts from your connected Facebook pages and Instagram profiles. Click on any post to view its detailed performance metrics. The list shows your content from all connected sources in one unified view."
            ),
          },
        },
        [
          !_vm.isListEmpty || !_vm.isEmptyFilters || _vm.isLoading
            ? _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchTerm,
                        expression: "searchTerm",
                      },
                    ],
                    staticClass: "text icon--search",
                    attrs: {
                      type: "text",
                      placeholder: _vm.tr("Search") + "...",
                    },
                    domProps: { value: _vm.searchTerm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchTerm = $event.target.value
                      },
                    },
                  }),
                  _c("v-select", {
                    staticClass: "source-select",
                    attrs: {
                      options: _vm.sourceProfileList,
                      reduce: (option) => option.id,
                      placeholder: _vm.tr("Select Social Profile"),
                      label: "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function (option) {
                            return [
                              _c("span", [
                                option.platform
                                  ? _c("i", {
                                      class: "icon " + option.platform,
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "selected-option",
                          fn: function (option) {
                            return [
                              _c("span", [
                                option.platform
                                  ? _c("i", {
                                      class: "icon " + option.platform,
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2769159693
                    ),
                    model: {
                      value: _vm.sourceProfileId,
                      callback: function ($$v) {
                        _vm.sourceProfileId = $$v
                      },
                      expression: "sourceProfileId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      !_vm.isLoading && _vm.isListEmpty
        ? [
            !_vm.isEmptyFilters
              ? _c("empty-screen", {
                  attrs: {
                    icon: "adjustments",
                    title: _vm.tr("No posts match your current filters"),
                    "flag-text": _vm.tr("Adjust filters"),
                    description: _vm.tr(
                      "We couldn't find any posts that match your selected filters. Try adjusting your filter settings to see more results in your post list."
                    ),
                  },
                })
              : _c("empty-screen", {
                  attrs: {
                    icon: "posts",
                    title: _vm.tr(
                      "Analyze your Facbook and Instagram posts with AI."
                    ),
                    "flag-text": _vm.tr("Connect platform"),
                    description: _vm.tr(
                      "Connect your Facebook and Instagram accounts to see all your posts here. Once connected, our AI will help you analyze your content and create automated advertising campaigns for better performance."
                    ),
                    path: "/integrations/meta/facebook-pages",
                  },
                }),
          ]
        : _vm._e(),
      _c("sorted-table", {
        staticClass: "row-hover-shadow",
        style: {
          display: !_vm.isLoading && _vm.isListEmpty ? "none" : "block",
        },
        attrs: {
          data: _vm.list,
          "initial-sort": { key: _vm.orderBy, direction: _vm.order },
        },
        on: { sort: _vm.sortTable },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("table-head", { attrs: { col: "source", width: "200px" } }, [
                  _vm._v(_vm._s(_vm.tr("Source"))),
                ]),
                _vm._l(_vm.availableFields, function (field, key) {
                  return _c(
                    "table-head",
                    {
                      key: "field-th-" + key,
                      attrs: {
                        col: field.machine_name,
                        sortable: field.sortable,
                        minWidth:
                          field.machine_name === "text"
                            ? "calc(100% - 550px)"
                            : "",
                        width:
                          field.machine_name === "text"
                            ? "calc(100% - 550px)"
                            : "",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr(field.label)) + " ")]
                  )
                }),
                _c("table-head", { attrs: { col: "actions", width: "32px" } }),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ sort }) {
              return [
                _vm._l(sort, function (row) {
                  return _c(
                    "table-row",
                    { key: row.id },
                    [
                      _c(
                        "table-data",
                        { staticClass: "source", attrs: { col: "source" } },
                        [
                          _c("social-page", {
                            attrs: {
                              "source-profile": row.source_profile,
                              date: row.created_at,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.availableFields, function (field, key) {
                        return _c(
                          "table-data",
                          {
                            key: "field-td-" + key + "-" + row.id,
                            class: field.machine_name,
                            attrs: { col: field.machine_name },
                          },
                          [
                            field.machine_name === "text"
                              ? [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/posts/" + row.id } },
                                    [
                                      _c("inline-social-content", {
                                        attrs: { entity: row },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "divider" }),
                                ]
                              : field.machine_name === "ai_value"
                              ? [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.tr(field.label))),
                                  ]),
                                  row.ai_value
                                    ? [
                                        _c("ai-score", {
                                          attrs: { value: row.ai_value },
                                        }),
                                      ]
                                    : row.ai_meta_in_progress
                                    ? [_c("i", { staticClass: "icon loader" })]
                                    : [_vm._v(" - ")],
                                ]
                              : field.machine_name === "created_at"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            _vm.formatToYMD(row.created_at) +
                                            ", " +
                                            _vm.formatTime(row.created_at),
                                          expression:
                                            "formatToYMD(row.created_at) + ', ' + formatTime(row.created_at)",
                                        },
                                      ],
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.tr(field.label))),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.timeAgo(row.created_at, _vm.tr)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : field.machine_name === "ai_content_actuality"
                              ? [
                                  row.contentActualityCheckInProgress
                                    ? _c("skeleton")
                                    : row.ai_content_actuality
                                    ? [
                                        _c("label", [
                                          _vm._v(_vm._s(_vm.tr(field.label))),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatToYMD(
                                                row.ai_content_actuality
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatTime(
                                                row.ai_content_actuality
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    : [_vm._v(" - ")],
                                ]
                              : [
                                  _c("label", [
                                    _vm._v(_vm._s(_vm.tr(field.label))),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          row.metric[field.machine_name],
                                          field.machine_name === "ctr"
                                            ? "%"
                                            : ""
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      _c("table-data", { attrs: { col: "actions" } }, [
                        _c(
                          "div",
                          { staticClass: "action-group" },
                          [
                            _c("navigation-arrow", {
                              attrs: { link: "/posts/" + row.id },
                            }),
                            _vm.$root.supportHasL2Access
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "bordered gray small",
                                    on: {
                                      click: () =>
                                        _vm.openCommunicatorDebug(row),
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.tr("Debug")) + " ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  )
                }),
                _vm.isLoading || _vm.hasMore
                  ? _vm._l(_vm.isListEmpty ? [1, 2, 3] : [1, 2], function (i) {
                      return _c(
                        "table-row",
                        {
                          key: "posts-loader-skeleton--" + i,
                          ref: "loadMore",
                          refInFor: true,
                        },
                        [
                          _c(
                            "table-data",
                            { staticClass: "source", attrs: { col: "source" } },
                            [_c("social-page")],
                            1
                          ),
                          _vm._l(_vm.availableFields, function (field, key) {
                            return _c(
                              "table-data",
                              {
                                key: "field-td-" + key + "-loadmore-" + i,
                                class: field.machine_name,
                                attrs: { col: field.machine_name },
                              },
                              [
                                field.machine_name === "text"
                                  ? _c("a", [_c("inline-social-content")], 1)
                                  : field.machine_name === "ai_value"
                                  ? _c("skeleton", { attrs: { width: "40px" } })
                                  : field.machine_name === "created_at"
                                  ? _c("skeleton", { attrs: { width: "80px" } })
                                  : field.machine_name ===
                                    "ai_content_actuality"
                                  ? _c("skeleton", { attrs: { width: "80px" } })
                                  : _c("skeleton", {
                                      attrs: { width: "40px" },
                                    }),
                              ],
                              1
                            )
                          }),
                          _c("table-data", { attrs: { col: "actions" } }, [
                            _c(
                              "div",
                              { staticClass: "action-group" },
                              [
                                _c("skeleton", {
                                  attrs: {
                                    width: "26px",
                                    height: "26px",
                                    radius: "10px",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      )
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }