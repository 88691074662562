var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ai-score-component" }, [
    _vm.value !== null && _vm.label
      ? _c("div", [_vm._v(" " + _vm._s(_vm.tr(_vm.label)) + " ")])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "value" },
      [
        _vm.value === null
          ? _c("i", { staticClass: "icon loader white" })
          : [_vm._v(_vm._s(_vm.value.toFixed(1)))],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }