export function parseNumber(value, allowZero) {
  if (value === 'null') {
    value = null;
  }

  if (value !== null) {
    value = parseFloat(value);
    if (
      isNaN(value)
      || (value === 0 && !allowZero)
    ) {
      value = null;
    }
  }

  return value;
}

export function formatNumber(number, suffix, decimals) {
  if (number == null){
    number = 0;
  }
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  number = number.toFixed(decimals ?? 2);
  number = parseFloat(number);
  number = new Intl.NumberFormat('en-US').format(number);
  number = number.replaceAll(',', ' ').replace('.', ',');

  if (number === '0') {
    return '–';
  }

  let result = number;
  if (suffix) {
    result += suffix;
  }

  return result;
}

export function formatPrice(price, currencySymbol, period) {
  if (!price && period) {
    return this.tr('Free');
  }

  price = parseFloat(parseFloat(price).toFixed(2));

  let result = '';
  if (currencySymbol.length === 1) {
    result = currencySymbol + formatNumber(price, null, 2);
  }
  else {
    result = formatNumber(price, null, 2) + ' ' + currencySymbol;
  }

  if (period === 'monthly') {
    result += ' / ' + this.tr('month');
  }
  if (period === 'annual') {
    result += ' / ' + this.tr('year');
  }

  return result;
}

export function formatCompactNumber(number) {
  if (number == null){
    number = 0;
  }
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  let str = number;
  if (str >= 100000) {
    if (str >= 1000000) {
      str = str / 1000000;
    }
    else {
      str = str / 1000;
    }
  }

  let decimals = 2;
  if (str < 10) {
    decimals = 3;
    str = str.toFixed(decimals);
  }
  else if (str < 100) {
    str = str.toFixed(2);
  }
  else if (str < 1000) {
    str = str.toFixed(1);
  }
  else {
    str = str.toFixed(0);
  }

  str = parseFloat(str);

  str = formatNumber(str, null, decimals);

  if (number >= 1000000) {
    str += 'M';
  }
  else if (number >= 100000) {
    str += 'k';
  }

  return str;
}
