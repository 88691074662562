var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "protected-accounts-view list-view" },
    [
      !_vm.isListEmpty
        ? _c("new-sorted-table", {
            attrs: { data: _vm.sourceProfileList },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("table-head", {
                        staticClass: "status",
                        attrs: { col: "status", width: "42px" },
                      }),
                      _c(
                        "table-head",
                        { attrs: { col: "name", minWidth: "150px" } },
                        [_vm._v(" " + _vm._s(_vm.tr("Account")) + " ")]
                      ),
                      _c("table-head", {
                        attrs: { col: "actions", width: "74px" },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function ({ sort }) {
                    return _vm._l(sort, function (sourceProfile) {
                      return _c(
                        "table-row",
                        { key: sourceProfile.id },
                        [
                          _c(
                            "table-data",
                            { staticClass: "status", attrs: { col: "status" } },
                            [
                              _c("switches", {
                                attrs: {
                                  disabled:
                                    _vm.$root.loggedInUser.isRestrictedViewer,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return (() =>
                                      _vm.onChangeModerationStatus(
                                        sourceProfile
                                      )).apply(null, arguments)
                                  },
                                },
                                model: {
                                  value: sourceProfile.comment_moderation,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      sourceProfile,
                                      "comment_moderation",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "sourceProfile.comment_moderation",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "table-data",
                            { staticClass: "name", attrs: { col: "name" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/moderation/post" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "name-with-icon",
                                      on: {
                                        click: () =>
                                          _vm.setSourceProfileFilter(
                                            sourceProfile
                                          ),
                                      },
                                    },
                                    [
                                      _c("i", {
                                        class:
                                          "icon light-black " +
                                          sourceProfile.platform,
                                      }),
                                      _c("div", { staticClass: "name" }, [
                                        _vm._v(_vm._s(sourceProfile.name)),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("table-data", { attrs: { col: "actions" } }, [
                            _c(
                              "div",
                              { staticClass: "action-group" },
                              [
                                !_vm.$root.loggedInUser.isRestrictedViewer
                                  ? _c("refresh-button", {
                                      staticClass: "icon-wrapper",
                                      attrs: {
                                        label: _vm.tr("Update posts"),
                                        "success-message": _vm.tr(
                                          "Posts of :name updated successfully.",
                                          { ":name": sourceProfile.name }
                                        ),
                                        promise: () =>
                                          _vm.refreshSourceProfile(
                                            sourceProfile.id
                                          ),
                                        "color-class": "light-black",
                                        "label-as-tooltip": "",
                                      },
                                    })
                                  : _vm._e(),
                                !_vm.$root.loggedInUser.isRestrictedViewer
                                  ? _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: {
                                              content: _vm.tr("Settings"),
                                              classes: [""],
                                            },
                                            expression:
                                              "{ content: tr('Settings'), classes: [ '' ] }",
                                          },
                                        ],
                                        staticClass: "icon-wrapper",
                                        on: {
                                          click: () =>
                                            _vm.openSourceProfileSettings(
                                              sourceProfile
                                            ),
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon gear light-black",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ],
              null,
              false,
              825759684
            ),
          })
        : _vm._e(),
      _vm.isListEmpty
        ? _c(
            "notification-card",
            { attrs: { "empty-screen": "", "show-image-wrapper": "" } },
            [
              _vm._v(
                " " + _vm._s(_vm.tr("No data sources are connected yet.")) + " "
              ),
              !_vm.$root.loggedInUser.isRestricted
                ? _c(
                    "router-link",
                    {
                      staticClass: "button primary",
                      attrs: { to: "/connected-platforms" },
                    },
                    [_vm._v(" " + _vm._s(_vm.tr("Connect Datasource")) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }