export default {
  watch: {
    'entity.output_campaign.objective'(val, oldVal) {
      const changeFromAppInstall = oldVal === 'OUTCOME_APP_PROMOTION' && val !== oldVal;
      const params = {
        changeFromAppInstall,
      };

      mainProcess(this.entity, params);
    },
    'entity.source_profiles': {
      deep: true,
      handler() {
        mainProcess(this.entity);
      },
    },
    'entity.settings.adset_conversion_event'(val) {
      onChangeConversionEvent(this.entity, val);
    },
    'entity.settings.adset_conversion_app_store_url'(val) {
      this.$nextTick(() => {
        if (this.fbOdaxConversionAppIsVisible) {
          onChangeConversionAppStoreUrl(this.entity, val);
        }
      });
    },
  },
};

function mainProcess(entity, { changeFromAppInstall } = {}) {
  const hasNoSourceProfile = entity.source_profiles.length === 0 || !entity.source_profiles[0].id;
  if (hasNoSourceProfile) {
    return;
  }

  if (changeFromAppInstall) {
    entity.settings.cta_button = 'NO_BUTTON';
    entity.settings.cta_url = null;
  }

  switch (entity.output_campaign.objective) {
    case 'OUTCOME_SALES':
      changeCtaUrl(entity, entity.source_profiles[0].website);
      setTimeout(() => entity.settings.adset_conversion_event = 'PURCHASE', 100);
      // changeCtaButton(entity, 'SHOP_NOW'); - it is done automatically via the onChangeConversionEvent function.
      break;

    case 'OUTCOME_LEADS':
      changeCtaUrl(entity, entity.source_profiles[0].website);
      setTimeout(() => entity.settings.adset_conversion_event = 'SCHEDULE', 100);
      // changeCtaButton(entity, 'LEARN_MORE'); - it is done automatically via the onChangeConversionEvent function.
      break;

    case 'OUTCOME_APP_PROMOTION':
      entity.settings.cta_button = 'USE_APP';
      entity.settings.cta_url = null;
      break;

    default:
      changeCtaButton(entity, 'NO_BUTTON');
      changeCtaUrl(entity, null);
  }
}

function onChangeConversionAppStoreUrl(entity, val) {
  if (val) {
    entity.settings.cta_url = val;

    if (entity.settings.cta_button === 'NO_BUTTON') {
      changeCtaButton(entity, 'USE_APP');
    }
  }
  else {
    changeCtaButton(entity, 'NO_BUTTON');
    changeCtaUrl(entity, null);
  }
}

function onChangeConversionEvent(entity, conversionEvent) {
  if (!conversionEvent) {
    return;
  }

  switch (conversionEvent) {
    case 'PURCHASE':
    case 'ADD_TO_CART':
    case 'ADD_PAYMENT_INFO':
    case 'INITIATED_CHECKOUT':
      changeCtaButton(entity, 'SHOP_NOW');
      break;

    case 'SCHEDULE':
      changeCtaButton(entity, 'LEARN_MORE');
      break;

    case 'START_TRIAL':
      changeCtaButton(entity, 'SIGN_UP');
      break;

    case 'CONTACT':
      changeCtaButton(entity, 'CONTACT_US');
      break;

    case 'DONATE':
      changeCtaButton(entity, 'DONATE');
      break;

    case 'SUBMIT_APPLICATION':
      changeCtaButton(entity, 'APPLY_NOW');
      break;

    default:
      if (
        entity.output_campaign.objective === 'OUTCOME_SALES'
        || entity.output_campaign.objective === 'OUTCOME_LEAD'
      ) {
        changeCtaButton(entity, 'LEARN_MORE');
      }
      else {
        changeCtaButton(entity, 'NO_BUTTON');
      }
  }
}

let autoCtaButton = null;
function changeCtaButton(entity, ctaButton) {
  const ctaButtonSelectedManually = entity.settings.cta_button !== 'NO_BUTTON' && entity.settings.cta_button !== autoCtaButton;

  autoCtaButton = ctaButton;
  if (!ctaButtonSelectedManually) {
    entity.settings.cta_button = ctaButton;
  }
}

let autoCtaUrl = null;
function changeCtaUrl(entity, ctaUrl) {
  const ctaUrlSettedManually = entity.settings.cta_url && entity.settings.cta_url !== autoCtaUrl;

  autoCtaUrl = ctaUrl;
  if (!ctaUrlSettedManually) {
    entity.settings.cta_url = ctaUrl;
  }
}
