<template>
  <div class="facebook-ad-preview-component">
    <div class="top">
      <div class="profile">
        <div class="image">
          <i class="icon infinite white" />
        </div>
        <div class="name">
          {{ name }}
        </div>
      </div>

      <div class="main-text" v-if="text" v-html="text.replace(/(?:\r\n|\r|\n)/g, '<br>')" />
    </div>

    <div class="image" v-if="imgUrl">
      <img :src="imgUrl" alt="Ad Image" />
    </div>

    <div class="cta-section" v-if="ctaTitle || ctaDescription">
      <div class="texts">
        <div class="cta-title" v-if="ctaTitle">{{ ctaTitle }}</div>
        <div class="cta-description" v-if="ctaDescription">{{ ctaDescription }}</div>
      </div>
      <div v-if="ctaButton" class="cta-button">{{ ctaButton }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    imgUrl: {
      type: String,
      required: false
    },
    ctaTitle: {
      type: String | null,
      required: false
    },
    ctaDescription: {
      type: String | null,
      required: false
    },
    ctaButton: {
      type: String | null,
      required: false
    },
  },
  computed: {
    name() {
      return process.env.VUE_APP_HTML_TITLE;
    },
  },
};
</script>

