import ChartTooltip from '@/chart/chartTooltip';
import * as d3 from 'd3';

const marginTop = 6;
const marginRight = 12;
const marginBottom = 16;
const marginLeft = 21 + marginRight;

export default class LineChart
{
  svg = null;
  width = null;
  height = null;
  xScaleType = null;
  yScaleType = null;

  xScale = null;
  yScale = null;

  #dataCollect = [];

  constructor(element, width, height, options = { xScaleType: 'scaleUtc', yScaleType: 'scaleLinear' }) {
    this.width = width;
    this.height = height;
    this.xScaleType = options.xScaleType;
    this.yScaleType = options.yScaleType;

    this.svg = d3.select(element)
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
  }

  addData(details) {
    this.#dataCollect.push(details);
  }

  render() {
    this._renderAxes();

    this.#dataCollect.forEach((details, key) => {
      const line = d3.line()
        .x(d => this.xScale(d[details.xKey]))
        .y(d => this.yScale(d[details.yKey]))
        .curve(d3.curveCatmullRom.alpha(0));

      const shadowFilterId = 'line-shadow-' + key;

      const defs = this.svg.append('defs');
      const filter = defs.append('filter')
        .attr('id', shadowFilterId)
        .attr('x', '-50%')
        .attr('y', '-50%')
        .attr('width', '200%')
        .attr('height', '300%');

      filter.append('feComponentTransfer')
        .attr('in', 'SourceGraphic')
        .attr('result', 'alphaOut')
        .append('feFuncA').attr('type', 'linear').attr('slope', 0.25);


      filter.append('feOffset')
        .attr('in', 'alphaOut')
        .attr('result', 'offOut')
        .attr('dx', '0')
        .attr('dy', '5')

      filter.append('feGaussianBlur')
        .attr('in', 'offOut')
        .attr('result', 'blurOut')
        .attr('stdDeviation', '15')

      filter.append('feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'blurOut')
        .attr('mode', 'normal')

      this.svg.append('path')
        .attr('fill', 'none')
        .attr('stroke', details.color)
        .attr('stroke-width', '3px')
        .attr('stroke-linecap', 'round')
        .attr('d', line(details.data))
        .attr('filter', 'url(#' + shadowFilterId + ')')
    });

    this.#dataCollect.forEach(details => {
      if (details.tooltip instanceof ChartTooltip) {
        details.tooltip.color = details.color;
        details.tooltip.bisect = d3.bisector(d => d[details.xKey]).center;
      }
    });

    this.svg
      .on('pointerenter pointermove', (event) => this._onPointerMoved(event))
      .on('pointerleave', (event) => this._onPointerLeft(event));
  }

  _onPointerMoved(event) {
    this.#dataCollect.forEach(details => {
      details.updateDynamicDetails(event, this.xScale, this.yScale);
    });
  }

  _onPointerLeft() {
    this.#dataCollect.forEach(details => {
      details.hideOnHoverDetails();
    });
  }

  _renderAxes() {
    let xValues = [];
    let yValues = [];

    let mergedData = [];

    this.#dataCollect.forEach(details => {
      let transformedData = details.data.map(i => {
        return { x: i[details.xKey], y: i[details.yKey] };
      })
      mergedData.push( ...transformedData );


      xValues.push( ...details.data.map(i => i[details.xKey]) );
      yValues.push( ...details.data.map(i => i[details.yKey]) );
    });

    this.xScale = d3[this.xScaleType](d3.extent(mergedData, d => d.x), [marginLeft, this.width - marginRight]);
    this.yScale = d3[this.yScaleType]([0, d3.max(mergedData, d => d.y)], [this.height - marginBottom, marginTop]);

    this._renderXAxis();
    this._renderYAxisWithGridLines();
  }

  _renderXAxis() {
    this.svg.append('g')
      .attr('transform', 'translate(0, ' + (this.height - marginBottom) + ')')
      .call(d3.axisBottom(this.xScale).ticks(this.width / 80).tickSizeOuter(0))
      .call(g => {
        g.select('.domain').remove();
        g.selectAll('.tick line').remove();
        g.selectAll('.tick text')
          .attr('fill', '#8B93AE')
          .style('font-size', '9px')
          .style('font-weight', '500')
          .style('cursor', 'default');
      });
  }

  _renderYAxisWithGridLines() {
    this.svg.append('g')
      .attr('transform', 'translate(' + marginLeft + ', 0)')
      .call(d3.axisLeft(this.yScale).ticks(this.height / 40))
      .call(g => {
        g.select('.domain').remove();
        g.selectAll('.tick line')
          .attr('x1', -1 * marginRight)
          .attr('x2', 'calc(100% - ' + (marginLeft) + 'px)')
          .attr('stroke', '#D1D7E4')
          .attr('stroke-dasharray', '6');
        g.selectAll('.tick text')
          .attr('fill', '#A3AED0')
          .style('font-size', '9px')
          .style('font-weight', '500')
          .style('cursor', 'default')
          .attr('x', -1 * (marginRight + 10));
      })
  }
}
