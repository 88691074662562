var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "moderation-view" },
    [
      _c(
        "sticky-header",
        {
          attrs: {
            title: _vm.tr("Moderation"),
            info: _vm.info,
            bordered: !_vm.tabsIsVisible,
          },
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function () {
                return [
                  _vm.isPostView
                    ? _c(
                        "router-link",
                        {
                          staticClass: "button bordered gray",
                          attrs: { to: "/moderation/post" },
                        },
                        [
                          _c("i", { staticClass: "icon arrow-left" }),
                          _vm._v(" " + _vm._s(_vm.tr("Back")) + " "),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.tabsIsVisible
            ? _c("form-step-tab", { attrs: { items: _vm.tabs } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "transition-position-fix" },
        [
          _c(
            "transition",
            { attrs: { name: "fade--fast" } },
            [_c("router-view", { ref: "child" })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }