<template>
  <div class="rule-overview-view">
    <div class="left">
      <sticky-container :active="stickyIsActive">
        <div class="action-buttons">
          <router-link
            :class="'button primary ' + (isLoading ? 'disabled' : '')"
            :to="isLoading ? '' : ('/automation/' + rule.id + '/edit')"
          >
            <i class="icon edit white" />
            {{ tr('Edit') }}
          </router-link>
          <router-link
            :class="'button bordered ' + (isLoading ? 'disabled' : '')"
            :to="isLoading ? '' : ('/automation/' + rule.id + '/duplicate')"
          >
            <i class="icon copy blue" />
            {{ tr('Duplicate') }}
          </router-link>
        </div>

        <div class="rule-details">
          <card :header-color="rule && rule.active ? 'primary' : 'inactive'">
            <template #header>
              <switches v-if="!rule" />
              <switches
                v-else
                v-model="rule.active"
                :disabled="statusSwitchIsDisabled"
                :emit-on-mount="false"
                @input="() => onChangeRuleStatus(rule)"
              />
              {{ tr('Automation') }}:

              <template v-if="isLoading">
                ...
              </template>
              <template v-else>
                {{ rule.active ? tr('Active') : tr('Inactive') }}
              </template>
            </template>

            <template #default>
              <template v-if="!isLoading">
                <div>
                  <label>{{ tr('Automation type') }}:</label>
                  <span :class="'flag action ' + actionFlagClass">
                    {{ actionFlagLabel(rule.action) }}
                  </span>
                </div>

                <div v-if="rule.action !== 'stop'">
                  <label>{{ tr('Budget') }}:</label>
                  {{ budget }}
                </div>

                <div v-if="rule.action !== 'stop'">
                  <label>{{ tr('Number of active ads') }}:</label>
                  {{ rule.active_ads }}
                </div>

                <div>
                  <label>{{ tr('Ad Account') }}:</label>
                  <span class="flag purple">
                    {{ rule.ad_account ? rule.ad_account.name : '---' }}
                  </span>
                </div>

                <div v-if="rule.action === 'start'">
                  <label>{{ tr('Sources') }}:</label>
                  <div class="sources">
                    <source-profile-flag
                      v-for="profile in sourceProfiles"
                      :key="profile.id"
                      :name="profile.name"
                      :platform="profile.platform"
                    />
                  </div>
                </div>
              </template>

              <div v-else class="skeletons">
                <skeleton width="40%" height="14px" />
                <skeleton width="60%" height="14px" />
              </div>
            </template>
          </card>

          <card :header-color="!isLoading ? 'primary' : 'inactive'">
            <template #header>
              {{ tr('Activity') }}
            </template>
            <template #default>
              <template v-if="!isLoading">
                <div>
                  <label>{{ tr('Created') }}:</label>
                  {{ formatToStringYMD(rule.created_at, tr) }}
                </div>

                <div>
                  <!--TODO ez nem jön APIból-->
                  <label>{{ tr('Last modified') }}:</label>
                  <span
                    v-if="rule.updated_at"
                    v-tooltip="{ content: formatToYMD(rule.updated_at) + '. ' + formatTime(rule.updated_at), classes: [ '' ] }"
                  >
                    {{ timeAgo(rule.updated_at, tr) }}
                  </span>
                  <template v-else> - </template>
                </div>

                <div>
                  <label>{{ tr('Last run') }}:</label>
                  <span
                    v-if="rule.last_run"
                    v-tooltip="{ content: formatToYMD(rule.last_run) + '. ' + formatTime(rule.last_run), classes: [ '' ] }"
                  >
                    {{ timeAgo(rule.last_run, tr) }}
                  </span>
                  <template v-else> - </template>
                </div>

                <div v-if="rule.active">
                  <label>{{ tr('Next run in') }}:</label>
                  {{ nextRunCountdown }}
                </div>

                <div>
                  <label>{{ tr('Last activity') }}:</label>
                  <span
                    v-if="rule.last_activity"
                    v-tooltip="{ content: formatToYMD(rule.last_activity) + '. ' + formatTime(rule.last_activity), classes: [ '' ] }"
                  >
                    {{ timeAgo(rule.last_activity, tr) }}
                  </span>
                  <template v-else> - </template>
                </div>

                <div>
                  <label>{{ tr('Tasks done') }}:</label>
                  {{ rule.triggered > 0 ? rule.triggered : '-' }}
                </div>
              </template>

              <div v-else class="skeletons">
                <skeleton width="40%" height="14px" />
                <skeleton width="60%" height="14px" />
                <skeleton width="30%" height="14px" />
              </div>
            </template>
          </card>

          <card :header-color="!isLoading ? 'primary' : 'inactive'" class="connected-rules">
            <template #header>
              {{ tr('Connected automations') }}
            </template>

            <template v-if="!isLoading">
              <div v-if="rule.connected_rules.length === 0" class="empty-text">
                {{ tr('No connected automations') }}
              </div>

              <access-handled-router-link
                v-for="connectedRule in rule.connected_rules"
                :key="connectedRule.id"
                :to="'/automation/' + connectedRule.id + '/overview'"
                class="connected-rule"
              >
                {{ connectedRule.name }}
              </access-handled-router-link>
            </template>

            <div v-else class="skeletons">
              <skeleton width="40%" height="14px" />
              <skeleton width="60%" height="14px" />
              <skeleton width="30%" height="14px" />
            </div>
          </card>
        </div>

        <div v-if="!isLoading" class="action-buttons action-buttons--bottom">
          <button
            class="button red"
            @click="openDeleteConfirmationModal()"
          >
            <i class="icon delete white" />
            {{ tr('Delete') }}
          </button>

          <button
            v-if="$root.currentCompany.enabled_manual_run && $root.loggedInUser.level === 'admin'"
            class="button primary"
            :disabled="isRunning"
            @click="runRule()"
          >
            <i :class="runIconClasses" />
            {{ tr('Run') }}
          </button>
        </div>
      </sticky-container>
    </div>

    <div class="right">
      <sticky-container :active="stickyIsActive">
        <div class="title">
          {{ tr('Completed tasks') }}
        </div>
        <activity-log
          ref="activityLog"
          :rule-id="rule ? rule.id : null"
        />
      </sticky-container>
    </div>
  </div>
</template>

<script>
import RuleSwitchMixin from '@/mixins/RuleSwitch.js';

import StickyContainer from '@/components/StickyContainer.vue';
import ActivityLog from '@/components/ActivityLog.vue';
import Card from '@/components/Card.vue';
import Skeleton from '@/components/Skeleton.vue';
import SourceProfileFlag from '@/components/SourceProfileFlag.vue';
import Switches from 'vue-switches';

import Rule from '@/entities/Rule.js';
import RuleService from '@/services/Rule.js';
import { formatToStringYMD, formatToYMD, formatTime, timeAgo } from '@/utils/date';
import { formatNumber } from '@/utils/number';

export default {
  name: 'RuleOverview',
  mixins: [ RuleSwitchMixin ],
  components: {
    StickyContainer,
    ActivityLog,
    Card,
    Switches,
    Skeleton,
    SourceProfileFlag,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      nextRunCountdown: '',
      countdownTimer: null,
      isRunning: false,
    };
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    this.destroyCountdownTimer();
    window.removeEventListener('resize', this.onWindowResize);
  },
  computed: {
    rule() {
      return this.$parent.rule;
    },
    stickyIsActive() {
      return this.windowWidth >= 992;
    },
    isLoading() {
      return !(this.rule instanceof Rule);
    },
    statusSwitchIsDisabled() {
      return this.$root.loggedInUser.isRestrictedViewer
        || this.$root.isSupportMode
        || this.isLoading
        || this.deletedCampaignFlagIsVisible;
    },
    runIconClasses() {
      return {
        'icon': true,
        'white': true,
        'play': !this.isRunning,
        'loader': this.isRunning,
      };
    },
    deletedCampaignFlagIsVisible() {
      return this.rule.campaign_status === 'deleted';
    },
    budget() {
      let budget = null;
      let period = null;
      let isAdsetBudget = false;

      if (this.rule.output_campaign && this.rule.output_campaign.budget) {
        budget = formatNumber(this.rule.output_campaign.budget, null, 2);
        period = this.rule.output_campaign.budget_period;
      }
      else if (this.rule.settings.adset_budget) {
        budget = formatNumber(this.rule.settings.adset_budget, null, 2);
        period = this.rule.settings.adset_budget_period;
        isAdsetBudget = true;
      }
      else {
        return '???';
      }

      let currency = this.$root.currentCompany.ad_accounts.find(
        (i) => i.id === this.rule.ad_account.id
      ).currency;
      let currencySymbol = this.$store.getters['app/currencies'].find(i => i.code === currency);

      let resultString = '';

      if (this.$root.language === 'hu') {
        resultString = `${budget}${currencySymbol.symbol} ${this.tr(period)}`;
      }
      else {
        resultString = `${currencySymbol.symbol}${budget} ${this.tr(period)}`;
      }

      if (isAdsetBudget) {
        resultString += ` / ${this.tr('Ad set')}`;
      }

      return resultString;
    },
    actionFlagClass() {
      switch (this.rule.action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return 'stop';

        case 'start':
          return 'post-boost';

        case 'scaling_up':
          return 'scaling';
      }
    },
    sourceProfiles() {
      if (!this.rule.source_profiles) {
        return [];
      }
      return this.rule.source_profiles;
    },
  },
  watch: {
    'rule.active': {
      immediate: true,
      handler(val) {
        if (val) {
          this.initCountdownTimer();
        }
        else {
          this.destroyCountdownTimer();
        }
      },
    },
    isRunning() {
      this.updateNextRunCountdown();
    },
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    formatToStringYMD,
    formatToYMD,
    formatTime,
    timeAgo,
    initCountdownTimer() {
      return;

      this.destroyCountdownTimer();
      this.updateNextRunCountdown();
      this.countdownTimer = setInterval(this.updateNextRunCountdown, 1000);
    },
    destroyCountdownTimer() {
      if (this.countdownTimer !== null) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
        this.nextRunCountdown = '-';
      }
    },
    updateNextRunCountdown() {
      if (!this.rule) {
        this.nextRunCountdown = '-';
        return;
      }

      const nextRunInSeconds = this.rule.nextRunInSeconds;
      if (nextRunInSeconds === null) {
        this.nextRunCountdown = '-';
      }
      else if (this.isRunning || nextRunInSeconds === 0) {
        this.nextRunCountdown = this.tr('just now');
      }
      else {
        this.nextRunCountdown = this.secToHHMMSS(nextRunInSeconds);
      }
    },
    secToHHMMSS(sec_num) {
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      let result = minutes + ':' + seconds;
      if (hours !== '00') {
        result = hours + ':' + result;
      }
      return result;
    },
    actionFlagLabel(action) {
      switch (action) {
        case 'stop':
        case 'smart_stop':
        case 'delete':
          return this.tr('Ad Stop');

        case 'start':
          return this.tr('Ad Creation');

        case 'scaling_up':
          return this.tr('Ad Scaling');
      }
    },

    openDeleteConfirmationModal() {
      let id = this.rule.id;
      this.$confirmation.open({
        className: 'delete-confirm',
        image: require('@/assets/img/delete-confirm.png'),
        imageClass: 'delete-confirm-image',
        title: this.tr('Are you sure you want to delete this automation?'),
        content: this.rule.name,
        labelCancel: this.tr('Cancel'),
        labelConfirm: this.tr('Confirm'),
        confirmClass: 'red',
        confirm: () => RuleService.delete(id).then(() => {
          this.$store.commit('rules/ruleRemoved', id);
          this.$toast.clear();
          this.$toast.success(this.tr('The automation was successfully deleted.'));
          this.$router.replace('/automation');
        }),
      });
    },
    runRule() {
      if (this.isRunning) {
        return;
      }

      this.isRunning = true;

      RuleService.run(this.rule.id)
        .then((resp) => {
          this.rule.updateWith(resp);
          this.$store.commit('rules/updateInList', this.rule);

          this.$toast.clear();
          this.$toast.success(this.tr('Automation executed successfully.'));

          if (this.$refs.activityLog) {
            this.$refs.activityLog.refresh();
          }
        })
        .catch(err => this.error(err))
        .finally(() => this.isRunning = false);
    },
  },
};
</script>
