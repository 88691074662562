<template>
  <div class="post-modal-content">
    <template v-if="!isLoading">
      <div class="post">
        <div class="top">
          <social-page
            :source-profile="post.source_profile"
            :date="post.created_at"
          />
        </div>
        <form-step-tab :items="tabs" />
      </div>
      <div class="scroll-wrapper">
        <div v-if="isOriginal" class="original">
          <div class="post">
            <inline-social-content :entity="post" />
            <link-with-icon
              v-if="post.url"
              :link="post.url"
            >
              {{ tr('Go to Post') }}
            </link-with-icon>
          </div>

          <h3>{{ tr('Post metrics') }}</h3>
          <div class="metric-select-wrapper">
            <v-select
              :options="metricFields"
              multiple
              label="label"
              class="metric-select"
              :close-on-select="false"
              @open="isMetricDropdownOpen = true"
              @close="isMetricDropdownOpen = false"
            >
              <template #search="{ attributes, events }">
                <input
                  v-bind="attributes"
                  :placeholder="tr('Customize metrics')"
                  class="metric-select--placeholder-input"
                  v-on="events"
                />
              </template>
              <template #option="field">
                <label
                  class="metric-select--option ignore-checkbox-style"
                  @click="() => toggleVisibleField(field)"
                >
                  <input
                    v-bind="field"
                    type="checkbox"
                    :checked="isSelected(field)"
                  />
                  {{ field.label }}
                </label>
              </template>
            </v-select>
          </div>

          <div class="metrics-wrapper">
            <sorted-table :data="[ post.metric ]" disable-scroll>
              <template #header>
                <table-head
                  v-for="(field) in metricVisibleFields"
                  :key="'metric-head-' + field.machine_name"
                  :col="field.machine_name"
                >
                  {{ tr(field.label) }}
                </table-head>
              </template>
              <template #default="{ sort }">
                <table-row>
                  <table-data
                    v-for="(field) in metricVisibleFields"
                    :key="'metric-value-' + field.machine_name"
                    :col="field.machine_name"
                  >
                    {{ formatNumber(sort[0][field.machine_name], field.machine_name === 'ctr' ? '%' : '') }}
                  </table-data>
                </table-row>
              </template>
            </sorted-table>
          </div>

          <div class="last-update">
            {{ tr('Data last updated at :time', { ':time': timeAgo(post.source_profile.fetched_at, tr) }) }}
          </div>

          <info-text v-if="!post.source_profile.has_active_start_rule" class="ai-evaluation-not-allowed">
            {{ tr('The post AI evaluation will not occur until the profile that published it has active Start Automation.') }}
          </info-text>
          <template v-else>
            <h3 class="title-with-icon">
              {{ tr('Actuality of the content') }}
            </h3>

            <template v-if="contectActualityFormOpen">
              <div class="templateable-input">
                <date-time-input v-model="newContentActuality" />
                <button
                  class="primary"
                  :disabled="isLoadingContantActualitySave"
                  @click="() => updateContentActuality()"
                >
                  <i v-if="isLoadingContantActualitySave" class="icon loader white" />
                  {{ tr('Save') }}
                </button>
                <a class="primary-link" @click="clearDateAndTime()">
                  {{ tr('Clear date and time') }}
                </a>
              </div>
            </template>
            <info-text v-else-if="!post.ai_evaluation_possible" class="ai-evaluation-not-possible">
              {{ tr('The text of the post is too short. AI evaluation is not possible.') }}
            </info-text>
            <template v-else>
              <div v-if="!post.ai_content_actuality_checked" class="ai-actuality-checking">
                <i class="icon loader" /> {{ tr('Checking content actuality...') }}
              </div>
              <template v-else-if="post.ai_content_actuality">
                {{ aiContentAtualityText }}:<br />
                {{ formatToYMD(post.ai_content_actuality) }}
                {{ formatTime(post.ai_content_actuality) }}
              </template>
              <template v-else>
                {{ contentActualityNotSetText }}
              </template>
            </template>

            <a
              v-if="
                (!post.ai_evaluation_possible || post.ai_content_actuality_checked) &&
                  !contectActualityFormOpen &&
                  !$root.loggedInUser.isRestrictedViewer
              "
              class="primary-link edit-content-actuality-link"
              @click="() => editDateAndTime()"
            >
              {{ tr('Edit date and time') }}
            </a>

            <h3
              v-if="post.ai_meta && post.ai_meta.length !== 0"
              class="title-with-icon"
            >
              {{ tr('Content scoring') }}
            </h3>

            <ai-score
              v-if="post.ai_evaluation_possible && !post.ai_meta_error"
              :value="post.ai_value"
            />

            <template v-if="post.ai_meta && post.ai_meta.length !== 0">
              <copy-evaluation :details="post.ai_meta" />
            </template>

            <div v-if="post.platform === 'facebook'" class="buttons">
              <button class="red" @click="() => openDeleteConfirmModal()">
                {{ tr('Delete post') }}
              </button>
            </div>
          </template>
        </div>
        <div v-if="isImproved" class="improved">
          <div
            v-if="post.ai_meta && post.ai_meta.length !== 0 && (!post.improved_copy || post.improved_copy.inProgress)"
            class="buttons"
          >
            <button
              class="primary"
              :disabled="improvedCopyInProgress"
              @click="() => improveCopy()"
            >
              <i v-if="improvedCopyInProgress" class="icon loader white" />
              {{ tr('Generate improved copy') }}
            </button>
          </div>
          <info-text
            v-else-if="!post.ai_meta || post.ai_meta.length === 0"
            class="imporovement-not-allowed"
          >
            {{ tr('Improved copy cannot be generated until AI evaluation is complete. Please check the issues on the Original tab first.') }}
          </info-text>

          <improved-copy-component
            v-if="post.improved_copy && !post.improved_copy.inProgress"
            :entity="post.improved_copy"
          />
        </div>
      </div>
    </template>
    <!-- Skeleton  -->
    <template v-else>
      <div class="post">
        <div class="top">
          <social-page :source-profile="null" />
        </div>
        <form-step-tab :items="tabs" />
      </div>
      <div class="scroll-wrapper">
        <div class="post">
          <inline-social-content :entity="null" />
          <link-with-icon>
            {{ tr('Go to Post') }}
          </link-with-icon>
        </div>

        <h3>{{ tr('Post metrics') }}</h3>
        <div class="metric-select-wrapper">
          <skeleton width="100%" height="40px" radius="10px" />
        </div>

        <div class="metrics-wrapper">
          <sorted-table disable-scroll>
            <template #header>
              <table-head width="100px"><skeleton width="50px" height="20px" radius="10px" /></table-head>
              <table-head width="100px"><skeleton width="50px" height="20px" radius="10px" /></table-head>
              <table-head width="100px"><skeleton width="50px" height="20px" radius="10px" /></table-head>
              <table-head width="100px"><skeleton width="50px" height="20px" radius="10px" /></table-head>
            </template>
            <template>
              <table-row>
                <table-data><skeleton width="50px" height="20px" radius="10px" /></table-data>
                <table-data><skeleton width="50px" height="20px" radius="10px" /></table-data>
                <table-data><skeleton width="50px" height="20px" radius="10px" /></table-data>
                <table-data><skeleton width="50px" height="20px" radius="10px" /></table-data>
              </table-row>
            </template>
          </sorted-table>
        </div>

        <div class="last-update">
          <skeleton width="250" height="20px" radius="10px" />
        </div>

        <div class="buttons">
          <skeleton width="100%" height="40px" radius="10px" />
        </div>

        <template>
          <h3 class="title-with-icon">
            {{ tr('Actuality of the content') }}
          </h3>

          <template>
            <div class="ai-actuality-checking">
              <i class="icon loader" /> {{ tr('Loading...') }}
            </div>
            <br />
          </template>

          <h3>{{ tr('Content scoring') }}</h3>

          <skeleton width="50px" height="20px" radius="10px" />

          <template>
            <copy-evaluation :details="null" />
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AiScore from '@/components/AiScore.vue';
import CopyEvaluation from '@/components/CopyEvaluation.vue';
import DateTimeInput from '@/components/DateTimeInput.vue';
import FormStepTab from '@/components/FormStepTab.vue';
import ImprovedCopyComponent from '@/components/ImprovedCopy.vue';
import InfoText from '@/components/InfoText.vue';
import InlineSocialContent from '@/components/InlineSocialContent.vue';
import LinkWithIcon from '@/components/LinkWithIcon.vue';
import PostDeleteModalContent from '@/components/ModalContents/PostDeleteModalContent.vue';
import Skeleton from '@/components/Skeleton.vue';
import SocialPage from '@/components/SocialPage.vue';
import { AI_STATUS } from '@/consts';
import ImprovedCopy from '@/entities/ImprovedCopy.js';
import Post from '@/entities/Post.js';
import MagicToolService from '@/services/MagicTool.js';
import PostService from '@/services/Post.js';
import { formatTime, formatToYMD, timeAgo } from '@/utils/date';
import { formatNumber } from '@/utils/number.js';

export default {
  name: 'PostModalContent',
  components: {
    AiScore,
    CopyEvaluation,
    DateTimeInput,
    ImprovedCopyComponent,
    InfoText,
    InlineSocialContent,
    LinkWithIcon,
    Skeleton,
    SocialPage,
    FormStepTab,
  },
  data() {
    return {
      isLoading: true,
      postLoadedFromStore: false,
      post: null,
      fetchTimer: null,
      tmpVisibleFields: [],
      newContentActuality: null,
      contectActualityFormOpen: false,
      isLoadingContantActualitySave: false,
      isMetricDropdownOpen: false,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    metricFields() {
      return this.$store.getters['app/postMetrics'];
    },
    improvedCopyInProgress() {
      return this.post && this.post.improved_copy && this.post.improved_copy.inProgress;
    },
    metricVisibleFields() {
      return this.$store.getters['posts/metricVisibleFields'];
    },
    aiContentAtualityText() {
      if (this.post.ai_content_actuality_status === AI_STATUS.MANUAL) {
        return this.tr('Due to a manual edit, the content loses its relevance on the following date');
      }
      return this.tr('According to Infinite∞Ai, the content loses its relevance at the following date');
    },
    contentActualityNotSetText() {
      if (this.post.ai_content_actuality_status === AI_STATUS.MANUAL) {
        return this.tr('The actuality of this content has been manually removed.');
      }
      return this.tr('The Infinite∞Ai did not find any element in the content that would tie its actuality to time.');
    },
    currentTab() {
      return this.$route.params.tab;
    },
    isOriginal() {
      return this.currentTab === 'original';
    },
    isImproved() {
      return this.currentTab === 'improved';
    },
    tabs() {
      const tabs = [];

      tabs.push({
        label: this.tr('Original Post'),
        link: `/posts/${this.id}/original`,
      });

      tabs.push({
        label: this.tr('Improved Content'),
        link: `/posts/${this.id}/improved`,
      });

      return tabs;
    },
  },
  watch: {
    isMetricDropdownOpen(isMetricDropdownOpen) {
      if (isMetricDropdownOpen) {
        this.onOpenFieldsModal();
      }
      else {
        this.applyChangedFields();
      }
    },
  },
  created() {
    this.loadData(true);
  },
  beforeDestroy() {
    this.destroyFetchTimer();
  },
  methods: {
    formatNumber,
    formatTime,
    formatToYMD,
    timeAgo,
    loadData(force) {
      if (!force) {
        this.post = this.$store.getters['posts/list'].find(post => post.id === this.id);
        this.postLoadedFromStore = !!this.post;
        if (this.postLoadedFromStore) {
          this.isLoading = false;
          this.onUpdated();
          return;
        }
      }

      PostService.get(this.id)
        .then(resp => this.post = resp)
        .catch(err => this.error(err))
        .finally(() => {
          this.isLoading = false;
          this.onUpdated();
        });
    },
    onUpdated() {
      this.$nextTick(() => {
        if (this.improvedCopyInProgress) {
          this.initFetchTimer();
        }
      });

      this.newContentActuality = this.post.ai_content_actuality;
    },
    initFetchTimer() {
      if (this.fetchTimer !== null) {
        return;
      }

      this.fetchTimer = setTimeout(() => {
        this.fetchTimer = null;
        this.loadData(true);
      }, 10000);
    },
    destroyFetchTimer() {
      if (this.fetchTimer !== null) {
        clearTimeout(this.fetchTimer);
        this.fetchTimer = null;
      }
    },
    updateContentActuality() {
      if (this.isLoadingContantActualitySave) {
        return;
      }

      this.isLoadingContantActualitySave = true;

      const clonedEntity = new Post(this.post);
      clonedEntity.ai_content_actuality = this.newContentActuality;

      PostService.update(clonedEntity)
        .then(resp => {
          this.post.ai_content_actuality = resp.ai_content_actuality;
          this.post.ai_content_actuality_status = resp.ai_content_actuality_status;
          this.contectActualityFormOpen = false;

          this.showToast('success', this.tr('The changes have been successfully saved.'));

          return resp;
        })
        .catch(err => this.error(err))
        .finally(() => this.isLoadingContantActualitySave = false);
    },
    improveCopy() {
      this.post.improved_copy = new ImprovedCopy({ status: 1 });

      MagicToolService.improve({ postId: this.post.id })
        .then(resp => {
          this.post.improved_copy = resp;
          this.showToast('success', this.tr('We are already working on it! The evaluation will be completed within 1-2 minutes.'));
          this.initFetchTimer();

          return resp;
        })
        .catch(err => {
          this.post.improved_copy = null;
          this.error(err);
        });
    },
    openDeleteConfirmModal() {
      this.$fullscreenModal.open(
        this,
        PostDeleteModalContent,
        {
          text: 'PostDeleteModalContent',
        },
      );
    },
    editDateAndTime() {
      this.contectActualityFormOpen = true;
      if (!this.newContentActuality) {
        this.newContentActuality = new Date();
        this.newContentActuality.setSeconds(0);
        this.newContentActuality.setMilliseconds(0);
      }
    },
    clearDateAndTime() {
      this.newContentActuality = null;
      this.updateContentActuality();
    },
    onOpenFieldsModal() {
      this.tmpVisibleFields = [ ...this.metricVisibleFields ];
    },
    applyChangedFields() {
      this.tmpVisibleFields = this.tmpVisibleFields.sort((a, b) => {
        const indexA = this.metricFields.findIndex(m => m.machine_name === a.machine_name);
        const indexB = this.metricFields.findIndex(m => m.machine_name === b.machine_name);
        return indexA - indexB;
      });

      this.$store.commit('posts/setMetricVisibleFields', this.tmpVisibleFields);
    },
    isSelected(field) {
      return this.tmpVisibleFields.some(s => s.machine_name === field.machine_name);
    },
    toggleVisibleField(field) {
      setTimeout(() => {
        if (this.isSelected(field)) {
          this.tmpVisibleFields = this.tmpVisibleFields.filter(i => i.machine_name !== field.machine_name);
        }
        else {
          this.tmpVisibleFields.push(field);
        }
      }, 1);
    },
  },
};
</script>
