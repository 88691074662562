var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source-profile-flag", class: _vm.flagColor },
    [
      _vm._v(" " + _vm._s(_vm.name) + " "),
      _c("i", { staticClass: "icon white", class: _vm.platform }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }