var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-report-view view" },
    [
      !_vm.$parent.rule
        ? _c("div", { staticClass: "loader" }, [
            _c("i", { staticClass: "icon loader black" }),
          ])
        : _c("report-view", {
            attrs: {
              "ad-account-id": _vm.$parent.rule.ad_account.id,
              "campaign-id": _vm.$parent.rule.output_campaign.id,
              action: _vm.action,
              period: _vm.period,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }